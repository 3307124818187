import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import UserManagement from "../components";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { getUserList } from "../../../../../actions/User";
import { User } from "../../../../../models/User";
import { UserListDispatchTypes } from "../../../../../models/DispatchTypes/User";
import { excelDownload, excelDownloadReset } from "../../../../../actions/AllPatientRecord";

const mapStateToProps = ({ userListState, excelDownloadState }: ApplicationState) => ({
  userList: userListState.response as User[],
  loading: userListState.loading as boolean,
  documentDownloadData: excelDownloadState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<UserListDispatchTypes>
  >
) => {
  return {
    getUserManagmentList: () => {
      dispatch(getUserList());
    },
    excelDownload: () => {
      dispatch(excelDownload())
    },
    excelDownloadReset: () => {
      dispatch(excelDownloadReset())
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getServiceProviderList,
  resetServiceProviderList,
} from "../../../../../actions/Service";
import { ServiceProviderDispatchTypes } from "../../../../../models/DispatchTypes/Service";
import { ServiceProviderResponse } from "../../../../../models/Api/Service";
import ServiceProvider from "../components/ServiceProvider";

const mapStateToProps = ({ serviceProviderState }: ApplicationState) => ({
  serviceProviderList: serviceProviderState as ServiceProviderResponse,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ServiceProviderDispatchTypes>
  >
) => {
  return {
    getServiceProviderList: (value: string) => {
      dispatch(getServiceProviderList(value));
    },
    resetServiceProviderList: () => {
      dispatch(resetServiceProviderList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider);

import { Reducer } from "redux";

import { PostServiceProviderResponse } from "../models/Api/Service";
import { PostServiceProviderDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceProviderActionDispatchType } from "../constants/Service";

const defaultResponse = {
  referralId: -1,
  referralServiceProviderId: 0,
  serviceProviderId: null,
  providerName: "",
  providerNpi: "",
  paymentStructure: "",
  streetName1: null,
  streetName2: null,
  providerCity: null,
  providerCounty: null,
  providerState: null,
  zipCode: null,
  userCreated: null,
  providerId: 0,
  plexisOfficeId: 0,
  phone: "",
  extension: null,
};

const initialState: PostServiceProviderResponse = {
  loading: false,
  response: defaultResponse,
  error: null,
};

const PostServiceProviderReducer: Reducer<
  PostServiceProviderResponse,
  PostServiceProviderDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_RESET: {
      return {
        ...state,
        response: defaultResponse,
        loading: false,
        error: {},
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
  }
  return state;
};
export default PostServiceProviderReducer;

import { Reducer } from "redux";

import { FaxFormMasterResponse } from "../models/Api/Master";
import { FaxFormDispatchTypes } from "../models/DispatchTypes/Master";
import { FaxFormMasterActionDispatchTypes } from "../constants/Master";

export const initialState: FaxFormMasterResponse = {
  loading: false,
  response: [],
};

const FaxFormMasterReducer: Reducer<
  FaxFormMasterResponse,
  FaxFormDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case FaxFormMasterActionDispatchTypes.FAX_FORM_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case FaxFormMasterActionDispatchTypes.FAX_FORM_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default FaxFormMasterReducer;

import { UseFormReset, UseFormWatch } from "react-hook-form";
import { Grid } from "@mui/material";

import Requestor from "../../ReferralIntake/containers/Requestor";
import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";
import { OuterBodyGrid, TopGrid } from "../styles/GeneralInfo";

export interface PropsFromState {
  values: AuthorizationDetailTabs;
  control: any;
  setValue: (id: any, value: any, boolean: any) => void;
  patientRequestDetail: AuthorizationDetailTabs;
  clearErrors: (name: string) => void;
  reset: UseFormReset<any>;
  getValues: any;
  watch: UseFormWatch<any>;
}

type AllProps = PropsFromState;

const RequestDetails: React.FC<AllProps> = ({
  control,
  setValue,
  values,
  patientRequestDetail,
  clearErrors,
  reset,
  getValues,
  watch,
}: AllProps) => {
  return (
    <Grid container sx={OuterBodyGrid}>
      <Grid item xs={12} sx={TopGrid}>
        <Requestor
          values={values}
          control={control}
          setValue={setValue}
          patientRequestDetail={patientRequestDetail}
          clearErrors={clearErrors}
          reset={reset}
          watch={watch}
          getValues={getValues}
          injectedFrom={RequesterComponentInjectType.AUTH_DETAILS}
        />
      </Grid>
    </Grid>
  );
};

export default RequestDetails;

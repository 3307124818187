import { Reducer } from "redux";

import { PhysicianRecordsResponse } from "../models/Api/PhysicianDetails";
import { ReferralIntakePhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  GetPhysicianRecordsActionDispatchTypes,
  PhysicianTypeID,
} from "../constants/PhysicianDetails";

const initialState: PhysicianRecordsResponse = {
  loading: true,
  response: [
    {
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      streetName: "",
      zipCode: "",
      city: "",
      phoneNo: "",
      taxId: "",
      physicianState: "",
      address: "",
      physicianTypeId: PhysicianTypeID.DEFAULT,
    },
  ],
};

const PhysicianRecordsReducer: Reducer<
  PhysicianRecordsResponse,
  ReferralIntakePhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  const invoke = (state: PhysicianRecordsResponse) => ({
    ...state,
    loading: true,
  });

  const success = (action: any) => ({
    response: action.response,
    loading: false,
  });

  const reset = () => ({
    loading: false,
    response: [],
  });

  switch (action.type) {
    case GetPhysicianRecordsActionDispatchTypes.GET_PHYSICIAN_RECORDS_INVOKE: {
      return invoke(state);
    }
    case GetPhysicianRecordsActionDispatchTypes.RESET_PHYSICIAN_RECORDS: {
      return reset();
    }
    case GetPhysicianRecordsActionDispatchTypes.GET_PHYSICIAN_RECORDS_SUCCESS: {
      return success(action);
    }
  }
  return state;
};

export default PhysicianRecordsReducer;

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ErrorBox, GridLayout, GridItem } from "../styles/styles";
import { others, colors } from "../../styles/colors";

export function errorPage() {
  return (
    <GridLayout container>
      <GridItem item>
        <ErrorBox>
          <Typography
            variant="h1"
            color={others.otherColors[10]}
            fontWeight="500"
          >
            <h1>404</h1>
            {/*TBD*/}
          </Typography>
          <Typography variant="h1" color={colors.primary.main} fontWeight="400">
            <h2>Oops Something went wrong !</h2>
          </Typography>
          <Link href="/">
            <a>
              <Typography
                variant="h3"
                color={colors.primary.main}
                fontWeight="400"
              >
                Go back home
              </Typography>
            </a>
          </Link>
        </ErrorBox>
      </GridItem>
    </GridLayout>
  );
}

export default errorPage;

import { Reducer } from "redux";

import { AdminNotesListResponse } from "../models/Api/Notes";
import { AdminNotesListDispatchTypes } from "../models/DispatchTypes/Notes";
import {
  AdminDefaultNoteList,
  NoteTemplateListActionDispatchTypes,
} from "../constants/Notes";

export const initialState: AdminNotesListResponse = {
  loading: false,
  response: AdminDefaultNoteList,
};

const AdminNotesListReducer: Reducer<
  AdminNotesListResponse,
  AdminNotesListDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_ERROR: {
      return {
        ...state,
        response: action.response,
        error: action.error,
        loading: false,
      };
    }
  }
  return state;
};

export default AdminNotesListReducer;

import colors from "../../../../../../../styles/colors/colors";

export const DisciplineTableData = () => ({
  paddingTop: "1.7rem",
});

export const DisciplineMainGrid = () => ({
  backgroundColor: colors.backgroundColor[2],
  padding: "0.5rem 0 0.8rem 0",
});

export const DisciplineTopContainerGrid = () => ({
  borderBottom: `1px solid ${colors.borders[1]}`,
});

export const DisciplineRightGrid = () => ({
  padding: "0 0 0 0.5rem",
});

export const DisciplineHeadingGrid = () => ({
  padding: "1rem 0 0.95rem 1.2rem",
  marginRight: "3.5rem",
});

export const DisciplineRightListGrid = () => ({
  margin: "1.5rem 0 1rem 0.8rem",
  display: "flex",
});

export const DividerStyle = () => ({
  margin: "0.6rem 5rem 0.6rem 0.8rem",
});

export const DisciplineLeftGrid = () => ({
  borderLeft: `1px solid ${colors.borders[1]}`,
  margin: "0.5rem 0",
});

export const DisciplineLeftHeadingGrid = () => ({
  padding: "0.3rem 0 1.95rem 1rem",
  borderBottom: `1px solid ${colors.borders[1]}`,
});

export const DisciplineLeftHeadingSubtitleGrid = () => ({
  padding: "1.85rem 0 0.7rem 1rem ",
});

export const DisciplineLeftListGrid = () => ({
  padding: "0 0 0 1rem",
  display: "flex",
});

export const DisciplineLeftListItemGrid = () => ({
  paddingTop: "1.4rem",
});

export const DisciplineBottomContainerGrid = () => ({
  marginTop: "0.3rem",
});

export const DisciplinePHcontainerGrid = () => ({
  padding: "0 1.4rem 0 3rem  ",
});

export const DisciplinePHheadingGrid = () => ({
  padding: "1.56rem 0 1.95rem 1rem",
  borderBottom: `1px solid ${colors.borders[1]}`,
});

export const DisciplinePHlistGrid = () => ({
  justifyContent: "space-around",
});

export const DisciplinePHlistItemGrid = () => ({
  display: "flex",
});

export const DisciplinePHlistItem = () => ({
  padding: "1.8rem 0 3rem 0",
});

export const DisciplineCareTypesdGrid = () => ({
  padding: "0 0 0 3rem",
  marginBottom: "2.4rem",
});

import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import { AllPendingUnassignStaffDispatchTypes, MyPatientFilterDispatchTypes } from "../models/DispatchTypes/Filter";
import { AllPendingUnassignStaffActionDispatchTypes, MyPatientFilterActionDispatchTypes } from "../constants/Filter";
import { FilterModel } from "../models/Api/Filter";
import { AllPendingUnassignStaff } from "../models/Filter";
import { PostAllPendingUnassignStaff } from "../services/AllPatientRecord";
import { configUrlForReferral } from "../constants/Config";

export const storeFilterPayload: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    MyPatientFilterDispatchTypes
  >
> = ( type: number, payload?: FilterModel) => {
  return async (dispatch: Dispatch) => {
    switch (type) {
      case 0:
        dispatch({
          response: payload,
          type: MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_RESET,
        });
        break;
      case 1:
        dispatch({
          response: payload,
          type: MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_UPDATE,
        });
        break;
      default:
        break;
    }
  };
};

export const postAllPendingUnassignStaff: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AllPendingUnassignStaffDispatchTypes
  >
> = (payload: AllPendingUnassignStaff) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_INVOKE,
    });

    const response = await PostAllPendingUnassignStaff(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};
import { configureStore } from "@reduxjs/toolkit";
import { Store } from "redux";

import { appConfigMiddleware } from "../middleware/AppConfigs";
import { ApplicationState } from "./ApplicationState";
import createRootReducer from "./index";

function configureAppStore(): Store<ApplicationState> {
  const store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(appConfigMiddleware),
  });
  return store;
}

export default configureAppStore;

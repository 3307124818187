import { useEffect,  useRef } from "react";

const usePreviousDataState = (object1: any, object2: any) => {
  const ref1 = useRef();
  const ref2 = useRef();

  useEffect(() => {
    ref1.current = object1;
    ref2.current = object2;

  }, [object1, object2]);

  return {ref1: ref1.current, ref2: ref2.current};
};

export default usePreviousDataState;
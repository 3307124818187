import { Dispatch, ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import {
  AllPatientDispatchTypes,
  AllPatientTabDispatchTypes,
  EligibilityCheckActionDispatchTypes,
  Icd10RecordDispatchTypes,
  MyPatientDispatchTypes,
  PatientRequestActionDispatchTypes,
  ReauthReferralRequestActionDispatchTypes,
  ReauthReferralSubmitActionDispatchTypes,
  ReferralSubmitActionDispatchTypes,
  SavePatientRequestActionDispatchTypes,
  UpdateMyPatientListDispatchTypes,
} from "../models/DispatchTypes/AllPatientRecord";
import {
  AssignStaffIncompletePatientDispatchTypes,
  IncompletePatientDispatchTypes,
} from "../models/DispatchTypes/Patient";
import {
  AssignStaffIncompletePatientActionDispatchTypes,
  IncompletePatientActionDispatchTypes,
} from "../constants/Patient";
import {
  AssignedStaff,
  AssignStaffIncompleteRequest,
  ReAuthReferralRequest,
  SaveIcd10Records,
} from "../models/Patient";
import {
  ReAuthServiceRequest,
  ServiceProviderPayload,
} from "./../models/Service";
import {
  Icd10RecordsActionDispatchTypes,
  MessageType,
  MyPatientActionDispatchTypes,
  MyPatientListActionDispatchTypes,
  PatientRecordDispatchTypes,
  PatientRequestDispatchTypes,
  PatientTabDispatchTypes,
  ReAuthReferralRequestDispatchType,
  ReferralResponseMessages,
  ReferralSubmitDispatchTypes,
  SaveOrUpdatePatientRequestDispatchTypes,
} from "../constants/AllPatientRecord";
import {
  CheckEligibility,
  DeleteDocumentsFile,
  GetAllPatientRecord,
  GetDocumentDownload,
  GetDocuments,
  GetExcelDownload,
  GetIcd10Records,
  PostIncompletePatientList,
  PostMyPatientList,
  GetPatientRequestById,
  GetpatientTab,
  GetReauthReferralRequestById,
  GetReauthReferralSubmit,
  GetReferralDocuments,
  ReferralSubmit,
  GetServiceRequestRecords,
  PutIncompletePatientList,
  PutMyPatientList,
  SaveDocumentsDetail,
  SaveDocumentsFile,
  SaveIcd10RecordsData,
  SavePatientRequest,
  SaveReauthReferralRequest,
  SaveReauthServiceRequest as ReAuthServiceRequestService,
  UpdateDocumentsDetail,
  UpdateDocumentsFile,
  UpdateICD10,
  UpdatePatientRequestById,
  UpdateReauthReferralRequest,
  UpdateReauthServiceRequest,
  PostGenerateAuthTemplate,
  SaveAttachLetter,
} from "../services/AllPatientRecord";
import {
  configUrlForBlob,
  configUrlForCheckEligibility,
  configUrlForReferral,
  configUrlForUserManagement,
} from "../constants/Config";
import {
  ReAuthPhysicianDetailsDispatchTypes,
  ReferralIntakePhysicianDetailsDispatchTypes,
} from "../models/DispatchTypes/PhysicianDetails";
import {
  GetFollowingPhysicianDetailsActionDispatchTypes,
  GetPhysicianDetailsActionDispatchTypes,
  GetPhysicianRecordsActionDispatchTypes,
  PostFollowingPhysicianDetailsActionDispatchTypes,
  PostPhysicianDetailsActionDispatchTypes,
  PutFollowingPhysicianDetailsActionDispatchTypes,
  PutPhysicianDetailsActionDispatchTypes,
} from "../constants/PhysicianDetails";
import {
  GetFollowingPhysicianDetailsCall,
  GetPhysicianDetails,
  GetPhysicianRecords,
  PostFollowingPhysicianDetailsCall,
  PostPhysicianDetails,
  PutFollowingPhysicianDetailsCall,
  PutPhysicianDetails,
} from "../services/PhysicianDetails";
import {
  DocumentDownloadDispatchTypes,
  DocumentReviewActionDispatchTypes,
  ExcelDownloadDispatchTypes,
  GenerateAuthTemplateDispatchTypes,
  ReferralDocumentFileDispatchTypes,
  SaveUpdateDocumentDetailActionDispatchTypes,
  AttachLetterDispatchTypes,
} from "../constants/Document";
import {
  AttachLetterActionDispatchTypes,
  DocumentDownloadActionDispatchTypes,
  DocumentReviewDispatchTypes,
  ExcelDownloadActionDispatchTypes,
  GenerateAuthTemplateActionDispatchTypes,
  ReferralDocumentFileActionDispatchTypes,
} from "../models/DispatchTypes/Document";
import {
  ReAuthServiceRequestActionDispatchType,
  ServiceRequestActionDispatchType,
} from "../constants/Service";
import { PostServiceRequest, PutServiceRequest } from "../services/Service";
import { PatientTabModule, ReauthTabModule } from "../routes/AllRoutes";
import { ReferralIntakePhysicianDetails } from "../models/PhysicianDetails";
import { ServiceProviderActionDispatchType } from "../constants/Service";
import SnackbarUtils from "../utils/SnackbarProvider";
import {
  GetServiceProviderById,
  PostServiceProviderDetails,
  PutServiceProvider,
} from "../services/Service";
import { ServiceProviderDetailDispatchTypes } from "../models/DispatchTypes/Service";
import {
  EligibilityCheckDispatchTypes,
  ReAuthReferralSubmitDispatchTypes,
} from "../constants/AllPatientRecord";
import { DispatchResponse, get404InitialData, getValue } from "../utils";
import {
  MyPatientResponse,
  ReAuthSubmitPayload,
  ReferralSubmitPayload,
} from "../models/Api/AllPatientRecord";
import { resetStates } from "./Common";

export const getAllPatientRecord: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AllPatientDispatchTypes>
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientRecordDispatchTypes.PATIENT_RECORD_INVOKE,
    });
    const response = await GetAllPatientRecord(
      configUrlForReferral,
      payload,
    );
    dispatch({
      response,
      type: PatientRecordDispatchTypes.PATIENT_RECORD_SUCCESS,
    });
  };
};

export const getPatientTabList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
> = (path?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientTabDispatchTypes.PATIENT_TAB_INVOKE,
    });
    const response = await GetpatientTab(path);
    dispatch({
      response,
      type: PatientTabDispatchTypes.PATIENT_TAB_SUCCESS,
    });
  };
};

export const getPatientRequestById: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<PatientRequestActionDispatchTypes>
  >
> = (referralId: string, patientId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientRequestDispatchTypes.PATIENT_REQUEST_INVOKE,
    });
    const response = await GetPatientRequestById(
      configUrlForReferral,
      referralId,
      patientId
    );
    dispatch({
      response,
      type: PatientRequestDispatchTypes.PATIENT_REQUEST_SUCCESS,
    });
  };
};

export const getICd10Records: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Icd10RecordDispatchTypes
  >
> = (id: number, typeId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Icd10RecordsActionDispatchTypes.ICD_10_INVOKE,
    });
    const response = await GetIcd10Records(configUrlForReferral, id, typeId);
    dispatch({
      response,
      type: Icd10RecordsActionDispatchTypes.ICD_10_SUCCESS,
    });
  };
};

export const updateReferralDetail: ActionCreator<
  ThunkAction<void, ApplicationState, Record<string, never>, Action<any>>
> = (payload: any, activeTab: number) => {
  return async (dispatch: Dispatch) => {
    await updateDispatch(payload, activeTab, dispatch);
  };
};

export const updateReauthReferralDetail: ActionCreator<
  ThunkAction<void, ApplicationState, Record<string, never>, Action<any>>
> = (payload: any, activeTab: number) => {
  return async (dispatch: Dispatch) => {
    await updateReauthDispatch(payload, activeTab, dispatch);
  };
};

export const updateDispatch = (
  payload: any,
  activeTab: number,
  dispatch: Dispatch
) => {
  switch (activeTab) {
    case PatientTabModule.REQUEST:
      return UpdateReferralRequest(payload, dispatch);
    case PatientTabModule.ICD10:
      return updateICD10(payload, dispatch);
    case PatientTabModule.ORDERING_PHYSICIAN:
      return putPhysicianDetails(
        getValue(payload, "orderingPhysician.referralId", ""),
        payload,
        dispatch
      );
    case PatientTabModule.SERVICE_PROVIDER:
      return UpdateServiceProvider(payload, dispatch);
    case PatientTabModule.SERVICE_REQUEST:
      return UpdateServiceRequest(payload, dispatch);
    case PatientTabModule.DOCUMENTS:
      return updateDocument(payload, dispatch);
    case PatientTabModule.REVIEW:
      return "";
  }
  return "";
};

export const updateReauthDispatch = (
  payload: any,
  activeTab: number,
  dispatch: Dispatch
) => {
  switch (activeTab) {
    case ReauthTabModule.REQUEST:
      return UpdateReauthRequest(payload, dispatch);
    case ReauthTabModule.ICD10:
      return updateICD10(payload, dispatch);
    case ReauthTabModule.FOLLOWING_PHYSICIAN:
      return putFollowingPhysicianDetails(
        payload.serviceRequestId,
        payload,
        dispatch
      );
    case ReauthTabModule.SERVICE_REQUEST:
      return UpdateReauthServiceRequestRecord(payload, dispatch);
    case ReauthTabModule.DOCUMENTS:
      return updateDocument(payload, dispatch);
    case ReauthTabModule.REVIEW:
      return "";
  }
  return "";
};

const UpdateReferralRequest = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_INVOKE,
  });
  const response = await UpdatePatientRequestById(
    configUrlForReferral,
    payload
  );
  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_REFERRAL_DATA)
        );
        break;
      default:
        break;
    }
  }
};

const UpdateServiceRequest = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_INVOKE,
  });

  const response = await PutServiceRequest(configUrlForReferral, payload);

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_SERVICE_REQUEST)
        );
        break;
      default:
        break;
    }
  }
};
export const getServiceProviderById: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ServiceProviderDetailDispatchTypes>
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_INVOKE,
    });
    const response = await GetServiceProviderById(
      configUrlForReferral,
      referralId
    );
    dispatch({
      response,
      type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_SUCCESS,
    });
  };
};

export const SaveServiceProviderDetails = async (
  payload: ServiceProviderPayload,
  dispatch: Dispatch
) => {
  dispatch({
    type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_INVOKE,
  });
  const response = await PostServiceProviderDetails(
    configUrlForReferral,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_SERVICE_PROVIDER_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const UpdateServiceProvider = async (
  payload: ServiceProviderPayload,
  dispatch: Dispatch
) => {
  dispatch({
    type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_INVOKE,
  });
  const suffix = `/${payload.referralId}`;
  const response = await PutServiceProvider(
    configUrlForReferral,
    suffix,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_SUCCESS,
        });

        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_SERVICE_PROVIDER_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const SaveICd10RecordsCall = async (
  payload: SaveIcd10Records,
  dispatch: Dispatch
) => {
  dispatch({
    type: Icd10RecordsActionDispatchTypes.ICD_10_INVOKE,
  });
  const response = await SaveIcd10RecordsData(configUrlForReferral, payload);

  if (response && response.status) {
    DispatchResponse(
      dispatch,
      response,
      Icd10RecordsActionDispatchTypes.ICD_10_ERROR,
      Icd10RecordsActionDispatchTypes.ICD_10_SUCCESS,
      MessageType.SAVE_ICD10_DATA
    );
  }
};

export const updateICD10 = async (
  payload: SaveIcd10Records,
  dispatch: Dispatch
) => {
  dispatch({
    type: Icd10RecordsActionDispatchTypes.ICD_10_INVOKE,
  });
  const response = await UpdateICD10(configUrlForReferral, payload);

  if (response && response.status) {
    DispatchResponse(
      dispatch,
      response,
      Icd10RecordsActionDispatchTypes.ICD_10_ERROR,
      Icd10RecordsActionDispatchTypes.ICD_10_SUCCESS,
      MessageType.UPDATE_ICD10_DATA
    );
  }
};

export const saveReferralDetail: ActionCreator<
  ThunkAction<void, ApplicationState, Record<string, never>, Action<any>>
> = (payload: any, activeTab: number) => {
  return async (dispatch: Dispatch) => {
    await saveDispatch(payload, activeTab, dispatch);
  };
};

export const referralIntakeReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<SavePatientRequestActionDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_RESET,
    });
  };
};

export const saveReauthReferralDetail: ActionCreator<
  ThunkAction<void, ApplicationState, Record<string, never>, Action<any>>
> = (payload: any, activeTab: number) => {
  return async (dispatch: Dispatch) => {
    await saveReauthDispatch(payload, activeTab, dispatch);
  };
};

export const putFollowingPhysicianDetails = async (
  reAuthId: number,
  data: ReferralIntakePhysicianDetails,
  dispatch: Dispatch
) => {
  dispatch({
    type: PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE,
  });
  const suffix = `${reAuthId}`;
  const response = await PutFollowingPhysicianDetailsCall(
    configUrlForReferral,
    suffix,
    data
  );

  switch (response.status) {
    case 422:
    case 400:
      dispatch({
        response,
        type: PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR,
      });
      break;
    case 200:
    case 201:
      dispatch({
        response: response.data,
        type: PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS,
      });
      SnackbarUtils.success(
        ReferralResponseMessages(MessageType.UPDATE_FOLLOWING_PHYSICIAN_DETAILS)
      );
      break;
    default:
      break;
  }
};

export const postFollowingPhysicianDetails = async (
  data: ReferralIntakePhysicianDetails,
  dispatch: Dispatch
) => {
  dispatch({
    type: PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_INVOKE,
  });

  const response = await PostFollowingPhysicianDetailsCall(
    configUrlForReferral,
    data
  );

  switch (response.status) {
    case 422:
    case 400:
      dispatch({
        response,
        type: PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_ERROR,
      });
      break;
    case 200:
    case 201:
      dispatch({
        response: response.data,
        type: PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS,
      });
      SnackbarUtils.success(
        ReferralResponseMessages(MessageType.SAVE_FOLLOWING_PHYSICIAN_DETAILS)
      );
      break;
    default:
      break;
  }
};

export const saveDispatch = (
  payload: any,
  activeTab: number,
  dispatch: Dispatch
) => {
  switch (activeTab) {
    case PatientTabModule.REQUEST:
      return SavePatientRequestDetail(payload, dispatch);
    case PatientTabModule.ICD10:
      return SaveICd10RecordsCall(payload, dispatch);
    case PatientTabModule.ORDERING_PHYSICIAN:
      return postPhysicianDetails(payload, dispatch);
    case PatientTabModule.SERVICE_PROVIDER:
      return SaveServiceProviderDetails(payload, dispatch);
    case PatientTabModule.SERVICE_REQUEST:
      return SaveServiceRequest(payload, dispatch);
    case PatientTabModule.DOCUMENTS:
      return saveDocument(payload, dispatch);
    case PatientTabModule.REVIEW:
      return "";
  }
  return "";
};

export const saveReauthDispatch = (
  payload: any,
  activeTab: number,
  dispatch: Dispatch
) => {
  switch (activeTab) {
    case ReauthTabModule.REQUEST:
      return SaveReauthRequest(payload, dispatch);
    case ReauthTabModule.ICD10:
      return SaveICd10RecordsCall(payload, dispatch);
    case ReauthTabModule.FOLLOWING_PHYSICIAN:
      return postFollowingPhysicianDetails(payload, dispatch);
    case ReauthTabModule.SERVICE_REQUEST:
      return SaveReAuthServiceRequest(payload, dispatch);
    case ReauthTabModule.DOCUMENTS:
      return saveDocument(payload, dispatch);
    case ReauthTabModule.REVIEW:
      return "";
  }
  return "";
};

const SavePatientRequestDetail = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_INVOKE,
  });
  const response = await SavePatientRequest(configUrlForReferral, payload);

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_REFERRAL_DATA)
        );
        break;
      default:
        break;
    }
  }
};

const SaveServiceRequest = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_INVOKE,
  });
  const response = await PostServiceRequest(configUrlForReferral, payload);

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_SERVICE_REQUEST)
        );
        break;
      default:
        break;
    }
  }
};
const SaveReAuthServiceRequest = async (payload: any, dispatch: Dispatch) => {
  // copy same for Reauth. and update
  dispatch({
    type: ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_INVOKE,
  });
  const response = await ReAuthServiceRequestService(
    configUrlForReferral,
    payload
  ); //configUrl for workflow url to pass from here

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_SERVICE_REQUEST)
        );
        break;
      default:
        break;
    }
  }
};

// Referral Intake Physician details new wireframe

export const getPhysicianRecords: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralIntakePhysicianDetailsDispatchTypes
  >
> = (value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetPhysicianRecordsActionDispatchTypes.GET_PHYSICIAN_RECORDS_INVOKE,
    });

    const suffix = `?searchText=${value}`;

    const response = await GetPhysicianRecords(configUrlForReferral, suffix);

    dispatch({
      response,
      type: GetPhysicianRecordsActionDispatchTypes.GET_PHYSICIAN_RECORDS_SUCCESS,
    });
  };
};

export const resetPhysicianRecords = () => {
  return resetStates([
    GetPhysicianRecordsActionDispatchTypes.RESET_PHYSICIAN_RECORDS,
  ]);
};

const putPhysicianDetails = async (
  referralId: string,
  payload: ReferralIntakePhysicianDetails,
  dispatch: Dispatch
) => {
  dispatch({
    type: PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_INVOKE,
  });
  const suffix = `/${referralId}`;
  const response = await PutPhysicianDetails(
    configUrlForReferral,
    suffix,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_PHYSICIAN_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const postPhysicianDetails = async (
  payload: any,
  dispatch: Dispatch
) => {
  dispatch({
    type: PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_INVOKE,
  });
  const response = await PostPhysicianDetails(configUrlForReferral, payload);

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_PHYSICIAN_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const getPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralIntakePhysicianDetailsDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_INVOKE,
    });
    const suffix = `/${referralId}`;
    const response = await GetPhysicianDetails(configUrlForReferral, suffix);
    dispatch({
      response,
      type: GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_SUCCESS,
    });
  };
};

export const getFollowingPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReAuthPhysicianDetailsDispatchTypes
  >
> = (reAuthId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE,
    });

    const response = await GetFollowingPhysicianDetailsCall(
      configUrlForReferral,
      reAuthId
    );

    dispatch({
      response,
      type: GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS,
    });
  };
};
export const getDocuments: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DocumentReviewDispatchTypes
  >
> = (id: string, type: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_INVOKE,
    });

    const response = await GetDocuments(configUrlForReferral, id, type);

    dispatch({
      response,
      type: DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_SUCCESS,
    });
  };
};

export const getReauthRequestById: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReauthReferralRequestActionDispatchTypes>
  >
> = (reAuthId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_INVOKE,
    });
    const response = await GetReauthReferralRequestById(
      configUrlForReferral,
      reAuthId
    );
    dispatch({
      response,
      type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_SUCCESS,
    });
  };
};

export const postReferralSubmit: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReferralSubmitActionDispatchTypes>
  >
> = (
  payload: ReferralSubmitPayload,
  referralIntakeDetails?: boolean
) => {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_INVOKE,
      });
      const response = await ReferralSubmit(
        configUrlForReferral,
        payload
      );
  
      if (response && response.status) {
        switch (response.status) {
          case 422:
          case 400:
            dispatch({
              response,
              type: ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_ERROR,
            });
            break;
          case 200:
          case 201:
            dispatch({
              response: response.data,
              type: ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_SUCCESS,
            });
            if (referralIntakeDetails) {
              SnackbarUtils.success(
                ReferralResponseMessages(MessageType.SAVE_REFERRAL_INTAKE_DETAILS)
              );
            } else {
              SnackbarUtils.success(
                ReferralResponseMessages(MessageType.SAVE_AUTH_DETAILS)
              );
            }
            break;
          default:
            break;
        }
      }
    };
  };

const updateDocument = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_INVOKE,
  });
  const response = await UpdateDocumentsDetail(configUrlForReferral, payload);
  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_DOCUMENT_DATA)
        );
        break;
      default:
        break;
    }
  }
};

const saveDocument = async (payload: any, dispatch: Dispatch) => {
  dispatch({
    type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_INVOKE,
  });
  const response = await SaveDocumentsDetail(configUrlForReferral, payload);

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_DOCUMENT_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const getReferralDocuments: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralDocumentFileActionDispatchTypes
  >
> = (referralId: number, type: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE,
    });

    const response = await GetReferralDocuments(
      configUrlForReferral,
      referralId,
      type
    );

    dispatch({
      response: response || [],
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS,
    });
  };
};

export const updateDocumentFile: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReferralDocumentFileActionDispatchTypes>
  >
> = (payload: any, referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE,
    });
    const response = await UpdateDocumentsFile(
      configUrlForReferral,
      payload,
      referralId
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS,
          });
          if (response) {
            SnackbarUtils.success(
              ReferralResponseMessages(MessageType.UPDATE_DOCUMENT_FILE)
            );
          }
          break;
        default:
          break;
      }
    }
  };
};

export const saveDocumentFile: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReferralDocumentFileActionDispatchTypes>
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE,
    });
    const response = await SaveDocumentsFile(configUrlForReferral, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS,
          });
          if (response) {
            SnackbarUtils.success(
              ReferralResponseMessages(MessageType.SAVE_DOCUMENT_FILE)
            );
          }
          break;
        default:
          break;
      }
    }
  };
};

export const deleteDocumentFile: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReferralDocumentFileActionDispatchTypes>
  >
> = (blobId: any, userDeleted: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE,
    });
    const response = await DeleteDocumentsFile(
      configUrlForReferral,
      blobId,
      userDeleted
    );
    dispatch({
      response,
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS,
    });
    if (response) {
      SnackbarUtils.success(
        ReferralResponseMessages(MessageType.DELETE_DOCUMENT_FILE)
      );
    }
  };
};

export const SaveReauthRequest = async (
  payload: ReAuthReferralRequest,
  dispatch: Dispatch
) => {
  dispatch({
    type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_INVOKE,
  });
  const response = await SaveReauthReferralRequest(
    configUrlForReferral,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_REFERRAL_DATA)
        );
        break;
      default:
        break;
    }
  }
};

const UpdateReauthRequest = async (
  payload: ReAuthReferralRequest,
  dispatch: Dispatch
) => {
  dispatch({
    type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_INVOKE,
  });
  const response = await UpdateReauthReferralRequest(
    configUrlForReferral,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_REFERRAL_DATA)
        );
        break;
      default:
        break;
    }
  }
};

export const getReauthServiceRequestsRecords: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Icd10RecordDispatchTypes
  >
> = (reAuthId: number, startOfCareDate?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_INVOKE,
    });
    const response = await GetServiceRequestRecords(
      configUrlForReferral,
      reAuthId,
      startOfCareDate
    );
    dispatch({
      response,
      type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_SUCCESS,
    });
  };
};

const UpdateReauthServiceRequestRecord = async (
  payload: ReAuthServiceRequest,
  dispatch: Dispatch
) => {
  dispatch({
    type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_INVOKE,
  });
  const response = await UpdateReauthServiceRequest(
    configUrlForReferral,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.UPDATE_SERVICE_REQUEST)
        );
        break;
      default:
        break;
    }
  }
};
//
export const PutMyPatientListRecord: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<UpdateMyPatientListDispatchTypes>
  >
> = (assignedToStaffId: number, payload: AssignedStaff) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MyPatientListActionDispatchTypes.MY_PATIENT_LIST_INVOKE,
    });
    const response = await PutMyPatientList(
      configUrlForReferral,
      assignedToStaffId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: MyPatientListActionDispatchTypes.MY_PATIENT_LIST_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: MyPatientListActionDispatchTypes.MY_PATIENT_LIST_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.UPDATE_INCOMPLETE_REQUEST)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const PutIncompletePatientListRecord: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AssignStaffIncompletePatientDispatchTypes>
  >
> = (assignedUserId: number, payload: AssignStaffIncompleteRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AssignStaffIncompletePatientActionDispatchTypes.ASSIGN_STAFF_INCOMPLETE_PATIENT_INVOKE,
    });

    const response = await PutIncompletePatientList(
      configUrlForReferral,
      assignedUserId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: AssignStaffIncompletePatientActionDispatchTypes.ASSIGN_STAFF_INCOMPLETE_PATIENT_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: AssignStaffIncompletePatientActionDispatchTypes.ASSIGN_STAFF_INCOMPLETE_PATIENT_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.UPDATE_INCOMPLETE_REQUEST)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getReauthReferralSubmit: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReauthReferralSubmitActionDispatchTypes>
  >
> = (reAuthId: string, payload: ReAuthSubmitPayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_INVOKE,
    });
    const response = await GetReauthReferralSubmit(
      configUrlForReferral,
      reAuthId,
      payload
    );
    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.REAUTH_DETAILS_SUCCESS)
          );
          break;
        default:
          break;
      }
    }
  };
};
export const getReauthReferralSubmitReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReauthReferralSubmitActionDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_RESET,
    });
  };
};
export const checkEligibility: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<EligibilityCheckActionDispatchTypes>
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_INVOKE,
    });
    const response = await CheckEligibility(
      configUrlForCheckEligibility,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const eligibilityReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<EligibilityCheckActionDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
    });
  };
};

export const deleteReauthDocumentFile: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ReferralDocumentFileActionDispatchTypes>
  >
> = (blobId: any, userDeleted: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE,
    });
    const response = await DeleteDocumentsFile(
      configUrlForReferral,
      blobId,
      userDeleted
    );
    dispatch({
      response,
      type: ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS,
    });
    if (response) {
      SnackbarUtils.success(
        ReferralResponseMessages(MessageType.DELETE_DOCUMENT_FILE)
      );
    }
  };
};
const initialState: MyPatientResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [],
  },
};
export const postMyPatientList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    MyPatientDispatchTypes
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MyPatientActionDispatchTypes.POST_PATIENTLIST_INVOKE,
    });
    const response = get404InitialData(
      await PostMyPatientList(configUrlForReferral, payload),
      initialState
    );
    dispatch({
      response: response.data,
      type: MyPatientActionDispatchTypes.POST_PATIENTLIST_SUCCESS,
    });
  };
};

export const postIncompletePatientList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    IncompletePatientDispatchTypes
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IncompletePatientActionDispatchTypes.POST_INCOMPLETE_PATIENT_INVOKE,
    });
    const response = get404InitialData(
      await PostIncompletePatientList(configUrlForReferral, payload),
      initialState
    );
    dispatch({
      response: response.data,
      type: IncompletePatientActionDispatchTypes.POST_INCOMPLETE_PATIENT_SUCCESS,
    });
  };
};

export const documentDownload: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<DocumentDownloadActionDispatchTypes>
  >
> = (blobId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_INVOKE,
    });

    const response = await GetDocumentDownload(
      configUrlForBlob,
      blobId,
      "file"
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 500:
        case 404:
          dispatch({
            response,
            type: DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: {
              blob: response.data,
              headers: response.headers,
            },
            type: DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const excelDownload: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ExcelDownloadActionDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_INVOKE,
    });
    const response = await GetExcelDownload(
      configUrlForUserManagement,
      "ExcelFile",
      "excel"
    );
    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 500:
        case 404:
          dispatch({
            response,
            type: ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const excelDownloadReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ExcelDownloadActionDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_RESET,
    });
  };
};

export const postGenerateAuthTemplate: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GenerateAuthTemplateActionDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GenerateAuthTemplateDispatchTypes.GENERATE_AUTH_TEMPLATE_INVOKE,
    });

    const response = await PostGenerateAuthTemplate(
      configUrlForReferral,
      referralId
    );
    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: GenerateAuthTemplateDispatchTypes.GENERATE_AUTH_TEMPLATE_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: GenerateAuthTemplateDispatchTypes.GENERATE_AUTH_TEMPLATE_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.AUTH_TEMPLATE_GENERATE_SUCCESS)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const saveAttachLetter: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AttachLetterActionDispatchTypes>
  >
> = (payload: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AttachLetterDispatchTypes.ATTACH_LETTER_INVOKE,
    });
    const response = await SaveAttachLetter(configUrlForReferral, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: AttachLetterDispatchTypes.ATTACH_LETTER_ERROR,
          });
          SnackbarUtils.error(
            ReferralResponseMessages(MessageType.ATTACH_LETTER_ERROR)
          );
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: AttachLetterDispatchTypes.ATTACH_LETTER_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.SAVE_ATTACH_LETTER)
          );
          break;
        default:
          break;
      }
    }
  };
};

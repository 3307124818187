import { Reducer } from "redux";

import { neverReached } from "../utils";
import { ReAuthReferralSourceActionDispatchTypes } from "../constants/Master";
import { ReAuthReferralSourceMasterResponse } from "../models/Api/Master";
import { ReAuthReferralSourceDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: ReAuthReferralSourceMasterResponse = {
  loading: false,
  response: [],
};

const ReAuthReferralSourceReducer: Reducer<
  ReAuthReferralSourceMasterResponse,
  ReAuthReferralSourceDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthReferralSourceActionDispatchTypes.RE_AUTH_REFERRAL_SOURCE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthReferralSourceActionDispatchTypes.RE_AUTH_REFERRAL_SOURCE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    default:
      neverReached(); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

export default ReAuthReferralSourceReducer;

import { Grid } from "@mui/material";
import { ReactNode } from "react";

import UnAuthorizedPage from "../components/errorPages/401";
import { Module } from "../models/Modules";
import { getValue } from "../utils";

const AuthGuard = ({
  children,
  authenticated,
  userModuleResponse,
}: {
  children: ReactNode;
  authenticated: boolean;
  userModuleResponse: Module[];
}) => {
  return authenticated === true ? (
    <Grid>{children}</Grid>
  ) : (
    <>
      {getValue(userModuleResponse, "[0].moduleId", 0) !== -1 ? (
        <UnAuthorizedPage></UnAuthorizedPage>
      ) : (
        ""
      )}
    </>
  );
};

export default AuthGuard;

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  UseFormGetValues,
  UseFormTrigger,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import { isEmpty } from "lodash";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  DateFormControl,
  HorizontalDivider,
  RequestContainer,
  RequestDetailsContainer,
  RequestDetailsItems,
  RequestHeader,
} from "../../ReferralIntake/styles/requestStyle";
import { rules } from "../../../../../utils/validation/Validation";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import {
  ReAuthReferralRequest,
  ReceivedMethods,
  UrgencyStatusMaster,
} from "../../../../../models/Patient";
import {
  ReceivedMethodMasterResponse,
  UrgencyStatusMasterResponse,
} from "../../../../../models/Api/Patient";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import {
  fromProviderPortal,
  getValue,
  length,
  isExpedited,
} from "../../../../../utils";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { ServiceRequestType } from "../../../../../constants/Master";

export interface PropsFromState {
  control: any;
  receivedMethodData: ReceivedMethodMasterResponse;
  setValue: (id: any, value: any, boolean: any) => void;
  urgencyStatus: UrgencyStatusMasterResponse;
  values: ReAuthReferralRequest;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: (name: string) => void;
  patientRequestDetail: ReAuthReferralRequest | any;
}

export interface PropsFromDispatch {
  getReceivedMethodDetails: (serviceRequestTypeId: number) => void;
  getUrgencyStatusMasterResp: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ReauthRequestDetail: React.FC<AllProps> = ({
  control,
  getReceivedMethodDetails,
  getUrgencyStatusMasterResp,
  receivedMethodData,
  setValue,
  urgencyStatus,
  getValues,
  clearErrors,
  watch,
  trigger,
  values,
  patientRequestDetail,
}: AllProps) => {
  const [urgencyStatusList, setUrgencyStatusList] = useState<
    UrgencyStatusMaster[] | any[]
  >([]);
  const [receivedMethod, setReceivedMethod] = useState<
    ReceivedMethods[] | any[]
  >([]);
  const { loading: loadingUrgencyStatus, response: urgencyStatusResponse } =
    urgencyStatus;
  const { loading: loadingReceivedMethod, response: receivedMethodResponse } =
    receivedMethodData;

  useWatch({
    name: "referralRequest",
    control,
  });

  useEffect(() => {
    if (!loadingUrgencyStatus) {
      getUrgencyStatusMasterResp();
    }
    if (!loadingReceivedMethod) {
      getReceivedMethodDetails(ServiceRequestType.RE_AUTH_ID);
    }
  }, []);

  useEffect(() => {
    const urgencyStatus =
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      urgencyStatusResponse.map((item) => {
        return {
          label: item.urgencyStatus,
          value: item.urgencyId,
        };
      });
    setUrgencyStatusList(urgencyStatus || []);
    setReceivedMethod(receivedMethodResponse || []);
  }, [urgencyStatusResponse, receivedMethodResponse]);

  useEffect(() => {
    if (
      getValue(patientRequestDetail, "referralRequest.urgencyId", 0) === null &&
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      !isEmpty(getValue(urgencyStatusResponse, "[0].urgencyStatus", ""))
    ) {
      const { urgencyId } = getValue(urgencyStatusResponse, "[0]");
      setValue("referralRequest.urgencyId", urgencyId, true);
    }
  }, [urgencyStatusResponse, patientRequestDetail]);

  const onChange = (e: any) => {
    setValue(e.target.name, e.target.value, true);
    const recMethod =
      receivedMethod &&
      length(receivedMethod) &&
      receivedMethod.filter((item) => {
        return item.receivedMethodId == e.target.value;
      });
    setValue(
      "referralRequest.receivedMethodName",
      getValue(recMethod, "[0].receivedMethodName", ""),
      true
    );
  };

  return (
    <Grid sx={RequestContainer}>
      <Typography
        variant="h6"
        fontWeight={fontWeight.Weight[5]}
        color={colors.fonts[20]}
        sx={RequestHeader}
      >
        REQUEST DETAILS:
      </Typography>
      <Divider orientation="horizontal" flexItem sx={HorizontalDivider} />
      <Grid container spacing={"3rem"} sx={RequestDetailsContainer}>
        <Grid item xs={2.3} sx={[displayFlex, RequestDetailsItems]}>
          <FormControl variant="standard" sx={DateFormControl}>
            <DatePicker
              name="referralRequest.receivedDateTime"
              control={control}
              label={"Date Request Received:"}
              helper={rules.dateTimeRequired}
              allowFutureDate={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2.7} sx={[displayFlex, RequestDetailsItems]}>
          <FormControl variant="standard">
            <FormInputTimePicker
              name="referralRequest.receivedDateTime"
              control={control}
              setValue={setValue}
              label="Time Request Received:"
              helper={rules.timeRequired}
              value={getValues("referralRequest.receivedDateTime")}
              watch={watch}
              trigger={trigger}
              clearErrors={clearErrors}
              allowFutureTime={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.1} sx={[displayFlex, RequestDetailsItems]}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={1.8} sx={[displayFlex, RequestDetailsItems]}>
          <FormControl variant="standard" fullWidth>
            <FormInputDropdown
              name="referralRequest.urgencyId"
              control={control}
              label="Choose Urgency Status:"
              helper={rules.urgencyStatus}
              list={urgencyStatusList}
              InputStyle={isExpedited(getValues("referralRequest.urgencyId"))}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.1} sx={[displayFlex, RequestDetailsItems]}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={4.2} sx={RequestDetailsItems}>
          <InputLabel required shrink={true} sx={displayFlex}>
            <Typography
              variant="h5"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[2]}
            >
              Choose Received Methods
            </Typography>
          </InputLabel>
          <Grid container>
            <Controller
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <>
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    row={true}
                    {...field}
                    onChange={onChange}
                  >
                    {receivedMethod &&
                      length(receivedMethod) &&
                      receivedMethod
                        .filter(
                          (singleOption: ReceivedMethods) =>
                            !singleOption.isIntegrationMethod ||
                            fromProviderPortal(
                              singleOption,
                              values,
                              "referralRequest.receivedMethodId"
                            )
                        )
                        .map((singleOption: ReceivedMethods) => (
                          <Grid
                            item
                            xs={2.4}
                            key={singleOption.receivedMethodId}
                            pr={"1rem"}
                          >
                            <FormControlLabel
                              value={Number(singleOption.receivedMethodId)}
                              label={
                                <Box sx={flexAlignCentre} pr={"0.5rem"}>
                                  <Typography
                                    variant="subtitle2"
                                    color={colors.black[2]}
                                    fontWeight={fontWeight.Weight[5]}
                                  >
                                    {singleOption.receivedMethodName}
                                  </Typography>
                                </Box>
                              }
                              control={<Radio />}
                            />
                          </Grid>
                        ))}
                  </RadioGroup>
                  <FormHelperText sx={{ mt: 0 }}>
                    {error ? error.message : null}
                  </FormHelperText>
                </>
              )}
              name={`referralRequest.receivedMethodId`}
              control={control}
              rules={rules.receivedMethodId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReauthRequestDetail;

import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { GetPhysicianDetails, GetNPPESDetailsData } from "../services/PhysicianDetails";
import { ApplicationState } from "../store/ApplicationState";
import { AuthOrderingPhysicianDetailsDispatchTypes, ReferralIntakePhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import { GetPhysicianDetailsActionDispatchTypes, GetNPPESDataActionDispatchTypes } from "../constants/PhysicianDetails";
import { configUrlForReferral } from "../constants/Config";
// Referral Intake Physician details new wireframe

export const getPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralIntakePhysicianDetailsDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_INVOKE,
    });
    const suffix = `/${referralId}`;
    const response = await GetPhysicianDetails(configUrlForReferral, suffix);

    dispatch({
      response,
      type: GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_SUCCESS,
    });
  };
};

export const getNPPEsDetailsData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthOrderingPhysicianDetailsDispatchTypes
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_INVOKE,
    });

    const response = await GetNPPESDetailsData(
      configUrlForReferral,
      payload
    );

    dispatch({
      response,
      type: GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_SUCCESS,
    });
  };
};

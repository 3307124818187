import { stateAbbreviations } from "../constants/StateAbbreviations";

export const matchState = (input: string): any => {
  if (input) {
    const normalizedInput = input.trim().toLowerCase();

    // Direct match for abbreviations
    if (stateAbbreviations[normalizedInput.toUpperCase()]) {
      return stateAbbreviations[normalizedInput.toUpperCase()];
    }

    // Match for full or partial state names
    const matchedState = Object.keys(stateAbbreviations).find(
      (state) =>
        state.toLowerCase().startsWith(normalizedInput)
    );

    return matchedState ? stateAbbreviations[matchedState] : input;
  }
  return null;
};

import { Reducer } from "redux";

import { RemoveStaffingPatientResponse } from "../models/Api/Staffing";
import { RemoveStaffingPatientDispatchTypes } from "../models/DispatchTypes/Staffing";
import { RemoveStaffingPatientActionDispatchTypes } from "../constants/Staffing";

export const initialState: RemoveStaffingPatientResponse = {
  loading: false,
  response: {
    searchResponse: [],
    totalRows: 0
  },
};

const RemoveStaffingPatientReducer: Reducer<
  RemoveStaffingPatientResponse,
  RemoveStaffingPatientDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  }
  return state;
};

export default RemoveStaffingPatientReducer;

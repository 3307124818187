import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import NotesTable from "../components/NotesTable";
import { DisciplineNoteDispatchTypes } from "../../../../../models/DispatchTypes/Notes";
import {
  getDisciplineTemplateNotesMaster,
  getNotesCategoryMaster,
  GetNotesSectionById,
} from "../../../../../actions/Notes";
import { UserDetailsPayload } from "../../../../../models/User";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  disciplineNoteListState,
  disciplineTemplateNotesState,
  loggedInUserState,
  NotesTemplateJsonState,
  adminCategoryNotesMasterState,
}: ApplicationState) => ({
  notesData: disciplineNoteListState,
  DisciplineTemplateData: disciplineTemplateNotesState,
  NotesDataById: NotesTemplateJsonState,
  categorydata: adminCategoryNotesMasterState,
  user: loggedInUserState.response as UserDetailsPayload,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<DisciplineNoteDispatchTypes>
  >
) => {
  return {
    getDisciplineTemplateNotesMaster: (categoryId: number) => {
      dispatch(getDisciplineTemplateNotesMaster(categoryId));
    },
    GetNotesSectionById: (templateId: number) => {
      dispatch(GetNotesSectionById(templateId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getNotesCategoryMaster: () => {
      dispatch(getNotesCategoryMaster());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesTable);

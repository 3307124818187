import API from "./api";
import { PatientInformationView, SaveInformation } from "../models/Patient";

export const GetPatientInformation = (
  configUrl: any,
  suffix: number,
  type: number
) => API.get(`${configUrl}api/patients/patientdetail/${suffix}/${type}`);

export const SaveInformationReceived = (
  configUrl: any,
  data: SaveInformation
) => API.post(`${configUrl}api/patients/Information`, data);

export const UpdatePatientHeader = (
  configUrl: any,
  data: PatientInformationView
) => API.putApi(`${configUrl}api/patients`, data);

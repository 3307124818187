export enum InformationReceivedActionDispatchTypes {
  HEALTHPLAN_INVOKE = "HEALTHPLAN_INVOKE",
  HEALTHPLAN_SUCCESS = "HEALTHPLAN_SUCCESS",
}

export enum PatientReferralInfoActionDispatchTypes {
  REFERRAL_INFO_INVOKE = "REFERRAL_INFO_INVOKE",
  REFERRAL_INFO_SUCCESS = "REFERRAL_INFO_SUCCESS",
}

export enum ConfirmationQuestion {
  QUESTION = "already has an active/incomplete referral, are you sure you want to create a new referral ?",
}

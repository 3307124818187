import { PatientTab, ReferralTab } from "../constants/AllPatientRecord";
import API from "./api";
import {
  MyPatientSearch,
  ReAuthReferralRequest,
  SaveIcd10Records,
} from "../models/Patient";
import { GetQueryString } from "../utils";
import {
  ReAuthSubmitPayload,
  ReferralSubmitPayload,
} from "../models/Api/AllPatientRecord";
import { AllPendingUnassignStaff } from "../models/Filter";

export const GetAllPatientRecord = (configUrl: string, payload: any) =>
  API.get(`${configUrl}api/patients/patientRecords?${GetQueryString(payload)}`);

export const GetpatientTab = (path?: string) => {
  if (path) {
    return ReferralTab;
  } else {
    return PatientTab;
  }
};

export const GetPatientDetailById = (
  configUrl: any,
  referralId: number,
  patientId: number
) =>
  API.get(
    `${configUrl}api/referral/ReferralOverview/${patientId}/${referralId}`
  );

export const GetIcd10Records = (configUrl: any, id: number, typeId: number) =>
  API.get(`${configUrl}api/icdcodes/${id}/${typeId}`);
export const SaveIcd10RecordsData = (
  configUrl: any,
  payload: SaveIcd10Records
) => API.postApi(`${configUrl}api/icdcodes`, payload);

export const UpdateICD10 = (configUrl: string, payload: any) =>
  API.putApi(`${configUrl}api/icdcodes/${payload.id}`, payload);

export const GetPatientRequestById = (
  configUrl: any,
  referralId: string,
  patientId: string
) => API.get(`${configUrl}api/requestintake/${referralId}/${patientId}`);

export const UpdatePatientRequestById = (configUrl: any, payload: any) =>
  API.putApi(
    `${configUrl}api/requestintake/${payload.referralDetail.referralId}`,
    payload
  );

export const SavePatientRequest = (configUrl: any, payload: any) =>
  API.postApi(`${configUrl}api/requestintake`, payload);

export const ReferralSubmit = (
  configUrl: string,
  payload: ReferralSubmitPayload
) =>
  API.postApi(`${configUrl}api/referral/Submit?${GetQueryString(payload)}`, {});

export const GetDocuments = (configUrl: string, id: string, type: number) =>
  API.get(`${configUrl}api/documents/${id}/${type}`);

export const UpdateDocumentsDetail = (configUrl: string, payload: any) =>
  API.putApi(`${configUrl}api/documents?id=${payload.id}`, payload);

export const SaveDocumentsDetail = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/documents`, payload);

export const GetReferralDocuments = (
  configUrl: string,
  referralId: number,
  type: number
) => API.get(`${configUrl}api/documents/Document/${referralId}/${type}`);

export const UpdateDocumentsFile = (
  configUrl: string,
  payload: any,
  referralId: string
) =>
  API.putApi(
    `${configUrl}api/documents/Document?referralId=${referralId}`,
    payload
  );

export const SaveDocumentsFile = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/documents/Document`, payload);

export const GetReauthReferralRequestById = (
  configUrl: any,
  reAuthId: number
) => API.get(`${configUrl}api/requestdetail/${reAuthId}`);

export const SaveReauthReferralRequest = (
  configUrl: string,
  payload: ReAuthReferralRequest
) => API.postApi(`${configUrl}api/requestdetail`, payload);

export const UpdateReauthReferralRequest = (configUrl: string, payload: any) =>
  API.putApi(
    `${configUrl}api/requestdetail/${payload.referralRequest.serviceRequestId}`,
    payload
  );

export const DeleteDocumentsFile = (
  configUrl: string,
  blobId: any,
  userDeleted: string
) =>
  API.delete(
    `${configUrl}api/documents/Document?blobId=${blobId}&userDeleted=${userDeleted}`
  );

export const UpdateReauthServiceRequest = (configUrl: string, payload: any) =>
  API.putApi(
    `${configUrl}api/servicerequest/ReAuth/${payload.serviceRequestId}`,
    payload
  );

export const SaveReauthServiceRequest = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/servicerequest/ReAuth`, payload);

export const GetReauthReferralSubmit = (
  configUrl: string,
  reAuthId: string,
  payload: ReAuthSubmitPayload
) =>
  API.postApi(
    `${configUrl}api/referral/SubmitReAuth/${reAuthId}?${GetQueryString(
      payload
    )}`,
    {}
  );

export const GetServiceRequestRecords = (
  configUrl: any,
  reAuthId: number,
  startOfCareDate?: string
) => {
  if (startOfCareDate === undefined) {
    return API.get(`${configUrl}api/servicerequest/ReAuth/${reAuthId}`);
  } else {
    return API.get(
      `${configUrl}api/servicerequest/ReAuth/${reAuthId}?startOfCareDate=${startOfCareDate}`
    );
  }
};

export const CheckEligibility = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}Eligibility/Check`, payload);

export const PostMyPatientList = (
  configUrl: string,
  payload: MyPatientSearch
) => API.postApi(`${configUrl}api/patients/searchPatientRecords`, payload);

export const PostIncompletePatientList = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/patients/getIncompleteRequests`, payload);

export const PutMyPatientList = (
  configUrl: string,
  assignedToStaffId: number,
  payload: any
) => {
  return API.putApi(
    `${configUrl}api/patients/assignStaff?assignedToStaffId=${assignedToStaffId}`,
    payload
  );
};

export const PutIncompletePatientList = (
  configUrl: string,
  assignedUserId: number,
  payload: any
) => {
  return API.putApi(
    `${configUrl}api/patients/assignStaffToIncompleteRequest?assignedUserId=${assignedUserId}`,
    payload
  );
};

export const GetDocumentDownload = (
  configUrl: string,
  blobId: number,
  type?: string
) => API.get(`${configUrl}api/Blob/GetBlobById/${blobId}`, type);

export const GetExcelDownload = (
  configUrl: string,
  path: string,
  type: string
) => API.get(`${configUrl}api/users/${path}`, type);

export const PostGenerateAuthTemplate = (
  configUrl: string,
  referralId: string
) => API.postApi(`${configUrl}api/documents/GetAuthDocument/${referralId}`, {});

export const PostAllPendingUnassignStaff = (configUrl: string, payload: AllPendingUnassignStaff) =>
  API.postApi(`${configUrl}api/patients/UnassignStaff`, payload);

export const SaveAttachLetter = (configUrl: string, payload: FormData) =>
  API.postApi(`${configUrl}api/documents/AttachLetter`, payload);
import { StaffingQueue } from "../models/Filter";
import { RemoveStaffingPatient, StaffingAssignStaff, StaffingUnassignStaff } from "../models/Staffing";
import API from "./api";

export const PostStaffingList = (configUrl: string, payload: StaffingQueue) =>
  API.postApi(`${configUrl}api/staffer/searchStaffingQueueList`, payload);

export const PostStaffingAssignStaff = (configUrl: string, payload: StaffingAssignStaff) =>
  API.postApi(`${configUrl}api/staffer/assignStaff`, payload);

export const PostStaffingUnassignStaff = (configUrl: string, payload: StaffingUnassignStaff) =>
  API.postApi(`${configUrl}api/staffer/UnassignStaff`, payload);

export const PostRemoveStaffingPatient = (configUrl: string, payload: RemoveStaffingPatient) =>
  API.postApi(`${configUrl}api/staffer/removeStaffer`, payload);

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import DocumentSendFax from "../components/DocumentSendFax";
import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getFaxFormMaster,
  getFaxFormOptionMaster,
} from "../../../../../actions/Master";
import { FaxFormDispatchTypes } from "../../../../../models/DispatchTypes/Master";

const mapStateToProps = ({
  masterFaxFormState,
  masterFaxFormOptionState,
  patientInformationViewState,
}: ApplicationState) => ({
  faxFormList: masterFaxFormState.response,
  faxFormOptionMaster: masterFaxFormOptionState.response,
  patientData: patientInformationViewState.response,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<FaxFormDispatchTypes>
  >
) => {
  return {
    getFaxFormMaster: () => {
      dispatch(getFaxFormMaster());
    },
    getFaxFormOptionMaster: (formId: number) => {
      dispatch(getFaxFormOptionMaster(formId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSendFax);

import { Reducer } from "redux";

import { PatientInformationResponse } from "../models/Api/Patient";
import { PatientInformationDispatchTypes } from "../models/DispatchTypes/Patient";
import { PatientInformationActionDispatchTypes } from "../constants/Patient";

const initialState: PatientInformationResponse = {
  loading: false,
  response: {
    dateRequestReceived: "",
    timeRequestReceived: "",
    urgencyStatus: "",
    isPatientHomebound: "",
    patientEntity: {
      patientId: -1,
      referralId: -1,
      pictureBlobId: null,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dateOfBirth: null,
      dateCreated: "",
      userCreated: null,
    },
    addressEntity: {
      addressId: -1,
      streetName: "",
      city: "",
      patientState: "",
      zipCode: "",
      phoneNo: "",
    },
    referralEntity: {
      receivedDateTime: null,
      receivedMethod: -1,
      receivedReferralSystem: null,
      isNavihealth: false,
    },
  },
};

const PatientInformationReducer: Reducer<
  PatientInformationResponse,
  PatientInformationDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientInformationActionDispatchTypes.PATIENT_INFORMATION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientInformationActionDispatchTypes.PATIENT_INFORMATION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientInformationReducer;

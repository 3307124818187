import { Reducer } from "redux";

import { AssignStaffIncompletePatientActionDispatchTypes } from "../constants/Patient";
import { AssignStaffIncompletePatientDispatchTypes } from "../models/DispatchTypes/Patient";
import { IncompletePatientSearchResponse } from "../models/Api/Patient";

const initialState: IncompletePatientSearchResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [
      {
        serviceRequestId: -1,
        patientId: -1,
        referralId: -1,
        fullName: "",
        urgencyStatus: "",
        requestDateTime: "",
        healthPlanName: "",
        assignedUserId: -1,
        assignedUserFullName: "",
        serviceRequestTypeName: "",
        patientState: "",
        facilityTypeName: "",
        createdBy: "",
      },
    ],
  },
};

const AssignStaffIncompleteRequestReducer: Reducer<
  IncompletePatientSearchResponse,
  AssignStaffIncompletePatientDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AssignStaffIncompletePatientActionDispatchTypes.ASSIGN_STAFF_INCOMPLETE_PATIENT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AssignStaffIncompletePatientActionDispatchTypes.ASSIGN_STAFF_INCOMPLETE_PATIENT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AssignStaffIncompleteRequestReducer;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { PatientDetailActionDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import ReauthReferralIntake from "../components";
import {
  getDocuments,
  getFollowingPhysicianDetails,
  getICd10Records,
  getReauthReferralSubmit,
  getReauthReferralSubmitReset,
  getReauthRequestById,
  getReauthServiceRequestsRecords,
  resetPhysicianRecords,
  saveReauthReferralDetail,
  updateReauthReferralDetail,
} from "../../../../../actions/AllPatientRecord";
import { getDocumentType } from "../../../../../actions/Master";
import { getPatients } from "../../../../../actions/PatientInformation";
import { getPhysicianDetails } from "../../../../../actions/PhysicianDetails";
import { ReAuthServiceRequestResponse } from "../../../../../models/Api/Service";
import { UserDetailsPayload } from "../../../../../models/User";
import { ReAuthSubmitPayload } from "../../../../../models/Api/AllPatientRecord";

const mapStateToProps = ({
  allPatientIntakeTabState,
  documentReviewState,
  followingPhysicianDetailsState,
  icd10RecordState,
  icdCodeTypeState,
  loggedInUserState,
  patientInformationViewState,
  reauthPatientDetailsState,
  reauthReferralIntakeRequestState,
  reAuthServiceRequestState,
  reAuthSubmitState,
  referralDocumentState,
  referralIntakePhysicianDetailsState,
  saveUpdatePatientRequestState,
  documentTypeState,
  updatePatientHeaderState,
}: ApplicationState) => ({
  documentReview: documentReviewState,
  followingPhysicianDetailsState: followingPhysicianDetailsState,
  icd10Records: icd10RecordState,
  icdCodeDataType: icdCodeTypeState,
  patientHeaderData: patientInformationViewState,
  patientRequestData: reauthReferralIntakeRequestState,
  reauthPatientDetails: reauthPatientDetailsState,
  reAuthServiceRequestData:
    reAuthServiceRequestState as ReAuthServiceRequestResponse,
  reAuthSubmitData: reAuthSubmitState,
  referralDocumentUpdateData: referralDocumentState,
  referralIntakePhysicianState: referralIntakePhysicianDetailsState,
  saveUpdatePatientData: saveUpdatePatientRequestState,
  tabList: allPatientIntakeTabState,
  user: loggedInUserState.response as UserDetailsPayload,
  documentTypeResponse: documentTypeState,
  updatePatientHeaderData: updatePatientHeaderState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<PatientDetailActionDispatchTypes>
  >
) => {
  return {
    getReauthIcd10RecordDetails: (id: number, typeId: number) => {
      dispatch(getICd10Records(id, typeId));
    },
    getReauthRequestById: (reAuthId: number) => {
      dispatch(getReauthRequestById(reAuthId));
    },
    updateReauthReferralDetail: (payload: any, activeTab: number) => {
      dispatch(updateReauthReferralDetail(payload, activeTab));
    },
    getReauthDocumentReviewData: (id: string, type: number) => {
      dispatch(getDocuments(id, type));
    },
    getFollowingPhysicianDetails: (reAuthId: number) => {
      dispatch(getFollowingPhysicianDetails(reAuthId));
    },
    getPhysicianDetails: (referralId: number) => {
      dispatch(getPhysicianDetails(referralId));
    },
    saveReauthReferralDetail: (payload: any, activeTab: number) => {
      dispatch(saveReauthReferralDetail(payload, activeTab));
    },
    getReauthReferralSubmit: (reAuthId: string, payload: ReAuthSubmitPayload) => {
      dispatch(getReauthReferralSubmit(reAuthId, payload));
    },
    getReAuthServiceRequestData: (
      reAuthId: string,
      startOfCareDate?: string
    ) => {
      dispatch(getReauthServiceRequestsRecords(reAuthId, startOfCareDate));
    },
    getPatient: (id: number, referralId: number) => {
      dispatch(getPatients(id, referralId));
    },
    getReauthReferralSubmitReset: () => {
      dispatch(getReauthReferralSubmitReset());
    },
    resetPhysicianRecords: () => {
      dispatch(resetPhysicianRecords());
    },
    getDocumentType: () => {
      dispatch(getDocumentType());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReauthReferralIntake);

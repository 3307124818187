import { Reducer } from "redux";

import { ReauthReferralRequestResponse } from "../models/Api/AllPatientRecord";
import { ReAuthReferralRequestDispatchType } from "../constants/AllPatientRecord";
import { ReauthReferralRequestActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";

const initialState: ReauthReferralRequestResponse = {
  loading: false,
  response: {
    referralRequest: {
      serviceRequestId: 0,
      referralId: 0,
      receivedDateTime: "",
      urgencyId: null,
      userCreated: null,
      patientEligibilityId: 0,
      receivedMethodId: null,
    },
    requester: {
      serviceRequestId: 0,
      facilityTypeId: null,
      requesterName: "",
      requesterExtension: "",
      requesterPhoneNo: "",
      requesterEmail: null,
      requesterFax: "",
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      requesterState: null,
      zipCode: null,
      facilityTypeName: "",
    },
  },
  error: null,
};

const ReauthReferralIntakeRequest: Reducer<
  ReauthReferralRequestResponse,
  ReauthReferralRequestActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ReauthReferralIntakeRequest;

import { connect } from "react-redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import ReAuthReviewICD10AndPhysicianDetails from "../components/ReAuthReviewICD10AndPhysicianDetails";

const mapStateToProps = ({ reAuthReviewState }: ApplicationState) => ({
  reAuthReviewDetails: reAuthReviewState,
});

export default connect(mapStateToProps)(ReAuthReviewICD10AndPhysicianDetails);

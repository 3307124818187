import { connect } from "react-redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import ReviewDisciplineRequest from "../components/ReviewDisciplineRequest";

const mapStateToProps = ({ reviewState }: ApplicationState) => ({
  disciplineRequest: reviewState,
});

export default connect(mapStateToProps)(ReviewDisciplineRequest);

import { Reducer } from "redux";

import { AuthorizationTypeMasterResponse } from "../models/Api/Master";
import { AuthorizationTypeDispatchTypes } from "../models/DispatchTypes/Master";
import { AuthorizationTypeMasterActionDispatchTypes } from "../constants/Master";

export const initialState: AuthorizationTypeMasterResponse = {
  loading: false,
  response: [],
};

const AuthorizationTypeMasterReducer: Reducer<
  AuthorizationTypeMasterResponse,
  AuthorizationTypeDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthorizationTypeMasterActionDispatchTypes.AUTHORIZATION_TYPE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthorizationTypeMasterActionDispatchTypes.AUTHORIZATION_TYPE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AuthorizationTypeMasterReducer;

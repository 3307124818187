import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { isEmpty, isNil, isUndefined } from "lodash";

import {
  ActiveStatus,
  baseContainer,
  BoxText,
  CreateReferralButton,
  Data,
  GridContainer,
  InactiveStatus,
  InfoIcon,
  infoReceivedBox,
  InfoText,
  Item,
  ItemGrid,
  StatusValue,
  UnderlineText,
} from "../styles/CreateReferral";
import {
  displayFlex,
  flexAllCentre,
} from "../../../../../styles/mui/styles/display";
import { colors } from "../../../../../styles/colors";
import { HealthPlanDetailsForm } from "../../../../../models/HealthPlan";
import {
  PatientFilterOptions,
  SaveInformation,
  PatientReferralInfo,
} from "../../../../../models/Patient";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { PatientContainerBox } from "../styles/patientDetailsStyle";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthorizationHistory,
  EligibilityHistoryPayload,
} from "../../../../../models/AuthorizationHistory";
import { AuthorizationHistoryResponse } from "../../../../../models/Api/AuthorizationHistory";
import { getValue, length } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { UserDetailsPayload } from "../../../../../models/User";
import { EligibilityRecord } from "../../../../../models/Master";
import Permission from "../../../../Permission";
import { HistoryTab } from "./PatientInformation";
import { PatientInformationActionDispatchTypesView } from "../../../../../constants/Patient";
import {
  Icd10RecordsActionDispatchTypes,
  PatientReferralStatus,
  PatientRequestDispatchTypes,
  SaveOrUpdatePatientRequestDispatchTypes,
} from "../../../../../constants/AllPatientRecord";
import {
  GetPhysicianDetailsActionDispatchTypes,
  PostPhysicianDetailsActionDispatchTypes,
} from "../../../../../constants/PhysicianDetails";
import {
  ServiceProviderActionDispatchType,
  ServiceRequestActionDispatchType,
} from "../../../../../constants/Service";
import { DocumentReviewActionDispatchTypes } from "../../../../../constants/Document";
import AddEligibilityDetails from "../containers/AddEligibilityDetails";
import { UpdateEligibilityForm } from "../../../../../constants/PatientInformation";
import { PageStatus } from "../../../../../constants/Authorization";
import CreateReferralModal from "./CreateReferralModal";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import {
  MessageType,
  ResponseMessages,
} from "../../../../../constants/ToastMessage";
import { mbiRegex } from "../../../../../constants/Regex";
import { getEligibilityStatus } from "../../../../../utils/Eligibility";
import { DESC, ELIGIBILITY_CHECK_DATE } from "../../../../../constants/FunctionalUtils";

export interface PropsFromDispatch {
  getEligibilityData: (benefitPlanUid: string) => void;
  saveInfoReceived: (info: SaveInformation) => void;
  getEligibilityHistoryRecord: (
    payload: EligibilityHistoryPayload
  ) => void;
  resetStates: (actionType: string[]) => void;
}
export interface PropsFromState {
  informationReceived: HealthPlanDetailsForm;
  patientReferralInfo: PatientReferralInfo;
  responseData: PatientInformationViewResponse;
  infoReceivedResp: PatientInformationViewResponse;
  eligibilityRecordResponse: EligibilityRecord;
  onSubmit?: any;
  pageStatus: any;
  setCreateRecordStatus?: any;
  user: UserDetailsPayload;
  authorizationHistory: AuthorizationHistoryResponse;
  setPatientFormValue?: (id: any, value: any, boolean: any) => void;
  referralId?: any;
  eligibilityRecord?: any;
  eligibilityHistoryUpdate?: (data: any) => void;
  payerState: PatientFilterOptions[];
  htmlControlId?: string;
  isModified: boolean;
  tab?: HistoryTab;
  updatedPayerDetails?: any;
  checkEligibilityBtnClick?: boolean;
  setIsManual?: (isManual: boolean) => void;
  setIsOpen?: (isOpen: boolean) => void;
  isManual?: boolean;
  errorMessages?: any;
  infoReceivedFlag?: boolean;
  loading?: boolean;
  checkEligibilityOpenModal?: boolean;
  isProceedManually?: boolean;
}

type AllProps = PropsFromState & PropsFromDispatch;

const CreateReferral: React.FC<AllProps> = ({
  responseData,
  saveInfoReceived,
  authorizationHistory,
  infoReceivedResp,
  onSubmit,
  pageStatus,
  setCreateRecordStatus,
  user,
  setPatientFormValue,
  referralId,
  eligibilityRecord,
  getEligibilityData,
  eligibilityRecordResponse,
  getEligibilityHistoryRecord,
  htmlControlId,
  isModified,
  updatedPayerDetails,
  tab,
  resetStates,
  setIsManual,
  isManual,
  errorMessages,
  infoReceivedFlag,
  loading,
  checkEligibilityOpenModal,
  isProceedManually,
  setIsOpen,
}: AllProps) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const { response } = responseData;
  const { patientId } = useParams();
  const [localPatientId, setPatientId] = useState<string>("");
  const [modifiedPayerName, setModifiedPayerName] = useState<boolean>(false);

  const methods = useForm<SaveInformation>({
    defaultValues: UpdateEligibilityForm,
    mode: "all",
  });

  const { handleSubmit, control, reset, getValues, setError, clearErrors } =
    methods;

  useWatch({
    name: "informationReceived",
    control,
  });

  useEffect(() => {
    setPatientId(patientId ? patientId : "");
  }, []);

  const handleUpdateEligibilityInformation = () => {
    let data: SaveInformation | null = null;
    data = {
      informationReceived: {
        ...getValues("informationReceived"),
        patientId: Number(getValue(response, "patientId", patientId)),
        isManual: true,
        eligibilityPayerId: getValue(response, "eligibilityPayerId"),
        eligibilityPayerName: getValue(response, "eligibilityPayerName"),
        alternatePhone: getValue(response, "memberInfo.alternatePhone"),
      },
      patientResidentialAddress: {
        ...response.addressInfo.patientResidentialAddress,
      },
      patientMailingAddress: {
        ...response.addressInfo.patientMailingAddress,
      },
    };
    if (pageStatus === PageStatus.RE_AUTH) {
      if (
        getValue(response, "memberInfo.healthPlan") !==
        getValues("informationReceived.healthPlan") ||
        getValue(response, "memberInfo.subscriberId") !==
        getValues("informationReceived.subscriberId")
      ) {
        setIsOpen && setIsOpen(true);
        return;
      }
    }
    saveInfoReceived(data);
  };

  const informationData = () => {
    const id = response ? response.patientId : patientId || "";
    let data: SaveInformation | null = null;

    const patientResidentialAddress = {
      ...eligibilityRecord.patientResidentialAddress,
    };
    const patientMailingAddress = {
      ...eligibilityRecord.patientMailingAddress,
    };

    if (id) {
      data = {
        informationReceived: {
          patientId: Number(id),
          healthPlanUid: getValue(eligibilityRecord, "healthPlanUid"),
          product: getValue(eligibilityRecord, "product"),
          benefitPlanUid: getValue(eligibilityRecord, "benefitPlanUid"),
          medicareBeneficiaryIdentifier: mbiRegex.test(
            getValue(eligibilityRecord, "mbi")
          )
            ? getValue(eligibilityRecord, "mbi")
            : null,
          ahcccsId: null,
          eligibilityStatus: getEligibilityStatus(
            getValue(eligibilityRecord, "eligibilityStatus")
          ),
          effectiveDate: formatDate(
            getValue(eligibilityRecord, "effectiveDate")
          ),
          effectiveThrough: formatDate(
            getValue(eligibilityRecord, "effectiveThrough")
          ),
          subscriberId: getValue(eligibilityRecord, "subscriberId"),
          eligibilityPayerId: modifiedPayerName
            ? updatedPayerDetails.payerId
            : getValue(response, "eligibilityPayerId"),
          eligibilityPayerName: modifiedPayerName
            ? updatedPayerDetails.payerName
            : getValue(response, "eligibilityPayerName"),
          apiEligibilityId: getValue(eligibilityRecord, "apiEligibilityId"),
          isManual: false,
          healthPlan: getValue(eligibilityRecord, "healthPlan"),
          benefitPlanName: getValue(eligibilityRecord, "benefitPlanName"),
          healthPlanState: getValue(eligibilityRecord, "healthPlanState"),
          alternatePhone: getValue(response, "memberInfo.alternatePhone"),
        },
        patientResidentialAddress,
        patientMailingAddress,
      };
    }
    return data;
  };

  const createReferralData = () => {
    if (response && response.patientId > 0 && !activeReferralHistory()) {
      saveInfoReceived(eligibilityRecord && informationData());
    }
  };
  
  const activeReferralHistory = () => {
    const activeReferralHistory =
      authorizationHistory.response &&
      authorizationHistory.response.authorizationHistory.filter(
        (authHistory: any) => authHistory.referralStatusName === "Active"
      );
    return activeReferralHistory && length(activeReferralHistory);
  };

  useEffect(() => {
    if (
      !infoReceivedResp.loading &&
      pageStatus !== PageStatus.CREATE_RECORD &&
      getValue(response, "patientId") > 0 &&
      getValue(eligibilityRecordResponse, "benefitPlanId") > 0 &&
      !isEmpty(getValue(eligibilityRecord, "subscriberId")) &&
      getEligibilityStatus(getValue(eligibilityRecord, "eligibilityStatus")) ===
      "Eligible"
    ) {
      const data = informationData();
      if (data) {
        saveInfoReceived(data);
      }
    }
  }, [eligibilityRecordResponse]);

  useEffect(() => {
    if (
      eligibilityRecord &&
      eligibilityRecord.response != null &&
      eligibilityRecord.loading === false &&
      !isEmpty(
        getValue(
          eligibilityRecord,
          "response.additionalNotes.benefitPlanUid",
          ""
        )
      ) &&
      getValue(eligibilityRecord, "response.additionalNotes.benefitPlanUid", "")
        .length > 0
    ) {
      getEligibilityData(
        eligibilityRecord.response.additionalNotes.benefitPlanUid
      );
    }
  }, [eligibilityRecord]);

  useEffect(() => {
    if (
      tab &&
      tab.tabNumber === 1 &&
      getValue(response, "patientId") > 0 &&
      !isEmpty(getValue(infoReceivedResp, "response.memberInfo.subscriberId"))
    ) {
      getEligibilityHistoryRecord({
        contains: true,
        pageNo: tab.page,
        pageSize: 10,
        patientId: response.patientId,
        sortColumn: ELIGIBILITY_CHECK_DATE,
        sortOrder: DESC
      });
    }
    if (
      isManual &&
      getValue(infoReceivedResp, "response.patientId") > 0 &&
      pageStatus !== PageStatus.RE_AUTH
    ) {
      if (activeIncompleteAuthHistory()) {
        SnackbarUtils.success(ResponseMessages(MessageType.UPDATE_ELIGIBILITY_INFORMATION));
        setOpenModal(true);
      } else {
        navigate(
          ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE, {
            referralId: 0,
            patientId: Number(patientId),
          })
        );
        SnackbarUtils.success(ResponseMessages(MessageType.UPDATE_ELIGIBILITY_INFORMATION));
      }
    }
    if (
      isManual &&
      getValue(infoReceivedResp, "response.patientId") > 0 &&
      pageStatus === PageStatus.RE_AUTH
    ) {
      navigate(
        ModuleLinks(PatientRecordsModule.REAUTH_INTAKE, {
          reAuthId: 0,
          referralId: referralId,
        })
      );
      SnackbarUtils.success(ResponseMessages(MessageType.UPDATE_ELIGIBILITY_INFORMATION));
    }
  }, [infoReceivedResp]);

  useEffect(() => {
    setModifiedPayerName(isModified);
  }, [isModified]);

  const handleCreateReferral = () => {
    if (pageStatus === PageStatus.CREATE_RECORD) {
      resetStates([
        PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
        Icd10RecordsActionDispatchTypes.ICD_10_RESET,
        SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_RESET,
        PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_RESET,
        GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET,
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET,
        ServiceRequestActionDispatchType.SERVICE_REQUEST_RESET,
        DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET,
        PatientRequestDispatchTypes.PATIENT_REQUEST_RESET,
      ]);
      setOpenModal(false);
      const id =
        getValue(response, "response.patientId", "") || patientId
          ? patientId
          : "";
      if (!isUndefined(setPatientFormValue)) {
        setPatientFormValue("informationReceived.patientId", id, true);
        setPatientFormValue(
          "informationReceived.healthPlan",
          eligibilityRecord && getValue(eligibilityRecord, "healthPlan"),
          true
        );
        setPatientFormValue(
          "informationReceived.product",
          getValue(eligibilityRecord, "product"),
          true
        );
        setPatientFormValue(
          "informationReceived.benefitPlan",
          getValue(eligibilityRecord, "benefitPlanName"),
          true
        );
        setPatientFormValue(
          "informationReceived.medicareBeneficiaryIdentifier",
          eligibilityRecord && getValue(eligibilityRecord, "mbi"),
          true
        );
        // TBD
        setPatientFormValue("informationReceived.ahcccsId", "", true);
        setPatientFormValue(
          "informationReceived.eligibilityStatus",
          getEligibilityStatus(
            getValue(eligibilityRecord, "eligibilityStatus")
          ),
          true
        );
        setPatientFormValue(
          "informationReceived.effectiveDate",
          formatDate(getValue(eligibilityRecord, "effectiveDate")),
          true
        );
        setPatientFormValue(
          "informationReceived.effectiveThrough",
          formatDate(getValue(eligibilityRecord, "effectiveThrough")),
          true
        );
        setPatientFormValue(
          "informationReceived.subscriberId",
          getValue(eligibilityRecord, "subscriberId"),
          true
        );
        setPatientFormValue(
          "informationReceived.userCreated",
          getValue(user, "email"),
          true
        );
      }
      onSubmit();
      setCreateRecordStatus("createPatient");
    } else if (pageStatus === PageStatus.PATIENT_DETAILS) {
      if (activeIncompleteAuthHistory()) {
        setOpenModal(true);
      } else {
        navigate(
          ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE, {
            referralId: 0,
            patientId: Number(patientId),
            additionalParams: 0,
          })
        ); //TBD this should be called after success response on create Referral
      }
    } else {
      navigate(
        ModuleLinks(PatientRecordsModule.REAUTH_INTAKE, {
          reAuthId: 0,
          referralId: referralId,
        })
      );
    }
  };

  const typographyBox = (
    header?: string,
    description?: string | number | null,
    color?: any,
    sx?: any
  ) => {
    return (
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[2]}
          color={colors.fonts[2]}
        >
          {header}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={fontWeight.Weight[5]}
          color={color}
          sx={sx}
        >
          {description}
        </Typography>
      </Box>
    );
  };

  const dataRender = (label: string) => {
    return (
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[5]}
        sx={StatusValue}
      >
        {label}
      </Typography>
    );
  };

  const renderButton = (
    label: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
  ) => {
    return (
      <Button variant="outlined" disabled={disabled} onClick={onClick}>
        {label}
      </Button>
    );
  };

  const handleProceedManually = () => {
    setIsManual && setIsManual(true);
  };

  const checkEligibilityDetailsVisibility = () => {
    if (isProceedManually) {
      const isEligibilityDetailsVisible =
        !loading &&
        !isEmpty(getValue(eligibilityRecord, "benefitPlanUid")) &&
        !isEmpty(getValue(eligibilityRecord, "subscriberId")) &&
        infoReceivedFlag &&
        !checkEligibilityOpenModal;
      return isEligibilityDetailsVisible;
    } else {
      return true;
    }
  };

  const activeIncompleteAuthHistory = () => {
    const activeIncompleteAuthHistory =
      authorizationHistory.response &&
      authorizationHistory.response.authorizationHistory.filter(
        (authHistory: AuthorizationHistory) =>
          authHistory.referralStatusName === PatientReferralStatus.ACTIVE ||
          isEmpty(authHistory.referralStatusName)
      );

    if (activeIncompleteAuthHistory) {
      return activeIncompleteAuthHistory && length(activeIncompleteAuthHistory);
    }
    return false;
  };

  return (
    <>
      {checkEligibilityDetailsVisibility() ? (
        <>
          <PatientContainerBox sx={displayFlex}>
            <Grid container sx={GridContainer}>
              <Grid item xs={12}>
                <Grid container pl={"0.8rem"}>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      color={colors.fonts[20]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      INFORMATION RECEIVED:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={colors.fonts[23]}
                      fontWeight={fontWeight.Weight[4]}
                      sx={BoxText}
                    >
                      <i>Using existing policy details:</i>
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={displayFlex}>
                  <Grid container sx={ItemGrid}>
                    <Grid item xs={1.5} sx={Item}>
                      {typographyBox(
                        "Health Plan:",
                        getValue(eligibilityRecord, "healthPlan"),
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1.4} sx={Item}>
                      {typographyBox(
                        "Subscriber ID:",
                        getValue(eligibilityRecord, "subscriberId"),
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1.8} sx={Item}>
                      {typographyBox(
                        "Product:",
                        getValue(eligibilityRecord, "product"),
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1.5} sx={Item}>
                      {typographyBox(
                        "Benefit Plan:",
                        getValue(eligibilityRecord, "benefitPlanName"),
                        colors.fonts[4],
                        UnderlineText
                      )}
                    </Grid>
                    <Grid item xs={1.2} sx={Item}>
                      {typographyBox(
                        "State:",
                        getValue(eligibilityRecord, "healthPlanState"),
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1.5} sx={Item}>
                      {typographyBox(
                        "MBI Number:",
                        getValue(eligibilityRecord, "mbi"),
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1.5} sx={Item}>
                      {typographyBox(
                        "AHCCS Number:",
                        null, //TBD with BA for mapping
                        colors.fonts[4],
                        Data
                      )}
                    </Grid>
                    <Grid item xs={1} sx={Item}>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[2]}
                          color={colors.fonts[2]}
                        >
                          Status:
                        </Typography>
                        {getEligibilityStatus(
                          getValue(eligibilityRecord, "eligibilityStatus")
                        ) === "Eligible" && (
                            <Box sx={ActiveStatus}>
                              <FaCheck size={16} />
                              {dataRender("Eligible")}
                            </Box>
                          )}
                        {getEligibilityStatus(
                          getValue(eligibilityRecord, "eligibilityStatus")
                        ) === "Not Eligible" && (
                            <Box sx={InactiveStatus}>
                              <FaTimes size={16} />
                              {dataRender("Not Eligible")}
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Box sx={infoReceivedBox}>
                      <Box sx={InfoIcon}>
                        <FaInfoCircle />
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          color={colors.fonts[13]}
                          fontWeight={fontWeight.Weight[3]}
                        >
                          You may begin intake process based
                        </Typography>
                        <Typography
                          variant="body1"
                          color={colors.fonts[13]}
                          fontWeight={fontWeight.Weight[3]}
                          sx={InfoText}
                        >
                          on eligibility information received:
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={CreateReferralButton}>
                      {pageStatus === PageStatus.RE_AUTH ? (
                        <>
                          {getEligibilityStatus(
                            getValue(eligibilityRecord, "eligibilityStatus")
                          ) === "Eligible"
                            ? renderButton(
                              "CREATE RE-AUTH. REQUEST",
                              handleCreateReferral
                            )
                            : renderButton(
                              "PROCEED MANUALLY",
                              handleProceedManually
                            )}
                        </>
                      ) : (
                        <Permission controlId={htmlControlId}>
                          {getEligibilityStatus(
                            getValue(eligibilityRecord, "eligibilityStatus")
                          ) === "Eligible"
                            ? renderButton(
                              "CREATE REFERRAL",
                              handleCreateReferral,
                              isNil(
                                getValue(eligibilityRecord, "subscriberId")
                              )
                            )
                            : renderButton(
                              "PROCEED MANUALLY",
                              handleProceedManually
                            )}
                        </Permission>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </PatientContainerBox>
          {activeIncompleteAuthHistory() && (
            <CreateReferralModal
              patientInfoResp={response}
              openModal={openModal}
              closeModal={setOpenModal}
              saveInfoReceived={createReferralData}
              patientId={localPatientId}
            />
          )}
        </>
      ) : !checkEligibilityOpenModal &&
        !loading &&
        !isEmpty(errorMessages) &&
        length(errorMessages) ? (
        <NoRecordFound
          error={errorMessages}
          handleProceedManually={handleProceedManually}
          isProceedManually={true}
        />
      ) : (
        !isEmpty(eligibilityRecord.apiEligibilityId) &&
        (isEmpty(getValue(eligibilityRecord, "benefitPlanUid")) ||
          isEmpty(getValue(eligibilityRecord, "subscriberId"))) && (
          <NoRecordFound
            handleProceedManually={handleProceedManually}
            isProceedManually={true}
          />
        )
      )}
      {!checkEligibilityOpenModal && isManual && (
        <>
          <AddEligibilityDetails
            control={control}
            onSubmit={handleSubmit(handleUpdateEligibilityInformation)}
            setCreateRecordStatus={setCreateRecordStatus}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
            reset={reset}
            isUpdateManually={isManual}
            isReAuth={pageStatus === PageStatus.RE_AUTH}
          />
          {activeIncompleteAuthHistory() && (
            <CreateReferralModal
              patientInfoResp={response}
              openModal={openModal}
              closeModal={setOpenModal}
              patientId={localPatientId}
              isUpdateManually={isManual}
            />
          )}
        </>
      )}
    </>
  );
};

type NoRecordFoundProps = {
  error?: string[];
  handleProceedManually?: () => void;
  isProceedManually: boolean;
};
export const NoRecordFound = ({
  error,
  handleProceedManually,
  isProceedManually,
}: NoRecordFoundProps) => {
  return (
    <PatientContainerBox sx={displayFlex}>
      <Grid container sx={GridContainer}>
        <Grid item xs={12}>
          <Grid container pl={"0.8rem"}>
            <Grid item xs={1.5}>
              <Typography
                variant="subtitle2"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
              >
                INFORMATION RECEIVED:
              </Typography>
              <Typography
                variant="body1"
                color={colors.fonts[11]}
                fontWeight={fontWeight.Weight[3]}
                sx={BoxText}
              >
                <i>Using existing policy details:</i>
              </Typography>
            </Grid>
            <Grid>
              {error &&
                error.map((mesg: any, index: number) => (
                  <FormHelperText key={index}>{mesg}</FormHelperText>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isProceedManually ? 10 : 12}>
          <Box m={"0 1rem"} sx={baseContainer}>
            <Typography
              variant="h2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[4]}
              sx={flexAllCentre}
              mt="0.5rem"
            >
              No record found
            </Typography>
          </Box>
        </Grid>
        {isProceedManually && (
          <Grid item xs={2}>
            <Button variant="outlined" onClick={handleProceedManually}>
              PROCEED MANUALLY
            </Button>
          </Grid>
        )}
      </Grid>
    </PatientContainerBox>
  );
};

export default CreateReferral;

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { UserDetailsDispatchTypes } from "../../../../../models/DispatchTypes/User";
import { UserDetailsPayload } from "../../../../../models/User";
import CreateNoteTemplate from "../components/CreateNoteTemplate";
import {
  getNotesCategoryMaster,
  saveNotesSection,
  updateNotesSection,
  GetNotesSectionByVersionId,
} from "../../../../../actions/Notes";
import { SaveNotes } from "../../../../..//models/Notes";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  userDetailsState,
  RoleState,
  adminCategoryNotesMasterState,
  GetadminNotesState,
  updateAdminNotesState,
  saveAdminNotesState,
  loggedInUserState,
}: ApplicationState) => {
  return {
    userDetails: userDetailsState,
    roles: RoleState,
    categorydata: adminCategoryNotesMasterState,
    NotesDataById: GetadminNotesState,
    UpdateNotesResponse: updateAdminNotesState,
    SaveNotesResp: saveAdminNotesState,
    user: loggedInUserState.response as UserDetailsPayload,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<UserDetailsDispatchTypes>
  >
) => {
  return {
    getNotesCategoryMaster: () => {
      dispatch(getNotesCategoryMaster());
    },
    saveNotesSection: (payload: SaveNotes) => {
      dispatch(saveNotesSection(payload));
    },
    updateNotesSection: (id: number, payload: SaveNotes) => {
      dispatch(updateNotesSection(id, payload));
    },
    GetNotesSectionByVersionId: (noteTemplateVersionId: number) => {
      dispatch(GetNotesSectionByVersionId(noteTemplateVersionId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNoteTemplate);

import { useLocation, matchPath, useRoutes } from "react-router-dom";
import { useEffect } from "react";
import type { IndexRouteObject, RouteObject } from "react-router-dom";

import { getValue, isNil } from "../utils";
import { RouteBox } from "../styles/common/style";
import {
  Module,
  NonIndexRouteWithFullPath,
  SubModule,
} from "../models/Modules";
import { UserModuleResponse } from "../models/Api/Modules";
import AuthGuard from "./AuthGuard";
import { RouteUrlInfo } from "../models/User";
import ErrorPage from "../components/errorPages/404";
import routeMapping, { Layout } from "./routePath";
import {
  prepareMenuItems,
  setActiveModuleSubModule,
  updateRouteObjectFullRoutePath,
} from "../utils/authentication/authorization";

export interface RouteProps {
  moduleData: UserModuleResponse;
  saveRouteUrlInfo: (routeUrlInfo: RouteUrlInfo) => void;
}

const RoutesList: React.FC<RouteProps> = ({ moduleData, saveRouteUrlInfo }) => {
  const userModule = moduleData.response;
  const { pathname } = useLocation();

  useEffect(() => {
    setActiveModuleSubModule(userModule, pathname, saveRouteUrlInfo, matchPath);
  }, [userModule, pathname]);

  const prepareAuthRoute = (
    userModuleResponse: Module[],
    routeMapping: NonIndexRouteWithFullPath[]
  ): RouteObject[] => {
    const routeMapObject: RouteObject[] = [];
    const menuDataObject = prepareMenuItems(userModuleResponse);
    const routeMappingObjectfullPath =
      updateRouteObjectFullRoutePath(routeMapping);
    routeMappingObjectfullPath.forEach(
      (mapModuleItem: NonIndexRouteWithFullPath) => {
        const mapModuleItemCopy: any = { ...mapModuleItem };
        const menuDataObjectIndex = menuDataObject.findIndex(
          (x) => x.routeUrl == mapModuleItemCopy.path
        );
        let AuthGuardComponent = (
          <AuthGuard
            authenticated={false}
            userModuleResponse={userModuleResponse}
          >
            {mapModuleItemCopy.element}
          </AuthGuard>
        );
        let fullRoutePath = mapModuleItemCopy.fullRoutePath;
        if (menuDataObjectIndex > -1) {
          AuthGuardComponent = (
            <AuthGuard
              authenticated={true}
              userModuleResponse={userModuleResponse}
            >
              {mapModuleItemCopy.element}
            </AuthGuard>
          );
          fullRoutePath = menuDataObject[menuDataObjectIndex].routeUrl;
        }
        mapModuleItemCopy.element = AuthGuardComponent;
        mapModuleItemCopy.fullRoutePath = fullRoutePath;
        mapModuleItemCopy.children = [];

        const updateChildren = (
          mapModuleItemCopy2Rec: IndexRouteObject[],
          mapRoutSingleObject: RouteObject | any,
          MenuModulePathCheckIndexData: Module | SubModule
        ): void => {
          const mapModuleItemCopy2 = { ...mapRoutSingleObject };
          let ModulePathCheckIndex2 = -1;
          if (
            MenuModulePathCheckIndexData &&
            MenuModulePathCheckIndexData.subModules
          ) {
            ModulePathCheckIndex2 =
              MenuModulePathCheckIndexData.subModules.findIndex(
                (x: Module | SubModule) => x.routeUrl == mapModuleItemCopy2.path
              );
          }
          let AuthGuardComponent = (
            <AuthGuard
              authenticated={false}
              userModuleResponse={userModuleResponse}
            >
              {mapModuleItemCopy2.element}
            </AuthGuard>
          );
          let fullRoutePath = mapModuleItemCopy2.fullRoutePath;
          if (ModulePathCheckIndex2 > -1) {
            AuthGuardComponent = (
              <AuthGuard
                authenticated={true}
                userModuleResponse={userModuleResponse}
              >
                {mapModuleItemCopy2.element}
              </AuthGuard>
            );
            fullRoutePath =
              MenuModulePathCheckIndexData.subModules &&
              MenuModulePathCheckIndexData.subModules[ModulePathCheckIndex2]
                .fullRoutePath;
          }
          mapModuleItemCopy2.element = AuthGuardComponent;
          mapModuleItemCopy2.fullRoutePath = fullRoutePath;
          if (
            Object.prototype.hasOwnProperty.call(
              mapRoutSingleObject,
              "children"
            )
          ) {
            mapModuleItemCopy2.children = [];
          }
          if (mapRoutSingleObject.children) {
            mapRoutSingleObject.children &&
              mapRoutSingleObject.children.forEach(
                (mapRoutSingleObject2: RouteObject) => {
                  updateChildren(
                    mapModuleItemCopy2.children,
                    mapRoutSingleObject2,
                    getValue(
                      MenuModulePathCheckIndexData,
                      `subModules[${ModulePathCheckIndex2}]`,
                      {}
                    )
                  );
                }
              );
          }

          mapModuleItemCopy2Rec.push(mapModuleItemCopy2);
        };

        mapModuleItem.children &&
          mapModuleItem.children.forEach((mapRoutSingleObject: RouteObject) => {
            updateChildren(
              mapModuleItemCopy.children,
              mapRoutSingleObject,
              menuDataObject[menuDataObjectIndex]
            );
          });

        routeMapObject.push(mapModuleItemCopy);
      }
    );

    return convertToSingleLevelRoute(routeMapObject, userModuleResponse);
  };

  const convertToSingleLevelRoute = (
    routeMapObject: any,
    userModuleResponse: any
  ) => {
    const singleLevelroutes: RouteObject[] | any = [
      {
        path: "/",
        element: <Layout />,
        children: [
          { index: true, element: null },
          {
            path: "*",
            element:
              getValue(userModuleResponse, "[0].moduleId", 0) !== -1 ? (
                <ErrorPage />
              ) : null,
          },
        ],
      },
    ];

    if (
      routeMapObject &&
      routeMapObject.length !== 0 &&
      !isNil(userModuleResponse)
    ) {
      const rootUrlElementObject = routeMapObject.find(
        (routeObject: any) =>
          routeObject.path ===
          getValue(userModuleResponse, "[0].routeUrl", null)
      );
      singleLevelroutes[0].children[0].element = getValue(
        rootUrlElementObject,
        "element",
        null
      );
    }

    const pushRouteElement = (
      pageRoutesNested: RouteObject[],
      routeObj: RouteObject | any
    ) => {
      const { fullRoutePath, element, children } = routeObj;
      pageRoutesNested.push({
        path: fullRoutePath,
        element: element,
      });
      if (children && children.length > 0) {
        for (const childRouteObj of children) {
          pushRouteElement(pageRoutesNested, childRouteObj);
        }
      }
    };
    for (const routeObj of routeMapObject) {
      pushRouteElement(singleLevelroutes[0].children, routeObj);
    }
    return singleLevelroutes;
  };

  const dynamicRouteData = prepareAuthRoute(userModule, routeMapping);
  const routeElements = useRoutes(dynamicRouteData);

  return (
    <>
      <RouteBox>{routeElements}</RouteBox>
    </>
  );
};

export default RoutesList;

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ErrorBox, GridLayout, GridItem } from "../styles/styles";
import { others, colors } from "../../styles/colors";

const UnAuthorizedPage = () => {
  return (
    <GridLayout container>
      <GridItem item>
        <ErrorBox>
          <Typography
            variant="h1"
            color={others.otherColors[10]}
            fontWeight="fontWeightBold"
            fontSize={"2.8em"}
          >
            401
          </Typography>
          <Typography
            variant="h2"
            color={colors.primary.main}
            fontWeight="fontWeightBold"
            fontSize={"1.8em"}
            pt={"1rem"}
            pb={"1rem"}
          >
            You are not Authorized to access this Page !
          </Typography>
          <Link href="/">
            <Typography
              variant="h3"
              color={colors.primary.main}
              fontWeight="400"
            >
              Go back home
            </Typography>
          </Link>
        </ErrorBox>
      </GridItem>
    </GridLayout>
  );
};

export default UnAuthorizedPage;

import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Params, useNavigate, useParams } from "react-router-dom";
import { alpha } from "@mui/material";
import { isNil } from "lodash";
import { useForm } from "react-hook-form";

import { Head, Body, Main } from "../../../../../components/custom/structure";
import {
  roleDetailsStyles,
  TitleBox,
  DividerItem,
  BodyOuter,
  BodyInner,
  IconFaChevronCircleUp,
  IconFaChevronCircleDown,
  HeaderItem,
  roleDetailsFooter,
  roleDetailsBody,
  alignItemBaseline,
  description,
  HorizontalDivider,
  SubtitleBox,
  SubtitleValue,
  ButtonBox,
  ErrorMessageBox,
  HeaderModuleBox,
  BodyDetailsContainer,
} from "../styles/roledetails";
import { Checkbox } from "../../../../../components/custom/checkbox";
import {
  RoleDetailsResponse,
  UpdateRoleDetailsResponse,
} from "../../../../../models/Api/Roles";
import {
  RoleDetailsAccess,
  RoleDetailsAuth,
  RoleDetailsStateType,
  RoleDetailsSubModule,
  SubModule,
} from "../../../../../models/Roles";
import { RoleDetailsBodyProps } from "../../../../../models/Roles";
import { MasterAccessResponse } from "../../../../../models/Api/Master";
import {
  MasterAccess,
  MasterSubModule,
  MasterSubModuleAccess,
} from "../../../../../models/Master";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { bodytable } from "../styles/rolemanagement";
import { rules } from "../../../../../utils/validation/Validation";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import {
  RoleDetailsApiFailed,
  roleDetailsInitialState,
} from "../../../../../constants/Roles";
import { initialState as MasterAccessInitialState } from "../../../../../reducers/MasterAccess";
import { initialState as RoleDetailsInitialState } from "../../../../../reducers/RoleDetails";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import Permission from "../../../../Permission";
import { AdminCenterAccess } from "../../../../../constants/Permission";

export interface PropsDispatch {
  getRoleDetails: (userId: string) => void;
  getMasterAccess: () => void;
  putRoleDetails: (roleId: string, payload: RoleDetailsAuth) => void;
  roleDetailsReset: () => void;
  updateRoleDetailsReset: () => void;
}

export interface RoleDetailsState {
  roleDetailsState: RoleDetailsResponse;
  masterAccessState: MasterAccessResponse;
}
export interface PropsState {
  roleDetails: RoleDetailsState;
  updateRoleDetails: UpdateRoleDetailsResponse;
}

export interface RoleDetailsAndMasterAccess {
  roleDetails: RoleDetailsAuth;
  masterAccess: MasterAccess[];
}
type RoleDetailsProps = PropsState & PropsDispatch;

const RoleDetails: React.FC<RoleDetailsProps> = ({
  roleDetails,
  getRoleDetails,
  putRoleDetails,
  getMasterAccess,
  roleDetailsReset,
  updateRoleDetails,
  updateRoleDetailsReset,
}: RoleDetailsProps) => {
  const clonedRoleDetailsState = JSON.parse(JSON.stringify(roleDetails));

  useEffect(() => {
    if (roleId) {
      getRoleDetails(roleId);
      getMasterAccess();
    }
  }, []);

  const [authorization, setAuthorization] =
    useState<RoleDetailsAndMasterAccess>({
      roleDetails: RoleDetailsInitialState.response,
      masterAccess: MasterAccessInitialState.response,
    });
  const [validation, setValidation] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiFailedMessage, setApiFailedMessage] = useState<string>("");

  const { handleSubmit, control, reset } = useForm<RoleDetailsStateType>({
    defaultValues: roleDetailsInitialState,
  });

  const onSubmit = (data: RoleDetailsStateType) => {
    const { roleName, description } = data;
    const payload: RoleDetailsAuth = {
      ...authorization.roleDetails,
    };
    if (roleName && description) {
      payload.loggedInUser = payload.roleName.toString();
      payload.roleName = roleName;
      payload.roleDescription = description;
      if (accessValidation(authorization.roleDetails)) {
        setValidation(false);
        putRoleDetails(payload.roleId.toString(), payload);
      } else setValidation(true);
    }
  };

  const onSubmitNavigate = (data: RoleDetailsStateType) => {
    const { roleName, description } = data;
    const payload: RoleDetailsAuth = {
      ...authorization.roleDetails,
    };
    if (roleName && description) {
      payload.loggedInUser = payload.roleName.toString();
      payload.roleName = roleName;
      payload.roleDescription = description;
      if (accessValidation(authorization.roleDetails)) {
        setValidation(false);
        putRoleDetails(payload.roleId.toString(), payload);
      } else setValidation(true);
    }
  };

  const { roleId }: Readonly<Params<string>> = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const { roleDetailsState, masterAccessState } = clonedRoleDetailsState;
    initializeState(roleDetailsState, masterAccessState);
  }, [roleDetails]);

  const initializeState = (
    roleDetailsState: RoleDetailsResponse,
    masterAccessState: MasterAccessResponse
  ) => {
    if (
      !isNil(masterAccessState) &&
      !isNil(roleDetailsState) &&
      !isNil(roleDetailsState.response) &&
      Array.isArray(masterAccessState.response) &&
      roleDetailsState.response.roleId !== -1 &&
      masterAccessState.response.length > 0
    ) {
      setAuthorization({
        roleDetails: { ...roleDetailsState.response },
        masterAccess: [...masterAccessState.response],
      });
      if (
        !isNil(roleDetailsState) &&
        !isNil(roleDetailsState.response) &&
        roleDetailsState.response.roleId !== -1
      ) {
        const { roleName, roleDescription } = roleDetailsState.response;
        reset({
          roleName,
          description: roleDescription,
        });
      }
    }
  };

  useEffect(() => {
    const { error, response, loading } = updateRoleDetails;
    if (!isNil(error)) {
      if (error.status === 400 || error.status === 422) {
        setApiError(true);
        if (error.data === RoleDetailsApiFailed.DUPLICATE_ROLE) {
          setApiFailedMessage(RoleDetailsApiFailed.DUPLICATE_ROLE);
        } else {
          setApiFailedMessage(RoleDetailsApiFailed.UPDATE_FAILED);
        }
      }
    } else {
      setApiError(false);
      if (saveAndExit && !isNil(response)) {
        updateRoleDetailsReset();
        navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
        setSaveAndExit(false);
      } else if (!isNil(response)) {
        const roleDetailsState = { response, loading };
        initializeState(
          roleDetailsState,
          clonedRoleDetailsState.masterAccessState
        );
      }
    }
  }, [updateRoleDetails]);

  const handleBack = () => {
    roleDetailsReset();
    updateRoleDetailsReset();
    navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    setSaveAndExit(false);
  };

  const handleSaveAndExit = () => {
    handleSubmit(onSubmitNavigate)();
    setSaveAndExit(true);
  };

  const handleCancel = () => {
    navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
  };

  const accessValidation = (roleDetails: RoleDetailsAuth) => {
    let subModules = [];
    if (roleDetails.roleId > 0) {
      subModules = roleDetails.roleModule.filter(
        (module) => module.roleSubModule.length > 0
      );
    }
    return subModules.length > 0;
  };

  const checkInitialData = (authorization: RoleDetailsAndMasterAccess) => {
    return (
      !isNil(authorization) && authorization.masterAccess[0].moduleId !== -1
    );
  };

  return (
    <>
      <Main>
        <Head
          title={"ROLE DETAILS"}
          description={
            "View and take actions on role based settings, create/edit/assign rights to roles here."
          }
          back
          dataTestId="roleDetails-back"
          handleBack={handleBack}
        >
          <Button
            onClick={handleCancel}
            data-testid="roleDetails-cancel"
            variant="text"
          >
            CANCEL
          </Button>
          <Permission
            controlId={`||${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_UPDATE}`}
          >
            <Button
              onClick={handleSaveAndExit}
              data-testid="roleDetails-save-exit"
              variant="contained"
              disabled={clonedRoleDetailsState.roleDetailsState.loading}
            >
              SAVE AND EXIT
            </Button>
            <Button
              onClick={handleSave}
              data-testid={"roleDetails-save"}
              variant="contained"
              disabled={clonedRoleDetailsState.roleDetailsState.loading}
            >
              SAVE
            </Button>
          </Permission>
        </Head>
        <Body sx={roleDetailsBody}>
          {checkInitialData(authorization) && (
            <RoleDetailsBody
              authorization={authorization}
              setAuthorization={setAuthorization}
              control={control}
              validation={validation}
              apiError={apiError}
              apiFailedMessage={apiFailedMessage}
            />
          )}
        </Body>
        <Grid item xs={12}>
          <Box sx={roleDetailsFooter}>
            <Button
              onClick={handleCancel}
              data-testid="roleDetails-cancel"
              variant="text"
            >
              CANCEL
            </Button>
            <Permission
              controlId={`||${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_UPDATE}`}
            >
              <Button
                onClick={handleSaveAndExit}
                data-testid="roleDetails-save-exit"
                variant="contained"
                disabled={clonedRoleDetailsState.roleDetailsState.loading}
              >
                SAVE AND EXIT
              </Button>
              <Button
                onClick={handleSave}
                data-testid={"roleDetails-save"}
                variant="contained"
                disabled={clonedRoleDetailsState.roleDetailsState.loading}
              >
                SAVE
              </Button>
            </Permission>
          </Box>
        </Grid>
      </Main>
    </>
  );
};

export default RoleDetails;

export const RoleDetailsBody = ({
  authorization,
  setAuthorization,
  control,
  validation,
  apiError,
  apiFailedMessage,
}: RoleDetailsBodyProps) => {
  const { roleDetails, masterAccess } =
    authorization as RoleDetailsAndMasterAccess;

  const { roleId } = roleDetails as RoleDetailsAuth;
  const accesses = masterAccess;

  const handleCheckBox = (
    permission: number,
    moduleId: number,
    subModuleId: number
  ) => {
    const module: RoleDetailsAccess | undefined =
      authorization.roleDetails.roleModule.find(
        (module: RoleDetailsAccess) => module.moduleId === moduleId
      );

    if (module) {
      const subModule: RoleDetailsSubModule | undefined =
        module.roleSubModule.find(
          (subModule: RoleDetailsSubModule) =>
            subModule.subModuleId === subModuleId
        );

      if (subModule) {
        const checkAccess = subModule.accesses.find(
          (access: string) => permission.toString() === access
        );
        if (checkAccess) {
          const access: string[] = subModule.accesses.filter(
            (access: string) => permission.toString() !== access
          );
          if (access.length === 0) {
            const sub: RoleDetailsSubModule[] = module.roleSubModule.filter(
              (submodule: RoleDetailsSubModule) =>
                submodule.subModuleId !== subModule.subModuleId
            );
            module.roleSubModule = sub;
          } else {
            module.roleSubModule.forEach((submodule: RoleDetailsSubModule) => {
              if (submodule.subModuleId === subModule?.subModuleId) {
                submodule.accesses = access;
              }
            });
          }

          const check = authorization.roleDetails.roleModule.map(
            (mod: RoleDetailsAccess) => {
              if (mod.moduleId === module?.moduleId) return module;
              return mod;
            }
          );
          authorization.roleDetails.roleModule = check;
          const obj = authorization;
          setAuthorization({ ...obj });
        } else {
          module.roleSubModule.forEach((submodule: RoleDetailsSubModule) => {
            if (submodule.subModuleId === subModule?.subModuleId && subModule) {
              subModule.accesses.push(permission.toString());
            }
          });

          const check = authorization.roleDetails.roleModule.map(
            (access: RoleDetailsAccess) => {
              if (access.moduleId === module?.moduleId) return module;
              return access;
            }
          );

          authorization.roleDetails.roleModule = check;
          const obj = authorization;
          setAuthorization({ ...obj });
        }
      } else {
        const module = authorization.masterAccess.find(
          (module: MasterAccess) => module.moduleId === moduleId
        );

        if (module) {
          const subModule: MasterSubModule | undefined = module.subModule.find(
            (subModule: MasterSubModule) =>
              subModule.subModuleId === subModuleId
          );

          if (subModule) {
            let sb: RoleDetailsSubModule | null = null;
            const accessIds: string[] = [];
            accessIds.push(permission.toString());
            sb = {
              moduleId: module.moduleId,
              roleId: authorization.roleDetails.roleId,
              subModuleId: subModule.subModuleId,
              subModuleName: subModule.subModuleName,
              accesses: accessIds,
            };
            authorization.roleDetails.roleModule.forEach((roleModule) => {
              if (roleModule.moduleId === module.moduleId && sb !== null) {
                roleModule.roleSubModule = [...roleModule.roleSubModule, sb];
              }
            });
            const obj = authorization;
            setAuthorization({ ...obj });
          }
        }
      }
    } else {
      const masterModule = authorization.masterAccess.find(
        (module: MasterAccess) => module.moduleId === moduleId
      );

      if (masterModule) {
        const roleDetailsSubModule = masterModule.subModule
          .map((submodule: MasterSubModule) => {
            return {
              moduleId: submodule.moduleId,
              roleId: authorization.roleDetails.roleId,
              subModuleId: submodule.subModuleId,
              subModuleName: submodule.subModuleName,
              accesses:
                submodule.subModuleId === subModuleId
                  ? [permission.toString()]
                  : [],
            };
          })
          .filter(
            (submodule: SubModule) => submodule.subModuleId === subModuleId
          );
        const roleDetailsModule: RoleDetailsAccess = {
          moduleId: masterModule.moduleId,
          moduleName: masterModule.moduleName,
          roleId: authorization.roleDetails.roleId,
          roleSubModule: roleDetailsSubModule,
        };

        const { roleModule } = authorization.roleDetails;
        authorization.roleDetails.roleModule = [
          ...roleModule,
          roleDetailsModule,
        ];
        const obj = authorization;
        setAuthorization({ ...obj });
      }
    }
  };

  const checkAccess = (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => {
    const { roleModule } = authorization.roleDetails as RoleDetailsAuth;
    if (!isNil(masterModule)) {
      const moduleId: RoleDetailsAccess | undefined = roleModule.find(
        (module: RoleDetailsAccess) => module.moduleId === masterModule.moduleId
      );
      if (moduleId) {
        const subModuleId: RoleDetailsSubModule | undefined =
          moduleId.roleSubModule.find(
            (submodule: RoleDetailsSubModule) =>
              submodule.subModuleId === masterSubModuleId
          );
        if (subModuleId) {
          if (subModuleId.accesses.includes(masterAccessId.toString()))
            return true;
          else return false;
        }
      }
    }

    return false;
  };

  const headerStyle = (
    marginBottom: string,
    title: string,
    isDescriptionAvailable?: boolean,
    description?: string
  ) => {
    return (
      <>
        <TitleBox mb={marginBottom}>
          <Typography
            variant="subtitle2"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
          >
            {title}
          </Typography>
          {isDescriptionAvailable && (
            <Typography
              mt={1}
              variant="subtitle2"
              color={alpha(colors.black[5], 0.8)}
              fontWeight={fontWeight.Weight[3]}
            >
              {description}
            </Typography>
          )}
        </TitleBox>
      </>
    );
  };

  const tableCell = (value: string) => {
    return (
      <>
        <TableCell align="center" sx={roleDetailsStyles.table_heading}>
          <Typography
            variant="subtitle2"
            color={colors.black[2]}
            fontWeight={fontWeight.Weight[4]}
          >
            {value}
          </Typography>
        </TableCell>
      </>
    );
  };
  const subTitleStyle = (
    subTitle: string,
    isValuePresent?: boolean,
    value?: string
  ) => {
    return (
      <>
        <Box sx={SubtitleBox}>
          <Box>
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[2]}
            >
              {subTitle}:
            </Typography>
          </Box>
          <Box>
            {isValuePresent && (
              <Typography
                variant="subtitle1"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                mt={2}
                sx={SubtitleValue}
              >
                {value}
              </Typography>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const checkInitialData = (accesses: MasterAccess[]) => {
    return accesses && accesses[0].moduleId !== -1;
  };

  return (
    <>
      <Grid item xs={12}>
        {headerStyle("1.31rem", "ADD ROLE", true, "Details specific to Role")}
        <DividerItem />
      </Grid>
      <Grid item xs={12}>
        {roleId > 0 && (
          <Grid container sx={BodyDetailsContainer}>
            <Grid item xs={1}>
              {subTitleStyle("Role ID", true, roleId.toString())}
            </Grid>
            <Grid item xs={11}>
              <Box sx={[roleDetailsStyles.body_row2, alignItemBaseline]}>
                <Box sx={roleDetailsStyles.body_row2col2.main}>
                  <FormInputText
                    name="roleName"
                    control={control}
                    label="Role Name:"
                    textLength={35}
                    fieldrequired="true"
                    multiline={true}
                    dataTestId="roleDetails-roleName"
                    helper={rules.roleName}
                  />
                </Box>
                <Box sx={[roleDetailsStyles.body_row2col3.main, description]}>
                  <FormInputText
                    name="description"
                    control={control}
                    label="Description:"
                    fieldrequired="true"
                    dataTestId="roleDetails-roleDescription"
                    multiline={true}
                    textLength={127}
                    maxRow={6}
                    helper={rules.roleDescription}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        <Divider light sx={HorizontalDivider} />
      </Grid>
      <Grid item xs={12}>
        {roleId > 0 && (
          <>
            <Box sx={HeaderModuleBox}>
              {headerStyle("1.31rem", "CHOOSE MODULES :")}
              {validation && (
                <Box
                  color={colors.red[100]}
                  textAlign={"left"}
                  sx={ErrorMessageBox}
                >
                  <Typography
                    variant="subtitle2"
                    color={colors.red[100]}
                    fontWeight={fontWeight.Weight[4]}
                  >
                    Please select atleast one access level
                  </Typography>
                </Box>
              )}
              {apiError && (
                <Box
                  color={colors.red[100]}
                  textAlign={"left"}
                  sx={ErrorMessageBox}
                >
                  <Typography
                    variant="subtitle2"
                    color={colors.red[100]}
                    fontWeight={fontWeight.Weight[4]}
                  >
                    {apiFailedMessage}
                  </Typography>
                </Box>
              )}
            </Box>
            <Grid container style={{ display: "grid" }}>
              <BodyOuter>
                <Grid item xs={12}>
                  <BodyInner>
                    <TableContainer>
                      <Table aria-label="collapsible table" sx={bodytable}>
                        <TableHead sx={HeaderItem}>
                          <TableRow>
                            {tableCell("Modules")}
                            {tableCell("Sub-Modules")}
                            {tableCell("Access Levels")}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {checkInitialData(accesses) &&
                            accesses.map((module: MasterAccess) => (
                              <ModuleRow
                                module={module}
                                key={module.moduleId}
                                checkAccess={checkAccess}
                                handleCheckBox={handleCheckBox}
                              />
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BodyInner>
                </Grid>
              </BodyOuter>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

type ModuleRowProps = {
  module: MasterAccess;
  handleCheckBox: (
    permission: number,
    module: number,
    subModule: number
  ) => void;
  checkAccess: (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => boolean;
};

const ModuleRow = ({ module, handleCheckBox, checkAccess }: ModuleRowProps) => {
  const [open, setOpen] = useState(false);
  const showDropdownIcon = module.subModule.length > 1;

  if (open)
    return (
      <Fragment key={module.moduleId}>
        {module.subModule.map((submodule: MasterSubModule, index: number) => {
          let moduleName = null;
          if (index === 0) moduleName = module.moduleName;
          return (
            <TableRow
              key={submodule.subModuleId}
              data-testid={`roleDetails-tableRow-${index}`}
            >
              <TableCell align="center">
                <Box sx={roleDetailsStyles.body_row3row2.c2}>
                  {!isNil(moduleName) && (
                    <Box sx={ButtonBox}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        <Box>
                          {open ? (
                            <IconFaChevronCircleUp />
                          ) : (
                            <IconFaChevronCircleDown />
                          )}
                        </Box>
                      </IconButton>
                    </Box>
                  )}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color={colors.black[2]}
                      fontWeight={fontWeight.Weight[4]}
                    >
                      {moduleName}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={roleDetailsStyles.body_row3row2.c3}>
                  <Typography
                    variant="subtitle2"
                    color={colors.black[5]}
                    fontWeight={fontWeight.Weight[4]}
                  >
                    {submodule.subModuleName}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Grid container>
                  {submodule.access.map(
                    (access: MasterSubModuleAccess, index: number) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={3}
                          key={access.accessId}
                        >
                          <Box>
                            <Box sx={roleDetailsStyles.box}>
                              <Checkbox
                                label={access.accessName}
                                checked={checkAccess(
                                  module,
                                  submodule.subModuleId,
                                  access.accessId
                                )}
                                dataTestId={`roleDetails-row${index}-${access.accessName}`}
                                handleClick={() =>
                                  handleCheckBox(
                                    access.accessId,
                                    module.moduleId,
                                    submodule.subModuleId
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
      </Fragment>
    );
  return (
    <TableRow key={module.moduleId}>
      <TableCell align="center">
        <Box sx={roleDetailsStyles.body_row3row2.c2}>
          {showDropdownIcon && (
            <Box sx={{ alignItems: "left" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                data-testid={`roleDetails-toggle-moduleId${module.moduleId}`}
              >
                <Box>
                  {open ? (
                    <IconFaChevronCircleUp />
                  ) : (
                    <IconFaChevronCircleDown />
                  )}
                </Box>
              </IconButton>
            </Box>
          )}
          <Box>
            <Typography
              variant="subtitle2"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
            >
              {module.moduleName}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{submodules(module.subModule, open)}</TableCell>
      <TableCell align="left">
        {subModulesAccess(module, open, handleCheckBox, checkAccess)}
      </TableCell>
    </TableRow>
  );
};

const submodules = (submodules: MasterSubModule[], open: boolean) => {
  let modules = null;
  if (!open) {
    modules = submodules.map((subModule: MasterSubModule, index: number) => {
      if (index === 0) {
        const totalSubModule =
          submodules.length - 1 > 0 ? `+${submodules.length - 1}` : "";
        return (
          <Box
            key={subModule.subModuleId}
            sx={roleDetailsStyles.body_row3row2.c3}
          >
            <Typography
              variant="subtitle2"
              color={colors.black[5]}
              fontWeight={fontWeight.Weight[3]}
            >{`${subModule.subModuleName} ${totalSubModule}`}</Typography>
          </Box>
        );
      }
      return null;
    });
    return modules;
  }
  return null;
};

const subModulesAccess = (
  permission: MasterAccess,
  open: boolean,
  handleCheckBox: (
    permission: number,
    module: number,
    subModule: number
  ) => void,
  checkAccess: (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => boolean
) => {
  let access = null;
  if (!open) {
    access = permission.subModule.map(
      (subModule: MasterSubModule, index: number) => {
        if (index === 0)
          return (
            <Grid container key={subModule.subModuleId}>
              {subModule.access.map((access: MasterSubModuleAccess) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={3}
                  key={access.accessId}
                >
                  <Box>
                    <Box sx={roleDetailsStyles.box}>
                      <Checkbox
                        label={access.accessName}
                        checked={checkAccess(
                          permission,
                          subModule.subModuleId,
                          access.accessId
                        )}
                        dataTestId={`roleDetails-moduleId${permission.moduleId}-${access.accessName}`}
                        handleClick={() =>
                          handleCheckBox(
                            access.accessId,
                            permission.moduleId,
                            subModule.subModuleId
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          );
      }
    );

    return access;
  }

  return null;
};

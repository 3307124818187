import colors from "../../../../../../../styles/colors/colors";

export const RequestDetailsOuterGrid = () => ({
  backgroundColor: colors.backgroundColor[2],
  padding: "0.5rem 0 2.5rem 1.2rem",
  borderBottom: `1px solid ${colors.borders[1]}`,
});

export const RequestDetailsFieldDescription = () => ({
  display: "flex",
  marginTop: "0.6rem",
  alignItems: "center",
  height: "1.52rem",
});

export const RequestDetailsFieldDescriptionIcon = (isIcon: boolean) => ({
  marginRight: isIcon ? "0.5rem" : "0rem",
  color: colors.primary.main,
});

export const RequestDetailsRowGrid = () => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0.5rem 2rem 0rem 2rem",
});

export const SubtitleIcon = () => ({
  paddingRight: "1rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0 2.5rem 0",
  width: "100%",
});

export const RequestDetailsInnerGrid = () => ({
  padding: "0.2rem 2rem 0rem 2rem",
});

export const Extention = (isExtention: boolean | undefined) => ({
  marginRight: isExtention ? "0.3rem" : "0rem",
  marginLeft: isExtention ? "0.5rem" : "0rem",
});

export const DividerGrid = () => ({
  paddingLeft: "1.5rem",
});

export const FieldBox = () => ({
  paddingBottom: "1rem",
});

export const FieldLabel = () => ({
  margin: "0.8rem 0",
});

export const VerticalDivider = () => ({
  height: "4.5rem",
});

export const FaxField = () => ({
  paddingRight: "0.8rem",
});

import { Reducer } from "redux";

import { StaffingListResponse } from "../models/Api/Staffing";
import { StaffingListDispatchTypes } from "../models/DispatchTypes/Staffing";
import { StaffingListActionDispatchTypes } from "../constants/Staffing";

export const initialState: StaffingListResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [
      {
        patientId: -1,
        serviceRequestId: -1,
        referralId: -1,
        authorizationNumber: "",
        fullName: "",
        gender: "",
        urgencyStatus: "",
        requestDateTime: "",
        staffingStatus: "",
        assignedUserId: -1,
        assignedUserFullName: "",
        healthPlanName: "",
        patientState: "",
        facilityTypeName: "",
        providerNpi: "",
        providerName: "",
        isReferralActive: false,
        county: "",
        stage: ""
      },
    ],
  },
};

const StaffingListReducer: Reducer<
  StaffingListResponse,
  StaffingListDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case StaffingListActionDispatchTypes.STAFFING_LIST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case StaffingListActionDispatchTypes.STAFFING_LIST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case StaffingListActionDispatchTypes.STAFFING_LIST_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  }
  return state;
};

export default StaffingListReducer;

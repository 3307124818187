export enum AuthorizationHistoryActionDispatchTypes {
  AUTHORIZATION_HISTORY_INVOKE = "AUTHORIZATION_HISTORY_INVOKE",
  AUTHORIZATION_HISTORY_RESET = "AUTHORIZATION_HISTORY_RESET",
  AUTHORIZATION_HISTORY_SUCCESS = "AUTHORIZATION_HISTORY_SUCCESS",
}

export enum EligibilityHistoryActionDispatchTypes {
  ELIGIBILITY_HISTORY_ERROR = "ELIGIBILITY_HISTORY_ERROR",
  ELIGIBILITY_HISTORY_INVOKE = "ELIGIBILITY_HISTORY_INVOKE",
  ELIGIBILITY_HISTORY_SUCCESS = "ELIGIBILITY_HISTORY_SUCCESS",
  ELIGIBILITY_HISTORY_RESET = "ELIGIBILITY_HISTORY_RESET",
}

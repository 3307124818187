import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { flexAllCentre } from "../../../../styles/mui/styles/display";
import fontWeight from "../../../../styles/mui/fontWeight";
import { colors } from "../../../../styles/colors";

export interface Props {
  alertMessage: string;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}
const InfoDialog = ({ isOpen, setIsOpen, alertMessage }: Props) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="body1"
          fontSize=".85rem"
          fontWeight={fontWeight.Weight[6]}
          color={colors.fonts[18]}
        >
          {alertMessage}
        </Typography>
      </DialogTitle>
      <DialogActions sx={flexAllCentre}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;

import { colors } from "../../../../styles/colors";

export const NppesOuterBodyGrid = () => ({
  backgroundColor: colors.backgroundColor[7],
  opacity: 1,
  padding: "1.3rem 0.5rem",
  borderRadius: "1.2rem",
  width: "100%",
});

export const NppesNameStyle = {
  paddingLeft: "2rem",
  paddingTop: "0.55rem",
};
export const SearchNpiContainer = () => ({
  maxWidth: "23.5rem",
});

export const NppesStateSearchWidth = {
  minWidth: "12rem",
};

export const NppesErrorMsgStyle = {
  position: "absolute",
  width: "max-content",
};

export const NppesParentElementStyle = {
  position:"relative"
}

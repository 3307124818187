import { Controller } from "react-hook-form";
import { Box, FormHelperText, TextField } from "@mui/material";
import { debounce, isNil, isNull, isUndefined } from "lodash";
import { List } from "react-movable";
import { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FaGripLines, FaRegTrashAlt } from "react-icons/fa";

import { accent, colors } from "../../../../../styles/colors";
import {
  ICD10Text,
  ICD10CodeType,
} from "../../../../../constants/ReferralListTab";
import fontWeight from "../../../../../styles/mui/fontWeight";
import FormInputFieldIcon from "../../../../../components/formComponents/FormInputFieldIcon";
import {
  DiseaseType,
  IcdCodeType,
  icdDiseaseType,
} from "../../../../../models/Master";
import { SaveIcd10Records } from "../../../../../models/Patient";
import {
  AutocompleteTabelCell,
  AutocompleteUI,
  DeleteButton,
  DescriptionTabelCell,
  FaSearchIcon,
  horizontalDivider,
  NextStepButton,
  ReauthOpacity,
  RemoveRowDeleteButton,
  SummaryItemContainer,
  tableCellColor,
  tableCellNoColor,
  TableHeadStyle,
} from "../styles/style";
import { arrayMove, getValue, length } from "../../../../../utils";
import { InfoTable } from "../../../../../constants/AllPatientRecord";
import { rules } from "../../../../../utils/validation/Validation";
import { IconDisabled } from "../../../../../styles/common/style";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface PropsFromState {
  append: any;
  clearErrors: any;
  control: any;
  diseaseTypeResp: DiseaseType[];
  fields: InfoTable[];
  getValues: any;
  icd10Response: any;
  icdCodeDataType: IcdCodeType[];
  icdvalues: SaveIcd10Records;
  remove: (id: number) => void;
  reset: any;
  setValue: (id: any, value: any, boolean: any) => void;
  watch?: any;
  formState?: any;
}

export interface PropsFromDispatch {
  getDiseaseTypeData: (diseaseIdentifier: string) => void;
  getIcdeCodeDataType: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ICD10: React.FC<AllProps> = ({
  append,
  clearErrors,
  control,
  diseaseTypeResp,
  fields,
  getDiseaseTypeData,
  getIcdeCodeDataType,
  getValues,
  icdCodeDataType,
  icdvalues,
  remove,
  reset,
  setValue,
  watch,
  formState,
}: AllProps) => {
  const [items, setItems] = useState<InfoTable[]>(fields);
  const [codeType, setCodeType] = useState<IcdCodeType[]>([]);
  const [removeFlag, setRemoveFlag] = useState<boolean>(false);
  const [icdDiseaseTypeResp, setDiseaseTypeResp] = useState<icdDiseaseType[]>(
    []
  );
  const [dragged, setDragged] = useState<boolean>(false);

  useEffect(() => {
    getIcdeCodeDataType();
    setItems(fields);
  }, []);

  useEffect(() => {
    if (
      diseaseTypeResp &&
      diseaseTypeResp.length > 0 &&
      diseaseTypeResp[0].icdDiagnosisCodeId !== 0
    ) {
      const newArray = diseaseTypeResp.map(
        ({ icdDiagnosisCodeId, icdCode, icdDescription }) => ({
          diagnosisCode: icdCode,
          diagnosisCodeDescription: icdDescription,
          icdDiagnosisCodeId: icdDiagnosisCodeId,
        })
      );
      setDiseaseTypeResp(newArray);
    } else {
      setDiseaseTypeResp([]);
    }
  }, [diseaseTypeResp]);

  useEffect(() => {
    setCodeType(icdCodeDataType);
  }, [icdCodeDataType, fields]);

  useEffect(() => {
    setItems(fields);
    setDiseaseTypeResp([]);
  }, [fields]);

  useEffect(() => {
    if(!dragged) {
      const itemsData: InfoTable[] = items;
      setItems([]);
      setItems(itemsData);
    }
  }, [dragged]);

  const handleAddOperation = () => {
    const newIcdCode = {
      referralIcdCodeId: 0,
      diagnosisTypeId:
        items.length > 0 ? ICD10CodeType.SECONDARY : ICD10CodeType.PRIMARY,
      diagnosisCode: "",
      diagnosisCodeDescription: "",
      icdDiagnosisCodeId: null,
    };
    if (items.length <= 4) {
      setItems((items) => [...items, newIcdCode]);
      append({
        referralIcdCodeId: 0,
        diagnosisTypeId:
          items.length > 0 ? ICD10CodeType.SECONDARY : ICD10CodeType.PRIMARY,
        diagnosisCode: "",
        diagnosisCodeDescription: "",
        icdDiagnosisCodeId: null,
      });
    }
    setRemoveFlag(false);
  };

  const removeTableRow = (rowIndex: any) => {
    const newItems = [...items];
    const removedItem = newItems.splice(rowIndex, 1);
    if (removedItem && removedItem[0].diagnosisTypeId === 1) {
      setRemoveFlag(true);
    } else {
      setRemoveFlag(false);
    }
    setItems(items.splice(rowIndex, rowIndex));
    remove(Number(rowIndex));
  };

  useEffect(() => {
    if (removeFlag === true) {
      for (let i = 0; i < items.length; i++) {
        if (items[0].diagnosisTypeId === 2) {
          items[0].diagnosisTypeId = 1;
          setValue(`icdCodesInfoDto.${0}.diagnosisTypeId`, 1, true);
          setDiseaseTypeResp([]);
          reset({ ...getValues(), icdCodesInfoDto: items });
        }
      }
    }
  }, [removeFlag, items, watch("icdCodesInfoDto")]);

  const handleArrayMove = (firstIndex: any, secIndex: any) => {
    const newData = arrayMove(items, firstIndex, secIndex);
    for (let i = 1; i < newData.length; i++) {
      if (newData[i].diagnosisTypeId == 1) {
        newData[i].diagnosisTypeId = 2;
        newData[0].diagnosisTypeId = 1;
      } else {
        newData[0].diagnosisTypeId = 1;
      }
    }
    reset({ ...getValues(), icdCodesInfoDto: newData });
    setItems(newData);
  };

  const commonText = (labelText: string) => {
    return (
      <>
        {labelText === "ADD ICD 10 CODES:" ? (
          <Typography
            variant="h6"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[20]}
          >
            {labelText}
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            color={colors.fonts[4]}
            fontWeight={fontWeight.Weight[5]}
          >
            {labelText}
          </Typography>
        )}
      </>
    );
  };

  const diseaseCode = (disease: string) => {
    const diseaseCode = disease.split("-");
    return diseaseCode[0];
  };

  const handleDiseaseTypeChange = (
    _event: any,
    value: any,
    diseaseId: any,
    diagnosisCode: any,
    index: any
  ) => {
    const itemList = items;
    itemList[index].icdDiagnosisCodeId =
      value !== null ? value.icdDiagnosisCodeId : "";
    itemList[index].diagnosisCode = value !== null ? value.diagnosisCode : "";
    itemList[index].diagnosisCodeDescription =
      value !== null ? value.diagnosisCodeDescription : "";
    setItems(itemList);
    setValue(diagnosisCode, value !== null ? value.diagnosisCode : "", true);
    setValue(diseaseId, value !== null ? value.icdDiagnosisCodeId : "", true);

    if (isNull(value)) {
      setValue(`icdCodesInfoDto.${index}.diagnosisCodeDescription`, "", true);
    } else {
      const icd10Description = icdDiseaseTypeResp.filter(
        (val) => val.diagnosisCode === value.diagnosisCode
      );
      setValue(
        `icdCodesInfoDto.${index}.diagnosisCodeDescription`,
        icd10Description[0].diagnosisCodeDescription,
        true
      );
    }
    clearErrors(diseaseId);
    setDiseaseTypeResp([]);
  };

  const handleDiseaseTypeData = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (!isNil(value) && !isNil(event)) {
      if (value.length >= 2 && reason === "input") {
        getDiseaseTypeData(value);
      }
      if (value.length === 0) {
        setDiseaseTypeResp([]);
      }
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((event, value, reason) => {
        handleDiseaseTypeData(event, value, reason);
      }, 500),
    []
  );

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debouncedSearch(event, value, reason);
  };

  const getDescription = (index: number) => {
    if (isUndefined(index)) {
      return;
    }

    const description =
      icdvalues &&
        length(icdvalues.icdCodesInfoDto) &&
        icdvalues.icdCodesInfoDto[index].diagnosisCodeDescription
        ? icdvalues.icdCodesInfoDto[index].diagnosisCodeDescription
        : [];
    return length(description) ? description : "";
  };

  const sortableListRef = useRef<any>(null);

  const handleDragStart = (index: any) => {
    sortableListRef.current = index;
  };

  const handleDraggableClick = (event: any, index: any) => {
    const clickedCellIndex = event.currentTarget.cellIndex;
    if (clickedCellIndex !== 0) {
      event.stopPropogation();
    } else {
      handleDragStart(index);
    }
  };

  const codeTypeList = (codeType: IcdCodeType[] | undefined) => {
    if (codeType && length(codeType)) {
      return codeType.map((code, index) => ({
        label: code.diagnosisType,
        value: code.diagnosisTypeId,
        disabled: index === 0 && code.diagnosisTypeId === 1 ? true : false,
      }));
    }
    return [];
  };

  return (
    <Grid item xs={12} sx={SummaryItemContainer}>
      <Box sx={displayFlex}>
        {commonText("ADD ICD 10 CODES:")}
        {formState && (
          <Typography variant="subtitle1" color={colors.red[100]} ml={"0.2rem"}>
            *
          </Typography>
        )}
      </Box>
      <Grid container>
        <Grid item xs={9.5}>
          <Typography
            variant="body1"
            color={colors.black[5]}
            fontWeight={fontWeight.Weight[2]}
            sx={ReauthOpacity}
            pt={"0.7rem"}
          >
            {ICD10Text}
          </Typography>
          <Typography variant="subtitle2" color={colors.red[100]} pt={"0.5rem"}>
            {getValue(formState, "errors.icdCodesInfoDto.root.message", null)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={1.5} pt={"0.4rem"}>
              <FaGripLines color={colors.fonts[5]} />
            </Grid>
            <Grid item xs={7.5} pt={"0.6rem"}>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[5]}
                sx={ReauthOpacity}
              >
                {" "}
                Drag to reorder
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {items.length > 4 ? (
                <Button variant="contained" sx={NextStepButton} disabled>
                  ADD
                </Button>
              ) : (
                <Permission
                  controlId={`${PatientRecordsAccess.DIAGNOSIS_CODE_CREATE}|${PatientRecordsAccess.DIAGNOSIS_CODE_READ}|`}
                  passThrough
                >
                  {(isDisabled: boolean) => (
                    <Button
                      variant="contained"
                      sx={NextStepButton}
                      onClick={handleAddOperation}
                      disabled={isDisabled}
                    >
                      ADD
                    </Button>
                  )}
                </Permission>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        sx={horizontalDivider}
      />
      <Grid container>
        <Grid item xs={12} pt={"1rem"}>
          <List
            ref={sortableListRef}
            values={items}
            lockVertically={true}
            onChange={({ oldIndex, newIndex }) =>
              handleArrayMove(oldIndex, newIndex)
            }
            renderList={({ children, props, isDragged }) => (
              <Table
                style={{
                  cursor: isDragged ? "grabbing" : undefined,
                }}
              >
                <TableHead sx={TableHeadStyle}>
                  <TableRow>
                    <TableCell width="5%"></TableCell>
                    <TableCell width="25%">
                      {commonText("Add Diagnosis Code")}
                    </TableCell>
                    <TableCell width="45%">
                      {commonText("Code Description")}
                    </TableCell>
                    <TableCell width="15%">{commonText(" Type:")}</TableCell>
                    <TableCell width="10%">{commonText("Actions:")}</TableCell>
                  </TableRow>
                </TableHead>
                <tbody {...props}>{children}</tbody>
              </Table>
            )}
            renderItem={({ props, isDragged, index }) => {
              //work around applied to fix react movable library issue with validation failure
              setTimeout(() => {
                setDragged(isDragged);
              }, 0)
              const row = (
                <TableRow
                  key={index}
                  {...props}
                  sx={
                    items && items[Number(index)].diagnosisTypeId === 1
                      ? tableCellColor
                      : tableCellNoColor
                  }
                >
                  <TableCell
                    onMouseDown={(event) => handleDraggableClick(event, index)}
                    style={{
                      cursor: "grab",
                    }}
                    width="2%"
                    height="9rem"
                  >
                    <FaGripLines color={colors.fonts[5]} />
                  </TableCell>
                  <TableCell
                    width="25%"
                    height="9rem"
                    sx={AutocompleteTabelCell}
                  >
                    <Box height="4.4rem">
                      <Controller
                        render={({ field: { ref }, fieldState: { error } }) => {
                          return (
                            <>
                              <Autocomplete
                                id="combo-box-demo"
                                onBlur={() => setDiseaseTypeResp([])}
                                sx={AutocompleteUI}
                                freeSolo
                                PopperComponent={StyledPopperCheckBoxAutocomplete}
                                options={
                                  icdDiseaseTypeResp &&
                                    icdDiseaseTypeResp.length > 0
                                    ? icdDiseaseTypeResp
                                    : []
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.icdDiagnosisCodeId ===
                                  value.icdDiagnosisCodeId
                                }
                                filterOptions={(icdDiseaseTypeResp) =>
                                  icdDiseaseTypeResp
                                }
                                disabled={
                                  getValues(
                                    `icdCodesInfoDto.${index}.referralIcdCodeId`
                                  ) !== 0
                                    ? true
                                    : false
                                }
                                onInputChange={handleInputChange}
                                renderInput={(params) => {
                                  params.inputProps.maxLength =
                                    rules.icd10Code.maxLength.value;
                                  return (
                                    <>
                                      <FormInputFieldIcon
                                        iconStart={<FaSearchIcon />}
                                        elementRef={ref}
                                        {...params}
                                      />
                                    </>
                                  );
                                }}
                                renderOption={(props, option) => {
                                  return (
                                    <ListItem
                                      {...props}
                                      key={option.diagnosisCode}
                                    >
                                      {option.diagnosisCode + " - "}
                                      {option.diagnosisCodeDescription}
                                    </ListItem>
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  typeof option === "string"
                                    ? option
                                    : option.diagnosisCode !== undefined
                                      ? diseaseCode(option.diagnosisCode)
                                      : ""
                                }
                                value={
                                  icdvalues &&
                                  icdvalues.icdCodesInfoDto &&
                                  icdvalues.icdCodesInfoDto[Number(index)]
                                }
                                onChange={(e, val) =>
                                  handleDiseaseTypeChange(
                                    e,
                                    val,
                                    `icdCodesInfoDto.${index}.icdDiagnosisCodeId`,
                                    `icdCodesInfoDto.${index}.diagnosisCode`,
                                    index
                                  )
                                }
                              />
                              {error && (
                                <FormHelperText>{error.message}</FormHelperText>
                              )}
                            </>
                          );
                        }}
                        name={`icdCodesInfoDto.${index}.icdDiagnosisCodeId`}
                        control={control}
                        rules={rules.icd10Code}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    width="45%"
                    height="9rem"
                    sx={DescriptionTabelCell}
                  >
                    <Box height="4.4rem">
                      <TextField
                        variant="standard"
                        fullWidth
                        maxRows={3}
                        value={getDescription(Number(index)) || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    width="16%"
                    height="9rem"
                    sx={AutocompleteTabelCell}
                  >
                    <Box height="4.4rem">
                      <FormControl variant="standard" fullWidth>
                        <FormInputDropdown
                          readOnly
                          control={control}
                          name={`icdCodesInfoDto.${index}.diagnosisTypeId`}
                          list={codeTypeList(codeType)}
                          helper={rules.icd10CodeType}
                        />
                      </FormControl>
                    </Box>
                  </TableCell>
                  <TableCell
                    width="12%"
                    height="9rem"
                    sx={RemoveRowDeleteButton}
                  >
                    <Permission
                      controlId={`${PatientRecordsAccess.DIAGNOSIS_CODE_CREATE}|${PatientRecordsAccess.DIAGNOSIS_CODE_READ}|`}
                      passThrough
                    >
                      {(isDisabled: boolean) => (
                        <Button
                          onClick={() => removeTableRow(index)}
                          style={DeleteButton}
                          disabled={isDisabled}
                        >
                          <FaRegTrashAlt
                            size={"1rem"}
                            color={accent.accentOrange[100]}
                            style={isDisabled ? IconDisabled : {}}
                          />
                        </Button>
                      )}
                    </Permission>
                  </TableCell>
                </TableRow>
              );
              return isDragged ? (
                <Table style={{ borderSpacing: 0 }}>
                  <TableBody>{row}</TableBody>
                </Table>
              ) : (
                row
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ICD10;

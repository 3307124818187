export const flexAlignCentre = () => ({
  display: "flex",
  alignItems: "center",
});

export const displayFlex = () => ({
  display: "flex",
});

export const justifyContentRight = () => ({
  justifyContent: "right",
});

export const justifyContentLeft = () => ({
  justifyContent: "left",
});

export const justifyContentEnd = () => ({
  justifyContent: "flex-end",
});

export const displayFlexEnd = () => ({
  display: "flex",
  justifyContent: "flex-end",
});

export const flexAllCentre = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const flexAllStart = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
});

export const flexAllEnd = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
});
export const justifyContentSpaceBetween = () => ({
  justifyContent: "space-between",
});

export const justifyContentStart = () => ({
  justifyContent: "flex-start",
});

export const flexColumn = () => ({
  display: "flex",
  flexDirection: "column",
});

export const flexRow = () => ({
  display: "flex",
  flexDirection: "row",
});

export const flexAlignEnd = () => ({
  display: "flex",
  alignItems: "end",
});

export const flexAlignBaseline = () => ({
  display: "flex",
  alignItems: "baseline",
});

export const alignStart = () => ({
  alignItems: "start",
});

export const myPatientRemoveBtn = () => ({
  paddingRight: "1.1rem",
});

export const flexRowCenter = () => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const overFlowHidden = () => ({
  overflow: "hidden",
});

export const BreakWord = () => ({
  display: "flex",
  wordBreak: "break-word",
});
import { Menu } from "@mui/material";
import { alpha, styled } from "@mui/system";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

import { colors, others } from "../../../../styles/colors";


export const ArrowDropDownIcon: any = styled(ArrowDropDown)(() => ({
    fontSize: "1.85rem",
    color: colors.backgroundColor[2],
}));

export const ArrowDropUpIcon: any = styled(ArrowDropUp)(() => ({
    fontSize: "1.85rem",
    color: colors.backgroundColor[2],
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({    
    '& .MuiPaper-root': {
        width: "100%",
        borderRadius: 6,
        marginTop: "0.3rem",
        marginLeft:'-0.3rem',
        color: others.otherColors[73],
        boxShadow: `0px 8px 16px ${others.otherColors[74]}`,
        '& .MuiMenu-list': {
            padding: '0.25rem 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: "1.1rem",
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
import { accent } from "../../../../../../../styles/colors";
import colors from "../../../../../../../styles/colors/colors";

export const GridContainer = () => ({
  backgroundColor: colors.backgroundColor[2],
  borderBottom: `1px solid ${colors.borders[1]}`,
  paddingLeft: "1rem"
});

export const ServiceRequestTableHead = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0rem 1.9rem 1.5rem ",
    borderBottom: `1.8px solid ${colors.dropShadow[4]}`,
  },
});

export const ServiceRequestTableBody = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0.5rem 2.1rem 1.5rem",
  },
});

export const DisciplineBodyTableRow = () => ({
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
});

export const HorizontalDivider = () => ({
  margin: "1.2rem 0 0.8rem 0",
  width: "100%",
});

export const ItemIconBox = () => ({
  color: accent.accentGreen[100],
  paddingRight: "0.6rem",
});

export const DocumentIconBox = () => ({
  color: colors.primary.main,
  paddingRight: "0.5rem",
});

export const GridLeftBody = () => ({
  padding: "1.5rem 0 3rem 2rem",
});

export const GridRightBody = () => ({
  padding: "1.5rem 0.5rem 3rem 1rem",
});

export const VerticalDivider = () => ({
  padding: "0.8rem 0 1.5rem 0.5rem",
});

export const CareTypeGrid = () => ({
  padding: "6rem 2rem 3rem 4rem",
});

export const CareTypeTitle = () => ({
  paddingBottom: "0.5rem",
  paddingLeft: "0.5rem",
});

export const CareTypeData = () => ({
  paddingTop: "1rem",
  paddingLeft: "0.5rem",
});

export const RequestedVisitTablecell = () => ({
  paddingLeft: "4rem",
});
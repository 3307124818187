import { Reducer } from "redux";

import { ReauthDocumentReviewResponse } from "../models/Api/Document";
import { SaveUpdateDocumentDetailActionDispatchTypes } from "../constants/Document";
import { ReauthDocumentDetailActionDispatchTypes } from "../models/DispatchTypes/Document";

export const initialPostState: ReauthDocumentReviewResponse = {
  loading: false,
  response: {
    referralDocument: {
      id: 0,
      reAuthId: 0,
      containsSupportedDoc: null,
      documentationDate: "",
      documentId: [],
      userCreated: null,
      type: 0,
    },
    blobDocument: [],
  },
  error: null,
};

const ReauthDocumentReviewReducer: Reducer<
  ReauthDocumentReviewResponse,
  ReauthDocumentDetailActionDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReauthDocumentReviewReducer;

import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  PostSurveyRequestDispatchTypes,
  DisciplineNoteDispatchTypes,
  AdminNotesListDispatchTypes,
  DeactivateNoteDispatchTypes,
  DeleteAdminNotesTemplateDispatchTypes,
  CategoryDispatchTypes,
  SaveNotesDispatchTypes,
  UpdateNotesDispatchTypes,
  DisciplineTemplateNotesDispatchTypes,
  GetDisciplineLevelNotesDispatchTypes,
  SaveDisciplineLevelNotesDispatchTypes,
  UpdateDisciplineLevelNotesDispatchTypes,
  GetReferralNoteDispatchTypes,
  GetReferralLevelNotesDataDispatchTypes,
  SaveReferralLevelNotesDispatchTypes,
  UpdateReferralLevelNotesDispatchTypes,
  NotesTemplateDispatchTypes,
  GetNotesDispatchTypes,
  AgencyCommunicationNoteDispatchTypes,
  LevelOfFunctionDispatchTypes,
} from "../models/DispatchTypes/Notes";
import {
  GetDisciplineNoteList,
  GetReferralNoteList,
  PostAdminNotesList,
  UpdateSurveyDetails,
  DeactivateNotesTemplate,
  DeleteNotesTemplate,
  GetNotesCategoryMaster,
  SaveNotesSection,
  UpdateNotesSection,
  GetDisciplineTemplateNotesMaster,
  GetDisciplineNoteData,
  SaveDisciplineNoteData,
  UpdateDisciplineNoteData,
  GetReferralNoteData,
  SaveReferralNoteData,
  UpdateReferralNoteData,
  GetTemplateById,
  GetNotesByTemplateVersionId,
  PostAgencyCommunicationNote,
  GetLevelOfFunction,
} from "../services/Notes";
import {
  NoteTemplateListActionDispatchTypes,
  AdminNotesTemplateDeleteActionDispatchTypes,
  DeactivateNoteActionDispatchTypes,
  CategoryActionDispatchTypes,
  SaveAdminNotesActionDispatchTypes,
  UpdateAdminNotesActionDispatchTypes,
  SurveyDetailsUpdateActionDispatchType,
  DisciplineNoteActionDispatchTypes,
  MasterDisciplineTemplateNotesActionDispatchTypes,
  GetDisciplineLevelNotesActionDispatchTypes,
  SaveDisciplineLevelNotesActionDispatchTypes,
  UpdateDisciplineLevelNotesActionDispatchTypes,
  ReferralNoteActionDispatchTypes,
  GetReferralLevelNotesActionDispatchTypes,
  SaveReferralLevelNotesActionDispatchTypes,
  UpdateReferralLevelNotesActionDispatchTypes,
  GetNotesTemplateActionDispatchTypes,
  GetAdminNotesByVersionIdActionDispatchTypes,
  AdminDefaultNoteList,
  PostAgencyCommunicationNoteActionDispatchTypes,
  LevelOfFunctionActionDispatchTypes,
} from "../constants/Notes";
import { MessageType, ResponseMessages } from "../constants/ToastMessage";
import {
  AgencyCommunicationNote,
  DisciplineLevelNote,
  ReferralLevelNote,
  SaveNotes,
  TemplateSearch,
} from "../models/Notes";
import SnackbarUtils from "../utils/SnackbarProvider";
import { ApplicationState } from "../store/ApplicationState";
import { configUrlForNote, configUrlForReferral } from "../constants/Config";
import { DisciplineNoteSearch } from "../models/Disciplines";
import { isNil } from "../utils";

export const postAdminNotesList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AdminNotesListDispatchTypes
  >
> = (payload: TemplateSearch) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_INVOKE,
    });

    const response = await PostAdminNotesList(configUrlForNote, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response: AdminDefaultNoteList,
            type: NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: NoteTemplateListActionDispatchTypes.POST_NOTE_TEMPLATE_LIST_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const deactivateNotesTemplate: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DeactivateNoteDispatchTypes
  >
> = (id: number, isActive: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_INVOKE,
    });

    const response = await DeactivateNotesTemplate(
      configUrlForNote,
      id,
      isActive
    );

    if (isNil(response)) {
      dispatch({
        response: true,
        type: DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_ERROR,
      });
      SnackbarUtils.error(ResponseMessages(MessageType.REQUEST_FAILED));
    } else {
      dispatch({
        response: response,
        type: DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_SUCCESS,
      });
      SnackbarUtils.success(ResponseMessages(MessageType.DEACTIVATE_SUCCESS));
    }
  };
};

export const deleteAdminNotesTemplate: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DeleteAdminNotesTemplateDispatchTypes
  >
> = (templateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_INVOKE,
    });

    const response = await DeleteNotesTemplate(configUrlForNote, templateId);

    if (isNil(response)) {
      dispatch({
        response: true,
        type: AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_ERROR,
      });
      SnackbarUtils.error(ResponseMessages(MessageType.REQUEST_FAILED));
    } else {
      dispatch({
        response: response,
        type: AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_SUCCESS,
      });
      SnackbarUtils.success(ResponseMessages(MessageType.DELETE_SUCCESS));
    }
  };
};

export const getNotesCategoryMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CategoryDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CategoryActionDispatchTypes.MASTER_CATEGORY_INVOKE,
    });

    const response = await GetNotesCategoryMaster(configUrlForNote);

    dispatch({
      response,
      type: CategoryActionDispatchTypes.MASTER_CATEGORY_SUCCESS,
    });
  };
};

export const saveNotesSection: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SaveNotesDispatchTypes
  >
> = (payload: SaveNotes) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SaveAdminNotesActionDispatchTypes.SAVE_NOTES_INVOKE,
    });

    const response = await SaveNotesSection(payload, configUrlForNote);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            error: response.data,
            type: SaveAdminNotesActionDispatchTypes.SAVE_NOTES_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: SaveAdminNotesActionDispatchTypes.SAVE_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.SAVE_ADMIN_NOTES_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const updateNotesSection: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdateNotesDispatchTypes
  >
> = (id: number, payload: SaveNotes) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_INVOKE,
    });

    const response = await UpdateNotesSection(id, payload, configUrlForNote);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            error: response.data,
            type: UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.UPDATE_NOTES_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const GetNotesSectionById: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    NotesTemplateDispatchTypes
  >
> = (templateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetNotesTemplateActionDispatchTypes.NOTES_TEMPLATE_JSON_INVOKE,
    });

    const response = await GetTemplateById(templateId, configUrlForNote);
    dispatch({
      response,
      type: GetNotesTemplateActionDispatchTypes.NOTES_TEMPLATE_JSON_SUCCESS,
    });
  };
};

export const GetNotesSectionByVersionId: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GetNotesDispatchTypes
  >
> = (templateVersionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_INVOKE,
    });

    const response = await GetNotesByTemplateVersionId(
      templateVersionId,
      configUrlForNote
    );
    dispatch({
      response,
      type: GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_SUCCESS,
    });
  };
};

export const getDisciplineNoteList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineNoteDispatchTypes
  >
> = (payload: DisciplineNoteSearch) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_INVOKE,
    });
    const response = await GetDisciplineNoteList(configUrlForReferral, payload);
    dispatch({
      response,
      type: DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_SUCCESS,
    });
  };
};

export const updateSurveyDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PostSurveyRequestDispatchTypes
  >
> = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_INVOKE,
    });

    const response = await UpdateSurveyDetails(payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.SAVE_SERVICE_REQUEST)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getDisciplineTemplateNotesMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineTemplateNotesDispatchTypes
  >
> = (categoryId?: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MasterDisciplineTemplateNotesActionDispatchTypes.DISCIPLINE_NOTES_INVOKE,
    });

    const response = await GetDisciplineTemplateNotesMaster(
      configUrlForNote,
      categoryId
    );

    dispatch({
      response,
      type: MasterDisciplineTemplateNotesActionDispatchTypes.DISCIPLINE_NOTES_SUCCESS,
    });
  };
};

export const getDisciplineLevelNotes: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GetDisciplineLevelNotesDispatchTypes
  >
> = (disciplineNoteId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_INVOKE,
    });
    const response = await GetDisciplineNoteData(
      configUrlForReferral,
      disciplineNoteId
    );
    dispatch({
      response,
      type: GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_SUCCESS,
    });
  };
};

export const SaveDisciplineLevelNotesData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SaveDisciplineLevelNotesDispatchTypes
  >
> = (payload: DisciplineLevelNote) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_INVOKE,
    });

    const response = await SaveDisciplineNoteData(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_ERROR,
          });
          SnackbarUtils.error(ResponseMessages(MessageType.REQUEST_FAILED));
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.SAVE_DISCIPLINE_LEVEL_NOTES)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const UpdateDisciplineLevelNoteData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdateDisciplineLevelNotesDispatchTypes
  >
> = (disciplineNoteId: number, payload: DisciplineLevelNote) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateDisciplineLevelNotesActionDispatchTypes.UPDATE_DISCIPLINE_NOTES_INVOKE,
    });

    const response = await UpdateDisciplineNoteData(
      configUrlForReferral,
      disciplineNoteId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: UpdateDisciplineLevelNotesActionDispatchTypes.UPDATE_DISCIPLINE_NOTES_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UpdateDisciplineLevelNotesActionDispatchTypes.UPDATE_DISCIPLINE_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.UPDATE_DISCIPLINE_LEVEL_NOTES)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getReferralNoteList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GetReferralNoteDispatchTypes
  >
> = (payload: DisciplineNoteSearch) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_INVOKE,
    });
    const response = await GetReferralNoteList(configUrlForReferral, payload);
    dispatch({
      response,
      type: ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_SUCCESS,
    });
  };
};

export const getReferralLevelNotes: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GetReferralLevelNotesDataDispatchTypes
  >
> = (referralNoteId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_INVOKE,
    });
    const response = await GetReferralNoteData(
      configUrlForReferral,
      referralNoteId
    );

    dispatch({
      response,
      type: GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_SUCCESS,
    });
  };
};

export const SaveReferralLevelNoteData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SaveReferralLevelNotesDispatchTypes
  >
> = (payload: ReferralLevelNote) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_INVOKE,
    });

    const response = await SaveReferralNoteData(configUrlForReferral, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_ERROR,
          });
          SnackbarUtils.error(ResponseMessages(MessageType.REQUEST_FAILED));
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.SAVE_REFERRAL_LEVEL_NOTES)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const UpdateReferralLevelNotesData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdateReferralLevelNotesDispatchTypes
  >
> = (referralNoteId: number, payload: ReferralLevelNote) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_INVOKE,
    });

    const response = await UpdateReferralNoteData(
      configUrlForReferral,
      referralNoteId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.UPDATE_REFERRAL_LEVEL_NOTES)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const PostAgencyCommunicationNoteAction: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AgencyCommunicationNoteDispatchTypes
  >
> = (payload: AgencyCommunicationNote) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_INVOKE,
    });

    const response = await PostAgencyCommunicationNote(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.AGENCY_COMMUNICATION_NOTE)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getLevelOfFunction: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    LevelOfFunctionDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LevelOfFunctionActionDispatchTypes.LEVEL_OF_FUNCTION_INVOKE,
    });
    const response = await GetLevelOfFunction(configUrlForReferral, referralId);

    dispatch({
      response,
      type: LevelOfFunctionActionDispatchTypes.LEVEL_OF_FUNCTION_SUCCESS,
    });
  };
};

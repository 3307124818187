import { Reducer } from "redux";

import { AuthorizationHistoryResponse } from "../models/Api/AuthorizationHistory";
import { AuthorizationHistoryDispatchTypes } from "../models/DispatchTypes/AuthorizationHistory";
import { AuthorizationHistoryActionDispatchTypes } from "../constants/AuthorizationHistory";

const initialState: AuthorizationHistoryResponse = {
  loading: false,
  response: {
    totalRows: 0,
    authorizationHistory: [],
  },
};

const AuthorizationHistoryReducer: Reducer<
  AuthorizationHistoryResponse,
  AuthorizationHistoryDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_RESET: {
      return initialState;
    }
  }
  return state;
};

export default AuthorizationHistoryReducer;

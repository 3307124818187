import colors from "../../../../../styles/colors/colors";

export const MainBox = () => ({
  position: "absolute",
  top: "2rem",
  left: "5%",
  width: "90%",
  bgcolor: colors.backgroundColor[1],
  margin: "1rem",
  borderRadius: "1.5rem",
  paddingTop: "1rem",
  marginLeft: "1rem",
  padding: "1rem",
});

export const CloseButton = () => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "0.5rem 0rem",
});

export const NotesViewHeaderTitle = {
  margin: "1rem 0rem",
};

export const NotesViewHeader = {
  display: "flex",
  gap: "0.2rem",
  alignItems: "center",
};
export const NotesViewCloseIcon = {
  justifyContent: "end",
  textAlign: "right",
};

export const NotesGridStyle = {
  display: "flex",
  alignItems: "center",
};

export const NotesDividerStyle = {
  margin: "0.5rem  0.5rem 0.5rem 0.5rem",
  borderRightWidth: 1,
  height: "2rem",
};

export const NotePreview = () => ({
  overflow: "scroll",
  pointerEvents: "none",
  maxHeight: "80%",
  minHeight: "10%",
  "&::-webkit-scrollbar": {
    width: ".25rem",
    height: "0.3rem",
    paddingLeft: "2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
  borderRadius: "1rem",
  height: "90%",
});

export const AutoCompleteDivider = () => ({
  borderRight: `1px solid ${colors.borders[2]}`,
});

export const VersionStatusDivider = () => ({
  borderLeft: `1px solid ${colors.borders[2]}`,
  marginLeft: "1rem",
});

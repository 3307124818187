import { Reducer } from "redux";

import { ExcelDownloadResponse } from "../models/Api/Document";
import { ExcelDownloadActionDispatchTypes } from "../models/DispatchTypes/Document";
import { ExcelDownloadDispatchTypes } from "../constants/Document";

const initialState: ExcelDownloadResponse = {
  loading: false,
  response: {} as Blob,
  error: null,
};

const ExcelDownloadReducer: Reducer<
  ExcelDownloadResponse,
  ExcelDownloadActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ExcelDownloadDispatchTypes.EXCEL_DOWNLOAD_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ExcelDownloadReducer;

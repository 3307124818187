import { Reducer } from "redux";

import { OrderAcceptorResponse } from "../models/Api/Master";
import { OrderAcceptorDispatchTypes } from "../models/DispatchTypes/Master";
import { OrderAcceptorActionDispatchTypes } from "../constants/Master";

const initialState: OrderAcceptorResponse = {
  loading: false,
  response: [
    {
      acceptedById: 0,
      acceptedByName: "",
    },
  ],
};

const OrderAcceptor: Reducer<
  OrderAcceptorResponse,
  OrderAcceptorDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case OrderAcceptorActionDispatchTypes.ORDER_ACCEPTOR_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case OrderAcceptorActionDispatchTypes.ORDER_ACCEPTOR_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default OrderAcceptor;

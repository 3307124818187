import { googleMapKey } from "../constants/Config";
import { Loader } from "@googlemaps/js-api-loader";

import { countryAbbreviations } from "../constants/CountryAbbreviations";

interface Address {
  city: string | null;
  patientState: string | null;
  zipCode: string | null;
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  country: string | null;
  streetNumber: string | null;
}

export const initMapScript = () => {
  const loader = new Loader({
    apiKey: googleMapKey,
    version: "weekly",
  });
  return loader.importLibrary("places");
};

export const extractPatientAddress = (place: any) => {
  const address: Address = {
    city: null,
    patientState: null,
    zipCode: null,
    streetName1: null,
    streetName2: null,
    county: null,
    country: null,
    streetNumber: null,
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(
    (component: {
      types: Array<string>;
      long_name: string;
      short_name: string;
    }) => {
      const types = component.types;
      const value = component.long_name;
      const short_name = component.short_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("street_number")) {
        address.streetNumber = value;
      }

      if (types.includes("route")) {
        address.streetName1 = value;
      }

      if (types.includes("neighborhood")) {
        address.streetName2 = value;
      }

      if (types.includes("administrative_area_level_2")) {
        address.county = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.patientState = value;
      }

      if (types.includes("postal_code")) {
        address.zipCode = value;
      }

      if (types.includes("country")) {
        address.country = countryAbbreviations[short_name] || short_name;
      }
    }
  );

  return address;
};

import { FaRegQuestionCircle } from "react-icons/fa";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  BackButton,
  ContentBox,
  ContentBoxHeader,
  CreateBtn,
  CrossIconBox,
  DetailsDivider,
  DobBox,
  GridBody,
  GridFooter,
  GridPatientInfo,
  HeaderBox,
  Info,
  ModalGrid,
  ModalTitle,
  NameBox,
  OuterBodyGrid,
  Question,
  QuestionBox,
  QuestionIcon,
} from "../styles/Modal";
import { Data } from "../styles/CreateReferral";
import { ConfirmationQuestion } from "../../../../../constants/InformationReceived";
import { PatientInformationView } from "../../../../../models/Patient";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import {
  MessageType,
  ResponseMessages,
} from "../../../../../constants/ToastMessage";
import { DateFormats } from "../../../../../constants/Date";
import {
  IconFaTimesCircle,
  ModalGridContainer,
} from "../../../../../styles/common/style";
import { getValue } from "../../../../../utils";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { formatDate } from "../../../../../utils/DateTime";
import { addressRegex } from "../../../../../constants/Regex";

type ModalProps = {
  openModal: boolean;
  closeModal: (onClose: boolean) => void;
  saveInfoReceived?: () => void;
  patientInfoResp: PatientInformationView;
  patientId: string;
  isUpdateManually?: boolean;
};

const CreateReferralModal = ({
  openModal,
  patientInfoResp,
  closeModal,
  saveInfoReceived,
  patientId,
  isUpdateManually,
}: ModalProps) => {
  const handleClose = () => {
    closeModal(false);
  };
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!isUpdateManually && saveInfoReceived) {
      saveInfoReceived();
    }
    navigate(
      ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE, {
        referralId: 0,
        patientId: Number(patientId),
        additionalParams: 0,
      })
    );
    if (!isUpdateManually) {
      SnackbarUtils.success(ResponseMessages(MessageType.INFORMATION_RECEIVED));
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={ModalGridContainer}
      >
        <Box sx={ModalGrid}>
          <Grid container sx={ContentBoxHeader}>
            <Grid item xs={11} sx={HeaderBox}>
              <Typography
                variant="h2"
                color={colors.fonts[4]}
                fontWeight={fontWeight.Weight[5]}
                sx={ModalTitle}
              >
                Create referral confirmation:
              </Typography>
            </Grid>
            <Grid item xs={1} sx={CrossIconBox}>
              <IconFaTimesCircle onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container sx={OuterBodyGrid}>
            <Grid item xs={12} sx={GridBody}>
              <Grid container>
                <Grid item xs={3} sx={GridPatientInfo}>
                  <Box>
                    <Typography
                      variant="h5"
                      color={colors.fonts[18]}
                      fontWeight={fontWeight.Weight[6]}
                      sx={NameBox}
                    >
                      {patientInfoResp && patientInfoResp.patientName}
                    </Typography>
                  </Box>
                  <Box sx={ContentBox}>
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[5]}
                      sx={Info}
                    >
                      Subscriber ID:
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[6]}
                      color={colors.black[2]}
                      sx={Info}
                    >
                      {getValue(patientInfoResp, "memberInfo.subscriberId", "")}
                    </Typography>
                  </Box>
                </Grid>
                <Divider orientation="vertical" flexItem sx={DetailsDivider} />
                <Grid item xs={2.2} sx={DobBox}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                    >
                      DOB:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.black[2]}
                      sx={Data}
                    >
                      {formatDate(
                        getValue(patientInfoResp, "memberInfo.dateOfBirth", ""),
                        DateFormats.MM_DD_YYYY
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1.5} pt={"0.8rem"}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                    >
                      Gender:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.black[2]}
                      sx={Data}
                    >
                      {getValue(patientInfoResp, "memberInfo.gender", "")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4.2} pt={"0.8rem"}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                    >
                      Address:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.black[2]}
                      sx={Data}
                    >
                      {patientInfoResp
                        ? addressRegex.test(
                            getValue(
                              patientInfoResp,
                              "addressInfo.residentialAddress",
                              ""
                            )
                          )
                          ? getValue(
                              patientInfoResp,
                              "addressInfo.residentialAddress",
                              ""
                            )
                          : ""
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={11.5} sx={QuestionBox}>
                  <Box sx={QuestionIcon}>
                    <FaRegQuestionCircle />
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      color={colors.black[3]}
                      fontWeight={fontWeight.Weight[5]}
                      sx={Question}
                    >
                      {patientInfoResp && patientInfoResp.patientName}&nbsp;
                      {ConfirmationQuestion.QUESTION}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={GridFooter}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={BackButton}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleNavigate()}
                  sx={CreateBtn}
                >
                  CREATE REFERRAL
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CreateReferralModal;

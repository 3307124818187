export enum FaxFormTemplate {
  HOME_HEALTH_SERVICES = "homeHealthServices",
  NOTICE_TO_REFERRAL = "noticeToReferral",
  OTHER_REASON = "otherReason",
}

export const FAX_OTHER_REASON_STATED = "Reason Stated Below";

export enum FaxFormOptions {
  FAX_FORM_OPTION_ID = "faxFormOptionId",
  FAX_FORM_OPTION_NAME = "faxFormOptionName",
}

const templateDefaultValues = {
  [FaxFormTemplate.HOME_HEALTH_SERVICES]: "",
  [FaxFormTemplate.NOTICE_TO_REFERRAL]: "",
  [FaxFormTemplate.OTHER_REASON]: "",
};

export const faxDefaultValues = {
  referralBlobId: 0,
  referralId: 0,
  blobId: 0,
  faxNumber: "",
  receivedDateTime: "",
  notes: "",
  faxToName: "",
  faxFrom: "",
  faxFile: "",
  ...templateDefaultValues,
};

export const faxMasterDataList = {
  label: FaxFormOptions.FAX_FORM_OPTION_NAME,
  value: FaxFormOptions.FAX_FORM_OPTION_ID,
};

export enum FaxSentStatus {
  Success = "Success",
  Error = "Error",
  InProgress = "In Progress",
  Queued = "Queued",
  LineBusy = "Line Busy",
  LineDead = "Line Dead",
  LineFailure = "Line Failure",
  NoDialTone = "No Dial Tone",
  NoAnswer = "No Answer",
  InvalidOrMissingNumber = "Invalid Or Missing Number",
  InvalidOrMissingFile = "Invalid Or Missing File",
  InvalidChannel = "Invalid Channel",
  UnexpectedDisconnect = "Unexpected Disconnect",
  NoChannelsAvailable = "No Channels Available",
  ChannelUnavailable = "Channel Unavailable",
  NothingToCancel = "Nothing To Cancel",
  DeviceTimeout = "Device Timeout",
  DeviceBusy = "Device Busy",
  NotFaxMachine = "Not Fax Machine",
  IncompatibleFaxMachine = "Incompatible Fax Machine",
  FileError = "File Error",
  FileNotFound = "File Not Found",
  FileUnsupported = "File Unsupported",
  CallCollision = "Call Collision",
  Cancelled = "Cancelled",
  CallBlocked = "Call Blocked",
  DestinationBlackListed = "Destination BlackListed",
  RemoteDisconnect = "Remote Disconnect",
  NegotiationError = "Negotiation Error",
  TrainingError = "Training Error",
  Unauthorized = "Unauthorized",
  InvalidParameter = "Invalid Parameter",
  NotImplemented = "Not Implemented",
  ItemNotFound = "Item Not Found",
  EncryptionDisabled = "Encryption Disabled",
  EncryptionRequired = "Encryption Required",
  DecryptFailure = "Decrypt Failure",
  DocumentRejected = "Document Rejected",
  DocumentNotSupported = "Document Not Supported",
  DocumentTooLarge = "Document Too Large",
}

export const HomeHealthServicesDescription =
  "The Request for Home Health Services noted above has been sent to the Tango Physician Reviewer for determination, with a potential for Partial Approval/Partial Denial of discipline visits. This is not a Full Denial and the member continues to receive Home Health Services.";

export const NoticeToReferralDescription =
  "The request for home health services has been sent to the Tango Physician Reviewer for review, with potential for denial due to: ";

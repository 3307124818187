import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ServiceProviderDetailsDispatchTypes } from "../../../../../models/DispatchTypes/Authorization";
import AgencyCommunication from "../components/AgencyCommunication";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { PostAgencyCommunicationNoteAction } from "../../../../../actions/Notes";
import { AgencyCommunicationNote } from "../../../../../models/Notes";
import { GetAgencyTemplateMaster } from "../../../../../actions/Master";

const mapStateToProps = ({
  masterAgencyCommunicationState,
  agencyCommunicationNote
}: ApplicationState) => ({
  agencyCommunicationTemplates: masterAgencyCommunicationState.response,
  agencyCommunicationNoteResponse: agencyCommunicationNote
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ServiceProviderDetailsDispatchTypes>
  >
) => {
  return {
    getAgencyCommunicationMaster: () => {
      dispatch(GetAgencyTemplateMaster());
    },
    postAgencyCommunicationNote: (payload: AgencyCommunicationNote) => {
      dispatch(PostAgencyCommunicationNoteAction(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyCommunication);

import { Reducer } from "redux";

import { DocumentDownloadResponse } from "../models/Api/Document";
import { DocumentDownloadActionDispatchTypes } from "../models/DispatchTypes/Document";
import { DocumentDownloadDispatchTypes } from "../constants/Document";

const initialState: DocumentDownloadResponse = {
  loading: false,
  response: null,
  error: null,
};

const DocumentDownloadReducer: Reducer<
  DocumentDownloadResponse,
  DocumentDownloadActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_INVOKE: {
      return {
        ...state,
        response: null,
        loading: true,
        error: null,
      };
    }
    case DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: null,
      };
    }
    case DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_ERROR: {
      return {
        ...state,
        error: action.response,
        response: null,
        loading: false,
      };
    }
    case DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_RESET: {
      return initialState;
    }
  }
  return state;
};

export default DocumentDownloadReducer;

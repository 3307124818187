import {
  Grid,
  Box,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Stack,
  Switch,
  FormControl,
} from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  Controller,
  UseFormReset,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import {
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  RequestHeaderBox,
  RefSourceRadio,
  AuthRequestorBox,
  RequestorGrid,
  AddressDetailHeader,
  ReferralHeader,
  FacilityAddress,
  RequestorFaxGrid,
  phoneNoInputStyle,
} from "../styles/requestStyle";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors, others } from "../../../../../styles/colors";
import { ReferralSourceFacility } from "../../../../../models/Referral";
import { getValue, isNil, length } from "../../../../../utils";
import { PatientRequest } from "../../../../../models/Patient";
import {
  FaPhoneInputDevider,
  FaRegEnvelopeIcon,
} from "../../../../../components/formComponents/styles/style";
import {
  flexAlignBaseline,
  flexAllCentre,
} from "../../../../../styles/mui/styles/display";
import { rules } from "../../../../../utils/validation/Validation";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";

export interface PropsFromState {
  values: PatientRequest | AuthorizationDetailTabs;
  control: any;
  setValue: (id: any, value: any, boolean: any) => void;
  patientRequestDetail: PatientRequest | AuthorizationDetailTabs;
  clearErrors: (name: string) => void;
  reset: UseFormReset<any>;
  getValues: any;
  watch: UseFormWatch<any>;
  injectedFrom: string;
  priorAuthReferralSourceResponse: ReferralSourceFacility[];
}

type AllProps = PropsFromState;

const Requestor: React.FC<AllProps> = ({
  control,
  setValue,
  values,
  patientRequestDetail,
  clearErrors,
  reset,
  getValues,
  injectedFrom,
  priorAuthReferralSourceResponse,
}: AllProps) => {
  const [showRequestor, setShowRequestor] = useState(true);
  const [requesterEmail, setRequesterEmail] = useState<any>();
  const [referralSourceEmail, setReferralSourceEamil] = useState<any>();
  const [sameAsRequestingFacilityState, setSameAsRequestingFacilityState] =
    useState<boolean>(
      getValue(values, "referralSource.sameAsRequestingFacility", false)
    );
  const updatePayload = useWatch({
    name: "requester",
    control,
  });
  const [isDischargedDateRequired, setIsDischargedDateRequired] =
    useState<boolean>(false);

  const handleRequesterEmail = (e: any) => {
    setRequesterEmail(e.target.value);
    setValue("requester.requesterEmail", e.target.value, true);
  };

  const handleReferralSourceEmail = (e: any) => {
    setReferralSourceEamil(e.target.value);
    setValue("referralSource.referralSourceEmail", e.target.value, true);
  };

  useEffect(() => {
    clearErrors("requester.requesterEmail");
  }, [requesterEmail]);

  useEffect(() => {
    clearErrors("referralSource.referralSourceEmail");
  }, [referralSourceEmail]);

  useEffect(() => {
    setSameAsRequestingFacilityState(
      getValue(values, "referralSource.sameAsRequestingFacility", false)
    );
  }, [priorAuthReferralSourceResponse]);

  useEffect(() => {
    if (RequesterComponentInjectType.PRIOR_AUTH === injectedFrom) {
      displayReferralDischargeDate(
        getValue(patientRequestDetail, "referralSource.facilityTypeId")
      );
    } else {
      displayReferralDischargeDate(
        getValue(values, "referralSource.facilityTypeId", null)
      );
    }
  }, [priorAuthReferralSourceResponse, patientRequestDetail]);

  useEffect(() => {
    if (values && values.requester && !values.requester.requesterEmail) {
      setValue("requester.requesterEmail", null, true);
    }
    if (
      values &&
      values.referralSource &&
      !values.referralSource.referralSourceEmail
    ) {
      setValue("referralSource.referralSourceEmail", null, true);
    }
    setValue(
      "requester.streetName1",
      getValue(values, "requester.streetName1", null),
      true
    );
    setValue(
      "requester.streetName2",
      getValue(values, "requester.streetName2", null),
      true
    );
    setValue(
      "requester.county",
      getValue(values, "requester.county", null),
      true
    );
    setValue("requester.city", getValue(values, "requester.city", null), true);
    setValue(
      "requester.requesterState",
      getValue(values, "requester.requesterState", null),
      true
    );
    setValue(
      "requester.zipCode",
      getValue(values, "requester.zipCode", null),
      true
    );
    setSameAsRequestingFacilityState(
      getValue(values, "referralSource.sameAsRequestingFacility", false)
    );
  }, [values]);

  const setReferralSourceDetailsSameAsRequester = () => {
    setValue(
      "referralSource.referralSourcePhoneNo",
      values && values.requester && values.requester.requesterPhoneNo,
      true
    );
    setValue(
      "referralSource.referralSourceExtension",
      values && values.requester && values.requester.requesterExtension,
      true
    );
    setValue(
      "referralSource.referralSourceFax",
      values && values.requester && values.requester.requesterFax,
      true
    );
    setValue(
      "referralSource.referralSourceEmail",
      values && values.requester && values.requester.requesterEmail,
      true
    );
    setValue(
      "referralSource.streetName1",
      getValue(values, "requester.streetName1", null),
      true
    );
    setValue(
      "referralSource.streetName2",
      getValue(values, "requester.streetName2", null),
      true
    );
    setValue(
      "referralSource.county",
      getValue(values, "requester.county", null),
      true
    );
    setValue(
      "referralSource.city",
      getValue(values, "requester.city", null),
      true
    );
    setValue(
      "referralSource.referralSourceState",
      getValue(values, "requester.requesterState", null),
      true
    );
    setValue(
      "referralSource.zipCode",
      getValue(values, "requester.zipCode", null),
      true
    );
    setValue(
      "referralSource.facilityTypeId",
      values && values.requester && values.requester.facilityTypeId,
      true
    );
    setValue(
      "referralSource.referralSourceName",
      values && values.requester && values.requester.requesterName,
      true
    );
    const requester =
      priorAuthReferralSourceResponse && length(priorAuthReferralSourceResponse)
        ? priorAuthReferralSourceResponse.filter((item) => {
            return (
              values &&
              values.requester &&
              item.facilityTypeId === values.requester.facilityTypeId
            );
          })
        : [];
    setValue(
      "referralSource.facilityTypeName",
      requester && length(requester) ? requester[0].facilityTypeName : "",
      true
    );
    setValue("referralSource.sameAsRequestingFacility", true, true);

    setValue(
      "referralSource.facilityName",
      getValue(values, "requester.facilityName", null),
      true
    );

    setValue(
      "referralSource.referralSourceNpi",
      getValue(values, "requester.requesterNpi", null),
      true
    );
  };

  const handleSourceDetail = () => {
    const iSameAsRequestor = !sameAsRequestingFacilityState;
    if (iSameAsRequestor) {
      setSameAsRequestingFacilityState(true);
      setReferralSourceDetailsSameAsRequester();
      displayReferralDischargeDate(
        getValue(values, "requester.facilityTypeId")
      );
    } else {
      setSameAsRequestingFacilityState(false);
      clearErrors("referralSource");
      setValue("referralSource.facilityName", "", true);
      setValue("referralSource.referralSourceNpi", "", true);
      setValue("referralSource.referralSourceName", "", true);
      setValue("referralSource.referralSourcePhoneNo", "", true);
      setValue("referralSource.referralSourceExtension", "", true);
      setValue("referralSource.referralSourceFax", "", true);
      setValue("referralSource.referralSourceEmail", "", true);
      setValue("referralSource.streetName1", null, true);
      setValue("referralSource.streetName2", null, true);
      setValue("referralSource.county", null, true);
      setValue("referralSource.city", null, true);
      setValue("referralSource.referralSourceState", null, true);
      setValue("referralSource.zipCode", null, true);
      setValue("referralSource.facilityTypeId", null, true);
      setValue("referralSource.sameAsRequestingFacility", false, true);
      setValue("referralSource.dischargeDate", null, true);
      displayReferralDischargeDate(
        getValue(patientRequestDetail, "referralSource.facilityTypeId")
      );
      setIsDischargedDateRequired(false);
    }
  };

  useEffect(() => {
    if (sameAsRequestingFacilityState) {
      setReferralSourceDetailsSameAsRequester();
    }
  }, [updatePayload]);

  const handleRequestorChange = (e: any) => {
    const requestorData =
      priorAuthReferralSourceResponse && length(priorAuthReferralSourceResponse)
        ? priorAuthReferralSourceResponse.filter(
            (item: ReferralSourceFacility) => {
              return item.facilityTypeId == e.target.value;
            }
          )
        : [];
    setValue(e.target.name, e.target.value, true);
    setValue(
      `requester.facilityTypeName`,
      requestorData && length(requestorData)
        ? getValue(requestorData, "[0].facilityTypeName", "")
        : "",
      true
    );
    clearErrors(e.target.name);
    if (
      getValue(values, "referralSource.sameAsRequestingFacility", false) ===
      true
    ) {
      displayReferralDischargeDate(e.target.value);
    }

    if (
      getValue(values, "referralSource.sameAsRequestingFacility", false) ===
        true &&
      getValue(requestorData, "[0].dischargeDateRequired", false) === false
    ) {
      setValue("referralSource.dischargeDate", null, true);
      setValue("authorizationGeneralInfoDto.dischargeDate", null, true);
    }
  };

  const handleReferralChange = (e: any) => {
    const referral =
      priorAuthReferralSourceResponse && length(priorAuthReferralSourceResponse)
        ? priorAuthReferralSourceResponse.filter(
            (item: ReferralSourceFacility) => {
              return item.facilityTypeId == e.target.value;
            }
          )
        : [];
    setValue(e.target.name, e.target.value, true);
    setValue(
      `referralSource.facilityTypeName`,
      referral && length(referral) ? referral[0].facilityTypeName : "",
      true
    );
    if (getValue(referral, "[0].dischargeDateRequired", false) === false) {
      setValue("referralSource.dischargeDate", null, true);
      setValue("authorizationGeneralInfoDto.dischargeDate", null, true);
    }
    displayReferralDischargeDate(e.target.value);
  };

  const displayReferralDischargeDate = (facilityTypeId: number) => {
    if (
      priorAuthReferralSourceResponse &&
      length(priorAuthReferralSourceResponse)
    ) {
      const referralSourceData = priorAuthReferralSourceResponse.find(
        (referralFacility: ReferralSourceFacility) =>
          referralFacility.facilityTypeId == facilityTypeId
      );
      if (referralSourceData) {
        setIsDischargedDateRequired(referralSourceData.dischargeDateRequired);
        if (isNil(getValues("referralSource.dischargeDate"))) {
          setValue("referralSource.dischargeDate", null, true);
        }
      }
    }
  };

  const requesterSearchInput = useRef() as MutableRefObject<HTMLInputElement>;
  const referralSourceSearchInput =
    useRef() as MutableRefObject<HTMLInputElement>;
  const requestorLabels = [
    {
      label: "Street Name 1: ",
      name: "requester.streetName1",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: "requester.streetName2",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "County: ",
      name: "requester.county",
      helper: rules.county,
      textLength: 100,
    },
    {
      label: "City: ",
      name: "requester.city",
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: "requester.requesterState",
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: "requester.zipCode",
      helper: rules.zipcode,
      textLength: 10,
    },
  ];

  const referralSourceLabels = [
    {
      label: "Street Name 1: ",
      name: "referralSource.streetName1",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: "referralSource.streetName2",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "County: ",
      name: "referralSource.county",
      helper: rules.county,
      textLength: 100,
    },
    {
      label: "City: ",
      name: "referralSource.city",
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: "referralSource.referralSourceState",
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: "referralSource.zipCode",
      helper: rules.zipcode,
      textLength: 10,
    },
  ];

  return (
    <Grid pb={"2.5rem"}>
      <Box
        sx={RequestHeaderBox(
          injectedFrom === RequesterComponentInjectType.AUTH_DETAILS
            ? RequesterComponentInjectType.AUTH_DETAILS
            : RequesterComponentInjectType.PRIOR_AUTH
        )}
      >
        {injectedFrom === RequesterComponentInjectType.PRIOR_AUTH && (
          <>
            {showRequestor ? (
              <FaChevronCircleUpIcon onClick={() => setShowRequestor(false)} />
            ) : (
              <FaChevronCircleDownIcon onClick={() => setShowRequestor(true)} />
            )}
          </>
        )}
        <Box pb={"1rem"}>
          <Typography
            variant="h6"
            fontWeight={fontWeight.Weight[5]}
            color={others.otherColors[63]}
            sx={AddressDetailHeader}
          >
            REQUESTOR:
          </Typography>
          <Typography
            variant="body1"
            fontWeight={fontWeight.Weight[2]}
            color={colors.black[5]}
            sx={AddressDetailHeader}
            mt={"0.7rem"}
          >
            Please add below all details specific to primary requestor and
            original referral source.
          </Typography>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />

      {showRequestor && (
        <Box sx={AuthRequestorBox}>
          <Box sx={flexAlignBaseline}>
            <Controller
              defaultValue=""
              render={({ field }) => (
                <Box mb={"2rem"}>
                  <>
                    <RadioGroup
                      aria-labelledby="controlled-radio-buttons-group"
                      sx={RefSourceRadio(false)}
                      {...field}
                      onChange={handleRequestorChange}
                    >
                      {priorAuthReferralSourceResponse &&
                        length(priorAuthReferralSourceResponse) &&
                        priorAuthReferralSourceResponse.map(
                          (singleOption: ReferralSourceFacility) => (
                            <Box key={singleOption.facilityTypeId} pr={"1rem"}>
                              <FormControlLabel
                                value={Number(singleOption.facilityTypeId)}
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    color={others.otherColors[63]}
                                    fontWeight={fontWeight.Weight[5]}
                                  >
                                    {singleOption.facilityTypeName}
                                  </Typography>
                                }
                                control={<Radio />}
                              />
                            </Box>
                          )
                        )}
                    </RadioGroup>
                  </>
                </Box>
              )}
              name={`requester.facilityTypeId`}
              control={control}
            />
          </Box>
          <Grid
            container
            spacing={
              RequesterComponentInjectType.PRIOR_AUTH === injectedFrom
                ? "2rem"
                : "1.25rem"
            }
            sx={RequestorGrid(false)}
          >
            <Grid item xs={2}>
              <FormInputText
                name="requester.facilityName"
                label="Facility Name:"
                control={control}
                textLength={100}
                placeholder="Add here..."
                helper={rules.requestorFacilityName}
              />
            </Grid>
            <Grid item xs={1.7}>
              <FormInputText
                name="requester.requesterNpi"
                label="NPI:"
                control={control}
                textLength={10}
                placeholder="Add here..."
                helper={rules.requestorNpi}
                inputType="onlyNumber"
              />
            </Grid>
            <Grid item xs={1.8}>
              <Controller
                defaultValue={""}
                render={({
                  field: { name, onBlur, value, ref, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="standard"
                    error={error && error.message ? true : false}
                    helperText={error ? error.message : null}
                    placeholder="Add here..."
                    name={name}
                    onBlur={onBlur}
                    value={value || ""}
                    ref={ref}
                    onChange={onChange}
                    label={
                      <Typography
                        variant="h5"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[2]}
                      >
                        Requestor:
                      </Typography>
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                )}
                name={`requester.requesterName`}
                control={control}
                rules={rules.authRequestorName}
              />
            </Grid>

            <Grid
              item
              xs={
                RequesterComponentInjectType.PRIOR_AUTH === injectedFrom
                  ? 2.7
                  : 2.8
              }
              style={phoneNoInputStyle(
                injectedFrom === RequesterComponentInjectType.PRIOR_AUTH
                  ? RequesterComponentInjectType.PRIOR_AUTH
                  : RequesterComponentInjectType.AUTH_DETAILS
              )}
            >
              <PhoneFaxExtInput
                label="Phone Number:"
                control={control}
                phoneFaxName={`requester.requesterPhoneNo`}
                extName={`requester.requesterExtension`}
                rules={rules.phoneNumber}
              />
            </Grid>
            <Grid item xs={1.8}>
              <Controller
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    {...field}
                    value={field.value || ""}
                    error={error && error.message ? true : false}
                    helperText={error ? error.message : null}
                    InputProps={{
                      startAdornment: (
                        <Stack direction="row" sx={flexAllCentre}>
                          <FaRegEnvelopeIcon />
                          <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            flexItem
                            sx={FaPhoneInputDevider}
                          />
                        </Stack>
                      ),
                    }}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleRequesterEmail}
                    label={
                      <Typography
                        variant="h5"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[2]}
                      >
                        Email ID:
                      </Typography>
                    }
                    placeholder="Add"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                name={`requester.requesterEmail`}
                control={control}
                rules={rules.emailAddress}
              />
            </Grid>
            <Grid item xs={1.9}>
              <PhoneFaxExtInput
                label={
                  injectedFrom === RequesterComponentInjectType.AUTH_DETAILS
                    ? "Fax number:"
                    : "Fax:"
                }
                control={control}
                phoneFaxName={`requester.requesterFax`}
                rules={rules.fax}
                isFax
              />
            </Grid>
          </Grid>

          <Grid container mt={"1rem"}>
            <Grid item xs={12}>
              <AddressFormInputUseForm
                control={control}
                searchInput={requesterSearchInput}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                labels={requestorLabels}
                addressLabel="Facility Address:"
                addressVariant="subtitle2"
                addressStyle="-1rem"
              />
            </Grid>
          </Grid>

          <Box sx={ReferralHeader}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={others.otherColors[64]}
              mr={"2rem"}
            >
              REFERRAL SOURCE:
            </Typography>
            <Controller
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={() => {
                    handleSourceDetail();
                  }}
                  checked={sameAsRequestingFacilityState}
                  value={field.value || false}
                />
              )}
              name={`referralSource.sameAsRequestingFacility`}
              control={control}
            />
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[4]}
              sx={AddressDetailHeader}
            >
              Same As Requestor
            </Typography>
          </Box>

          <Controller
            defaultValue=""
            render={({ field }) => (
              <>
                <RadioGroup
                  aria-labelledby="controlled-radio-buttons-group"
                  sx={RefSourceRadio(false)}
                  {...field}
                  onChange={handleReferralChange}
                >
                  {priorAuthReferralSourceResponse &&
                    length(priorAuthReferralSourceResponse) &&
                    priorAuthReferralSourceResponse.map(
                      (singleOption: ReferralSourceFacility) => (
                        <Box key={singleOption.facilityTypeId} pr={"1rem"}>
                          <FormControlLabel
                            disabled={sameAsRequestingFacilityState}
                            value={Number(singleOption.facilityTypeId)}
                            label={
                              <Typography
                                variant="subtitle2"
                                color={others.otherColors[63]}
                                fontWeight={fontWeight.Weight[5]}
                              >
                                {singleOption.facilityTypeName}
                              </Typography>
                            }
                            control={<Radio />}
                          />
                        </Box>
                      )
                    )}
                </RadioGroup>
              </>
            )}
            name={`referralSource.facilityTypeId`}
            control={control}
          />

          <Grid
            container
            spacing={
              RequesterComponentInjectType.PRIOR_AUTH === injectedFrom
                ? "2rem"
                : "1.25rem"
            }
            mt={"1rem"}
          >
            <Grid item xs={1.8}>
              <FormInputText
                name="referralSource.facilityName"
                label="Facility Name:"
                control={control}
                textLength={100}
                disabled={sameAsRequestingFacilityState}
                placeholder="Add here..."
                helper={rules.requestorFacilityName}
              />
            </Grid>
            <Grid item xs={1.2}>
              <FormInputText
                name="referralSource.referralSourceNpi"
                label="NPI:"
                control={control}
                textLength={10}
                disabled={sameAsRequestingFacilityState}
                placeholder="Add here..."
                helper={rules.requestorNpi}
                inputType="onlyNumber"
              />
            </Grid>
            <Grid item xs={1.6}>
              <Controller
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      disabled={sameAsRequestingFacilityState}
                      variant="standard"
                      {...field}
                      placeholder="Add here..."
                      error={error && error.message ? true : false}
                      helperText={error ? error.message : null}
                      value={field.value || ""}
                      label={
                        <Typography
                          variant="h5"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.fonts[2]}
                        >
                          Requesting Contact Name:
                        </Typography>
                      }
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </>
                )}
                name={`referralSource.referralSourceName`}
                control={control}
                rules={rules.referralSourceName}
              />
            </Grid>

            <Grid
              item
              xs={2.4}
              style={phoneNoInputStyle(
                injectedFrom === RequesterComponentInjectType.PRIOR_AUTH
                  ? RequesterComponentInjectType.PRIOR_AUTH
                  : RequesterComponentInjectType.AUTH_DETAILS
              )}
            >
              <PhoneFaxExtInput
                label="Phone Number:"
                control={control}
                disabled={sameAsRequestingFacilityState}
                phoneFaxName={`referralSource.referralSourcePhoneNo`}
                extName={`referralSource.referralSourceExtension`}
                rules={rules.phoneNumber}
              />
            </Grid>

            <Grid item xs={1.7}>
              <Controller
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled={sameAsRequestingFacilityState}
                    variant="standard"
                    {...field}
                    value={field.value || ""}
                    error={error && error.message ? true : false}
                    helperText={error ? error.message : null}
                    InputProps={{
                      startAdornment: (
                        <Stack direction="row" sx={flexAllCentre}>
                          <FaRegEnvelopeIcon />
                          <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            flexItem
                            sx={FaPhoneInputDevider}
                          />
                        </Stack>
                      ),
                    }}
                    onChange={handleReferralSourceEmail}
                    label={
                      <Typography
                        variant="h5"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[2]}
                      >
                        Email ID:
                      </Typography>
                    }
                    inputProps={{ maxLength: 255 }}
                    placeholder="Add"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                name={`referralSource.referralSourceEmail`}
                control={control}
                rules={rules.emailAddress}
              />
            </Grid>
            <Grid item xs={1.7}>
              <PhoneFaxExtInput
                disabled={sameAsRequestingFacilityState}
                label={
                  injectedFrom === RequesterComponentInjectType.AUTH_DETAILS
                    ? "Fax number:"
                    : "Fax:"
                }
                control={control}
                phoneFaxName={`referralSource.referralSourceFax`}
                rules={rules.fax}
                isFax
              />
            </Grid>
            {isDischargedDateRequired && (
              <Grid
                item
                xs={
                  RequesterComponentInjectType.PRIOR_AUTH === injectedFrom
                    ? 1.7
                    : 1.9
                }
              >
                <FormControl variant="standard" fullWidth>
                  <DatePicker
                    name={
                      "referralSource.dischargeDate"
                    }
                    control={control}
                    label={"Referral Source Discharge Date:"}
                    allowFutureDate={true}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container sx={RequestorFaxGrid(sameAsRequestingFacilityState)}>
            <Grid item xs={12} sx={FacilityAddress}>
              <AddressFormInputUseForm
                disable={sameAsRequestingFacilityState}
                control={control}
                searchInput={referralSourceSearchInput}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                labels={referralSourceLabels}
                addressLabel="Facility Address:"
                addressVariant="subtitle2"
                addressStyle="-1rem"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default Requestor;

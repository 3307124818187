import { Reducer } from "redux";

import { PatientServiceProviderMasterResponse } from "../models/Api/PatientList";
import { ServiceProviderMasterDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceProviderMasterActionDispatchType } from "../constants/Service";

export const defaultServiceProviderMaster = [
  {
    providerName: "",
    providerNpi: "",
  },
];

const initialState: PatientServiceProviderMasterResponse = {
  loading: false,
  response: defaultServiceProviderMaster,
};

const ServiceProviderMasterReducer: Reducer<
  PatientServiceProviderMasterResponse,
  ServiceProviderMasterDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_RESET: {
      return {
        ...state,
        response: [],
        loading: false,
      };
    }
  }
  return state;
};

export default ServiceProviderMasterReducer;

import { Reducer } from "redux";

import { DisciplineTemplateActionDispatchTypes } from "../constants/Master";
import { DisciplineTemplateResponse } from "../models/Api/Master";
import { DisciplineTemplateDispatchTypes } from "../models/DispatchTypes/Master";

export const initialPostState: DisciplineTemplateResponse = {
  loading: false,
  response: [
    {
      rationaleTemplateId: null,
      templateName: "",
      rationale: "",
      resourceDescription: "",
    },
  ],
};

const DisciplineTemplateReducer: Reducer<
  DisciplineTemplateResponse,
  DisciplineTemplateDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case DisciplineTemplateActionDispatchTypes.DISCIPLINE_TEMPLATE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DisciplineTemplateActionDispatchTypes.DISCIPLINE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DisciplineTemplateReducer;

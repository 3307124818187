import { Grid, Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

import fontWeight from "../../../styles/mui/fontWeight";
import { others, colors } from "../../../styles/colors";

export const HeaderBox = styled(Grid)(() => ({
  padding: "1.5rem",
}));

export const HeaderModuleName = () => ({
  textTransform: "uppercase",
  color: others.DashboardBreadCumb[1],
  marginBottom: "0.5rem",
});

export const PatientDetailBox = styled(Box)(() => ({
  backgroundColor: colors.backgroundColor[7],
  borderRadius: "0.5rem",
  margin: "2rem 1rem 0",
  minHeight: "80vh",
  padding: "0 0.5rem 0.5rem"
}));

export const PatientContainerBox = styled(Box)(() => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: colors.backgroundColor[2],
  borderRadius: "1rem",
  marginBottom: "1rem",
  paddingBottom: "1rem",
}));

export const PatientBox = styled(Box)(() => ({
  padding: "1.2rem 0 0 1.5rem",
}));

export const PatientHeaderBox = styled(Box)(() => ({
  display: "flex",
  padding: "1.5rem",
}));

export const SelectedOption = () => ({
  background: colors.black[7],
  borderRadius: "1rem",
  padding: "0.3rem 1.1rem 0.3rem 1.2rem",
  width: "fit-content",
  margin: "0.2rem",
  display: "flex",
  alignItems: "center",
});

export const DividerLine = () => ({
  margin:"0 1rem ",
});

export const warnStyle = () => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
});

export const InputCloseIcon = {
  cursor: "pointer",
};

export const TabsPanelSectionBox = styled(Box)((props) => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: props.theme.palette.common.white,
  borderRadius: "10px",
  marginBottom: "1rem",
}));

export const DisabledTab = (enabledTab: any) => ({
  pointerEvents: enabledTab ? "none" : "auto",
  color: enabledTab && colors.grey
});


export const PanelBox = styled(Box)(() => ({
  padding: "1.2rem 1.5rem 0 1.5rem",
}));

export const RefTabs = styled(Tabs)((props) => ({
  overflow: "visible",
  "& .MuiTabs-scroller": {
    overflow: "visible",
  },
  marginLeft: "6rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
  "& .Mui-selected": {
    border: `1px solid ${colors.borders[1]}`,
    color: colors.fonts[18],
    fontSize: ".75rem",
    fontWeight: fontWeight.Weight[6],
    opacity: 1,
    textTransform: "uppercase",
    borderBottom: `1px solid ${props.theme.palette.common.white}`,
  },
  "& .MuiTabs-flexContainer": {
    paddingTop: "20px",
  },
}));

export const RefTab = styled(Tab)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  marginRight: "1rem",
  borderRadius: "10px",
  borderBottomRightRadius: "0px",
  borderBottomLeftRadius: "0px",
  border: `1px solid ${colors.borders[1]}`,
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  textTransform: "uppercase",
  overflow: "visible",
  color: colors.fonts[19],
  padding: "0.7rem 0.5rem 0.2rem 0.5rem",
  minHeight: "2.45rem",
  fontSize: ".75rem",
  fontWeight: fontWeight.Weight[3],
  opacity: 0.9,
  width: "9.3rem",
}));

export const ReAuthRefTab = styled(Tab)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  marginRight: "1rem",
  borderRadius: "10px",
  borderBottomRightRadius: "0px",
  borderBottomLeftRadius: "0px",
  border: `1px solid ${colors.borders[1]}`,
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  textTransform: "uppercase",
  overflow: "visible",
  color: colors.fonts[19],
  padding: "0.7rem 0.5rem 0.2rem 0.5rem",
  minHeight: "2.45rem",
  fontSize: ".75rem",
  fontWeight: fontWeight.Weight[3],
  opacity: 0.9,
  width: "10.3rem",
}));
export const HeaderStyling = () => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: "1.5rem"
});
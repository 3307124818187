import { Reducer } from "redux";

import { PhysicianDetailsResponse } from "../models/Api/PhysicianDetails";
import { ReferralIntakePhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  GetPhysicianDetailsActionDispatchTypes,
  PhysicianTypeID,
  PostPhysicianDetailsActionDispatchTypes,
  PutPhysicianDetailsActionDispatchTypes,
} from "../constants/PhysicianDetails";

const initialState: PhysicianDetailsResponse = {
  loading: true,
  response: {
    primaryPhysician: {
      referralId: 0,
      referralPhysicianDetailId: 0,
      physicianId: -1,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName: "",
      county: "",
      city: "",
      physicianState: "",
      zipCode: "",
      userCreated: null,
      physicianTypeId: PhysicianTypeID.PRIMARY,
      physicianUid: "",
    },
    orderingPhysician: {
      referralId: 0,
      physicianId: -1,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName: "",
      county: "",
      city: "",
      state: "",
      zipCode: "",
      physicianState: "",
      physicianTypeId: PhysicianTypeID.ORDERING,
      physicianSameAsPrimary: false,
    },
  },
  error: null,
};

const ReferralIntakePhysicianDetailsReducer: Reducer<
  PhysicianDetailsResponse,
  ReferralIntakePhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  const invoke = (state: PhysicianDetailsResponse) => ({
    ...state,
    loading: true,
  });

  const success = (state: PhysicianDetailsResponse, action: any) => ({
    ...state,
    response: action.response,
    loading: false,
    error: {},
  });

  const error = (state: PhysicianDetailsResponse, action: any) => ({
    ...state,
    loading: false,
    error: action.response,
  });

  switch (action.type) {
    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }

    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_ERROR: {
      return error(state, action);
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_ERROR: {
      return error(state, action);
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ReferralIntakePhysicianDetailsReducer;

import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material";
import { Box } from "@mui/material";
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";

import { others, colors, accent } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const IconFaChevronCircleUp = styled(FaChevronCircleUp)(() => ({
  color: colors.fonts[18],
}));

export const IconFaChevronCircleDown = styled(FaChevronCircleDown)(() => ({
  color: colors.fonts[18],
}));

const paddingmargin = {
  margin: "0.24rem",
  padding: "0.24rem",
};

const labeldefault = {
  textAlign: "left",
  opacity: "1",
};

const primary = [
  labeldefault,
  {
    color: colors.fonts[2],
    textTransform: "uppercase",
  },
  paddingmargin,
];

const secondary = [
  labeldefault,
  {
    color: colors.black[5],
  },
  paddingmargin,
];

const boxstyle = {
  boxSizing: "border-box",
  margin: "0.05rem 2px",
  padding: "0.05rem",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  textAlign: "left",
  opacity: "1",
};

const row_padding = {
  paddingBottom: "1.2rem",
};

const body_row1 = {
  borderBottom: "none",
  ...row_padding,
};

const body_row2 = {
  borderBottom: "none",
  ...boxstyle,
  ...row_padding,
};

const body_row3 = {
  ...boxstyle,
  display: "block",
};

const body_row2col1 = {
  main: {},
  c1: [...primary, { color: colors.fonts[2], textTransform: "none" }],
  c2: [...primary, { color: others.otherColors[42], textTransform: "none" }],
};

const body_row2col2 = {
  main: {
    ...paddingmargin,
    marginLeft: "2rem",
  },
  c1: [...primary, { color: colors.fonts[2], textTransform: "none" }],
  c2: [...primary, { color: colors.black[5], textTransform: "none" }],
};

const body_row2col3 = {
  main: {
    ...paddingmargin,
    marginLeft: "2rem",
  },
  c1: [...primary, { color: colors.fonts[2], textTransform: "none" }],
  c2: [...primary, { color: colors.black[5], textTransform: "none" }],
};

const body_row3row1 = {
  main: {},
  c1: [...primary, { color: "black", textTransform: "none" }],
};

export const alignItemBaseline = {
  alignItems: "baseline",
};

export const description = {
  width: "15rem",
};

const body_row3row2 = {
  heading: [
    ...primary,
    {
      color: colors.fonts[2],
      textAlign: "left",
      background: "rgba(211, 219, 235, 0.35)",
      padding: "0.8rem",
      borderRadius: "0.5rem",
      marginLeft: "-1px",
    },
  ],
  c1: { paddingLeft: "1.5rem" },
  body: [
    ...primary,
    {
      textAlign: "left",
      padding: "0.8rem",
      marginLeft: "-0.05rem",
      borderBottom: `0.05rem solid ${colors.dropShadow[4]}`,
    },
  ],
  c2: {
    display: "flex",
    alignItems: "center",
    padding: "0.2rem 0rem",
    textTransform: "none",
    ...primary,
  },
  c3: {
    padding: "1.2rem 0.1rem",
    paddingLeft: "1.5rem",
    ...primary,
    color: colors.fonts[2],
    textTransform: "none",
    borderLeft: `0.05rem solid ${colors.dropShadow[4]}`,
  },
  c4: {},
  checkbox1: {
    ...primary,
    color: colors.fonts[2],
    textTransform: "none",
    boxSizing: "border-box",
    padding: "0.5rem 0rem",
    paddingLeft: "1rem",
  },
  checkbox: {
    ...primary,
    color: colors.fonts[2],
    textTransform: "none",
    borderLeft: `0.05rem solid ${colors.dropShadow[4]}`,
    boxSizing: "border-box",
    padding: "0.5rem 0rem",
    paddingLeft: "1rem",
  },
};

const table_heading = {
  letterSpacing: "0px",
  color: colors.black[5],
  textTransform: "uppercase",
};

const box = {
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  marginLeft: "1.2rem",
};

export const TitleBox = styled(Box)(() => ({
  textAlign: "initial",
  color: colors.fonts[4],
  padding: "0 0 0 1.3rem",
  width: "100%",
  marginTop: "1.21rem",
}));

export const DividerItem = styled(Divider)(() => ({
  color: colors.dropShadow[4],
  margin: "0.72rem 1.25rem 0.72rem 1.25rem",
}));

export const HorizontalDivider = () => ({
  color: colors.dropShadow[4],
  margin: "0.72rem 1.25rem 0.72rem 1.25rem",
});

export const BodyOuter = styled(Box)(() => ({
  margin: "0.9rem 2.8rem 2rem 2.8rem",
  background: colors.backgroundColor[7],
  borderRadius: "0.5rem",
}));

export const BodyInner = styled(Box)(() => ({
  margin: "0.9rem 0.9rem 0.9rem 0.9rem",
  padding: "0.9rem",
  background: colors.backgroundColor[2],
  opacity: 1,
  borderRadius: "0.4875rem",
}));

export const HeaderBox = styled(Box)(() => ({
  display: "flex",
  backgroundColor: alpha(colors.black[7], 0.35),
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.5rem",
}));

export const BackDropStyle = {
  color: others.otherColors[33],
  position: "absolute",
  opacity: 0.5,
};

export const HeaderItem = {
  backgroundColor: colors.backgroundColor[8],
  borderRadius: "0.5rem",
};

export const IconButtonBox = styled(Box)(() => ({
  alignItems: "left",
}));

export const FaChevronCircleStyle = styled(Box)(() => ({
  color: colors.fonts[18],
}));

export const roleDetailsFooter = {
  background: alpha(accent.accentOcean[30], 0.4),
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  paddingTop: "1rem",
  paddingBottom: "1rem",
};
export const createRoleFooter = roleDetailsFooter;
export const roleDetailsBody = {
  background: alpha(accent.accentOcean[30], 0.4),
  padding: "15px",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
};

export const NameBox = () => ({
  marginLeft: "0.24rem",
});

export const ButtonBox = () => ({
  alignItems: "left",
});

export const BodyGridContainer = () => ({
  padding: "0 2.8rem 2rem 2.8rem"
});

export const HeaderOuterBox = () => ({
  margin: "1.6rem 0 0 3rem"
});

export const ErrorMessageBox = () => ({
  paddingLeft: "1.3rem",
});

export const SubtitleBox = () => ({
  paddingTop: "0.7rem"
});

export const SubtitleValue = () => ({
  paddingRight: "1.2rem",
});

export const HeaderModuleBox = () => ({
  margin: "1.6rem 0 0 2rem"
});

export const BodyDetailsContainer = () => ({
  padding: "1rem 0 0 2rem"
});

export const roleDetailsStyles = {
  body_row1,
  body_row2,
  body_row3,
  body_row2col1,
  body_row2col2,
  body_row2col3,
  body_row3row1,
  body_row3row2,
  boxstyle,
  primary,
  secondary,
  table_heading,
  box,
  NameBox,
  ButtonBox,
  BodyGridContainer,
  HeaderOuterBox,
  ErrorMessageBox,
  SubtitleBox,
  SubtitleValue
};

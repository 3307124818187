import {
  Button,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export interface Props {
  formError: any;
  hasFormError: boolean;
  setHasFormError: (value: boolean) => void;
}
const ErrorDialog = ({ hasFormError, setHasFormError, formError }: Props) => {
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      disableScrollLock
      onClose={() => setHasFormError(false)}
      open={hasFormError}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          color={colors.primary.main}
          fontWeight={fontWeight.Weight[4]}
          variant="h6"
        >
          Please check below details
        </Typography>
      </DialogTitle>
      <DialogContent>
        {(formError &&
          formError.error &&
          Object.keys(formError.error).length > 0 &&
          formError.error.status == 422) ||
        (formError &&
          formError.error &&
          Object.keys(formError.error).length > 0 &&
          formError.error.status == 400 &&
          typeof formError.error.data != "string") ? (
          Object.keys(formError.error.data).map((field, index) => {
            const messages = formError.error.data[field];

            return (
              <DialogContentText
                id="alert-dialog-description"
                key={index}
                mb={"0.5rem"}
              >
                <Typography
                  color={colors.black[2]}
                  fontWeight={fontWeight.Weight[4]}
                  variant="body1"
                >
                  {index + 1}. {messages[0]}
                </Typography>
              </DialogContentText>
            );
          })
        ) : (
          <DialogContentText id="alert-dialog-description" mb={"0.5rem"}>
            <Typography
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
              variant="body1"
            >
              {formError && formError.error && formError.error.data}
            </Typography>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={() => setHasFormError(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import NoteManagement from "../components";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { UserListDispatchTypes } from "../../../../../models/DispatchTypes/User";

import {
  deactivateNotesTemplate,
  deleteAdminNotesTemplate,
  postAdminNotesList,
} from "../../../../../actions/Notes";
import { SaveNotes, TemplateSearch } from "../../../../../models/Notes";
import { NotesApprovalStatusMasterDispatchTypes } from "../../../../../models/DispatchTypes/Master";
import { resetStates } from "../../../../../actions/Common";
import { DeactivateNoteDispatchTypes } from "../../../../../models/DispatchTypes/Notes";

const mapStateToProps = ({
  adminNotesListState,
  deleteAdminNotesTemplateState,
  saveAdminNotesState,
  updateAdminNotesState,
  deactivateNotesTemplateState,
}: ApplicationState) => ({
  adminNotesData: adminNotesListState,
  deleteAdminNotesTemplateData: deleteAdminNotesTemplateState,
  UpdateNotesResponse: updateAdminNotesState.response as SaveNotes,
  SaveNotesResp: saveAdminNotesState,
  deactivateNotesTemplateData: deactivateNotesTemplateState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<
      | UserListDispatchTypes
      | NotesApprovalStatusMasterDispatchTypes
      | DeactivateNoteDispatchTypes
    >
  >
) => {
  return {
    getAdminNotesList: (payload?: TemplateSearch) => {
      dispatch(postAdminNotesList(payload));
    },
    deactivateNotesTemplate: (id: number, isActive: boolean) => {
      dispatch(deactivateNotesTemplate(id, isActive));
    },
    deleteAdminNotesTemplate: (id: number) => {
      dispatch(deleteAdminNotesTemplate(id));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteManagement);

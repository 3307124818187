import { Reducer } from "redux";

import { AuthPastMedicalHistoryResponse } from "../models/Api/Authorization";
import { AuthPastMedicalHistoryDispatchTypes } from "../models/DispatchTypes/Authorization";
import { AuthPastMedicalHistoryActionDispatchTypes } from "../constants/Authorization";

export const initialState: AuthPastMedicalHistoryResponse = {
  loading: false,
  response: {
    referralId: 0,
    userCreated: null,
    diseasesDto: [],
    psychologicalDiseasesDto: [],
  },
};

const AuthPastMedicalHistoryReducer: Reducer<
  AuthPastMedicalHistoryResponse,
  AuthPastMedicalHistoryDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthPastMedicalHistoryActionDispatchTypes.AUTH_PAST_MEDICAL_HISTORY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthPastMedicalHistoryActionDispatchTypes.AUTH_PAST_MEDICAL_HISTORY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AuthPastMedicalHistoryReducer;

import API from "./api";

export const GetRoleManagement = (configUrl: string) =>
  API.get(`${configUrl}api/roles/Access`);

export const GetRoleDetail = (configUrl: string, suffix: string) =>
  API.get(`${configUrl}api/roles/${suffix}/access`);

export const PutRole = (configUrl: string, payload: any, suffix: string) =>
  API.putApi(`${configUrl}api/roles/${suffix}`, payload);

export const PostRole = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/roles`, payload);

export const DeleteRole = (configUrl: string, suffix: string) =>
  API.delete(`${configUrl}api/roles/${suffix}`);

import { Reducer } from "redux";

import { Icd10RecordResponse } from "../models/Api/AllPatientRecord";
import { Icd10RecordDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { Icd10RecordsActionDispatchTypes } from "../constants/AllPatientRecord";

const initialResponse = {
  id: 0,
  typeId: 0,
  userCreated: null,
  icdCodesInfoDto: [],
};

const initialState: Icd10RecordResponse = {
  loading: false,
  response: initialResponse,
  error: null,
};

const IcdCodeTypeReducer: Reducer<
  Icd10RecordResponse,
  Icd10RecordDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case Icd10RecordsActionDispatchTypes.ICD_10_INVOKE: {
      return {
        ...state,
        error: null,
        response: initialResponse,
        loading: true,
      };
    }
    case Icd10RecordsActionDispatchTypes.ICD_10_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case Icd10RecordsActionDispatchTypes.ICD_10_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case Icd10RecordsActionDispatchTypes.ICD_10_RESET: {
      return initialState;
    }
  }
  return state;
};

export default IcdCodeTypeReducer;

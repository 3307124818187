import { connect } from "react-redux";

import { ApplicationState } from "../../../store/ApplicationState";
import PrivateDutyAuth from "../components/MyPatientsDetails/PrivateDutyAuth";

const mapStateToProps = ({
  filterState,
}: ApplicationState) => ({
  filterState: filterState,
});

export default connect(mapStateToProps)(PrivateDutyAuth);

import { AuthDetailsAccess, PatientRecordsAccess } from "./Permission";

export enum DocumentReviewActionDispatchTypes {
  DOCUMENT_REVIEW_INVOKE = "DOCUMENT_REVIEW_INVOKE",
  DOCUMENT_REVIEW_SUCCESS = "DOCUMENT_REVIEW_SUCCESS",
  DOCUMENT_REVIEW_RESET = "DOCUMENT_REVIEW_RESET",
}

export enum SaveUpdateDocumentDetailActionDispatchTypes {
  DOCUMENT_DETAIL_SAVE_ERROR = "DOCUMENT_DETAIL_SAVE_ERROR",
  DOCUMENT_DETAIL_SAVE_INVOKE = "DOCUMENT_DETAIL_SAVE_INVOKE",
  DOCUMENT_DETAIL_SAVE_SUCCESS = "DOCUMENT_DETAIL_SAVE_SUCCESS",
}

export enum ReferralDocumentFileDispatchTypes {
  REFERRAL_DOCUMENT_ERROR = "REFERRAL_DOCUMENT_ERROR",
  REFERRAL_DOCUMENT_INVOKE = "REFERRAL_DOCUMENT_INVOKE",
  REFERRAL_DOCUMENT_SUCCESS = "REFERRAL_DOCUMENT_SUCCESS",
  REFERRAL_DOCUMENT_RESET = "REFERRAL_DOCUMENT_RESET",
}

export enum DocumentDownloadDispatchTypes {
  DOCUMENT_DOWNLOAD_ERROR = "DOCUMENT_DOWNLOAD_ERROR",
  DOCUMENT_DOWNLOAD_INVOKE = "DOCUMENT_DOWNLOAD_INVOKE",
  DOCUMENT_DOWNLOAD_RESET = "DOCUMENT_DOWNLOAD_RESET",
  DOCUMENT_DOWNLOAD_SUCCESS = "DOCUMENT_DOWNLOAD_SUCCESS",
}

export enum ExcelDownloadDispatchTypes {
  EXCEL_DOWNLOAD_ERROR = "EXCEL_DOWNLOAD_ERROR",
  EXCEL_DOWNLOAD_INVOKE = "EXCEL_DOWNLOAD_INVOKE",
  EXCEL_DOWNLOAD_RESET = "EXCEL_DOWNLOAD_RESET",
  EXCEL_DOWNLOAD_SUCCESS = "EXCEL_DOWNLOAD_SUCCESS",
}

const itemHeight = 48;
const itemPaddingTop = 8;
export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: "15.5rem",
    },
  },
};

export enum DocumentType {
  PDF = "pdf",
  DOC = "msword",
  DOCX = "vnd.openxmlformats-officedocument.wordprocessingml.document",
  PNG = "png",
  JPEG = "jpeg",
  JPG = "jpg",
  INVALID = "",
}

export enum GenerateAuthTemplateDispatchTypes {
  GENERATE_AUTH_TEMPLATE_ERROR = "GENERATE_AUTH_TEMPLATE_ERROR",
  GENERATE_AUTH_TEMPLATE_INVOKE = "GENERATE_AUTH_TEMPLATE_INVOKE",
  GENERATE_AUTH_TEMPLATE_SUCCESS = "GENERATE_AUTH_TEMPLATE_SUCCESS",
}

export const Source = {
  FAX: "Fax",
  INBOUND: "Inbound",
};

export enum DocumentTabType {
  DOCUMENT = 1,
  AUTH_DETAILS = 0,
  ATTACH_LETTER = 2,
}

export const documentActions = [
  { label: "ADD NEW DOCUMENT" },
  { label: "GENERAL AUTH TEMPLATE" },
  { label: "SEND FAX" },
  { label: "ATTACH LETTER" },
];

export const documentDropDownButtons = [
  {
    label: "ADD NEW DOCUMENT",
    permission: `${PatientRecordsAccess.DOCUMENT_CREATE}|${PatientRecordsAccess.DOCUMENT_READ}|`,
    func: "addNewDocument()",
    isDisabled: false,
  },
  {
    label: "GENERATE AUTH TEMPLATE",
    permission: null,
    func: "handleGenerateAuthTemplate()",
    isDisabled: false,
  },
  {
    label: "SEND FAX",
    permission: null,
    func: "openSendFax()",
    isDisabled: false,
  },
  {
    label: "ATTACH LETTER",
    permission: `${AuthDetailsAccess.ATTACH_LETTER_CREATE}|${AuthDetailsAccess.ATTACH_LETTER_READ}|`,
    func: "handleAttachLetter()",
    isDisabled: false,
  },
];

export enum DocumentDropDownButtons {
  ADD_NEW_DOCUMENT = "ADD NEW DOCUMENT",
  GENERATE_AUTH_TEMPLATE = "GENERATE AUTH TEMPLATE",
  SEND_FAX = "SEND FAX",
  ATTACH_LETTER = "ATTACH LETTER",
}
export enum AttachLetterDispatchTypes {
  ATTACH_LETTER_ERROR = "ATTACH_LETTER_ERROR",
  ATTACH_LETTER_INVOKE = "ATTACH_LETTER_INVOKE",
  ATTACH_LETTER_SUCCESS = "ATTACH_LETTER_SUCCESS",
  ATTACH_LETTER_RESET = "ATTACH_LETTER_RESET",
}

export enum DocumentFieldLabel {
  SERVICE_REQUEST_NUMBER = "Service Request Number:",
  TYPE = "Type:",
  SOURCE = "Source:",
  DOC_TYPE = "Doc Type:",
  MAIL_DATE = "Mail Date:",
  TIME = "Time:",
  DATE_RECEIVED = "Date Received:",
  TIME_RECEIVED = "Time Received:",
}

export enum DocumentFieldName {
  DOCUMENT_SOURCE_ID = "documentSourceId",
  DOCUMENT_ID = "documentId",
  SERVICE_REQUEST_NUMBER = "serviceRequestId",
  TYPE = "typeId",
}

export enum DocumentComponentTitle {
  ADD_DOCUMENTATION = "ADD DOCUMENTATION:",
  ATTACH_LETTER = "ATTACH LETTER:",
  DOCUMENT_ALLOWED_FILE = "Allowed file types (.doc, .docx, .pdf, .jpeg, .jpg, .png, .xlsx, .xls, .txt)",
  ATTACH_LETTER_ALLOWED_FILE = "Allowed file types (.pdf)",
}

import { Reducer } from "redux";

import { SaveIcd10RecordResponse } from "../models/Api/AllPatientRecord";
import { SaveIcd10RecordDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { SaveIcd10RecordsActionDispatchTypes } from "../constants/AllPatientRecord";

const initialState: SaveIcd10RecordResponse = {
  loading: false,
  response: {
    id: 0,
    userCreated: null,
    icdCodesInfoDto: [],
    typeId: 0,
  },
  error: null,
};

const IcdCodeTypeReducer: Reducer<
  SaveIcd10RecordResponse,
  SaveIcd10RecordDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case SaveIcd10RecordsActionDispatchTypes.SAVE_ICD_10_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SaveIcd10RecordsActionDispatchTypes.SAVE_ICD_10_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case SaveIcd10RecordsActionDispatchTypes.SAVE_ICD_10_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default IcdCodeTypeReducer;

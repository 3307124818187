import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { ServiceProviderData } from "../../../../../models/Authorization";
import { ServiceProviderDetailsDispatchTypes } from "../../../../../models/DispatchTypes/Authorization";
import {
  deleteServiceProvider,
  getServiceProviderDetails,
  putServiceProviderDetails,
} from "../../../../../actions/AuthorizationDetails";
import {
  getServiceProviderList,
  resetServiceProviderList,
} from "../../../../../actions/Service";
import { getServiceProviderById } from "../../../../../actions/AllPatientRecord";
import ServiceProviderDetails from "../components/ServiceProviderDetails";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  loggedInUserState,
  serviceProviderDataState,
  serviceProviderDetailsState,
  serviceProviderState,
  deleteServiceProviderState
}: ApplicationState) => ({
  serviceProviderDetails: serviceProviderDetailsState,
  serviceProviderList: serviceProviderState,
  updatedIntakeServiceProviderData: serviceProviderDataState.response,
  user: loggedInUserState.response,
  deleteServiceProviderDetail: deleteServiceProviderState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ServiceProviderDetailsDispatchTypes>
  >
) => {
  return {
    getServiceProviderData: (authNo: number) => {
      dispatch(getServiceProviderDetails(authNo));
    },
    getServiceProviderById: (referralId: string) => {
      dispatch(getServiceProviderById(referralId));
    },
    getServiceProviders: (value: string) => {
      dispatch(getServiceProviderList(value));
    },
    resetServiceProvider: () => {
      dispatch(resetServiceProviderList());
    },
    putServiceProviderDetails: (
      authId: number,
      payLoad: ServiceProviderData
    ) => {
      dispatch(putServiceProviderDetails(authId, payLoad));
    },
    deleteServiceProvider: (referralId: number) => {
      dispatch(deleteServiceProvider(referralId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderDetails);

import { Reducer } from "redux";

import { PayerResponse } from "../models/Api/Master";
import { PayerListDispatchTypes } from "../models/DispatchTypes/Master";
import { PayerActionDispatchTypes } from "../constants/Master";

const initialState: PayerResponse = {
  loading: false,
  response: [],
};

const PayerReducer: Reducer<PayerResponse, PayerListDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PayerActionDispatchTypes.PAYER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PayerActionDispatchTypes.PAYER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PayerReducer;

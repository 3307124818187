import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import OrderValidation from "../components/OrderValidation";
import {
  NonCoveredServicesDispatchTypes,
  OrderAcceptorDispatchTypes,
  OrderingSourceDispatchTypes,
} from "../../../../../models/DispatchTypes/Master";
import {
  getNonCoveredService,
  getOrderAcceptor,
  getOrderingSource,
} from "../../../../../actions/Master";

const mapStateToProps = ({
  nonCoveredServiceState,
  orderAcceptorState,
  orderingSourceState,
}: ApplicationState) => ({
  nonCoveredService: nonCoveredServiceState,
  orderAcceptor: orderAcceptorState,
  orderingSource: orderingSourceState,
});

type DispatchTypes = NonCoveredServicesDispatchTypes &
  OrderAcceptorDispatchTypes &
  OrderingSourceDispatchTypes;

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<DispatchTypes>
  >
) => {
  return {
    getNonCoveredService: () => {
      dispatch(getNonCoveredService());
    },
    getOrderingSource: () => {
      dispatch(getOrderingSource());
    },
    getOrderAcceptor: () => {
      dispatch(getOrderAcceptor());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderValidation);

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  GetDisciplineLevelNote,
  GetReferralLevelNote,
  ReferralNoteResponse,
  SaveDisciplineLevelNote,
  SaveReferralLevelNote,
  UpdateDisciplineLevelNote,
  UpdateReferralLevelNote,
} from "../../../../../models/Api/Notes";
import { DisciplineNoteSearch } from "../../../../../models/Disciplines";
import { ReferralNote } from "../../../../../models/Referral";
import { DISCIPLINE_PER_PAGE } from "../../../../../constants/FunctionalUtils";
import NotesTable from "../containers/NotesTable";
import {
  PostAgencyCommunicationNoteActionDispatchTypes,
  NoteType,
  NotesReviewData,
  ReferralNoteActionDispatchTypes,
  ReferralNoteValues,
  SaveDisciplineLevelNotesActionDispatchTypes,
  SaveReferralLevelNotesActionDispatchTypes,
  UpdateDisciplineLevelNotesActionDispatchTypes,
  UpdateReferralLevelNotesActionDispatchTypes,
} from "../../../../../constants/Notes";
import { ReferralLevelNote } from "../../../../../models/Notes";
import { isNil } from "../../../../../utils";
import { AuthDetailsAccess } from "../../../../../constants/Permission";

export interface PropsFromDispatch {
  getReferralNoteList: (payload: DisciplineNoteSearch) => void;
  resetStates: (actionType: string[]) => void;
  getReferralLevelNotes: (referralNoteId: number) => void;
  saveReferralLevelNoteData: (payload: ReferralLevelNote) => void;
  updateReferralLevelNotesData: (
    disciplineNoteId: number,
    payload: ReferralLevelNote
  ) => void;
  getDisciplineLevelNotes: (disciplineNoteId: number) => void;
  setRenderReferralNotes?: Dispatch<SetStateAction<boolean>>;
  setServiceDetails: Dispatch<SetStateAction<boolean>>;
}
export interface PropsFromState {
  ReferralNoteListData: ReferralNoteResponse;
  getReferrallevelNotesData: GetReferralLevelNote;
  saveReferralLevelNoteDataResp: SaveReferralLevelNote;
  updateReferralLevelNotesDataResp: UpdateReferralLevelNote;
  GetDisciplineLevelNotesData: GetDisciplineLevelNote;
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNote;
  agencyCommunicationNote?: boolean;
  renderReferralNotes?: boolean;
}

type AllProps = PropsFromDispatch & PropsFromState;

const ReferralNoteList: React.FC<AllProps> = ({
  ReferralNoteListData,
  getReferralNoteList,
  resetStates,
  getReferralLevelNotes,
  saveReferralLevelNoteData,
  updateReferralLevelNotesData,
  getReferrallevelNotesData,
  saveReferralLevelNoteDataResp,
  updateReferralLevelNotesDataResp,
  getDisciplineLevelNotes,
  GetDisciplineLevelNotesData,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataresp,
  setRenderReferralNotes,
  renderReferralNotes,
  setServiceDetails,
  agencyCommunicationNote
}: AllProps) => {
  const referralId = location.pathname.split("/").pop();
  const [referralNoteRowCount, setReferralNoteRowCount] = useState<number>(1);
  const [page, setPage] = useState(1);
  const [referralNoteList, setReferralNoteList] = useState<ReferralNote[]>([]);
  const [disableReferralBtns, setDisableReferralBtns] =
    useState<boolean>(false);
  const [pageName, setPageName] = useState<string>("");

  const {
    loading: loadingReferralNoteList,
    response: referralNoteListResponse,
  } = ReferralNoteListData;

  const searchData = {
    id: Number(referralId),
    pageNo: 1,
    pageSize: DISCIPLINE_PER_PAGE,
  };

  useEffect(() => {
    resetStates([
      ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_RESET,
      SaveReferralLevelNotesActionDispatchTypes.SAVE_REFERRAL_NOTES_RESET,
      UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_RESET,
      SaveDisciplineLevelNotesActionDispatchTypes.SAVE_DISCIPLINE_NOTES_RESET,
      UpdateDisciplineLevelNotesActionDispatchTypes.UPDATE_DISCIPLINE_NOTES_RESET,
    ]);
    if (!loadingReferralNoteList && referralId != "") {
      getReferralNoteList(searchData);
    }
  }, [referralId]);

  useEffect(() => {
    if (
      !isNil(saveReferralLevelNoteDataResp) &&
      saveReferralLevelNoteDataResp.response.referralNoteId !== -1
    ) {
      getReferralNoteList(searchData);
    }
    if (
      !isNil(updateReferralLevelNotesDataResp) &&
      updateReferralLevelNotesDataResp.response.referralNoteId !== -1
    ) {
      getReferralNoteList(searchData);
    }
  }, [saveReferralLevelNoteDataResp, updateReferralLevelNotesDataResp]);

  useEffect(() => {
    if (
      !isNil(saveDisciplineLevelNotesDataResp) &&
      saveDisciplineLevelNotesDataResp.response.disciplineNoteId !== -1
    ) {
      getReferralNoteList(searchData);
    }
    if (
      !isNil(updateDisciplineLevelNoteDataresp) &&
      updateDisciplineLevelNoteDataresp.response.disciplineNoteId !== -1
    ) {
      getReferralNoteList(searchData);
    }
  }, [saveDisciplineLevelNotesDataResp, updateDisciplineLevelNoteDataresp]);

  useEffect(() => {
    if (referralNoteListResponse) {
      setReferralNoteList(
        referralNoteListResponse && referralNoteListResponse.referralNoteDetail
      );
      setReferralNoteRowCount(
        referralNoteListResponse && referralNoteListResponse.totalRows
      );
    }
  }, [referralNoteListResponse && referralNoteListResponse.referralNoteDetail]);

  const handlePageChange = (_e: any, page: number) => {
    setPage(page);
    callAction(Number(referralId), Number(page), DISCIPLINE_PER_PAGE);
  };

  const callAction = (referralId: number, page: number, pageSize: number) =>{
    const payload = {
      id: referralId,
      pageNo: page,
      pageSize: pageSize,
    };
    getReferralNoteList(payload);
  }

  useEffect(() => {
    if(agencyCommunicationNote === true){
      callAction(Number(referralId), 1, DISCIPLINE_PER_PAGE);
      resetStates([
        PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_RESET
      ]);
    }
  },[agencyCommunicationNote])

  

  return (
    <NotesTable
      list={referralNoteList}
      totalRows={referralNoteRowCount}
      headerLabel={ReferralNoteValues}
      title={NotesReviewData.REFERRAL_NOTES}
      handlePageChange={handlePageChange}
      page={page}
      setDisableReferralBtns={setDisableReferralBtns}
      disableReferralBtns={disableReferralBtns}
      getReferralLevelNotes={getReferralLevelNotes}
      getReferrallevelNotesData={getReferrallevelNotesData}
      saveReferralLevelNoteData={saveReferralLevelNoteData}
      updateReferralLevelNotesData={updateReferralLevelNotesData}
      saveReferralLevelNoteDataResp={saveReferralLevelNoteDataResp}
      updateReferralLevelNotesDataResp={updateReferralLevelNotesDataResp}
      getDisciplineLevelNotes={getDisciplineLevelNotes}
      GetDisciplineLevelNotesData={GetDisciplineLevelNotesData}
      addControlId={`${AuthDetailsAccess.AUTH_DETAILS_ADD_NOTES_CREATE}|${AuthDetailsAccess.AUTH_DETAILS_ADD_NOTES_READ}|`}
      editControlId={`|${AuthDetailsAccess.AUTH_DETAILS_ADD_NOTES_READ}|${AuthDetailsAccess.AUTH_DETAILS_ADD_NOTES_UPDATE}`}
      setRenderReferralNotes={setRenderReferralNotes}
      renderReferralNotes={renderReferralNotes}
      setPageName={setPageName}
      pageName={pageName}
      setServiceDetails={setServiceDetails}
      type={NoteType.REFERRAL_NOTE}
    />
  );
};

export default ReferralNoteList;

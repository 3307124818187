import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  ReAuthReviewActionDispatchType,
  ReviewActionDispatchTypes,
} from "../constants/Review";
import {
  ReAuthReviewDispatchTypes,
  ReviewDispatchTypes,
} from "../models/DispatchTypes/Review";
import { ApplicationState } from "../store/ApplicationState";
import { configUrlForReferral } from "../constants/Config";
import { GetReAuthReview, GetReview } from "../services/Review";

export const getReview: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReviewDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReviewActionDispatchTypes.REVIEW_INVOKE,
    });

    const response = await GetReview(configUrlForReferral, referralId);

    dispatch({
      response,
      type: ReviewActionDispatchTypes.REVIEW_SUCCESS,
    });
  };
};

export const getReAuthReview: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReAuthReviewDispatchTypes
  >
> = (reAuthId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthReviewActionDispatchType.RE_AUTH_REVIEW_INVOKE,
    });

    const response = await GetReAuthReview(configUrlForReferral, reAuthId);

    dispatch({
      response: response,
      type: ReAuthReviewActionDispatchType.RE_AUTH_REVIEW_SUCCESS,
    });
  };
};

import { FaPhone, FaRegTrashAlt, FaSearch, FaUserNurse } from "react-icons/fa";
import { Box, styled } from "@mui/system";

import others from "../../../../../styles/colors/others";
import colors from "../../../../../styles/colors/colors";
import accent from "../../../../../styles/colors/accent";

export const OuterGridContainer = () => ({
  backgroundColor: others.otherColors[33],
  margin: "0.975rem",
  padding: "0.1rem",
  borderRadius: "0.487rem",
});

export const GridTitle = () => ({
  padding: "0.5rem",
});

export const TitleGridItem = () => ({
  padding: "0.6rem 0rem 0rem 0.6rem",
  textAlign: "initial",
});

export const TitleBox = () => ({
  paddingBottom: "0.487rem",
});

export const DividerStyle = () => ({
  padding: "0 0 1rem 0rem",
});

export const SearchGridContainer = () => ({
  padding: "1.2rem 0rem 0rem 2.56rem",
});

export const DetailsGridContainer = () => ({
  padding: "0rem 0rem 3rem 1.5rem",
});

export const DetailsInfoContainer = () => ({
  padding: "1.5rem 2.3rem 0rem  0",
});

export const AddManuallyGridContainer = () => ({
  padding: "1rem 0 1rem 0.4rem",
});

export const AddManually = () => ({
  padding: "1rem 0 2rem 1rem",
});

export const TextFiledPadding = () => ({
  paddingLeft: "1rem",
});

export const AutocompleteUI = () => ({
  width: "100%",
  position: "relative",
});

export const BoxPhoneNo = styled(Box)(() => ({
  height: "1.25rem",
  display: "flex",
  textAlign: "left",
  color: colors.fonts[2],
  margin: "0.72rem 0 0 0",
  padding: "0 1.25rem 0 0",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "left",
  color: colors.fonts[2],
  padding: "0.2rem 0 0 0.72rem",
}));

export const IconFaUserNurse = styled(FaUserNurse)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "left",
  color: colors.primary.main,
  margin: "0 0.6rem 0.23rem 0rem",
}));

export const FaSearchIcon = styled(FaSearch)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const IconFaRegTrashAlt = styled(FaRegTrashAlt)(() => ({
  fontSize: "1.35rem",
  color: accent.accentOrange[100],
  cursor: "pointer",
  margin: "0 0.6rem 0.23rem 0.6rem",
}));

export const IconFaPhone = styled(FaPhone)(() => ({
  color: colors.primary.main,
  width: "1.2rem",
  height: "0.9rem",
  margin: "0rem 0.5rem 0 0",
}));

export const SctionOrContainer = () => ({
  padding: "1.3rem 0rem 0rem 2rem",
});

export const OrBoxStyle = () => ({
  width: "2rem",
  height: "2rem",
  textAlign: "center",
  justifyContent: "center",
  borderRadius: "1rem",
  backgroundColor: colors.fonts[11],
});

export const OrTextStyle = () => ({
  color: others.otherColors[33],
});

export const OrDividerStyle = {
  paddingTop: "1rem",
};

export const FormInputBoxAutoComplete = {
  padding: "1rem 0",
};

export const FormInputBox = {
  padding: "1rem 2rem 1rem 1rem ",
};

export const TableHeadMargin = (isHeader: boolean) => ({
  marginTop: isHeader ? "1rem" : "auto",
});

export const SearchedResultTableHead = () => ({
  padding: ".75rem 0 .75rem 1.5rem",
  backgroundColor: others.MainBackground[1],
  borderBottom: 0,
  "&.MuiTableCell-root:first-of-type": {
    borderTopLeftRadius: "0.19rem",
    borderBottomLeftRadius: "0.19rem",
    paddingLeft: "1.5rem",
  },
  "&.MuiTableCell-root:last-child": {
    borderTopRightRadius: "0.19rem",
    borderBottomRightRadius: "0.19rem",
  },
});

export const SearchedResultTableBody = () => ({
  padding: ".5rem 0 .4rem 1.5rem",
  textTransform: "capitalize",
  border: 0,
  borderBottom: `0.05rem solid ${colors.borders[1]}`,
  "&.MuiTableCell-root:first-of-type": {
    paddingLeft: "1.5rem",
  },
});

export const Body = { padding: "1.25rem" };

export const AddAddressBox = () => ({
  marginLeft: "1.5rem",
  marginTop: "1.5rem",
});

export const AddressDetailHeader = () => ({
  display: "flex",
  marginLeft: "1rem",
});

export const AddressHeaderBox = () => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  marginBottom: "1rem",
});

export const MemberAddressBox = () => ({
  background: others.otherColors[44],
  padding: "0.7rem",
  margin: "0 1rem 0",
  borderRadius: "0.5rem",
});

export const AddAddressGrid = () => ({
  marginTop: "2rem",
  marginLeft: "0.5rem",
});

import { SaveInformation, SavePatientInformation } from "../models/Patient";

export enum PatientInformationActionDispatchTypesView {
  VIEW_PATIENT_INFORMATION_FAIL = "VIEW_PATIENT_INFORMATION_FAIL",
  VIEW_PATIENT_INFORMATION_INVOKE = "VIEW_PATIENT_INFORMATION_INVOKE",
  VIEW_PATIENT_INFORMATION_RESET = "VIEW_PATIENT_INFORMATION_RESET",
  VIEW_PATIENT_INFORMATION_SUCCESS = "VIEW_PATIENT_INFORMATION_SUCCESS",

  UPDATE_PATIENT_INFORMATION_ERROR = "UPDATE_PATIENT_INFORMATION_ERROR",
  UPDATE_PATIENT_INFORMATION_INVOKE = "UPDATE_PATIENT_INFORMATION_INVOKE",
  UPDATE_PATIENT_INFORMATION_SUCCESS = "UPDATE_PATIENT_INFORMATION_SUCCESS",
  UPDATE_PATIENT_INFORMATION_RESET = "UPDATE_PATIENT_INFORMATION_RESET",
}

export enum PatientInformationReceivedActionDispatchTypes {
  INFORMATION_RECEIVED_INVOKE = "INFORMATION_RECEIVED_INVOKE",
  INFORMATION_RECEIVED_SUCCESS = "INFORMATION_RECEIVED_SUCCESS",
  INFORMATION_RECEIVED_RESET = "INFORMATION_RECEIVED_RESET",
}

export const GetPatientInfo: SavePatientInformation = {
  createPatient: {
    patientId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    userCreated: null,
    cellPhone: "",
    phoneNo: "",
    extNo: "",
    emailAddress: "",
    isManual: false,
  },
  patientResidentialAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 1,
    country: null,
  },
  patientMailingAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 3,
    country: null,
  },
  informationReceived: {
    ahcccsId: null,
    effectiveDate: "",
    effectiveThrough: "",
    eligibilityStatus: "",
    healthPlan: "",
    benefitPlanName: "",
    healthPlanState: "",
    medicareBeneficiaryIdentifier: "",
    patientId: 0,
    policyId: "",
    product: "",
    subscriberId: "",
    benefitPlanUid: "",
    healthPlanUid: "",
    apiEligibilityId: "",
    eligibilityPayerId: "",
    eligibilityPayerName: "",
    isManual: false,
    alternatePhone: "",
  },
};

export const UpdateEligibilityForm: SaveInformation = {
  informationReceived: {
    ahcccsId: null,
    effectiveDate: "",
    effectiveThrough: "",
    eligibilityStatus: "",
    healthPlan: "",
    benefitPlanName: "",
    healthPlanState: "",
    medicareBeneficiaryIdentifier: null,
    patientId: 0,
    product: "",
    subscriberId: "",
    benefitPlanUid: "",
    healthPlanUid: "",
    apiEligibilityId: null,
    eligibilityPayerId: "",
    eligibilityPayerName: "",
    isManual: false,
    alternatePhone: null,
  },
  patientResidentialAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 1,
    country: null,
  },
  patientMailingAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 3,
    country: null,
  },
};

export enum EligibilityInformation {
  UPDATE = "UPDATE ELIGIBILITY INFORMATION:",
  ADD = "ADD ELIGIBILITY INFORMATION",
}

import { Reducer } from "redux";

import { DiseaseTypeResponse } from "../models/Api/Master";
import { DiseaseTypeDispatchTypes } from "../models/DispatchTypes/Master";
import { DiseaseTypeActionDispatchTypes } from "../constants/Master";
const initialResponse = [
  {
    icdDiagnosisCodeId: 0,
    icdCode: "",
    icdDescription: "",
  },
];

const initialState: DiseaseTypeResponse = {
  loading: false,
  response: initialResponse,
};

const IcdCodeTypeReducer: Reducer<
  DiseaseTypeResponse,
  DiseaseTypeDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DiseaseTypeActionDispatchTypes.DISEASE_TYPE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DiseaseTypeActionDispatchTypes.DISEASE_TYPE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default IcdCodeTypeReducer;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import AddEligibilityDetails from "../components/AddEligibilityDetails";
import {
  GetBenefitPlanDetailsMasterResp,
  getHealthPlanMasterResp,
} from "../../../../../actions/Master";
import { BenefitPlanDetailsDispatchTypes } from "../../../../../models/DispatchTypes/Patient";
import { HealthPlanDispatchTypes } from "../../../../../models/DispatchTypes/Master";

const mapStateToProps = ({
  healthPlanMasterState,
  benefitPlanDetailsState,
}: ApplicationState) => ({
  healthPlanMaster: healthPlanMasterState.response,
  benefitPlanDetails: benefitPlanDetailsState.response,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<HealthPlanDispatchTypes | BenefitPlanDetailsDispatchTypes>
  >
) => {
  return {
    getHealthPlanMasterResp: () => {
      dispatch(getHealthPlanMasterResp());
    },
    getBenefitPlanDetailsMasterResp: (healthPlanId: number) => {
      dispatch(GetBenefitPlanDetailsMasterResp(healthPlanId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEligibilityDetails);

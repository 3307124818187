export const USER_MANAGEMENT_DESCRIPTION = "View and take actions on user based settings, edit/assign rights to users here.";

export enum UserListActionDispatchTypes {
  USER_LIST_INVOKE = "USER_LIST_INVOKE",
  USER_LIST_SUCCESS = "USER_LIST_SUCCESS",
}

export enum UserDetailsActionDispatchTypes {
  USER_DETAILS_INVOKE = "USER_DETAILS_INVOKE",
  USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS",

  USER_DETAILS_POST_INVOKE = "USER_DETAILS_POST_INVOKE",
  USER_DETAILS_POST_SUCCESS = "USER_DETAILS_POST_SUCCESS",

  USER_DETAILS_POST_ERROR = "USER_DETAILS_POST_ERROR",

  USER_DETAILS_RESET = "USER_DETAILS_RESET",

  LOGIN_INVOKE = "LOGIN_INVOKE",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
}

export enum LoggedInUserDetailsActionDispatchTypes {
  LOGIN_INVOKE = "LOGIN_INVOKE",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",

  PUT_USER_DETAILS_ERROR = "PUT_USER_DETAILS_ERROR",
  PUT_USER_DETAILS_INVOKE = "PUT_USER_DETAILS_INVOKE",
  PUT_USER_DETAILS_SUCCESS = "PUT_USER_DETAILS_SUCCESS",
}

export const HeaderLabel = [
  { label: "PICTURE", width: 30 },
  { label: "NAME", width: 220 },
  { label: "EMAIL", width: 100 },
  { label: "USER STATUS", width: 120 },
  { label: "LAST LOGIN", width: 170 },
  { label: "ROLES", width: 300 },
  { label: "ACTION", width: 40 },
];

export const ACTIVE = "Active";
export const ADMIN_CENTER = "Admin Center";
export const ROUTE_USER_DETAILS = "/userdetails";
export const SUSPENDED = "Suspended";

export enum AdminCenterBreadCrumbs {
  ROLE_DETAILS = "roledetails",
  ROLE_MANAGEMENT = "rolemanagement",
  USER_DETAILS = "userdetails",
  USER_MANAGMENT = "usermanagement",
  NOTE_MANAGEMENT = "notemanagement",
  NOTE_TEMPLATE = "createtemplate",
}

export enum UserPermissionActionDispatchTypes {
  USER_PERMISSION_INVOKE = "USER_PERMISSION_INVOKE",
  USER_PERMISSION_SUCCESS = "USER_PERMISSION_SUCCESS",
  USER_PERMISSION_ERROR = "USER_PERMISSION_ERROR",
  USER_PERMISSION_RESET = "USER_PERMISSION_RESET",
}

export enum RouteUrlInfoActionDispatchTypes {
  ROUTE_URL_INFO_INVOKE = "ROUTE_URL_INFO_INVOKE",
  ROUTE_URL_INFO_SUCCESS = "ROUTE_URL_INFO_SUCCESS",
}

export enum PermissionActionDispatchTypes {
  PERMISSION_INVOKE = "PERMISSION_INVOKE",
  PERMISSION_SUCCESS = "PERMISSION_SUCCESS",
}

export const UNASSIGNED_USER: any = {
  userId: -1,
  fullName: "Unassigned",
  userPhoto: "",
  email: "",
  userStatus: "",
  lastLogin: "",
  roles: "",
  userName: ""
}//unshift function will required any data type here to add 0th index

export enum UserListWithFilterActionDispatchTypes {
  GET_USER_LIST_WITH_FILTER_INVOKE = "GET_USER_LIST_WITH_FILTER_INVOKE",
  GET_USER_LIST_WITH_FILTER_SUCCESS = "GET_USER_LIST_WITH_FILTER_SUCCESS"
}

import { colors, others } from "../../../../../styles/colors";

export const ModalGrid = () => ({
  position: "absolute",
  top: "20%",
  left: "20%",
  width: "60%",
  height: "auto",
  bgcolor: "background.paper",
  padding: "1.5rem 1.5rem",
  borderRadius: "0.5rem",
  marginLeft: "1rem",
  zIndex: 1
});

export const HeaderBox = () => ({
  padding: "1rem",
});

export const Info = () => ({
  marginTop: "0.5rem",
  paddingLeft: "0.5rem",
});

export const DobBox = () => ({
  paddingLeft: "1.2rem",
  paddingTop: "0.8rem",
});

export const OuterBodyGrid = () => ({
  backgroundColor: colors.backgroundColor[3],
  opacity: 1,
  padding: "1rem 0.7rem",
  borderRadius: "0.5rem",
});

export const GridBody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "2rem 1.5rem",
  paddingLeft: "2.3rem",
  margin: "0.5rem",
  borderRadius: "1.2rem",
  display: "flex",
});

export const GridPatientInfo = () => ({
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  paddingTop: "0.8rem",
});

export const GridFooter = () => ({
  margin: "0.4rem",
  display: "flex",
  padding: "0.4rem 0",
  justifyContent: "end",
});

export const GridCheckEligibility = () => ({
  display: "flex",
  padding: "0.4rem 0.4rem 0.4rem 0",
  justifyContent: "end",
});

export const CrossIconBox = () => ({
  paddingTop: "0.5rem",
  cursor: "pointer",
});

export const Crossicon = {
  color: colors.red[80],
  opacity: 2,
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
};

export const ModalTitle = {
  paddingTop: "0.4rem",
  paddingBottom: "0.5rem",
};

export const ContentBox = () => ({
  display: "flex",
  flexDirection: "row",
});
export const SubHeaderBox = () => ({
  paddingTop: "0.2rem",
  paddingBottom: "0.5rem",
});
export const ContentBoxHeader = () => ({
  display: "flex",
  flexDirection: "row",
  paddingBottom: "1rem",
});

export const QuestionBox = () => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "3rem",
  paddingLeft: "3rem",
  paddingBottom: "3.5rem",
  alignItems: "center",
});

export const Question = () => ({
  paddingLeft: "1rem",
  paddingRight: "2rem",
  paddingBotton: "2rem",
  lineHeight: "1.2rem",
});

export const DetailsDivider = () => ({
  margin: "0 0.5rem",
});

export const AvatarIcon = () => ({
  width: "3.6rem",
  height: "3.8rem",
});

export const QuestionIcon = () => ({
  color: colors.fonts[18],
  fontSize: "1.7rem",
  paddingLeft: "2.6rem",
  paddingRight: "0.6rem",
  alignItems: "center",
});

export const NameBox = () => ({
  paddingLeft: "0.5rem",
});

export const BackButton = () => ({
  width: "4.6rem",
  height: "2.2rem",
});

export const CreateBtn = () => ({
  height: "2.2rem",
});

export const FilterParameterGrid = () => ({
  paddingRight: "2rem",
});

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { AuthorizationHistoryDispatchTypes } from "../../../../../models/DispatchTypes/AuthorizationHistory";
import HistoryTabs from "../components/HistoryTabs";
import {
  getAuthorizationHistory,
} from "../../../../../actions/AuthorizationHistory";
import { AuthAndEligibilityHistoryPayload } from "../../../../../models/AuthorizationHistory";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  authorizationhistoryState,
  eligibilityHistoryState,
}: ApplicationState) => ({
  authorizationHistory: authorizationhistoryState,
  eligibilityHistoryResp: eligibilityHistoryState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AuthorizationHistoryDispatchTypes>
  >
) => {
  return {
    getAuthorizationHistoryRecord: (
      payload: AuthAndEligibilityHistoryPayload
    ) => {
      dispatch(getAuthorizationHistory(payload));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTabs);

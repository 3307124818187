import { Reducer } from "redux";

import { DisciplineTemplateNotesResponse } from "../models/Api/Notes";
import { DisciplineTemplateNotesDispatchTypes } from "../models/DispatchTypes/Notes";
import { MasterDisciplineTemplateNotesActionDispatchTypes } from "../constants/Notes";

export const initialState: DisciplineTemplateNotesResponse = {
  loading: false,
  response: [],
};

const MasterDisciplineTemplateNotesReducer: Reducer<
  DisciplineTemplateNotesResponse,
  DisciplineTemplateNotesDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case MasterDisciplineTemplateNotesActionDispatchTypes.DISCIPLINE_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MasterDisciplineTemplateNotesActionDispatchTypes.DISCIPLINE_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterDisciplineTemplateNotesReducer;

import { Reducer } from "redux";

import { ServiceStatusActionDispatchTypes } from "../constants/Master";
import { ServiceStatusReasonResponse } from "../models/Api/Master";
import { SeriveStatusDispatchTypes } from "../models/DispatchTypes/Master";

export const initialPostState: ServiceStatusReasonResponse = {
  loading: false,
  response: [
    {
      serviceStatusReasonId: -1,
      serviceStatusReason: "",
      captureRequesterName: false,
    },
  ],
};

const DisciplineReducer: Reducer<
  ServiceStatusReasonResponse,
  SeriveStatusDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case ServiceStatusActionDispatchTypes.SERVICE_STATUS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceStatusActionDispatchTypes.SERVICE_STATUS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DisciplineReducer;

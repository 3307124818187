import { documentFaxMaxSize } from "./Config";

export enum MessageType {
  DELETE_SUCCESS = "DELETE_SUCCESS",
  DOCUMENT_DOWNLOAD_ERROR = "DOCUMENT_DOWNLOAD_ERROR",
  INFORMATION_RECEIVED = "INFORMATION_RECEIVED",
  PATIENT_DETAILS = "PATIENT_DETAILS",
  POST_SUCCESS = "POST_SUCCESS",
  PUT_SUCCESS = "PUT_SUCCESS",
  REQUEST_FAILED = "REQUEST_FAILED",
  UPDATE_USER_DATA = "UPDATE USER DATA",
  SAVE_ADMIN_NOTES_DATA = "SAVE_ADMIN_NOTES_DATA",
  ADMIN_NOTES_DATA_ERROR = "ADMIN_NOTES_DATA_ERROR",
  UPDATE_NOTES_DATA = "UPDATE_NOTES_DATA",
  UPDATE_NOTES_DATA_ERROR = "UPDATE_NOTES_DATA_ERROR",
  GET_NOTES_DATA = "GET_NOTES_DATA",
  GET_NOTES_DATA_ERROR = "GET_NOTES_DATA_ERROR",
  SAVE_SERVICE_REQUEST = "SAVE_SERVICE_REQUEST",
  GET_DISCIPLINE_LEVEL_NOTES = "GET_DISCIPLINE_LEVEL_NOTES",
  SAVE_DISCIPLINE_LEVEL_NOTES = "SAVE_DISCIPLINE_LEVEL_NOTES",
  UPDATE_DISCIPLINE_LEVEL_NOTES = "UPDATE_DISCIPLINE_LEVEL_NOTES",
  GET_REFERRAL_LEVEL_NOTES = "GET_REFERRAL_LEVEL_NOTES",
  SAVE_REFERRAL_LEVEL_NOTES = "SAVE_REFERRAL_LEVEL_NOTES",
  UPDATE_REFERRAL_LEVEL_NOTES = "UPDATE_REFERRAL_LEVEL_NOTES",
  DEACTIVATE_SUCCESS = "DEACTIVATE_SUCCESS",
  NOTES_PREVIEW_ERROR = "NOTES_PREVIEW_ERROR",
  SEND_FAX_MAX_SIZE = "SEND_FAX_MAX_SIZE",
  UPDATE_PATIENT_HEADER_DATA = "UPDATE_PATIENT_HEADER_DATA",
  AGENCY_COMMUNICATION_NOTE = "AGENCY_COMMUNICATION_NOTE",
  UPDATE_ELIGIBILITY_INFORMATION = "UPDATE_ELIGIBILITY_INFORMATION",
}

export const ResponseMessages = (value: string) => {
  switch (value) {
    case MessageType.UPDATE_USER_DATA:
      return "User Details Updated Successfully !";
    case MessageType.PATIENT_DETAILS:
      return "Patient Details Saved Successfully !";
    case MessageType.INFORMATION_RECEIVED:
      return "Information Received Data Saved Successfully !";
    case MessageType.POST_SUCCESS:
      return "Data Saved Successfully!";
    case MessageType.PUT_SUCCESS:
      return "Data Updated Successfully!";
    case MessageType.DELETE_SUCCESS:
      return "Data Deleted Successfully!";
    case MessageType.DOCUMENT_DOWNLOAD_ERROR:
      return "Document Download Error!";
    case MessageType.REQUEST_FAILED:
      return "Something Went Wrong";
    case MessageType.SAVE_ADMIN_NOTES_DATA:
      return "Notes Saved Successfully !";
    case MessageType.ADMIN_NOTES_DATA_ERROR:
      return "Notes Could Not Be Saved Successfully !";
    case MessageType.UPDATE_NOTES_DATA:
      return "Notes Updated Successfully !";
    case MessageType.UPDATE_NOTES_DATA_ERROR:
      return "Notes Update Failure !";
    case MessageType.GET_NOTES_DATA_ERROR:
      return "Failed to load Notes !";
    case MessageType.SAVE_SERVICE_REQUEST:
      return "Service Request Saved Successfully !";
    case MessageType.DEACTIVATE_SUCCESS:
      return "Template Status Updated Successfully !";
    case MessageType.NOTES_PREVIEW_ERROR:
      return "Error in Notes Preview !";
    case MessageType.SAVE_REFERRAL_LEVEL_NOTES:
      return "Referral Notes Saved Successfully !";
    case MessageType.UPDATE_REFERRAL_LEVEL_NOTES:
      return "Referral Notes Updated Successfully !";
    case MessageType.SAVE_DISCIPLINE_LEVEL_NOTES:
      return "Discipline Notes Saved Successfully !";
    case MessageType.UPDATE_DISCIPLINE_LEVEL_NOTES:
      return "Discipline Notes Updated Successfully !";
    case MessageType.UPDATE_PATIENT_HEADER_DATA:
      return "Patient Details Updated Successfully !";
    case MessageType.AGENCY_COMMUNICATION_NOTE:
      return "Agency Communication Note Saved Successfully !";
    case MessageType.SEND_FAX_MAX_SIZE:
      return `This file can not be selected as total file size crossing ${documentFaxMaxSize}MB limit !`;
    case MessageType.UPDATE_ELIGIBILITY_INFORMATION:
      return "Eligibility Information Updated Successfully !";
    default:
      return "";
  }
};

export const NO_RECORD_FOUND = "NO RECORD FOUND";

import {
  Grid,
  Box,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import { RxAvatar } from "react-icons/rx";
import { FaArrowRight } from "react-icons/fa";
import { isEmpty } from "lodash";

import {
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  RequestHeaderBox,
  OrderBox,
  AddressRadio,
  SelectBox,
  OrderGrid,
  OrderLine,
  OrderRadio,
  NonCoveredCheckbox,
  CustDialRadio,
  BoxRadio,
  AddressDetailHeader,
} from "../styles/requestStyle";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors, others } from "../../../../../styles/colors";
import { PatientRequest } from "../../../../../models/Patient";
import {
  NonCoveredServicesResponse,
  OrderAcceptorResponse,
  OrderingSourceResponse,
} from "../../../../../models/Api/Master";
import { Controller } from "react-hook-form";
import { FormInputDate } from "../../../../../components/formComponents/FormInputDate";
import { getValue, length } from "../../../../../utils";
import {
  NonCoveredServices,
  OrderAcceptor,
  OrderingSource,
} from "../../../../../models/Master";
import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import { RadioButton } from "../../../../../constants/AllPatientRecord";
import { ReviewRequestDetailsQuestions } from "../../../../../constants/Review";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";

export interface PropsFromState {
  nonCoveredService: NonCoveredServicesResponse;
  orderingSource: OrderingSourceResponse;
  orderAcceptor: OrderAcceptorResponse;
  values: PatientRequest;
  control: any;
  setValue: (id: any, value: any, boolean: any) => void;
  clearErrors: (name: string) => void;
}

export interface PropsFromDispatch {
  getNonCoveredService: () => void;
  getOrderingSource: () => void;
  getOrderAcceptor: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const OrderValidation: React.FC<AllProps> = ({
  control,
  setValue,
  values,
  getNonCoveredService,
  getOrderingSource,
  getOrderAcceptor,
  nonCoveredService,
  orderingSource,
  orderAcceptor,
  clearErrors,
}: AllProps) => {
  const [showRequestor, setShowRequestor] = useState(true);
  const [containHealthOrder, setContainHealthOrder] = useState("");
  const {
    loading: loadingNonCoveredService,
    response: nonCoveredServiceResponse,
  } = nonCoveredService;
  const { loading: loadingOrderingSource, response: orderingSourceResponse } =
    orderingSource;
  const { loading: loadingOrderAcceptor, response: orderAcceptorResponse } =
    orderAcceptor;
  const [nonCoveredServiceList, setNonCoveredServiceList] = useState<
    string | null
  >();
  const [nonCoveredServiceNameList, setNonCoveredServiceNameList] = useState<
    string | null
  >();
  const [isNonCoveredServices, setIsNonCoveredServices] = useState("No");

  useEffect(() => {
    if (!loadingNonCoveredService) {
      getNonCoveredService();
    }
    if (!loadingOrderingSource) {
      getOrderingSource();
    }
    if (!loadingOrderAcceptor) {
      getOrderAcceptor();
    }
  }, []);

  const onChange = (e: any) => {
    setContainHealthOrder(e.target.value);
    if (e.target.value === "No" || e.target.value === "Unknown") {
      setValue("referralDetail.isCustodialCare", null, true);
      setValue("referralDetail.isNonCoveredService", null, true);
      setValue("referralDetail.nonCoveredServiceNames", null, true);
      setValue("referralDetail.nonCoveredServices", null, true);
      setValue("referralDetail.acceptedById", null, true);
      setValue("referralDetail.acceptedByName", null, true);
      setValue("referralDetail.orderedById", null, true);
      setValue("referralDetail.orderedByName", null, true);
      setValue("referralDetail.signedOrderDate", null, true);
      setValue("referralDetail.signedOrderType", null, true);
      setNonCoveredServiceList(null);
    }
    setValue(e.target.name, e.target.value, true);

    clearErrors(e.target.name);
  };

  useEffect(() => {
    setContainHealthOrder(
      getValue(values, "referralDetail.isSignedHealthOrder")
    );
    setNonCoveredServiceList(
      getValue(values, "referralDetail.nonCoveredServices")
    );
    setIsNonCoveredServices(
      getValue(values, "referralDetail.isNonCoveredService")
    );
  }, [values && values.referralDetail]);

  useEffect(() => {
    if (isEmpty(isNonCoveredServices) || !isNonCoveredServices) {
      setNonCoveredServiceList(null);
    }
  }, [isNonCoveredServices]);

  const handleNonCoveredService = (e: any) => {
    const nonCoverService = nonCoveredServiceList
      ? nonCoveredServiceList.split(",")
      : [];
    const nonCoverServiceName = nonCoveredServiceNameList
      ? nonCoveredServiceNameList.split(",")
      : [];
    if (e.target.checked) {
      nonCoverService.push(e.target.value);
      const service =
        nonCoveredServiceResponse && length(nonCoveredServiceResponse)
          ? nonCoveredServiceResponse.filter((item: NonCoveredServices) => {
              return item.serviceId == e.target.value;
            })
          : [];
      if (service && length(service)) {
        nonCoverServiceName.push(service[0].serviceName);
      }
      setNonCoveredServiceNameList(nonCoverServiceName.toString());
      setNonCoveredServiceList(nonCoverService.toString());
    } else {
      const newNonCoverService =
        nonCoverService && length(nonCoverService)
          ? nonCoverService.filter((item) => {
              return item.trim() != e.target.value;
            })
          : [];

      const service =
        nonCoveredServiceResponse && length(nonCoveredServiceResponse)
          ? nonCoveredServiceResponse.filter((item: NonCoveredServices) => {
              return item.serviceId == e.target.value;
            })
          : [];
      if (service && length(service)) {
        const newNonCoverServiceName =
          nonCoverServiceName && length(nonCoverServiceName)
            ? nonCoverServiceName.filter((item) => {
                return item.trim() != service[0].serviceName;
              })
            : [];
        setNonCoveredServiceNameList(newNonCoverServiceName.toString());
      }
      setNonCoveredServiceList(newNonCoverService.toString());
    }
    clearErrors(e.target.name);
  };

  useEffect(() => {
    setValue("referralDetail.nonCoveredServices", nonCoveredServiceList, true);
  }, [nonCoveredServiceList]);

  useEffect(() => {
    setValue(
      "referralDetail.nonCoveredServiceNames",
      nonCoveredServiceNameList,
      true
    );
  }, [nonCoveredServiceNameList]);

  const onChangeNonCovered = (e: any) => {
    setIsNonCoveredServices(e.target.value);
    if (e.target.value !== "Yes") {
      setNonCoveredServiceList(null);
    }
    setValue("referralDetail.isNonCoveredService", e.target.value, true);
    clearErrors("referralDetail.isNonCoveredService");
  };

  const orderingSourceList = (
    orderingSourceResponse: OrderingSource[] | undefined
  ): DropdownOption[] => {
    if (orderingSourceResponse && length(orderingSourceResponse)) {
      return orderingSourceResponse.map((orderingSource) => ({
        label: orderingSource.orderedByName,
        value: orderingSource.orderedById,
      }));
    }
    return [];
  };

  const orderAcceptorList = (
    orderAcceptorResponse: OrderAcceptor[]
  ): DropdownOption[] => {
    if (orderAcceptorResponse && length(orderAcceptorResponse)) {
      return orderAcceptorResponse.map((orderAcceptor) => ({
        label: orderAcceptor.acceptedByName,
        value: orderAcceptor.acceptedById,
      }));
    }
    return [];
  };

  return (
    <Grid pb={"3.5rem"}>
      <Box sx={RequestHeaderBox(RequesterComponentInjectType.PRIOR_AUTH)}>
        {showRequestor ? (
          <FaChevronCircleUpIcon onClick={() => setShowRequestor(false)} />
        ) : (
          <FaChevronCircleDownIcon onClick={() => setShowRequestor(true)} />
        )}
        <Box pb={"0.6rem"}>
          <Typography
            variant="h6"
            fontWeight={fontWeight.Weight[5]}
            color={others.otherColors[63]}
            sx={AddressDetailHeader}
          >
            ORDER VALIDATION:
          </Typography>
          <Typography
            variant="body1"
            fontWeight={fontWeight.Weight[2]}
            color={colors.black[5]}
            sx={AddressDetailHeader}
            mt={"0.7rem"}
          >
            Select order specific details and information requied below.
          </Typography>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />

      {showRequestor && (
        <Box sx={OrderBox}>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[3]}
            color={colors.fonts[1]}
          >
            {ReviewRequestDetailsQuestions.HOME_HEALTH_ORDER}
          </Typography>
          <Box ml={"0.3rem"} mb={"1.5rem"}>
            <Controller
              defaultValue=""
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="controlled-radio-buttons-group"
                  {...field}
                  sx={CustDialRadio}
                  onChange={onChange}
                >
                  {RadioButton &&
                    RadioButton.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            {item.label}
                          </Typography>
                        }
                      />
                    ))}
                </RadioGroup>
              )}
              name={`referralDetail.isSignedHealthOrder`}
              control={control}
            />
            <Box display={containHealthOrder === "Yes" ? "block" : "none"}>
              <Box ml={"0.3rem"} mt={"1.6rem"}>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[1]}
                >
                  If Yes please enter below details:
                </Typography>

                <Grid container sx={OrderGrid}>
                  <Grid item pt={"1.5rem"}>
                    <FormInputDate
                      control={control}
                      label={"Order Date:"}
                      name={`referralDetail.signedOrderDate`}
                    />
                  </Grid>

                  <Grid item ml={"5.5rem"} pt={"1.5rem"}>
                    <Box sx={BoxRadio}>
                      <Controller
                        defaultValue=""
                        render={({ field }) => (
                          <RadioGroup
                            aria-labelledby="controlled-radio-buttons-group"
                            {...field}
                            sx={AddressRadio}
                          >
                            <FormControlLabel
                              value={"Verbal"}
                              control={<Radio />}
                              label={
                                <Typography
                                  variant="subtitle1"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                >
                                  Verbal
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value={"Written"}
                              control={<Radio />}
                              label={
                                <Typography
                                  variant="subtitle1"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                >
                                  Written
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        )}
                        name={`referralDetail.signedOrderType`}
                        control={control}
                      />
                    </Box>
                  </Grid>

                  <Grid item sx={flexAlignCentre} xs={2} ml={"5rem"}>
                    <FormControl fullWidth variant="standard">
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[5]}
                        color={others.otherColors[66]}
                      >
                        Who was the order given by ?
                      </Typography>
                      <Box sx={flexAlignCentre}>
                        <Box sx={SelectBox}>
                          <RxAvatar
                            size={"1.7rem"}
                            color={colors.primary.main}
                          />
                          <FaArrowRight
                            size={"0.8rem"}
                            color={colors.arrowIcon[3]}
                          />
                        </Box>
                        <FormInputDropdown
                          name={"referralDetail.orderedById"}
                          control={control}
                          list={orderingSourceList(orderingSourceResponse)}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item sx={flexAlignCentre} xs={2} ml={"3rem"}>
                    <FormControl fullWidth variant="standard">
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[5]}
                        color={others.otherColors[66]}
                      >
                        Who has accepted the order ?
                      </Typography>
                      <Box sx={flexAlignCentre}>
                        <Box sx={SelectBox}>
                          <FaArrowRight
                            size={"0.8rem"}
                            color={colors.arrowIcon[3]}
                          />
                          <RxAvatar
                            size={"1.7rem"}
                            color={colors.primary.main}
                          />
                        </Box>
                        <FormInputDropdown
                          name={`referralDetail.acceptedById`}
                          control={control}
                          list={orderAcceptorList(orderAcceptorResponse)}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Divider orientation="horizontal" flexItem sx={OrderLine} light />
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[1]}
                >
                  {ReviewRequestDetailsQuestions.CUSTODIAL_CARE}
                </Typography>
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup
                      aria-labelledby="controlled-radio-buttons-group"
                      {...field}
                      sx={OrderRadio}
                    >
                      {RadioButton &&
                        RadioButton.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            value={item.value}
                            control={<Radio />}
                            label={
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[63]}
                                fontWeight={fontWeight.Weight[5]}
                              >
                                {item.label}
                              </Typography>
                            }
                          />
                        ))}
                    </RadioGroup>
                  )}
                  name={`referralDetail.isCustodialCare`}
                  control={control}
                />
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[1]}
                  mt={"1.5rem"}
                >
                  {ReviewRequestDetailsQuestions.NON_COVERED_SERVICES}
                </Typography>
                <Controller
                  defaultValue={""}
                  render={({ field }) => (
                    <>
                      <RadioGroup
                        aria-labelledby="controlled-radio-buttons-group"
                        {...field}
                        sx={OrderRadio}
                        onChange={onChangeNonCovered}
                      >
                        {RadioButton &&
                          RadioButton.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item.value}
                              control={<Radio />}
                              label={
                                <Typography
                                  variant="subtitle1"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                >
                                  {item.label}
                                </Typography>
                              }
                            />
                          ))}
                      </RadioGroup>
                    </>
                  )}
                  name={`referralDetail.isNonCoveredService`}
                  control={control}
                />
                {isNonCoveredServices == "Yes" && (
                  <Box sx={displayFlex}>
                    <Controller
                      defaultValue={""}
                      render={({ field }) => (
                        <Box mb={"1rem"}>
                          <>
                            <FormGroup
                              {...field}
                              onChange={handleNonCoveredService}
                              sx={NonCoveredCheckbox}
                            >
                              {nonCoveredServiceResponse &&
                                length(nonCoveredServiceResponse) &&
                                nonCoveredServiceResponse.map(
                                  (item: NonCoveredServices) => {
                                    return (
                                      <FormControlLabel
                                        key={item.serviceId}
                                        control={
                                          <Checkbox
                                            value={item.serviceId}
                                            size="small"
                                            checked={
                                              nonCoveredServiceList &&
                                              nonCoveredServiceList.includes(
                                                item.serviceId.toString()
                                              )
                                                ? true
                                                : false
                                            }
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="subtitle2"
                                            color={others.otherColors[63]}
                                            fontWeight={fontWeight.Weight[5]}
                                            mr={"0.5rem"}
                                          >
                                            {item.serviceName}
                                          </Typography>
                                        }
                                      />
                                    );
                                  }
                                )}
                            </FormGroup>
                          </>
                        </Box>
                      )}
                      name={`referralDetail.nonCoveredServices`}
                      control={control}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default OrderValidation;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { AuthPhysicianDetails } from "../../../../../models/PhysicianDetails";
import PhysicianDetails from "../components/PhysicianDetails";
import {
  getAuthFollowingPhysicianDetails,
  getAuthOrderingPhysicianDetails,
  putAuthFollowingPhysicianDetails,
  putAuthOrderingPhysicianDetails,
} from "../../../../../actions/AuthorizationDetails";
import {
  AuthFollowingPhysicianDetailsDispatchTypes,
  AuthOrderingPhysicianDetailsDispatchTypes,
} from "../../../../../models/DispatchTypes/PhysicianDetails";
import { UserDetailsPayload } from "../../../../../models/User";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  authFollowingPhysicianDetailsState,
  authOrderingPhysicianDetailsState,
  loggedInUserState,
}: ApplicationState) => ({
  followingPhysicianDetails: authFollowingPhysicianDetailsState.response as AuthPhysicianDetails,
  orderingPhysicianDetails: authOrderingPhysicianDetailsState.response as AuthPhysicianDetails,
  user: loggedInUserState.response as UserDetailsPayload,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<
      | AuthOrderingPhysicianDetailsDispatchTypes
      | AuthFollowingPhysicianDetailsDispatchTypes
    >
  >
) => {
  return {
    getOrderingPhysicianDetails: (referralId: string) => {
      dispatch(getAuthOrderingPhysicianDetails(referralId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getFollowingPhysicianDetails: (referralId: string) => {
      dispatch(getAuthFollowingPhysicianDetails(referralId));
    },

    putOrderingPhysicianDetails: (
      referralId: string,
      payload: AuthPhysicianDetails
    ) => {
      dispatch(putAuthOrderingPhysicianDetails(referralId, payload));
    },

    putFollowingPhysicianDetails: (
      referralId: string,
      payload: AuthPhysicianDetails
    ) => {
      dispatch(putAuthFollowingPhysicianDetails(referralId, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianDetails);

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import { postMyPatientList } from "../../../actions/AllPatientRecord";
import AllPendings from "../components/MyPatientsDetails/AllPendings";
import { SearchFilterModel } from "../../../models/Filter";

const mapStateToProps = ({ myPatientListState, filterState }: ApplicationState) => ({
  MyPatientsRecord: myPatientListState,
  filterState: filterState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    postMyPatientList: (payload?: SearchFilterModel) => {
      dispatch(postMyPatientList(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPendings);

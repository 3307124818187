import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { AppConfigsActionDispatchTypes } from "../constants/AppConfigs";
import { AppConfigsDispatchTypes } from "../models/DispatchTypes/AppConfigs";
import { ApplicationState } from "../store/ApplicationState";
import { GetAppConfigs } from "../services/AppConfigs";

export const getConfigs: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AppConfigsDispatchTypes
  >
> = (configFileName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AppConfigsActionDispatchTypes.APP_CONFIG_INVOKE,
    });
    const response = await GetAppConfigs(configFileName);
    dispatch({
      response: response,
      type: AppConfigsActionDispatchTypes.APP_CONFIG_SUCCESS,
    });
  };
};

import { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { matchSorter } from "match-sorter";

import { ActionType } from "../../../../constants/Staffing";
import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";
import { ModalGridContainer } from "../../../../styles/common/style";
import { displayFlex } from "../../../../styles/mui/styles/display";
import { UserListWithFilterPayload, User } from "../../../../models/User";
import { isNil, length } from "../../../../utils";
import { NO_RECORD_FOUND } from "../../../../constants/ToastMessage";
import { AssignButton, AutoComplete, BackButton, ButtonStyles, DisciplineGridbodyInfo, DisciplineModalGrid, DisplayFlex, ErrorMessage, IconFaInfoAlt, IconFaSearch, ModalGrid, PatientDetailBox, RequiredSymbol, Search, SelectedReferralsBox, TableStyle, Width } from "../styles/style";
import { StyledPopperCheckBoxAutocomplete } from "../../../styles/styles";

export interface PropsFromState {
  userList: User[];
  userName: User | null | undefined;
  availableStaffResponse: User[];
  openModal: boolean;
  action?: string;
  selectedPatients?: any;
  selectedPatientsData?: any;
  displayDisciplineList ?: boolean;
  errorFlag: boolean
  tableRenderer?: () => JSX.Element;
  handleClose: () => void
  handleAssign: () => void
  getStaffList: (payload: UserListWithFilterPayload) => void;
  onSelection: (_e: SyntheticEvent<Element, Event>, value: User | null) => void
}

const AssignStaffModalUI = ({ openModal, action, selectedPatients, displayDisciplineList, userList, userName, selectedPatientsData, availableStaffResponse, errorFlag, tableRenderer, getStaffList, handleClose, handleAssign, onSelection }: PropsFromState) => {

  const [filteredUserList, setFilteredUserList] = useState(userList);
  const [isUserSuspended, setIsUserSuspended] = useState<boolean>(false);

  const defaultProps = (userManagementList: User[]) => ({
    options: userManagementList as User[],
    getOptionLabel: (option: User) => option.fullName,
  });

  useEffect(() => {
    setFilteredUserList(userList);
  }, [userList]);

  useEffect(() => {
    if (openModal && action === ActionType.ASSIGN_STAFF) {
      const payload: UserListWithFilterPayload = {
        isSuspended: isUserSuspended,
        userSearch: ""
      }
      getStaffList(payload);
    } else {
      setIsUserSuspended(true);
    }
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-to-assign-discipline-staff"
      aria-describedby="modal-modal-description"
      sx={ModalGridContainer}
    >
      <Box sx={displayDisciplineList ? DisciplineModalGrid : ModalGrid}>
        <Grid container>
          <Grid container pl={"1rem"}>
            <Grid item xs={12} py={".5rem"} sx={{ display: "flex" }}>
              <Typography
                variant="h3"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
              >
                {action === ActionType.UNASSIGN_STAFF
                  ? ActionType.UNASSIGN_STAFF
                  : ActionType.ASSIGN_STAFF}
              </Typography>
              {displayDisciplineList && <Box sx={DisciplineGridbodyInfo}>
                <IconFaInfoAlt />
                <Typography
                  variant="h6"
                  color={colors.black[2]}
                  fontWeight={fontWeight.Weight[5]}
                >
                  INFO: Please save the service details before assigning staff.
                </Typography>
              </Box>
              }
            </Grid>
            {action === ActionType.ASSIGN_STAFF && (
              <Grid item xs={12} mt={".5rem"}>
                <Box sx={displayFlex}>
                  <Typography
                    variant="body1"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[4]}
                  >
                    Staff:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color={colors.red[100]}
                    fontWeight={fontWeight.Weight[3]}
                    sx={RequiredSymbol}
                  >
                    *
                  </Typography>
                </Box>
                <Grid item >
                  <Autocomplete
                    sx={AutoComplete}
                    {...defaultProps(filteredUserList)}
                    id="auto-complete"
                    autoComplete
                    onChange={(e, v) => {
                      onSelection(e, v);
                    }}
                    includeInputInList
                    value={userName || null}
                    PopperComponent={StyledPopperCheckBoxAutocomplete}
                    key={
                      !isNil(availableStaffResponse) &&
                        availableStaffResponse.length > 0
                        ? availableStaffResponse[0].userId
                        : ""
                    }
                    noOptionsText={NO_RECORD_FOUND}
                    disabled={!length(availableStaffResponse)}
                    filterOptions={(options, { inputValue }) =>
                      matchSorter(options, inputValue, { keys: ["fullName"] })
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.fullName === value.fullName
                    }
                    renderInput={(params) => {
                      params.inputProps.maxLength = 50;
                      return (
                        <Box sx={Search}>
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    <IconFaSearch />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        </Box>
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.userId}>
                          {option.fullName}
                        </li>
                      );
                    }}
                    data-testid="myPatients-autocomplete"
                  />
                  {errorFlag === true ? (
                    <Box sx={ErrorMessage}>
                      <Typography
                        variant="body1"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.red[100]}
                      >
                        Please select the staff
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {!displayDisciplineList && <PatientDetailBox>
              <Box sx={DisplayFlex}>
                <Grid sx={Width}>
                  <Box sx={SelectedReferralsBox}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[20]}
                    >
                      Assign Staff to the selected Referrals -
                      {selectedPatients && selectedPatients.length}
                    </Typography>
                  </Box>
                  <Grid sx={TableStyle}>{tableRenderer && tableRenderer()}</Grid>
                </Grid>
              </Box>
            </PatientDetailBox>}
          </Grid>
          <Grid container sx={ButtonStyles}>
            <Button variant="outlined" sx={BackButton} onClick={handleClose}>
              BACK
            </Button>
            <Button
              variant="contained"
              sx={AssignButton}
              onClick={handleAssign}
              disabled={displayDisciplineList || length(selectedPatientsData) ? false : true}
            >
              {action === ActionType.UNASSIGN_STAFF
                ? ActionType.UNASSIGN
                : ActionType.ASSIGN}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default AssignStaffModalUI;
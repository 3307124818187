import { TextField } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../../../../styles/colors";

export const GridBody = (isAuthDetails:boolean) => ({
  background: colors.backgroundColor[9],
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 2rem 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "2.5rem",
  borderRadius: "0.8rem",
  margin: isAuthDetails ? "0rem 3rem 2rem 1rem":"0rem 4rem 2rem 4rem",
});

export const SendFaxGridContainer = () => ({
  opacity: 1,
  borderRadius: "0.8rem",
  margin: "1rem 0.3rem 1rem 2.7rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0 2.5rem",
  width: "98%",
});

export const SendFaxHeading = () => ({
  padding: "0rem 2rem 1rem 0rem",
  margin: "0rem 2rem 1rem 2rem",
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${colors.borders[2]}`,
});

export const SendFaxFields = () => ({
  paddingTop: "1.4rem",
  paddingLeft: "3rem",
  justifyContent: "space-between",
});

export const TabHorizontalDivider = () => ({
  margin: "1rem 0 2.5rem 2rem",
  width: "96%",
});

export const Header = () => ({
  width: "98%",
  paddingRight: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
});

export const TableWidth = () => ({
  width: "96%",
});

export const BodyTable = () => ({
  "& .MuiTableCell-root": {
    borderBottom: `0.05rem solid ${colors.primary.light}`,
    borderRight: "none",
    borderLeft: "none",
  },
  "& th:first-of-type": {
    borderRadius: "0.5rem 0 0 0.5rem",
  },
  "& th:last-child": {
    borderRadius: "0 0.5rem 0.5rem 0",
  },
});

export const HeaderItem = () => ({
  backgroundColor: colors.backgroundColor[8],
  borderBottom: "unset",
  padding: ".5rem 0 0 0",
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: "none",
  },
  "& th:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: "none",
  },
});

export const DateFormControl = () => ({
  marginTop: "0.8rem",
});

export const ButtonFormControl = () => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "5.8rem",
});

export const ButtonFormControlFax = () => ({
  margin: "0.8rem 0 0.5rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
});

export const AddDocumentSection = (minimizeDocument: boolean) => ({
  paddingLeft: minimizeDocument ? "2rem" : "4.5rem",
  paddingBottom: "1rem",
});

export const ShowIcon = () => ({
  marginRight: "1.5rem",
});

export const ActionSection = () => ({
  svg: {
    cursor: "pointer",
    "&:first-of-type": {
      marginRight: "1.25rem",
    },
  },
});

export const SaveButton = () => ({
  width: "6.5rem",
});

export const DocumentTitle = () => ({
  padding: "0.3rem 0.4rem 0 1rem",
});

export const FileIcon = {
  marginLeft: "2.3rem",
};

export const DropdownGrid = () => ({
  marginTop: "1rem",
});

export const DocumentDetailsTableCell = () => ({
  padding: "1.5rem 0",
});

export const DocumentData = (isDocNo?: boolean) => ({
  paddingTop: "0.4rem",
  paddingLeft: isDocNo ? "0rem" : "1.1rem",
  textAlign: isDocNo ? "center" : "left",
});

export const DocumentTypeIcon = () => ({
  color: colors.fonts[18],
  padding: "0.3rem 0.5rem 0 0.8rem",
});

export const SendFaxDocumentTypeIcon = () => ({
  color: colors.fonts[18],
  padding: "0rem 0.8rem 0 0.8rem",
});

export const CancelButton = () => ({
  background: colors.backgroundColor[9],
});

export const DatePickerInput = () => ({
  "& .MuiInput-input": {
    WebkitBoxShadow: `0 0 0 62.5rem ${colors.backgroundColor[9]} inset`,
  },
});

export const HiddenTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
}));

export const addedDocumentField = () => ({
  minWidth: "12.5rem",
  justifyContent: "flex-end",
  display: "grid",
  flex: 1,
  alignItems: "center",
});

export const RefreshIconStyle = {
  color: colors.black[14],
  marginLeft: "0.4rem",
  cursor: "pointer",
};

export const DocumentName = {
  overflow: "hidden",
  display: "block",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "10rem",
  textDecoration: "unset",
};

export const DocumentNameUnderline = {
  textDecoration: "none",
};

export const DisabledDocumentLink = {
  cursor: "default",
  color: colors.grey[1],
  textDecoration: "none",
  pointerEvents: "none",
};

export const DisabledDownloadIcon = {
  cursor: "default",
  color: colors.grey[1],
};
export const HeaderTableCell = (isDocNo?: boolean) => ({
  display: "flex",
  justifyContent: isDocNo ? "center" : "left",
});

export const SendFaxGrid = () => ({
  padding: "0 2rem 0 1.5rem",
});

export const TitleBox = () => ({
  paddingTop: "0.3rem",
  paddingLeft: "1rem",
});

export const DeleteIconStyle = () => ({
  padding: "0rem 0.8rem 0 0.8rem",
  fontSize: "0.1rem",
});

export const GridStyle = () => ({
  display: "flex",
  alignItems: "center"
});

export const SendFaxDivider = () => ({
  marginLeft: "1.5rem",
  height: "1.5rem",
});
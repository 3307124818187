import { Reducer } from "redux";

import { surveyJSON } from "../services/NotesTempData"; //TBD
import { UpdateSurveyDetailsResponse } from "../models/Api/Notes";
import { PostSurveyRequestDispatchTypes } from "../models/DispatchTypes/Notes";
import { SurveyDetailsUpdateActionDispatchType } from "../constants/Notes";

export const initialPutState: UpdateSurveyDetailsResponse = {
  loading: false,
  response: { data: surveyJSON, referralId: 0 },
  error: null,
};

const Survey: Reducer<
  UpdateSurveyDetailsResponse,
  PostSurveyRequestDispatchTypes
> = (state = initialPutState, action) => {
  switch (action.type) {
    case SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    case SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case SurveyDetailsUpdateActionDispatchType.SURVEY_REQUEST_POST_RESET: {
      return initialPutState;
    }
  }
  return state;
};

export default Survey;

import {
  AuthorizationDetailsLink,
  CreatePatient,
  PATIENT_RECORD,
  PatientDetailsForm,
  ReAuthCompleteAuthorizationView,
  ReauthReferralIntakeLink,
  ReferralIntakeLink,
  UserManagementLink,
  UserManagementDetailsLink,
  RoleManagementRoleDetailsLink,
  NoteManagementTemplateLink,
  NoteManagementLink,
  NoteManagementCreateTemplateLink,
  RoleManagementLink,
  RoleManagementCreateRoleLink,
} from "./routePath";

export enum PatientRecordsModule {
  ALL_PATIENTS = 0,
  PATIENTS_DETAILS,
  REFERRAL_INTAKE,
  REFERRAL_INTAKE_MODULE,
  PATIENT_DETAILS_FORM,
  PATIENT_INFORMATION_VIEW,
  REAUTH_PATIENT_INFORMATION_VIEW,
  AUTHORIZATION_VIEW,
  REFERRAL_INTAKE_BY_REFERRAL,
  PATIENT_RECORD,
  REAUTH_INTAKE,
  REAUTH_RESUME,
  REAUTH_COMPLETE_AUTHORIZATION_DETAILS,
  REAUTH_COMPLETE,
  REAUTH_INCOMPLETE,
  USER_MANAGEMENT,
  USER_MANAGEMENT_DETAILS,
  ROLE_MANAGEMENT_ROLE_DETAILS,
  NOTE_MANAGEMENT_EDIT_TEMPLATE,
  NOTE_MANAGEMENT,
  NOTE_MANAGEMENT_CREATE_TEMPLATE,
  ROLE_MANAGEMENT,
  ROLE_MANAGEMENT_CREATE_ROLE,
}

export const ModuleLinks = (PageId: number, params?: any) => {
  switch (PageId) {
    case PatientRecordsModule.REFERRAL_INTAKE:
      return ReferralIntakeLink(params.referralId, params.patientId, params.additionalParams);
    case PatientRecordsModule.PATIENT_DETAILS_FORM:
      return PatientDetailsForm(
        params.patientId ? params.patientId.toString() : ""
      );
    case PatientRecordsModule.PATIENT_INFORMATION_VIEW:
      return CreatePatient(
        params.lastName,
        params.dob,
        params.healthPlan,
        params.policyId,
        params.isManual,
      );
    case PatientRecordsModule.REFERRAL_INTAKE_MODULE:
      return ReferralIntakeLink(params.referralId, params.patientId, params.additionalParams);
    case PatientRecordsModule.REAUTH_COMPLETE_AUTHORIZATION_DETAILS:
      return ReAuthCompleteAuthorizationView(
        params.referralId ? params.referralId.toString() : "",
        `?reAuthType=${1}`
      );
    case PatientRecordsModule.AUTHORIZATION_VIEW:
      return AuthorizationDetailsLink(params.Id ? params.Id.toString() : "");
    case PatientRecordsModule.REFERRAL_INTAKE_BY_REFERRAL:
      return ReferralIntakeLink(params.referralId, params.patientId, params.additionalParams);
    case PatientRecordsModule.PATIENT_RECORD:
      return PATIENT_RECORD;
    case PatientRecordsModule.REAUTH_INTAKE:
      return ReauthReferralIntakeLink(params.reAuthId, params.referralId);
    case PatientRecordsModule.REAUTH_RESUME:
      return ReauthReferralIntakeLink(params.reAuthId, params.referralId);
    case PatientRecordsModule.USER_MANAGEMENT_DETAILS:
      return UserManagementDetailsLink(`${params.userId}`);
    case PatientRecordsModule.USER_MANAGEMENT:
      return UserManagementLink();
    case PatientRecordsModule.NOTE_MANAGEMENT_CREATE_TEMPLATE:
      return NoteManagementTemplateLink();
    case PatientRecordsModule.NOTE_MANAGEMENT:
      return NoteManagementLink();
    case PatientRecordsModule.NOTE_MANAGEMENT_EDIT_TEMPLATE:
      return NoteManagementCreateTemplateLink(`${params.templateVersionId}`);
    case PatientRecordsModule.ROLE_MANAGEMENT:
      return RoleManagementLink();
    case PatientRecordsModule.ROLE_MANAGEMENT_CREATE_ROLE:
      return RoleManagementCreateRoleLink();
    case PatientRecordsModule.ROLE_MANAGEMENT_ROLE_DETAILS:
      return RoleManagementRoleDetailsLink(`${params.roleId}`);
  }
  return "";
};

export enum PatientTabModule {
  REQUEST = 0,
  ICD10 = 1,
  ORDERING_PHYSICIAN = 2,
  SERVICE_PROVIDER = 3,
  SERVICE_REQUEST = 4,
  DOCUMENTS = 5,
  REVIEW = 6,
}

export enum ReauthTabModule {
  REQUEST = 0,
  ICD10 = 1,
  FOLLOWING_PHYSICIAN = 2,
  SERVICE_REQUEST = 3,
  DOCUMENTS = 4,
  REVIEW = 5,
}

import { Table, TableCell, TableHead, TableRow, styled } from "@mui/material";

import { colors, others } from "../../../styles/colors";
import fontWeight from "../../../styles/mui/fontWeight";

export const ModalGrid = () => ({
  position: "absolute",
  top: "20%",
  left: "5%",
  width: "90%",
  height: "auto",
  bgcolor: "background.paper",
  padding: "1rem",
  borderRadius: "1.5rem",
  paddingTop: "1rem",
  marginLeft: "1rem",
});

export const AutocompleteUI = () => ({
  width: "12rem",
  position: "relative",
});

export const TableStyle = () => ({
  width: "100%",
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: colors.backgroundColor[2],
  borderRadius: "0.5rem",
  padding: "1rem",
});

export const TableStyleModal = {
  width: "100%",
  "& .MuiTableCell-root": {
    borderRight: "none",
    borderLeft: "none",
    paddingLeft: "0.4rem",
    paddingRight: "0.4rem",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderRight: "1rem",
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
  },
};

export const TableRowStyle = {
  height: "2rem",
};

export const TableCellStyle = (length: number, index: number) => ({
  borderRight: 0,
  borderBottom:
    length - 1 === index ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const TableCellRendererStyle = (removeBorder: boolean) => ({
  borderRight: 0,
  padding: 0,
  textAlign: "left",
  paddingRight: "1%",
  paddingLeft: "0rem",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const RemoveTableCellStyle = (length: number, index: number) => ({
  borderBottom:
    length - 1 === index ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const RemoveTableCellRendererStyle = {
  border: 0,
  padding: "1.25rem 0",
};

export const DisplayFlex = () => ({
  display: "flex",
  padding: "1rem",
});

export const ButtonStyles = () => ({
  justifyContent: "flex-end",
  padding: "1rem ",
});

export const Width = () => ({
  width: "100%",
});

export const Padding = () => ({
  padding: "1rem",
});

export const TableContainerStyle = () => ({
  maxHeight: "30vh",
  minHeight: "10rem",
  "&::-webkit-scrollbar": {
    width: ".25rem",
    height: "0.3rem",
    paddingLeft: "2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
});

export const ContainerStyle = {
  "&::-webkit-scrollbar": {
    width: "2rem",
    height: "0.2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
    marginLeft: "10rem",
    marginRight: "10rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
};

export const JustifyContent = () => ({
  justifyContent: "flex-end",
  padding: "1rem ",
});

export const TableContainersStyle = () => ({
  maxHeight: "21rem",
  overflow: "auto",
});

export const PatientTableHeadCell = () => ({
  backgroundColor: colors.backgroundColor[8],
  borderBottom: "unset",
  "&.MuiTableCell-root:last-child": {
    borderBottom: "none",
  },
});

export const SelectedReferralsBox = () => ({
  padding: "1rem 0 1rem 2rem",
});

export const TableCellHeaderLeft = styled(TableCell)(() => ({
  left: 0,
  backgroundColor: colors.backgroundColor[8],
  border: 0,
}));

export const TableNameDetails = (length: number, index: number) => ({
  textDecoration: "underline",
  textDecorationColor: colors.fonts[3],
  textAlign: "left",
  borderBottom:
    length - 1 === index ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const CheckboxFormControl = {
  "&.MuiFormControlLabel-root": {
    marginRight: 0,
  },
};

export const BackButton = () => ({
  width: "8rem",
});

export const SortBox = () => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5rem",
  svg: {
    "&:first-of-type": {
      marginBottom: "-0.8rem",
    },
  },
});

export const AlignCenter = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderRight: "none",
    borderLeft: "none",
    paddingLeft: "0.88rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.4875rem 0 0 0.4875rem",
    borderRight: "1rem",
  },
  "& th:last-of-type": {
    borderRadius: "0 0.4875rem 0.4875rem 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: others.SchedulerColors[1],
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
}));

export const TableMainHeadRow = styled(TableRow)(() => ({
  height: "3rem",
}));

export const TableCellStickyLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 40,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableCellStickyHeaderLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 50,
  backgroundColor: others.SchedulerColors[1],
}));

export const TableCellStickyHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  width: "3rem",
  left: 0,
  backgroundColor: others.SchedulerColors[1],
}));

export const checkBoxStyle = {
  "& span": {
    padding: "0rem ",
  },
};

export const AssignButton = () => ({
  width: "10.2rem",
});

export const RequiredSymbol = () => ({
  paddingTop: "0.1rem",
  paddingLeft: "0.4rem",
});

export const ErrorMessage = () => ({
  paddingTop: "0.5rem",
  paddingLeft: "1rem",
});


import { Reducer } from "redux";

import { LetterCopyTypeMasterResponse } from "../models/Api/Master";
import { LetterCopyTypeMasterDispatchTypes } from "../models/DispatchTypes/Master";
import { LetterCopyTypeMasterActionDispatchTypes } from "../constants/Master";

const initialState: LetterCopyTypeMasterResponse = {
  loading: false,
  response: [],
};

const LetterCopyTypeMasterReducer: Reducer<
  LetterCopyTypeMasterResponse,
  LetterCopyTypeMasterDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case LetterCopyTypeMasterActionDispatchTypes.GET_LETTER_COPY_TYPE_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LetterCopyTypeMasterActionDispatchTypes.GET_LETTER_COPY_TYPE_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default LetterCopyTypeMasterReducer;

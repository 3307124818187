import { Reducer } from "redux";

import { AgencyCommunicationResponse } from "../models/Api/Master";
import { AgencyCommunicationMasterActionDispatchTypes } from "../constants/Master";
import { AgencyCommunicationDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: AgencyCommunicationResponse = {
  loading: false,
  response: [
    {
      templateId: -1,
      templateName: "",
      templateDescription: ""
    },
  ],
};

const AgencyCommunicationReducer: Reducer<
AgencyCommunicationResponse,
AgencyCommunicationDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AgencyCommunicationMasterActionDispatchTypes.GET_AGENCY_COMMUNICATION_TEMPLATE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AgencyCommunicationMasterActionDispatchTypes.GET_AGENCY_COMMUNICATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AgencyCommunicationReducer;

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { styled } from "@mui/system";

import { colors, others } from "../../../../../styles/colors";

export const Gridbody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 2rem 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const BottomGrid = () => ({
  background: colors.backgroundColor[7],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  paddingTop: "0.5rem",
  marginRight: "0.85rem",
  borderRadius: "1.2rem",
});
export const VerticalDivider = () => ({
  margin: "0 1.2rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0",
  width: "99%",
});

export const SummaryBox = () => ({
  width: "100%",
  padding: "0.8rem ",
  paddingBottom: "1.2rem",
  display: "flex",
  justifyContent: "space-between",
});

export const Header = () => ({
  width: "100%",
  paddingRight: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
});

export const AutoComplete = {
  width: "32rem",
  borderBottom: others.otherColors[35],
  borderBottomWidth: "0.06rem",
};

export const Search = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const AutocompleteTableHolder = () => ({
  width: "inherit",
  left: "-2rem",
  top: "1rem",
  backgroundColor: `${colors.white[1]} !important`, //TBD
  borderRadius: "0.19rem",
});

export const TableHeadMargin = (isHeader: boolean) => ({
  marginTop: isHeader ? "1rem" : "auto",
});

export const SearchedResultTableHead = () => ({
  padding: ".75rem 0 .75rem 1.2rem",
  backgroundColor: others.MainBackground[1],
  borderBottom: 0,
  "&.MuiTableCell-root:first-of-type": {
    borderTopLeftRadius: "0.19rem",
    borderBottomLeftRadius: "0.19rem",
    paddingLeft: "1.5rem",
  },
  "&.MuiTableCell-root:last-child": {
    borderTopRightRadius: "0.19rem",
    borderBottomRightRadius: "0.19rem",
  },
});

export const SearchedResultTableBody = () => ({
  padding: ".5rem 0 .4rem 1.2rem",
  textTransform: "capitalize",
  border: 0,
  borderBottom: `0.05rem solid ${colors.borders[1]}`,
  "&.MuiTableCell-root:first-of-type": {
    paddingLeft: "1.5rem",
  },
});

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const ShowIcon = () => ({
  marginRight: "1rem",
});

export const HeaderTitle = () => ({
  paddingTop: "0.3rem",
  paddingLeft: "1rem",
});

export const GridContent = () => ({
  paddingRight: "1.5rem",
  paddingLeft: "3rem",
});

export const Item = () => ({
  padding: "1rem",
  paddingBottom: "1.2rem",
});

export const ItemValue = () => ({
  paddingTop: "0.3rem",
});

export const ItemIconBox = () => ({
  color: colors.primary.main,
  paddingRight: "0.5rem",
  paddingTop: "0.1rem",
});

import { Reducer } from "redux";

import { AttachLetterResponse } from "../models/Api/Document";
import { AttachLetterActionDispatchTypes } from "../models/DispatchTypes/Document";
import { AttachLetterDispatchTypes } from "../constants/Document";

const initialState: AttachLetterResponse = {
  loading: false,
  response: [],
  error: null,
};

const SaveAttachLetter: Reducer<
  AttachLetterResponse,
  AttachLetterActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AttachLetterDispatchTypes.ATTACH_LETTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AttachLetterDispatchTypes.ATTACH_LETTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case AttachLetterDispatchTypes.ATTACH_LETTER_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case AttachLetterDispatchTypes.ATTACH_LETTER_RESET: {
      return initialState;
    }
  }
  return state;
};

export default SaveAttachLetter;

import { Grid, Box, Link, Divider, MenuList } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";

import { Module } from "../../../models/Modules";
import { UserDetailsPayload } from "../../../models/User";
import { UserModuleResponse } from "../../../models/Api/Modules";
import {
  DropdownContent,
  DropdownMenu,
  HamburgerMenuIcon,
  MenuBox,
  MenuBoxMax,
  MenusBoxHamburger,
  MenuTab,
  MenuToolbar,
  StickyHeader,
} from "../../styles/styles";
import { flexAlignCentre } from "../../../styles/mui/styles/display";
import { height100PCT } from "../../../styles/mui/styles/heights";
import { getValue, isNil, length } from "../../../utils";
import { width100PCT } from "../../../styles/mui/styles/widths";
import colors from "../../../styles/colors/colors";
import fontWeight from "../../../styles/mui/fontWeight";
import { prepareMenuItems } from "../../../utils/authentication/authorization";

export interface PropsFromState {
  item: UserModuleResponse;
  user: UserDetailsPayload;
}

export interface PropsFromDispatch {
  getUserModules: (userId: number) => void;
  getUserPermission: (userId: number) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const Menus: React.FC<AllProps> = ({
  item,
  getUserModules,
  user,
  getUserPermission,
}) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef<any | null>(null);
  const userModule = item.response;
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const location = useLocation();
  useEffect(() => {
    if (user && user.userId && user.userId !== -1) {
      getUserModules(user.userId);
      getUserPermission(user.userId);
    }
  }, [user]);

  const handleOpen = (link: string, label?: number) => {
    if (label !== undefined) {
      navigate(`${link}`); //TBD 02-05-2023
    }
  };

  const pathname =
    location.pathname == "/"
      ? userModule && userModule[0].routeUrl
      : location.pathname;

  const checkActiveRoute = (link: string) => {
    const words = !isNil(link) ? link.split("/") : ["", "admincenter"];
    return !isNil(pathname) && pathname.includes(words[1]);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = (elTopOffset: any, elHeight: any) => {
    if (window.pageYOffset > elTopOffset) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  useEffect(() => {
    const header =
      headerRef &&
      headerRef.current &&
      headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const menuItems = prepareMenuItems(userModule);

  return (
    <>
      <Grid container sx={sticky.isSticky ? { height: sticky.offset } : {}}>
        <Grid item xs={12}>
          <MenuToolbar
            disableGutters
            sx={sticky.isSticky ? StickyHeader : ""}
            ref={headerRef}
          >
            <MenuBoxMax>
              <MenusBoxHamburger>
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  aria-label="account of current user"
                  color="inherit"
                  onClick={handleOpenNavMenu}
                  size="large"
                >
                  <HamburgerMenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  {userModule?.map((item: Module) => (
                    <Box key={item.moduleId}>
                      <MenuList
                        key={item.moduleId}
                        onClick={() =>
                          handleOpen(item.routeUrl, item.moduleOrder)
                        }
                      >
                        <Typography
                          variant="h6"
                          textAlign="center"
                          fontWeight={
                            checkActiveRoute(item.routeUrl)
                              ? fontWeight.Weight[4]
                              : fontWeight.Weight[2]
                          }
                          color={colors.fonts[20]}
                        >
                          {item.moduleName}
                        </Typography>
                        {item &&
                          item.subModules &&
                          length(item.subModules) &&
                          item.subModules.map((subModule, index) => {
                            return (
                              <MenuItem key={index} sx={{ ml: "1rem" }}>
                                <Link
                                  variant="h6"
                                  fontWeight={fontWeight.Weight[2]}
                                  onClick={() =>
                                    navigate(`${subModule.fullRoutePath}`)
                                  }
                                >
                                  {subModule.subModuleName}
                                </Link>
                                <Divider
                                  orientation="horizontal"
                                  variant="fullWidth"
                                  flexItem
                                  sx={{ margin: "0 0.5rem" }}
                                />
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    </Box>
                  ))}
                </Menu>
              </MenusBoxHamburger>

              <MenuBox sx={{ ...height100PCT, ...width100PCT }}>
                <>
                  {menuItems?.map((item: Module) => (
                    <Box key={item.moduleId} sx={DropdownMenu}>
                      {
                        <MenuTab
                          key={item.moduleId}
                          to={item.routeUrl}
                          color={
                            checkActiveRoute(item.routeUrl)
                              ? "primary"
                              : "default"
                          }
                        >
                          <Typography
                            variant="h5"
                            component={"span"}
                            fontWeight={
                              checkActiveRoute(item.routeUrl)
                                ? fontWeight.Weight[4]
                                : fontWeight.Weight[2]
                            }
                            sx={flexAlignCentre}
                          >
                            {item.moduleName}
                            {item.subModules.filter((item) => item.isMenu)
                              .length !== 0 && (
                              <MdKeyboardArrowDown
                                color={colors.fonts[18]}
                                size={18}
                              />
                            )}
                          </Typography>
                        </MenuTab>
                      }
                      <Box sx={DropdownContent}>
                        {getValue(item, "item.subModules", []) &&
                          length(item.subModules) &&
                          item.subModules
                            .filter((subItem) => subItem.isMenu)
                            .map((subItem, index) => {
                              return (
                                <Box key={index}>
                                  <Link
                                    variant="h6"
                                    fontWeight={fontWeight.Weight[4]}
                                    onClick={() =>
                                      navigate(`${subItem.fullRoutePath}`)
                                    }
                                  >
                                    {subItem.subModuleName}
                                  </Link>
                                  <Divider
                                    orientation="horizontal"
                                    variant="fullWidth"
                                    flexItem
                                    sx={{ margin: "0 0.5rem" }}
                                  />
                                </Box>
                              );
                            })}
                      </Box>
                    </Box>
                  ))}
                </>
              </MenuBox>
            </MenuBoxMax>
          </MenuToolbar>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default Menus;

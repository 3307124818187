const brand = {
  primary: "#0071BC",
  secondary: "#8CC63E",
};

const primary = {
  main: "#1D4289",
  light: "#E2F4FF",
};

const hover = {
  main: "#245ECC",
};

const black = {
  1: "#1C1C1C",
  2: "#23242A",
  3: "#303030",
  4: "#3D3D3D",
  5: "#4E5264",
  6: "#656B77",
  7: "#D3DBEB",
  8: "#F1F1F1",
  9: "#1E1E1E",
  10: "#A9AFBB",
  11: "#000",
  12: "#1A2027",
  13: "#000000",
  14: "#444444",
  15: "#020203",
  16: "#26292D",
};

const red = {
  100: "#FF0000",
  90: "#FF1A1A",
  101: "#FF6173",
  80: "#d32f2f",
  102: "#F33A3A",
};

const dropShadow = {
  1: "#E0E0E0",
  2: "#F0F0F0",
  3: "#C9D0D8",
  4: "#00000029",
  5: "#00000014",
  6: "#00000012",
  7: "#8F9098",
  8: "#606060",
  9: "#EEEEEE",
};

const borders = {
  1: "#E1E8EE",
  2: "#DFDFDF",
  3: "#B9DB8E",
  4: "#EBEBEB",
  5: "#F5F5F5",
  6: "#00000021",
  7: "#E8E8E8",
  8: "#0BA800",
};

const fonts = {
  1: "#0D0E10",
  2: "#636A91",
  3: "#006CB5",
  4: "#3E3E3E",
  5: "#7E7E7E",
  6: "#414852",
  7: "#A8A8A8",
  8: "#1C6BF5",
  9: "#141F52",
  10: "#8F9098",
  11: "#707070",
  12: "#C5C5C5",
  13: "#707384",
  14: "#676E7D",
  15: "#FFA77A",
  16: "#19384D",
  17: "#02243B",
  18: "#0D3693",
  19: "#17191C",
  20: "#202020",
  21: "#1567E3",
  22: "#0BA800",
  23: "#7B7B7B",
  24: "#4a4a4a",
  25: "#4E5264",
  26: "#054068",
  27: "#8E8E8E",
  28: "#eb792d",
  29: "#8A8A8A",
};

const backgrounds = {
  1: "#F3F7F9",
  2: "#F2FAFE",
  3: "#F4F6FA",
  4: "#A6A8B4",
  5: "#5382EF",
  6: "#e2f4ff8d",
};

const white = {
  1: "#FFF",
};

const backgroundColor = {
  1: "#F5F6F8",
  2: "#FFFFFF",
  3: "#EFF9FF",
  4: "#308fe8",
  5: "#F9FDFF",
  6: "#F9FAFB",
  7: "#F3FBFF",
  8: "#F2F4F9",
  9: "#F3FBFF",
  10: "#F3F3F3",
  11: "#999DB4",
  12: "#F1F9FD",
  13: "#fafdff",
  14: "#FEFCFF",
};
const arrowIcon = {
  1: "#054875",
  2: "#F8A268",
  3: "#676767",
  4: "#988F8F",
  5: "#C9C9C9",
};

const progressBar = {
  1: "#DBE9F2",
  2: "#9CD5EF",
  3: "#F5BC6E",
  4: "#e6e8ed",
};

const circleBorder = {
  border: "#989898",
  oliveDrab: "#6b8e23",
};

const statusComplete = "#3AAF3A";

const riIcon = "#F8A268";

const grey = {
  1: "#D6D6D6",
};

export default {
  brand,
  primary,
  hover,
  black,
  dropShadow,
  borders,
  fonts,
  backgrounds,
  white,
  red,
  arrowIcon,
  backgroundColor,
  progressBar,
  circleBorder,
  riIcon,
  statusComplete,
  grey,
};

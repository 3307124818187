import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../../store/ApplicationState";
import { AllPatientTabDispatchTypes } from "../../../../../../models/DispatchTypes/AllPatientRecord";
import PatientHeader from "../components";
import {
  GetAccomodationMasterResp,
  GetLanguageMasterResp,
} from "../../../../../../actions/Master";
import { updatePatientHeader } from "../../../../../../actions/PatientInformation";
import { PatientInformationView } from "../../../../../../models/Patient";
import { getRelationshipMasterResp } from "../../../../../../actions/Patient";
import {
  getPhysicianRecords,
  resetPhysicianRecords,
} from "../../../../../../actions/AllPatientRecord";

const mapStateToProps = ({
  physicianRecordsState,
  languageState,
  accomodationState,
  relationshipState,
  NotifyInternalDataValuesState,
  updatePatientHeaderState,
}: ApplicationState) => ({
  physicianRecords: physicianRecordsState,
  LanguageMasterData: languageState,
  AccomodationMasterData: accomodationState,
  relationshipData: relationshipState,
  NotifyInternalDataValuesResp: NotifyInternalDataValuesState,
  updatePatientHeaderData: updatePatientHeaderState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    getLanguageMasterResp: () => {
      dispatch(GetLanguageMasterResp());
    },
    getAccomodationMasterResp: () => {
      dispatch(GetAccomodationMasterResp());
    },
    updatePatientHeader: (data: PatientInformationView) => {
      dispatch(updatePatientHeader(data));
    },
    getRelationshipMasterResp: () => {
      dispatch(getRelationshipMasterResp());
    },
    getPhysicianRecords: (value: string) => {
      dispatch(getPhysicianRecords(value));
    },
    resetPhysicianRecords: () => {
      dispatch(resetPhysicianRecords());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientHeader);

import { UseFormTrigger } from "react-hook-form";
import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import { AddressFormInput } from "../../../../../components/formComponents/AddressFormInput";
import { characters } from "../../../../../constants/Patterns";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { GENDER_DROPDOWN_OPTIONS } from "../../../../../constants/Constants"; 
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AddressDetailsContainerBody,
  AddressHeader,
  horizontalDivider,
  IoIosPinIcon,
  ParentGridBody,
  PatientDetailsContainerBody,
  PatientInputHolder,
  SummaryContainerHead,
  SummaryHeadPatientName,
  SummaryHeadTexts,
  SummaryItemContainer,
} from "../styles/styles";
import { colors } from "../../../../../styles/colors";
import { PatientDetailsInfo } from "../../../../../constants/Patient";
import { rules } from "../../../../../utils/validation/Validation";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";

export interface AddressInfo {
  control: any;
  setValue: (id: any, value: any, boolean: any) => void;
  values: any;
  trigger: UseFormTrigger<any>;
  isManual: boolean;
}

type AllProps = AddressInfo;

const PatientDetailsForm: React.FC<AllProps> = ({
  control,
  setValue,
  values,
  trigger,
  isManual
}: AllProps) => {
  const patientDetails = () => {
    const maxDate = dayjs()

    return (
      <Grid sx={ParentGridBody}>
        <Grid container sx={SummaryContainerHead}>
          <Grid item xs={12}>
            <Grid container sx={SummaryHeadTexts}>
              <Grid item xs={12}>
                <Box sx={SummaryHeadPatientName}>
                  <Typography
                    variant="h6"
                    color={colors.fonts[20]}
                    fontWeight={fontWeight.Weight[6]}
                    align="left"
                  >
                    Patient Details
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    color={colors.fonts[5]}
                    fontWeight={fontWeight.Weight[3]}
                    align="left"
                  >
                    {PatientDetailsInfo}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Permission
          controlId={`|${PatientRecordsAccess.PATIENT_DETAILS_FORM_READ}|${PatientRecordsAccess.PATIENT_DETAILS_FORM_UPDATE}`}
          passThrough
        >
          {(readOnly: boolean) => (
            <>
              <Grid container sx={PatientDetailsContainerBody}>
                <Grid item xs={2.3}>
                  <Grid container sx={PatientInputHolder("15rem")}>
                    <FormInputText
                      name="createPatient.lastName"
                      control={control}
                      label="Last Name:"
                      helper={rules.lastName}
                      pattern={characters}
                      textLength={64}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2.3}>
                  <Grid container sx={PatientInputHolder("15rem")}>
                    <FormInputText
                      name="createPatient.middleName"
                      control={control}
                      label="Middle Name:"
                      helper={rules.middleName}
                      textLength={64}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2.3}>
                  <Grid container sx={PatientInputHolder("15rem")}>
                    <FormInputText
                      name="createPatient.firstName"
                      fieldrequired="required"
                      control={control}
                      label="First Name:"
                      helper={rules.firstName}
                      textLength={64}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1.5}>
                  <Grid container sx={PatientInputHolder("8rem")}>
                    <FormInputDropdown
                      name="createPatient.gender"
                      fieldrequired="required"
                      control={control}
                      label="Gender:"
                      helper={rules.gender}
                      list={GENDER_DROPDOWN_OPTIONS}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container sx={PatientInputHolder("10rem")}>
                    <FormControl variant="standard">
                      <DatePicker
                        name="createPatient.dateOfBirth"
                        control={control}
                        label={"Date Of Birth:"}
                        helper={rules.dateOfBirth}
                        readOnly={readOnly}
                        maximumdate={maxDate}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Divider sx={horizontalDivider} />
                </Grid>
              </Grid>
              <Grid container sx={AddressDetailsContainerBody}>
                <Grid item xs={12}>
                  <Grid container pl={"1rem"}>
                    <Grid item xs={0.2} pt={"0.5rem"}>
                      <IoIosPinIcon />
                    </Grid>
                    <Grid item xs={2.2}>
                      <Box sx={AddressHeader}>
                        <Typography
                          variant="h5"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.fonts[4]}
                          align="left"
                        >
                          ADDRESS DETAILS:
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <AddressFormInput
                    control={control}
                    setValue={setValue}
                    values={values}
                    trigger={trigger}
                    isRequired={isManual}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Permission>
      </Grid>
    );
  };
  return (
    <>
      <Grid item xs={12} sx={SummaryItemContainer}>
        {patientDetails()}
      </Grid>
    </>
  );
};

export default PatientDetailsForm;

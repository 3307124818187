import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Button,
} from "@mui/material";
import { alpha } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";
import { isNil } from "lodash";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Head, Body, Main } from "../../../../../components/custom/structure";
import {
  roleDetailsStyles,
  HeaderItem,
  IconButtonBox,
  FaChevronCircleStyle,
  TitleBox,
  DividerItem,
  createRoleFooter,
  alignItemBaseline,
  description,
  NameBox,
  ButtonBox,
  BodyGridContainer,
  HeaderOuterBox,
  ErrorMessageBox,
} from "../styles/roledetails";
import { Checkbox } from "../../../../../components/custom/checkbox";
import {
  CreateRoleBodyProps,
  RoleDetailsAccess,
  RoleDetailsAuth,
  RoleDetailsStateType,
  RoleDetailsSubModule,
} from "../../../../../models/Roles";
import { MasterAccessResponse } from "../../../../../models/Api/Master";
import {
  MasterAccess,
  MasterSubModule,
  MasterSubModuleAccess,
} from "../../../../../models/Master";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { bodytable } from "../styles/rolemanagement";
import { rules } from "../../../../../utils/validation/Validation";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import {
  RoleDetailsApiFailed,
  roleDetailsInitialState,
} from "../../../../../constants/Roles";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";

export interface PropsDispatch {
  getMasterAccess: () => void;
  postRole: (payload: RoleDetailsAuth) => void;
  roleDetailsReset: () => void;
}

export interface PropsState {
  masterAccessState: MasterAccessResponse;
  roleDetailsState: any;
}
type RoleDetailsProps = PropsState & PropsDispatch;

const CreateRole: React.FC<RoleDetailsProps> = ({
  masterAccessState,
  getMasterAccess,
  postRole,
  roleDetailsState,
  roleDetailsReset,
}: RoleDetailsProps) => {
  const [masterAccess, setMasterAccess] =
    useState<MasterAccessResponse>(masterAccessState);

  const [role, setRole] = useState<RoleDetailsAuth>({
    roleId: 0,
    roleName: "",
    roleDescription: "",
    roleModule: [],
    loggedInUser: "",
  });
  const [validation, setValidation] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiFailedMessage, setApiFailedMessage] = useState<string>("");

  const { handleSubmit, control } = useForm<RoleDetailsStateType>({
    defaultValues: roleDetailsInitialState,
  });

  const onSubmit = (data: RoleDetailsStateType) => {
    const { roleName, description } = data;
    const payload = role;
    if (roleName && description) {
      payload.loggedInUser = payload.roleName.toString();
      payload.roleName = roleName;
      payload.roleDescription = description;
      if (accessValidation(role)) {
        setValidation(false);
        postRole(payload);
      } else setValidation(true);
    }
  };

  const onSubmitNavigate = (data: RoleDetailsStateType) => {
    const { roleName, description } = data;
    const payload = role;
    if (roleName && description) {
      payload.loggedInUser = payload.roleName.toString();
      payload.roleName = roleName;
      payload.roleDescription = description;
      if (accessValidation(role)) {
        setValidation(false);
        postRole(payload);
      } else setValidation(true);
    }
  };

  useEffect(() => {
    if (
      !isNil(masterAccessState) &&
      Array.isArray(masterAccessState.response) &&
      masterAccessState.response[0].moduleId === -1
    ) {
      getMasterAccess();
    }
    setMasterAccess(masterAccessState);
  }, [masterAccessState.response]);

  useEffect(() => {
    const { error, response } = roleDetailsState;

    if (!isNil(error)) {
      if (error.status === 400 || error.status === 422) {
        setApiError(true);
        if (error.data === RoleDetailsApiFailed.DUPLICATE_ROLE) {
          setApiFailedMessage(RoleDetailsApiFailed.DUPLICATE_ROLE);
        } else {
          setApiFailedMessage(RoleDetailsApiFailed.ADD_ROLE_FAILED);
        }
      }
    } else {
      setApiError(false);
      if (saveAndExit && !isNil(response)) {
        roleDetailsReset();
        navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
        setSaveAndExit(false);
      }
    }
  }, [roleDetailsState]);

  const navigate = useNavigate();

  const handleBack = () => {
    roleDetailsReset();
    navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    setSaveAndExit(false);
  };

  const handleSaveAndExit = () => {
    handleSubmit(onSubmitNavigate)();
    setSaveAndExit(true);
  };

  const handleCancel = () => {
    navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT));
  };

  const accessValidation = (roleDetails: RoleDetailsAuth) => {
    let subModules = [];
    subModules = roleDetails.roleModule.filter(
      (module) => module.roleSubModule.length > 0
    );

    return subModules.length > 0;
  };

  const checkInitialData = (masterAccess: MasterAccessResponse) => {
    return (
      !isNil(masterAccess) &&
      Array.isArray(masterAccess.response) &&
      masterAccess.response[0].moduleId !== -1
    );
  };

  return (
    <>
      <Main>
        <Head
          title={"ADD ROLES"}
          description={
            "View and take actions on role based settings, create/edit/assign rights to roles here."
          }
          back
          dataTestId={"createRole-back"}
          handleBack={handleBack}
        >
          <Button
            onClick={handleCancel}
            data-testid="createRole-cancel"
            variant="text"
          >
            CANCEL
          </Button>
          <Permission
            controlId={`${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_CREATE}||`}
          >
            <Button
              onClick={handleSaveAndExit}
              data-testid="createRole-save-exit"
              variant="contained"
              disabled={roleDetailsState.loading}
            >
              SAVE AND EXIT
            </Button>
            <Button
              onClick={handleSave}
              data-testid={"createRole-save-exit"}
              variant="contained"
              disabled={roleDetailsState.loading}
            >
              SAVE
            </Button>
          </Permission>
        </Head>
        <Body>
          {checkInitialData(masterAccess) && (
            <CreateRoleBody
              masterAccess={masterAccess}
              role={role}
              setRole={setRole}
              control={control}
              validation={validation}
              apiError={apiError}
              apiFailedMessage={apiFailedMessage}
            />
          )}
        </Body>
        <Grid item xs={12}>
          <Box sx={createRoleFooter}>
            <Button
              onClick={handleCancel}
              data-testid="createRole-cancel"
              variant="text"
            >
              CANCEL
            </Button>
            <Permission
              controlId={`${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_CREATE}||`}
            >
              <Button
                onClick={handleSaveAndExit}
                data-testid="createRole-save-exit"
                variant="contained"
                disabled={roleDetailsState.loading}
              >
                SAVE AND EXIT
              </Button>
              <Button
                onClick={handleSave}
                data-testid="createRole-save-exit"
                variant="contained"
                disabled={roleDetailsState.loading}
              >
                SAVE
              </Button>
            </Permission>
          </Box>
        </Grid>
      </Main>
    </>
  );
};

export default CreateRole;

export const CreateRoleBody = ({
  masterAccess,
  role,
  setRole,
  control,
  validation,
  apiError,
  apiFailedMessage,
}: CreateRoleBodyProps) => {
  const handleCheckBox = (
    permission: number,
    moduleId: number,
    subModuleId: number
  ) => {
    const module: RoleDetailsAccess | undefined = role.roleModule.find(
      (module: RoleDetailsAccess) => module.moduleId === moduleId
    );

    if (module) {
      const subModule: RoleDetailsSubModule | undefined =
        module.roleSubModule.find(
          (subModule: RoleDetailsSubModule) =>
            subModule.subModuleId === subModuleId
        );

      if (subModule) {
        const checkAccess = subModule.accesses.find(
          (access: string) => permission.toString() === access
        );
        if (checkAccess) {
          const access: string[] = subModule.accesses.filter(
            (access: string) => permission.toString() !== access
          );

          if (access.length === 0) {
            const sub: RoleDetailsSubModule[] = module.roleSubModule.filter(
              (submodule: RoleDetailsSubModule) =>
                submodule.subModuleId !== subModule.subModuleId
            );
            module.roleSubModule = sub;
          } else {
            module.roleSubModule.forEach((submodule: RoleDetailsSubModule) => {
              if (submodule.subModuleId === subModule?.subModuleId) {
                submodule.accesses = access;
              }
            });
          }

          const check = role.roleModule.map((mod: RoleDetailsAccess) => {
            if (mod.moduleId === module?.moduleId) return module;
            return mod;
          });
          role.roleModule = check;
          const obj = role;
          setRole({ ...obj });
        } else {
          module.roleSubModule.forEach((submodule: RoleDetailsSubModule) => {
            if (submodule.subModuleId === subModule?.subModuleId && subModule) {
              subModule.accesses.push(permission.toString());
            }
          });

          const check = role.roleModule.map((access: RoleDetailsAccess) => {
            if (access.moduleId === module?.moduleId) return module;
            return access;
          });

          role.roleModule = check;
          const obj = role;
          setRole({ ...obj });
        }
      } else {
        const module = masterAccess.response.find(
          (module: MasterAccess) => module.moduleId === moduleId
        );
        if (module) {
          const subModule: MasterSubModule | undefined = module.subModule.find(
            (subModule: MasterSubModule) =>
              subModule.subModuleId === subModuleId
          );
          if (subModule) {
            let sb: RoleDetailsSubModule | null = null;
            const accessIds: string[] = [];
            accessIds.push(permission.toString());
            sb = {
              moduleId: module.moduleId,
              roleId: role.roleId,
              subModuleId: subModule.subModuleId,
              subModuleName: subModule.subModuleName,
              accesses: accessIds,
            };
            role.roleModule.forEach((roleModule) => {
              if (roleModule.moduleId === module.moduleId && sb !== null) {
                roleModule.roleSubModule = [...roleModule.roleSubModule, sb];
              }
            });

            const obj = role;
            setRole({ ...obj });
          }
        }
      }
    } else {
      const masterModule = masterAccess.response.find(
        (module: MasterAccess) => module.moduleId === moduleId
      );

      if (masterModule) {
        const roleDetailsSubModule = masterModule.subModule
          .map((submodule: MasterSubModule) => {
            return {
              moduleId: submodule.moduleId,
              roleId: role.roleId,
              subModuleId: submodule.subModuleId,
              subModuleName: submodule.subModuleName,
              accesses:
                submodule.subModuleId === subModuleId
                  ? [permission.toString()]
                  : [],
            };
          })
          .filter((submodule) => submodule.subModuleId === subModuleId);

        const roleDetailsModule: RoleDetailsAccess = {
          moduleId: masterModule.moduleId,
          moduleName: masterModule.moduleName,
          roleId: role.roleId,
          roleSubModule: roleDetailsSubModule,
        };

        const { roleModule } = role;
        role.roleModule = [...roleModule, roleDetailsModule];

        const obj = role;
        setRole({ ...obj });
      }
    }
  };

  const checkAccess = (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => {
    const { roleModule } = role as RoleDetailsAuth;
    if (!isNil(masterModule)) {
      const moduleId: RoleDetailsAccess | undefined = roleModule.find(
        (module: RoleDetailsAccess) => module.moduleId === masterModule.moduleId
      );
      if (moduleId) {
        const subModuleId: RoleDetailsSubModule | undefined =
          moduleId.roleSubModule.find(
            (submodule: RoleDetailsSubModule) =>
              submodule.subModuleId === masterSubModuleId
          );

        if (subModuleId) {
          if (subModuleId.accesses.includes(masterAccessId.toString()))
            return true;
          else return false;
        }
      }
    }

    return false;
  };

  const headerStyle = (
    marginBottom: string,
    title: string,
    isDescriptionAvailable?: boolean,
    description?: string
  ) => {
    return (
      <>
        <TitleBox mb={marginBottom}>
          <Typography
            variant="subtitle2"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
          >
            {title}
          </Typography>
          {isDescriptionAvailable && (
            <Typography
              mt={1}
              variant="subtitle2"
              color={alpha(colors.black[5], 0.8)}
              fontWeight={fontWeight.Weight[3]}
            >
              {description}
            </Typography>
          )}
        </TitleBox>
      </>
    );
  };

  const tableHeader = (position: any, headerTitle: string) => {
    return (
      <>
        <TableCell align={position} sx={roleDetailsStyles.table_heading}>
          <Typography
            variant="subtitle2"
            color={colors.black[2]}
            fontWeight={fontWeight.Weight[4]}
          >
            {headerTitle}
          </Typography>
        </TableCell>
      </>
    );
  };

  const checkInitialData = (masterAccess: MasterAccessResponse) => {
    return masterAccess && masterAccess.response[0].moduleId !== -1;
  };

  return (
    <>
      <Grid item xs={12}>
        {headerStyle("1.31rem", "ADD ROLE", true, "Details specific to Role")}
        <DividerItem />
      </Grid>
      <Grid item xs={12} pl={"1.5rem"}>
        <Box sx={[roleDetailsStyles.body_row2, alignItemBaseline]}>
          <Box sx={roleDetailsStyles.body_row2col2.main}>
            <FormInputText
              name="roleName"
              control={control}
              label="Role Name:"
              textLength={35}
              fieldrequired="true"
              dataTestId="createRole-roleName"
              helper={rules.roleName}
              multiline={true}
            />
          </Box>
          <Box sx={[roleDetailsStyles.body_row2col3.main, description]}>
            <FormInputText
              name="description"
              control={control}
              label="Description:"
              fieldrequired="true"
              dataTestId="createRole-roleDescription"
              helper={rules.roleDescription}
              multiline={true}
              textLength={127}
              maxRow={6}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={HeaderOuterBox}>
          {headerStyle("1.31rem", "CHOOSE MODULES :")}
          {validation && (
            <Box
              color={colors.red[100]}
              textAlign={"left"}
              sx={ErrorMessageBox}
            >
              <Typography
                variant="subtitle2"
                color={colors.red[100]}
                fontWeight={fontWeight.Weight[4]}
              >
                Please select atleast one access level
              </Typography>
            </Box>
          )}
          {apiError && (
            <Box
              color={colors.red[100]}
              textAlign={"left"}
              sx={ErrorMessageBox}
            >
              <Typography
                variant="subtitle2"
                color={colors.red[100]}
                fontWeight={fontWeight.Weight[4]}
              >
                {apiFailedMessage}
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container sx={BodyGridContainer}>
          <Body>
            <Grid item xs={12}>
              <Box sx={{ margin: 4 }}>
                <TableContainer>
                  <Table aria-label="collapsible table" sx={bodytable}>
                    <TableHead sx={HeaderItem}>
                      <TableRow>
                        {tableHeader("center", "Modules")}
                        {tableHeader("left", "Sub-Modules")}
                        {tableHeader("left", "Access Levels")}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checkInitialData(masterAccess) &&
                        masterAccess.response.map(
                          (module: MasterAccess, index: number) => (
                            <SubModuleSection
                              module={module}
                              key={module.moduleId}
                              index={index}
                              handleCheckBox={handleCheckBox}
                              checkAccess={checkAccess}
                            />
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Body>
        </Grid>
      </Grid>
    </>
  );
};

const typography = (value: string): JSX.Element => {
  return (
    <>
      <Typography
        variant="subtitle1"
        color={colors.black[2]}
        fontWeight={fontWeight.Weight[4]}
      >
        {value}
      </Typography>
    </>
  );
};

type SubModuleSectionProps = {
  module: MasterAccess;
  index: number;
  checkAccess: (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => boolean;
  handleCheckBox: (
    permission: number,
    moduleId: number,
    subModuleId: number
  ) => void;
};

const SubModuleSection = ({
  module,
  index,
  handleCheckBox,
  checkAccess,
}: SubModuleSectionProps) => {
  const [open, setOpen] = useState(false);
  const showDropdownIcon = module.subModule.length > 1;

  if (open) {
    return (
      <>
        {module.subModule.map((submodule: MasterSubModule, index: number) => {
          let moduleName = null;
          if (index === 0) moduleName = module.moduleName;
          return (
            <TableRow
              data-testid={`createRole-tableRow-${index}`}
              key={submodule.subModuleId}
            >
              <TableCell align="center">
                <Box sx={roleDetailsStyles.body_row3row2.c2}>
                  {!isNil(moduleName) && (
                    <IconButtonBox>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        <FaChevronCircleStyle>
                          {open ? (
                            <FaChevronCircleUp />
                          ) : (
                            <FaChevronCircleDown />
                          )}
                        </FaChevronCircleStyle>
                      </IconButton>
                    </IconButtonBox>
                  )}
                  <Box sx={NameBox}>
                    {!isNil(moduleName) && typography(moduleName)}
                  </Box>
                </Box>
              </TableCell>
              <TableCell key={index}>
                <Box sx={roleDetailsStyles.body_row3row2.c3}>
                  {typography(submodule.subModuleName)}
                </Box>
              </TableCell>
              <TableCell align="left">
                <Grid container>
                  {submodule.access.map((access: MasterSubModuleAccess) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={3}
                      key={access.moduleId}
                    >
                      <Box>
                        <Box sx={roleDetailsStyles.box}>
                          <Checkbox
                            label={access.accessName}
                            checked={checkAccess(
                              module,
                              submodule.subModuleId,
                              access.accessId
                            )}
                            dataTestId={`createRole-row${index}-${access.accessName}`}
                            handleClick={() =>
                              handleCheckBox(
                                access.accessId,
                                module.moduleId,
                                submodule.subModuleId
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  }
  return (
    <>
      <TableRow
        data-testid={`createRole-tableRow-${index}`}
        key={module.moduleId}
      >
        <TableCell align="center">
          <Box sx={roleDetailsStyles.body_row3row2.c2}>
            {showDropdownIcon && (
              <Box sx={ButtonBox}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  data-testid={`createRole-toggle-moduleId${module.moduleId}`}
                >
                  <FaChevronCircleStyle>
                    {open ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                  </FaChevronCircleStyle>
                </IconButton>
              </Box>
            )}
            <Box sx={NameBox}>{typography(module.moduleName)}</Box>
          </Box>
        </TableCell>
        <TableCell>{submodules(module.subModule, open)}</TableCell>
        <TableCell>
          {subModulesAccess(module, open, handleCheckBox, checkAccess)}
        </TableCell>
      </TableRow>
    </>
  );
};

const submodules = (submodules: MasterSubModule[], open: boolean) => {
  let modules = null;
  if (!open) {
    modules = submodules.map((subModule: MasterSubModule, index: number) => {
      if (index === 0) {
        const totalSubModule =
          submodules.length - 1 > 0 ? `+${submodules.length - 1}` : "";
        return (
          <Box
            key={subModule.subModuleId}
            sx={roleDetailsStyles.body_row3row2.c3}
          >
            {typography(`${subModule.subModuleName} ${totalSubModule}`)}
          </Box>
        );
      }
      return null;
    });
    return modules;
  }
  return null;
};

const subModulesAccess = (
  permission: MasterAccess,
  open: boolean,
  handleCheckBox: (
    permission: number,
    module: number,
    subModule: number
  ) => void,
  checkAccess: (
    masterModule: MasterAccess,
    masterSubModuleId: number,
    masterAccessId: number
  ) => boolean
) => {
  let access = null;
  if (!open) {
    access = permission.subModule.map(
      (subModule: MasterSubModule, index: number) => {
        if (index === 0)
          return (
            <Grid container key={permission.moduleName}>
              {subModule.access.map((access: MasterSubModuleAccess) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={3}
                  key={access.accessId}
                >
                  <Box>
                    <Box sx={roleDetailsStyles.box}>
                      <Checkbox
                        label={access.accessName}
                        checked={checkAccess(
                          permission,
                          subModule.subModuleId,
                          access.accessId
                        )}
                        dataTestId={`createRole-moduleId${permission.moduleId}-${access.accessName}`}
                        handleClick={() =>
                          handleCheckBox(
                            access.accessId,
                            permission.moduleId,
                            subModule.subModuleId
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          );
      }
    );

    return access;
  }

  return null;
};

import { Reducer } from "redux";

import { DisciplineNoteResponse } from "../models/Api/Notes";
import { DisciplineNoteDispatchTypes } from "../models/DispatchTypes/Notes";
import { DisciplineNoteActionDispatchTypes } from "../constants/Notes";

const initialState: DisciplineNoteResponse = {
  loading: false,
  response: {
    totalRows: -1,
    disciplineNoteDetail: [],
  },
};
const DisciplineNoteReducer: Reducer<
  DisciplineNoteResponse,
  DisciplineNoteDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case DisciplineNoteActionDispatchTypes.GET_DISCIPLINE_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default DisciplineNoteReducer;

import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";

import { PhysicianFormInputs } from "../../../../routes/ReferralIntake/components/PhysicianInputForm";
import { PhysicianInfoSave } from "../style";
import { PhysicianRecordsResponse } from "../../../../../../models/Api/PhysicianDetails";
import { PhysicianDetails } from "../../../../../../models/PhysicianDetails";
import { getFormattedPhoneNo, isNil } from "../../../../../../utils";
import { PhysicianTypeID } from "../../../../../../constants/PhysicianDetails";
import { SaveButton } from ".";
import NppesSearchComponent from "../../../../../../components/custom/nppesSearchComponent/container/NppesSearchComponent";

export interface PropsFromState {
  control: Control<any, any>;
  reset: UseFormReset<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  inputFieldNames: any;
  physicianAutocompleteName: string;
  physicianRecords: PhysicianRecordsResponse;
  handleSave: any;
  showSaveButton: boolean;
}
export interface PropsFromDispatch {
  getPhysicianRecords: (value: string) => void;
  resetPhysicianRecords: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const PhysicianInfo: React.FC<AllProps> = ({
  control,
  reset,
  getValues,
  setValue,
  inputFieldNames,
  physicianAutocompleteName,
  physicianRecords,
  resetPhysicianRecords,
  handleSave,
  showSaveButton,
}: AllProps) => {
  const { response } = physicianRecords;
  const [open, setOpen] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState<boolean>(false);
  useEffect(() => {
    if (response && response.length != 0 && response[0].physicianName !== "") {
      setPhysicianListResp([...response]);
    } else {
      setPhysicianListResp(() => []);
    }
  }, [response]);

  const [PhysicianListResp, setPhysicianListResp] = useState<
    PhysicianDetails[]
  >([]);

  const choosePrimaryPhysician = (option: PhysicianDetails) => {
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        fax,
        county,
        phoneExt,
        phoneNo,
      } = option;
      reset({
        ...getValues(),
        primaryPhysician: {
          ...getValues("primaryPhysician"),
          physicianName: physicianName,
          physicianNpiId: physicianNpiId,
          taxId: taxId,
          streetName1: streetName1,
          streetName2: streetName2,
          city: city,
          county: county,
          state: physicianState,
          physicianState: physicianState,
          zipCode: zipCode,
          fax: getFormattedPhoneNo(fax),
          physicianTypeId: PhysicianTypeID.PRIMARY,
          phoneExt: phoneExt,
          physicianUid: "",
          phoneNo: getFormattedPhoneNo(phoneNo),
        },
      });
    }
  };

  const handleSelect = (option: PhysicianDetails) => {
    choosePrimaryPhysician(option);
    const value = PhysicianListResp.find(
      (physician) => physician.physicianName === option.physicianName
    );
    setValue(physicianAutocompleteName, value?.physicianName || null);
    resetPhysicianRecords();
    setOpen && setOpen(false);
  };
  const clearPhysicianInfo = () => {
    reset({
      ...getValues(),
      primaryPhysician: {
        ...getValues("primaryPhysician"),
        physicianName: "",
        physicianNpiId: "",
        taxId: "",
        streetName1: "",
        streetName2: "",
        city: "",
        county: "",
        state: "",
        physicianState: "",
        zipCode: "",
        fax: "",
        physicianTypeId: PhysicianTypeID.PRIMARY,
        phoneExt: "",
        physicianUid: "",
        phoneNo: "",
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <NppesSearchComponent
          handleSelect={handleSelect}
          NppesOpen={open}
          NppesSetOpen={setOpen}
          openAutocomplete={openAutocomplete}
          setOpenAutocomplete={setOpenAutocomplete}
        />
      </Grid>
      <Grid item xs={showSaveButton ? 10 : 12}>
        <PhysicianFormInputs
          control={control}
          reset={reset}
          getValues={getValues}
          setValue={setValue}
          names={inputFieldNames}
          isHeader={true}
          addManually={true}
          showClearBtn={false}
        />
      </Grid>
      {showSaveButton && (
        <>
          <Grid item xs={1} sx={PhysicianInfoSave}>
            {SaveButton(handleSave)}
          </Grid>
          <Grid item xs={1} sx={PhysicianInfoSave}>
            <Button variant="outlined" onClick={() => clearPhysicianInfo()}>
              CLEAR
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PhysicianInfo;

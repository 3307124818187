import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import AuthGeneralInfo from "../components/GeneralInfo";
import { NtuReasonMasterDispatchTypes } from "../../../../../models/DispatchTypes/Master";
import { GetNtuReasonMasterResponse } from "../../../../../actions/Master";

const mapStateToProps = ({
  authorizationStatusState,
  careCoordinationProgramState,
  careGiverState,
  dischargeReasonState,
  healthProgramState,
  staffingStatusState,
  urgencyStatusState,
  masterNtuReasonState
}: ApplicationState) => ({
  authorizationStatus: authorizationStatusState,
  careCoordination: careCoordinationProgramState,
  careGiver: careGiverState,
  disChargeReason: dischargeReasonState,
  healthProgram: healthProgramState,
  staffingStatus: staffingStatusState,
  urgencyStatus: urgencyStatusState,
  masterNtuReason: masterNtuReasonState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<NtuReasonMasterDispatchTypes>
  >
) => {
  return {
    getNtuReasonMasterResponse: () => {
      dispatch(GetNtuReasonMasterResponse());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGeneralInfo);

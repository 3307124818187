import { Box } from "@mui/system";

import { SaveIcd10Records } from "../../../../../models/Patient";
import ICD10 from "../../ReferralIntake/containers/ICD10";

export interface PropsFromState {
  append: any;
  clearErrors: (name: string) => void;
  control: any;
  fields: any[];
  getValues: any;
  icd10Response: any;
  icdvalues: SaveIcd10Records;
  remove: (id: number) => void;
  reset: any;
  setValue: (id: any, value: any, boolean: any) => void;
  watch: any;
}

type AllProps = PropsFromState;
const DiagnosisCode: React.FC<AllProps> = ({
  append,
  clearErrors,
  control,
  fields,
  getValues,
  icd10Response,
  icdvalues,
  remove,
  reset,
  setValue,
  watch,
}: AllProps) => {
  return (
    <>
      <Box>
        <ICD10
          append={append}
          clearErrors={clearErrors}
          control={control}
          fields={fields}
          getValues={getValues}
          icd10Response={icd10Response}
          icdvalues={icdvalues}
          remove={remove}
          reset={reset}
          setValue={setValue}
          watch={watch}
        />
      </Box>
    </>
  );
};

export default DiagnosisCode;

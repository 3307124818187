import { ReactElement, useRef } from "react";
import { Control, Controller } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { PatternFormat } from "react-number-format";
import { isNull, isUndefined, trim } from "lodash";

import {
  FaFaxIcon,
  FaPhoneInputDevider,
  FaPhoneInputIcon,
} from "./styles/style";
import { flexAllCentre } from "../../styles/mui/styles/display";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";

interface PhoneInfo {
  control: Control<any>;
  label?: string;
  phoneFaxName: string;
  rules: any;
  isFax?: boolean;
  extName?: string;
  disabled?: boolean;
  icon?: ReactElement<any, any>;
}

type AllProps = PhoneInfo;

export const PhoneFaxExtInput: React.FC<AllProps> = ({
  control,
  disabled,
  label,
  phoneFaxName,
  rules,
  extName,
  isFax,
  icon,
}: AllProps) => {
  const inputRefPhone = useRef<any>();
  const inputRefExt = useRef<any>();

  const handleExtensionNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0 && inputRefPhone.current) {
      inputRefPhone.current.focus();
    }
  };

  const handlePhoneNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = 14;

    if (
      event.target.value &&
      trim(event.target.value).length === maxLength &&
      inputRefExt.current
    ) {
      inputRefExt.current.focus();
    }
  };

  return (
    <Grid container>
      <Grid item xs={extName ? 8 : 12}>
        <Controller
          rules={rules}
          render={({
            field: { onChange, value, onBlur, ...fieldProps },
            fieldState: { error },
          }) => {
            let fieldError = false;
            let fieldErrorMessage = "";
            if (error && error.message) {
              fieldError = true;
              fieldErrorMessage = error.message;
            }

            return (
              <PatternFormat
                name={fieldProps.name}
                format="(%%%) %%%-%%%%"
                patternChar="%"
                placeholder="(000) 000-0000"
                variant="standard"
                error={fieldError}
                value={!isNull(value) && !isUndefined(value) ? value : ""}
                helperText={fieldErrorMessage}
                required={rules && rules.required && label ? true : false}
                disabled={disabled}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <Stack direction="row" sx={flexAllCentre}>
                      {icon ? (
                        icon
                      ) : isFax ? (
                        <FaFaxIcon />
                      ) : (
                        <FaPhoneInputIcon />
                      )}
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                        sx={FaPhoneInputDevider}
                      />
                    </Stack>
                  ),
                }}
                label={
                  <Typography
                    variant="h5"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[2]}
                  >
                    {label}
                  </Typography>
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputRef={inputRefPhone}
                onChange={(e) => {
                  onChange(e);
                  handlePhoneNo(e);
                }}
                onBlur={onBlur}
                customInput={TextField}
              />
            );
          }}
          name={phoneFaxName}
          control={control}
        />
      </Grid>
      {extName && (
        <Grid item xs={4}>
          <Controller
            render={({
              field: { onChange, value, onBlur, ...fieldProps },
              fieldState: { error },
            }) => {
              let fieldError = false;
              let fieldErrorMessage = "";
              if (error && error.message) {
                fieldError = true;
                fieldErrorMessage = error.message;
              }

              return (
                <PatternFormat
                  name={fieldProps.name}
                  format="%%%%"
                  patternChar="%"
                  variant="standard"
                  placeholder="0000"
                  value={!isNull(value) && !isUndefined(value) ? value : ""}
                  disabled={disabled}
                  error={fieldError}
                  helperText={fieldErrorMessage}
                  InputProps={{
                    startAdornment: (
                      <Stack direction="row" sx={flexAllCentre} pr={"1rem"}>
                        ext.
                      </Stack>
                    ),
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputRef={inputRefExt}
                  onChange={(e) => {
                    onChange(e);
                    handleExtensionNo(e);
                  }}
                  onBlur={onBlur}
                  customInput={TextField}
                />
              );
            }}
            name={extName}
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
};

import { FaMapMarkerAlt, FaRegEnvelope } from "react-icons/fa";

import { alpha, styled } from "@mui/system";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const Gridbody = () => ({
  marginTop: "1rem",
});

export const AvatarIcon = () => ({
  width: "4.2rem",
  height: "4.1rem",
});

export const PatientName = () => ({
  paddingLeft: "1rem",
  display: "flex",
  alignItems: "center",
  paddingTop: "0.2rem",
});

export const LeftContainer = () => ({
  width: "17%",
});

export const RightContainer = () => ({
  width: "83%",
  paddingLeft: "0.8rem",
});

export const PatientDetails = () => ({
  width: "100%",
  padding: "1rem ",
  display: "flex",
  justifyContent: "space-between",
});

export const PatientDetailsTop = () => ({
  width: "90%",
  padding: "1rem ",
  display: "flex",
  justifyContent: "space-between",
});

export const PatientDetailsBottom = () => ({
  display: "flex",
  padding: "1rem ",
  justifyContent: "space-between",
});

export const GridPatientInfo = () => ({
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
});

export const DetailsDivider = () => ({
  margin: "0 0.5rem",
});

export const Data = () => ({
  marginTop: "0.7rem",
});

export const TableWidth = () => ({
  width: "96%",
});

export const BodyTable = () => ({
  "& .MuiTableCell-root": {
    borderBottom: `none`,
    borderRight: "none",
    borderLeft: "none",
  },
  "& th:first-of-type": {
    borderRadius: "0.5rem 0 0 0.5rem",
  },
  "& th:last-child": {
    borderRadius: "0 0.5rem 0.5rem 0",
  },
});

export const HeaderItem = () => ({
  backgroundColor: alpha(colors.black[7], 0.35),
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
  height: "1rem",
});

export const AuthNumber = () => ({
  textDecoration: "underline",
});

export const FaMapMarkerAltIcon = styled(FaMapMarkerAlt)(() => ({
  color: colors.primary.main,
  margin: "0 .5rem 0 0 ",
}));
export const FaRegEnvelopeIcon = styled(FaRegEnvelope)(() => ({
  color: colors.primary.main,
  margin: "0 .5rem 0 0",
}));

export const HorizontalDivider = () => ({
  margin: "0.9rem",
  width: "98%",
});

export const SelectedAuthGrid = () => ({
  paddingTop: "0.5rem",
  paddingLeft: "2.1rem",
});

export const SelectedAuthTableGrid = () => ({
  paddingTop: "0.8rem",
});

export const GridContainer = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1rem 0.8rem 1rem 0.8rem",
  marginTop: "1rem",
  borderRadius: "1.2rem",
  display: "flex",
});

export const OuterbodyGrid = () => ({
  backgroundColor: colors.backgroundColor[7],
  opacity: 1,
  padding: "0.8rem 0.8rem",
  borderRadius: "0.5rem",
});

export const DetailsGrid = () => ({
  paddingLeft: "1rem",
});

export const ItemGrid = () => ({
  paddingLeft: "1.5rem",
});

export const ProductGrid = () => ({
  paddingLeft: "1.2rem",
});

export const TopPaddingItem = () => ({
  paddingTop: "1rem",
});

export const AddressFieldGrid = () => ({
  paddingTop: "0.6rem",
});

export const PatientDetailsBottomGrid = () => ({
  padding: "1rem 0",
  borderTop: `1px solid ${colors.borders[1]}`,
});

export const HistoryDataGrid = () => ({
  paddingTop: "0.2rem",
});

export const FieldValue = () => ({
  paddingTop: "0.5rem",
});

export const DetailsButton = () => ({
  fontSize: "0.6rem",
  fontWeight: fontWeight.Weight[4],
  height: "2.5rem",
  width: "8rem",
});

import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
} from "@mui/material";

import { length } from "../../../../utils";
import VirtualizeAutocomplete from "../../../formComponents/AutocompleteList";
import {
  NppesAutocompleteUI,
  IconFaUser,
} from "../../../../routes/AllPatientRecords/routes/ReferralIntake/styles/OrderingPhysician";
import {
  getTableBody,
  getTableHead,
  TableDropdown,
} from "../../../../constants/AllPatientRecord";
import fontWeight from "../../../../styles/mui/fontWeight";
import { colors } from "../../../../styles/colors";
import {
  NppesAutoComplete,
  IconFaSearch,
  Search,
} from "../../../../routes/AdminCenter/routes/NoteManagement/styles/NotesList";
import { NO_RECORD_FOUND } from "../../../../constants/ToastMessage";
import { StyledPopperCheckBoxAutocomplete } from "../../../styles/styles";
import useMenuItem from "../../../../hooks/useMenuItem";
import { stateNameAbbreviations } from "../../../../constants/StateAbbreviations";
import { getValue, isNil, stateListData } from "../../../../utils";
import {
  GetNPPESDataActionDispatchTypes,
  NppesApiCall,
  NPPESinitialRecord,
  NppesNameErrorMsg,
  physicianInitialData,
  AutocompleteDropDownPlacement,
} from "../../../../constants/PhysicianDetails";
import {
  NppesErrorMsgStyle,
  NppesOuterBodyGrid,
  NppesParentElementStyle,
  NppesStateSearchWidth,
  SearchNpiContainer,
} from "../styles/style";
import { DatePickerInput } from "../../../../routes/AllPatientRecords/routes/ReferralIntake/styles/DocumentReview";
import {
  NppesAutoCompletePaper,
  AutocompleteRow,
  AvailableRecords,
  StyledPopper,
} from "../../../formComponents/styles/style";
import {
  NppesFadedContainedButtonUI,
  NppesinlineStyle,
  NppesNPIPadding,
  NppesNPISearchPadding,
  NppesTableHeadColor,
  tableHederCellStyle,
} from "../../../../styles/common/style";
import { TablecellStyle } from "../../../../routes/AdminCenter/routes/UserManagement/styles/userProfile";
import { NPPESDataResponse } from "../../../../models/Api/PhysicianDetails";
import {
  AutocompleteDropDownPaddingType,
  AutoCompleteDropDownPositionType,
  PhysicianDetails,
} from "../../../../models/PhysicianDetails";
import {
  flexAlignCentre,
  flexAlignEnd,
  flexAllCentre,
} from "../../../../styles/mui/styles/display";

export interface PropsFromState {
  NppesOpen: boolean;
  NppesSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelect: React.Dispatch<React.SetStateAction<any>>;
  PhysicianDetailsData: NPPESDataResponse;
  openAutocomplete: boolean;
  setOpenAutocomplete: React.Dispatch<React.SetStateAction<boolean>>;
  autocompleteDropDownPosition?: AutocompleteDropDownPlacement;
  autocompleteDropdownPadding?: number;
}

export interface PropsFromDispatch {
  resetStates?: (actionType: string[]) => void;
  getNPPESDetails: (payload: any) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const NppesComponent: React.FC<AllProps> = ({
  NppesOpen,
  NppesSetOpen,
  handleSelect,
  PhysicianDetailsData,
  getNPPESDetails,
  resetStates,
  openAutocomplete,
  setOpenAutocomplete,
  autocompleteDropDownPosition,
  autocompleteDropdownPadding,
}: AllProps) => {
  const { open, onOpen, onClose } = useMenuItem();
  const [stateList, setStateList] = useState<any[]>([]);
  const [stateName, setStateName] = useState<any>();
  const [payloadStateName, setPayloadStateName] = useState<any>(null);
  const [lastNameinputValue, setLastNameInputValue] = useState<string>("");
  const [firstNameInputValue, setFirstNameInputValue] = useState<string>("");
  const [PhysicianListByNameResp, setPhysicianListByNameResp] = useState<
    PhysicianDetails[]
  >([]);
  const [PhysicianListByNPIResp, setPhysicianListByNPIResp] = useState<
    PhysicianDetails[]
  >([]);
  const [apicallIdentifier, setApiCallIdentifier] = useState<string>("");
  const { response, loading } = PhysicianDetailsData;
  const [npiNumberLength, setNpiNumberLength] = useState<string>("");

  const autocompleteRef = useRef<HTMLInputElement | null>(null);
  const BtnRef = useRef<HTMLButtonElement | null>(null);
  const lastNameFieldRef = useRef<HTMLInputElement | null>(null);
  const firstNameFieldRef = useRef<HTMLInputElement | null>(null);

  const methods = useForm<any>({
    defaultValues: null,
    mode: "all",
  });

  const { control, handleSubmit } = methods;

  const AutoCompleteDropDownPosition: AutoCompleteDropDownPositionType =
    autocompleteDropDownPosition
      ? { placement: autocompleteDropDownPosition }
      : {};

  const AutocompleteDropDownPadding: AutocompleteDropDownPaddingType =
    autocompleteDropdownPadding
      ? {
          offset: [autocompleteDropdownPadding, 0],
        }
      : {};

  const debouncedSearch = debounce((event, value, reason) => {
    handleNppesName(event, value, reason);
  }, 500);

  useEffect(() => {
    if (getValue(response, "[0].physicianName", "") !== "") {
      if (
        apicallIdentifier === NppesApiCall.BY_NAME &&
        PhysicianListByNameResp.length === 0
      ) {
        setPhysicianListByNPIResp([]);
        setPhysicianListByNameResp([...NPPESinitialRecord, ...response]);
      }
      if (apicallIdentifier === NppesApiCall.BY_NPI) {
        setPhysicianListByNameResp([]);
        setPhysicianListByNPIResp([...response]);
      }
    } else {
      setPhysicianListByNameResp(() => []);
      setPhysicianListByNPIResp(() => []);
    }
  }, [response]);

  useEffect(() => {
    window.addEventListener("scroll", closeDropdown);
    window.addEventListener("click", closeMuiDropdown);
    return () => {
      window.removeEventListener("scroll", closeDropdown);
      window.removeEventListener("click", closeMuiDropdown);
    };
  }, []);

  useEffect(() => {
    if (npiNumberLength.length < 10) {
      NppesSetOpen && NppesSetOpen(false);
      setPhysicianListByNPIResp(() => []);
      setOpenAutocomplete(false);
    }
  }, [npiNumberLength]);

  const handleFocus = () => {
    if (npiNumberLength.length === 10) {
      setApiCallIdentifier(NppesApiCall.BY_NPI);
      NppesSetOpen && NppesSetOpen(true);
      const payload = {
        npi: npiNumberLength,
      };
      getNPPESDetails(payload);
    } else {
      NppesSetOpen && NppesSetOpen(false);
    }
  };

  const refs = [autocompleteRef, BtnRef, firstNameFieldRef, lastNameFieldRef];

  const closeMuiDropdown = (event: MouseEvent) => {
    setTimeout(() => {
      const isOutsideClick = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );

      if (isOutsideClick) {
        setOpenAutocomplete(false);
        setPhysicianListByNameResp([]);
      }
    }, 0);
  };

  const closeDropdown = () => {
    setOpenAutocomplete(false);
    setPhysicianListByNameResp(() => []);
    setPhysicianListByNPIResp(() => []);
  };

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setPhysicianListByNameResp(() => []);
    debouncedSearch(event, value, reason);
    setNpiNumberLength(value.trim());
  };

  const handleNppesName = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setApiCallIdentifier("");
    setPhysicianListByNameResp([]);
    if (!isNil(value) && !isNil(event)) {
      if (value.length < 10) {
        NppesSetOpen && NppesSetOpen(false);
      }
      if (value.trim().length === 10 && reason === "input") {
        setApiCallIdentifier(NppesApiCall.BY_NPI);
        setPhysicianListByNameResp([]);
        resetStates &&
          resetStates([GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_RESET]);
        NppesSetOpen && NppesSetOpen(true);
        const payload = {
          npi: value,
        };
        getNPPESDetails(payload);
      }
    }
  };

  const validateNPPESNames = () => {
    const firstNameFilled =
      firstNameInputValue && firstNameInputValue.trim() !== "";
    const lastNameFilled =
      lastNameinputValue && lastNameinputValue.trim() !== "";

    return firstNameFilled || lastNameFilled || NppesNameErrorMsg;
  };

  useEffect(() => {
    const statesArray = stateListData();
    setStateList(statesArray);
  }, [stateNameAbbreviations]);

  const defaultProps = (stateList: any[] | null | undefined) => ({
    options: stateList as any[],
    getOptionLabel: (option: any) => option.stateName,
  });

  const onSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: any | null
  ) => {
    setStateName(value);
    const stateNameData =
      value != null && !isNil(stateList)
        ? stateList &&
          stateList.find(
            (v: any) =>
              v.stateName === value.stateName && v.stateId === value.stateId
          )
        : null;
    setPayloadStateName(stateNameData === null ? null : stateNameData.stateId);
    onClose();
  };

  const handleChoose = (option?: PhysicianDetails) => {
    handleSelect(option);
    setOpenAutocomplete(false);
    setPhysicianListByNameResp(() => []);
    setPhysicianListByNPIResp(() => []);
  };

  const onSubmit = () => {
    setOpenAutocomplete(true);
    setApiCallIdentifier(NppesApiCall.BY_NAME);
    resetStates &&
      resetStates([GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_RESET]);
    const payload = {
      firstName: firstNameInputValue && firstNameInputValue + "*",
      lastName: lastNameinputValue && lastNameinputValue + "*",
      state: payloadStateName,
    };
    getNPPESDetails(payload);
  };

  const handleSearch = () => {
    setPhysicianListByNPIResp([]);
    handleSubmit(onSubmit)();
  };

  const CustomPaper = (props: any) => {
    return (
      <Paper
        {...props}
        sx={NppesAutoCompletePaper("55rem")}
        ref={autocompleteRef}
      />
    );
  };

  const tableHeader = (position: any, headerTitle: string) => {
    return (
      <TableCell align={position} sx={tableHederCellStyle}>
        <Typography
          variant="body1"
          color={colors.black[14]}
          fontWeight={fontWeight.Weight[5]}
        >
          {headerTitle}
        </Typography>
      </TableCell>
    );
  };

  const tableCell = (
    value: any,
    isButton: boolean,
    option?: PhysicianDetails
  ) => {
    return (
      <TableCell sx={TablecellStyle}>
        {isButton === false ? (
          <Typography
            variant="subtitle2"
            color={colors.black[4]}
            fontWeight={fontWeight.Weight[4]}
          >
            {value}
          </Typography>
        ) : (
          <Button
            variant="contained"
            sx={[NppesFadedContainedButtonUI]}
            onClick={() => handleChoose(option)}
            ref={BtnRef}
            data-testid="nppes-choose"
          >
            CHOOSE
          </Button>
        )}
      </TableCell>
    );
  };

  return (
    <Grid container sx={flexAllCentre}>
      <Grid item xs={12}>
        <Grid container sx={NppesOuterBodyGrid}>
          <Grid item xs={"auto"} sx={flexAlignEnd}>
            <Grid container sx={SearchNpiContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                  sx={NppesNPISearchPadding}
                  display="inline"
                >
                  Search by NPI
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[2]}
                  color={colors.black[2]}
                  sx={NppesNPIPadding}
                  display="inline"
                >
                  (Requires full NPI number to view the results):
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Controller
                    name={"npi"}
                    control={control}
                    defaultValue={null}
                    render={({ fieldState: { error } }) => {
                      return (
                        <>
                          <VirtualizeAutocomplete
                            options={PhysicianListByNPIResp}
                            value={physicianInitialData}
                            handleInputChange={handleInputChange}
                            sx={NppesAutocompleteUI}
                            open={NppesOpen}
                            handleSelect={handleSelect}
                            tableHead={getTableHead(TableDropdown.PHYSICIAN)}
                            tableBody={getTableBody(TableDropdown.PHYSICIAN)}
                            autocompleteInputIcon={<IconFaUser />}
                            setOpen={NppesSetOpen}
                            maxLength={10}
                            width="55rem"
                            loading={loading}
                            placeholder="Enter NPI number"
                            InputType="onlyNumber"
                            handleFocus={handleFocus}
                            hideCrossIcon={true}
                            setClearBlur={false}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.6} sx={flexAlignCentre}>
            <Grid container sx={flexAllCentre}>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[4]}
              >
                OR
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs sx={flexAlignEnd}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                >
                  Search by Name/State
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap={"0.5rem"} pt={"0.4rem"}>
                  <Grid item xs={2.5} sx={NppesParentElementStyle}>
                    <Box>
                      <Controller
                        name={"lastName"}
                        control={control}
                        defaultValue={null}
                        data-testid="nppes-last-Name"
                        rules={{ validate: validateNPPESNames }}
                        render={({ fieldState: { error } }) => {
                          return (
                            <>
                              <Autocomplete
                                options={PhysicianListByNameResp}
                                value={null}
                                freeSolo
                                getOptionLabel={(option: any) =>
                                  option.physicianName
                                }
                                PaperComponent={CustomPaper}
                                PopperComponent={(props) => (
                                  <StyledPopper
                                    {...props}
                                    {...AutoCompleteDropDownPosition}
                                    modifiers={[
                                      {
                                        name: "offset",
                                        options: {
                                          ...AutocompleteDropDownPadding,
                                        },
                                      },
                                    ]}
                                  />
                                )}
                                loading={loading}
                                filterOptions={(options) => options}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Enter Last Name"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: null,
                                      endAdornment: null,
                                      onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                          e.stopPropagation();
                                        }
                                      },
                                    }}
                                    inputRef={lastNameFieldRef}
                                  />
                                )}
                                renderOption={(_props, option) => {
                                  return (
                                    <>
                                      {option.physicianNpiId === "" && (
                                        <Box sx={AvailableRecords}>
                                          <Typography
                                            variant="subtitle2"
                                            color={colors.black[2]}
                                            fontWeight={fontWeight.Weight[4]}
                                          >
                                            Available records :
                                          </Typography>
                                        </Box>
                                      )}
                                      <TableContainer>
                                        <Table>
                                          {option.physicianNpiId === "" ? (
                                            <TableHead>
                                              <TableRow>
                                                <Grid
                                                  container
                                                  sx={NppesTableHeadColor}
                                                >
                                                  <Grid item xs={4.1}>
                                                    {tableHeader(
                                                      "left",
                                                      "Name:"
                                                    )}
                                                  </Grid>
                                                  <Grid item xs={1.9}>
                                                    {tableHeader(
                                                      "left",
                                                      "NPI Number:"
                                                    )}
                                                  </Grid>
                                                  <Grid item xs={4.3}>
                                                    {tableHeader(
                                                      "left",
                                                      "Address:"
                                                    )}
                                                  </Grid>
                                                  <Grid item xs={1.7}>
                                                    {tableHeader(
                                                      "left",
                                                      "Action:"
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              </TableRow>
                                            </TableHead>
                                          ) : (
                                            <TableBody>
                                              <TableRow
                                                key={option.physicianNpiId}
                                              >
                                                <Box sx={[NppesinlineStyle]}>
                                                  <Grid
                                                    container
                                                    sx={AutocompleteRow}
                                                  >
                                                    <Grid item xs={4.1}>
                                                      {tableCell(
                                                        option.physicianName,
                                                        false
                                                      )}
                                                    </Grid>
                                                    <Grid item xs={1.9}>
                                                      {tableCell(
                                                        option.physicianNpiId,
                                                        false
                                                      )}
                                                    </Grid>
                                                    <Grid item xs={3.8}>
                                                      {tableCell(
                                                        option.physicianAddress,
                                                        false
                                                      )}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      {tableCell(
                                                        "",
                                                        true,
                                                        option
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </TableRow>
                                            </TableBody>
                                          )}
                                        </Table>
                                      </TableContainer>
                                    </>
                                  );
                                }}
                                sx={DatePickerInput}
                                inputValue={lastNameinputValue}
                                onInputChange={(_event, newInputValue) =>
                                  setLastNameInputValue(newInputValue)
                                }
                                onOpen={() => {
                                  if (
                                    Array.isArray(PhysicianListByNameResp) &&
                                    length(PhysicianListByNameResp)
                                  ) {
                                    setOpenAutocomplete(true);
                                  }
                                }}
                                open={openAutocomplete}
                                fullWidth
                              />
                              {error && (
                                <FormHelperText sx={NppesErrorMsgStyle}>
                                  {error.message}
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Box>
                      <Controller
                        name={"firstName"}
                        data-testid="nppes-first-Name"
                        rules={{ validate: validateNPPESNames }}
                        control={control}
                        defaultValue={null}
                        render={() => {
                          return (
                            <>
                              <Autocomplete
                                options={[]}
                                value={null}
                                freeSolo
                                getOptionLabel={(option: any) =>
                                  option.physicianName
                                }
                                PaperComponent={CustomPaper}
                                PopperComponent={StyledPopper}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Enter First Name"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: null,
                                      endAdornment: null,
                                      onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                          e.stopPropagation();
                                        }
                                      },
                                    }}
                                    inputRef={firstNameFieldRef}
                                  />
                                )}
                                sx={DatePickerInput}
                                inputValue={firstNameInputValue}
                                onInputChange={(_event, newInputValue) =>
                                  setFirstNameInputValue(newInputValue)
                                }
                                onOpen={() => {
                                  if (
                                    Array.isArray(PhysicianListByNameResp) &&
                                    length(PhysicianListByNameResp)
                                  ) {
                                    setOpenAutocomplete(true);
                                  }
                                }}
                                fullWidth
                              />
                            </>
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={NppesStateSearchWidth}>
                    <Box>
                      <Controller
                        name={"state"}
                        control={control}
                        defaultValue={null}
                        render={() => {
                          return (
                            <>
                              <Autocomplete
                                sx={NppesAutoComplete}
                                data-testid="nppes-state"
                                {...defaultProps(stateList)}
                                id="auto-complete"
                                autoComplete
                                onChange={(e, v) => {
                                  onSelection(e, v);
                                }}
                                includeInputInList
                                value={stateName || null}
                                noOptionsText={NO_RECORD_FOUND}
                                PopperComponent={
                                  StyledPopperCheckBoxAutocomplete
                                }
                                disabled={isNil(stateList) ? true : false}
                                isOptionEqualToValue={(option, value) =>
                                  option.stateId === value.stateId &&
                                  option.stateName === value.stateName
                                }
                                filterOptions={(options, { inputValue }) => {
                                  const lowercasedInput =
                                    inputValue.toLowerCase();
                                  return options.filter(
                                    (option) =>
                                      option.stateName
                                        .toLowerCase()
                                        .includes(lowercasedInput) ||
                                      option.stateId
                                        .toLowerCase()
                                        .includes(lowercasedInput)
                                  );
                                }}
                                onOpen={onOpen}
                                onClose={onClose}
                                clearOnBlur={true}
                                renderInput={(params) => {
                                  params.inputProps.maxLength = 50;
                                  return (
                                    <Box sx={Search}>
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Choose State(Optional)"
                                        InputProps={{
                                          ...params.InputProps,
                                          startAdornment: (
                                            <>
                                              <InputAdornment position="start">
                                                <IconFaSearch />
                                              </InputAdornment>
                                              {params.InputProps.startAdornment}
                                            </>
                                          ),
                                        }}
                                        sx={DatePickerInput}
                                      />
                                    </Box>
                                  );
                                }}
                                onInputChange={(_event, value) =>
                                  value !== "" && onOpen
                                }
                                open={open}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.stateId}>
                                      {option.stateName}
                                    </li>
                                  );
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box>
                      <Button
                        onClick={handleSearch}
                        variant="contained"
                        data-testid="nppes-search"
                      >
                        SEARCH
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NppesComponent;

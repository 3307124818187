import { Divider, Grid, Typography } from "@mui/material";

import { colors } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { DividerLineforReauth } from "../styles/style";
import fontWeight from "../../../../../styles/mui/fontWeight";

function Header() {
  return (
    <Grid item sx={displayFlex} xs={8} alignItems={"center"}>
      <Typography
        color={colors.black[3]}
        fontWeight={fontWeight.Weight[5]}
        variant="h4"
      >
        ADD REFERRAL DETAILS
      </Typography>
      <Divider
        orientation="vertical"
        flexItem
        sx={DividerLineforReauth}
      ></Divider>
      <Typography
        color={colors.fonts[11]}
        fontWeight={fontWeight.Weight[2]}
        variant="subtitle2"
      >
        Add referral details with respect to personal, source and address
        details below:
      </Typography>
    </Grid>
  );
}

export default Header;

import { Box, Grid, styled } from "@mui/material";
import { alpha } from "@mui/system";
import type { BoxProps, GridProps } from "@mui/material";
import { StyledComponent } from "@emotion/styled";

import { colors } from "../../../../../styles/colors";

export const BreadcumbLeftGrid: StyledComponent<GridProps> = styled(Grid)(
  () => ({
    padding: "1rem 1.5rem 1rem 1.5rem",
  })
);

export const ParentLayoutHeader = {
  height: "4.8rem",
  justify: "flex-start",
  alignItems: "center",
};

export const BoxOrientation: StyledComponent<GridProps> = styled(Grid)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItem: "center",
  justifyItem: "center",
}));

export const HeaderActionBorder: StyledComponent<BoxProps> = styled(Box)(
  () => ({
    borderRight: `1px solid ${alpha(colors.dropShadow[7], 1)}`,
    height: "1.0rem",
    margin: "0 0.8rem 0 0.8rem",
  })
);

export const BoxMarginTop = {
  margin: "0.24rem 0 0 0",
};

export const PatientContainerBox: StyledComponent<BoxProps> = styled(Box)(
  () => ({
    boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
    backgroundColor: colors.backgroundColor[2],
    borderRadius: "0.5rem",
    marginBottom: "1.2rem",
    padding: "1rem 1.5rem",
    width: "100%",
  })
);

export const leftContainer = {
  width: "50%",
};

export const rightContainer = {
  width: "50%",
};

export const avatarStyle = {
  width: "4rem",
  height: "4rem",
};

export const horizontalDivider = () => ({
  width: "100%",
  maxWidth: "100rem",
  paddingLeft: "20rem",
  paddingBottom: "1rem",
});

export const patientDetailsTop = {
  width: "100%",
  padding: "1rem ",
  display: "flex",
  justifyContent: "space-between",
};

export const patientDetailsBottom = {
  display: "flex",
  padding: "1rem ",
  justifyContent: "space-between",
};

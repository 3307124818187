import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AllPatientTabDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { getPatientTabList } from "../../../../../actions/AllPatientRecord";
import PatientTab from "../components/PatientTab";

const mapStateToProps = ({ allPatientIntakeTabState }: ApplicationState) => ({
  tabList: allPatientIntakeTabState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    getPatientTabList: () => {
      dispatch(getPatientTabList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientTab);

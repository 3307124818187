import { Reducer } from "redux";

import { get404InitialData } from "../utils";
import { CountyResponse } from "../models/Api/PatientList";
import { CountyDispatchTypes } from "../models/DispatchTypes/Service";
import { CountyActionDispatchType } from "../constants/Service";

export const defaultCounty = [
  {
    county: "",
  },
];

const initialState: CountyResponse = {
  loading: false,
  response: defaultCounty,
};

const CountyReducer: Reducer<CountyResponse, CountyDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CountyActionDispatchType.GET_COUNTY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CountyActionDispatchType.GET_COUNTY_SUCCESS: {
      return {
        ...state,
        response: get404InitialData(action.response, []),
        loading: false,
      };
    }
  }
  return state;
};

export default CountyReducer;

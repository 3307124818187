import { ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";

import { CheckboxFormControl, checkboxcolor, style } from "../styles/style";
import { others } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Param checkedLabels is mandatory
type CheckboxProps = {
  checked?: boolean;
  checkedLabels?: any;
  dataTestId?: string;
  disabled?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleClick?: (...args: any[]) => void;
  isBold?: boolean;
  label?: string;
  labels?: any;
  radioGroup?: boolean;
  value?: any;
};

const CheckBox = ({
  checked,
  checkedLabels,
  dataTestId,
  disabled,
  handleChange,
  handleClick,
  isBold,
  label,
  labels,
  radioGroup,
  value,
}: CheckboxProps) => {
  if (radioGroup && labels && checkedLabels) {
    return (
      <>
        <Box>
          <FormControl sx={style.formcontrol}>
            <FormLabel sx={style.formlabel}>{label}</FormLabel>
            <FormGroup sx={style.formgroup}>
              {labels.map((label: any) => (
                <FormControlLabel
                  key={label.value}
                  label={
                    <Typography
                      variant="subtitle2"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[4]}
                    >
                      {label.label}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      disabled={disabled}
                      data-testid={`customCheckboxGroup-${label.label}`}
                      value={label.value}
                      checked={checkedLabels.includes(label.value)}
                      onChange={handleChange}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <Box>
          <FormControlLabel
            label={
              <Typography
                variant="subtitle2"
                color={others.otherColors[63]}
                fontWeight={
                  isBold ? fontWeight.Weight[5] : fontWeight.Weight[4]
                }
              >
                {label}
              </Typography>
            }
            control={
              <Checkbox
                disabled={disabled}
                size="small"
                value={value}
                checked={checked}
                data-testid={dataTestId}
                onChange={handleClick ? handleClick : handleChange}
                sx={checkboxcolor}
              />
            }
            sx={CheckboxFormControl}
          />
        </Box>
      </Box>
    </>
  );
};

export default CheckBox;

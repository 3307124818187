import { Box, Button, Grid, Typography } from "@mui/material";
import { FaChevronRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { UseFormTrigger, UseFormWatch } from "react-hook-form";

import { width100PCT } from "../../../../../styles/mui/styles/widths";
import {
  ReAuthRefTab,
  RefTabs,
} from "../../../../AllPatientRecords/styles/style";
import { a11yProps, length, getValue, scrollTop } from "../../../../../utils";
import { AllPatientIntakeTabResponse } from "../../../../../models/Api/AllPatientRecord";
import { PanelBox, TabsPanelSectionBox } from "../../../styles/style";
import { colors } from "../../../../../styles/colors";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { flexAllEnd } from "../../../../../styles/mui/styles/display";
import { NextStepButton } from "../../ReferralIntake/styles/style";
import { ReAuthReferralRequest, Status } from "../../../../../models/Patient";
import { ReAuthServiceRequestResponse } from "../../../../../models/Api/Service";
import { SaveIcd10Records } from "../../../../../models/Patient";
import AlertDialog from "../../ReferralIntake/components/AlertDialog";
import Documents from "../../ReferralIntake/components/Documents";
import DocumentsReview from "../../ReferralIntake/containers/DocumentsReview";
import FollowingPhysician from "./FollowingPhysician";
import fontWeight from "../../../../../styles/mui/fontWeight";
import Header from "../../ReferralIntake/components/Header";
import ICD10 from "../../ReferralIntake/containers/ICD10";
import ReauthRequestDetail from "../containers/ReauthRequest";
import ReauthRequestor from "../containers/ReauthRequestor";
import ServiceRequest from "../containers/ServiceRequest";
import {
  FollowingPhysicianDetails,
  ReferralIntakePhysicianDetails,
} from "../../../../../models/PhysicianDetails";
import ReAuthReviewDocumentationDetails from "./ReAuthReview/containers/ReAuthReviewDocumentationDetails";
import ReAuthReviewICD10AndPhysicianDetails from "./ReAuthReview/containers/ReAuthReviewICD10AndPhysicianDetails";
import ReAuthReviewRequest from "./ReAuthReview/containers/ReAuthReviewRequest";
import ReAuthReviewRequestor from "./ReAuthReview/containers/ReAuthReviewRequestor";
import ReAuthReviewServiceRequest from "./ReAuthReview/containers/ReAuthReviewServiceRequest";
import {
  BackClickAlertMessage,
  SaveDetailsAlertMessage,
} from "../../../../../constants/Patient";
import { DocumentType } from "../../../../../models/Master";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import { DocumentTabType } from "../../../../../constants/Document";
import { ReAuthIntakeLastTab } from "../../../../../constants/AllPatientRecord";

export interface PropsFromState {
  clearErrors: (name: string) => void;
  control: any;
  currentTab: number;
  followingPhysicianDetailsResp: FollowingPhysicianDetails;
  followingPhysicianResp?: any;
  formError: any;
  formState: any;
  getReAuthServiceRequestData: (
    reAuthId: string,
    startOfCareDate?: string
  ) => void;
  getValues: any;
  handleSubmit: () => any;
  icd10RecordsAppend: any;
  icd10RecordsFields: any[];
  icd10RecordsRemove: any;
  icd10Response: any;
  icdvalues: SaveIcd10Records;
  patientRequestDetail: ReAuthReferralRequest | any;
  physicianDetailsResp: ReferralIntakePhysicianDetails;
  reAuthServiceRequest: ReAuthServiceRequestResponse | any;
  referralId: string;
  reset: any;
  resetField: any;
  setCurrentTab: (id: number) => void;
  setSocDate: any;
  setValue: (id: any, value: any, boolean: any) => void;
  socDate: any;
  submitReferral: () => void;
  tabList: AllPatientIntakeTabResponse;
  values: any;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  minimumDate: string;
  resetPhysicianRecords: () => void;
  documentType: DocumentType[];
}
export interface PropsFromDispatch {
  getPatientTabList: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface TabPanelProps {
  children?: React.ReactNode;
  className?: string;
  index: number;
  value: number;
}

function TabsPanelSection(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelSectionBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <PanelBox>
          <Box>{children}</Box>
        </PanelBox>
      )}
    </TabsPanelSectionBox>
  );
}

const ReferralTab: React.FC<AllProps> = ({
  clearErrors,
  control,
  currentTab,
  followingPhysicianDetailsResp,
  followingPhysicianResp,
  formError,
  formState,
  getPatientTabList,
  getReAuthServiceRequestData,
  getValues,
  handleSubmit,
  icd10RecordsAppend,
  icd10RecordsFields,
  icd10RecordsRemove,
  icd10Response,
  icdvalues,
  patientRequestDetail,
  physicianDetailsResp,
  reAuthServiceRequest,
  referralId,
  reset,
  resetField,
  setCurrentTab,
  setSocDate,
  setValue,
  socDate,
  submitReferral,
  tabList,
  values,
  watch,
  trigger,
  minimumDate,
  resetPhysicianRecords,
  documentType,
}: AllProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [backClick, setBackClick] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const { loading, response } = tabList;
  const [patientTabList, setTabList] = useState<Status[]>([]);

  useEffect(() => {
    if (!loading) {
      getPatientTabList();
    }
  }, []);

  useEffect(() => {
    if (response) {
      setTabList(response);
    }
  }, [response]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param event is mandatory
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    if (currentTab != tabList) {
      setOpenAlert(true);
    }
    setActiveTab(newValue);
  };

  const handleNextStep = () => {
    handleSubmit();
    setIsTabChange(false);
    setIsNextStep(true);
    scrollTop();
  };

  const handleBackClick = () => {
    setOpenAlert(false);
    setBackClick(false);
    if (currentTab != 0) {
      setCurrentTab(currentTab - 1);
    }
  };

  const handleBackStep = () => {
    if (currentTab == ReAuthIntakeLastTab) {
      setCurrentTab(currentTab - 1);
    } else {
      setOpenAlert(true);
      setBackClick(true);
    }
  };

  const handleSave = () => {
    handleSubmit();
    setIsTabChange(true);
    setOpenAlert(false);
  };

  useEffect(() => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    const formErrorLength = !isEmpty(getValue(formError, "response", {}))
      ? Object.keys(getValue(formError, "response", {})).length
      : 0;

    if (
      isTabChange &&
      formError &&
      !formError.loading &&
      formError.error &&
      Object.keys(formError.error).length == 0 &&
      formErrorLength >= 0
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      if (currentTab == tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      setIsTabChange(false);
    } else if (
      isNextStep &&
      formError &&
      !formError.loading &&
      formError.error &&
      Object.keys(formError.error).length == 0 &&
      formErrorLength >= 0
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(currentTab + 1);
      }

      if (currentTab == tabList) {
        setCurrentTab(activeTab);
      }
      setIsNextStep(false);
    } else if (
      (isTabChange && formError && !formError.loading && !formError.error) ||
      (isNextStep && formError && !formError.loading && !formError.error)
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      if (currentTab == tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      setIsTabChange(false);
      setIsNextStep(false);
    }
  }, [formError]);

  useEffect(() => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    if (currentTab == tabList) {
      setCurrentTab(activeTab);
    }
  }, [activeTab]);

  const handleSubmitCreate = () => {
    submitReferral();
  };

  return (
    <Box sx={width100PCT} pt={"0.5rem"}>
      <Grid container sx={flexAlignCentre} p={"0.5rem 1rem"}>
        <Header />
        <Grid item sx={flexAllEnd} xs={4}>
          {currentTab != 0 && (
            <Button
              variant="outlined"
              sx={NextStepButton}
              onClick={handleBackStep}
            >
              BACK
            </Button>
          )}
          {currentTab ==
          (response && length(response) && response.length - 1) ? (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={handleSubmitCreate}
            >
              <FaChevronRight /> SUBMIT AND CREATE
            </Button>
          ) : (
            <Permission
              controlId={`${PatientRecordsAccess.RE_AUTH_SAVE_AND_PROCEED_TO_NEXT_CREATE}||`}
            >
              <Button
                variant="contained"
                sx={NextStepButton}
                onClick={handleNextStep}
              >
                <FaChevronRight /> SAVE AND PROCEED TO NEXT STEP
              </Button>
            </Permission>
          )}
        </Grid>
      </Grid>
      {formState && Object.keys(formState.errors).length > 0 ? (
        <Typography
          fontWeight={fontWeight.Weight[3]}
          variant="subtitle2"
          color={colors.red[100]}
          pl={"1rem"}
        >
          Please fill in all mandatory fields
        </Typography>
      ) : null}
      <Box>
        <RefTabs
          value={currentTab}
          onChange={handleChange}
          sx={{ marginLeft: "2.8rem" }}
        >
          {patientTabList &&
            length(patientTabList) &&
            patientTabList.map((item, index) => {
              return (
                <ReAuthRefTab
                  key={index}
                  {...a11yProps(index)}
                  label={item.status}
                />
              );
            })}
        </RefTabs>
      </Box>

      <TabsPanelSection value={currentTab} index={0}>
        <ReauthRequestDetail
          control={control}
          setValue={setValue}
          values={values}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
          clearErrors={clearErrors}
          patientRequestDetail={patientRequestDetail}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={0}>
        <ReauthRequestor
          clearErrors={clearErrors}
          control={control}
          patientRequestDetail={patientRequestDetail}
          setValue={setValue}
          values={values}
          reset={reset}
          getValues={getValues}
          trigger={trigger}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={1}>
        <ICD10
          append={icd10RecordsAppend}
          clearErrors={clearErrors}
          control={control}
          fields={icd10RecordsFields}
          getValues={getValues}
          icd10Response={icd10Response}
          icdvalues={icdvalues}
          remove={icd10RecordsRemove}
          reset={reset}
          setValue={setValue}
          watch={watch}
          formState={formState}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={2}>
        <FollowingPhysician
          control={control}
          followingPhysicianDetailsResp={followingPhysicianDetailsResp}
          followingPhysicianResp={followingPhysicianResp}
          getValues={getValues}
          physicianDetailsResp={physicianDetailsResp}
          reset={reset}
          setValue={setValue}
          watch={watch}
          resetPhysicianRecords={resetPhysicianRecords}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={3}>
        <ServiceRequest
          control={control}
          getReAuthServiceRequestData={getReAuthServiceRequestData}
          getValues={getValues}
          reAuthServiceRequestData={reAuthServiceRequest}
          resetField={resetField}
          setSocDate={setSocDate}
          setValue={setValue}
          socDate={socDate}
          formState={formState}
          setIsNextStep={setIsNextStep}
          setIsTabChange={setIsTabChange}
          minimumDate={minimumDate}
          clearErrors={clearErrors}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={4}>
        <Documents
          control={control}
          setValue={setValue}
          values={values}
          documentType={documentType}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={4}>
        <DocumentsReview
          flowType={"reAuth"}
          isAuthDetails={false}
          refId={referralId}
          documentType={documentType}
          type={DocumentTabType.DOCUMENT}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={5}>
        <ReAuthReviewRequest />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={5}>
        <ReAuthReviewRequestor />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={5}>
        <ReAuthReviewICD10AndPhysicianDetails />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={5}>
        <ReAuthReviewServiceRequest />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={5}>
        <ReAuthReviewDocumentationDetails />
      </TabsPanelSection>

      <Box sx={flexAlignCentre}>
        {formState && Object.keys(formState.errors).length > 0 ? (
          <Typography
            fontWeight={fontWeight.Weight[3]}
            variant="subtitle2"
            color={colors.red[100]}
            pl={"1rem"}
            sx={width100PCT}
          >
            Please fill in all mandatory fields
          </Typography>
        ) : null}
        <Grid item sx={flexAllEnd} xs={12} m={"1.5rem"} ml={"auto"}>
          {currentTab != 0 && (
            <Button
              variant="outlined"
              sx={NextStepButton}
              onClick={handleBackStep}
            >
              BACK
            </Button>
          )}
          {currentTab ==
          (response && length(response) && response.length - 1) ? (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={handleSubmitCreate}
            >
              {" "}
              <FaChevronRight /> SUBMIT AND CREATE
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={() => handleNextStep()}
            >
              {" "}
              <FaChevronRight /> SAVE AND PROCEED TO NEXT STEP
            </Button>
          )}
        </Grid>
      </Box>
      <AlertDialog
        alertMessage={
          backClick ? BackClickAlertMessage : SaveDetailsAlertMessage
        }
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleSubmit={handleSave}
        back={backClick}
        handleBackClick={handleBackClick}
      />
    </Box>
  );
};
export default ReferralTab;

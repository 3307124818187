import { useState, useRef, useEffect } from "react";
import { Button, Grid, MenuItem } from "@mui/material";
import { isNil } from "lodash";

import {
    ArrowDropDownIcon,
    ArrowDropUpIcon,
    StyledMenu,
} from "../styles/style";
import Permission from "../../../../routes/Permission";
import useMenuItem from "../../../../hooks/useMenuItem";
import { DropdownAction } from "../../../../models/Common";

type DropDownMenuProps = {
    label: string;
    documentDropDownButtons: DropdownAction[];
    onSelect: (value: DropdownAction) => void;
};

const DropDownMenu: React.FC<DropDownMenuProps> = ({
    label,
    documentDropDownButtons,
    onSelect,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const gridRef = useRef<HTMLDivElement>(null);
    const [menuWidth, setMenuWidth] = useState<string | number>("auto");
    const { open, onOpen, onClose } = useMenuItem();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onOpen();
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => setAnchorEl(null), 200);
    };

    const handleMenuItemClick = (data: DropdownAction) => {
        onSelect(data);
        handleClose();
    };

    const renderMenuItem = (data: DropdownAction) => (
        <MenuItem
            key={data.label}
            disabled={data.isDisabled}
            onClick={() => handleMenuItemClick(data)}
            disableRipple
        >
            {data.label}
        </MenuItem>
    );

    useEffect(() => {
        if (gridRef.current) {
            setMenuWidth(gridRef.current.offsetWidth);
        }
    }, [open]);

    return (
        <Grid container>
            <Grid item xs={12} ref={gridRef} >
                <Button
                    id="dropdown-button"
                    aria-controls={open ? "dropdown-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                >
                    {label}
                </Button>
                <StyledMenu
                    id="dropdown-menu"
                    MenuListProps={{
                        "aria-labelledby": "dropdown-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                        width: menuWidth,
                    }}
                    disableScrollLock
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {documentDropDownButtons.map((data) =>
                        !isNil(data.permission) ? (
                            <Permission
                                key={data.label}
                                controlId={data.permission}
                                passThrough
                            >
                                {(isDisabled: boolean) =>
                                    !isDisabled ? renderMenuItem(data) : null
                                }
                            </Permission>
                        ) : (
                            renderMenuItem(data)
                        )
                    )}
                </StyledMenu>
            </Grid>
        </Grid>
    );
}

export default DropDownMenu;

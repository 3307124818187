import API from "./api";

import { PatientDetail } from "../models/Patient";

export const GetPatientRecord = (configUrl: any, suffix: string) =>
  API.get(`${configUrl}api/patients/${suffix}`);

export const GetPatientDetail = (configUrl: any, suffix: string) =>
  API.get(`${configUrl}api/patients/${suffix}`);

export const savePatientDetails = (configUrl: any, data: PatientDetail) =>
  API.post(`${configUrl}api/referrals`, data);

export const GetReceivedMethod = (configUrl: any, serviceRequestTypeId: number) =>
  API.get(`${configUrl}api/master/ReceivedMethod/${serviceRequestTypeId}`);

export const UpdatePatientDetail = (configUrl: any, data: any, suffix: any) =>
  API.put(`${configUrl}api/referrals/${suffix}`, data);

export const getReferralDetailsById = (configUrl: any, suffix: any) =>
  API.get(`${configUrl}api/referrals/${suffix}`);

export const SavePatientInformationData = (configUrl: any, data: FormData) =>
  API.post(`${configUrl}api/patients`, data);

export const GetUrgencyStatusMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/urgency`);

export const GetRelationshipMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/relationship`);

export const UpdateRemovePatient = (
  configUrl: any,
  data: any,
  staffId: number
) =>
  API.putApi(`${configUrl}api/patients/removePatient?staffId=${staffId}`, data);

import { Grid } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";

import {
  PaginationStyle,
  PatientContainerBox,
  TableGrid,
} from "../../styles/style";
import { getPatientFilterPayload, isNil, length } from "../../../../utils";
import { ASC, DESC, PER_PAGE } from "../../../../constants/FunctionalUtils";
import AssignStaff from "../../containers/AssignStaff";
import TableComponent from "../../../../components/formComponents/TableComponent";
import PaginationItem from "../../../../components/custom/pagination/components/PaginationItem";
import {
  ActionType,
  RemoveStaffingHeaderLabel,
  StaffingListData,
  StaffingListHeaderLabel,
} from "../../../../constants/Staffing";
import {
  StaffingList,
  StaffingReferralDetail,
} from "../../../../models/Staffing";
import RemovePatient from "../../containers/RemovePatient";
import { StaffingQueue } from "../../../../models/Filter";
import { FilterModel } from "../../../../models/Api/Filter";
import { getSortColumn } from "../../../../constants/AllPatientRecord";

export interface PropsFromState {
  activeTab: number;
  fieldValues: any;
  postStaffingList: (payload: StaffingQueue) => void;
  handleClear: () => void;
  openModal: boolean;
  errorFlag: boolean;
  patientRecord: StaffingList | null;
  staffingReferralSelected: StaffingReferralDetail[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setStaffingReferralSelected: React.Dispatch<
    React.SetStateAction<StaffingReferralDetail[]>
  >;
  setValue: (name: string, value: number | string, boolean: any) => void;
  getValues: UseFormGetValues<any>;
  setPage: (page: number) => void;
  setFilter: (filter: string) => void;
  page: number;
  setCheckRemovePatient: (value: boolean) => void;
  action: string;
  setSelectedFilterByArray: (arr: string[] | any) => void;
  storeFilterPayload: (type: number, payload?: any) => void;
  filterState: FilterModel;
  valueToOrderBy: string;
  setValueToOrderBy: (valueToOrderBy: string) => void;
}

type Props = PropsFromState;

const Staffing = ({
  activeTab,
  postStaffingList,
  handleClear,
  openModal,
  errorFlag,
  patientRecord,
  staffingReferralSelected,
  setOpenModal,
  setErrorFlag,
  setStaffingReferralSelected,
  setValue,
  getValues,
  setPage,
  page,
  setCheckRemovePatient,
  action,
  filterState,
  valueToOrderBy,
  setValueToOrderBy,
}: Props) => {
  const [allSelect, setAllSelect] = useState(false);
  const [orderDirection, setOrderDirection] = useState(ASC);
  const [staffingList, setStaffingList] = useState(
    patientRecord &&
      patientRecord.searchResponse &&
      length(patientRecord.searchResponse) &&
      patientRecord.searchResponse[0].referralId != 0
      ? patientRecord.searchResponse
      : []
  );
  const [allSelectedReferral, setAllSelectedReferral] = useState<
    StaffingReferralDetail[]
  >([]);

  /**------------------------------------------------------------------------------------- */
  const handleSort = (property: any) => {
    onAction(false, 1, property);
  };
  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    onAction(true, page);
  };
  const onAction = (isPageChange: boolean, page: number, property?: any) => {
    setPage(isPageChange ? page : 1);
    setValue("pageNo", isPageChange ? page : 1, true);
    if (!isPageChange) {
      const isAsc =
        valueToOrderBy === getSortColumn(property) && orderDirection === ASC;
      setOrderDirection(isAsc ? DESC : ASC);
      setValueToOrderBy(getSortColumn(property));
      setValue("sortColumn", getSortColumn(property), true);
      setValue("sortOrder", isAsc ? DESC : ASC, true);
      postStaffingList({
        ...getPatientFilterPayload(filterState.response.staffingQueue),
        pageNo: getValues("pageNo"),
        sortColumn: getSortColumn(property),
        sortOrder: isAsc ? DESC : ASC,
        firstName: getValues("firstName"),
        lastName: getValues("lastName"),
      });
      return;
    }
    postStaffingList({
      ...getPatientFilterPayload(filterState.response.staffingQueue),
      pageNo: page,
      sortColumn: valueToOrderBy,
      sortOrder: orderDirection,
    });
  };
  /**------------------------------------------------------------------------------------- */
  const count = Math.ceil(
    patientRecord && patientRecord.totalRows
      ? Number(patientRecord.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );
  useEffect(() => {
    const staffingList =
      patientRecord &&
      patientRecord.searchResponse &&
      length(patientRecord.searchResponse) &&
      patientRecord.searchResponse[0].patientId != 0
        ? patientRecord.searchResponse
        : [];
    setStaffingList(staffingList);
  }, [patientRecord]);

  useEffect(() => {
    const isPresent =
      staffingList.length !== 0 &&
      staffingList.every((val) =>
        allSelectedReferral.some((item) =>
          Object.entries(val).every(
            ([key, value]) =>
              item[key as keyof StaffingReferralDetail] === value
          )
        )
      );

    if (isPresent === true) {
      setAllSelect(true);
      setStaffingReferralSelected(allSelectedReferral);
    } else {
      setAllSelect(false);
      setStaffingReferralSelected(allSelectedReferral);
    }
  }, [allSelectedReferral, page, staffingList, staffingReferralSelected]);

  useEffect(() => {
    if (openModal) {
      if (staffingReferralSelected.length == 0) {
        setErrorFlag(true);
        setOpenModal(false);
      }
    }
    if (length(staffingReferralSelected)) {
      setErrorFlag(false);
    }
  }, [openModal, staffingReferralSelected]);

  const handleCheck = (e: any) => {
    if (e.target.value == "all") {
      setAllSelect(!allSelect);
      if (!allSelect) {
        const selectList = staffingList;
        const isAllSelectedPatients = selectList.every((val) =>
          allSelectedReferral.some((item) =>
            Object.entries(val).every(
              ([key, value]) =>
                item[key as keyof StaffingReferralDetail] === value
            )
          )
        );
        const filteredArray = selectList.filter(
          (item) =>
            !allSelectedReferral.some(
              (value) => value.serviceRequestId === item.serviceRequestId
            )
        );

        if (isAllSelectedPatients === false) {
          setAllSelectedReferral([...allSelectedReferral, ...filteredArray]);
        }
        setStaffingReferralSelected(selectList);
        return;
      }
      const uncheckArray = allSelectedReferral.filter(
        (items) =>
          !staffingList.some(
            (val) => val.serviceRequestId === items.serviceRequestId
          )
      );
      setStaffingReferralSelected([]);
      setAllSelectedReferral(uncheckArray);
    } else {
      const patientListOriginal: StaffingReferralDetail[] = staffingList;
      const patientList = staffingReferralSelected
        ? staffingReferralSelected
        : [];
      const patientAuthNos =
        patientList && patientList.map((patient) => patient.serviceRequestId);
      if (patientAuthNos && patientAuthNos.includes(Number(e.target.value))) {
        const newPatients = patientList
          ? patientList.filter((item) => {
              return item.serviceRequestId != e.target.value;
            })
          : [];

        const newSelectedPatients = allSelectedReferral
          ? allSelectedReferral.filter((item) => {
              return item.serviceRequestId != e.target.value;
            })
          : [];
        setStaffingReferralSelected(newPatients);
        setAllSelectedReferral(newSelectedPatients);
        setAllSelect(false);
      } else {
        const patient = patientListOriginal.filter(
          (item) => item.serviceRequestId == e.target.value
        );
        const newArrayWithAddedPatients = [...patientList, patient[0]];
        setStaffingReferralSelected(newArrayWithAddedPatients);
        setAllSelectedReferral(newArrayWithAddedPatients);
      }
    }
  };
  const checkPatient = (id: number) => {
    const patientChecked = staffingReferralSelected.filter(
      (patient) => patient.serviceRequestId == id
    );
    if (patientChecked.length == 0) return false;
    else return true;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PatientContainerBox m={"0 1rem"} sx={TableGrid}>
          <TableComponent
            allSelect={allSelect}
            checkPatient={checkPatient}
            handleCheck={handleCheck}
            handleSort={handleSort}
            HeaderLabel={StaffingListHeaderLabel}
            list={StaffingListData}
            orderDirection={orderDirection}
            patientList={staffingList}
            type="staffing"
            valueToOrderBy={valueToOrderBy}
          />
        </PatientContainerBox>
      </Grid>
      {length(staffingList) && (
        <Grid item xs={12} sx={PaginationStyle}>
          <PaginationItem
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Grid>
      )}
      {!isNil(staffingReferralSelected) &&
        openModal &&
        !errorFlag &&
        (action === ActionType.ASSIGN_STAFF ||
          action === ActionType.UNASSIGN_STAFF) && (
          <AssignStaff
            action={action}
            activeTab={activeTab}
            handleClear={handleClear}
            openModal={openModal}
            patientList={StaffingListHeaderLabel}
            selectedList={staffingReferralSelected}
            setAllSelect={setAllSelect}
            setOpenModal={setOpenModal}
            setPage={setPage}
            setPatientSelected={setStaffingReferralSelected}
            setAllSelectedPatients={setAllSelectedReferral}
            type="staffing"
          />
        )}
      {!isNil(staffingReferralSelected) &&
        openModal &&
        !errorFlag &&
        action === ActionType.REMOVE_FROM_LIST && (
          <RemovePatient
            activeTab={activeTab}
            allSelect={allSelect}
            handleSort={handleSort}
            openModal={openModal}
            patientList={RemoveStaffingHeaderLabel}
            selectedList={staffingReferralSelected}
            setAllSelect={setAllSelect}
            setCheckRemovePatient={setCheckRemovePatient}
            setOpenModal={setOpenModal}
            setPage={setPage}
            setPatientSelected={setStaffingReferralSelected}
            setAllSelectedPatients={setAllSelectedReferral}
            list={StaffingListData}
            action={action}
          />
        )}
    </Grid>
  );
};

export default Staffing;

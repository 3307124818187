import { Box, Grid, Typography } from "@mui/material";

import {
  DetailsInfo,
  DetailsInfoContainer,
  SearchGridContainer,
  SearchResultGrid,
  SerachTitle,
} from "../styles/PhysicianInputForm";
import {
  formatAddress,
  PhysicianDetails,
} from "../../../../../models/PhysicianDetails";
import colors from "../../../../../styles/colors/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  flexAlignCentre,
  justifyContentLeft,
} from "../../../../../styles/mui/styles/display";
import {
  FaFaxIcon,
  FaPhoneInputIcon,
} from "../../../../../components/formComponents/styles/style";
import { getFormattedPhoneNo } from "../../../../../utils";
import { PhysicianDetailsDetailsHeader } from "../../../../../constants/PhysicianDetails";

type AvailablePhysicianDetailsProps = {
  physicianDetails: PhysicianDetails;
  modal: boolean | undefined;
};

export const AvailablePhysicianDetails = ({
  physicianDetails,
  modal,
}: AvailablePhysicianDetailsProps) => {
  const {
    physicianName,
    physicianNpiId,
    city,
    county,
    physicianState,
    phoneNo,
    phoneExt,
    taxId,
    zipCode,
    state,
    fax,
    streetName1,
    streetName2,
  } = physicianDetails;
  const physicianstate = physicianState || state;

  const address = formatAddress(
    streetName1,
    city,
    county,
    physicianstate,
    zipCode,
    streetName2
  );

  const taxIdDetails = detailsSectionItem("Tax ID: ", taxId, modal, 4, 1.5);
  const addressDetails = detailsSectionItem("Address: ", address, modal, 4, 3);

  return (
    <>
      <Grid item xs={12} sx={modal ? SearchResultGrid : {}}>
        {modal && (
          <Box>
            <Typography
              variant="body1"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
              sx={SerachTitle}
            >
              SEARCH RESULT:
            </Typography>
          </Box>
        )}
        <Grid container sx={modal ? SearchGridContainer : DetailsInfo}>
          {detailsSectionItem("Physician Name:", physicianName, modal, 4, 2)}
          {detailsSectionItem("NPI:", physicianNpiId, modal, 4, 1.5)}
          {modal ? taxIdDetails : addressDetails}
          {modal ? addressDetails : taxIdDetails}
          {detailsSectionItem(
            "Phone Number:",
            phoneNo && getFormattedPhoneNo(phoneNo),
            modal,
            4,
            2.5,
            "phone",
            phoneExt
          )}
          {detailsSectionItem(
            "Fax Number:",
            fax && getFormattedPhoneNo(fax),
            modal,
            4,
            1.5,
            "fax"
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const detailsSectionItem = (
  headerTitle: string,
  value: string | undefined,
  modal: boolean | undefined,
  xs1: number,
  xs2: number,
  icon?: string,
  phoneExt?: string
) => {
  const extension = (phoneExt: string) => (
    <Box sx={[flexAlignCentre, justifyContentLeft]}>
      <Box>
        <Typography
          variant={
            headerTitle === PhysicianDetailsDetailsHeader.PHYSICIAN_NAME ||
            headerTitle === PhysicianDetailsDetailsHeader.NPI
              ? "h6"
              : "subtitle2"
          }
          color={colors.black[5]}
          fontWeight={fontWeight.Weight[4]}
        >
          &nbsp; ext: &nbsp;
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={
            headerTitle === PhysicianDetailsDetailsHeader.PHYSICIAN_NAME ||
            headerTitle === PhysicianDetailsDetailsHeader.NPI
              ? "h6"
              : "subtitle2"
          }
          color={colors.black[5]}
          fontWeight={fontWeight.Weight[4]}
        >
          {phoneExt}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid item xs={modal ? xs1 : xs2} sx={DetailsInfoContainer}>
        <Typography
          variant="body1"
          color={colors.fonts[2]}
          fontWeight={fontWeight.Weight[2]}
        >
          {headerTitle}
        </Typography>
        <Box
          sx={[flexAlignCentre, justifyContentLeft]}
          pt={icon ? "0.5rem" : "0.7rem"}
        >
          {icon ? (
            <Box>
              {value && icon === "phone" && <FaPhoneInputIcon />}
              {value && icon === "fax" && <FaFaxIcon />}
            </Box>
          ) : null}
          <Box
            sx={[flexAlignCentre, justifyContentLeft]}
          >
            <Box>
              <Typography
                variant={
                  headerTitle ===
                    PhysicianDetailsDetailsHeader.PHYSICIAN_NAME ||
                  headerTitle === PhysicianDetailsDetailsHeader.NPI
                    ? "h6"
                    : "subtitle2"
                }
                color={colors.black[5]}
                fontWeight={fontWeight.Weight[4]}
              >
                {value}
              </Typography>
            </Box>
            {icon === "phone" && phoneExt && extension(phoneExt)}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

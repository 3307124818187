import { Reducer } from "redux";

import { UserListActionDispatchTypes } from "../constants/User";
import { defaultImage } from "../constants/ImagePaths";
import { UserListResponse } from "../models/Api/User";
import { UserListDispatchTypes } from "../models/DispatchTypes/User";

const initialState: UserListResponse = {
  loading: true,
  response: [
    {
      userId: -1,
      fullName: "",
      userPhoto: defaultImage,
      userName: "",
      email: "",
      userStatus: "",
      lastLogin: "",
      roles: "",
    },
  ],
};

const UserListReducer: Reducer<UserListResponse, UserListDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserListActionDispatchTypes.USER_LIST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserListActionDispatchTypes.USER_LIST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default UserListReducer;

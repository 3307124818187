import { Grid } from "@mui/material";
import { alpha, Box, styled } from "@mui/system";
import { FaAngleRight, FaSearch, FaTimes } from "react-icons/fa";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const Casing = () => ({
  textTransform: "uppercase",
  padding: "0 0 0.487rem 0",
});

export const GrideItem = () => ({
  padding: "0.7rem 0 0 0",
  textAlign: "initial",
});

export const TopPaddingStyle = () => ({
  paddingTop: "0.9rem 0.9rem 0.9rem 0",
  textAlign: "initial",
});

export const LeftPaddingStyle = () => ({
  padding: "0.9rem 0.9rem 0.9rem 0",
  textAlign: "initial",
});

export const PsychologicalLeftPaddingStyle = () => ({
  padding: "1rem 0.9rem 0.9rem 0",
  textAlign: "initial",
});

export const CheckBoxGridPadding = {
  paddingLeft: "0.5rem",
};

export const PastHistoryCheckBoxGridPadding = {
  paddingLeft: "0.5rem",
  paddingTop: "1rem",
};

export const OuterGridContainer = {
  backgroundColor: others.otherColors[33],
  borderRadius: "0.487rem",
  padding: "1rem 0 1.4rem 0",
};

export const Title = () => ({
  textTransform: "uppercase",
  padding: "1.14rem 0 0.487rem 0",
});

export const PaddingStyle = () => ({
  padding: "0.5rem 0rem 0 2.14rem ",
});

export const BottomPadding = () => ({
  padding: "0rem 0rem 1rem 2.14rem ",
});
export const AllCheckboxPadding = {
  padding: "1rem 0rem 0rem 2rem ",
};

export const CareTypeLefBoxtStyle = () => ({
  padding: "0.7rem 3rem 1rem 2rem ",
});

export const CareTypeRightBoxtStyle = () => ({
  padding: "0 1rem 1rem 1rem ",
});

export const DisplayEnd = () => ({
  display: "flex",
  justifyContent: "end",
});

export const DateStyle = () => ({
  padding: "0rem 0rem 1rem 2rem",
  display: "flex",
});

export const CareTypeBoxPaddingStyle = () => ({
  paddingBottom: "1rem",
});

export const PsycologicalTitle = { textTransform: "capitalized" };

export const DividerStyle = {
  marginBottom: "0.97rem",
  marginTop: "1.24rem",
  marginLeft: "1.7rem",
};

export const BottomDividerStyle = {
  marginTop: " 2.47rem",
};

export const DidciplinesDividerStyle = {
  marginBottom: " 0rem",
  marginLeft: "1.7rem",
  marginTop: "2rem",
};

export const VerticalDivider = {
  height: "38.1rem",
  marginTop: "1.5rem",
  marginRight: "0.7rem",
};

export const CareTypeDividerStyle = {
  opacity: "0.4",
  marginTop: " 0.97rem",
};

export const StartCareDate = {
  padding: "1.69rem 0rem 1.706rem 0rem",
};

export const PaddingLeft = { paddingLeft: "5rem" };

export const GridForDisciplines = styled(Grid)(() => ({
  textAlign: "left",
  color: colors.black[5],
  paddingBottom: "1rem",
  marginLeft: "1.4rem",
}));

export const GridForCareType = styled(Grid)(() => ({
  textAlign: "left",
  color: colors.black[5],
  paddingBottom: "0.87rem",
}));

export const DesciplinesTypeGrid = styled(Grid)(() => ({
  display: "flex",
  padding: "1.25rem 0 0 0rem",
}));

export const PastMedicalHistoryGrid = styled(Grid)(() => ({
  display: "flex",
  padding: "1.25rem 0 0 0.25rem",
}));

export const SearchCareTypeBox = styled(Box)(() => ({
  padding: "1.25rem 3.25rem 3.25rem 1.8rem",
}));

export const CareTypeBox = {
  background: colors.backgroundColor[7],
  marginTop: "0.975rem",
  padding: "0.88rem 0.89rem 4.49rem",
  borderRadius: "0.487rem",
  height: "30.83rem",
};

export const MedicalHistoryBox = {
  marginTop: "0.975rem",
  borderRadius: "0.487rem",
  height: "25.83rem",
};

export const GridScrolling = {
  overflowY: "scroll",
  height: "25rem",
};

export const CareTypePaper = {
  p: "0.098rem 0.195rem",
  display: "flex",
  alignItems: "center",
  width: "90%",
  borderRadius: "1.6rem",
};

export const PaddingAll = {
  padding: "0.487rem 0.487rem 0.487rem 1.4rem",
};

export const InputBaseStyle = {
  ml: 1,
  flex: 1,
  color: alpha(colors.black[2], 0.7),
  fontWeight: "500",
};

export const searchCareTypeStyle = {
  padding: "0.88rem 0.98rem 0.98rem 0.98rem",
  borderRadius: "0.98rem",
  width: "auto",
  boxShadow: "0.0487rem 0.15rem 0.78rem others.otherColors[43]",
  background: others.otherColors[33],
  marginBottom: ".75rem",
};

export const GridChipBox = styled(Grid)(() => ({
  textAlign: "left",
  paddingLeft: "4.25rem",
  font: fontWeight.Weight[4],
}));

export const IconFaTimes = styled(FaTimes)(() => ({
  color: colors.black[1],
  textAlign: "right",
}));

export const ChipStyle = {
  background: others.otherColors[33],
  width: "70%",
  marginTop: "0.9rem",
  justifyContent: "left",
  padding: "1.3rem",
  "& .MuiChip-deleteIcon": {
    color: others.otherColors[40],
    opacity: 0.8,
  },
};

export const Footer = styled(Box)(() => ({
  display: "flex",
  padding: "0.9rem 1.5rem 0 0",
  justifyContent: "end",
}));

export const GridFooter = () => ({
  opacity: 1,
  margin: "0.5rem",
  display: "flex",
  padding: "0.8rem 0.8rem",
  float: "right",
});

export const IconFaSearch = styled(FaSearch)(() => ({
  display: "flex",
  color: alpha(colors.fonts[4], 0.32),
  justifyContent: "left",
}));

export const BackButton = () => ({
  color: colors.primary["main"],
  background: colors.black[7],
});

export const IconFaAngleRight = styled(FaAngleRight)(() => ({
  marginRight: "0.87rem",
  color: others.otherColors[33],
}));

export const DividerFooter = {
  margin: "1.24rem 2.24rem 0rem 2.24rem",
};

export const TextFieldPadding = { paddingLeft: "0rem", width: "50%" };

export const ChipLabelWidth = { width: "20.63rem" };

export const PastMedicalHistoryCheckbox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const BehaviourStyle = { display: "flex", paddingLeft: "2rem" };

export const NonCoveredCheckbox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const Asterisk = () => ({
  alignItems: "center",
  padding: "0rem 1.2rem 1rem 0 ",
  display: "flex",
});

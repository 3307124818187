import { Reducer } from "redux";

import { ServiceProviderResponse } from "../models/Api/Service";
import { ServiceProviderDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceProviderActionDispatchType } from "../constants/Service";

const initialState: ServiceProviderResponse = {
  loading: false,
  response: [],
};

const ServiceProviderReducer: Reducer<
  ServiceProviderResponse,
  ServiceProviderDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_INVOKE: {
      return {
        response: [],
        loading: true,
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_RESET: {
      return {
        response: [],
        loading: false,
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_SUCCESS: {
      return {
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ServiceProviderReducer;

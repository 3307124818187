import { Reducer } from "redux";

import { ReAuthTimelineResponse } from "../models/Api/Authorization";
import { ReAuthTimelineActionDispatchTypes } from "../constants/Authorization";
import { ReAuthTimelineDispatchTypes } from "../models/DispatchTypes/Authorization";

const initialState: ReAuthTimelineResponse = {
  loading: false,
  response: [],
};

const ReAuthTimelineReducer: Reducer<
  ReAuthTimelineResponse,
  ReAuthTimelineDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_RESET: {
      return initialState
    }
  }
  return state;
};

export default ReAuthTimelineReducer;

import { Reducer } from "redux";

import { DocumentFileResponse } from "../models/Api/Document";
import { ReferralDocumentFileActionDispatchTypes } from "../models/DispatchTypes/Document";
import { ReferralDocumentFileDispatchTypes } from "../constants/Document";

const initialState: DocumentFileResponse = {
  loading: false,
  response: [],
  error: null,
};

const SaveOrUpdateDocumentFile: Reducer<
  DocumentFileResponse,
  ReferralDocumentFileActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_RESET: {
      return initialState;
    }
  }
  return state;
};

export default SaveOrUpdateDocumentFile;

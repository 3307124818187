import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import Staffing from "../components/MyPatientsDetails/Staffing";
import { postStaffingList } from "../../../actions/Staffing";
import { StaffingQueue } from "../../../models/Filter";

const mapStateToProps = ({
  myPatientListState,
  filterState,
}: ApplicationState) => ({
  MyPatientsRecord: myPatientListState,
  filterState: filterState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    postStaffingList: (payload?: StaffingQueue) => {
      dispatch(postStaffingList(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staffing);

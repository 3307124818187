import { createTheme } from "@mui/material/styles";

import { colors, others } from "../../colors";

const color = {
  palette: {
    primary: {
      main: colors.primary.main,
      light: colors.primary.light,
      dark: colors.primary.main,
      contrastText: colors.primary.light,
    },
  },
};

const globalTheme = createTheme({
  ...color,
});

const themeOptions = {
  ...color,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: others.MainBackground[1],
          minWidth: "1350px",
          "&::-webkit-scrollbar": {
            width: "0.5em",
            background: colors.dropShadow[5],
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.dropShadow[5],
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.dropShadow[8],
            borderRadius: "20px",
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "3.4rem",
          height: "3.4rem",
          [globalTheme.breakpoints.up("sm")]: {
            minHeight: "3.4rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 0,
          boxShadow: "none",
          "&.MuiAutocomplete-paper": {
            width: "auto",
            height: "auto",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          height: "1.9rem",
          padding: "0.1rem 1.75rem 0 1.75rem",
          fontWeight: 600,
          fontSize: ".75rem",
          letterSpacing: 0,
          lineHeight: 1,
          margin: "0 .5rem 0 .5rem",
          minWidth: "3.5rem",
          fontFamily: "DM Sans",
          color: colors.fonts[18],
        },
        outlined: {
          backgroundColor: globalTheme.palette.common.white,
          border: `2px solid ${globalTheme.palette.primary.main}`,
          "&:hover": {
            border: `2px solid ${globalTheme.palette.primary.main}`,
          },
        },
        contained: {
          backgroundColor: colors.fonts[18],
          color: globalTheme.palette.common.white,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          letterSpacing: "0",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          letterSpacing: "0",
          fontFamily: "DM Sans",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.fonts[4],
          cursor: "pointer",
          "&:hover": {
            color: colors.fonts[4],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:after": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
            opacity: "0.3",
          },
          "& .MuiInput-underline:before": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
            opacity: "0.3",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
            opacity: "0.3",
          },
          "& .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiInputBase-formControl":
          {
            marginTop: "auto",
            paddingBottom: ".3rem",
          },
          "& .MuiAutocomplete-inputRoot": {
            paddingLeft: "0.6rem",
            paddingBottom: ".25rem",
            "& .MuiAutocomplete-input": {
              color: colors.black[5],
              fontSize: ".7rem",
              fontFamily: "DM Sans",
              fontWeight: "600",
              "&::placeholder": {
                color: others.otherColors[63],
                fontWeight: "500",
              },
            },
          },
        },
        listbox: {
          maxHeight: "16rem",
          overflow: "hidden auto",
          border: `1px solid ${colors.borders[1]}`,
          borderRadius: "4px",
          cursor: "pointer",
          "& .MuiAutocomplete-option": {
            fontWeight: 600,
            fontSize: ".7rem",
            fontFamily: "DM Sans",
            padding:"0.5rem 1rem"
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: ".7rem",
          fontFamily: "DM Sans",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-popupIndicator": {
            color: colors.fonts[4],
            marginRight: ".6rem",
          },
        },
        sizeMedium: {
          color: colors.fonts[18],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: colors.black[5],
          fontSize: ".8rem",
          fontWeight: "500",
          borderBottom: `none`,
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
          },
          "&::before": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
            opacity: ".3",
          },
          "&::after": {
            borderBottom: `1px solid ${colors.fonts[8]}`,
            opacity: ".3",
          },
          "&.MuiInputBase-root.MuiInputBase-formControl": {
            marginTop: "1.2rem",
            "& .MuiInputAdornment-root .MuiSvgIcon-root": {
              paddingBottom: ".25rem",
            },
          },
          "&.MuiInputBase-multiline": {
            padding: "1px 0 1px",
          },
        },
        input: {
          height: "1.25rem",
          padding: ".2rem 0 .35rem",
          color: colors.black[5],
          fontWeight: "600",
          fontSize: ".7rem",
          fontFamily: "DM Sans",
          "&::placeholder": {
            color: colors.black[5],
            opacity: "1",
          },
          WebkitBoxShadow: `0 0 0 1000px ${colors.backgroundColor[2]} inset`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: "1.25rem",
          padding: ".2rem 0 .35rem",
          fontWeight: "600",
          fontSize: ".7rem",
          color: colors.black[5],
          fontFamily: "DM Sans",
          "&::placeholder": {
            color: colors.black[5],
            opacity: "1",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32.5,
          height: 12.5,
          padding: 0,
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 17,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(17px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 1,
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: colors.fonts[18],
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 11,
            height: 11,
            borderRadius: 6,
            transition: globalTheme.transitions.create(["width"], {
              duration: 200,
            }),
          },
          "& .MuiSwitch-track": {
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: colors.backgroundColor[11],
            boxSizing: "border-box",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.fonts[2],
          opacity: 0.88,
          fontSize: ".9rem",
          fontWeight: 500,
        },
        asterisk: {
          fontSize: ".95rem",
          color: colors.red[100],
          paddingLeft: ".25rem",
          "&$error": {
            color: colors.red[100],
          },
          display: "inline-flex",
        },
        colorSecondary: {
          "&$checked": {
            color: colors.primary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.primary.main,
          "& .MuiSvgIcon-root": {
            height: "1rem",
            width: "1rem",
          },
          "&.Mui-checked": {
            "& .MuiSvgIcon-root": {
              color: colors.backgrounds[5],
            },
          },
          "&.Mui-disabled": {
            "& .MuiSvgIcon-root": {
              color: others.otherColors[58],
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "DM Sans",
          fontWeight: "400",
          lineHeight: 1,
          letterSpacing: "0px",
        },
        h1: {
          fontSize: "1.2rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: "1.2rem",
          },
        },
        h2: {
          fontSize: "1.1rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: "1.1rem",
          },
        },
        h3: {
          fontSize: "1rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: "1rem",
          },
        },
        h4: {
          fontSize: ".95rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".95rem",
          },
        },
        h5: {
          fontSize: ".9rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".9rem",
          },
          display: "inline-flex",
        },
        h6: {
          fontSize: ".85rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".85rem",
          },
        },
        subtitle1: {
          fontSize: ".8rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".8rem",
          },
        },
        subtitle2: {
          fontSize: ".75rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".75rem",
          },
        },
        body1: {
          fontSize: ".7rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".7rem",
          },
        },
        body2: {
          fontSize: ".65rem",
          [globalTheme.breakpoints.only("sm")]: {
            fontSize: ".65rem",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.fonts[18],
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: colors.black[5],
          border: `1px solid ${colors.primary.main}`,
          fontFamily: "DM Sans",
          "&.Mui-selected": {
            color: colors.white[1],
            backgroundColor: colors.primary.main,
            "&:hover": {
              backgroundColor: colors.primary.main,
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.red[100],
          "&.Mui-error": {
            color: colors.red[100],
          },
        },
      },
      asterisk: {
        color: colors.red[100],
        "&.Mui-error": {
          color: colors.red[100],
        },
      },
    },
  },
};

export default themeOptions;

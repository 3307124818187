import { Box, styled, Tab, Tabs } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaRegEnvelope,
  FaUserMd,
} from "react-icons/fa";

import colors from "../../../../../../styles/colors/colors";
import fontWeight from "../../../../../../styles/mui/fontWeight";

export const PatientHeaderTabs = styled(Tabs)((props) => ({
  overflow: "visible",
  "& .MuiTabs-scroller": {
    overflow: "visible",
  },
  marginLeft: "2rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
  "& .Mui-selected": {
    border: `1px solid ${colors.borders[1]}`,
    color: colors.fonts[18],
    fontSize: ".75rem",
    fontWeight: fontWeight.Weight[6],
    opacity: 1,
    textTransform: "uppercase",
    borderBottom: `1px solid ${props.theme.palette.common.white}`,
  },
  "& .MuiTabs-flexContainer": {
    paddingTop: "20px",
  },
}));

export const PatientHeaderTab = styled(Tab)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  marginRight: "1rem",
  borderRadius: "10px",
  borderBottomRightRadius: "0px",
  borderBottomLeftRadius: "0px",
  border: `1px solid ${colors.borders[1]}`,
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  textTransform: "uppercase",
  overflow: "visible",
  color: colors.fonts[19],
  padding: "0.7rem 0.5rem 0.2rem 0.5rem",
  minHeight: "2.45rem",
  fontSize: ".75rem",
  fontWeight: fontWeight.Weight[3],
  opacity: 0.9,
  width: "10.3rem",
}));

export const TabsPanelSectionBox = styled(Box)((props) => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: props.theme.palette.common.white,
  borderRadius: "10px",
  marginBottom: "1rem",
}));

export const PanelBox = styled(Box)(() => ({
  padding: "1.2rem 1.5rem 1rem 1.5rem",
}));

export const PatientDetailsValue = () => ({
  paddingTop: "0.8rem",
  lineHeight: "1.2rem",
});

export const DataField = () => ({
  paddingTop: "0.9rem",
});

export const ItemIconBox = () => ({
  color: colors.fonts[18],
  paddingRight: "0.4rem",
  paddingTop: "0.8rem",
});
export const patientName = {
  display: "flex",
  padding: "0.2rem 0.2rem 0 0.5rem",
  wordBreak: "break-word",
};

export const IconFaUser = styled(FaUserMd)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "left",
  color: colors.primary.main,
  margin: "0 0.6rem 0.23rem 0",
}));

export const FieldValue = () => ({
  paddingTop: "0.5rem",
  lineHeight: "1.2rem",
});

export const FaMapMarkerAltIcon: StyledComponent<any> = styled(FaMapMarkerAlt)(
  () => ({
    color: colors.primary.main,
    margin: "0 .5rem 0 0 ",
  })
);

export const FaRegEnvelopeIcon: StyledComponent<any> = styled(FaRegEnvelope)(
  () => ({
    color: colors.primary.main,
    margin: "0.05rem .5rem 0 0",
  })
);

export const MemberInfoContainer = () => ({
  display: "flex",
  paddingBottom: "1rem",
  justifyContent: "space-between",
});

export const MemberInfoFeild = () => ({
  padding: "0.9rem 0.5rem 0rem 0.5rem",
});

export const MemberInfoFormFeild = () => ({
  padding: "0.9rem 0.5rem 0rem 0.5rem",
});
export const MemberInfoFormFeildDropdown = () => ({
  padding: "0.98rem 0.5rem 0rem 0.5rem",
});
export const AddressInfoContainer = () => ({
  minHeight: "11rem",
});

export const AddressContainer = () => ({
  backgroundColor: colors.backgroundColor[1],
  borderRadius: "0.5rem",
  display: "flex",
  padding: "1.2rem 0rem 1.2rem 1rem",
});

export const AddressFeild = () => ({
  margin: "0rem 1rem",
});

export const PhysicianInfoSave = () => ({
  display: "flex",
  alignItems: "end",
  paddingBottom: "1.4rem",
});

export const MemberInfoSave = () => ({
  display: "flex",
  alignItems: "end",
  paddingBottom: "0.8rem",
  justifyContent: "flex-end",
});

export const FaMobileAltIcon = styled(FaMobileAlt)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary && props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const LowerContainerItem = () => ({
  display: "flex",
  gap: "1.5rem",
});

export const LowerContainer = () => ({
  padding: "0.5rem 0rem",
  display: "flex",
  borderTop: `1px solid ${colors.borders[1]}`,
});


import { Typography } from "@mui/material";

import { colors } from "../../../styles/colors";
import fontWeight from "../../../styles/mui/fontWeight";

export const mandatoryFieldLabel = () => (
  <Typography
    variant="h3"
    fontWeight={fontWeight.Weight[3]}
    color={colors.red[100]}
    pl={"0.4rem"}
  >
    *
  </Typography>
);

import { Navigate, Outlet } from "react-router-dom";

import { AuthorizationDetails } from "./AllPatientRecords/routes/AuthorizationDetails";
import { Mypatients } from "./MyPatients";
import { PatientInfo } from "./AllPatientRecords/routes/PatientDetails";
import { ReauthReferralIntake } from "./AllPatientRecords/routes/ReauthReferral";
import { ReferralIntake } from "./AllPatientRecords/routes/ReferralIntake";
import PatientInformationView from "./AllPatientRecords/routes/PatientDetails/containers/PatientInformation";
import {
  UserManagement,
  UserDetails,
} from "./AdminCenter/routes/UserManagement";
import {
  RoleManagement,
  RoleDetails,
  CreateRole,
} from "./AdminCenter/routes/RoleManagement";
import {
  NoteManagement,
  CreateNoteTemplate,
} from "./AdminCenter/routes/NoteManagement";
import { PatientRecords } from "./AllPatientRecords/routes/PatientRecords";
import { NonIndexRouteWithFullPath } from "../models/Modules";

export const MY_PATIENT_LIST = "/my-patients";

export const ADMIN_CENTER = "/admin-center";
export const USER_MANAGEMENT = "/user-management";
export const USER_MANAGEMENT_DETAILS = "/user-management/user-details/";
export const ROLE_MANAGEMENT = "/role-management";
export const ROLE_MANAGEMENT_ROLE_DETAILS = "/role-management/role-details/";
export const ROLE_MANAGEMENT_CREATE_ROLE = "/role-management/add-role";
export const NOTE_MANAGEMENT = "/note-management";
export const NOTE_MANAGEMENT_EDIT_TEMPLATE = "/note-management/edit-template/";
export const NOTE_MANAGEMENT_CREATE_TEMPLATE =
  "/note-management/create-template";

export const ALL_RECORDS = "/allrecords";
export const AUTHORZATION_DETAIL = "/authorization-details/";

export const PATIENT_RECORD = "/patient-records";
export const PATIENT_DETAIL = "/patient-details/";
export const CREATE_PATIENT = "/create-patient";
export const REAUTH_REFERRAL_INTAKE = "/create-re-auth/";
export const REFERRAL_INTAKE = "/create-prior-auth/";

export const ReferralIntakeLink = (referralId: string, id: string, additionalParams: string) => {
  return `${PATIENT_RECORD}${REFERRAL_INTAKE}${referralId}/${id}/${additionalParams}`;
};

export const ReauthReferralIntakeLink = (
  reAuthId: string,
  referralId: string
) => {
  return `${PATIENT_RECORD}${REAUTH_REFERRAL_INTAKE}${reAuthId}/${referralId}`;
};

export const PatientDetailsForm = (id: string) => {
  return `${PATIENT_RECORD}${PATIENT_DETAIL}${id}`;
};

export const AuthorizationDetailsLink = (id: string) => {
  return `${PATIENT_RECORD}${AUTHORZATION_DETAIL}${id}`;
};

export const ReAuthCompleteAuthorizationView = (id: string, type?: string) => {
  return `${PATIENT_RECORD}${AUTHORZATION_DETAIL}${id}${type}`;
};

export const AdminCenterLink = (id: string) => {
  return `${ADMIN_CENTER}${id}`;
};

export const CreatePatient = (
  lastName: string,
  dob: string,
  healthPlan: string,
  policyId: string,
  isManual: string
) => {
  return `${PATIENT_RECORD}${CREATE_PATIENT}/${lastName}/${dob}/${healthPlan}/${policyId}/${isManual}`;
};

export const UserManagementLink = () => {
  return `${ADMIN_CENTER}${USER_MANAGEMENT}`;
};

export const UserManagementDetailsLink = (id: string) => {
  return `${ADMIN_CENTER}${USER_MANAGEMENT_DETAILS}${id}`;
};

export const RoleManagementLink = () => {
  return `${ADMIN_CENTER}${ROLE_MANAGEMENT}`;
};

export const RoleManagementRoleDetailsLink = (id: string) => {
  return `${ADMIN_CENTER}${ROLE_MANAGEMENT_ROLE_DETAILS}${id}`;
};

export const RoleManagementCreateRoleLink = () => {
  return `${ADMIN_CENTER}${ROLE_MANAGEMENT_CREATE_ROLE}`;
};

export const NoteManagementLink = () => {
  return `${ADMIN_CENTER}${NOTE_MANAGEMENT}`;
};

export const NoteManagementCreateTemplateLink = (id: string) => {
  return `${ADMIN_CENTER}${NOTE_MANAGEMENT_EDIT_TEMPLATE}${id}`;
};

export const NoteManagementTemplateLink = () => {
  return `${ADMIN_CENTER}${NOTE_MANAGEMENT_CREATE_TEMPLATE}`;
};

export const Layout = () => {
  return <Outlet />;
};

const routeMapping: NonIndexRouteWithFullPath[] = [
  {
    path: "/my-patients",
    element: <Mypatients />,
    children: [],
  },
  {
    path: "/patient-records",
    element: <PatientRecords />,
    children: [
      {
        path: "create-patient/:lastName/:dob/:healthPlan/:policyId/:isManual",
        element: <PatientInfo />,
      },
      {
        path: "patient-details/:patientId",
        element: <PatientInformationView />,
      },
      {
        path: "create-prior-auth/:referralId/:patientId/:additionalParams",
        element: <ReferralIntake />,
      },
      {
        path: "authorization-details/:authNo",
        element: <AuthorizationDetails />,
      },
      {
        path: "create-re-auth/:reAuthId/:referralId",
        element: <ReauthReferralIntake />,
      },
    ],
  },
  {
    path: "/admin-center",
    element: <Navigate to="/admin-center/user-management" replace />,
    children: [
      {
        path: "user-management",
        element: <UserManagement />,
        children: [
          {
            path: "user-details/:userid",
            element: <UserDetails />,
          },
        ],
      },
      {
        path: "role-management",
        element: <RoleManagement />,
        children: [
          {
            path: "role-details/:roleId",
            element: <RoleDetails />,
          },
          {
            path: "add-role",
            element: <CreateRole />,
          },
        ],
      },
      {
        path: "note-management",
        element: <NoteManagement />,
        children: [
          {
            path: "create-template",
            element: <CreateNoteTemplate />,
          },
          {
            path: "edit-template/:templateId",
            element: <CreateNoteTemplate />,
          },
        ],
      },
    ],
  },
];

export default routeMapping;

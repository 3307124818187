import { ErrorMessage } from "@hookform/error-message";
import { FieldError } from "react-hook-form";
import { Typography } from "@mui/material";

import { colors } from "../../../styles/colors";

interface ErrorMessageProps {
  errors: FieldError | undefined;
  name: string;
}

type AllProps = ErrorMessageProps;

export const ReactHookFormErrMessage: React.FC<AllProps> = ({
  errors,
  name,
}: AllProps) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Typography
          variant="subtitle1"
          color={colors.red[100]}
          display="inline"
        >
          {message}
        </Typography>
      )}
    />
  );
};

import { LogLevel } from "@azure/msal-common";

import Logger from "../logHandlers/Logger";

export const loginScope = (clientId: string) => {
  const scope = {
    scopes: [`api://${clientId}/AdminAccess`, `api://${clientId}/UserAccess`],
  };
  return scope;
};

export const msalConfig = (data: any) => {
  const auth = {
    auth: {
      clientId: data.REACT_APP_CLIENT_ID,
      authority: data.REACT_APP_LOGIN_URL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "/",
      postLogoutRedirectUri: data.REACT_APP_LOGIN_URL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      claimsBasedCachingEnabled: true, // default for v2.x version, manually enabled for v3.x
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              Logger.error(message);
              return;
            case LogLevel.Verbose:
              Logger.debug(message);
              return;
            case LogLevel.Warning:
              Logger.warn(message);
              return;
          }
        },
      },
    },
  };
  return auth;
};

import { Reducer } from "redux";

import { AllPatientIntakeTabResponse } from "../models/Api/AllPatientRecord";
import { AllPatientTabDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { PatientTabDispatchTypes } from "../constants/AllPatientRecord";

const initialState: AllPatientIntakeTabResponse = {
  loading: false,
  response: [
    {
      id: -1,
      status: "",
    },
  ],
};

const PatientTabReducer: Reducer<
  AllPatientIntakeTabResponse,
  AllPatientTabDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientTabDispatchTypes.PATIENT_TAB_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientTabDispatchTypes.PATIENT_TAB_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientTabReducer;

import {
  FaCheck,
} from "react-icons/fa";
import { OverridableStringUnion } from "@mui/types";
import {
  Box,
  TableCell,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";

import { flexAllCentre } from "../../../../styles/mui/styles/display";
import { TimelineAuthStatus } from "../../../../models/Patient";
import { Variant } from "@mui/material/styles/createTypography";
import colors from "../../../../styles/colors/colors";
import fontWeight from "../../../../styles/mui/fontWeight";
import {
  TableBodyCell,
  TableHeaderCell,
  TableTypo,
} from "../styles/AuthAndReAuthDetailsCommon";

export const tableCell = (
  value: string | number,
  head?: boolean,
  width?: string,
  border?: any,
  tableName?: string
) => {
  const alignCenter = "center";

  return (
    <>
      <TableCell
        align={alignCenter}
        sx={head ? TableHeaderCell : TableBodyCell(tableName)}
        width={width}
      >
        {TypographyBox(
          "subtitle2",
          value,
          value == "Active" ? colors.fonts[22] : colors.fonts[4],
          fontWeight.Weight[4],
          " ",
          TableTypo(border, head, tableName)
        )}
      </TableCell>
    </>
  );
};

export const TypographyBox = (
  variant:
    | OverridableStringUnion<
        "inherit" | Variant,
        TypographyPropsVariantOverrides
      >
    | undefined,
  value: string | number,
  color: string,
  fontWeight: string,
  fontStyle?: string,
  sx?: object
) => {
  return (
    <Typography
      variant={variant}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      color={color}
      sx={sx}
    >
      {value}
    </Typography>
  );
};

export const getAuthDetailsById = (id: number, data: any) => {
  const timelineAuthStatusArray: TimelineAuthStatus[] = data || [];
  const auth: TimelineAuthStatus | undefined = timelineAuthStatusArray.find(
    (status) => status.workflowStageId === id
  );
  return auth;
};

export const StageComplete = () => {
  return (
    <Box sx={flexAllCentre}>
      <Box p="0.3rem" pt="0rem">
        <FaCheck color={colors.statusComplete} size="0.8rem" />
      </Box>
      <Box p="0.3rem">
        {TypographyBox(
          "body1",
          "COMPLETED",
          colors.statusComplete,
          fontWeight.Weight[3]
        )}
      </Box>
    </Box>
  );
};

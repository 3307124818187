import { Reducer } from "redux";

import { GeneralInfoResponse } from "../models/Api/Authorization";
import { GeneralInfoDispatchTypes } from "../models/DispatchTypes/Authorization";
import { GeneralInfoActionDispatchTypes } from "../constants/Authorization";

const initialState: GeneralInfoResponse = {
  loading: false,
  response: {
    receivedDate: "",
    receivedTime: "",
    authStatus: "",
    urgencyStatus: "",
    urgencyReason: "",
    referralSource: "",
    startOfCare: "",
    socValidate: false,
    staffingStatus: "",
    homeBound: "",
    homeBoundDate: "",
    careGiver: "",
    careGiverInfo: "",
    createdBy: "",
    createDate: "",
    effectiveFromDate: "",
    effectiveThroughDate: "",
    requestor: "",
    facilityDate: "",
    dischargeDate: "",
    dischargeReason: "",
    dischargeVerify: false,
  },
};

const GeneralInfoReducer: Reducer<
  GeneralInfoResponse,
  GeneralInfoDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GeneralInfoActionDispatchTypes.GENERAL_INFO_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GeneralInfoActionDispatchTypes.GENERAL_INFO_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GeneralInfoActionDispatchTypes.GENERAL_INFO_RESET: {
      return initialState;
    }
  }
  return state;
};

export default GeneralInfoReducer;

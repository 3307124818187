import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getCareType,
  getDisciplines,
  getPastMedicalHistory,
  getPsychologicalHistory,
} from "../../../../../actions/Master";
import {
  CareTypeResponse,
  PastMedicalHistoryResponse,
  PsychologicalHistoryResponse,
} from "../../../../../models/Api/Master";
import { ReferralOverviewDetail } from "../../../../../models/Patient";
import ServiceRequestUI from "../components/ServiceRequest";
import { resetStates } from "../../../../../actions/Common";
import { DisciplineSearch } from "../../../../../models/Service";

const mapStateToProps = ({
  careTypeState,
  disciplinesState,
  pastMedicalHistoryState,
  patientRecordDetailState,
  psychologicalHistoryState,
  postServiceRequestState,
}: ApplicationState) => ({
  careTypeData: careTypeState as CareTypeResponse,
  disciplinesState,
  pastMedicalHistoryData: pastMedicalHistoryState as PastMedicalHistoryResponse,
  patientDetail: patientRecordDetailState.response as ReferralOverviewDetail,
  psychologicalHistoryData:
    psychologicalHistoryState as PsychologicalHistoryResponse,
  serviceRequestUpdateData: postServiceRequestState,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getDisciplines: (payload: DisciplineSearch) => {
      dispatch(getDisciplines(payload));
    },

    getCareType: () => {
      dispatch(getCareType());
    },

    getPastMedicalHistory: () => {
      dispatch(getPastMedicalHistory());
    },

    getPsychologicalHistory: () => {
      dispatch(getPsychologicalHistory());
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestUI);

import { Reducer } from "redux";

import { PatientInformationViewResponse } from "../models/Api/PatientInformation";
import { PatientInformationReceivedDispatchType } from "../models/DispatchTypes/PatientInformation";
import { PatientInformationReceivedActionDispatchTypes } from "../constants/PatientInformation";
import { PatientHeaderDefaultValues } from "../constants/Patient";

 const defaultState = PatientHeaderDefaultValues;

const initialState: PatientInformationViewResponse = {
  loading: false,
  response: defaultState,
};

const PatientInformationReceivedReducer: Reducer<
  PatientInformationViewResponse,
  PatientInformationReceivedDispatchType
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_RESET: {
      return {
        ...state,
        response: defaultState,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientInformationReceivedReducer;

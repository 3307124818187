import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { PatientDetailActionDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import ReferralIntake from "../components";

import {
  getDocuments,
  getICd10Records,
  getPatientRequestById,
  getPhysicianDetails,
  postReferralSubmit,
  getServiceProviderById,
  referralIntakeReset,
  resetPhysicianRecords,
  saveReferralDetail,
  updateReferralDetail,
} from "../../../../../actions/AllPatientRecord";
import {
  getDocumentType,
  getPriorAuthReferralSourceMasterResp,
} from "../../../../../actions/Master";
import { getPatients } from "../../../../../actions/PatientInformation";
import { getServiceRequest } from "../../../../../actions/Service";
import { ReferralIntakePhysicianDetailsDispatchTypes } from "../../../../../models/DispatchTypes/PhysicianDetails";
import { ServiceRequestResponse } from "../../../../../models/Api/Service";
import { UserDetailsPayload } from "../../../../../models/User";
import { ReferralSubmitPayload } from "../../../../../models/Api/AllPatientRecord";
import {
  notifyInternalDataUpdates,
  resetStates,
} from "../../../../../actions/Common";
import { NotifyInternalData } from "../../../../../models/Patient";

const mapStateToProps = ({
  allPatientIntakeTabState,
  documentReviewState,
  icd10RecordState,
  icdCodeTypeState,
  loggedInUserState,
  patientInformationViewState,
  patientRecordDetailState,
  patientReferralRequestState,
  physicianRecordsState,
  postServiceProviderState,
  postServiceRequestState,
  referralDocumentState,
  referralIntakePhysicianDetailsState,
  referralSubmitState,
  saveIcd10RecordState,
  saveUpdatePatientRequestState,
  serviceProviderDataState,
  serviceRequestState,
  documentTypeState,
  priorAuthReferralSourceState,
  updatePatientHeaderState,
}: ApplicationState) => ({
  documentReview: documentReviewState,
  icd10Records: icd10RecordState,
  icdCodeDataType: icdCodeTypeState,
  patientHeaderData: patientInformationViewState,
  physicianRecordsState: physicianRecordsState,
  patientDetail: patientRecordDetailState,
  patientRequestData: patientReferralRequestState,
  referralDocumentUpdateData: referralDocumentState,
  referralIntakePhysicianState: referralIntakePhysicianDetailsState,
  referralSubmitData: referralSubmitState,
  saveIcd10RecordsRsp: saveIcd10RecordState,
  saveUpdatePatientData: saveUpdatePatientRequestState,
  serviceProviderData: serviceProviderDataState,
  serviceProviderUpdateData: postServiceProviderState,
  serviceRequestData: serviceRequestState as ServiceRequestResponse,
  serviceRequestUpdateData: postServiceRequestState,
  tabList: allPatientIntakeTabState,
  user: loggedInUserState.response as UserDetailsPayload,
  documentTypeResponse: documentTypeState,
  priorAuthReferralSource: priorAuthReferralSourceState,
  updatedPatientHeaderResp: updatePatientHeaderState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<
      | PatientDetailActionDispatchTypes
      | ReferralIntakePhysicianDetailsDispatchTypes
    >
  >
) => {
  return {
    getIcd10RecordDetails: (id: number, typeId: number) => {
      dispatch(getICd10Records(id, typeId));
    },
    getServiceRequest: (referralId: number) => {
      dispatch(getServiceRequest(referralId));
    },
    getPatientRequestById: (referralId: string, patientId: string) => {
      dispatch(getPatientRequestById(referralId, patientId));
    },
    updateReferralDetail: (payload: any, activeTab: number) => {
      dispatch(updateReferralDetail(payload, activeTab));
    },
    saveReferralDetail: (payload: any, activeTab: number) => {
      dispatch(saveReferralDetail(payload, activeTab));
    },
    getServiceProviderById: (referralId: string) => {
      dispatch(getServiceProviderById(referralId));
    },
    getPhysicianDetails: (referralId: string) => {
      dispatch(getPhysicianDetails(referralId));
    },
    postReferralSubmit: (
      payload: ReferralSubmitPayload,
      referralIntakeDetails?: boolean
    ) => {
      dispatch(postReferralSubmit(payload, referralIntakeDetails));
    },
    getDocumentReviewData: (id: string, type: number) => {
      dispatch(getDocuments(id, type));
    },
    resetReferralIntake: () => {
      dispatch(referralIntakeReset());
    },
    getPatient: (id: number, type: number) => {
      dispatch(getPatients(id, type));
    },
    resetPhysicianRecords: () => {
      dispatch(resetPhysicianRecords());
    },
    getDocumentType: () => {
      dispatch(getDocumentType());
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    notifyInternalDataUpdates: (payload: NotifyInternalData) => {
      dispatch(notifyInternalDataUpdates(payload));
    },
    getPriorAuthReferralSourceMasterResp: () => {
      dispatch(getPriorAuthReferralSourceMasterResp());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralIntake);

import { Reducer } from "redux";

import { get404InitialData } from "../utils";
import { PatientStateResponse } from "../models/Api/PatientList";
import { PatientStateDispatchTypes } from "../models/DispatchTypes/Service";
import { PatientStateActionDispatchType } from "../constants/Service";

export const defaultPatientState = [
  {
    patientState: "",
  },
];

const initialState: PatientStateResponse = {
  loading: false,
  response: defaultPatientState,
};

const PatientStateReducer: Reducer<
  PatientStateResponse,
  PatientStateDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientStateActionDispatchType.PATIENT_STATE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientStateActionDispatchType.PATIENT_STATE_SUCCESS: {
      return {
        ...state,
        response: get404InitialData(action.response, []),
        loading: false,
      };
    }
  }
  return state;
};

export default PatientStateReducer;

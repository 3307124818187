import { Reducer } from "redux";

import { FaxFormOptionMasterResponse } from "../models/Api/Master";
import { FaxFormOptionDispatchTypes } from "../models/DispatchTypes/Master";
import { FaxFormOptionMasterActionDispatchTypes } from "../constants/Master";

export const initialState: FaxFormOptionMasterResponse = {
  loading: false,
  response: {
    homeHealthServices: [],
    noticeToReferral: [],
  },
};

const FaxFormOptionMasterReducer: Reducer<
  FaxFormOptionMasterResponse,
  FaxFormOptionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_SUCCESS: {
      return {
        response: action.response,
        loading: false,
      };
    }
    case FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_RESET: {
      return initialState;
    }
  }
  return state;
};

export default FaxFormOptionMasterReducer;

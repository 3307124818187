import { UserListWithFilterPayload } from "../models/User";
import API from "../services/api";

export const GetUserDetails = (configUrl: string, suffix: string | undefined) =>
  API.get(`${configUrl}api/users/${suffix}`);

export const PutUserDetails = (
  configUrl: string,
  suffix: string,
  payload: any
) => API.putApi(`${configUrl}api/users/${suffix}`, payload);

export const PutLoggedInUserDetails = (
  configUrl: string,
  suffix: string,
  payload: any
) => API.putApi(`${configUrl}api/users/${suffix}`, payload);

export const GetUserList = (configUrl: string) =>
  API.get(`${configUrl}api/users`);

export const GetRoleManagement = (configUrl: string, suffix: string) =>
  API.get(`${configUrl}api/access/${suffix}`);

export const GetRoles = (configUrl: string) => API.get(`${configUrl}api/roles`);

export const GetUserPermissionCall = (configUrl: string, userId: number) =>
  API.get(`${configUrl}api/useraccess/userPermission/${userId}`);

export const GetUserListWithFilter=(configUrl: string, payload:UserListWithFilterPayload)=>API.get(`${configUrl}api/users/${payload.isSuspended}/status?userSearch=${payload.userSearch}`);
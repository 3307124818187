import { connect } from "react-redux";

import {
  PastMedicalHistoryResponse,
  PsychologicalHistoryResponse,
} from "../../../../../models/Api/Master";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { AuthPastMedicalHistoryResponse } from "../../../../../models/Api/Authorization";
import AuthPastMedicalHistoryUI from "../components/PastMedicalHistory";

const mapStateToProps = ({
  authPastMedicalHistoryState,
  pastMedicalHistoryState,
  psychologicalHistoryState,
}: ApplicationState) => ({
  authPastMedicalHistoryData:
    authPastMedicalHistoryState as AuthPastMedicalHistoryResponse,
  pastMedicalHistoryData: pastMedicalHistoryState as PastMedicalHistoryResponse,
  psychologicalHistoryData:
    psychologicalHistoryState as PsychologicalHistoryResponse,
});

export default connect(mapStateToProps)(AuthPastMedicalHistoryUI);

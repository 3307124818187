import { MutableRefObject, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isNull } from "lodash";

import {
  AlignCenter,
  checkBoxStyle,
  ContainerStyle,
  DownIcon,
  HeaderTableCell,
  PatientTableCell,
  SortBox,
  TableBodyCheckbox,
  TableBodyRow,
  TableCellcheckBoxStyle,
  TableCellHeaderLeft,
  TableCellSticky,
  TableCellStickyHeader,
  TableCellStickyHeaderLeftSecond,
  TableCellStickyLeft,
  TableCellStickyLeftSecond,
  TableComponentGrid,
  TableMain,
  TableMainHead,
  TableMainHeadRow,
  TableNameDetails,
  ViewButton,
} from "./styles/style";
import { ASC, DESC } from "../../constants/FunctionalUtils";
import { Checkbox } from "../custom/checkbox";
import { colors } from "../../styles/colors";
import { CursorPointer, TooltipStyle } from "../../styles/common/style";
import { DateFormats } from "../../constants/Date";
import { length, urgencyStatusColor, serviceStatusColor } from "../../utils";
import {
  getSortColumn,
  PatientList
} from "../../constants/AllPatientRecord";
import { formatDate } from "../../utils/DateTime";
import fontWeight from "../../styles/mui/fontWeight";
import { ModuleLinks, PatientRecordsModule } from "../../routes/AllRoutes";

export interface PropsFromDispatch {
  checkPatient?: (Id: any) => any;
  handleCheck?: (e: any) => any;
  handleSort: (property: any) => any;
}

export interface PropsFromState {
  allSelect?: boolean;
  HeaderLabel: any;
  list: any;
  orderDirection: any;
  patientList: any;
  type: string;
  valueToOrderBy: any;
}
type AllProps = PropsFromState & PropsFromDispatch;

const TableComponent: React.FC<AllProps> = ({
  allSelect,
  checkPatient,
  handleCheck,
  handleSort,
  HeaderLabel,
  list,
  orderDirection,
  patientList,
  type,
  valueToOrderBy,
}: AllProps) => {
  const containerRef = useRef<any>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(containerRef);
  const navigate = useNavigate();

  const handleView = (Id: number, flag: boolean) => {
    if (flag) {
      navigate(
        ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
          Id: Id,
        })
      );
    } else {
      navigate(
        ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
          patientId: Id,
        })
      );
    }
  };

  return (
    <Grid item xs={12} sx={TableComponentGrid}>
      <TableContainer
        component={Paper}
        {...events}
        ref={containerRef}
        sx={ContainerStyle}
      >
        <TableMain>
          <TableMainHead>
            <TableMainHeadRow>
              <TableCellHeaderLeft>
                <Box sx={AlignCenter}>
                  <Box sx={checkBoxStyle}>
                    <Checkbox
                      value={"all"}
                      handleChange={handleCheck}
                      checked={allSelect}
                    />
                  </Box>
                </Box>
              </TableCellHeaderLeft>
              {HeaderLabel.map((tablecell: any, index: any) =>
                index !== 0 && tablecell.label != "" ? (
                  <TableCell
                    key={index}
                    onClick={() => handleSort(tablecell.label)}
                    sx={HeaderTableCell}
                  >
                    <Box sx={tablecell.style?.cell}>
                      <Box sx={AlignCenter}>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.black[14]}
                        >
                          {tablecell.label}
                        </Typography>
                        <Box sx={SortBox}>
                          <FaSortUp
                            color={
                              orderDirection == ASC &&
                              getSortColumn(tablecell.label) == valueToOrderBy
                                ? colors.arrowIcon[5]
                                : colors.arrowIcon[4]
                            }
                            size={"0.7rem"}
                          />
                          <FaSortDown
                            color={
                              orderDirection == DESC &&
                              getSortColumn(tablecell.label) == valueToOrderBy
                                ? colors.arrowIcon[5]
                                : colors.arrowIcon[4]
                            }
                            size={"0.7rem"}
                            style={DownIcon}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                ) : index !== 0 ? (
                  <TableCellStickyHeader key={index}>
                    <Box sx={tablecell.style?.cell}>
                      <Typography
                        variant="body1"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.black[14]}
                      >
                        {tablecell.label}
                      </Typography>
                    </Box>
                  </TableCellStickyHeader>
                ) : (
                  <TableCellStickyHeaderLeftSecond
                    key={index}
                    onClick={() => handleSort(tablecell.label)}
                  >
                    <Box sx={tablecell.style?.cell}>
                      <Box sx={AlignCenter}>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.black[14]}
                        >
                          {tablecell.label}
                        </Typography>
                        <Box sx={SortBox}>
                          <FaSortUp
                            color={
                              orderDirection == ASC &&
                              getSortColumn(tablecell.label) == valueToOrderBy
                                ? colors.arrowIcon[5]
                                : colors.arrowIcon[4]
                            }
                            size={"0.7rem"}
                          />
                          <FaSortDown
                            color={
                              orderDirection == DESC &&
                              getSortColumn(tablecell.label) == valueToOrderBy
                                ? colors.arrowIcon[5]
                                : colors.arrowIcon[4]
                            }
                            size={"0.7rem"}
                            style={DownIcon}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </TableCellStickyHeaderLeftSecond>
                )
              )}
            </TableMainHeadRow>
          </TableMainHead>
          <TableBody>
            {length(patientList) ? (
              patientList.map((row: any, rowIndex: number) => {
                return (
                  <TableRow key={rowIndex} sx={TableBodyRow}>
                    <TableCellStickyLeft
                      sx={TableCellcheckBoxStyle(
                        patientList.length - 1 === rowIndex
                      )}
                    >
                      <Box sx={TableBodyCheckbox}>
                        <Checkbox
                          value={
                            type === "incompleteRequest"
                              ? row.serviceRequestId
                              : type === "staffing"
                              ? row.serviceRequestId
                              : row.serviceRequestDisciplineId
                          }
                          handleChange={handleCheck}
                          checked={
                            allSelect ||
                            (checkPatient &&
                              checkPatient(
                                type === "incompleteRequest"
                                  ? row.serviceRequestId
                                  : type === "staffing"
                                  ? row.serviceRequestId
                                  : row.serviceRequestDisciplineId
                              ))
                          }
                        />
                      </Box>
                    </TableCellStickyLeft>
                    {list.map((data: PatientList, index: number) => {
                      if (data.value === list[0].value) {
                        return (
                          <TableCellStickyLeftSecond
                            sx={TableNameDetails(
                              patientList.length - 1 === rowIndex
                            )}
                            key={index}
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={fontWeight.Weight[6]}
                              color={colors.fonts[18]}
                              sx={CursorPointer}
                              onClick={() => handleView(row.patientId, false)}
                            >
                              {row[data.value]}
                            </Typography>
                          </TableCellStickyLeftSecond>
                        );
                      }
                      return (
                        <TableCell
                          sx={PatientTableCell(
                            patientList.length - 1 === rowIndex
                          )}
                          key={index}
                        >
                          {data.value === "providerNpi" &&
                          !isNull(row[data.value]) &&
                          !isNull(row["providerName"]) ? (
                            <Tooltip
                              placement="bottom"
                              title={
                                <Typography
                                  variant="subtitle1"
                                  fontWeight={fontWeight.Weight[4]}
                                >
                                  {row["providerName"]}
                                </Typography>
                              }
                              arrow
                              slotProps={{
                                popper: {
                                  sx: TooltipStyle,
                                },
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                fontWeight={fontWeight.Weight[5]}
                                color={colors.black[5]}
                              >
                                {row[data.value]}
                              </Typography>
                            </Tooltip>
                          ) : data.value === "authorizationNumber" &&
                            type === "staffing" ? (
                            <Link
                              variant="subtitle1"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[21]}
                              onClick={() =>
                                navigate(
                                  ModuleLinks(
                                    PatientRecordsModule.AUTHORIZATION_VIEW,
                                    {
                                      Id: row.referralId,
                                    }
                                  )
                                )
                              }
                            >
                              {row[data.value]}
                            </Link>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              fontWeight={data.fontWeight}
                              color={
                                data.value === list[1].value ? urgencyStatusColor(row[data.value]):
                                  data.value === list[6].value
                                  ?serviceStatusColor(row[data.value])
                                  : colors.black[5]
                              }
                            >
                              {data.value === list[2].value
                                ? formatDate(
                                    row[data.value],
                                    DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
                                  )
                                : row[data.value]}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                    {type === "staffing" ? (
                      <></>
                    ) : (
                      <TableCellSticky
                        sx={PatientTableCell(
                          patientList.length - 1 === rowIndex
                        )}
                      >
                        <Box>
                          <Button
                            variant="contained"
                            onClick={() => handleView(row.referralId, true)}
                            sx={ViewButton}
                          >
                            VIEW
                          </Button>
                        </Box>
                      </TableCellSticky>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={14}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={fontWeight.Weight[4]}
                    color={colors.black[5]}
                  >
                    NO RECORDS FOUND
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableMain>
      </TableContainer>
    </Grid>
  );
};

export default TableComponent;

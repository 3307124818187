import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { DisciplineNoteSearch } from "../../../../../models/Disciplines";
import { resetStates } from "../../../../../actions/Common";
import {
  SaveReferralLevelNoteData,
  UpdateReferralLevelNotesData,
  getDisciplineLevelNotes,
  getReferralLevelNotes,
  getReferralNoteList,
} from "../../../../../actions/Notes";
import { GetReferralNoteDispatchTypes } from "../../../../../models/DispatchTypes/Notes";
import ReferralNoteList from "../components/ReferralNote";
import { ReferralLevelNote } from "../../../../../models/Notes";

const mapStateToProps = ({
  referralNotesListState,
  GetReferralLevelNotesState,
  SaveReferralLevelNotesState,
  UpdateReferralLevelNotesState,
  GetDisciplineLevelNotesState,
  SaveDisciplineLevelNotesState,
  UpdateDisciplineLevelNotesState,
  agencyCommunicationNote
}: ApplicationState) => ({
  ReferralNoteListData: referralNotesListState,
  getReferrallevelNotesData: GetReferralLevelNotesState,
  saveReferralLevelNoteDataResp: SaveReferralLevelNotesState,
  updateReferralLevelNotesDataResp: UpdateReferralLevelNotesState,
  GetDisciplineLevelNotesData: GetDisciplineLevelNotesState,
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNotesState,
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNotesState,
  agencyCommunicationNote: agencyCommunicationNote.response
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<GetReferralNoteDispatchTypes>
  >
) => {
  return {
    getReferralNoteList: (payload: DisciplineNoteSearch) => {
      dispatch(getReferralNoteList(payload));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getReferralLevelNotes: (referralNoteId: number) => {
      dispatch(getReferralLevelNotes(referralNoteId));
    },
    saveReferralLevelNoteData: (payload: ReferralLevelNote) => {
      dispatch(SaveReferralLevelNoteData(payload));
    },
    updateReferralLevelNotesData: (
      disciplineNoteId: number,
      payload: ReferralLevelNote
    ) => {
      dispatch(UpdateReferralLevelNotesData(disciplineNoteId, payload));
    },
    getDisciplineLevelNotes: (disciplineNoteId: number) => {
      dispatch(getDisciplineLevelNotes(disciplineNoteId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralNoteList);

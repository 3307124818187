import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  displayFlex,
  flexAlignCentre,
  flexAllEnd,
} from "../../../../../styles/mui/styles/display";
import ReferralTab from "../containers/ReferralTab";
import {
  AllPatientIntakeTabResponse,
  Icd10RecordResponse,
  ReauthReferralRequestResponse,
  ReAuthSubmitPayload,
  ReferralSubmitResponse,
  SavePatientRequestResponse,
} from "../../../../../models/Api/AllPatientRecord";
import { ReAuthReferralRequest } from "../../../../../models/Patient";
import {
  BackButton,
  DetailBox,
  DividerLine,
  ReferralGrid,
} from "../../ReferralIntake/styles/style";
import {
  documentDefaultValues,
  DocumentFlowType,
  Icd10RecordsDefaultValues,
  IntakeType,
  PatientDetailType,
  ReAuthInitialRequest,
  ReAuthInitialServiceRequest,
} from "../../../../../constants/AllPatientRecord";
import { DocumentReviewResponse } from "../../../../../models/Api/Document";
import { ReAuthServiceRequestResponse } from "../../../../../models/Api/Service";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import {
  FollowingPhysicianDetailsResponse,
  PhysicianDetailsResponse,
} from "../../../../../models/Api/PhysicianDetails";
import { AUTH_TABS } from "../../../../../constants/FunctionalUtils";
import { FollowingPhysicianDetailsForm } from "../../../../../models/PhysicianDetails";
import { getConcatStringOrNull, getValue } from "../../../../../utils";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { UserDetailsPayload } from "../../../../../models/User";
import ErrorDialog from "../../ReferralIntake/components/ErrorDialog";
import IncompleteDialog from "../../ReferralIntake/components/IncompleteDialog";
import { matchState } from "../../../../../utils/StateSearch";
import { DocumentTypeResponse } from "../../../../../models/Api/Master";
import { BackClickAlertMessage } from "../../../../../constants/Patient";
import { rules } from "../../../../../utils/validation/Validation";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import AlertDialog from "../../ReferralIntake/components/AlertDialog";
import PatientHeader from "../../../components/ActiveAuthCommon/PatientHeader/containers";

export interface PropsFromState {
  documentReview: DocumentReviewResponse;
  followingPhysicianDetailsState: FollowingPhysicianDetailsResponse;
  icd10Records: Icd10RecordResponse;
  patientHeaderData: PatientInformationViewResponse;
  patientRequestData: ReauthReferralRequestResponse;
  reAuthServiceRequestData: ReAuthServiceRequestResponse;
  reAuthSubmitData: ReferralSubmitResponse;
  referralDocumentUpdateData: DocumentReviewResponse;
  referralIntakePhysicianState: PhysicianDetailsResponse;
  saveUpdatePatientData: SavePatientRequestResponse;
  tabList: AllPatientIntakeTabResponse;
  user: UserDetailsPayload;
  documentTypeResponse: DocumentTypeResponse;
  updatePatientHeaderData: PatientInformationViewResponse;
}
export interface PropsFromDispatch {
  getFollowingPhysicianDetails: (reAuthId: number) => void;
  getPatient: (patientId: number, type: number) => void;
  getPhysicianDetails: (referralId: number) => void;
  getReauthDocumentReviewData: (id: string, type: number) => void;
  getReauthIcd10RecordDetails: (id: number, typeId: number) => void;
  getReauthReferralSubmit: (
    reAuthId: string,
    payload: ReAuthSubmitPayload
  ) => void;
  getReauthReferralSubmitReset: () => void;
  getReauthRequestById: (reAuthId: number) => void;
  getReAuthServiceRequestData: (
    reAuthId: string,
    startOfCareDate?: string
  ) => void;
  saveReauthReferralDetail: (payload: any, activeTab: number) => void;
  updateReauthReferralDetail: (payload: any, activeTab: number) => void;
  resetPhysicianRecords: () => void;
  getDocumentType: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ReauthReferralIntake: React.FC<AllProps> = ({
  documentReview,
  followingPhysicianDetailsState,
  getFollowingPhysicianDetails,
  getPatient,
  getPhysicianDetails,
  getReauthDocumentReviewData,
  getReauthIcd10RecordDetails,
  getReauthReferralSubmit,
  getReauthReferralSubmitReset,
  getReauthRequestById,
  getReAuthServiceRequestData,
  icd10Records,
  patientHeaderData,
  patientRequestData,
  reAuthServiceRequestData,
  reAuthSubmitData,
  referralDocumentUpdateData,
  referralIntakePhysicianState,
  saveReauthReferralDetail,
  tabList,
  updateReauthReferralDetail,
  user,
  resetPhysicianRecords,
  getDocumentType,
  documentTypeResponse,
  updatePatientHeaderData,
}: AllProps) => {
  const navigate = useNavigate();
  const { reAuthId, referralId } = useParams();

  const { loading, response } = patientHeaderData;
  const { response: updatePatientHeaderResponse } = updatePatientHeaderData;

  const { loading: patientRequestLoading, response: patientRequestResponse } =
    patientRequestData;

  const { response: followingPhysicianDetailsResp } =
    followingPhysicianDetailsState;
  const { response: physicianDetailsResp } = referralIntakePhysicianState;
  const {
    loading: reAuthServiceRequestLoading,
    response: reAuthServiceRequestResponse,
  } = reAuthServiceRequestData;
  const { loading: icd10RecordsLoading, response: Icd10RecordsResponse } =
    icd10Records;
  const [patientRequestDetail, setPatientRequestDetail] = useState<
    ReAuthReferralRequest | any
  >(null);
  const [currentTab, setCurrentTab] = useState(0);
  const { loading: documentLoading, response: documentResponse } =
    documentReview;
  const { loading: loadingDocumentType, response: documentType } =
    documentTypeResponse;
  const [icd10Response, setICd10Response] = useState<any>(null);
  const { response: referralSubmitResponse } = reAuthSubmitData;
  const [formError, setFormError] = useState<any>();
  const [hasFormError, setHasFormError] = useState(false);
  const [isCompleted, setIsCompleted] = useState(true);
  const [localReferralId, setReferralId] = useState<string>("");
  const [localReAuthId, setReAuthId] = useState<any>(0);
  const [socDate, setSocDate] = useState<any>();
  const [minimumDate, setMinimumDate] = useState<string>("");
  // saveDetailsClickType === 0 -> default value
  // saveDetailsClickType === 1 -> save details button clicked -> do not navigate
  // saveDetailsClickType === 2 -> save and exit button clicked -> navigate
  const [saveDetailsClickType, setSaveDetailsClickType] = useState<number>(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [backClick, setBackClick] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (
      referralSubmitResponse &&
      referralSubmitResponse.isCompleted != null &&
      !referralSubmitResponse.isCompleted
    ) {
      setShowErrorDialog(false);
      if (saveDetailsClickType === 2) {
        navigate(
          ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
            Id: response && response.referralId ? response.referralId : 0,
          })
        );
      }
      setIsCompleted(false);
    } else if (referralSubmitResponse && referralSubmitResponse.isCompleted) {
      setIsCompleted(true);
      if (saveDetailsClickType === 2) {
        navigate(
          ModuleLinks(
            PatientRecordsModule.REAUTH_COMPLETE_AUTHORIZATION_DETAILS,
            {
              referralId:
                response && response.referralId ? response.referralId : 0,
            }
          )
        );
      }
      getReauthReferralSubmitReset();
    }
  }, [referralSubmitResponse]);

  useEffect(() => {
    if (minimumDate.length === 0) {
      setMinimumDate(reAuthServiceRequestResponse.currentDate);
    }
  }, [reAuthServiceRequestResponse, minimumDate]);
  const [followingPhysicianResp, setFollowingPhysicianResp] =
    useState<any>(null);

  const [tabInfo, setTabInfo] = useState<any>(ReAuthInitialRequest);

  useEffect(() => {
    findCurrentTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (
      response &&
      response.intakeStageId &&
      response.intakeStageId >= AUTH_TABS
    ) {
      setCurrentTab(response.intakeStageId - AUTH_TABS - 1);
    }
  }, [response]);

  const findCurrentTab = (currentTabInfo: any) => {
    switch (currentTabInfo) {
      case 0:
        setTabInfo(ReAuthInitialRequest);
        break;
      case 1:
        setTabInfo(Icd10RecordsDefaultValues);
        break;
      case 2:
        setTabInfo(FollowingPhysicianDetailsForm);
        break;
      case 3:
        setTabInfo(ReAuthInitialServiceRequest);
        break;
      case 4:
        setTabInfo(documentDefaultValues);
        break;
      default:
        return {};
    }
  };

  const methods = useForm<any>({
    defaultValues: tabInfo,
    mode: "all",
  });

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    trigger,
  } = methods;

  const values = getValues();

  useEffect(() => {
    setReAuthId(Number(reAuthId) || 0);
    if (Number(reAuthId) > 0) {
      if (!loading) {
        getPatient(Number(reAuthId || 0), PatientDetailType.ServiceRequest);
      }
    } else if (referralId) {
      setReferralId(referralId ? referralId : "");
      if (!loading) {
        getPatient(Number(referralId || 0), PatientDetailType.Referral);
      }
    }
  }, [updatePatientHeaderResponse]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (!patientRequestLoading && reAuthId && Number(reAuthId) != 0) {
          getReauthRequestById(Number(reAuthId));
        }
        break;
      case 1:
        if (!icd10RecordsLoading && reAuthId) {
          getReauthIcd10RecordDetails(
            Number(reAuthId),
            DocumentFlowType.ReAuth
          );
        }
        break;
      case 2:
        if (reAuthId) {
          getFollowingPhysicianDetails(Number(reAuthId));
        }
        if (localReferralId) {
          getPhysicianDetails(Number(localReferralId));
        }
        break;
      case 3:
        if (!reAuthServiceRequestLoading && reAuthId) {
          getReAuthServiceRequestData(reAuthId);
        }
        break;
      case 4:
        if (!documentLoading && reAuthId) {
          getReauthDocumentReviewData(
            reAuthId.toString(),
            DocumentFlowType.ReAuth
          );
          if (!loadingDocumentType) {
            getDocumentType();
          }
        }
        break;
      default:
        break;
    }
  }, [currentTab, reAuthId, referralId]);

  useEffect(() => {
    if (response && response.referralId) {
      setReferralId(response.referralId.toString());
    }
  }, [response]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (patientRequestResponse) {
          reset(patientRequestResponse);
          setPatientRequestDetail(patientRequestResponse);
        } else {
          reset(ReAuthInitialRequest);
        }
        break;
      case 1:
        if (Icd10RecordsResponse) {
          reset(Icd10RecordsResponse);
          setICd10Response(Icd10RecordsResponse);
        } else {
          reset(Icd10RecordsDefaultValues);
        }
        break;
      case 2:
        if (followingPhysicianDetailsResp) {
          reset(followingPhysicianDetailsResp);
          setFollowingPhysicianResp(followingPhysicianDetailsResp);
        } else {
          reset(FollowingPhysicianDetailsForm);
        }
        break;
      case 3:
        if (reAuthServiceRequestResponse) {
          reset(reAuthServiceRequestResponse);
        } else {
          reset(ReAuthInitialServiceRequest);
        }
        break;
      case 4:
        if (documentResponse) {
          reset(documentResponse);
        } else {
          reset(documentDefaultValues);
        }
        break;
      default:
        break;
    }
  }, [
    currentTab,
    documentResponse,
    followingPhysicianDetailsResp,
    Icd10RecordsResponse,
    patientRequestResponse,
    reAuthServiceRequestResponse,
  ]);

  useEffect(() => {
    const userName = getValue(user, "email");
    if (currentTab === 0) {
      setValue("referralRequest.userCreated", userName);
      setValue("referralRequest.serviceRequestId", reAuthId);
      setValue("requester.serviceRequestId", reAuthId);
      setValue("referralRequest.referralId", referralId);
      setValue(
        "referralRequest.patientEligibilityId",
        (response && response.patientEligibilityId) || 0
      );
    }
    if (currentTab === 1) {
      const id = Number(reAuthId);
      setValue("userCreated", userName);
      setValue("id", id);
      setValue("typeId", DocumentFlowType.ReAuth);
    }
    if (currentTab === 2) {
      setValue("userCreated", userName);
      setValue("userUpdated", userName);
      setValue("serviceRequestId", reAuthId);
      setValue("referralId", referralId);
    }
    if (currentTab == 3) {
      setValue("serviceRequestId", reAuthId);
      setValue("referralId", referralId);
      setValue("userCreated", userName);
    }
    if (currentTab == 4) {
      setValue("userCreated", userName);
      setValue("id", reAuthId);
      setValue("type", DocumentFlowType.ReAuth);
    }
  });

  useEffect(() => {
    if (
      patientRequestData &&
      patientRequestData.response &&
      patientRequestData.response.referralRequest &&
      patientRequestData.response.referralRequest.serviceRequestId != 0
    ) {
      setReferralId(
        patientRequestData.response.referralRequest.referralId.toString()
      );
      Number(reAuthId) > 0
        ? setReAuthId(reAuthId)
        : setReAuthId(
            patientRequestData.response.referralRequest.serviceRequestId
          );
      navigate(
        ModuleLinks(PatientRecordsModule.REAUTH_INTAKE, {
          reAuthId:
            patientRequestData.response.referralRequest.serviceRequestId,
          referralId: referralId,
        }),
        { replace: true }
      );
      reset(patientRequestData.response);
      setPatientRequestDetail(patientRequestData.response);
    }
  }, [patientRequestData]);

  const submitReferral = () => {
    getReauthReferralSubmit(localReAuthId.toString(), {
      userCreated: getValue(user, "email", ""),
      userId: getValue(user, "userId", null),
    });
  };

  const saveAndExit = (data: any) => {
    setSaveDetailsClickType(2);
    SubmitForm(data);
    navigate(
      ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
        Id: localReferralId,
      })
    );
  };

  const submitAndCreate = () => {
    setSaveDetailsClickType(2);
    submitReferral();
  };

  const saveDetails = (data: any) => {
    setSaveDetailsClickType(1);
    SubmitForm(data);
  };

  const SubmitForm = (data: any) => {
    setMinimumDate("");
    switch (currentTab) {
      case 0:
        if (
          patientRequestResponse &&
          patientRequestResponse.referralRequest.serviceRequestId != 0
        ) {
          const updatedResponse = {
            ...data,
            requester: {
              ...data.requester,
              requesterState: matchState(
                getConcatStringOrNull(data.requester.requesterState)
              ),
            },
          };
          updateReauthReferralDetail(updatedResponse, currentTab);
        } else {
          const updatedResponse = {
            ...data,
            requester: {
              ...data.requester,
              requesterState: matchState(
                getConcatStringOrNull(data.requester.requesterState)
              ),
            },
          };
          saveReauthReferralDetail(updatedResponse, currentTab);
        }
        break;
      case 1:
        if (Icd10RecordsResponse && Icd10RecordsResponse.id != 0) {
          updateReauthReferralDetail(data, currentTab);
        } else {
          saveReauthReferralDetail(data, currentTab);
        }
        break;
      case 2:
        {
          const followingPhysicianAddress = {
            streetName1: getValue(data, "streetName1") || null,
            streetName2: getValue(data, "streetName2") || null,
            county: getValue(data, "county") || null,
            city: getValue(data, "city") || null,
            state: getValue(data, "state") || null,
            zipCode: getValue(data, "zipCode") || null,
            physicianState: getValue(data, "physicianState") || null,
          };
          const followingPhysician = {
            ...data,
            ...followingPhysicianAddress,
          };

          if (
            followingPhysicianDetailsResp &&
            followingPhysicianDetailsResp.serviceRequestId != 0
          ) {
            updateReauthReferralDetail(followingPhysician, currentTab);
          } else {
            saveReauthReferralDetail(followingPhysician, currentTab);
          }
        }
        break;
      case 3:
        if (
          reAuthServiceRequestResponse &&
          reAuthServiceRequestResponse.serviceRequestId != -1
        ) {
          data.startOfCareVerified = true;
          data.startOfCareDate = socDate;
          updateReauthReferralDetail(data, currentTab);
        } else {
          data.startOfCareVerified = true;
          data.startOfCareDate = socDate;
          saveReauthReferralDetail(data, currentTab);
        }
        break;
      case 4:
        if (documentResponse && documentResponse.id != 0) {
          updateReauthReferralDetail(data, currentTab);
        } else {
          saveReauthReferralDetail(data, currentTab);
        }
        break;
      case 5:
        submitReferral();
        break;
      default:
        break;
    }
  };

  const FormSumitErrorCheck = (content: any) => {
    if (
      (content &&
        content.error &&
        Object.keys(content.error).length > 0 &&
        content.error.status == 422) ||
      (content &&
        content.error &&
        Object.keys(content.error).length > 0 &&
        content.error.status == 400)
    ) {
      setHasFormError(true);
    } else {
      setHasFormError(false);
    }
    setFormError(content);
  };

  useEffect(() => {
    switch (currentTab) {
      case 0:
        FormSumitErrorCheck(patientRequestData);
        break;
      case 1:
        FormSumitErrorCheck(icd10Records);
        break;
      case 2:
        FormSumitErrorCheck(followingPhysicianDetailsState);
        break;
      case 3:
        FormSumitErrorCheck(reAuthServiceRequestData);
        break;
      case 4:
        FormSumitErrorCheck(referralDocumentUpdateData);
        break;
      default:
        break;
    }
  }, [
    patientRequestData,
    icd10Records,
    followingPhysicianDetailsState,
    reAuthServiceRequestData,
    referralDocumentUpdateData,
  ]);

  const {
    fields: icd10RecordsFields,
    append: icd10RecordsAppend,
    remove: icd10RecordsRemove,
  } = useFieldArray({
    control,
    name: "icdCodesInfoDto",
    rules: currentTab === 1 ? rules.icdCodes : {},
  });

  const handleCloseAlert = () => {
    setIsCompleted(true);
    if (saveDetailsClickType === 2) {
      navigate(
        ModuleLinks(PatientRecordsModule.REAUTH_PATIENT_INFORMATION_VIEW, {
          patientId: response && response.patientId ? response.patientId : 0,
        })
      );
    }
  };

  const handleBackClick = () => {
    setOpenAlert(false);
    setBackClick(false);
    navigate(
      ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
        Id: referralId,
      })
    );
  };

  const handleBackStep = () => {
    setOpenAlert(true);
    setBackClick(true);
  };

  return (
    <>
      <Breadcrumb
        type={IntakeType.RE_AUTHORIZATION_DETAILS}
        referralId={Number(referralId)}
        patientId={response && response.patientId ? response.patientId : null}
        currentTab={currentTab}
      />
      <Grid sx={ReferralGrid}>
        <Box sx={displayFlex}>
          <Grid sx={flexAlignCentre}>
            <Button
              variant="contained"
              onClick={() => handleBackStep()}
              sx={BackButton}
            >
              BACK
            </Button>
            <Typography
              variant="h3"
              fontWeight={fontWeight.Weight[6]}
              color={colors.black[2]}
              ml={"1.5rem"}
              flex={"none"}
            >
              REFERRAL INTAKE
            </Typography>
            <Divider orientation="vertical" flexItem sx={DividerLine}></Divider>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[2]}
              color={colors.fonts[25]}
              flex={"none"}
            >
              Add referral details with respect to personal, source and address
              details below:
            </Typography>
          </Grid>
          <Grid sx={flexAllEnd} ml={"auto"}>
            <Permission
              controlId={`${PatientRecordsAccess.RE_AUTH_SAVE_AND_EXIT_CREATE}||`}
            >
              <Button variant="contained" onClick={handleSubmit(saveAndExit)}>
                SAVE AND EXIT
              </Button>
            </Permission>
            <Permission
              controlId={`${PatientRecordsAccess.RE_AUTH_SAVE_DETAILS_CREATE}||`}
            >
              <Button variant="outlined" onClick={handleSubmit(saveDetails)}>
                SAVE DETAILS
              </Button>
            </Permission>
          </Grid>
        </Box>
        <DetailBox>
          <PatientHeader patientData={patientHeaderData} />
          <ReferralTab
            clearErrors={clearErrors}
            control={control}
            currentTab={currentTab}
            followingPhysicianDetailsResp={followingPhysicianDetailsResp}
            followingPhysicianResp={followingPhysicianResp}
            formError={formError}
            formState={formState}
            getReAuthServiceRequestData={getReAuthServiceRequestData}
            getValues={getValues}
            handleSubmit={handleSubmit(saveDetails)}
            icd10RecordsAppend={icd10RecordsAppend}
            icd10RecordsFields={icd10RecordsFields}
            icd10RecordsRemove={icd10RecordsRemove}
            icd10Response={icd10Response}
            icdvalues={values}
            patientRequestDetail={patientRequestDetail}
            physicianDetailsResp={physicianDetailsResp}
            reAuthServiceRequest={reAuthServiceRequestData}
            referralId={localReferralId}
            reset={reset}
            resetField={resetField}
            setCurrentTab={setCurrentTab}
            setSocDate={setSocDate}
            setValue={setValue}
            socDate={socDate}
            submitReferral={submitAndCreate}
            values={values}
            watch={watch}
            trigger={trigger}
            minimumDate={minimumDate}
            resetPhysicianRecords={resetPhysicianRecords}
            documentType={documentType || []}
          />
        </DetailBox>
      </Grid>
      {isCompleted != null &&
        !isCompleted &&
        saveDetailsClickType == 2 &&
        showErrorDialog && (
          <IncompleteDialog
            referralStage={
              referralSubmitResponse &&
              referralSubmitResponse.referralStage &&
              referralSubmitResponse.referralStage.length > 0
                ? referralSubmitResponse.referralStage
                : []
            }
            currentTab={currentTab}
            handleCloseAlert={handleCloseAlert}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
            tabList={tabList}
          />
        )}
      {hasFormError && (
        <ErrorDialog
          formError={formError}
          hasFormError={hasFormError}
          setHasFormError={setHasFormError}
        />
      )}
      <AlertDialog
        alertMessage={BackClickAlertMessage}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        back={backClick}
        handleBackClick={handleBackClick}
      />
    </>
  );
};

export default ReauthReferralIntake;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AppConfigs } from "../../models/AppConfigs";
import { AppConfigsActionDispatchTypes } from "../../constants/AppConfigs";
import { ApplicationState } from "../../store/ApplicationState";
import { getConfigs } from "../../actions/AppConfigs";
import App from "../components";
import { getAccessControlId, saveRouteUrlInfo } from "../../actions/User";
import { RouteUrlInfo } from "../../models/User";

const mapStateToProps = ({
  appConfigsState,
  userModuleState,
  UserPermissionState,
  RouteUrlInfoState,
  permissionState
}: ApplicationState) => ({
  config: appConfigsState.response as AppConfigs,
  moduleData: userModuleState,
  userPermission: UserPermissionState.response,
  routeUrlInfo: RouteUrlInfoState.response,
  permissionState: permissionState.response
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AppConfigsActionDispatchTypes>
  >
) => {
  return {
    getConfig: (configFileName: string) => {
      dispatch(getConfigs(configFileName));
    },
    saveRouteUrlInfo: (routeUrlInfo: RouteUrlInfo) => {
      dispatch(saveRouteUrlInfo(routeUrlInfo));
    },
    getAccessControlId: (controlIds: string[]) => {
      dispatch(getAccessControlId(controlIds));
    }, 
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

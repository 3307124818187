import {
  Box,
  Button,
  Divider,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Control } from "react-hook-form";
import { FaRegTrashAlt } from "react-icons/fa";

import { FormInputDropdown } from "../../formComponents/FormInputDropdown";
import { PhoneFaxExtInput } from "../../formComponents/PhoneFaxExtInput";
import { displayFlex } from "../../../styles/mui/styles/display";
import { colors, others } from "../../../styles/colors";
import fontWeight from "../../../styles/mui/fontWeight";
import {
  AddButton,
  ContactDetails,
  EmergencyContactTableStyle,
  NameWidth,
  RelationshipWidth,
  deleteIcon,
  RelationshipDetails,
  emergencyContactErrorMsg,
} from "../../../routes/AllPatientRecords/routes/ReferralIntake/styles/requestStyle";
import {
  EmergencyContactTableHeadStyle,
  TableBodyStyle,
} from "../../../routes/AllPatientRecords/routes/ReferralIntake/styles/style";
import { rules } from "../../../utils/validation/Validation";
import { EmergencyContact, RelationshipMaster } from "../../../models/Patient";
import { EmergencyContactTypes } from "../../../constants/Constants";
import { FormInputText } from "../../formComponents/FormInputText";
export interface EmergencyContactProps {
  emergencyContactValues: EmergencyContact[];
  control: Control;
  fields: any[];
  relationshipList: RelationshipMaster[];
  handleRemove: (index: any) => void;
  append: any;
  contactType: string;
}

type AllProps = EmergencyContactProps;

const EmergencyContactData: React.FC<AllProps> = ({
  control,
  fields,
  relationshipList,
  handleRemove,
  append,
  contactType,
}: AllProps) => {
  return (
    <>
      <Box
        sx={displayFlex}
        pb={
          contactType === EmergencyContactTypes.ALTERNATE_CONTACT
            ? "0.5rem"
            : "0"
        }
      >
        <Box>
          <Typography
            variant="subtitle2"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            mb={"0.5rem"}
          >
            Emergency Contact Numbers
          </Typography>

          <Typography
            variant="body1"
            color={colors.fonts[5]}
            fontWeight={fontWeight.Weight[2]}
          >
            Please add emergency contact phone numbers, family member phone
            numbers.
          </Typography>
        </Box>
        <Button
          disabled={fields.length > 2}
          sx={AddButton}
          variant="contained"
          onClick={() =>
            append({
              contactName: "",
              relationId: null,
              phoneNo: "",
              extension: "",
            })
          }
        >
          <Typography
            variant="body1"
            fontWeight={fontWeight.Weight[4]}
            color={others.otherColors[33]}
          >
            ADD
          </Typography>
        </Button>
      </Box>
      {contactType === EmergencyContactTypes.REQUEST_EMERGENCY_CONTACT && (
        <Divider orientation="horizontal" flexItem sx={{ mt: "1rem" }} />
      )}
      <TableContainer sx={EmergencyContactTableStyle}>
        <Table>
          <TableHead sx={EmergencyContactTableHeadStyle}>
            <TableRow>
              <TableCell sx={NameWidth}>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[4]}
                  pl={"0.2rem"}
                >
                  Name:
                </Typography>
              </TableCell>
              <TableCell sx={RelationshipWidth}>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[4]}
                >
                  Relationship:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[4]}
                >
                  Phone Number:
                </Typography>
              </TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={
              contactType === EmergencyContactTypes.ALTERNATE_CONTACT
                ? TableBodyStyle
                : NameWidth
            }
          >
            {fields &&
              fields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell sx={NameWidth}>
                    <FormControl variant="standard" fullWidth>
                      <FormInputText
                        name={`emergencyContact.${index}.contactName`}
                        control={control}
                        textLength={30}
                        helper={rules.emergencyContactName}
                        InputStyle={emergencyContactErrorMsg}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={RelationshipDetails}>
                    <FormControl variant="standard" fullWidth>
                      <FormInputDropdown
                        control={control}
                        name={`emergencyContact.${index}.relationId`}
                        list={relationshipList}
                        helper={rules.relationshipName}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={ContactDetails}>
                    <PhoneFaxExtInput
                      control={control}
                      phoneFaxName={`emergencyContact.${index}.phoneNo`}
                      extName={`emergencyContact.${index}.extension`}
                      rules={rules.phone}
                    />
                  </TableCell>
                  <TableCell>
                    <FaRegTrashAlt
                      onClick={() => handleRemove(index)}
                      size={"1rem"}
                      style={deleteIcon}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EmergencyContactData;

import { Link, NavLink } from "react-router-dom";
import { Box, styled } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { accent, colors, others } from "../../styles/colors";
import fontWeight from "../../styles/mui/fontWeight";

export const HeaderToolbar: any = styled(Toolbar)((props) => ({
  minHeight: "5.1rem",
  display: "flex",
  justifyContent: "space-between",
  [props.theme.breakpoints.up("sm")]: {
    minHeight: "5.1rem",
  },
}));

export const LogoLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const LogoImg = styled("img")(() => ({
  width: "13rem",
  height: "2.5rem",
}));

export const HeaderArrowDropDownIcon: any = styled(ArrowDropDownIcon)(() => ({
  fontSize: "1.85rem",
  marginTop: "0.3rem",
  color: colors.fonts[26],
}));

export const HeaderActionBorder = styled(Box)(() => ({
  borderRight: `2px solid ${colors.dropShadow[9]}`,
  height: "1.75rem",
  margin: ".6rem",
}));

export const HeaderHelpOutlineOutlinedIcon: any = styled(
  HelpOutlineOutlinedIcon
)(() => ({
  height: "1.25rem",
  width: "1.25rem",
  display: "flex",
  color: colors.fonts[26],
}));

export const MenuToolbar: any = styled(Toolbar)((props) => ({
  padding: "0 1.5rem 0 1.5rem",
  backgroundColor: others.HeaderMenu[2],
  minHeight: "3.9rem",
  boxShadow: `0px 2px 6px ${colors.dropShadow[3]}`,
  [props.theme.breakpoints.up("sm")]: {
    minHeight: "4.2rem",
  },
}));

export const StickyHeader = () => ({
  position: "fixed",
  top: "0px",
  width: "100%",
  opacity: "1",
  zIndex: "10",
});

export const MenuBoxMax = styled(Box)((props) => ({
  maxWidth: "none",
  width: "100%",
  [props.theme.breakpoints.up(1920)]: {
    maxWidth: "1775px",
  },
}));

export const MenuTab: any = styled(NavLink)((props) => ({
  cursor: "pointer",
  width: "12%",
  minWidth: "9.5rem",
  maxWidth: "9.5rem",
  margin: "0.15rem .25rem",
  padding: "0.4rem 0.7rem",
  border: "none",
  borderRadius: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
  color:
    props.color == "primary" ? colors.fonts[18] : colors.backgroundColor[2],
  letterSpacing: 0,
  backgroundColor:
    props.color == "primary" ? colors.backgroundColor[2] : "none",
  "&:hover": {
    color: colors.fonts[18],
    backgroundColor: colors.backgroundColor[2],
    outline: `0px solid ${accent.accentOcean[100]}`,
    "&> .MuiTypography-root.MuiTypography-h5": {
      fontWeight: fontWeight.Weight[4],
    },
  },
  "&.Mui-selected": {
    backgroundColor: colors.backgroundColor[2],
    color: colors.fonts[18],
  },
  "&.Mui-disabled": {
    opacity: "0.5",
    cursor: "not-allowed",
  },
}));

export const MenuExpandMoreOutlinedIcon: any = styled(ExpandMoreOutlinedIcon)(
  () => ({
    fontSize: "1.2rem",
    marginLeft: "0.25rem",
  })
);

export const MenuBox = styled(Box)((props) => ({
  display: "none",
  gap: "3rem",
  paddingLeft: "1rem",
  [props.theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const MenusBoxHamburger = styled(Box)((props) => ({
  display: "flex",
  [props.theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const HamburgerMenuIcon: any = styled(MenuIcon)(() => ({
  width: "1.85rem",
  height: "1.85rem",
  color: colors.backgroundColor[2],
}));

export const HeaderUserDetails = () => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  gap: "0.3rem",
});

export const HeaderCustomAvatarGrid = () => ({
  display: "flex",
  alignItems: "center",
});

export const HeaderAvatarGridContainer = () => ({
  justifyContent: "flex-end",
  height: "100%",
});

export const DropdownMenu = () => ({
  position: "relative",
  display: "inline-block",
  "&:hover": {
    div: {
      display: "block",
    },
  },
  a: {
    width: "auto",
  },
});

export const DropdownContent = () => ({
  display: "none",
  background: others.otherColors[33],
  minWidth: "max-content",
  position: "absolute",
  zIndex: 1,
  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: "0.5rem",
  a: {
    display: "block",
    textDecoration: "none",
    padding: "1rem 2rem 1rem 1rem",
    width: "100%",
    cursor: "pointer",
  },
});

export const BackdropSection = () => ({
  zIndex: 20,
});

export const ProductionBannerMessage = () => ({
  justifyContent: "center",
  alignItems:"center",
  display:"flex"
});
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaFax, FaPhone, FaUserMd, FaUserNurse } from "react-icons/fa";

import { Review } from "../../../../../../../models/Patient";
import { ReviewResponse } from "../../../../../../../models/Api/Review";
import colors from "../../../../../../../styles/colors/colors";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  Icd10CodeLeftColumnGrid,
  Icd10CodeLeftColumnHeadingGrid,
  Icd10CodeLeftColumnTableGrid,
  Icd10CodeLeftColumnTableHead,
  Icd10CodeMdIcon,
  Icd10CodeOutterGrid,
  Icd10CodeRightColumnGrid,
  Icd10CodeRightOrderingPhysicianGrid,
  Icd10CodeRightOrderingPhysicianHeadingGrid,
  Icd10CodeRightPrimaryPhysicianGrid,
  Icd10CodeRightPrimaryPhysicianHeadingGrid,
  Icd10CodeServiceProviderFeildsGrid,
  Icd10CodeServiceProviderHeadingGrid,
  Icd10CodeTableBody,
} from "../styles/ICD10styles";
import {
  DividerStyle,
  Extention,
  FlexStyle,
  RequestDetailsFeildDescription,
  RequestDetailsFeildDescriptionIcon,
  RequestDetailsFeildsGrid,
} from "../styles/styles";
import { getFormattedPhoneNo, getValue } from "../../../../../../../utils";
import { ReviewDetails } from "../../../../../../../constants/Review";
import { BreakWord } from "../../../../../../../styles/mui/styles/display";

export interface PropsFromState {
  icd10code: ReviewResponse;
}

type ReviewIcd10Props = PropsFromState;
interface Props {
  Description: string | number | boolean;
  extension?: string;
  gridSize: number;
  heading: string;
  Icon?: React.ElementType;
  isExtention?: boolean | undefined;
  isIcon?: any;
  isbold?: boolean;
}
const ReviewFeilds: React.FC<Props> = ({
  Description,
  extension,
  gridSize,
  heading,
  Icon,
  isExtention,
  isIcon,
  isbold,
}: Props) => {
  return (
    <Grid item xs={gridSize} sx={RequestDetailsFeildsGrid}>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
      >
        {heading}
      </Typography>
      <Box sx={RequestDetailsFeildDescription(isIcon)}>
        <Box sx={RequestDetailsFeildDescriptionIcon(isIcon)}>
          {Description && Icon && <Icon size={16} />}
        </Box>
        <Box sx={FlexStyle}>
          <Typography
            variant={
              (heading === ReviewDetails.PHYSICIAN_NAME ||
                heading === ReviewDetails.NAME ||
                heading === ReviewDetails.NPI ||
                heading === ReviewDetails.TAX_ID) &&
                isbold
                ? "h6"
                : "subtitle2"
            }
            fontWeight={
              heading === ReviewDetails.NPI || heading === ReviewDetails.ADDRESS
                ? fontWeight.Weight[3]
                : fontWeight.Weight[5]
            }
            color={colors.fonts[25]}
          >
            {Description}
          </Typography>
          {extension && (
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[25]}
              sx={Extention(isExtention)}
            >
              ext.{extension}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const getDescription = (object: any, entity: any) => {
  return object && entity ? entity : "";
};

const ReviewIcd10: React.FC<ReviewIcd10Props> = ({
  icd10code,
}: ReviewIcd10Props) => {
  const [reviewResponse, setreviewResponse] = useState<Review | undefined>(
    icd10code?.response
  );
  const { response } = icd10code;
  const orderingPhysician = reviewResponse && reviewResponse.orderingPhysician;
  const primaryPhysician = reviewResponse && reviewResponse.primaryPhysician;
  const serviceProvider = reviewResponse && reviewResponse.serviceProvider;

  useEffect(() => {
    if (response) {
      setreviewResponse(response);
    }
  }, [response]);

  return (
    <Grid container sx={Icd10CodeOutterGrid}>
      <Grid item xs={6} sx={Icd10CodeLeftColumnGrid}>
        <Grid item xs={12} sx={Icd10CodeLeftColumnHeadingGrid}>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[20]}
          >
            ICD 10 CODE:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={DividerStyle} light />
        </Grid>
        <Grid item xs={12} sx={Icd10CodeLeftColumnTableGrid}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={Icd10CodeLeftColumnTableHead}>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[4]}
                    >
                      Diagnosis Code
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={Icd10CodeTableBody}>
                {reviewResponse &&
                  reviewResponse.icdCode.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.fonts[4]}
                        >
                          {data.diagnosisCode +
                            " - " +
                            data.diagnosisCodeDescription}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={6} sx={Icd10CodeRightColumnGrid}>
        <Grid item xs={12} sx={Icd10CodeRightPrimaryPhysicianGrid}>
          <Grid container sx={Icd10CodeRightPrimaryPhysicianHeadingGrid}>
            <Box sx={Icd10CodeMdIcon}>
              <FaUserMd color={colors.fonts[18]} size="22" />
            </Box>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              PRIMARY PHYSICIAN DETAILS:
            </Typography>
          </Grid>
          <Grid container>
            <ReviewFeilds
              heading="Physician Name:"
              Description={getDescription(
                reviewResponse,
                primaryPhysician && primaryPhysician.physicianName
              )}
              gridSize={3}
              isbold={true}
            />
            <ReviewFeilds
              heading="NPI:"
              Description={getDescription(
                reviewResponse,
                primaryPhysician && primaryPhysician.physicianNpiId
              )}
              gridSize={4}
              isbold={true}
            />
            <ReviewFeilds
              heading="Address:"
              Description={getDescription(
                reviewResponse,
                primaryPhysician && primaryPhysician.physicianAddress
              )}
              gridSize={5}
            />
            <ReviewFeilds
              heading="Tax ID:"
              Description={getDescription(
                reviewResponse,
                primaryPhysician && primaryPhysician.taxId
              )}
              gridSize={3}
              isbold={true}
            />
            <ReviewFeilds
              heading="Phone:"
              Description={getFormattedPhoneNo(
                getValue(reviewResponse, "primaryPhysician.phoneNo")
              )}
              gridSize={4}
              Icon={FaPhone}
              isIcon={true}
              isExtention={true}
              extension={getDescription(
                reviewResponse,
                primaryPhysician && primaryPhysician.phoneExt
              )}
            />
            <ReviewFeilds
              heading="Fax No:"
              Description={getFormattedPhoneNo(
                getValue(reviewResponse, "primaryPhysician.fax")
              )}
              gridSize={3}
              Icon={FaFax}
              isIcon={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={Icd10CodeRightOrderingPhysicianGrid}>
          <Grid container sx={Icd10CodeRightOrderingPhysicianHeadingGrid}>
            <Box sx={Icd10CodeMdIcon}>
              <FaUserMd color={colors.fonts[18]} size="22" />
            </Box>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              ORDERING PHYSICIAN DETAILS:
            </Typography>
          </Grid>
          <Grid container sx={BreakWord}>
            <ReviewFeilds
              heading="Physician Name:"
              Description={getDescription(
                reviewResponse,
                orderingPhysician && orderingPhysician.physicianName
              )}
              gridSize={3}
              isbold={true}
            />
            <ReviewFeilds
              heading="NPI:"
              Description={getDescription(
                reviewResponse,
                orderingPhysician && orderingPhysician.physicianNpiId
              )}
              gridSize={4}
              isbold={true}
            />
            <ReviewFeilds
              heading="Address:"
              Description={getDescription(
                reviewResponse,
                orderingPhysician && orderingPhysician.physicianAddress
              )}
              gridSize={5}
            />
            <ReviewFeilds
              heading="Tax ID:"
              Description={getDescription(
                reviewResponse,
                orderingPhysician && orderingPhysician.taxId
              )}
              gridSize={3}
              isbold={true}
            />
            <ReviewFeilds
              heading="Phone:"
              Description={getFormattedPhoneNo(
                getValue(reviewResponse, "orderingPhysician.phoneNo")
              )}
              gridSize={4}
              Icon={FaPhone}
              isIcon={true}
              isExtention={true}
              extension={getDescription(
                reviewResponse,
                orderingPhysician && orderingPhysician.phoneExt
              )}
            />
            <ReviewFeilds
              heading="Fax No:"
              Description={getFormattedPhoneNo(
                getValue(reviewResponse, "orderingPhysician.fax")
              )}
              gridSize={3}
              Icon={FaFax}
              isIcon={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={Icd10CodeServiceProviderHeadingGrid}>
          <Box sx={Icd10CodeMdIcon}>
            <FaUserNurse color={colors.fonts[18]} size="22" />
          </Box>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[20]}
          >
            SERVICE PROVIDER DETAILS:
          </Typography>
        </Grid>
        <Grid sx={Icd10CodeServiceProviderFeildsGrid}>
          <ReviewFeilds
            heading="Name:"
            Description={getDescription(
              reviewResponse,
              serviceProvider && serviceProvider.providerName
            )}
            gridSize={2.5}
            isbold={true}
          />
          <ReviewFeilds
            heading="NPI:"
            Description={getDescription(
              reviewResponse,
              serviceProvider && serviceProvider.providerNpi
            )}
            gridSize={1.5}
          />
          <ReviewFeilds
            heading="Address:"
            Description={getDescription(
              reviewResponse,
              serviceProvider && serviceProvider.providerAddress
            )}
            gridSize={3}
          />
          <ReviewFeilds
            heading="Payment Structure:"
            Description={getDescription(
              reviewResponse,
              serviceProvider && serviceProvider.paymentStructure
            )}
            gridSize={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewIcd10;

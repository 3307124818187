import { Reducer } from "redux";
import { RouteUrlInfoActionDispatchTypes } from "../constants/User";
import { RouteUrlInfoResponse } from "../models/Api/User";
import { RouteUrlInfoDispatchTypes } from "../models/DispatchTypes/User";

const initialState: RouteUrlInfoResponse = {
  loading: true,
  response: {
    routeUrl: "",
    moduleId: 0,
    subModuleId: 0,
  },
};

const RouteUrlReducer: Reducer<
  RouteUrlInfoResponse,
  RouteUrlInfoDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RouteUrlInfoActionDispatchTypes.ROUTE_URL_INFO_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RouteUrlInfoActionDispatchTypes.ROUTE_URL_INFO_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default RouteUrlReducer;

import API from "./api";
import {
  CreateUpdatePhysicianDetails,
  NppesPayloadData,
} from "../models/PhysicianDetails";
import { GetQueryString } from "../utils";

export const GetPhysicianRecord = (configUrl: any, suffix: string) =>
  API.get(`${configUrl}api/physician/${suffix}`);

export const GetReferralPhysicianRecord = (configUrl: any, suffix: number) =>
  API.get(`${configUrl}api/physician/${suffix}`);

export const CreateUpdatePhysicianRecord = (
  configUrl: any,
  data: CreateUpdatePhysicianDetails
) => API.post(`${configUrl}api/physician`, data);

export const UpdatePhysicianRecord = (
  configUrl: any,
  data: CreateUpdatePhysicianDetails,
  suffix: number
) => API.post(`${configUrl}api/physician/${suffix}`, data);

export const GetPhysicianDetailsByReferralId = (
  configUrl: any,
  suffix: string
) => API.get(`${configUrl}api/physician/${suffix}`);

// ordering physician

export const GetPhysicianRecords = (configUrl: string, suffix: string) =>
  API.get(`${configUrl}api/physician${suffix}`);

export const PutPhysicianDetails = (
  configUrl: string,
  suffix: string,
  data: any
) => API.putApi(`${configUrl}api/physician${suffix}`, data);

export const PostPhysicianDetails = (configUrl: string, data: any) =>
  API.postApi(`${configUrl}api/physician`, data);

export const GetPhysicianDetails = (configUrl: string, suffix: string) =>
  API.get(`${configUrl}api/physician${suffix}`);

// Following physician

export const PutFollowingPhysicianDetailsCall = (
  configUrl: string,
  suffix: string,
  data: any
) => API.putApi(`${configUrl}api/physician/ReAuth/${suffix}`, data);

export const PostFollowingPhysicianDetailsCall = (
  configUrl: string,
  data: any
) => API.postApi(`${configUrl}api/physician/ReAuth`, data);

export const GetFollowingPhysicianDetailsCall = (
  configUrl: string,
  reAuthId: number
) => API.get(`${configUrl}api/physician/ReAuth/${reAuthId}`);

export const GetAuthOrderingPhysicianDetails = (
  configUrl: string,
  referralId: string
) => API.get(`${configUrl}api/physician/ordering?referralId=${referralId}`);

export const PutAuthOrderingPhysicianDetails = (
  configUrl: string,
  referralId: string,
  data: any
) =>
  API.putApi(
    `${configUrl}api/physician/orderingPhysician?referralId=${referralId}`,
    data
  );

export const GetAuthFollowingPhysicianDetails = (
  configUrl: string,
  referralId: string
) => API.get(`${configUrl}api/physician/following?referralId=${referralId}`);

export const PutAuthFollowingPhysicianDetails = (
  configUrl: string,
  referralId: string,
  data: any
) =>
  API.putApi(
    `${configUrl}api/physician/followingPhysician?referralId=${referralId}`,
    data
  );

export const GetNPPESDetailsData = (
  configUrl: string,
  payload: NppesPayloadData
) => API.get(`${configUrl}api/physician/details?${GetQueryString(payload)}`);

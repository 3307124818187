import { Dispatch, ActionCreator } from "redux";
import { isNil } from "lodash";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import { RoleDetailsActionDispatchTypes } from "../constants/Roles";
import { RoleDetailsDispatchTypes } from "../models/DispatchTypes/Roles";
import {
  DeleteRole,
  GetRoleDetail,
  PostRole,
  PutRole,
} from "../services/Roles";
import { configUrlForUserManagement } from "../constants/Config";
import { GetRoleManagement } from "../services/Roles";
import { MessageType, ResponseMessages } from "../constants/ToastMessage";
import { RoleDetailsAuth } from "../models/Roles";
import { RoleManagementActionDispatchTypes } from "../constants/Roles";
import { RoleManagementDispatchTypes } from "../models/DispatchTypes/Roles";
import SnackbarUtils from "../utils/SnackbarProvider";

export const getRoleDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleDetailsDispatchTypes
  >
> = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleDetailsActionDispatchTypes.ROLE_DETAILS_INVOKE,
    });

    const response = await GetRoleDetail(configUrlForUserManagement, userId);

    dispatch({
      response: response,
      type: RoleDetailsActionDispatchTypes.ROLE_DETAILS_SUCCESS,
    });
  };
};

export const putRoleDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleDetailsDispatchTypes
  >
> = (roleId: string, payload: RoleDetailsAuth) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleDetailsActionDispatchTypes.UPDATE_ROLE_INVOKE,
    });
    const response = await PutRole(configUrlForUserManagement, payload, roleId);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            type: RoleDetailsActionDispatchTypes.UPDATE_ROLE_ERROR,
            response,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: RoleDetailsActionDispatchTypes.UPDATE_ROLE_SUCCESS,
          });
          SnackbarUtils.success(ResponseMessages(MessageType.PUT_SUCCESS));
          break;
        default:
          break;
      }
    }
  };
};

export const postRole: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleDetailsDispatchTypes
  >
> = (payload: RoleDetailsAuth) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleDetailsActionDispatchTypes.POST_ROLE_INVOKE,
    });

    const response = await PostRole(configUrlForUserManagement, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            type: RoleDetailsActionDispatchTypes.POST_ROLE_ERROR,
            response,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: RoleDetailsActionDispatchTypes.POST_ROLE_SUCCESS,
          });
          SnackbarUtils.success(ResponseMessages(MessageType.POST_SUCCESS));
          break;
        default:
          break;
      }
    }
  };
};

export const roleDetailsReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleDetailsDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleDetailsActionDispatchTypes.ROLE_DETAILS_RESET,
    });
  };
};

export const updateRoleDetailsReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleDetailsDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleDetailsActionDispatchTypes.UPDATE_ROLE_RESET,
    });
  };
};

export const deleteRole: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleManagementDispatchTypes
  >
> = (roleId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleManagementActionDispatchTypes.DELETE_ROLE_INVOKE,
    });

    const response = await DeleteRole(
      configUrlForUserManagement,
      roleId.toString()
    );

    if (isNil(response)) {
      dispatch({
        response: true,
        type: RoleManagementActionDispatchTypes.DELETE_ROLE_ERROR,
      });
      SnackbarUtils.error(ResponseMessages(MessageType.REQUEST_FAILED));
    }

    dispatch({
      response: response,
      type: RoleManagementActionDispatchTypes.DELETE_ROLE_SUCCESS,
    });
    SnackbarUtils.success(ResponseMessages(MessageType.DELETE_SUCCESS));
  };
};

export const deleteRoleReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleManagementDispatchTypes
  >
> = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      response: false,
      type: RoleManagementActionDispatchTypes.DELETE_ROLE_RESET,
    });
  };
};

export const getRoleManagementList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RoleManagementDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RoleManagementActionDispatchTypes.ROLE_MANAGEMENT_INVOKE,
    });

    const response = await GetRoleManagement(configUrlForUserManagement);

    dispatch({
      response: response,
      type: RoleManagementActionDispatchTypes.ROLE_MANAGEMENT_SUCCESS,
    });
  };
};

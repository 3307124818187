import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import { getUserList } from "../../../actions/User";
import { User } from "../../../models/User";
import MyPatientFilter from "../components/MyPatientsDetails/Filter";
import {
  getCounty,
  getPatientState,
  getServiceProviderMaster,
} from "../../../actions/Service";
import { resetStates } from "../../../actions/Common";
import { getUrgencyStatusMasterResp } from "../../../actions/Patient";
import {
  getDisciplineTypeResp,
  getDisciplines,
  getHealthPlanMasterResp,
  getPriorAuthReferralSourceMasterResp,
  getReferralServiceStatusResp,
  getServiceStatusReasonResp,
  getStaffingStatusMasterResp,
} from "../../../actions/Master";
import { storeFilterPayload } from "../../../actions/Filter";
import { SearchFilterModel } from "../../../models/Filter";
import { DisciplineSearch } from "../../../models/Service";

const mapStateToProps = ({
  documentCreatedState,
  patientStateState,
  userListState,
  staffingStatusState,
  urgencyStatusState,
  referralServiceStatusState,
  disciplineTypeState,
  disciplinesState,
  healthPlanMasterState,
  filterState,
  serviceStatusReasonState,
  countyState,
  priorAuthReferralSourceState,
}: ApplicationState) => ({
  availableStaff: documentCreatedState,
  patientStateList: patientStateState.response,
  userList: userListState.response as User[],
  staffingStatus: staffingStatusState.response,
  urgencyStatus: urgencyStatusState.response,
  referralServiceStatus: referralServiceStatusState.response,
  serviceRequestType: disciplineTypeState.response,
  skill: disciplinesState.response,
  healthPlanMaster: healthPlanMasterState.response,
  filterState: filterState,
  serviceStatusReasonState: serviceStatusReasonState.response,
  countyList: countyState.response,
  priorAuthReferralSource: priorAuthReferralSourceState.response,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    getServiceProviderMaster: (selection: string) => {
      dispatch(getServiceProviderMaster(selection));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getPatientState: (selection: string) => {
      dispatch(getPatientState(selection));
    },
    getStaffList: () => {
      dispatch(getUserList());
    },
    getUrgencyStatusMasterResp: () => {
      dispatch(getUrgencyStatusMasterResp());
    },
    getStaffingStatusMasterResp: () => {
      dispatch(getStaffingStatusMasterResp());
    },
    getReferralServiceStatusMasterResp: (requiredIncompleteOption: boolean) => {
      dispatch(getReferralServiceStatusResp(requiredIncompleteOption));
    },
    getServiceStatusReasonMasterResp: (id: number) => {
      dispatch(getServiceStatusReasonResp(id));
    },
    getServiceRequestTypeMasterResp: () => {
      dispatch(getDisciplineTypeResp());
    },
    getSkillMasterResp: (payload: DisciplineSearch) => {
      dispatch(getDisciplines(payload));
    },
    getHealthPlanMasterResp: () => {
      dispatch(getHealthPlanMasterResp());
    },

    storeFilterPayload: (type: number, payload?: SearchFilterModel) => {
      dispatch(storeFilterPayload(type, payload));
    },
    getCounty: (selection: string) => {
      dispatch(getCounty(selection));
    },
    getPriorAuthReferralSourceFacility: () => {
      dispatch(getPriorAuthReferralSourceMasterResp());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyPatientFilter);

import { Reducer } from "redux";

import { GetPatientSelectedAuthStatusResponse } from "../models/Api/Authorization";
import { PatientSelectedAuthActionDispatchTypes } from "../constants/Authorization";
import { PatientSelectedAuthDispatchTypes } from "../models/DispatchTypes/Authorization";

const initialState: GetPatientSelectedAuthStatusResponse = {
  loading: false,
  response: {
    auth: 0,
    status: "",
    serviceProvider: 0,
    admitDate: "",
    dischargeDate: "",
    totalApproved: 0,
    sn: 0,
    pt: 0,
    ot: 0,
    st: 0,
    msw: 0,
    hha: 0,
  },
};

const PatientSelectedAuthStatusReducer: Reducer<
  GetPatientSelectedAuthStatusResponse,
  PatientSelectedAuthDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientSelectedAuthActionDispatchTypes.GET_PATIENT_SELECTED_AUTH_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientSelectedAuthActionDispatchTypes.GET_PATIENT_SELECTED_AUTH_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientSelectedAuthStatusReducer;

import { Box, Divider, Grid, Typography, Link, Tooltip } from "@mui/material";
import { FaRegCalendarCheck, FaRegFileAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import FileSaver from "file-saver";

import {
  BlobDocumentContainer,
  blobDocumentGrid,
  DocumentationDate,
  DocumentationDateContainer,
  DocumentationDateGrid,
  DocumentDataGrid,
  DocumentFieldGrid,
  DocumentIconBox,
  DocumentNameTypeBox,
  DocumentsGrid,
  FieldBox,
  FieldLabel,
  FileNameField,
  GridBody,
  GridContainer,
  GridLeftBody,
  HorizontalDivider,
  HorizontalDividerGrid,
  SupportingDocumentsData,
  SupportingDocumentsGrid,
  VerticalDivider,
} from "../styles/ReAuthReviewDocumentationDetails";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../../../styles/colors";
import {
  ReviewDocumentationDetailQuestions,
  SupportingDocumentsLabel,
} from "../../../../../../../constants/Review";
import { ReAuthReviewResponse } from "../../../../../../../models/Api/Review";
import { extractDocType, length } from "../../../../../../../utils";
import { formatDate } from "../../../../../../../utils/DateTime";
import {
  alignStart,
  displayFlex,
  flexAllStart,
} from "../../../../../../../styles/mui/styles/display";
import {
  ReAuthReviewBlobDoc,
  ReviewDocumentationDetail,
} from "../../../../../../../models/Document";
import { DateFormats } from "../../../../../../../constants/Date";
import { DocumentDownloadResponse } from "../../../../../../../models/Api/Document";
import { MessageType } from "../../../../../../../constants/ToastMessage";
import { NO_RECORD_FOUND } from "../../../../../../../constants/ToastMessage";
import { ReferralResponseMessages } from "../../../../../../../constants/AllPatientRecord";
import SnackbarUtils from "../../../../../../../utils/SnackbarProvider";
import { getDocTypeIcon } from "../../../../../../../utils/DocIcons";
import {
  DocumentName,
  DocumentNameUnderline,
} from "../../../../ReferralIntake/styles/DocumentReview";
import { Data } from "../../../../../../AdminCenter/routes/NoteManagement/styles/NotesList";

export interface PropsFromState {
  reAuthReviewDetails: ReAuthReviewResponse;
  documentDownloadData: DocumentDownloadResponse;
}
export interface PropsFromDispatch {
  documentDownload: (blobId: number) => void;
}

type ReAuthReviewDocumentationDetailsProps = PropsFromState & PropsFromDispatch;

const ReAuthReviewDocumentationDetails: React.FC<
  ReAuthReviewDocumentationDetailsProps
> = ({
  reAuthReviewDetails,
  documentDownload,
  documentDownloadData,
}: ReAuthReviewDocumentationDetailsProps) => {
  const [supportingDocs, setSupportingDocs] = useState<
    ReAuthReviewBlobDoc[] | undefined
  >();
  const [fileName, setFileName] = useState<string>("");
  const { response: documentDownloadResponse, error: documentDownloadError } =
    documentDownloadData;
  const [documents, setDocuments] = useState<
    ReviewDocumentationDetail | undefined
  >();
  const { response } = reAuthReviewDetails;

  const handleDocument = (blobId: number, fileName: string) => {
    documentDownload(blobId);
    setFileName(fileName);
  };

  useEffect(() => {
    if (documentDownloadResponse && documentDownloadResponse.blob) {
      const blob = new Blob([documentDownloadResponse.blob], {
        type: documentDownloadResponse.headers["content-type"],
      });
      FileSaver.saveAs(blob, fileName);
      setFileName("");
    } else if (
      documentDownloadError &&
      documentDownloadError.error &&
      Object.keys(documentDownloadError.error).length == 0
    ) {
      SnackbarUtils.error(
        ReferralResponseMessages(MessageType.DOCUMENT_DOWNLOAD_ERROR)
      );
    }
  }, [documentDownloadResponse, documentDownloadError]);

  useEffect(() => {
    if (response) {
      setSupportingDocs(response.reAuthBlobDocumentDetail);
      setDocuments(response.reAuthReferralDocuments);
    }
  }, [response]);

  return (
    <>
      <Grid container sx={GridContainer}>
        <Grid item xs={12} sx={GridBody}>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
          >
            DOCUMENTATION DETAILS:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light sx={HorizontalDivider} />
        </Grid>
        <Grid item xs={3.2} sx={GridLeftBody}>
          <Grid container>
            <Grid item xs={12} pt={"1.5rem"}>
              <Box sx={[flexAllStart, alignStart]}>
                <Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[4]}
                    >
                      {ReviewDocumentationDetailQuestions.SUPPORTING_DOCUMENTS}
                    </Typography>
                  </Box>
                  <Box pt="1rem">
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[6]}
                      color={colors.fonts[4]}
                      pl={"0.5rem"}
                    >
                      {documents?.containsSupportedDoc === true ? "YES" : "NO"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {documents?.containsSupportedDoc === true && (
              <>
                <Grid item xs={12} sx={SupportingDocumentsData}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.fonts[20]}
                  >
                    DOCUMENTS:
                  </Typography>
                </Grid>
                {documents?.documentName && length(documents.documentName) ? (
                  documents.documentName.map((documentName: string) => (
                    <Grid item xs={6} key={documentName}>
                      <Box sx={DocumentsGrid}>
                        <Box sx={DocumentIconBox}>
                          <FaRegFileAlt size={18} color={colors.primary.main} />
                        </Box>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.fonts[4]}
                          pt={"0.2rem"}
                        >
                          {documentName}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={6} pt="1rem" pl="1rem">
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[4]}
                      color={colors.fonts[4]}
                      pt={"0.2rem"}
                    >
                      {NO_RECORD_FOUND}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={DocumentationDateContainer}>
                  <Grid container>
                    <Grid item xs={5} pr={"1rem"}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[4]}
                      >
                        Date of most recent documentation:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={DocumentationDateGrid}>
                      <Box sx={displayFlex}>
                        <Box sx={DocumentIconBox}>
                          {documents.documentationDate && (
                            <FaRegCalendarCheck size={18} />
                          )}
                        </Box>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[4]}
                          color={colors.fonts[4]}
                          sx={DocumentationDate}
                        >
                          {documents?.documentationDate &&
                            formatDate(
                              documents?.documentationDate,
                              DateFormats.MM_DD_YYYY
                            )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={0.1} sx={VerticalDivider}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={8.7} sx={SupportingDocumentsGrid}>
          <Grid container>
            <Grid item xs={12}>
              <Box ml="1rem">
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[20]}
                >
                  SUPPORTING DOCUMENTS
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={BlobDocumentContainer}>
            {supportingDocs && length(supportingDocs) ? (
              supportingDocs.map((data: ReAuthReviewBlobDoc, index) => {
                const hideRightBorder = (index + 1) % 3 === 0;
                return (
                  <Grid
                    item
                    xs={4}
                    key={data.blobId}
                    sx={blobDocumentGrid(hideRightBorder)}
                  >
                    <Grid container>
                      <Grid item xs={1.9}>
                        <Box sx={DocumentIconBox}>
                          {getDocTypeIcon(extractDocType(data.mediaType))}
                        </Box>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          variant="h5"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.primary.main}
                          sx={FileNameField}
                        >
                          <Link
                            variant="h5"
                            fontWeight={fontWeight.Weight[4]}
                            color={colors.fonts[18]}
                            onClick={() =>
                              handleDocument(
                                data.blobId,
                                data.originalDocumentName
                              )
                            }
                            sx={DocumentNameUnderline}
                          >
                            <Tooltip
                              title={
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={fontWeight.Weight[3]}
                                  sx={Data}
                                >
                                  {data.originalDocumentName}
                                </Typography>
                              }
                              arrow
                            >
                              <Typography
                                variant="subtitle1"
                                fontWeight={fontWeight.Weight[5]}
                                color={colors.fonts[18]}
                                sx={DocumentName}
                              >
                                {data.originalDocumentName}
                              </Typography>
                            </Tooltip>
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={HorizontalDividerGrid}>
                        <Divider light sx={HorizontalDivider} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={DocumentFieldGrid}>
                      <Typography
                        variant="body1"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[2]}
                        sx={FieldLabel}
                      >
                        {SupportingDocumentsLabel.RECEIVED_ON}:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.fonts[20]}
                      >
                        {data.showDateTime &&
                          data.dateTimeReceived &&
                          formatDate(
                            data.dateTimeReceived,
                            DateFormats.MM_DD_YYYY
                          )}
                        &nbsp;&nbsp;
                        {data.showDateTime &&
                          data.dateTimeReceived &&
                          formatDate(
                            data.dateTimeReceived,
                            DateFormats.TIME_AM_PM
                          )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={DocumentDataGrid}>
                      <Box>
                        {fieldRender(
                          SupportingDocumentsLabel.SOURCE,
                          data.documentSourceName
                        )}
                      </Box>
                      <Box sx={DocumentNameTypeBox}>
                        {fieldRender(
                          SupportingDocumentsLabel.DOC_TYPE,
                          data.documentTypeName
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={DocumentFieldGrid}>
                      {fieldRender(
                        SupportingDocumentsLabel.CREATED_BY,
                        data.userFullName
                      )}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={4} sx={blobDocumentGrid(true)}>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[4]}
                  color={colors.fonts[4]}
                  pt={"0.2rem"}
                >
                  {NO_RECORD_FOUND}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const fieldRender = (label: string, value: string | number) => {
  return (
    <Box sx={FieldBox}>
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
          sx={FieldLabel}
        >
          {label}:
        </Typography>
      </Box>

      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[4]}
        color={colors.fonts[20]}
      >
        {value ? value : ""}
      </Typography>
    </Box>
  );
};

export default ReAuthReviewDocumentationDetails;

import { Reducer } from "redux";

import { PatientReferralInfoResponse } from "../models/Api/InformationReceived";
import { InformationReceivedDispatchTypes } from "../models/DispatchTypes/InformationReceived";
import { PatientReferralInfoActionDispatchTypes } from "../constants/InformationReceived";

export const initialState: PatientReferralInfoResponse = {
  loading: false,
  response: {
    refId: -1,
    fullName: "",
    dob: "",
    gender: "",
    address: "",
  },
};

const PatientReferralInfoReducer: Reducer<
  PatientReferralInfoResponse,
  InformationReceivedDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientReferralInfoActionDispatchTypes.REFERRAL_INFO_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientReferralInfoActionDispatchTypes.REFERRAL_INFO_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientReferralInfoReducer;

import { Reducer } from "redux";

import { PastMedicalHistoryResponse } from "../models/Api/Master";
import { PastMedicalHistoryActionDispatchTypes } from "../constants/Master";
import { PastMedicalHistoryDispatchTypes } from "../models/DispatchTypes/Master";

const initialState: PastMedicalHistoryResponse = {
  loading: false,
  response: [],
};

const PastMedicalHistoryReducer: Reducer<
  PastMedicalHistoryResponse,
  PastMedicalHistoryDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PastMedicalHistoryActionDispatchTypes.PAST_MEDICAL_HISTORY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PastMedicalHistoryActionDispatchTypes.PAST_MEDICAL_HISTORY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PastMedicalHistoryReducer;

import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { ReAuthReviewResponse } from "../../../../../../../models/Api/Review";
import {
  ReAuthReview,
  ReviewServiceDiscipline,
} from "../../../../../../../models/Patient";

import {
  DisciplineBodyTableRow,
  GridContainer,
  GridLeftBody,
  HorizontalDivider,
  ItemIconBox,
  RequestedVisitTablecell,
  ServiceRequestTableBody,
  ServiceRequestTableHead,
} from "../styles/ReAuthReviewServiceRequest";
import { accent, colors } from "../../../../../../../styles/colors";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  displayFlex,
  flexAllCentre,
} from "../../../../../../../styles/mui/styles/display";
import { DateFormats } from "../../../../../../../constants/Date";
import { formatDate } from "../../../../../../../utils/DateTime";
import { NO_RECORD_FOUND } from "../../../../../../../constants/ToastMessage";
import { ReviewDisciplineTableHeader } from "../../../../../../../constants/Review";

export interface PropsFromState {
  reAuthReviewDetails: ReAuthReviewResponse;
}

type ReAuthReviewServiceRequestProp = PropsFromState;

const ReAuthReviewServiceRequest: React.FC<ReAuthReviewServiceRequestProp> = ({
  reAuthReviewDetails,
}: ReAuthReviewServiceRequestProp) => {
  const [reviewDetails, setReviewDetails] = useState<
    ReAuthReview | undefined
  >();
  const { response } = reAuthReviewDetails;

  const reviewServiceRequests =
    reviewDetails && reviewDetails.reAuthServiceRequestDetails;

  useEffect(() => {
    if (response) {
      setReviewDetails(response);
    }
  }, [response]);

  const headerTableCell = (value: string | number) => {
    return (
      <>
        <TableCell>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
          >
            {value}
          </Typography>
        </TableCell>
      </>
    );
  };

  const dataTableCell = (
    value: string | number,
    fontWeight?: any,
    sx?: any
  ) => {
    return (
      <>
        <TableCell>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight}
            color={colors.fonts[4]}
            sx={sx}
          >
            {value ? value : ""}
          </Typography>
        </TableCell>
      </>
    );
  };

  return (
    <Grid container sx={GridContainer}>
      <Grid item xs={12} sx={GridLeftBody}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              SERVICE REQUESTS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light sx={HorizontalDivider} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={ServiceRequestTableHead}>
                  <TableRow>
                    {headerTableCell(ReviewDisciplineTableHeader.DISCIPLINES)}
                    {headerTableCell(
                      ReviewDisciplineTableHeader.REQUESTED_NUMBER_OF_VISITS
                    )}
                    {headerTableCell(
                      ReviewDisciplineTableHeader.PREVIOUSLY_REQUESTED
                    )}
                    {headerTableCell(
                      ReviewDisciplineTableHeader.CURRENT_AUTH_PERIOD
                    )}
                    {headerTableCell(
                      ReviewDisciplineTableHeader.NEXT_AUTH_PERIOD
                    )}
                  </TableRow>
                </TableHead>
                <TableBody sx={ServiceRequestTableBody}>
                  {reviewServiceRequests &&
                  Array.isArray(reviewServiceRequests.reAuthDiscipline) &&
                  reviewServiceRequests.reAuthDiscipline.length > 0 ? (
                    reviewServiceRequests.reAuthDiscipline.map(
                      (discipline: ReviewServiceDiscipline, index: number) => (
                        <TableRow key={index} sx={DisciplineBodyTableRow}>
                          <TableCell>
                            <Box sx={displayFlex}>
                              <Typography
                                variant="h6"
                                fontWeight={fontWeight.Weight[6]}
                                color={colors.fonts[4]}
                              >
                                {discipline.disciplineName && `${index + 1}) `}{" "}
                                &nbsp;
                              </Typography>
                              <Typography
                                variant="h6"
                                fontWeight={fontWeight.Weight[6]}
                                color={colors.fonts[4]}
                              >
                                {discipline.disciplineName}
                              </Typography>
                            </Box>
                          </TableCell>
                          {dataTableCell(
                            discipline.totalRequestedVisit,
                            fontWeight.Weight[6],
                            RequestedVisitTablecell
                          )}
                          <TableCell>
                            {reviewServiceRequests.previousRequested === "1" ? (
                              <Box sx={displayFlex} pl={"1rem"}>
                                <Box sx={ItemIconBox}>
                                  <FaCheck />
                                </Box>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight={fontWeight.Weight[6]}
                                  color={accent.accentGreen[100]}
                                >
                                  YES
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          {dataTableCell(
                            `${formatDate(
                              reviewServiceRequests.currentStartDate,
                              DateFormats.MM_DD_YYYY
                            )}-${formatDate(
                              reviewServiceRequests.currentEndDate,
                              DateFormats.MM_DD_YYYY
                            )}`,
                            fontWeight.Weight[3]
                          )}
                          {dataTableCell(
                            `${formatDate(
                              reviewServiceRequests.nextStartDate,
                              DateFormats.MM_DD_YYYY
                            )}-${formatDate(
                              reviewServiceRequests.nextEndDate,
                              DateFormats.MM_DD_YYYY
                            )}`,
                            fontWeight.Weight[3]
                          )}
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Box sx={flexAllCentre}>
                          <Box>
                            <Typography
                              variant="subtitle1"
                              fontWeight={fontWeight.Weight[4]}
                              color={colors.fonts[4]}
                            >
                              {NO_RECORD_FOUND}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReAuthReviewServiceRequest;

import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

export const ItemPagination = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "end",
}));

export const PaginationButton = (disabled: boolean) => ({
  width: "0.5rem",
  textDecoration: disabled ? "none" : "underline",
  color: colors.fonts[4],
  fontWeight: fontWeight.Weight[5],
  paddingTop: "0.2rem",
  fontSize: "0.7rem",
});

export const CustomPaginationButton = () => ({
  width: "0.3rem",
  textDecoration: "underline",
  color: colors.fonts[4],
  fontWeight: fontWeight.Weight[5],
  paddingTop: "0.2rem",
  fontSize: "0.75rem",
});

export const PageNumber = {
  width: "10rem",
  height: "10rem",
  borderRadius: "50%",
  textAlign: "center",
};

export const TablePaginationStyle = () => ({
  "& .MuiTablePagination-selectLabel": {
    color: colors.fonts[4],
    fontWeight: fontWeight.Weight[2],
    fontSize: "0.8rem",
  },
  "& .MuiTablePagination-displayedRows": {
    borderLeft: `2px solid ${colors.dropShadow[9]}`,
    paddingLeft: "0.8rem",
    color: colors.fonts[4],
    fontWeight: fontWeight.Weight[2],
    fontSize: "0.8rem",
  },
  "& .MuiInputBase-root": {
    marginRight: "0.5rem",
    marginLeft: 0,
  },
  "& .MuiTablePagination-spacer": {
    display: "none",
  },
});

export const ListPageNumber = () => ({
  paddingLeft: "1rem",
});

export const PaginationItemStyle = {
  flex: "1 1 100%",
};

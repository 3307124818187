import { Box, Breadcrumbs, Grid, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { BreadcrumbsRoot } from "../../styles/common/style";
import { colors } from "../../styles/colors";
import { displayFlex } from "../../styles/mui/styles/display";
import { ModuleLinks, PatientRecordsModule } from "../../routes/AllRoutes";
import fontWeight from "../../styles/mui/fontWeight";
import {
  BreadcrumbLeftGrid,
  BreadcrumbPaper,
  BreadcrumbRightGrid,
} from "../styles/styles";
import {
  BreadcrumbName,
  IntakeType,
  ReAuthIntakeLastTab,
  ReferralIntakeLastTab,
} from "../../constants/AllPatientRecord";
import AlertDialog from "../../routes/AllPatientRecords/routes/ReferralIntake/components/AlertDialog";
import { BackClickAlertMessage } from "../../constants/Patient";

interface Props {
  patientId?: number | null;
  referralId?: number;
  reset?: () => void;
  type?: string;
  authDetails?: boolean;
  currentTab?: number;
}

const Breadcrumb: React.FC<Props> = ({
  patientId,
  referralId,
  reset,
  type,
  authDetails,
  currentTab,
}: Props) => {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [backClick, setBackClick] = useState(false);
  const [link, setLink] = useState<any>();

  const navigatePatientRecord = () => {
    reset && reset();
    setAlertDetails(ModuleLinks(PatientRecordsModule.PATIENT_RECORD));
  };

  const navigatePatientDetail = () => {
    reset && reset();
    if (patientId) {
      setAlertDetails(
        ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
          patientId: patientId,
        })
      );
    }
  };

  const navigateAuthDetail = () => {
    reset && reset();
    if (referralId) {
      setAlertDetails(
        ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
          Id: referralId,
        })
      );
    }
  };

  const handleBackClick = () => {
    setOpenAlert(false);
    setBackClick(false);
    navigate(link);
  };

  const setAlertDetails = (value: any) => {
    if (
      (currentTab == ReferralIntakeLastTab &&
        type === IntakeType.REFERRAL_INTAKE) ||
      (currentTab == ReAuthIntakeLastTab &&
        type === IntakeType.RE_AUTHORIZATION_DETAILS)
    ) {
      navigate(value);
    } else {
      setOpenAlert(true);
      setBackClick(true);
      setLink(value);
    }
  };

  const breadcrumbLink = (
    key: number,
    onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined,
    breadcrumbName: string
  ) => {
    return (
      <Link
        key={key}
        color={colors.fonts[8]}
        variant="subtitle2"
        onClick={onClick}
        fontWeight={fontWeight.Weight[4]}
      >
        {breadcrumbName}
      </Link>
    );
  };

  const typographyBox = (key: number) => {
    let breadcrumbName = "";
    if (type === IntakeType.RE_AUTHORIZATION_DETAILS) {
      breadcrumbName = BreadcrumbName.RE_AUTHORIZATION_DETAILS;
    } else if (authDetails) {
      breadcrumbName = BreadcrumbName.AUTHORIZATION_DETAILS;
    } else {
      breadcrumbName = BreadcrumbName.REFERRAL_INTAKE;
    }

    return (
      <Typography
        key={key}
        variant="subtitle2"
        fontWeight={fontWeight.Weight[4]}
        color={colors.fonts[11]}
      >
        {breadcrumbName}
      </Typography>
    );
  };

  const breadcrumbs = [
    breadcrumbLink(1, navigatePatientRecord, BreadcrumbName.PATIENT_RECORDS),
    breadcrumbLink(2, navigatePatientDetail, BreadcrumbName.PATIENT_DETAILS),
    type === IntakeType.RE_AUTHORIZATION_DETAILS &&
      breadcrumbLink(
        2,
        navigateAuthDetail,
        BreadcrumbName.AUTHORIZATION_DETAILS
      ),
    typographyBox(3),
  ];

  return (
    <>
      <BreadcrumbPaper elevation={3}>
        <Grid container mt={"0.5rem"}>
          <BreadcrumbLeftGrid item xs={6}>
            <Breadcrumbs
              separator={
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[4]}
                  color={colors.black[4]}
                >
                  &nbsp;&gt;&nbsp;
                </Typography>
              }
              aria-label="breadcrumb"
              sx={BreadcrumbsRoot}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </BreadcrumbLeftGrid>
          <BreadcrumbRightGrid item xs={6}>
            <Box sx={displayFlex}>
              <Typography variant="body1" color={colors.red[100]}>
                *
              </Typography>
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[2]}
                color={colors.fonts[4]}
              >
                &nbsp;Mandatory fields:
              </Typography>
            </Box>
          </BreadcrumbRightGrid>
        </Grid>
      </BreadcrumbPaper>
      <AlertDialog
        alertMessage={BackClickAlertMessage}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        back={backClick}
        handleBackClick={handleBackClick}
      />
    </>
  );
};

export default Breadcrumb;

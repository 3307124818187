import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { indexOf, isNil, isNull } from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  BodyTable,
  NotesDisciplineGridbody,
  GridBody,
  HeaderItem,
  SurveyJsContainer,
  TableWidth,
  HorizontalDivider,
  NotesDisciplineGridbodyInfo,
  NotesGridbody,
} from "../styles/Disciplines";

import {
  CategoryMaster,
  DisciplineLevelNote,
  DisciplineNote,
  DisciplineNotes,
  ReferralLevelNote,
} from "../../../../../models/Notes";
import {
  CategoryStatus,
  IconFaRegEye,
  IconFaInfoAlt,
  IconFaPencilAlt,
  IconContainer,
  IconName,
} from "../../../../AdminCenter/routes/NoteManagement/styles/NotesList";
import AssessmentViewer from "../../../../../components/surveyjs/AssessmentViewer";
import { DISCIPLINE_PER_PAGE } from "../../../../../constants/FunctionalUtils";
import { getValue, length } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { rules } from "../../../../../utils/validation/Validation";
import {
  CategoryResponse,
  DisciplineTemplateNotesResponse,
  GetDisciplineLevelNote,
  GetReferralLevelNote,
  NotesTemplateJsonResp,
  SaveDisciplineLevelNote,
  SaveReferralLevelNote,
  UpdateDisciplineLevelNote,
  UpdateReferralLevelNote,
} from "../../../../../models/Api/Notes";
import { ReferralNote } from "../../../../../models/Referral";
import { UserDetailsPayload } from "../../../../../models/User";
import {
  GetDisciplineLevelNotesActionDispatchTypes,
  GetReferralLevelNotesActionDispatchTypes,
  NoteType,
  NotesCommentSectionMaxCharLength,
  NotesData,
  NotesReviewData,
  ProviderNote,
} from "../../../../../constants/Notes";
import {
  NotesTooltip,
  NotesTooltipStyle,
  NotesCommentTooltip,
} from "../styles/NotesTable";
import { IconDisabled } from "../../../../../styles/common/style";
import Permission from "../../../../Permission";

export interface PropsFromState {
  list: any;
  totalRows: number;
  headerLabel: any;
  handlePageChange: (_e: any, page: number) => void;
  page: number;
  DisciplineTemplateData: DisciplineTemplateNotesResponse;
  getDisciplineTemplateNotesMaster: (categoryId: number) => void;
  getDisciplineLevelNotes?: (disciplineNoteId: number) => void;
  updateDisciplineLevelNoteData?: (
    disciplineNoteId: number,
    payload: DisciplineLevelNote
  ) => void;
  getReferralLevelNotes?: (referralNoteId: number) => void;
  GetDisciplineLevelNotesData?: GetDisciplineLevelNote;
  GetNotesSectionById: (templateId: number) => void;
  NotesDataById: NotesTemplateJsonResp;
  title: string;
  saveDisciplineLevelNotesData?: (payload: DisciplineLevelNote) => void;
  saveReferralLevelNoteData?: (payload: ReferralLevelNote) => void;
  updateReferralLevelNotesData?: (
    disciplineNoteId: number,
    payload: ReferralLevelNote
  ) => void;
  serviceRequestDisciplineId?: number;
  user: UserDetailsPayload;
  getReferrallevelNotesData?: GetReferralLevelNote;
  resetStates: (actionType: string[]) => void;
  saveDisciplineLevelNotesDataResp?: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataResp?: UpdateDisciplineLevelNote;
  saveReferralLevelNoteDataResp?: SaveReferralLevelNote;
  updateReferralLevelNotesDataResp?: UpdateReferralLevelNote;
  disableButtons?: boolean;
  setDisableButtons?: Dispatch<SetStateAction<boolean>>;
  setDisableReferralBtns?: Dispatch<SetStateAction<boolean>>;
  disableReferralBtns?: boolean;
  getNotesCategoryMaster: () => void;
  categorydata: CategoryResponse;
  addControlId: string;
  editControlId: string;
  setRenderDisciplineNotes?: Dispatch<SetStateAction<boolean>>;
  renderDisciplineNotes?: boolean;
  setRenderReferralNotes?: Dispatch<SetStateAction<boolean>>;
  renderReferralNotes?: boolean;
  setPageName: Dispatch<SetStateAction<string>>;
  pageName: string;
  setServiceDetails?: Dispatch<SetStateAction<boolean>>;
  type: string;
}

type AllProps = PropsFromState;

const NotesTable = ({
  list,
  totalRows,
  handlePageChange,
  page,
  DisciplineTemplateData,
  getDisciplineTemplateNotesMaster,
  getDisciplineLevelNotes,
  updateDisciplineLevelNoteData,
  GetDisciplineLevelNotesData,
  GetNotesSectionById,
  NotesDataById,
  headerLabel,
  title,
  getReferralLevelNotes,
  saveDisciplineLevelNotesData,
  saveReferralLevelNoteData,
  serviceRequestDisciplineId,
  user,
  getReferrallevelNotesData,
  updateReferralLevelNotesData,
  resetStates,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataResp,
  saveReferralLevelNoteDataResp,
  updateReferralLevelNotesDataResp,
  disableButtons,
  setDisableButtons,
  setDisableReferralBtns,
  disableReferralBtns,
  categorydata,
  getNotesCategoryMaster,
  addControlId,
  editControlId,
  setRenderDisciplineNotes,
  renderDisciplineNotes,
  setRenderReferralNotes,
  renderReferralNotes,
  setPageName,
  pageName,
  setServiceDetails,
  type,
}: AllProps) => {
  dayjs.extend(isBetween);
  const [editFlagForReferral, setEditFlagForReferral] =
    useState<boolean>(false);
  const [editFlagForDiscipline, setEditFlagForDiscipline] =
    useState<boolean>(false);
  const [editNotesPreview, setEditNotesPreview] = useState<boolean>(false);
  const [notesData, setNotesData] = useState<any>();
  const [endpointCallFlag, setEndpointCallFlag] = useState<string>("");
  const [notesTemplateName, setNotesTemplateName] = useState<string>("");
  const [notesTemplateId, setNotesTemplateId] = useState<number>(0);
  const [tempDisciplineNoteId, setTempDisciplineNoteId] = useState<number>();
  const [tempReferralNoteId, setTempReferralNoteId] = useState<number>();
  const [tempNoteEntryId, setTempNoteEntryId] = useState<number>();
  const [categoryList, setCategoryList] = useState<CategoryMaster[] | any[]>(
    []
  );
  const [tempCreatedDate, setTempCreatedDate] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const [noteTemplateCategoryList, setNoteTemplateCategoryList] = useState<
    DisciplineNotes[] | any[]
  >([]);
  const [
    noteTemplateCategoryListForReferral,
    setNoteTemplateCategoryListForReferral,
  ] = useState<DisciplineNotes[] | any[]>([]);

  const [tempDisciplineNotesdata, setTempDisciplineNotesdata] =
    useState<DisciplineLevelNote>();
  const [tempReferralNotesdata, setTempreferralNotesdata] =
    useState<ReferralLevelNote>();

  const { authNo } = useParams();

  const { response: DisciplineTemplateDataResponse } = DisciplineTemplateData;

  const { loading: loadingCategorydataStatus, response: categorydataResponse } =
    categorydata;

  const methods = useForm({
    defaultValues: NotesData,
  });
  const { control, reset, getValues } = methods;

  const count = Math.ceil(
    totalRows
      ? Number(totalRows) / DISCIPLINE_PER_PAGE
      : 1 / DISCIPLINE_PER_PAGE
  );
  const headerTableCell = (value: string) => {
    return (
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[4]}
          color={colors.black[14]}
        >
          {value}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    const categoryStatus =
      categorydataResponse &&
      length(categorydataResponse) &&
      categorydataResponse.map((item) => {
        return {
          label: item.categoryName,
          value: item.templateCategoryId,
        };
      });
    setCategoryList(categoryStatus || []);
  }, [categorydataResponse]);

  useEffect(() => {
    if (!loadingCategorydataStatus) {
      getNotesCategoryMaster();
    }
  }, []);

  useEffect(() => {
    if (
      !isNil(getReferrallevelNotesData && getReferrallevelNotesData.response) &&
      getReferrallevelNotesData &&
      getReferrallevelNotesData.response.noteTemplateId !== -1
    ) {
      reset(getReferrallevelNotesData.response);
      setRenderReferralNotes && setRenderReferralNotes(true);
      setNotesTemplateId(getReferrallevelNotesData.response.noteTemplateId);
      setNotesTemplateName(getReferrallevelNotesData.response.templateName);
      setTempreferralNotesdata(getReferrallevelNotesData.response);
      setNotesData(getReferrallevelNotesData.response.noteEntryJson);
      setEditNotesPreview(true);
    }
  }, [getReferrallevelNotesData]);

  useEffect(() => {
    if (title === NotesReviewData.DISCIPLINE_NOTES) {
      if (
        !isNil(saveDisciplineLevelNotesDataResp) &&
        saveDisciplineLevelNotesDataResp.response.disciplineNoteId !== -1
      ) {
        reset(saveDisciplineLevelNotesDataResp.response);
        setNotesData(saveDisciplineLevelNotesDataResp.response.noteEntryJson);
        setDisableButtons && setDisableButtons(false);
        setRenderDisciplineNotes && setRenderDisciplineNotes(false);
      }
    } else {
      if (
        !isNil(saveReferralLevelNoteDataResp) &&
        saveReferralLevelNoteDataResp.response.referralNoteId !== -1
      ) {
        reset(saveReferralLevelNoteDataResp.response);
        setNotesData(saveReferralLevelNoteDataResp.response.noteEntryJson);
        setDisableReferralBtns && setDisableReferralBtns(false);
        setRenderReferralNotes && setRenderReferralNotes(false);
      }
    }
  }, [saveDisciplineLevelNotesDataResp, saveReferralLevelNoteDataResp]);

  useEffect(() => {
    if (title === NotesReviewData.DISCIPLINE_NOTES) {
      if (
        !isNil(updateDisciplineLevelNoteDataResp) &&
        updateDisciplineLevelNoteDataResp.response.disciplineNoteId !== -1
      ) {
        reset(updateDisciplineLevelNoteDataResp.response);
        setNotesData(updateDisciplineLevelNoteDataResp.response.noteEntryJson);
        setDisableButtons && setDisableButtons(false);
        setRenderDisciplineNotes && setRenderDisciplineNotes(false);
      }
    } else {
      if (
        !isNil(updateReferralLevelNotesDataResp) &&
        updateReferralLevelNotesDataResp.response.referralNoteId !== -1
      ) {
        reset(updateReferralLevelNotesDataResp.response);
        setNotesData(updateReferralLevelNotesDataResp.response.noteEntryJson);
        setDisableReferralBtns && setDisableReferralBtns(false);
        setRenderReferralNotes && setRenderReferralNotes(false);
      }
    }
  }, [updateDisciplineLevelNoteDataResp, updateReferralLevelNotesDataResp]);

  useEffect(() => {
    if (
      !isNil(GetDisciplineLevelNotesData) &&
      GetDisciplineLevelNotesData.response.disciplineNoteId !== -1
    ) {
      setRenderDisciplineNotes && setRenderDisciplineNotes(true);
      reset(
        GetDisciplineLevelNotesData && GetDisciplineLevelNotesData.response
      );
      setNotesTemplateName(GetDisciplineLevelNotesData.response.templateName);
      setNotesTemplateId(GetDisciplineLevelNotesData.response.noteTemplateId);
      setTempDisciplineNotesdata(GetDisciplineLevelNotesData.response);
      setNotesData(GetDisciplineLevelNotesData.response.noteEntryJson);
      setEditNotesPreview(true);
    }
  }, [GetDisciplineLevelNotesData]);

  useEffect(() => {
    if (title === NotesReviewData.DISCIPLINE_NOTES) {
      const categoryStatus =
        DisciplineTemplateDataResponse &&
        length(DisciplineTemplateDataResponse) &&
        DisciplineTemplateDataResponse.map((item) => {
          return {
            label: item.templateName,
            value: item.noteTemplateId,
          };
        });
      setNoteTemplateCategoryList(categoryStatus || []);
    }
    if (title === NotesReviewData.REFERRAL_NOTES) {
      const categoryStatus =
        DisciplineTemplateDataResponse &&
        length(DisciplineTemplateDataResponse) &&
        DisciplineTemplateDataResponse.map((item) => {
          return {
            label: item.templateName,
            value: item.noteTemplateId,
          };
        });
      setNoteTemplateCategoryListForReferral(categoryStatus || []);
    }
  }, [DisciplineTemplateDataResponse]);

  useEffect(() => {
    if (
      !isNil(NotesDataById.response) &&
      NotesDataById.response.noteTemplateId !== -1
    ) {
      setNotesData(NotesDataById.response.templateJson);
    }
  }, [NotesDataById]);

  useEffect(() => {
    if (notesData && notesData.noteTemplateId !== -1) {
      setEditNotesPreview(true);
    }
  }, [notesData]);

  const handleCancelOp = () => {
    if (title === NotesReviewData.DISCIPLINE_NOTES) {
      setRenderDisciplineNotes && setRenderDisciplineNotes(false);
    }
    if (title === NotesReviewData.REFERRAL_NOTES) {
      setRenderReferralNotes && setRenderReferralNotes(false);
    }
  };

  const bodyTableCell = (
    userCreated: string,
    dateCreated: string,
    name: string,
    object: DisciplineNote,
    id: number,
    noteEntryId?: number,
    noteLevel?: string
  ) => {
    const checkDateRange = dayjs().isBetween(
      formatDate(
        dayjs(dateCreated).toString(),
        DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
      ),
      formatDate(
        dayjs(dateCreated).add(1, "day").toString(),
        DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
      )
    );

    return (
      <>
        {indexOf(
          [
            ProviderNote.SERVICE_PROVIDER_DETAILS,
            ProviderNote.REQUEST_NOTE,
            ProviderNote.AGENCY_COMMUNICATION,
            ProviderNote.PORTAL,
          ],
          object.templateName,
          0
        ) === -1 && name == "action" ? (
          <Box sx={{ display: "flex" }}>
            <Box sx={IconContainer}>
              <IconFaRegEye
                onClick={() => {
                  handleViewNotes(id, noteEntryId, noteLevel);
                }}
              />
              <Box sx={IconName} className="tooltip">
                View
              </Box>
            </Box>
            {checkDateRange === true && (
              <>
                {getValue(object, "noteLevel", null) !== "Discipline" &&
                  userCreated === user.email && (
                    <Permission controlId={editControlId} passThrough>
                      {(isDisabled: boolean) => (
                        <Box sx={IconContainer}>
                          <IconFaPencilAlt
                            onClick={() => {
                              handleNavigate(
                                id,
                                Number(noteEntryId),
                                dateCreated
                              );
                            }}
                            style={isDisabled ? IconDisabled : {}}
                          />

                          <Box sx={IconName} className="tooltip">
                            Edit
                          </Box>
                        </Box>
                      )}
                    </Permission>
                  )}
              </>
            )}
          </Box>
        ) : (
          <Box>
            {name === "dateCreated" ? (
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[4]}
                pt={"0.4rem"}
              >
                {formatDate(
                  getValue(object, name, ""),
                  DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
                )}
              </Typography>
            ) : (name === "note" || name === "comment") &&
              !isNull(getValue(object, name, null)) &&
              getValue(object, name, null).length >
                NotesCommentSectionMaxCharLength ? (
              <Tooltip
                title={
                  <Typography
                    variant="subtitle1"
                    fontWeight={fontWeight.Weight[4]}
                  >
                    {indexOf(
                      [
                        NotesReviewData.SERVICE_PROVIDER_NOTE,
                        NotesReviewData.INRULE_REQUEST_NOTE,
                        NotesReviewData.PORTAL,
                      ],
                      object.templateName,
                      0
                    ) === -1 ? (
                      getValue(object, name, null)
                    ) : (
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: getValue(object, name, null),
                        }}
                      />
                    )}
                  </Typography>
                }
                arrow
                slotProps={{
                  popper: {
                    sx: NotesTooltipStyle,
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.black[5]}
                >
                  {indexOf(
                    [
                      NotesReviewData.SERVICE_PROVIDER_NOTE,
                      NotesReviewData.INRULE_REQUEST_NOTE,
                    ],
                    object.templateName,
                    0
                  ) === -1 ? (
                    <Box sx={NotesTooltip}>{getValue(object, name, null)}</Box>
                  ) : (
                    <Box
                      sx={NotesCommentTooltip}
                      dangerouslySetInnerHTML={{
                        __html: getValue(object, name, null),
                      }}
                    />
                  )}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[4]}
                pt={"0.4rem"}
              >
                {name == "authorizationNumber" ? (
                  `${getValue(object, name, "")}${
                    isNull(getValue(object, "serviceNumber", null))
                      ? ""
                      : `/${getValue(object, "serviceNumber", null)}`
                  }`
                ) : object.templateName !==
                  NotesReviewData.SERVICE_PROVIDER_NOTE ? (
                  getValue(object, name, "")
                ) : (
                  <Box
                    sx={NotesCommentTooltip}
                    dangerouslySetInnerHTML={{
                      __html: getValue(object, name, ""),
                    }}
                  />
                )}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  };

  const handleAddNotes = (notesIdentifier: string) => {
    resetStates([
      GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_RESET,
      GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_RESET,
    ]);
    setDisableButtons && setDisableButtons(true);
    reset(NotesData);
    setEditNotesPreview(false);
    setEditFlagForDiscipline(true);
    setEditFlagForReferral(true);
    if (notesIdentifier === "referral") {
      setServiceDetails && setServiceDetails(false);
      setPageName(NotesReviewData.REFERRAL_NOTES);
      if (disableReferralBtns === false) {
        setDisableReferralBtns && setDisableReferralBtns(true);
        setDisableButtons && setDisableButtons(true);
      }
      setRenderReferralNotes && setRenderReferralNotes(true);
      setRenderDisciplineNotes && setRenderDisciplineNotes(false);
      setEndpointCallFlag("addReferral");
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_REFERRAL_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
    }
    if (notesIdentifier === "discipline") {
      setPageName(NotesReviewData.DISCIPLINE_NOTES);
      setRenderDisciplineNotes && setRenderDisciplineNotes(true);
      setRenderReferralNotes && setRenderReferralNotes(false);
      setEndpointCallFlag("addDiscipline");
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_DISCIPLINE_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
    }
  };

  const handleNavigate = (
    id: any,
    noteEntryId: number,
    dateCreated: string
  ) => {
    setShowError(false);
    setTempCreatedDate(dateCreated);
    setDisableButtons && setDisableButtons(true);
    setTempNoteEntryId(noteEntryId);
    if (title === NotesReviewData.REFERRAL_NOTES) {
      setServiceDetails && setServiceDetails(false);
      setPageName(NotesReviewData.REFERRAL_NOTES);
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_REFERRAL_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
      setEditFlagForReferral(true);
      setRenderReferralNotes && setRenderReferralNotes(true);
      setRenderDisciplineNotes && setRenderDisciplineNotes(false);
      if (disableReferralBtns === false) {
        setDisableReferralBtns && setDisableReferralBtns(true);
        setDisableButtons && setDisableButtons(true);
      }
      setTempReferralNoteId(id);
      setEndpointCallFlag("editReferral");
      getReferralLevelNotes && getReferralLevelNotes(id);
    } else {
      setPageName(NotesReviewData.DISCIPLINE_NOTES);
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_DISCIPLINE_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
      setEditFlagForDiscipline(true);
      setTempDisciplineNoteId(id);
      setRenderDisciplineNotes && setRenderDisciplineNotes(true);
      setRenderReferralNotes && setRenderReferralNotes(false);
      setEndpointCallFlag("editDiscipline");
      getDisciplineLevelNotes && getDisciplineLevelNotes(id);
    }
  };

  const handleViewNotes = (id: any, disciplineId: any, noteLevel: any) => {
    setShowError(false);
    if (title === NotesReviewData.REFERRAL_NOTES) {
      setServiceDetails && setServiceDetails(false);
      setPageName(NotesReviewData.REFERRAL_NOTES);
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_REFERRAL_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
      setDisableReferralBtns && setDisableReferralBtns(false);
      resetStates([
        GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_RESET,
      ]);
      if (noteLevel === "Discipline") {
        const tempCategoryId = categoryList.filter(
          (id) => id.label === NotesReviewData.NOTE_DISCIPLINE_LABEL
        );

        if (tempCategoryId.length !== 0) {
          getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
        }
        getDisciplineLevelNotes && getDisciplineLevelNotes(disciplineId);
        setEditFlagForReferral(false);
      } else {
        getReferralLevelNotes && getReferralLevelNotes(id);
        setEditFlagForReferral(false);
      }
      setRenderDisciplineNotes && setRenderDisciplineNotes(false);
      setRenderReferralNotes && setRenderReferralNotes(true);
    } else {
      setPageName(NotesReviewData.DISCIPLINE_NOTES);
      const tempCategoryId = categoryList.filter(
        (id) => id.label === NotesReviewData.NOTE_DISCIPLINE_LABEL
      );

      if (tempCategoryId.length !== 0) {
        getDisciplineTemplateNotesMaster(tempCategoryId[0].value);
      }
      setDisableButtons && setDisableButtons(false);
      resetStates([
        GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_RESET,
      ]);
      setEditFlagForDiscipline(false);
      getDisciplineLevelNotes && getDisciplineLevelNotes(id);
      setRenderDisciplineNotes && setRenderDisciplineNotes(true);
      setRenderReferralNotes && setRenderReferralNotes(false);
    }
  };

  const handleCategorySelection = (e: any) => {
    if (title === NotesReviewData.DISCIPLINE_NOTES) {
      const templateName = noteTemplateCategoryList.filter(
        (id) => id.value === e.target.value
      );
      setNotesTemplateName(templateName[0].label);
      setNotesTemplateId(e.target.value);
      if (e.target.value > 0) {
        GetNotesSectionById(Number(e.target.value));
      }
    }
    if (title === NotesReviewData.REFERRAL_NOTES) {
      const templateName = noteTemplateCategoryListForReferral.filter(
        (id) => id.value === e.target.value
      );
      setNotesTemplateName(templateName[0].label);
      setNotesTemplateId(e.target.value);
      if (e.target.value > 0) {
        GetNotesSectionById(Number(e.target.value));
      }
    }
  };

  useEffect(() => {
    if (
      title === NotesReviewData.DISCIPLINE_NOTES &&
      renderDisciplineNotes === false
    ) {
      setDisableButtons && setDisableButtons(false);
    }
    if (
      title === NotesReviewData.REFERRAL_NOTES &&
      renderReferralNotes === false
    ) {
      setDisableReferralBtns && setDisableReferralBtns(false);
    }
  }, [renderDisciplineNotes, renderReferralNotes]);

  return (
    <Grid item xs={12} sx={GridBody}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2} pt={"0.5rem"}>
              <Typography
                variant="h4"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[5]}
              >
                {type === NoteType.DISCIPLINE_NOTE
                  ? NoteType.DISCIPLINE_NOTE
                  : NoteType.REFERRAL_NOTE}
              </Typography>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={2} pl={"2rem"}>
              <Permission controlId={addControlId} passThrough>
                {(isDisabled: boolean) => (
                  <Button
                    variant="contained"
                    onClick={() => handleAddNotes(title)}
                    disabled={
                      (title === NotesReviewData.DISCIPLINE_NOTES
                        ? disableButtons
                        : disableReferralBtns) || isDisabled
                    }
                  >
                    Add Note
                  </Button>
                )}
              </Permission>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={"1rem"}>
          <TableContainer sx={TableWidth}>
            <Table aria-label="collapsible table" sx={BodyTable}>
              <TableHead aria-label="sticky Header" sx={HeaderItem}>
                <TableRow>
                  {headerLabel &&
                    headerLabel.map((row: any) => {
                      return (
                        <TableCell width={row.width} key={row.key}>
                          {headerTableCell(row.headerLabel)}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              {list && length(list) ? (
                <TableBody>
                  {title === NotesReviewData.DISCIPLINE_NOTES ? (
                    <>
                      {list &&
                        list.map((rowData: DisciplineNote, index: number) => {
                          return (
                            <TableRow key={rowData.disciplineNoteId}>
                              {headerLabel &&
                                headerLabel.map((row: any) => {
                                  return (
                                    <TableCell width={row.width} key={row.key}>
                                      {bodyTableCell(
                                        rowData.userCreated,
                                        rowData.dateCreated,
                                        row.name,
                                        list[Number(index)],
                                        rowData.disciplineNoteId,
                                        rowData.noteEntryId
                                      )}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {list &&
                        list.map((rowData: ReferralNote, index: number) => {
                          return (
                            <TableRow key={index}>
                              {headerLabel &&
                                headerLabel.map(
                                  (row: any, headerLabelIndex: number) => {
                                    return (
                                      <TableCell
                                        width={row.width}
                                        key={headerLabelIndex}
                                      >
                                        {bodyTableCell(
                                          rowData.userCreated,
                                          rowData.dateCreated,
                                          row.name,
                                          list[Number(index)],
                                          rowData.referralNoteId,
                                          rowData.disciplineNoteId,
                                          rowData.noteLevel
                                        )}
                                      </TableCell>
                                    );
                                  }
                                )}
                            </TableRow>
                          );
                        })}
                    </>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      No records found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
        {list && length(list) && (
          <Grid item xs={12} mt={1}>
            <PaginationItem
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
        {((renderDisciplineNotes === true &&
          (pageName === NotesReviewData.DISCIPLINE_NOTES ||
            pageName === NotesReviewData.REFERRAL_NOTES)) ||
          (renderReferralNotes === true &&
            pageName === NotesReviewData.REFERRAL_NOTES)) && (
          <Grid container sx={NotesDisciplineGridbody}>
            <Grid item xs={12} sx={NotesGridbody}>
              <Grid container>
                <Grid item xs={12} pt={"0.5rem"}>
                  <Typography
                    variant="h5"
                    color={colors.black[2]}
                    fontWeight={fontWeight.Weight[5]}
                  >
                    ADD NOTE HERE
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider light sx={HorizontalDivider} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4} sx={CategoryStatus}>
                      <FormControl variant="standard" fullWidth>
                        <FormInputDropdown
                          onChangeHandler={(e: any) =>
                            handleCategorySelection(e)
                          }
                          name="noteTemplateId"
                          fieldrequired="required"
                          control={control}
                          label="Choose template:"
                          helper={rules.TemplateCategory}
                          list={
                            title === NotesReviewData.DISCIPLINE_NOTES
                              ? noteTemplateCategoryList
                              : noteTemplateCategoryListForReferral
                          }
                          disabled={
                            title === NotesReviewData.DISCIPLINE_NOTES
                              ? !editFlagForDiscipline
                              : !editFlagForReferral
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={0.5}></Grid>
                    <Grid item xs={7.5}>
                      <Grid container>
                        <Grid item xs={0.8} pt={"2.2rem"} pl={"0.8rem"}>
                          <IconFaInfoAlt />
                        </Grid>
                        <Grid item xs={11.2} sx={NotesDisciplineGridbodyInfo}>
                          <Typography
                            variant="h6"
                            color={colors.black[2]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            INFO: Please add below information and click Save
                            once complete to add a standard note.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {getValues("noteTemplateId") === null && (
                  <Grid item xs={2}>
                    <Button variant="outlined" onClick={handleCancelOp}>
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {editNotesPreview === true && (
              <Grid
                item
                xs={12}
                sx={SurveyJsContainer}
                key={
                  tempDisciplineNotesdata &&
                  tempDisciplineNotesdata.disciplineNoteId
                }
              >
                <Grid container>
                  <Grid item xs={12}>
                    <AssessmentViewer
                      isSubmit={false}
                      editFlag={
                        title === NotesReviewData.DISCIPLINE_NOTES
                          ? editFlagForDiscipline
                          : editFlagForReferral
                      }
                      assessmentViewData={notesData}
                      endpointcallFlag={endpointCallFlag}
                      saveDisciplineLevelNotesData={
                        saveDisciplineLevelNotesData
                      }
                      saveReferralLevelNoteData={saveReferralLevelNoteData}
                      serviceRequestDisciplineId={serviceRequestDisciplineId}
                      updateDisciplineLevelNoteData={
                        updateDisciplineLevelNoteData
                      }
                      updateReferralLevelNotesData={
                        updateReferralLevelNotesData
                      }
                      notesTemplateName={notesTemplateName}
                      notesTemplateId={notesTemplateId}
                      user={user}
                      authNo={authNo}
                      tempDisciplineNoteId={tempDisciplineNoteId}
                      tempReferralNoteId={tempReferralNoteId}
                      tempNoteEntryId={tempNoteEntryId}
                      GetDisciplineLevelNotesData={tempDisciplineNotesdata}
                      getReferrallevelNotesData={tempReferralNotesdata}
                      setEditNotesPreview={
                        pageName === NotesReviewData.DISCIPLINE_NOTES
                          ? setRenderDisciplineNotes
                          : setRenderReferralNotes
                      }
                      title={title}
                      tempCreatedDate={tempCreatedDate}
                      setShowError={setShowError}
                      setNotesData={setNotesData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {showError === true && (
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[3]}
                color={colors.red[100]}
                pt={"0.4rem"}
              >
                {NotesReviewData.NOTES_UPDATE_ERROR}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NotesTable;

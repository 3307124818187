import { Reducer } from "redux";

import { SavePatientInformationResponse } from "../models/Api/Patient";
import { SavePatientInformationDispatchTypes } from "../models/DispatchTypes/Patient";
import { SavePatientInformationActionDispatchTypes } from "../constants/Patient";

export const defaultPatientFormData: any = {
  createPatient: {
    patientId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    userCreated: null,
    cellPhone: "",
    phoneNo: "",
    extNo: "",
    emailAddress: "",
    isManual: false,
  },
  patientResidentialAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 1,
    country: null,
  },
  patientMailingAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    addressTypeId: 3,
    country: null,
  },
  informationReceived: {
    ahcccsId: null,
    effectiveDate: "",
    effectiveThrough: "",
    eligibilityStatus: "",
    medicareBeneficiaryIdentifier: "",
    patientId: 0,
    policyId: "",
    product: "",
    subscriberId: "",
    benefitPlanUid: "",
    healthPlanUid: "",
    apiEligibilityId: "",
    eligibilityPayerId: "",
    eligibilityPayerName: "",
    fullNameCreated: "",
    isManual: false,
  },
};

const initialState: SavePatientInformationResponse = {
  loading: false,
  response: {
    patientId: 0,
    patientName: "",
    dateOfBirth: "",
    subscriberId: "",
    gender: "",
    medicareBeneficiaryIdentifier: "",
    languagePreferenceValue: "",
    extension: "",
    phoneNo: "",
    residentialAddress: "",
    mailingAddress: "",
    healthPlan: "",
    eligibilityDates: "",
    dateOfIssuance: "",
    emergencyExtension: "",
    emergencyPhoneNo: "",
    benefitPlan: "",
    product: "",
    stateOfIssuance: "",
    patientHistory: {
      patientId: 0,
      insurance: "",
      policyId: "",
      product: "",
      benefitPlan: "",
      stateOfIssuance: "",
      enrollmentDates: "",
      eligibilityCheckDate: "",
    },
  },
};

const PatientInformationReducer: Reducer<
  SavePatientInformationResponse,
  SavePatientInformationDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case SavePatientInformationActionDispatchTypes.SAVE_PATIENT_INFORMATION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SavePatientInformationActionDispatchTypes.SAVE_PATIENT_INFORMATION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientInformationReducer;

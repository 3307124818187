import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import RemovePatient from "../components/MyPatientsDetails/RemovePatient";
import {
  GetRemovePatientData,
  PutRemovePatientData,
} from "../../../models/Patient";
import { updateRemovePatient } from "../../../actions/Patient";
import { User, UserDetailsPayload } from "../../../models/User";
import { RemoveStaffingPatient } from "../../../models/Staffing";
import { postRemoveStaffingPatient } from "../../../actions/Staffing";

const mapStateToProps = ({
  loggedInUserState,
  myPatientListState,
  RemovePatientState,
  userListState,
}: ApplicationState) => ({
  MyPatientsRecord: myPatientListState,
  removePatientResp: RemovePatientState.response as GetRemovePatientData,
  user: loggedInUserState.response as UserDetailsPayload,
  userList: userListState.response as User[],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    updateRemovePatientList: (
      payload: PutRemovePatientData,
      staffId: number
    ) => {
      dispatch(updateRemovePatient(payload, staffId));
    },
    postRemoveStaffingPatientList: (payload: RemoveStaffingPatient) => {
      dispatch(postRemoveStaffingPatient(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemovePatient);

import { Reducer } from "redux";

import { InformationReceivedActionDispatchTypes } from "../constants/InformationReceived";
import { InformationReceivedResponse } from "../models/Api/InformationReceived";
import { InformationReceivedDispatchTypes } from "../models/DispatchTypes/InformationReceived";

export const initialState: InformationReceivedResponse = {
  loading: false,
  response: {
    state: "",
    healthPlan: "",
    productPlan: "",
    benefitPlan: "",
    mbiNumber: -1,
    ahccsNumber: -1,
    insurance: "",
    status: false,
    policyId: "",
  },
};

const InformationReceivedReducer: Reducer<
  InformationReceivedResponse,
  InformationReceivedDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case InformationReceivedActionDispatchTypes.HEALTHPLAN_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case InformationReceivedActionDispatchTypes.HEALTHPLAN_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default InformationReceivedReducer;

import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { UseFormGetValues } from "react-hook-form";

import {
  PaginationStyle,
  PatientContainerBox,
  TableGrid,
} from "../../styles/style";
import {
  AssignStaffHeaderLabel,
  getSortColumn,
  MyPatientActionDispatchTypes,
  MyPatientHeaderLabel,
  myPatientsData,
} from "../../../../constants/AllPatientRecord";
import { ASC, DESC, PER_PAGE } from "../../../../constants/FunctionalUtils";
import TableComponent from "../../../../components/formComponents/TableComponent";
import { FilterModel } from "../../../../models/Api/Filter";
import { SearchFilterModel } from "../../../../models/Filter";
import { AllPatientDetails, MyPatient } from "../../../../models/Patient";
import { getPatientFilterPayload, isNil, length } from "../../../../utils";
import PaginationItem from "../../../../components/custom/pagination/components/PaginationItem";
import AssignStaff from "../../containers/AssignStaff";

export interface PropsFromState {
  setValue: (name: string, value: number | string, boolean: any) => void;
  errorFlag: boolean;
  setErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  patientSelected: AllPatientDetails[];
  setPatientSelected: React.Dispatch<React.SetStateAction<AllPatientDetails[]>>;
  handleClear: () => void;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
  getValues: UseFormGetValues<any>;
  setPage: (page: number) => void;
  page: number;
  action: string;
  setSelectedFilterByArray: (arr: string[] | any) => void;
  storeFilterPayload: (type: number, payload?: FilterModel) => void;
  valueToOrderBy: string;
  setValueToOrderBy: (valueToOrderBy: string) => void;
  postMyPatientList: (payload: SearchFilterModel) => void;
  privateDutyAuthRecord: MyPatient | null;
  filterState: FilterModel;
  getMyPatientListReset: (actionType: string[]) => void;
}

type Props = PropsFromState;

const PrivateDutyAuth = ({
  activeTab,
  postMyPatientList,
  handleClear,
  openModal,
  errorFlag,
  privateDutyAuthRecord,
  patientSelected,
  setOpenModal,
  setErrorFlag,
  setPatientSelected,
  setValue,
  getValues,
  setPage,
  page,
  action,
  filterState,
  valueToOrderBy,
  setValueToOrderBy,
  getMyPatientListReset
}: Props) => {
  const [orderDirection, setOrderDirection] = useState(ASC);
  const [allSelect, setAllSelect] = useState(false);
  const [privateDutyAuthList, setPrivateDutyAuthList] = useState(
    privateDutyAuthRecord &&
      privateDutyAuthRecord.searchResponse &&
      length(privateDutyAuthRecord.searchResponse) &&
      privateDutyAuthRecord.searchResponse[0].patientId != 0
      ? privateDutyAuthRecord.searchResponse
      : []
  );
  const [allSelectedPatients, setAllSelectedPatients] = useState<
    AllPatientDetails[]
  >([]);

  const count = Math.ceil(
    privateDutyAuthRecord && privateDutyAuthRecord.totalRows
      ? Number(privateDutyAuthRecord.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );

  const handleSort = (property: string) => {
    onAction(false, 1, property);
  };

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    onAction(true, page);
  };

  const onAction = (isPageChange: boolean, page: number, property?: string) => {
    setPage(isPageChange ? page : 1);
    setValue("pageNo", isPageChange ? page : 1, true);
    if (!isPageChange) {
      const isAsc =
        valueToOrderBy === getSortColumn(property) && orderDirection === ASC;
      setOrderDirection(isAsc ? DESC : ASC);
      setValueToOrderBy(getSortColumn(property));
      setValue("sortColumn", getSortColumn(property), true);
      setValue("sortOrder", isAsc ? DESC : ASC, true);
      postMyPatientList({
        ...getPatientFilterPayload(filterState.response.privateDutyAuth),
        pageNo: getValues("pageNo"),
        sortColumn: getSortColumn(property),
        sortOrder: isAsc ? DESC : ASC,
      });
      return;
    }
    postMyPatientList({
      ...getPatientFilterPayload(filterState.response.privateDutyAuth),
      pageNo: page,
      sortColumn: valueToOrderBy,
      sortOrder: orderDirection,
    });
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == "all") {
      setAllSelect(!allSelect);
      if (!allSelect) {
        const selectList = privateDutyAuthList;
        const isAllSelectedPatients = selectList.every(
          (val: AllPatientDetails) =>
            allSelectedPatients.some((item) =>
              Object.entries(val).every(
                ([key, value]) => item[key as keyof AllPatientDetails] === value
              )
            )
        );
        const filteredArray = selectList.filter(
          (item: AllPatientDetails) =>
            !allSelectedPatients.some(
              (value) =>
                value.serviceRequestDisciplineId ===
                item.serviceRequestDisciplineId
            )
        );

        if (isAllSelectedPatients === false) {
          setAllSelectedPatients([...allSelectedPatients, ...filteredArray]);
        }
        setPatientSelected(selectList);
        return;
      }
      const uncheckArray = allSelectedPatients.filter(
        (items) =>
          !privateDutyAuthList.some(
            (val: AllPatientDetails) =>
              val.serviceRequestDisciplineId ===
              items.serviceRequestDisciplineId
          )
      );
      setPatientSelected([]);
      setAllSelectedPatients(uncheckArray);
    } else {
      const patientListOriginal: AllPatientDetails[] = privateDutyAuthList;
      const patientList = patientSelected ? patientSelected : [];
      const patientAuthNos =
        patientList &&
        patientList.map((patient) => patient.serviceRequestDisciplineId);
      if (patientAuthNos && patientAuthNos.includes(Number(e.target.value))) {
        const newPatients = patientList
          ? patientList.filter((item: AllPatientDetails) => {
              return item.serviceRequestDisciplineId != Number(e.target.value);
            })
          : [];

        const newSelectedPatients = allSelectedPatients
          ? allSelectedPatients.filter((item: AllPatientDetails) => {
              return item.serviceRequestDisciplineId != Number(e.target.value);
            })
          : [];
        setPatientSelected(newPatients);
        setAllSelectedPatients(newSelectedPatients);
        setAllSelect(false);
      } else {
        const patient = patientListOriginal.filter(
          (item: AllPatientDetails) =>
            item.serviceRequestDisciplineId == Number(e.target.value)
        );
        const newArrayWithAddedPatients = [...patientList, patient[0]];
        setPatientSelected(newArrayWithAddedPatients);
        setAllSelectedPatients(newArrayWithAddedPatients);
      }
    }
  };

  const checkPatient = (id: number) => {
    const patientChecked = patientSelected.filter(
      (patient) => patient.serviceRequestDisciplineId == id
    );
    if (patientChecked.length == 0) return false;
    else return true;
  };

  useEffect(() => {
    const privateDutyAuthData =
      privateDutyAuthRecord &&
      privateDutyAuthRecord.searchResponse &&
      length(privateDutyAuthRecord.searchResponse) &&
      privateDutyAuthRecord.searchResponse[0].patientId != 0
        ? privateDutyAuthRecord.searchResponse
        : [];
    setPrivateDutyAuthList(privateDutyAuthData);
  }, [privateDutyAuthRecord]);

  useEffect(() => {
    return () => {
      getMyPatientListReset([
        MyPatientActionDispatchTypes.POST_PATIENTLIST_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    const isPresent =
      privateDutyAuthList.length !== 0 &&
      privateDutyAuthList.every((val: AllPatientDetails) =>
        allSelectedPatients.some((item) =>
          Object.entries(val).every(
            ([key, value]) => item[key as keyof AllPatientDetails] === value
          )
        )
      );
    setAllSelect(isPresent);
    setPatientSelected(allSelectedPatients);
  }, [allSelectedPatients, page, privateDutyAuthList, patientSelected]);

  useEffect(() => {
    if (openModal) {
      if (patientSelected.length == 0) {
        setErrorFlag(true);
        setOpenModal(false);
      }
    }
    if (length(patientSelected)) {
      setErrorFlag(false);
    }
  }, [openModal, patientSelected]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PatientContainerBox m={"0 1rem"} sx={TableGrid}>
          <TableComponent
            allSelect={allSelect}
            checkPatient={checkPatient}
            handleCheck={handleCheck}
            handleSort={handleSort}
            HeaderLabel={MyPatientHeaderLabel}
            list={myPatientsData}
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            patientList={privateDutyAuthList}
            type="privateDutyAuth"
          />
        </PatientContainerBox>
      </Grid>
      {length(privateDutyAuthList) && (
        <Grid item xs={12} sx={PaginationStyle}>
          <PaginationItem
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Grid>
      )}
      {!isNil(patientSelected) && openModal && !errorFlag && (
        <AssignStaff
          action={action}
          activeTab={activeTab}
          handleClear={handleClear}
          openModal={openModal}
          patientList={AssignStaffHeaderLabel}
          selectedList={patientSelected}
          setAllSelect={setAllSelect}
          setOpenModal={setOpenModal}
          setPage={setPage}
          setPatientSelected={setPatientSelected}
          setAllSelectedPatients={setAllSelectedPatients}
          type="pending"
        />
      )}
    </Grid>
  );
};

export default PrivateDutyAuth;

import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

import { ReactHookFormErrMessage } from "../../../../../components/formComponents/common/ReactHookFormErrMessage";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthPeriodTablecell,
  BodyTable,
  BoxContainer,
  CareDateGrid,
  CounterDivider,
  CounterVisitsBox,
  DisciplineBodyTableRow,
  DisciplineBox,
  DisciplineDataTablecell,
  DisciplineHeader,
  DisciplineTableTitle,
  HeaderItem,
  HeaderModuleName,
  HorizontalDivider,
  IconBox,
  IconDisableStyle,
  SelectHeader,
  TableWidth,
  TotalTablecell,
  TotalVisitCount,
  TotalVisitsDataTablecell,
  VisitCount,
  VisitCountTablecell,
  VisitsStyle,
} from "../styles/ServiceRequestReAuth";
import { Checkbox } from "../../../../../components/custom/checkbox";
import { colors, others } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import {
  CareTypeResponse,
  DisciplinesResponse,
} from "../../../../../models/Api/Master";
import { handleInputEvent, length, getValue } from "../../../../../utils";
import { addDaysToDate, formatDate } from "../../../../../utils/DateTime";
import {
  Discipline,
  DisciplineSearch,
  ServiceDiscipline,
} from "../../../../../models/Service";
import { ReAuthServiceRequestResponse } from "../../../../../models/Api/Service";
import {
  AllowedVisitCounts,
  AuthVisitTypes,
  DisciplineTableHeader,
} from "../../../../../constants/Service";
import { ReauthOpacity } from "../../ReferralIntake/styles/style";
import { rules } from "../../../../../utils/validation/Validation";
import { PatientInformationView } from "../../../../../models/Patient";
import { DisciplinesActionDispatchTypes } from "../../../../../constants/Master";
import { ServiceRequestType } from "../../../../../constants/Master";
import { DateFormats } from "../../../../../constants/Date";

export interface PropsDispatch {
  getDisciplines: (payload: DisciplineSearch) => void;
  setValue: (id: any, value: any, boolean: any) => void;
  resetStates: (actionType: string[]) => void;
}
export interface PropsState {
  careTypes: CareTypeResponse;
  control: any;
  disciplinesState: DisciplinesResponse;
  getReAuthServiceRequestData: (
    reAuthId: string,
    startOfCareDate?: string
  ) => void;
  getValues: any;
  reauthPatientDetails: PatientInformationView;
  reAuthServiceRequestData: ReAuthServiceRequestResponse;
  resetField: any;
  setSocDate: any;
  socDate: any;
  formState: any;
  setIsNextStep: Dispatch<SetStateAction<any>>;
  setIsTabChange: Dispatch<SetStateAction<any>>;
  minimumDate: any;
  clearErrors: (name: string) => void;
}

type AllProps = PropsState & PropsDispatch;

const ServiceRequest: React.FC<AllProps> = ({
  control,
  disciplinesState,
  getDisciplines,
  getReAuthServiceRequestData,
  reauthPatientDetails,
  reAuthServiceRequestData,
  setSocDate,
  setValue,
  formState,
  setIsNextStep,
  setIsTabChange,
  minimumDate,
  clearErrors,
  getValues,
  resetStates,
}: AllProps) => {
  const [disciplines, setDisciplines] = useState(disciplinesState.response);
  const [startOfCareDateFlag, setStartOfCareDateFlag] = useState(
    reAuthServiceRequestData &&
    reAuthServiceRequestData.response &&
    reAuthServiceRequestData.response.startOfCareVerified
  );

  const [reAuthServiceRequest, setReAuthServiceRequest] = useState(
    reAuthServiceRequestData && reAuthServiceRequestData.response
  );
  const [selectServiceDisciplines, setSelectServiceDisciplines] = useState(
    reAuthServiceRequestData &&
    reAuthServiceRequestData.response &&
    reAuthServiceRequestData.response.reAuthDiscipline
  );

  const [form, setForm] = useState<any>({
    referralId: reAuthServiceRequest && reAuthServiceRequest.referralId,
    userCreated: reAuthServiceRequest && reAuthServiceRequest.userCreated,
    startOfCareDate: "",
    currentAuthPeriod:
      reAuthServiceRequest &&
      reAuthServiceRequest.currentStartDate + reAuthServiceRequest &&
      reAuthServiceRequest.currentEndDate,
    nextAuthPeriod:
      reAuthServiceRequest &&
      reAuthServiceRequest.nextStartDate + reAuthServiceRequest &&
      reAuthServiceRequest.nextEndDate,
    reAuthDiscipline: [],
    reAuthCareType: [],
  });

  const { reAuthId } = useParams();

  useEffect(() => {
    if (getValue(reauthPatientDetails, "patientId", 0) != 0) {
      const payload: DisciplineSearch = {
        id: Number(reAuthId),
        type: ServiceRequestType.RE_AUTH_ID,
        requiredTbd: false,
        authorizationTypeId: getValue(
          reauthPatientDetails,
          "authorizationTypeId",
          0
        ),
      };
      getDisciplines(payload);
    }
    return () => {
      resetStates([DisciplinesActionDispatchTypes.DISCIPLINES_RESET]);
    };
  }, [reauthPatientDetails]);

  useEffect(() => {
    setDisciplines(disciplinesState.response);
  }, [disciplinesState.response]);

  useEffect(() => {
    setStartOfCareDateFlag(
      reAuthServiceRequestData &&
      reAuthServiceRequestData.response &&
      reAuthServiceRequestData.response.startOfCareVerified
    );
  }, [
    reAuthServiceRequestData &&
    reAuthServiceRequestData.response &&
    reAuthServiceRequestData.response.startOfCareVerified,
  ]);

  useEffect(() => {
    setSocDate(
      reAuthServiceRequestData &&
      reAuthServiceRequestData.response &&
      reAuthServiceRequestData.response.startOfCareDate
    );
  }, [
    reAuthServiceRequestData &&
    reAuthServiceRequestData.response &&
    reAuthServiceRequestData.response.startOfCareDate,
  ]);

  useEffect(() => {
    setReAuthServiceRequest(
      reAuthServiceRequestData && reAuthServiceRequestData.response
    );
    setSelectServiceDisciplines(
      reAuthServiceRequestData &&
      reAuthServiceRequestData.response &&
      reAuthServiceRequestData.response.reAuthDiscipline
    );
  }, [
    reAuthServiceRequestData && reAuthServiceRequestData.response,
    reAuthServiceRequestData &&
    reAuthServiceRequestData.response &&
    reAuthServiceRequestData.response.reAuthDiscipline,
  ]);
  const headerTableCell = (value: string) => {
    return (
      <>
        <TableCell
          sx={value === DisciplineTableHeader.TOTAL ? TotalTablecell : {}}
        >
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={colors.black[16]}
            sx={
              value === DisciplineTableHeader.TOTAL
                ? TotalVisitCount
                : VisitCountTablecell
            }
          >
            {value}
          </Typography>
        </TableCell>
      </>
    );
  };

  const header = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontWeight={fontWeight.Weight[5]}
            color={colors.black[2]}
            sx={HeaderModuleName}
          >
            All RE-AUTH. REQUESTS
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={others.otherColors[36]}
            sx={ReauthOpacity}
          >
            Please choose the Desciplines and skills applicable for &nbsp;
            {reauthPatientDetails && reauthPatientDetails.patientName
              ? reauthPatientDetails.patientName
              : ""}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box sx={BoxContainer}>
        {header()}
        {selection(
          control,
          startOfCareDateFlag,
          getReAuthServiceRequestData,
          reAuthId,
          setSocDate,
          minimumDate,
          setIsNextStep,
          setIsTabChange,
          formState
        )}
        <Divider sx={HorizontalDivider} />
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={DisciplineTableTitle}>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.black[2]}
                  display="inline"
                >
                  CHOOSE DISCIPLINES:
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={colors.red[100]}
                  display="inline"
                  pl="0.5rem"
                >
                  *
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <ReactHookFormErrMessage
                errors={formState.errors}
                name="reAuthDiscipline"
              />
            </Grid>
            <Divider sx={HorizontalDivider} />
            <Grid item xs={12}>
              <TableContainer sx={TableWidth}>
                <Table aria-label="collapsible table" sx={BodyTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {authPeriodField(
                        "Current Auth Period",
                        formatDate(
                          reAuthServiceRequest &&
                          reAuthServiceRequest.currentStartDate,
                          DateFormats.MM_DD_YYYY
                        ) +
                        " - " +
                        formatDate(
                          reAuthServiceRequest &&
                          reAuthServiceRequest.currentEndDate,
                          DateFormats.MM_DD_YYYY
                        )
                      )}
                      {authPeriodField(
                        "Next Auth Period",
                        formatDate(
                          reAuthServiceRequest &&
                          reAuthServiceRequest.nextStartDate,
                          DateFormats.MM_DD_YYYY
                        ) +
                        " - " +
                        formatDate(
                          reAuthServiceRequest &&
                          reAuthServiceRequest.nextEndDate,
                          DateFormats.MM_DD_YYYY
                        ),
                        reAuthServiceRequest
                      )}
                    </TableRow>
                  </TableHead>
                  <TableHead sx={HeaderItem}>
                    <TableRow>
                      <TableCell>
                        <Box sx={displayFlex}>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[3]}
                            color={colors.black[16]}
                            sx={SelectHeader}
                          >
                            {DisciplineTableHeader.SELECT}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[3]}
                            color={colors.black[16]}
                            sx={DisciplineHeader}
                          >
                            {DisciplineTableHeader.DISCIPLINES}
                          </Typography>
                        </Box>
                      </TableCell>
                      {headerTableCell(
                        DisciplineTableHeader.REQUESTED_NO_OF_VISITS
                      )}
                      {headerTableCell(
                        DisciplineTableHeader.REQUESTED_NO_OF_VISITS
                      )}
                      {headerTableCell(DisciplineTableHeader.TOTAL)}
                    </TableRow>
                  </TableHead>
                  <DisciplineRowRender
                    disciplines={disciplines}
                    setValue={setValue}
                    selectServiceDisciplines={selectServiceDisciplines}
                    setSelectServiceDisciplines={setSelectServiceDisciplines}
                    setForm={setForm}
                    form={form}
                    control={control}
                    getValues={getValues}
                    clearErrors={clearErrors}
                  />
                </Table>
              </TableContainer>
            </Grid>
            <Divider sx={HorizontalDivider} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
type disciplineRowRenderProps = {
  disciplines: Discipline[];
  setValue: any;
  selectServiceDisciplines: ServiceDiscipline[];
  setSelectServiceDisciplines: any;
  form: any;
  setForm: any;
  control: any;
  getValues: any;
  clearErrors: any;
};

const DisciplineRowRender = ({
  disciplines,
  setValue,
  selectServiceDisciplines,
  setSelectServiceDisciplines,
  form,
  setForm,
  control,
  getValues,
  clearErrors,
}: disciplineRowRenderProps) => {
  const handleDiscipline = (e: any) => {
    const disciplineList = selectServiceDisciplines
      ? selectServiceDisciplines
      : [];

    const disciplineIds =
      disciplineList &&
      disciplineList.map((discipline) => discipline.disciplineId);
    if (disciplineIds && disciplineIds.includes(Number(e.target.value))) {
      const newDisciplines = disciplineList
        ? disciplineList.filter((item) => {
          return item.disciplineId != e.target.value;
        })
        : [];
      setSelectServiceDisciplines(newDisciplines);
      const formContent = form;
      formContent.reAuthDiscipline = newDisciplines;
      setForm(formContent);
    } else {
      const disciplineData =
        disciplines &&
        length(disciplines) &&
        disciplines.filter((item) => {
          return item.disciplineId == e.target.value;
        });
      const discipline = {
        disciplineId: Number(e.target.value),
        disciplineName:
          disciplineData && length(disciplineData)
            ? disciplineData[0].disciplineName
            : "",
        currentRequestedVisit: 0,
        nextRequestedVisit: 0,
        totalRequestedVisit: 0,
      };
      const newArrayWithAddedDisciplines = [...disciplineList, discipline];
      setSelectServiceDisciplines(newArrayWithAddedDisciplines);
      const formContent = form;
      formContent.reAuthDiscipline = newArrayWithAddedDisciplines;
      setForm(formContent);
    }
  };
  useEffect(() => {
    setValue("reAuthDiscipline", selectServiceDisciplines, true);
  }, [selectServiceDisciplines]);
  const checkedDisciplines = (disciplineId: number) => {
    const disciplineIds =
      selectServiceDisciplines &&
      length(selectServiceDisciplines) &&
      selectServiceDisciplines.map(
        (serviceDiscipline: ServiceDiscipline) => serviceDiscipline.disciplineId
      );

    if (disciplineIds && disciplineIds.includes(disciplineId)) {
      return true;
    }
    return false;
  };

  const getVisits = (disciplineId: number, type: string) => {
    const data =
      selectServiceDisciplines &&
      length(selectServiceDisciplines) &&
      selectServiceDisciplines.find(
        (serviceDiscipline: ServiceDiscipline) =>
          serviceDiscipline.disciplineId == disciplineId
      );
    if (data && type === AuthVisitTypes.CURRENT_AUTH_REQUESTED_VISITS) {
      return data.currentRequestedVisit;
    } else if (data && type === AuthVisitTypes.NEXT_AUTH_REQUESTED_VISITS) {
      return data.nextRequestedVisit;
    }

    return 0;
  };

  const getTotalVisits = (disciplineId: number) => {
    const data =
      selectServiceDisciplines &&
      length(selectServiceDisciplines) &&
      selectServiceDisciplines.find(
        (serviceDiscipline: ServiceDiscipline) =>
          serviceDiscipline.disciplineId == disciplineId
      );
    if (data) {
      return data.totalRequestedVisit;
    }
    return 0;
  };

  const getIndex = (disciplineId: number) => {
    let i = -1;

    selectServiceDisciplines &&
      length(selectServiceDisciplines) &&
      selectServiceDisciplines.forEach(
        (serviceDiscipline: ServiceDiscipline, index: number) => {
          if (serviceDiscipline.disciplineId == disciplineId) {
            i = index;
          }
        }
      );
    if (i >= 0) {
      return i;
    }
    return -1;
  };

  return (
    <TableBody>
      {disciplines.map((discipline: Discipline) => (
        <TableRow key={discipline.disciplineId} sx={DisciplineBodyTableRow}>
          <TableCell sx={DisciplineBox}>
            <Box sx={displayFlex}>
              <Box>
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <Box pl="1rem">
                      <FormGroup {...field} onChange={handleDiscipline}>
                        <FormControlLabel
                          key={discipline.disciplineId}
                          control={
                            <Checkbox
                              value={discipline.disciplineId}
                              checked={checkedDisciplines(
                                discipline.disciplineId
                              )}
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </Box>
                  )}
                  name={`reAuthDiscipline`}
                  control={control}
                  rules={rules.discipline}
                />
              </Box>
              <Typography
                variant="h6"
                color={others.otherColors[63]}
                fontWeight={fontWeight.Weight[5]}
                sx={DisciplineDataTablecell}
              >
                {discipline.disciplineName}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <CounterForVisits
              selectServiceDisciplines={selectServiceDisciplines}
              value={getVisits(
                discipline.disciplineId,
                "currentAuthRequestedVisits"
              )}
              index={getIndex(discipline.disciplineId)}
              setSelectServiceDisciplines={setSelectServiceDisciplines}
              type={true}
              control={control}
              name={`currentAuthRequestedVisits-${discipline.disciplineId}`}
              getValues={getValues}
              clearErrors={clearErrors}
              setValue={setValue}
              inputType="onlyNumber"
            />
          </TableCell>
          <TableCell>
            <CounterForVisits
              selectServiceDisciplines={selectServiceDisciplines}
              value={getVisits(
                discipline.disciplineId,
                "nextAuthRequestedVisits"
              )}
              index={getIndex(discipline.disciplineId)}
              setSelectServiceDisciplines={setSelectServiceDisciplines}
              type={false}
              control={control}
              name={`nextAuthRequestedVisits-${discipline.disciplineId}`}
              getValues={getValues}
              clearErrors={clearErrors}
              setValue={setValue}
              inputType="onlyNumber"
            />
          </TableCell>
          <TableCell sx={TotalVisitsDataTablecell}>
            <Typography
              variant="h6"
              color={colors.fonts[4]}
              fontWeight={fontWeight.Weight[5]}
            >
              {getTotalVisits(discipline.disciplineId)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const selection = (
  control: any,
  startOfCareDateFlag: any,
  getReAuthServiceRequestData: (
    reAuthId: string,
    startOfCareDate?: string
  ) => void,
  reauthId: any,
  setSocDate: any,
  minimumDate: string,
  setIsNextStep: Dispatch<SetStateAction<any>>,
  setIsTabChange: Dispatch<SetStateAction<any>>,
  formState: any
) => {
  const handleDateChange = debounce((e: any) => {
    setIsNextStep(false);
    setIsTabChange(false);
    setSocDate(e);
    const newDate = formatDate(e);
    if (
      Object.prototype.hasOwnProperty.call(
        formState.errors,
        "startOfCareDate"
      ) === false
    ) {
      getReAuthServiceRequestData(reauthId.toString(), newDate);
    }
  }, 500);

  return (
    <Grid sx={CareDateGrid} container spacing={5}>
      <Grid item xs={6}>
        <Box sx={displayFlex}>
          <Box pt="1rem">
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[4]}
              display="inline"
            >
              Start of care date:
            </Typography>
            <Typography
              variant="subtitle1"
              color={colors.red[100]}
              display="inline"
              pl="0.5rem"
            >
              *
            </Typography>
          </Box>
          <Box pl={"0.7rem"}>
            <FormControl variant="standard">
              <DatePicker
                disabled={startOfCareDateFlag ? startOfCareDateFlag : false}
                name="startOfCareDate"
                control={control}
                label={""}
                fieldrequired={"required"}
                helper={rules.referralSourceDischargeDate}
                onChangeHandler={handleDateChange}
                maximumdate={addDaysToDate(minimumDate, 59, "day")}
                socDateFlag={true}
                allowFutureDate={true}
              />
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const authPeriodField = (
  label: string,
  value: string,
  reAuthServiceRequest?: any
) => {
  const calcNextAuthPeriod = () => {
    const nextStartDate =
      reAuthServiceRequest &&
      reAuthServiceRequest.currentStartDate &&
      new Date(reAuthServiceRequest && reAuthServiceRequest.currentStartDate);
    nextStartDate && nextStartDate.setDate(nextStartDate.getDate() + 30);
    const nextEndDate =
      reAuthServiceRequest &&
      reAuthServiceRequest.currentEndDate &&
      new Date(reAuthServiceRequest && reAuthServiceRequest.currentEndDate);
    nextEndDate && nextEndDate.setDate(nextEndDate.getDate() + 30);
    const date1 = nextStartDate && formatDate(nextStartDate.toISOString());
    const date2 = nextEndDate && formatDate(nextEndDate.toISOString());
    const next30DayAuthPeriod =
      reAuthServiceRequest && reAuthServiceRequest.currentStartDate
        ? `${date1} - ${date2}`
        : "-";
    return next30DayAuthPeriod;
  };
  return (
    <TableCell>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[29]}
        sx={AuthPeriodTablecell}
      >
        {label}
      </Typography>
      <Typography
        variant="h6"
        color={colors.fonts[16]}
        fontWeight={fontWeight.Weight[5]}
        sx={AuthPeriodTablecell}
      >
        {value.trim().length <= 1 ? calcNextAuthPeriod() : value}
      </Typography>
    </TableCell>
  );
};

type CounterForVisitsProps = {
  value: any;
  setSelectServiceDisciplines: any;
  index: number;
  type: boolean;
  selectServiceDisciplines: ServiceDiscipline[];
  control: any;
  name: string;
  getValues: any;
  clearErrors: any;
  setValue: any;
  inputType: string;
};

const CounterForVisits = ({
  value,
  index,
  selectServiceDisciplines,
  type,
  setSelectServiceDisciplines,
  control,
  name,
  getValues,
  clearErrors,
  setValue,
  inputType
}: CounterForVisitsProps) => {
  const handleIncreaseCounter = () => {
    if (type) {
      const i = index;

      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, index) => {
          if (index === i && value < AllowedVisitCounts.MAX) {
            item.currentRequestedVisit = value + 1;
            item.totalRequestedVisit = item.totalRequestedVisit + 1;
            setValue(name, item.currentRequestedVisit)
          }
        });

      setSelectServiceDisciplines([...selectServiceDisciplines]);

      clearErrorMessages(
        selectServiceDisciplines[index].disciplineId,
        selectServiceDisciplines[index].disciplineId
      );
    } else {
      const i = index;

      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, index) => {
          if (index === i && value < AllowedVisitCounts.MAX) {
            item.nextRequestedVisit = value + 1;
            item.totalRequestedVisit = item.totalRequestedVisit + 1;
            setValue(name, item.nextRequestedVisit)

          }
        });
      setSelectServiceDisciplines([...selectServiceDisciplines]);
      clearErrorMessages(
        selectServiceDisciplines[index].disciplineId,
        selectServiceDisciplines[index].disciplineId
      );
    }
  };

  const handleVisits = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = Number(event.target.value);
    const i = index;

    if (type) {
      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, idx) => {
          if (idx === i && newValue <= AllowedVisitCounts.MAX) {
            item.currentRequestedVisit = newValue;
            item.totalRequestedVisit = item.currentRequestedVisit + item.nextRequestedVisit;
            setValue(name, item.currentRequestedVisit);
          }
        });

      setSelectServiceDisciplines([...selectServiceDisciplines]);
      clearErrorMessages(
        selectServiceDisciplines[i].disciplineId,
        selectServiceDisciplines[i].disciplineId
      );
    } else {
      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, idx) => {
          if (idx === i && newValue <= AllowedVisitCounts.MAX) {
            item.nextRequestedVisit = newValue;
            item.totalRequestedVisit = item.currentRequestedVisit + item.nextRequestedVisit;
            setValue(name, item.nextRequestedVisit);
          }
        });
      setSelectServiceDisciplines([...selectServiceDisciplines]);
      clearErrorMessages(
        selectServiceDisciplines[i].disciplineId,
        selectServiceDisciplines[i].disciplineId
      );
    }
  };
  const handleDecreaseCounter = () => {
    if (type) {
      const i = index;

      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, index) => {
          if (index === i && value > AllowedVisitCounts.MIN) {
            item.currentRequestedVisit! <= 0
              ? (item.currentRequestedVisit = 0)
              : (item.currentRequestedVisit = value - 1);
            item.totalRequestedVisit =
              item.currentRequestedVisit + item.nextRequestedVisit;
            setValue(name, item.currentRequestedVisit)
          }
        });
      setSelectServiceDisciplines([...selectServiceDisciplines]);
      clearErrorMessages(
        selectServiceDisciplines[index].disciplineId,
        selectServiceDisciplines[index].disciplineId
      );
    } else {
      const i = index;

      selectServiceDisciplines &&
        length(selectServiceDisciplines) &&
        selectServiceDisciplines.forEach((item, index) => {
          if (index === i && value > AllowedVisitCounts.MIN) {
            item.nextRequestedVisit! <= 0
              ? (item.nextRequestedVisit = 0)
              : (item.nextRequestedVisit = value - 1);
            item.totalRequestedVisit =
              item.currentRequestedVisit + item.nextRequestedVisit;
            setValue(name, item.nextRequestedVisit)
          }
        });
      setSelectServiceDisciplines([...selectServiceDisciplines]);
      clearErrorMessages(
        selectServiceDisciplines[index].disciplineId,
        selectServiceDisciplines[index].disciplineId
      );
    }
  };
  const clearErrorMessages = (nextValue: number, currentValue: number) => {
    clearErrors(`nextAuthRequestedVisits-${nextValue}`);
    clearErrors(`currentAuthRequestedVisits-${currentValue}`);
  };

  const visitsValidate = (index: number) => {
    const currentValue = getValues(
      `reAuthDiscipline[${index}].currentRequestedVisit`
    );
    const nextValue = getValues(
      `reAuthDiscipline[${index}].nextRequestedVisit`
    );
    if (currentValue === 0 && nextValue === 0) {
      return "Can't proceed with 0 visits ";
    }
    return true;
  };

  return (
    <>
      <Box sx={CounterVisitsBox}>
        {index >= 0 ? (
          <>
            <FiMinusCircle
              onClick={handleDecreaseCounter}
              style={
                value > AllowedVisitCounts.MIN ? IconBox : IconDisableStyle
              }
              size={20}
            />
            <Box >
              <Controller
                name={name}
                control={control}
                defaultValue={[]}
                rules={{ validate: () => visitsValidate(index) }}
                render={({ field: { ref, ...fieldProps },
                  fieldState: { error }, }) => (<TextField
                    variant="standard"
                    {...fieldProps}
                    value={value}
                    sx={VisitsStyle}
                    fullWidth
                    inputProps={{
                      min: 0,
                      style: { textAlign: "center" },
                      maxLength: 4,
                    }}
                    inputRef={ref}
                    onInput={(e) => {
                      handleInputEvent(e, inputType);
                    }}
                    onChange={(event) => {
                      handleVisits(event)
                    }}
                    helperText={error ? error.message : ""}
                    error={error ? true : false}
                  />
                )
                }
              />
            </Box>
            <FiPlusCircle
              onClick={handleIncreaseCounter}
              style={
                value < AllowedVisitCounts.MAX ? IconBox : IconDisableStyle
              }
              size={20}
            />
          </>
        ) : (
          <>
            <FiMinusCircle style={IconDisableStyle} size={20} />
            <Box>
              <Typography
                variant="subtitle1"
                color={colors.fonts[25]}
                fontWeight={fontWeight.Weight[5]}
                sx={VisitCount}
              >
                {0}
              </Typography>
              <Divider sx={CounterDivider} />
            </Box>
            <FiPlusCircle style={IconDisableStyle} size={20} />
          </>
        )}
      </Box>
    </>
  );
};

export default ServiceRequest;

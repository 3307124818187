import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { getMasterEligibilityPayer } from "../../../../../actions/Master";
import PatientFilter from "../components/Filters";

const mapStateToProps = ({ payerState }: ApplicationState) => ({
  payerState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    getMasterEligibilityPayer: () => {
      dispatch(getMasterEligibilityPayer());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientFilter);

import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  GetReceivedMethod,
  GetRelationshipMaster,
  GetUrgencyStatusMaster,
  SavePatientInformationData,
  UpdateRemovePatient,
} from "../services/Patient";
import { ApplicationState } from "../store/ApplicationState";
import {
  RelationshipDispatchTypes,
  RemovePatientDispatchTypes,
  SavePatientInformationDispatchTypes,
  SaveReceivedMethodDispatchTypes,
  UrgencyStatusDispatchTypes,
} from "../models/DispatchTypes/Patient";
import {
  RelationshipActionDispatchTypes,
  RemovePatientActionDispatchTypes,
  SavePatientInformationActionDispatchTypes,
  SaveReceivedMethodActionDispatchTypes,
  UrgencyStatusActionDispatchTypes,
} from "../constants/Patient";
import { configUrlForReferral } from "../constants/Config";
import { MessageType } from "../constants/AllPatientRecord";
import { ReferralResponseMessages } from "../constants/AllPatientRecord";
import SnackbarUtils from "../utils/SnackbarProvider";

export const SavepatientInformationCall: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SavePatientInformationDispatchTypes
  >
> = (data: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SavePatientInformationActionDispatchTypes.SAVE_PATIENT_INFORMATION_INVOKE,
    });
    const response = await SavePatientInformationData(
      configUrlForReferral,
      data
    );
    dispatch({
      response,
      type: SavePatientInformationActionDispatchTypes.SAVE_PATIENT_INFORMATION_SUCCESS,
    });
  };
};

export const getReceivedMethodMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SaveReceivedMethodDispatchTypes
  >
> = (serviceRequestTypeId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SaveReceivedMethodActionDispatchTypes.SAVE_RECEIVED_METHOD_INVOKE,
    });
    const response = await GetReceivedMethod(
      configUrlForReferral,
      serviceRequestTypeId
    );
    dispatch({
      response,
      type: SaveReceivedMethodActionDispatchTypes.SAVE_RECEIVED_METHOD_SUCCESS,
    });
  };
};

//urgency status
export const getUrgencyStatusMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UrgencyStatusDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UrgencyStatusActionDispatchTypes.URGENCY_STATUS_INVOKE,
    });
    const response = await GetUrgencyStatusMaster(configUrlForReferral);
    dispatch({
      response,
      type: UrgencyStatusActionDispatchTypes.URGENCY_STATUS_SUCCESS,
    });
  };
};

//relationship
export const getRelationshipMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RelationshipDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RelationshipActionDispatchTypes.RELATIONSHIP_INVOKE,
    });
    const response = await GetRelationshipMaster(configUrlForReferral);
    dispatch({
      response,
      type: RelationshipActionDispatchTypes.RELATIONSHIP_SUCCESS,
    });
  };
};

export const updateRemovePatient: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RemovePatientDispatchTypes
  >
> = (payload: any, staffId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RemovePatientActionDispatchTypes.REMOVE_PATIENT_INVOKE,
    });
    const response = await UpdateRemovePatient(
      configUrlForReferral,
      payload,
      staffId
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
        case 500:
          dispatch({
            response,
            type: RemovePatientActionDispatchTypes.REMOVE_PATIENT_ERROR,
          });
          SnackbarUtils.error(
            ReferralResponseMessages(MessageType.REMOVE_PATIENT_ERROR)
          );
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: RemovePatientActionDispatchTypes.REMOVE_PATIENT_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.REMOVE_PATIENT_SUCCESS)
          );
          break;
        default:
          break;
      }
    }
  };
};

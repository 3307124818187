import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import RoleManagement from "../components";
import {
  deleteRole,
  deleteRoleReset,
  getRoleManagementList,
} from "../../../../../actions/Roles";
import { RoleManagementDispatchTypes } from "../../../../../models/DispatchTypes/Roles";
import { RoleManagementResponse } from "../../../../../models/Api/Roles";
import { DeleteResponse } from "../../../../../models/Api/Delete";

const mapStateToProps = ({
  roleManagementListState,
  isDeleted,
}: ApplicationState) => ({
  roleManagementList: roleManagementListState as RoleManagementResponse,
  isDeleted: isDeleted as DeleteResponse,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<RoleManagementDispatchTypes>
  >
) => {
  return {
    getRoleManagementList: () => {
      dispatch(getRoleManagementList());
    },
    deleteRole: (roleId: number) => {
      dispatch(deleteRole(roleId));
    },
    deleteRoleReset: () => {
      dispatch(deleteRoleReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);

import { Reducer } from "redux";

import { NotesApprovalStatusMasterResponse } from "../models/Api/Master";
import { NotesApprovalStatusMasterDispatchTypes } from "../models/DispatchTypes/Master";
import { NotesApprovalStatusMasterActionDispatchTypes } from "../constants/Master";

const initialState: NotesApprovalStatusMasterResponse = {
  loading: false,
  response: [],
};
const NotesApprovalStatusMasterReducer: Reducer<
  NotesApprovalStatusMasterResponse,
  NotesApprovalStatusMasterDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case NotesApprovalStatusMasterActionDispatchTypes.NOTES_APPROVAL_STATUS_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case NotesApprovalStatusMasterActionDispatchTypes.NOTES_APPROVAL_STATUS_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default NotesApprovalStatusMasterReducer;

import { Box } from "@mui/system";

import Header from "../containers/Header";

const AuthorizationDetails: React.FC<any> = () => {
  return (
    <>
      <Box>
        <Header />
      </Box>
    </>
  );
};

export default AuthorizationDetails;

import { Reducer } from "redux";

import { CreatedByResponse } from "../models/Api/Master";
import { CreatedByDispatchTypes } from "../models/DispatchTypes/Master";
import { CreatedByActionDispatchTypes } from "../constants/Master";

const initialState: CreatedByResponse = {
  loading: false,
  response: [
    {
      userId: 0,
      fullName: "",
    },
  ],
};

const CreatedByReducer: Reducer<CreatedByResponse, CreatedByDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CreatedByActionDispatchTypes.CREATED_BY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CreatedByActionDispatchTypes.CREATED_BY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default CreatedByReducer;

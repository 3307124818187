import { Dispatch, ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  GetPatientInformation,
  SaveInformationReceived,
  UpdatePatientHeader,
} from "../services/PatientInformation";
import {
  PatientInformationDispatchType,
  PatientInformationReceivedDispatchType,
  UpdatePatientHeaderDispatchTypes,
} from "../models/DispatchTypes/PatientInformation";
import { ApplicationState } from "../store/ApplicationState";
import {
  PatientInformationActionDispatchTypesView,
  PatientInformationReceivedActionDispatchTypes,
} from "../constants/PatientInformation";
import { configUrlForReferral } from "../constants/Config";
import { PatientInformationView, SaveInformation } from "../models/Patient";
import SnackbarUtils from "../utils/SnackbarProvider";
import { MessageType, ResponseMessages } from "../constants/ToastMessage";
import { EligibilityDataDispatchTypes } from "../constants/EligibilityData";
import { EligibilityDataActionDispatchTypes } from "../models/DispatchTypes/EligibilityData";

export const getPatients: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<PatientInformationDispatchType>
  >
> = (id: number, type: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_INVOKE,
    });
    const response = await GetPatientInformation(
      configUrlForReferral,
      id,
      type
    );
    dispatch({
      response,
      type: PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_SUCCESS,
    });
  };
};

export const patientInformationReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<PatientInformationDispatchType>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
    });
  };
};

export const SaveInformationReceivedData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<PatientInformationReceivedDispatchType>
  >
> = (info: SaveInformation) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_INVOKE,
    });
    const response = await SaveInformationReceived(configUrlForReferral, info);
    dispatch({
      response,
      type: PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_SUCCESS,
    });
  };
};

export const updatePatientHeader: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdatePatientHeaderDispatchTypes
  >
> = (payload: PatientInformationView) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientInformationActionDispatchTypesView.UPDATE_PATIENT_INFORMATION_INVOKE,
    });

    const response = await UpdatePatientHeader(configUrlForReferral, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: PatientInformationActionDispatchTypesView.UPDATE_PATIENT_INFORMATION_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: PatientInformationActionDispatchTypesView.UPDATE_PATIENT_INFORMATION_SUCCESS,
          });
          SnackbarUtils.success(
            ResponseMessages(MessageType.UPDATE_PATIENT_HEADER_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const storeEligibilityData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<EligibilityDataActionDispatchTypes>
  >
> = (info: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      response:info,
      type: EligibilityDataDispatchTypes.ELIGIBILITY_DATA_SUCCESS,
    });
  };
};
import { Reducer } from "redux";

import { DischargeReasonActionDispatchTypes } from "../constants/Master";
import { DischargeReasonMasterResponse } from "../models/Api/Master";
import { DischargeReasonDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: DischargeReasonMasterResponse = {
  loading: false,
  response: [
    {
      dischargeReasonId: 0,
      dischargeReason: "",
    },
  ],
};

const DischargeReasonReducer: Reducer<
  DischargeReasonMasterResponse,
  DischargeReasonDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DischargeReasonActionDispatchTypes.DISCHARGE_REASON_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DischargeReasonActionDispatchTypes.DISCHARGE_REASON_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DischargeReasonReducer;

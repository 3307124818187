import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isEmpty, isNull, isUndefined } from "lodash";

import { ASC } from "../../../../constants/FunctionalUtils";
import {
  IncompletePatientSearchResponse,
  RemovePatientResponse,
} from "../../../../models/Api/Patient";
import { MyPatientResponse } from "../../../../models/Api/AllPatientRecord";
import {
  AllPatientDetails,
  County,
  IncompleteRequestSearchResp,
  MyPatient,
  PatientFilterOptions,
  PatientState,
} from "../../../../models/Patient";
import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";
import {
  ActionButton,
  ActiveTabStyle,
  AssignStaffButton,
  ClearButton,
  ClearButtonBox,
  ClearButtonGrid,
  Header,
  HeaderModuleName,
  Heading,
  InputCloseIcon,
  PatientBox,
  PatientContainerBox,
  PatientDetailBox,
  SearchedText,
  SelectedOption,
} from "../../styles/style";
import AllPending from "../../containers/AllPending";
import IncompleteRequests from "../../containers/IncompleteRequests";
import MyPatientFilter from "../../containers/Filter";
import SearchRecord from "./SearchRecords";
import { displayFlex, displayFlexEnd, flexAlignCentre } from "../../../../styles/mui/styles/display";
import { User, UserDetailsPayload } from "../../../../models/User";
import { ServiceProviderMaster } from "../../../../models/Service";
import {
  HealthPlanData,
  Labels,
  UserData,
  getFilterDropDownList,
  getPrefixChipName,
  incompleteRequestFilterBy,
  myPatientListFilterBy,
  patientListFilterBy,
  urlBuildEnum,
} from "../../../../constants/PatientList";
import {
  getCurrentTabName,
  getFilterPayloadAsPerTab,
  getPatientFilterPayload,
  getLocalStorage,
  getValue,
  isNil,
  length,
  setLocalStorage,
} from "../../../../utils";
import { IncompletePatientSearch } from "../../../../models/Patient";
import {
  getSortColumn,
  MyPatientActionDispatchTypes,
  SortColumn,
} from "../../../../constants/AllPatientRecord";
import Staffing from "../../containers/Staffing";
import {
  StaffingList,
  StaffingReferralDetail,
} from "../../../../models/Staffing";
import {
  RemoveStaffingPatientResponse,
  StaffingAssignStaffResponse,
  StaffingListResponse,
  StaffingUnassignStaffResponse,
} from "../../../../models/Api/Staffing";
import {
  ActionType,
  staffingListFilterBy,
} from "../../../../constants/Staffing";
import { errorMessageBoxHeight } from "../../styles/AllPendingStyles";
import Permission from "../../../Permission";
import { defaultServiceProviderMaster } from "../../../../reducers/ServiceProviderMaster";
import { defaultPatientState } from "../../../../reducers/PatientState";
import {
  IncompleteRequest,
  SearchFilterModel,
  StaffingQueue,
  Values,
} from "../../../../models/Filter";
import { AllPendingUnassignStaffResponse, FilterModel } from "../../../../models/Api/Filter";
import { initialResponseFilter } from "../../../../reducers/StoreFilterPayload";
import { defaultCounty } from "../../../../reducers/County";
import PrivateDutyAuth from "../../containers/PrivateDutyAuth";
import {
  getCurrentTabBtnDetails,
  myPatientTabs,
} from "../../../../constants/Filter";

export interface PropsFromState {
  activeTab: number;
  incompelteRequestRecord: IncompletePatientSearchResponse;
  myPatientsRecord: MyPatientResponse;
  serviceProviderList: ServiceProviderMaster[];
  user: UserDetailsPayload;
  removePatientState: RemovePatientResponse;
  staffingList: StaffingListResponse;
  myPatientUpdatedData: MyPatientResponse;
  assignStaffIncompleteRequestData: IncompletePatientSearchResponse;
  staffingAssignStaffData: StaffingAssignStaffResponse;
  staffingUnassignStaffData: StaffingUnassignStaffResponse;
  RemoveStaffingPatientState: RemoveStaffingPatientResponse;
  filterStatePayload: FilterModel | any;
  allPendingUnassignStaffData: AllPendingUnassignStaffResponse
}

export interface PropsFromDispatch {
  postMyPatientList: (payload?: SearchFilterModel) => void;
  postIncompletePatientList: (payload?: any) => void;
  getMyPatientListReset: (actionType: string[]) => void;
  postStaffingList: (payload: StaffingQueue) => void;
  storeFilterPayload: (type: number, payload?: any) => void;
}

type Props = PropsFromState & PropsFromDispatch;

const MyPatientDetails = ({
  activeTab,
  postIncompletePatientList,
  postMyPatientList,
  incompelteRequestRecord,
  myPatientsRecord,
  serviceProviderList,
  getMyPatientListReset,
  user,
  removePatientState,
  postStaffingList,
  staffingList,
  myPatientUpdatedData,
  assignStaffIncompleteRequestData,
  staffingAssignStaffData,
  staffingUnassignStaffData,
  RemoveStaffingPatientState,
  storeFilterPayload,
  filterStatePayload,
  allPendingUnassignStaffData
}: Props) => {
  const [patientRecord, setPatientRecord] = useState<MyPatient | null>(null);
  const [incompleteRequestRecord, setIncompleteRequestRecord] =
    useState<IncompletePatientSearch | null>(null);
  const [staffingRecord, setStaffingRecord] = useState<StaffingList | null>(
    null
  );
  const [fieldValues, setFieldValues] = useState<any>();
  const { response } = myPatientsRecord;
  const { response: incompleteRequestResponse } = incompelteRequestRecord;
  const { loading: staffingListLoading, response: staffingListResponse } =
    staffingList;
  const [openModal, setOpenModal] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [staffingAction, setStaffingAction] = useState<string | undefined>("");
  const [patientSelected, setPatientSelected] = useState<AllPatientDetails[]>(
    []
  );
  const [patientSelectedForIncomplete, setPatientSelectedForIncomplete] =
    useState<IncompleteRequestSearchResp[]>([]);
  const [staffingReferralSelected, setStaffingReferralSelected] = useState<
    StaffingReferralDetail[]
  >([]);
  const [page, setPage] = useState(1);
  const [checkRemovePatient, setCheckRemovePatient] = useState<boolean>(false);
  const [userName, setUserName] = useState<User>(UserData);
  const [multipleAssignToStaffId, setMultipleAssignToStaffId] = useState<
    User[] | []
  >([]);
  const [healthPlan, setHealthPlan] =
    useState<PatientFilterOptions>(HealthPlanData);
  const [serviceProviderNPIList, setServiceProviderNPIList] = useState(
    serviceProviderList ? serviceProviderList : []
  );
  const [patientStateSelected, setPatientStateSelected] =
    useState<PatientState | null>(defaultPatientState[0]);

  /**new filter changes */
  const [filterState, setFilterState] = useState<any>(
    filterStatePayload.response
  );
  const [filterPayload, setFilterPayload] = useState<SearchFilterModel>(
    filterStatePayload.response
  );
  const [selectedFilterByArray, setSelectedFilterByArray] = useState<any[]>([]);
  const [patientFilterBy, setPatientFilterBy] = useState<Values[] | undefined>(
    patientListFilterBy
  );
  const [myPatientFilterBy, setMyPatientFilterBy] = useState<
    Values[] | undefined
  >(myPatientListFilterBy);

  const [incompleteFilterBy, setIncompleteFilterBy] = useState<
    Values[] | undefined
  >(incompleteRequestFilterBy);
  const [staffingFilterBy, setStaffingFilterBy] = useState<
    Values[] | undefined
  >(staffingListFilterBy);

  const [privateDutyAuthFilterBy, setPrivateDutyAuthFilterBy] = useState<
    Values[] | undefined
  >(patientListFilterBy);

  const [countySelected, setCountySelected] = useState<County | null>(
    defaultCounty[0]
  );
  const [isDataStoredInCache, setIsDataStoredInCache] =
    useState<boolean>(false);
  const [currentTabDetails, setCurrentTabDetails] = useState<any>(
    myPatientTabs.myPatient
  );
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("");
 const { response: allPendingUnassignStaffResponse } = allPendingUnassignStaffData;
  const methods = useForm<any>({
    defaultValues: {
      firstName: "",
      lastName: "",
      filterBy: "",
      dynamic: "",
      assignedToStaffId: "",
      contains: true,
      pageNo: 1,
      pageSize: 10,
      sortColumn: getSortColumn(SortColumn.DATE_TIME_REQUESTED),
      sortOrder: ASC,
    },
    mode: "all",
  });

  const { control, setValue, getValues, watch } = methods;

  const handleClear = () => {
    setPage(1);
    setUserName(UserData);
    setHealthPlan(HealthPlanData);
    setMultipleAssignToStaffId([]);
    setErrorFlag(false);

    /**new filter changes */
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("filterBy", "");
    setValue("dynamic", "");
    setValue("pageNo", "1");
    setFieldValues("");
    const filterStorage = getLocalStorage("myPatientFilterStorage");
    if (filterStorage) {
      let filterData = {};
      let payload = filterStatePayload.response;
      switch (activeTab) {
        case 0:
          payload = {
            ...payload,
            [getCurrentTabName(activeTab)]: initialResponseFilter.myPatient,
          };
          storeFilterPayload(1, payload);
          setFilterPayload(payload);
          postMyPatientList({
            ...getPatientFilterPayload(initialResponseFilter.myPatient),
            assignUser: [user.userId.toString()],
          });
          filterData = {
            ...filterStorage,
            [getCurrentTabName(activeTab)]: {
              ...initialResponseFilter.myPatient,
              assignUser: [user.userId.toString()],
            },
          };
          break;
        case 1:
          payload = {
            ...payload,
            [getCurrentTabName(activeTab)]: {
              ...initialResponseFilter.allPending,
              urgencyStatus: null,
              serviceStatus: null,
            },
          };
          setFilterPayload(payload);
          storeFilterPayload(1, payload);
          postMyPatientList({
            ...getPatientFilterPayload(initialResponseFilter.allPending),
            urgencyStatus: null,
            serviceStatus: null,
          });
          filterData = {
            ...filterStorage,
            [getCurrentTabName(activeTab)]: {
              ...initialResponseFilter.allPending,
              urgencyStatus: null,
              serviceStatus: null,
            },
          };
          break;
        case 2:
          payload = {
            ...payload,
            [getCurrentTabName(activeTab)]:
              initialResponseFilter.incompleteRequest,
          };
          setFilterPayload(payload);
          storeFilterPayload(1, payload);

          postIncompletePatientList(
            getPatientFilterPayload(initialResponseFilter.incompleteRequest)
          );
          filterData = {
            ...filterStorage,
            [getCurrentTabName(activeTab)]:
              initialResponseFilter.incompleteRequest,
          };
          break;
        case 3:
          payload = {
            ...payload,
            [getCurrentTabName(activeTab)]: initialResponseFilter.staffingQueue,
          };
          setFilterPayload(payload);
          storeFilterPayload(1, payload);
          postStaffingList(
            getPatientFilterPayload(initialResponseFilter.staffingQueue)
          );
          filterData = {
            ...filterStorage,
            [getCurrentTabName(activeTab)]: initialResponseFilter.staffingQueue,
          };
          break;
        case 4:
          payload = {
            ...payload,
            [getCurrentTabName(activeTab)]: {
              ...initialResponseFilter.privateDutyAuth,
              serviceStatus: null,
            },
          };
          setFilterPayload(payload);
          storeFilterPayload(1, payload);
          postMyPatientList({
            ...getPatientFilterPayload(initialResponseFilter.privateDutyAuth),
            serviceStatus: null,
          });
          filterData = {
            ...filterStorage,
            [getCurrentTabName(activeTab)]: {
              ...initialResponseFilter.privateDutyAuth,
              serviceStatus: null,
            },
          };
          break;
        default:
          break;
      }
      setLocalStorage("myPatientFilterStorage", { ...filterData });
    }
  };

  const values = getValues();

  useEffect(() => {
    if (response) {
      setPatientRecord(response || null);
    }
    if (incompleteRequestResponse === undefined) {
      setIncompleteRequestRecord(null);
    } else if (incompleteRequestResponse.totalRows !== -1) {
      setIncompleteRequestRecord(incompleteRequestResponse || null);
    }
  }, [response, incompleteRequestResponse]);

  useEffect(() => {
    if (isUndefined(staffingListResponse)) {
      setStaffingRecord(null);
    } else if (staffingListResponse.totalRows !== -1) {
      setStaffingRecord(staffingListResponse);
    }
  }, [staffingListResponse]);

  useEffect(() => {
    const isResponse =
      getValue(
        myPatientUpdatedData,
        "response.searchResponse[0].referralId",
        0
      ) > 0 ||
      getValue(
        assignStaffIncompleteRequestData,
        "response.searchResponse[0].referralId",
        0
      ) > 0 ||
      getValue(
        staffingAssignStaffData,
        "response.searchResponse[0].referralId",
        0
      ) > 0 ||
      getValue(
        staffingUnassignStaffData,
        "response.searchResponse[0].referralId",
        0
      ) > 0 ||
      getValue(removePatientState, "response.searchResponse[0].referralId", 0) >
        0 ||
      getValue(
        RemoveStaffingPatientState,
        "response.searchResponse[0].referralId",
        0
      ) > 0 ||
      getValue(
        allPendingUnassignStaffResponse,
        "searchResponse[0].referralId",
        0
      ) > 0;
    if (isResponse) {
      handleSearchValidate("search");
    }
  }, [
    myPatientUpdatedData.response,
    assignStaffIncompleteRequestData.response,
    staffingAssignStaffData.response,
    staffingUnassignStaffData.response,
    removePatientState.response,
    RemoveStaffingPatientState.response,
    allPendingUnassignStaffResponse
  ]);

  useEffect(() => {
    !isNil(serviceProviderList)
      ? setServiceProviderNPIList(serviceProviderList)
      : setServiceProviderNPIList(defaultServiceProviderMaster);
  }, [serviceProviderList]);

  useEffect(() => {
    const filterStorage = getLocalStorage("myPatientFilterStorage");
    if (activeTab === 0 && !isNull(filterStorage)) {
      storeFilterPayload(1, filterStorage);
    }
    setCurrentTabDetails(getCurrentTabBtnDetails(activeTab));
  }, [activeTab]);

  const actionCall = (activeTab: number) => {
    const filterStorage = getLocalStorage("myPatientFilterStorage");
    let filterData = {};
    if (filterStorage && !isEmpty(filterStatePayload.response)) {
      switch (activeTab) {
        case 0:
          if ((user && user.userId > 0) || checkRemovePatient) {
            const payload = filterStatePayload.response;
            filterData = {
              ...filterStorage,
              myPatient: {
                ...payload.myPatient,
                assignUser: [user.userId.toString()],
              },
            };
            postMyPatientList({
              ...getPatientFilterPayload(payload.myPatient),
              assignUser: [user.userId.toString()],
            });
            if (
              filterStatePayload.response.myPatient &&
              !isEmpty(
                getValue(filterStatePayload, "response.myPatient.sortColumn") &&
                  !isUndefined(filterStatePayload.response.myPatient.sortColumn)
              )
            ) {
              setValueToOrderBy(
                filterStatePayload.response.myPatient.sortColumn
              );
            }
          }
          break;
        case 1:
          filterData = {
            ...filterStorage,
            allPending: filterStatePayload.response.allPending,
          };
          postMyPatientList(
            getPatientFilterPayload(filterStatePayload.response.allPending)
          );
          if (
            filterStatePayload.response.allPending &&
            !isEmpty(
              getValue(filterStatePayload, "response.allPending.sortColumn") &&
                !isUndefined(filterStatePayload.response.allPending.sortColumn)
            )
          ) {
            setValueToOrderBy(
              filterStatePayload.response.allPending.sortColumn
            );
          }
          break;
        case 2:
          filterData = {
            ...filterStorage,
            incompleteRequest: filterStatePayload.response.incompleteRequest,
          };
          postIncompletePatientList(
            getPatientFilterPayload(
              filterStatePayload.response.incompleteRequest
            )
          );
          if (
            filterStatePayload.response.incompleteRequest &&
            !isEmpty(
              getValue(
                filterStatePayload,
                "response.incompleteRequest.sortColumn"
              ) &&
                !isUndefined(
                  filterStatePayload.response.incompleteRequest.sortColumn
                )
            )
          ) {
            setValueToOrderBy(
              filterStatePayload.response.incompleteRequest.sortColumn
            );
          }
          break;
        case 3:
          if (checkRemovePatient === true) {
            setStaffingRecord(null);
            if (removePatientState.response.totalRows !== response.totalRows) {
              postStaffingList(
                getPatientFilterPayload(
                  filterStatePayload.response.staffingQueue
                )
              );
            }
          } else {
            if (!staffingListLoading && !openModal) {
              postStaffingList(
                getPatientFilterPayload(
                  filterStatePayload.response.staffingQueue
                )
              );
            }
            filterData = {
              ...filterStorage,
              staffingQueue: filterStatePayload.response.staffingQueue,
            };
            setStaffingRecord(null);
            setValue("assignUser", null);
          }
          setValueToOrderBy(
            filterStatePayload.response.staffingQueue.sortColumn
          );
          break;
        case 4:
          filterData = {
            ...filterStorage,
            privateDutyAuth: filterStatePayload.response.privateDutyAuth,
          };
          postMyPatientList(
            getPatientFilterPayload(filterStatePayload.response.privateDutyAuth)
          );
          if (
            filterStatePayload.response.privateDutyAuth &&
            !isEmpty(
              getValue(
                filterStatePayload,
                "response.privateDutyAuth.sortColumn"
              ) &&
                !isUndefined(
                  filterStatePayload.response.privateDutyAuth.sortColumn
                )
            )
          ) {
            setValueToOrderBy(
              filterStatePayload.response.privateDutyAuth.sortColumn
            );
          }
          break;
        default:
          break;
      }
      setLocalStorage("myPatientFilterStorage", { ...filterData });
    }
  };

  useEffect(() => {
    isDataStoredInCache && actionCall(activeTab);
  }, [
    isDataStoredInCache,
    activeTab,
    user,
    checkRemovePatient,
    removePatientState,
  ]);

  const setFilter = (filter: string) => {
    switch (filter) {
      case urlBuildEnum.HEALTHPLAN:
        setValue("healthPlanName", getValues("dynamic"));
        break;
      case urlBuildEnum.Urgency:
        setValue("urgencyStatus", getValues("dynamic"));
        break;
      case urlBuildEnum.ASSIGNEDTO:
        setValue("assignedUserId", getValues("assignedToStaffId"));
        break;
      case urlBuildEnum.GetPatientCounty:
        setValue("county", getValues("dynamic"));
        break;
      case urlBuildEnum.GetPatientStates:
        setValue("state", getValues("dynamic"));
        break;
      case urlBuildEnum.CREATEDBY:
        setValue("userCreated", getValues("dynamic"));
        break;
      case urlBuildEnum.StaffingStatus:
        setValue("staffingStatus", getValues("dynamic"));
        break;
      case urlBuildEnum.ServiceProvider:
        setValue("serviceProvider", getValues("dynamic"));
        break;
      default:
        break;
    }
  };
  /**---------------------------------------------------------------------------------------------------------------------------- */

  const storeInputValuesinPayloadOnSearch = (payload: any) => {
    storeFilterPayload(1, payload);
  };

  const handleSearchValidate = (clickType: string) => {
    if ("search" === clickType) {
      getMyPatientListReset([
        MyPatientActionDispatchTypes.POST_PATIENTLIST_RESET,
      ]);
      setPage(1);
      let payload: any = filterStatePayload.response;
      if (!isEmpty(payload)) {
        payload = getFilterPayloadAsPerTab(
          activeTab,
          filterStatePayload.response
        );
        payload = {
          ...payload,
          firstName: !isEmpty(getValues("firstName"))
            ? {
                label: getValues("firstName"),
                value: getValues("firstName"),
                prefix: "First Name",
                filterBy: "firstName",
              }
            : null,
          lastName: !isEmpty(getValues("lastName"))
            ? {
                label: getValues("lastName"),
                value: getValues("lastName"),
                prefix: "Last Name",
                filterBy: "lastName",
              }
            : null,
          pageNo: getValues("pageNo"),
          pageSize: getValues("pageSize"),
          sortColumn: getValues("sortColumn"),
          sortOrder:
            getValues("sortOrder").length !== 0
              ? getValues("sortOrder")
              : "ASC",
        };
      }
      storeInputValuesinPayloadOnSearch({
        ...filterStatePayload.response,
        [getCurrentTabName(activeTab)]: payload,
      });
      const filterStorage = getLocalStorage("myPatientFilterStorage");
      let filterData = {};
      if (filterStorage) {
        switch (activeTab) {
          case 0:
            filterData = {
              ...filterStorage,
              myPatient: {
                ...payload,
                assignUser: [getValue(user, "userId", "0").toString()],
              },
            };
            postMyPatientList({
              ...getPatientFilterPayload(payload),
              assignUser: [getValue(user, "userId", "0").toString()],
            });
            break;
          case 1:
            filterData = {
              ...filterStorage,
              allPending: {
                ...payload,
              },
            };
            postMyPatientList({
              ...getPatientFilterPayload(payload),
            });
            break;
          case 2:
            filterData = {
              ...filterStorage,
              incompleteRequest: payload,
            };
            postIncompletePatientList(getPatientFilterPayload(payload));
            break;
          case 3:
            filterData = {
              ...filterStorage,
              staffingQueue: payload,
            };
            postStaffingList(getPatientFilterPayload(payload));
            break;
          case 4:
            filterData = {
              ...filterStorage,
              privateDutyAuth: {
                ...payload,
              },
            };
            postMyPatientList({
              ...getPatientFilterPayload(payload),
            });
            break;
          default:
            break;
        }
        setLocalStorage("myPatientFilterStorage", { ...filterData });
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(filterStatePayload.response)) {
      const payload = getFilterPayloadAsPerTab(
        activeTab,
        filterStatePayload.response
      );
      pushRemovedFilterOptionIntoDropdown(payload);
      setFilterPayload({
        ...filterPayload,
        [getCurrentTabName(activeTab)]: payload,
      });
      setIsDataStoredInCache(true);
      setValueToOrderBy(getValue(payload, "sortColumn", ""));
    }
  }, [filterStatePayload.response]);

  const pushRemovedFilterOptionIntoDropdown = (
    payload: MyPatient | IncompleteRequest | StaffingQueue | any
  ) => {
    const arr: any = [];
    const filterList: any = getFilterDropDownList(activeTab);
    filterList &&
      filterList.forEach((element: Values) => {
        if (isEmpty(getValue(payload, `${element.paramLabel}`, ""))) {
          const shouldPushElement =
            ((activeTab === 0 || activeTab === 1 || activeTab === 4) &&
              element.paramLabel === "serviceStatusReason" &&
              !isEmpty(payload) &&
              !isEmpty(payload.serviceStatus) &&
              getValue(payload, "serviceStatus.haveStatusReason")) ||
            element.paramLabel !== "serviceStatusReason" ||
            activeTab === 2 ||
            activeTab === 3;
          if (shouldPushElement) {
            arr.push(element);
          }
        }
      });

    switch (activeTab) {
      case 0:
        setMyPatientFilterBy(arr);
        break;
      case 1:
        setPatientFilterBy(arr);
        break;
      case 2:
        setIncompleteFilterBy(arr);
        break;
      case 3:
        setStaffingFilterBy(arr);
        break;
      case 4:
        setPrivateDutyAuthFilterBy(arr);
        break;
      default:
        break;
    }
  };
  const clearInputField = (fieldName: string, chip: string) => {
    if (chip === fieldName) {
      setValue(fieldName, "");
    }
  };

  const handleClearField = (chip: any) => {
    let payload = filterStatePayload.response;
    if (!isEmpty(payload)) {
      payload = getFilterPayloadAsPerTab(
        activeTab,
        filterStatePayload.response
      );

      payload =
        (activeTab === 0 || activeTab === 1 || activeTab === 4) &&
        chip.filterBy === "serviceStatus"
          ? {
              ...payload,
              [chip.filterBy]: null,
              serviceStatusReason: null,
            }
          : {
              ...payload,
              [chip.filterBy]: null,
            };

      clearInputField("firstName", chip.filterBy);
      clearInputField("lastName", chip.filterBy);

      setFilterPayload({
        ...filterStatePayload.response,
        [getCurrentTabName(activeTab)]: payload,
      });
      storeFilterPayload(1, {
        ...filterStatePayload.response,
        [getCurrentTabName(activeTab)]: payload,
      });
      pushRemovedFilterOptionIntoDropdown(
        getFilterPayloadAsPerTab(activeTab, {
          ...filterStatePayload.response,
          [getCurrentTabName(activeTab)]: payload,
        })
      );
    }
  };
  const renderDynamicChip = (item: any) => {
    if (item.prefix === Labels.ASSIGNED_TO) {
      const labelArray: string[] = item.label;
      const labelString: string = labelArray.join(" , ");
      return `${item.prefix}-${labelString}`;
    } else {
      return `${item.prefix}-${item.label}`;
    }
  };
  /**new filter chanegs */
  useEffect(() => {
    const filteredArray: Values[] = [];
    let tab = filterState && filterState.response;
    if (!isEmpty(filterState) && !isEmpty(filterState.response)) {
      tab = getFilterPayloadAsPerTab(activeTab, filterState.response);
      for (const name in tab) {
        if (
          tab &&
          Object.prototype.hasOwnProperty.call(tab, name) &&
          typeof tab[name] === "object" &&
          tab[name] != null  && 
          !Array.isArray(tab[name])
        ) {
          filteredArray.push({ 
            ...tab[name],
            filterBy: name,
            prefix: getPrefixChipName(name, activeTab),
          });
          if (name === "firstName") {
            setValue("firstName", tab[name].value);
          } else if (name === "lastName") {
            setValue("lastName", tab[name].value);
          }
        }
      }
    }

    if (selectedFilterByArray > filteredArray) {
      actionCall(activeTab);
    }

    setSelectedFilterByArray(filteredArray);
  }, [filterState]);

  /**---------------------------------------------------------------------------------------------------------------------------- */
  const handleAssignStaff = (action?: string) => {
    if (activeTab === 0 || activeTab === 1 || activeTab === 4) {
      if (!length(patientSelected)) {
        setErrorFlag(true);
        setStaffingAction(action);
      }
      setStaffingAction(action);
      length(patientSelected) && setOpenModal((openModal) => !openModal);
    } else if (activeTab === 2) {
      if (!length(patientSelectedForIncomplete)) {
        setErrorFlag(true);
        setStaffingAction(action);
      }
      setStaffingAction(action);
      length(patientSelectedForIncomplete) &&
        setOpenModal((openModal) => !openModal);
    } else if (activeTab === 3) {
      if (!length(staffingReferralSelected)) {
        setErrorFlag(true);
        setStaffingAction(action);
      }
      length(staffingReferralSelected) &&
        setOpenModal((openModal) => !openModal);
      setStaffingAction(action);
    }
  };

  const errorMessage = () => {
    return (
      <Box pt={".5rem"} sx={flexAlignCentre} pl={1}>
        <Typography
          variant="subtitle1"
          color={colors.red[100]}
          fontWeight={fontWeight.Weight[3]}
        >
          *
        </Typography>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.red[100]}
        >
          Please select the staff record
        </Typography>
      </Box>
    );
  };
  const getActionButtonUi = (actionType: string) => {
    return (
      <Box sx={AssignStaffButton}>
        <Button
          variant="contained"
          sx={ActionButton}
          onClick={() => handleAssignStaff(actionType)}
        >
          {actionType}
        </Button>
        <Box sx={errorMessageBoxHeight}>
          {staffingAction === actionType && errorFlag && errorMessage()}
        </Box>
      </Box>
    );
  };
  const getActionButton = (actionType: string, controlId: string) => {
    return (
      <Box>
        {isEmpty(controlId) ? (
          getActionButtonUi(actionType)
        ) : (
          <Permission controlId={controlId}>
            {getActionButtonUi(actionType)}
          </Permission>
        )}
      </Box>
    );
  };

  const clearButton = () => {
    return (
      !isEmpty(selectedFilterByArray) &&
      length(selectedFilterByArray) && (
        <Grid item xs={"auto"} sx={ClearButtonGrid}>
          <Button variant="outlined" onClick={handleClear} sx={ClearButton}>
            CLEAR
          </Button>
        </Grid>
      )
    );
  };

  return (
    <>
      <PatientContainerBox>
        <PatientBox>
          <MyPatientFilter
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            handleSearchValidate={handleSearchValidate}
            patientRecords={patientRecord}
            setFieldValues={setFieldValues}
            setValue={setValue}
            values={values}
            watch={watch}
            multipleAssignToStaffId={multipleAssignToStaffId}
            setMultipleAssignToStaffId={setMultipleAssignToStaffId}
            userName={userName}
            setUserName={setUserName}
            healthPlan={healthPlan}
            setHealthPlan={setHealthPlan}
            serviceProviderNPIList={serviceProviderNPIList}
            setServiceProviderNPIList={setServiceProviderNPIList}
            patientStateSelected={patientStateSelected}
            setPatientStateSelected={setPatientStateSelected}
            countySelected={countySelected}
            setCountySelected={setCountySelected}
            setErrorFlag={setErrorFlag}
            /**new filter changes */
            updatedFilterState={setFilterState}
            setPatientFilterBy={setPatientFilterBy} //set state
            patientFilterBy={patientFilterBy} //set value
            setFilterPayload={setFilterPayload}
            filterPayload={filterPayload}
            setMyPatientFilterBy={setMyPatientFilterBy}
            myPatientFilterBy={myPatientFilterBy}
            setIncompleteFilterBy={setIncompleteFilterBy}
            incompleteFilterBy={incompleteFilterBy}
            setStaffingFilterBy={setStaffingFilterBy}
            staffingFilterBy={staffingFilterBy}
            setPrivateDutyAuthFilterBy={setPrivateDutyAuthFilterBy}
            privateDutyAuthFilterBy={privateDutyAuthFilterBy}
          />
        </PatientBox>
        <PatientDetailBox>
          <Grid container sx={Header}>
            <Grid item sx={Heading}>
              <Box pr={"0.8rem"}>
                <Typography
                  color={colors.black[3]}
                  fontWeight={fontWeight.Weight[6]}
                  sx={HeaderModuleName}
                  variant="h4"
                >
                  RECORDS:
                </Typography>
                <Typography
                  color={colors.fonts[11]}
                  fontWeight={fontWeight.Weight[1]}
                  variant="subtitle2"
                >
                  {activeTab === 3
                    ? "List of patient records in staffing queue"
                    : "List of patient records modified recently."}
                </Typography>
              </Box>
              {!isEmpty(selectedFilterByArray) &&
                length(selectedFilterByArray) && (
                  <Divider orientation="vertical" flexItem />
                )}
            </Grid>
            <Grid item xs sx={SearchedText(activeTab)}>
              <Grid container>
                {!isEmpty(selectedFilterByArray) &&
                  length(selectedFilterByArray) &&
                  selectedFilterByArray.map((item: any, index: number) => {
                    return (
                      <Box key={index} sx={SelectedOption()}>
                        <Box sx={flexAlignCentre}>
                          <Typography
                            color={colors.fonts[18]}
                            fontWeight={fontWeight.Weight[5]}
                            variant="body2"
                          >
                            {renderDynamicChip(item)}
                          </Typography>
                        </Box>
                        <Typography
                          color={colors.fonts[18]}
                          fontWeight={fontWeight.Weight[6]}
                          ml={"0.1rem"}
                        >
                          <IoIosClose
                            onClick={() => handleClearField(item)}
                            size="19"
                            style={InputCloseIcon}
                          />
                        </Typography>
                      </Box>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item sx={ActiveTabStyle}>
              <Grid
                container
                sx={displayFlexEnd}
              >
                <Box sx={displayFlex}>
                  <Box sx={ClearButtonBox}>{clearButton()}</Box>
                  {currentTabDetails.assignButton && (
                    <Box>
                      {getActionButton(
                        ActionType.ASSIGN_STAFF,
                        currentTabDetails.assignButtonPermission
                      )}
                    </Box>
                  )}
                  {currentTabDetails.unAssignButton && (
                    <Box>
                      {getActionButton(
                        ActionType.UNASSIGN_STAFF,
                        currentTabDetails.unAssignButtonPermission
                      )}
                    </Box>
                  )}
                  {currentTabDetails.remmoveListButton && (
                    <Box>
                      {getActionButton(
                        ActionType.REMOVE_FROM_LIST,
                        currentTabDetails.remmoveListButtonPermission
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {activeTab === 0 && (
            <SearchRecord
              activeTab={activeTab}
              checkRemovePatient={checkRemovePatient}
              fieldValues={fieldValues}
              postMyPatientList={postMyPatientList}
              openModal={openModal}
              errorFlag={errorFlag}
              patientRecord={patientRecord ? patientRecord : null}
              patientSelected={patientSelected}
              setCheckRemovePatient={setCheckRemovePatient}
              setOpenModal={setOpenModal}
              setErrorFlag={setErrorFlag}
              setPatientSelected={setPatientSelected}
              setValue={setValue}
              user={user}
              getValues={getValues}
              setPage={setPage}
              page={page}
              setSelectedFilterByArray={setSelectedFilterByArray}
              storeFilterPayload={storeFilterPayload}
              filterState={filterStatePayload}
              valueToOrderBy={valueToOrderBy}
              setValueToOrderBy={setValueToOrderBy}
              handleClear={handleClear}
              action={staffingAction ? staffingAction : ""}
              getMyPatientListReset={getMyPatientListReset}
            />
          )}
          {activeTab === 1 && (
            <AllPending
              patientRecord={patientRecord ? patientRecord : null}
              setValue={setValue}
              errorFlag={errorFlag}
              setErrorFlag={setErrorFlag}
              patientSelected={patientSelected}
              setPatientSelected={setPatientSelected}
              handleClear={handleClear}
              openModal={openModal}
              setOpenModal={setOpenModal}
              fieldValues={fieldValues}
              activeTab={activeTab}
              getValues={getValues}
              setPage={setPage}
              page={page}
              action={staffingAction ? staffingAction : ""}
              setSelectedFilterByArray={setSelectedFilterByArray}
              storeFilterPayload={storeFilterPayload}
              valueToOrderBy={valueToOrderBy}
              setValueToOrderBy={setValueToOrderBy}
              getMyPatientListReset={getMyPatientListReset}
            />
          )}
          {activeTab === 2 && (
            <IncompleteRequests
              patientRecord={
                incompleteRequestRecord ? incompleteRequestRecord : null
              }
              activeTab={activeTab}
              fieldValues={fieldValues}
              postIncompletePatientList={postIncompletePatientList}
              handleClear={handleClear}
              openModal={openModal}
              errorFlag={errorFlag}
              setOpenModal={setOpenModal}
              setErrorFlag={setErrorFlag}
              setValue={setValue}
              getValues={getValues}
              setPage={setPage}
              page={page}
              setFilter={setFilter}
              patientSelectedForIncomplete={patientSelectedForIncomplete}
              setPatientSelectedForIncomplete={setPatientSelectedForIncomplete}
              action={staffingAction ? staffingAction : ""}
              setSelectedFilterByArray={setSelectedFilterByArray}
              storeFilterPayload={storeFilterPayload}
              valueToOrderBy={valueToOrderBy}
              setValueToOrderBy={setValueToOrderBy}
            />
          )}
          {activeTab === 3 && (
            <Staffing
              patientRecord={staffingRecord ? staffingRecord : null}
              setValue={setValue}
              errorFlag={errorFlag}
              setErrorFlag={setErrorFlag}
              staffingReferralSelected={staffingReferralSelected}
              setStaffingReferralSelected={setStaffingReferralSelected}
              handleClear={handleClear}
              openModal={openModal}
              setOpenModal={setOpenModal}
              fieldValues={fieldValues}
              activeTab={activeTab}
              getValues={getValues}
              setPage={setPage}
              setFilter={setFilter}
              page={page}
              action={staffingAction ? staffingAction : ""}
              setCheckRemovePatient={setCheckRemovePatient}
              setSelectedFilterByArray={setSelectedFilterByArray}
              storeFilterPayload={storeFilterPayload}
              valueToOrderBy={valueToOrderBy}
              setValueToOrderBy={setValueToOrderBy}
            />
          )}
          {activeTab === 4 && (
            <PrivateDutyAuth
              privateDutyAuthRecord={patientRecord ? patientRecord : null}
              setValue={setValue}
              errorFlag={errorFlag}
              setErrorFlag={setErrorFlag}
              patientSelected={patientSelected}
              setPatientSelected={setPatientSelected}
              handleClear={handleClear}
              openModal={openModal}
              setOpenModal={setOpenModal}
              activeTab={activeTab}
              getValues={getValues}
              setPage={setPage}
              page={page}
              action={staffingAction ? staffingAction : ""}
              setSelectedFilterByArray={setSelectedFilterByArray}
              storeFilterPayload={storeFilterPayload}
              valueToOrderBy={valueToOrderBy}
              setValueToOrderBy={setValueToOrderBy}
              postMyPatientList={postMyPatientList}
              getMyPatientListReset={getMyPatientListReset}
            />
          )}
        </PatientDetailBox>
      </PatientContainerBox>
    </>
  );
};

export default MyPatientDetails;

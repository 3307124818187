import React, { ChangeEvent, useState, useEffect, SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  alpha,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FileSaver from "file-saver";
import { isNil } from "lodash";

import { Switch } from "../../../../../components/custom/switch";
import {
  ItemMainUM,
  ButtonStyle,
  ExportIcon,
  AutoCompleteBootom,
  AutoComplete,
  IconFaSearch,
  Search,
  FilterIcon,
  DividerHeight,
  IconFilter,
  IconFaUndo,
  IconFaFilePdf,
  GridList,
  SummaryContainerBody,
  PatientSearchLabel,
} from "../styles/userProfile";
import UserList from "../containers/UserList";
import { User } from "../../../../../models/User";
import { ACTIVE, SUSPENDED, USER_MANAGEMENT_DESCRIPTION } from "../../../../../constants/User";
import { Head, Main, Body } from "../../../../../components/custom/structure";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { ExcelDownloadResponse } from "../../../../../models/Api/Document";
import {
  MessageType,
  NO_RECORD_FOUND,
} from "../../../../../constants/ToastMessage";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import { ReferralResponseMessages } from "../../../../../constants/AllPatientRecord";
import { length } from "../../../../../utils";
import useMenuItem from "../../../../../hooks/useMenuItem";
import { PatientDataTableBody } from "../../../../AllPatientRecords/routes/PatientRecords/styles/style";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

const label = { inputProps: { "aria-label": "Switch" } };

export interface PropsFromDispatch {
  getUserManagmentList: () => void;
  excelDownload: () => void;
  excelDownloadReset: () => void;
}
export interface PropsFromState {
  userList: User[];
  loading: boolean;
  documentDownloadData: ExcelDownloadResponse;
}
type AllProps = PropsFromState & PropsFromDispatch;

const UserManagement: React.FC<AllProps> = ({
  userList,
  loading,
  getUserManagmentList,
  excelDownload,
  documentDownloadData,
  excelDownloadReset,
}) => {
  const [userManagementList, setUserManagementList] = useState(userList);
  const [userName, setUserName] = useState<User | undefined | null>();
  const [isActive, setIsActive] = useState<string>(ACTIVE);
  const [toggleChecked, setToggleChecked] = useState(true);
  const [filteredUserList, setFilteredUserList] = useState(userList);
  const { open, onOpen, onClose } = useMenuItem();

  const {
    response: documentDownloadResponse,
    error: documentDownloadError,
    loading: documentDownloadLoading,
  } = documentDownloadData;

  const defaultProps = (userManagementList: User[]) => ({
    options: userManagementList as User[],
    getOptionLabel: (option: User) => option.fullName,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param e is mandatory
  const onSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: User | null,
    reason: string
  ) => {
    setUserName(value);
    onClose();
    setUserManagementList(
      value != null && !isNil(userList)
        ? userList.filter(
          (v: User) =>
            v.fullName === value.fullName && v.userId === value.userId
        )
        : userList
    );

    if (reason === "clear") {
      setUserManagementList(
        getFilteredList(toggleChecked ? ACTIVE : SUSPENDED)
      );
    }
  };

  const toggleUserStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(undefined);
    const userStatus = event.target.checked;
    setToggleChecked(userStatus);
    setFilterByStatus(userStatus);
  };

  const setFilterByStatus = (value: boolean) => {
    if (value) {
      setIsActive(ACTIVE);
      setUserManagementList(getFilteredList(ACTIVE));
      setFilteredUserList(getFilteredList(ACTIVE));
    } else {
      setIsActive(SUSPENDED);
      setUserManagementList(getFilteredList(SUSPENDED));
      setFilteredUserList(getFilteredList(SUSPENDED));
    }
  };

  const getFilteredList = (value: string) => {
    const users = userList.filter((v: User) => v.userStatus === value);

    return users;
  };

  const onClearFilter = () => {
    setUserName(undefined);
    setUserManagementList(getFilteredList(ACTIVE));
    setIsActive(ACTIVE);
    setToggleChecked(true);
  };

  useEffect(() => {
    getUserManagmentList();
  }, []);

  useEffect(() => {
    if (!loading && Array.isArray(userList)) {
      if (length(userList)) {
        setUserManagementList(getFilteredList(ACTIVE));
      }
    }
  }, [loading]);

  useEffect(() => {
    if (documentDownloadResponse && documentDownloadResponse.size) {
      const blob = URL.createObjectURL(documentDownloadResponse);
      FileSaver.saveAs(blob, "UserDetails.xlsx");
      URL.revokeObjectURL(blob);
      excelDownloadReset();
    } else if (
      documentDownloadError &&
      documentDownloadError.error &&
      Object.keys(documentDownloadError.error).length == 0
    ) {
      SnackbarUtils.error(
        ReferralResponseMessages(MessageType.DOCUMENT_DOWNLOAD_ERROR)
      );
    }
  }, [documentDownloadResponse, documentDownloadError]);

  useEffect(() => {
    setFilteredUserList(getFilteredList(ACTIVE));
  }, [userList]);

  const downloadExcel = () => {
    excelDownload();
  };

  const validation = () => {
    return (
      <Box display="flex" justifyContent={"center"}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody sx={PatientDataTableBody}>
            <TableRow>
              <TableCell align="center" colSpan={8}>
                {NO_RECORD_FOUND}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <>
      <Main>
        <Head
          title={"USER MANAGEMENT"}
          description={ USER_MANAGEMENT_DESCRIPTION }
        >
          <Box sx={ExportIcon}>
            <Button
              sx={ButtonStyle(documentDownloadLoading)}
              onClick={() => downloadExcel()}
              disabled={documentDownloadLoading}
              data-testid="userManagement-exportToPdf"
            >
              <IconFaFilePdf
                color={
                  documentDownloadLoading ? colors.white[1] : colors.fonts[18]
                }
                size={15}
              />
              <Typography
                variant="subtitle2"
                color={
                  documentDownloadLoading ? colors.white[1] : colors.fonts[18]
                }
                fontWeight={fontWeight.Weight[5]}
              >
                Export To Excel
              </Typography>
            </Button>
          </Box>
        </Head>

        <Body>
          <Grid item xs={12}>
            <ItemMainUM>
              <Box sx={AutoCompleteBootom}>
                <Grid container sx={SummaryContainerBody}>
                  <Grid item xs={12}>
                    <Box sx={PatientSearchLabel}>
                      <Typography
                        variant="subtitle2"
                        color={colors.fonts[2]}
                        fontWeight={fontWeight.Weight[3]}
                      >
                        Search user:
                      </Typography>
                    </Box>
                    <Autocomplete
                      sx={AutoComplete}
                      {...defaultProps(filteredUserList)}
                      id="auto-complete"
                      autoComplete
                      onChange={(e, v, reason) => {
                        onSelection(e, v, reason);
                      }}
                      includeInputInList
                      value={userName || null}
                      noOptionsText={NO_RECORD_FOUND}
                      PopperComponent={StyledPopperCheckBoxAutocomplete}
                      disabled={isNil(userManagementList) ? true : false}
                      isOptionEqualToValue={(option, value) =>
                        option.fullName === value.fullName
                      }
                      renderInput={(params) => {
                        params.inputProps.maxLength = 50;
                        return (
                          <Box sx={Search}>
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Search for user"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      <IconFaSearch />
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          </Box>
                        );
                      }}
                      onInputChange={(_event, value) =>
                        value !== "" ? onOpen() : onClose()
                      }
                      open={open}
                      onOpen={() => onOpen()}
                      onClose={() => onClose()}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.userId}>
                            {option.fullName}
                          </li>
                        );
                      }}
                      data-testid="userManagement-autocomplete"
                    />
                  </Grid>
                </Grid>
                <Grid container sx={SummaryContainerBody}>
                  <Grid item xs={12}>
                    <Box sx={FilterIcon}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        marginTop={1}
                      >
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          textAlign="center"
                          flexItem
                          sx={DividerHeight}
                        />
                        <IconFilter />
                        <Typography
                          variant="subtitle1"
                          color={colors.black[5]}
                          fontWeight={fontWeight.Weight[4]}
                        >
                          Filter by status:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={alpha(colors.black[4], 0.8)}
                          fontWeight={fontWeight.Weight[4]}
                          ml={"1rem"}
                          data-testid="userManagement-userStatus"
                        >
                          {isActive}
                        </Typography>
                        <Switch
                          {...label}
                          disabled={isNil(userManagementList) ? true : false}
                          onChange={toggleUserStatus}
                          checked={toggleChecked}
                          dataTestId={"userManagement-status"}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={ExportIcon}>
                <Button
                  sx={ButtonStyle(false)}
                  onClick={onClearFilter}
                  data-testid="userManagement-filter"
                  disabled={isNil(userManagementList) ? true : false}
                >
                  <IconFaUndo />
                  <Typography
                    variant="subtitle2"
                    color={colors.fonts[18]}
                    fontWeight={fontWeight.Weight[5]}
                  >
                    Reset Filter
                  </Typography>
                </Button>
              </Box>
            </ItemMainUM>
          </Grid>
          <Grid item xs={2}></Grid>
          <GridList item xs={12}>
            {!loading ? (
              userManagementList &&
                userManagementList.length > 0 &&
                userManagementList[0].userId !== -1 ? (
                <UserList list={userManagementList} />
              ) : (
                validation()
              )
            ) : null}
          </GridList>
        </Body>
      </Main>
    </>
  );
};

export default UserManagement;

import { DeleteResponse } from "../models/Api/Delete";
import { Reducer } from "redux";
import { RoleManagementActionDispatchTypes } from "../constants/Roles";
import { RoleManagementDispatchTypes } from "../models/DispatchTypes/Roles";

export const initialState: DeleteResponse = {
  loading: false,
  response: false,
};

const DeleteReducer: Reducer<DeleteResponse, RoleManagementDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RoleManagementActionDispatchTypes.DELETE_ROLE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RoleManagementActionDispatchTypes.DELETE_ROLE_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case RoleManagementActionDispatchTypes.DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case RoleManagementActionDispatchTypes.DELETE_ROLE_RESET: {
      return {
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DeleteReducer;

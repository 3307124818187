import { connect } from "react-redux";
import UserList from "../components/UserList";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { User } from "../../../../../models/User";

const mapStateToProps = ({
  userListState,
}: ApplicationState) => ({
  userList: userListState.response as User[]
});

export default connect(mapStateToProps)(UserList);

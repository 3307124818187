import { Reducer } from "redux";

import { ServiceRequestNumberMasterResponse } from "../models/Api/Master";
import { ServiceRequestNumberMasterDispatchTypes } from "../models/DispatchTypes/Master";
import { ServiceRequestNumberMasterActionDispatchTypes } from "../constants/Master";

const initialState: ServiceRequestNumberMasterResponse = {
  loading: false,
  response: [],
};

const ServiceRequestNumberMasterReducer: Reducer<
  ServiceRequestNumberMasterResponse,
  ServiceRequestNumberMasterDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceRequestNumberMasterActionDispatchTypes.GET_SERVICE_REQUEST_NUMBER_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceRequestNumberMasterActionDispatchTypes.GET_SERVICE_REQUEST_NUMBER_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ServiceRequestNumberMasterReducer;

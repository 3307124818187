import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/bs";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { isNull } from "lodash";
import { Variant } from "@mui/material/styles/createTypography";

import {
  DateText,
  Description,
  Gridbody,
  IconBox,
  NavLine,
  StageCircle,
  StageInfoGrid,
  StageLabel,
  StageLine,
  TimelineGridContainer,
  VerticalDivider,
  IconStyle,
  labelColor,
  HeaderTableRow,
  TablecellStyle,
  TableBoxMargin,
  Header,
  ListHeader,
  Data,
  pendingCountStyle,
  TextStyle,
  UnderlineNtu,
  UnderlineCompleted,
} from "../styles/ReAuthTimeline";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import {
  DropdownContent,
  DropdownMenu,
  FaChevronDownIcon,
  FaRevertIcon,
  ModifyButton,
  ResumeIntakeButton,
  StatusDataButton,
  TableMain,
  TableMainHead,
} from "../styles/Disciplines";
import { getValue, isNil, length } from "../../../../../utils";
import { AuthTimelineStages } from "../../../../../models/Patient";
import {
  PutAuthorizationDetailTabResponse,
  ReAuthTimelineResponse,
} from "../../../../../models/Api/Authorization";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { italicFont } from "../../../../../styles/mui/styles/font";
import { StageData } from "../../../../../models/Authorization";
import { ServiceHeader } from "../../../../../constants/Discipline";
import { UpdateDisciplineResponse } from "../../../../../models/Api/Discipline";
import {
  ActiveAuthorizationIcon,
  TimelineStagesStatusList,
} from "../../../../../constants/Authorization";

export interface PropsFromDispatch {
  getReAuthTimelineData: (referralId: string) => void;
}
export interface PropsFromState {
  reAuthTimeline: ReAuthTimelineResponse;
  handleRef: React.MouseEventHandler;
  updatedDisciplineResp: UpdateDisciplineResponse;
  putAuthorizationDetailTabData: PutAuthorizationDetailTabResponse;
}
type AllProps = PropsFromState & PropsFromDispatch;

const ReAuthTimeline: React.FC<AllProps> = ({
  getReAuthTimelineData,
  reAuthTimeline,
  handleRef,
  updatedDisciplineResp,
  putAuthorizationDetailTabData,
}: AllProps) => {
  const [reAuthTimelineData, setReAuthTimelineData] =
    useState<AuthTimelineStages[]>();
  const { response: reAuthTimelineResponse } = reAuthTimeline;
  const { response: updatedDisciplineResponse } = updatedDisciplineResp;
  const { response: putAuthorizationDetailTabDataResponse } =
    putAuthorizationDetailTabData;
  const { authNo } = useParams();
  const navigate = useNavigate();
  const referralId = authNo;
  useEffect(() => {
    if (reAuthTimelineResponse) {
      setReAuthTimelineData(reAuthTimelineResponse);
    }
  }, [reAuthTimelineResponse]);

  useEffect(() => {
    if (
      updatedDisciplineResponse.serviceRequestDisciplineId !== -1 &&
      referralId
    ) {
      getReAuthTimelineData(referralId);
    }
    if (
      getValue(
        putAuthorizationDetailTabDataResponse,
        "authorizationGeneralInfoDto.staffingStatusId"
      ) !== -1 &&
      referralId
    ) {
      getReAuthTimelineData(referralId);
    }
  }, [updatedDisciplineResponse, putAuthorizationDetailTabDataResponse]);

  const getTimelineDataById = (id: number) => {
    const timelineData =
      reAuthTimelineData &&
      reAuthTimelineData.find(
        (reAuthTimelineData: AuthTimelineStages) =>
          reAuthTimelineData.workflowStageId === id
      );
    return timelineData;
  };

  function FilteredTimelineArray(TimelineResponse: AuthTimelineStages[]) {
    if (TimelineResponse.length >= 4) {
      const TimelineResponseSubset = [...TimelineResponse.slice(4)];
      const LastTimelineObj = LastTimelineStage(TimelineResponseSubset);
      const timelineStages = [
        ...TimelineResponse.slice(0, 4),
        ...LastTimelineObj,
      ];
      return timelineStages;
    } else {
      return TimelineResponse;
    }
  }
  const LastTimelineStage = (TimelineStagesArray: any) => {
    const lastStageObject = TimelineStagesArray.map(
      (item: AuthTimelineStages) => {
        if (item.stageStatus === TimelineStagesStatusList.COMPLETED) {
          return {
            referralId: item.referralId,
            patientId: item.patientId,
            timelineId: item.timelineId,
            workflowStageId: item.workflowStageId,
            workflowStageName: item.workflowStageName,
            stageDescription: item.stageDescription,
            additionalInformation: item.additionalInformation
              ? item.additionalInformation
              : null,
            stageStatus: item.stageStatus,
            completedOn: item.completedOn ? item.completedOn : null,
            stageData: item.stageData ? item.stageData : [],
          };
        } else {
          return null;
        }
      }
    );

    if (!isNull(lastStageObject[1])) {
      return [lastStageObject[1]];
    } else if (!isNull(lastStageObject[0])) {
      return [lastStageObject[0]];
    } else {
      return [TimelineStagesArray[0]];
    }
  };

  return (
    <>
      {reAuthTimelineData && length(reAuthTimelineData) && (
        <Grid container sx={Gridbody}>
          <Grid item xs={12} sx={displayFlex}>
            <Typography
              variant="h6"
              color={colors.fonts[19]}
              fontWeight={fontWeight.Weight[5]}
            >
              Timeline stages/ status of this authorization
            </Typography>
            <Divider orientation="vertical" flexItem sx={VerticalDivider} />
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[4]}
              color={colors.brand["secondary"]}
              sx={italicFont}
            >
              {(reAuthTimelineData &&
                reAuthTimelineData.length >= 4 &&
                reAuthTimelineData[4].stageStatus ===
                  TimelineStagesStatusList.COMPLETED) ||
              reAuthTimelineData[5].stageStatus ===
                TimelineStagesStatusList.COMPLETED
                ? TimelineStagesStatusList.COMPLETED
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {reAuthTimelineResponse &&
                length(reAuthTimelineResponse) &&
                FilteredTimelineArray(reAuthTimelineResponse).map(
                  (reAuthTimeline: AuthTimelineStages) => {
                    const timelineData = getTimelineDataById(
                      reAuthTimeline.workflowStageId
                    );
                    if (isNil(timelineData)) return null;
                    return (
                      <Grid item xs={2.4} key={reAuthTimeline.workflowStageId}>
                        <Grid container>
                          {
                            <Grid item xs={12}>
                              {TimelineStages(
                                reAuthTimeline.workflowStageId,
                                reAuthTimeline.workflowStageName,
                                reAuthTimeline.stageDescription,
                                reAuthTimeline.stageStatus,
                                reAuthTimeline.additionalInformation,
                                reAuthTimeline.referralId,
                                reAuthTimeline.stageData,
                                reAuthTimeline.patientId,
                                handleRef,
                                navigate
                              )}
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    );
                  }
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const TimelineStages = (
  id: number,
  label: string,
  description: string | undefined,
  stageStatus: string,
  additionalInformation: string | null,
  referralId: number,
  stageData: StageData[],
  patientId: number,
  handleRef: React.MouseEventHandler,
  navigate: any
) => {
  const handleClick = (e: any) => {
    handleRef(e);
  };

  const defaultStageStatus = (stageStatus: string) => {
    return (
      <>
        {stageStatus === TimelineStagesStatusList.COMPLETED ? (
          <>
            <Grid item xs={2}>
              <FaIcons.BsCheck style={IconStyle} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="subtitle2"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={labelColor}
              >
                COMPLETED
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
            >
              {stageStatus}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const timeLinelabel = (id: number, label: string) => {
    return (
      <Grid container>
        {id === 5 || id === 6 ? (
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={
                id === 5 && stageStatus === TimelineStagesStatusList.COMPLETED
                  ? "subtitle2"
                  : stageStatus === TimelineStagesStatusList.YET_TO_START
                  ? "subtitle2"
                  : "body2"
              }
              color={colors.fonts[18]}
              fontWeight={
                id === 5 && stageStatus === TimelineStagesStatusList.COMPLETED
                  ? fontWeight.Weight[6]
                  : stageStatus === TimelineStagesStatusList.YET_TO_START
                  ? fontWeight.Weight[6]
                  : fontWeight.Weight[1]
              }
              pl={"1.2rem"}
              sx={UnderlineNtu(id, stageStatus)}
            >
              NTU
            </Typography>
            <Typography
              variant="subtitle2"
              color={colors.fonts[18]}
              fontWeight={fontWeight.Weight[6]}
              pt={"0.6rem"}
            >
              /
            </Typography>
            <Typography
              variant={
                id === 6 && stageStatus === TimelineStagesStatusList.COMPLETED
                  ? "subtitle2"
                  : stageStatus === TimelineStagesStatusList.YET_TO_START
                  ? "subtitle2"
                  : "body2"
              }
              color={colors.fonts[18]}
              fontWeight={
                id === 6 && stageStatus === TimelineStagesStatusList.COMPLETED
                  ? fontWeight.Weight[6]
                  : stageStatus === TimelineStagesStatusList.YET_TO_START
                  ? fontWeight.Weight[6]
                  : fontWeight.Weight[1]
              }
              sx={UnderlineCompleted(id, stageStatus)}
            >
              Completed
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="subtitle2"
            color={colors.fonts[18]}
            fontWeight={fontWeight.Weight[6]}
            sx={StageLabel}
          >
            {label}
          </Typography>
        )}
      </Grid>
    );
  };
  const handleStartClick = () => {
    navigate(
      ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE_BY_REFERRAL, {
        referralId: referralId,
        patientId: patientId,
        additionalParams: 0,
      })
    );
  };
  const tableCell = (
    value: string | number,
    variant: Variant,
    color: string,
    fontWeight: string
  ) => {
    return (
      <TableCell>
        <Header>
          <Box sx={ListHeader}>
            <Typography
              variant={variant}
              color={color}
              fontWeight={fontWeight}
              m={"0 0.5rem"}
              sx={typeof value === "number" ? pendingCountStyle : Data}
            >
              {value}
            </Typography>
          </Box>
        </Header>
      </TableCell>
    );
  };

  const status = (
    statusId: number,
    status: string,
    stageData: StageData[],
    additionalInformation: string | null
  ) => {
    switch (status) {
      case TimelineStagesStatusList.START:
        if (statusId === 1) {
          return (
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={ResumeIntakeButton}
                onClick={handleStartClick}
              >
                RESUME INTAKE
              </Button>
            </Grid>
          );
        }
        if (statusId === 2 && length(stageData)) {
          return (
            <Grid container>
              {!isNil(additionalInformation) ? (
                <Grid item xs={12} pb={"0.8rem"}>
                  <Button
                    variant="contained"
                    sx={ModifyButton}
                    onClick={handleClick}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {additionalInformation}
                    </Typography>
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} pb={"0.8rem"}>
                  <Button
                    variant="contained"
                    sx={ModifyButton}
                    onClick={handleClick}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[5]}
                    >
                      START
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box sx={DropdownMenu}>
                  <Button variant="text" sx={StatusDataButton}>
                    <FaRevertIcon />
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {stageData.length.toString()} service
                    </Typography>
                    <FaChevronDownIcon />
                  </Button>
                  <Box sx={DropdownContent}>
                    <TableContainer>
                      <TableMain aria-label="simple table">
                        <TableMainHead>
                          <TableRow sx={HeaderTableRow}>
                            {ServiceHeader.map((tablecell, index) => (
                              <TableCell
                                key={index}
                                width={tablecell.width}
                                style={TablecellStyle}
                              >
                                <Typography
                                  variant="body1"
                                  color={colors.black[2]}
                                  fontWeight={fontWeight.Weight[3]}
                                  sx={TableBoxMargin}
                                >
                                  {tablecell.label}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableMainHead>
                        <TableBody>
                          {stageData &&
                            length(stageData) &&
                            stageData.map((status: StageData) => {
                              return (
                                <TableRow
                                  key={status.serviceNumber}
                                  onClick={handleClick}
                                >
                                  {tableCell(
                                    status.serviceNumber,
                                    "body2",
                                    colors.fonts[21],
                                    fontWeight.Weight[5]
                                  )}
                                  {tableCell(
                                    status.serviceStatus,
                                    "body2",
                                    colors.fonts[1],
                                    fontWeight.Weight[4]
                                  )}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </TableMain>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        }
        if (statusId === 2) {
          return (
            <Grid item xs={12} pb={"0.8rem"}>
              <Button
                variant="contained"
                sx={ModifyButton}
                onClick={handleClick}
              >
                <Typography variant="body1" fontWeight={fontWeight.Weight[5]}>
                  {additionalInformation}
                </Typography>
              </Button>
            </Grid>
          );
        }
        if (statusId === 3 && !isNil(additionalInformation)) {
          return (
            <Grid item xs={12} pb={"0.8rem"}>
              <Button
                variant="contained"
                sx={ModifyButton}
                onClick={handleClick}
              >
                <Typography variant="body1" fontWeight={fontWeight.Weight[5]}>
                  {additionalInformation}
                </Typography>
              </Button>
            </Grid>
          );
        }
        if (statusId === 4) {
          return (
            <Grid item xs={12} sx={TextStyle}>
              <Typography variant="subtitle2" fontWeight={fontWeight.Weight[5]}>
                {additionalInformation}
              </Typography>
            </Grid>
          );
        }
        return (
          <Grid item xs={12} pb={"0.8rem"}>
            {!isNil(additionalInformation) ? (
              <Button
                variant="contained"
                sx={ModifyButton}
                onClick={handleClick}
              >
                <Typography variant="body1" fontWeight={fontWeight.Weight[5]}>
                  {additionalInformation}
                </Typography>
              </Button>
            ) : (
              <Grid item xs={12} pb={"0.8rem"}>
                <Button
                  variant="contained"
                  sx={ModifyButton}
                  onClick={handleClick}
                >
                  <Typography variant="body1" fontWeight={fontWeight.Weight[5]}>
                    START
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        );
      case TimelineStagesStatusList.YET_TO_START:
        if (statusId === 4 && !isNull(additionalInformation)) {
          return (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={DateText}
              >
                {additionalInformation}
              </Typography>
            </Grid>
          );
        }
        return (
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
              sx={TextStyle}
            >
              {TimelineStagesStatusList.YET_TO_START}
            </Typography>
          </Grid>
        );
      case TimelineStagesStatusList.COMPLETED:
        if (
          statusId === 2 &&
          (!isNil(additionalInformation) ||
            (stageStatus === TimelineStagesStatusList.COMPLETED &&
              length(stageData)))
        ) {
          return (
            <>
              {!isNil(additionalInformation) ? (
                <Grid item xs={12} pb={"0.8rem"}>
                  <Button
                    variant="contained"
                    sx={ModifyButton}
                    onClick={handleClick}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {additionalInformation}
                    </Typography>
                  </Button>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={2}>
                    <FaIcons.BsCheck style={IconStyle} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="subtitle2"
                      color={colors.fonts[22]}
                      fontWeight={fontWeight.Weight[4]}
                      sx={labelColor}
                    >
                      COMPLETED
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {length(stageData) && (
                <Grid item xs={12}>
                  <Box sx={DropdownMenu}>
                    <Button variant="text" sx={StatusDataButton}>
                      <FaRevertIcon />
                      <Typography
                        variant="body1"
                        fontWeight={fontWeight.Weight[5]}
                      >
                        {stageData.length.toString()} service
                      </Typography>
                      <FaChevronDownIcon />
                    </Button>
                    <Box sx={DropdownContent}>
                      <TableContainer>
                        <TableMain aria-label="simple table">
                          <TableMainHead>
                            <TableRow sx={HeaderTableRow}>
                              {ServiceHeader.map((tablecell, index) => (
                                <TableCell
                                  key={index}
                                  width={tablecell.width}
                                  style={TablecellStyle}
                                >
                                  <Typography
                                    variant="body1"
                                    color={colors.black[2]}
                                    fontWeight={fontWeight.Weight[3]}
                                    sx={TableBoxMargin}
                                  >
                                    {tablecell.label}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableMainHead>
                          <TableBody>
                            {stageData &&
                              length(stageData) &&
                              stageData.map((status: StageData) => {
                                return (
                                  <TableRow
                                    key={status.serviceNumber}
                                    onClick={handleClick}
                                  >
                                    {tableCell(
                                      status.serviceNumber,
                                      "body2",
                                      colors.fonts[21],
                                      fontWeight.Weight[5]
                                    )}
                                    {tableCell(
                                      status.serviceStatus,
                                      "body2",
                                      colors.fonts[1],
                                      fontWeight.Weight[4]
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </TableMain>
                      </TableContainer>
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          );
        }

        if (statusId === 3 && length(stageData)) {
          return (
            <Grid container>
              <Grid item xs={2}>
                <FaIcons.BsCheck style={IconStyle} />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="subtitle2"
                  color={colors.black[2]}
                  fontWeight={fontWeight.Weight[4]}
                  sx={labelColor}
                >
                  COMPLETED
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid container>
            <Grid item xs={2}>
              <FaIcons.BsCheck style={IconStyle} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="subtitle2"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={labelColor}
              >
                COMPLETED
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        return <Grid container>{defaultStageStatus(stageStatus)}</Grid>;
    }
  };

  return (
    <Grid
      container
      sx={TimelineGridContainer(stageData && length(stageData) ? true : false)}
    >
      <Grid item xs={1.5}>
        <Box sx={StageCircle(stageStatus)}>{id === 6 ? 5 : id}</Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={StageInfoGrid}>
          <Box height={"30%"}>
            <Grid container>
              <Grid item xs={0.3}>
                <Box sx={IconBox}>{ActiveAuthorizationIcon(label)}</Box>
              </Grid>
              <Grid item xs={7.3}>
                {timeLinelabel(id, label)}
              </Grid>
              <Grid item xs={4.4}>
                <Box sx={StageLine}>
                  {id === 5 || id === 6 ? "" : <Box sx={NavLine}></Box>}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box height={"40%"}>
            <Typography
              variant="body2"
              color={colors.fonts[11]}
              fontWeight={fontWeight.Weight[3]}
              sx={Description}
            >
              {description}
            </Typography>
          </Box>
          <Box height={"30%"}>
            <Grid container>
              <Grid item xs={8}>
                <Grid container>
                  {stageStatus &&
                    status(id, stageStatus, stageData, additionalInformation)}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReAuthTimeline;

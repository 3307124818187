import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { isNull, isNil, has, isEmpty } from "lodash";

import { DateFieldType, DateFormats, FieldType } from "../constants/Date";

dayjs.extend(utc);

/**
 * @param {string | Date}  dateInput - A date string or date object
 * @param {string | null} [outputFormat = null] - An optional param for output format | DateFormats enum | Default is "MM/DD/YYYY h:mm a"
 * @param {string | null} [inputFormat = null] - An optional param for input format | DateFormats enum | Default is accepted formats by dayjs
 * @param {boolean | null} [isUTC = null] - An optional param if date to be formatted in UTC format only | Default is false
 *
 * @returns {string} This is the formatted date string or empty when invalid date object or string input is there
 */
export const formatDate = (
  dateInput: string | Date,
  outputFormat: string | null = null,
  inputFormat: string | null = null,
  isUTC: boolean | null = null
): string => {
  let formattedDateString = "";
  if (!isNil(dateInput)) {
    const d =
      dateInput instanceof Date || !isNull(inputFormat)
        ? dateInput
        : new Date(dateInput);
    let dateObj: dayjs.Dayjs;
    if (isNull(outputFormat)) {
      outputFormat = DateFormats.MM_DD_YYYY_h_mm_a;
    }
    if (inputFormat) {
      dateObj = dayjs(d, inputFormat);
      if (isUTC) {
        dateObj = dayjs.utc(d, inputFormat);
      }
    } else {
      dateObj = dayjs(d);
      if (isUTC) {
        dateObj = dayjs.utc(d);
      }
    }

    formattedDateString = dateObj.isValid() ? dateObj.format(outputFormat) : "";
  }
  return formattedDateString;
};

/**
 * @param {any}  restData - A restData any
 *
 * @returns {string} This is the utcdate
 */
export const utcDateToUser = (restData: any) => {
  if (Array.isArray(restData)) {
    for (let i = 0; i < restData.length; i++) {
      utcDateToUser(restData[i]);
    }
  } else if (typeof restData === "object") {
    for (const objectKey in restData) {
      if (has(FieldType, objectKey)) {
        if (restData[objectKey]) {
          if (
            !(
              restData[objectKey].includes("z") ||
              restData[objectKey].includes("Z")
            )
          ) {
            restData[objectKey] = dayjs(restData[objectKey] + "z").toJSON();
          }
        }
      }
      utcDateToUser(restData[objectKey]);
    }
  }
  return restData;
};

/**
 * @param {any}  restData -api request object
 * @param {string} DateFieldType - An param to change output format which don't have time on ui | DateFieldType enum"
 *
 * @returns {any} This is the final request object after formatted date
 */
export const userDate = (restData: any) => {
  if (Array.isArray(restData)) {
    for (let i = 0; i < restData.length; i++) {
      userDate(restData[i]);
    }
  } else if (typeof restData === "object") {
    for (const objectKey in restData) {
      if (has(DateFieldType, objectKey)) {
        if (restData[objectKey]) {
          restData[objectKey] = dayjs(restData[objectKey]).format("YYYY-MM-DD");
        }
      }
      userDate(restData[objectKey]);
    }
  }
  return restData;
};

/**following function used to return current date*/
export const currentDate = () => {
  return dayjs().format();
};
/**
 * @param {any}  date - a date string
 * @param {number} value - a value number
 * @param {any} type - type any
 *
 * @returns {any} following function used to add number of days/month in current provided date
 */
export const addDaysToDate = (date: string, value: number, type: any) => {
  return dayjs(date).add(value, type);
};

/**
 * @param {any}  date - a date string
 *
 * @returns {any} Function to check if the given UTC time is before or exactly at 11:59 PM
 */
export const isAfter11_59PM = (date: any) => {
  const dateTime = dayjs(date);
  const endOfDay = dateTime.endOf("day").subtract(1, "minute");
  const isEndOfDay = dayjs().isAfter(endOfDay, "minute");
  return isEndOfDay;
};
/**
 * @param {any}  date - a date string[] | null
 *
 * @returns {any} following function used to filter out latest date time from an array
 */
export const getLatestDate = (data: any) => {
  if (!isEmpty(data)) {
    const latestDateTime = data.reduce((latest: any, current: any) => {
      const latestDate = new Date(latest);
      const currentDate = new Date(current);
      return currentDate > latestDate ? current : latest;
    }, data[0]);
    return latestDateTime;
  }
  return "";
};

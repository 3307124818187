import { Reducer } from "redux";

import { DocumentReviewResponse } from "../models/Api/Document";
import { SaveDocumentDetailActionDispatchTypes } from "../models/DispatchTypes/Document";
import { SaveUpdateDocumentDetailActionDispatchTypes } from "../constants/Document";

const initialState: DocumentReviewResponse = {
  loading: false,
  response: {
    id: 0,
    containsSupportedDoc: null,
    documentationDate: "",
    documentId: [],
    userCreated: null,
    type: 0,
    addedDocuments: []
  },
  error: null,
};

const SaveOrUpdateReferralDocument: Reducer<
  DocumentReviewResponse,
  SaveDocumentDetailActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case SaveUpdateDocumentDetailActionDispatchTypes.DOCUMENT_DETAIL_SAVE_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default SaveOrUpdateReferralDocument;

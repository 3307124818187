import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights;

const loadInsights = (key: string) => {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: key,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
};

export { reactPlugin, appInsights, loadInsights };

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { isNil } from "lodash";

import {
  Header,
  ListHeader,
  IconFaPencilAlt,
  TableMain,
  TableMainHead,
  TablecellStyle,
  Data,
  HeaderTableRow,
  IconFaRegEye,
  IconFaTrashAlt,
  IconFaPowerOff,
  IconFaPowerOn,
  SortBox,
  IconContainer,
  IconName,
  ListActionHeader,
} from "../styles/NotesList";
import usePagination from "../../../../../utils/Pagination";
import {
  PER_PAGE,
  DEFAULT_PAGE,
  ASC,
  DESC,
} from "../../../../../constants/FunctionalUtils";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { Variant } from "@mui/material/styles/createTypography";
import {
  HeaderLabel,
  SaveAdminNotesActionDispatchTypes,
  UpdateAdminNotesActionDispatchTypes,
} from "../../../../../constants/Notes";
import { AdminNote, SaveNotes } from "../../../../../models/Notes";
import AlertDialog from "../../../../../routes/AllPatientRecords/routes/ReferralIntake/components/AlertDialog";
import { DownIcon } from "../../../../../components/formComponents/styles/style";
import NotesView from "./NotesView";
import { GetNotesResponse } from "../../../../../models/Api/Notes";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import Permission from "../../../../Permission";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import { IconDisabled } from "../../../../../styles/common/style";
export interface PropsFromState {
  list: AdminNote[] | null | undefined;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  handleSort: (property: any) => any;
  orderDirection: any;
  valueToOrderBy: any;
  NotesDataById: GetNotesResponse;
  deleteAdminNotesTemplate: (id: number) => void;
  deactivateNotesTemplate: (id: number, isActive: boolean) => void;
  totalRows: number;
  loading: boolean;
}

export interface PropsFromDispatch {
  GetNotesSectionByVersionId: (noteTemplateVersionId: number) => void;
  resetStates: (actionType: string[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const NoteTemplateList: React.FC<AllProps> = ({
  list,
  setOpenModal,
  openModal,
  handleSort,
  orderDirection,
  valueToOrderBy,
  deleteAdminNotesTemplate,
  deactivateNotesTemplate,
  GetNotesSectionByVersionId,
  NotesDataById,
  resetStates,
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [page, setPage] = useState(1);
  const [id, setId] = useState(-1);
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const adminNotesListData = usePagination(list == null ? [] : list, PER_PAGE);
  const navigate = useNavigate();
  const [notesData, setNotesData] = useState<SaveNotes>();
  const count = list == null ? 0 : Math.ceil(list.length / PER_PAGE);

  const validation = () => {
    return (
      <TableRow>
        <TableCell align="center" colSpan={9}>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[4]}
            color={colors.black[5]}
          >
            {NO_RECORD_FOUND}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const handlePageChange = (_e: any, page: number) => {
    setPage(page);
    adminNotesListData.jump(page);
  };

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    adminNotesListData.jump(DEFAULT_PAGE);
  }, [list]);

  const handleNavigate = (id: any) => {
    resetStates([
      SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET,
      UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET,
    ]);
    navigate(
      ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT_EDIT_TEMPLATE, {
        templateVersionId: Number(id),
      })
    );
  };

  const tableCell = (
    value: string | number,
    variant: Variant,
    color: string,
    fontWeight: string
  ) => {
    return (
      <TableCell>
        <Header>
          <Box sx={ListHeader}>
            <Typography
              variant={variant}
              color={color}
              fontWeight={fontWeight}
              sx={Data}
            >
              {value}
            </Typography>
          </Box>
        </Header>
      </TableCell>
    );
  };

  const handleListAction = (isActive: boolean | null) => {
    if (isNil(isActive)) {
      deleteAdminNotesTemplate(id);
    } else {
      deactivateNotesTemplate(id, isActive);
    }
    setOpenAlert(false);
    setIsActive(null);
  };

  const handleView = (noteTemplateVersionId: number) => {
    GetNotesSectionByVersionId(noteTemplateVersionId);
  };

  useEffect(() => {
    if (
      !isNil(NotesDataById.response) &&
      NotesDataById.response.templateVersionId !== -1
    ) {
      setNotesData(NotesDataById.response);
      setOpenModal(true);
    }
  }, [NotesDataById]);

  useEffect(() => {
    if (!openAlert) {
      setIsActive(null);
    }
  }, [openAlert]);

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <TableMain>
                <TableMainHead>
                  <TableRow sx={HeaderTableRow}>
                    {HeaderLabel.map((tablecell, index) => (
                      <TableCell
                        key={index}
                        width={tablecell.width}
                        style={TablecellStyle}
                      >
                        <Box sx={flexAlignCentre}>
                          <Typography
                            variant="body1"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.black[14]}
                          >
                            {tablecell.label}
                          </Typography>
                          {tablecell.label != "Action" && (
                            <Box
                              sx={SortBox}
                              onClick={() => handleSort(tablecell.key)}
                            >
                              <FaSortUp
                                color={
                                  orderDirection == ASC &&
                                    tablecell.key == valueToOrderBy
                                    ? colors.arrowIcon[5]
                                    : colors.arrowIcon[4]
                                }
                                size={"0.7rem"}
                              />
                              <FaSortDown
                                color={
                                  orderDirection == DESC &&
                                    tablecell.key == valueToOrderBy
                                    ? colors.arrowIcon[5]
                                    : colors.arrowIcon[4]
                                }
                                size={"0.7rem"}
                                style={DownIcon}
                              />
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableMainHead>
                {list && list.length > 0 && list[0].noteTemplateId != -1 ? (
                  <TableBody>
                    {adminNotesListData
                      .currentData()
                      .map((data: AdminNote, index: number) => {
                        return (
                          <TableRow key={index}>
                            {tableCell(
                              data.categoryName,
                              "subtitle2",
                              colors.fonts[1],
                              fontWeight.Weight[4]
                            )}
                            {tableCell(
                              data.noteTemplateId,
                              "subtitle1",
                              colors.fonts[18],
                              fontWeight.Weight[5]
                            )}
                            {tableCell(
                              data.templateName,
                              "subtitle1",
                              colors.fonts[18],
                              fontWeight.Weight[5]
                            )}
                            {tableCell(
                              data.isActive ? "Active" : "Inactive",
                              "subtitle2",
                              data.isActive == false
                                ? colors.red[100]
                                : colors.fonts[22],
                              fontWeight.Weight[4]
                            )}
                            <TableCell>
                              <Header>
                                <Box sx={ListActionHeader}>
                                  <Permission
                                    controlId={`|${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_READ}|${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_UPDATE}`}
                                    passThrough
                                  >
                                    {(isDisabled: boolean) => (
                                      <>
                                        <Box sx={IconContainer}>
                                          <IconFaRegEye
                                            onClick={() => {
                                              handleView(
                                                data.templateVersionId
                                              );
                                            }}
                                          />
                                          <Box
                                            sx={IconName}
                                            className="tooltip"
                                          >
                                            View
                                          </Box>
                                        </Box>
                                        {data.isActive == true && (
                                          <Box sx={IconContainer}>
                                            <IconFaPencilAlt
                                              onClick={() => {
                                                handleNavigate(
                                                  data.templateVersionId
                                                );
                                              }}
                                              style={
                                                isDisabled ? IconDisabled : {}
                                              }
                                            />
                                            <Box
                                              sx={IconName}
                                              className="tooltip"
                                            >
                                              Edit
                                            </Box>
                                          </Box>
                                        )}
                                        {data.isActive == true ? (
                                          <Box sx={IconContainer}>
                                            <IconFaPowerOff
                                              onClick={() => {
                                                setOpenAlert(true);
                                                setAlertMessage(
                                                  "Are you sure you want to Deactivate record?"
                                                );
                                                setId(data.noteTemplateId);
                                                setIsActive(false);
                                              }}
                                              style={
                                                isDisabled ? IconDisabled : {}
                                              }
                                            />
                                            <Box
                                              sx={IconName}
                                              className="tooltip"
                                            >
                                              Deactivate
                                            </Box>
                                          </Box>
                                        ) : (
                                          <Box sx={IconContainer}>
                                            <IconFaPowerOn
                                              onClick={() => {
                                                setOpenAlert(true);
                                                setAlertMessage(
                                                  "Are you sure you want to Activate record?"
                                                );
                                                setId(data.noteTemplateId);
                                                setIsActive(true);
                                              }}
                                              style={
                                                isDisabled ? IconDisabled : {}
                                              }
                                            />
                                            <Box
                                              sx={IconName}
                                              className="tooltip"
                                            >
                                              ACTIVATE
                                            </Box>
                                          </Box>
                                        )}
                                        <Box sx={IconContainer}>
                                          <IconFaTrashAlt
                                            onClick={() => {
                                              setOpenAlert(true);
                                              setAlertMessage(
                                                "Are you sure you want to delete record?"
                                              );
                                              setId(data.templateVersionId);
                                            }}
                                            style={
                                              isDisabled ? IconDisabled : {}
                                            }
                                          />

                                          <Box
                                            sx={IconName}
                                            className="tooltip"
                                          >
                                            Delete
                                          </Box>
                                        </Box>
                                      </>
                                    )}
                                  </Permission>
                                </Box>
                              </Header>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  validation()
                )}
              </TableMain>
            </TableContainer>
          </Grid>
          {list && list.length > 0 && list[0].noteTemplateId != -1 ? (
            <Grid item xs={12} mt={1}>
              <PaginationItem
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item>
            <AlertDialog
              alertMessage={alertMessage}
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              handleSubmit={() => handleListAction(isActive)}
            />
            <NotesView
              openModal={openModal}
              setOpenModal={setOpenModal}
              notesViewData={notesData}
              resetStates={resetStates}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NoteTemplateList;

import React, { ChangeEvent, useState, useEffect, SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Divider, TextField, alpha } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { isNil } from "lodash";
import { useNavigate } from "react-router-dom";

import { Switch } from "../../../../../components/custom/switch";
import {
  ButtonStyle,
  ExportIcon,
  AutoCompleteBootom,
  AutoComplete,
  IconFaSearch,
  Search,
  FilterIcon,
  IconFilter,
  IconFaUndo,
  GridList,
  PatientSearchLabel,
  FilterByStatus,
  DividerHeight,
} from "../styles/NotesList";
import NoteTemplateList from "../containers/NoteTemplateList";
import { Head, Main, Body } from "../../../../../components/custom/structure";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { length } from "../../../../../utils";
import {
  AdminNote,
  SaveNotes,
  TemplateSearch,
} from "../../../../../models/Notes";
import {
  ADMIN_LIST_PAGE_SIZE,
  ASC,
  DESC,
} from "../../../../../constants/FunctionalUtils";
import {
  ACTIVE,
  SaveAdminNotesActionDispatchTypes,
  UpdateAdminNotesActionDispatchTypes,
  INACTIVE,
  AdminNotesTemplateDeleteActionDispatchTypes,
  DeactivateNoteActionDispatchTypes,
} from "../../../../../constants/Notes";
import {
  AdminNotesListResponse,
  DeactivateNoteResponse,
  SaveNotesResponse,
} from "../../../../../models/Api/Notes";
import { DeleteResponse } from "../../../../../models/Api/Delete";
import useMenuItem from "../../../../../hooks/useMenuItem";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import Permission from "../../../../Permission";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface PropsFromDispatch {
  getAdminNotesList: (payload?: TemplateSearch) => void;
  deleteAdminNotesTemplate: (id: number) => void;
  deactivateNotesTemplate: (id: number, isActive: boolean) => void;
  resetStates: (actionType: string[]) => void;
}
export interface PropsFromState {
  adminNotesData: AdminNotesListResponse;
  deleteAdminNotesTemplateData: DeleteResponse;
  SaveNotesResp: SaveNotesResponse;
  UpdateNotesResponse: SaveNotes;
  deactivateNotesTemplateData: DeactivateNoteResponse;
}
type AllProps = PropsFromState & PropsFromDispatch;

const NoteManagement: React.FC<AllProps> = ({
  adminNotesData,
  getAdminNotesList,
  deleteAdminNotesTemplate,
  deactivateNotesTemplate,
  deleteAdminNotesTemplateData,
  resetStates,
  SaveNotesResp,
  UpdateNotesResponse,
  deactivateNotesTemplateData,
}) => {
  const [adminNotesList, setAdminNotesList] = useState<
    AdminNote[] | null | undefined
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const [orderDirection, setOrderDirection] = useState(ASC);
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const [templateName, setTemplateName] = useState<
    AdminNote | undefined | null
  >();
  const [isActive, setIsActive] = useState<boolean>(ACTIVE);
  const [toggleChecked, setToggleChecked] = useState(true);
  const [selectedOption, setSelectedOption] = useState(0);

  const [filteredTempalteList, setFilteredTemplateList] = useState<
    AdminNote[] | null | undefined
  >(null);
  const navigate = useNavigate();

  const { loading: loadingAdminNotesList, response: adminNotesListResponse } =
    adminNotesData;
  const { response: deleteAdminNotesListResponse } =
    deleteAdminNotesTemplateData;
  const { response: deactivateAdminNotesListResponse } =
    deactivateNotesTemplateData;
  const { response: SaveNotesRespData } = SaveNotesResp;

  const label = { inputProps: { "aria-label": "Switch" } };

  const defaultProps = (adminNotesList: AdminNote[] | null | undefined) => ({
    options: adminNotesList as AdminNote[],
    getOptionLabel: (option: AdminNote) => option.templateName,
  });

  const { open, onOpen, onClose } = useMenuItem();
  const onSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: AdminNote | null,
    reason: string
  ) => {
    setTemplateName(value);
    onClose();
    setAdminNotesList(
      value != null && !isNil(adminNotesData.response?.searchResponse)
        ? adminNotesData.response &&
            adminNotesData.response.searchResponse.filter(
              (v: AdminNote) =>
                v.templateName === value.templateName &&
                v.noteTemplateId === value.noteTemplateId
            )
        : adminNotesData.response && adminNotesData.response?.searchResponse
    );

    if (reason === "clear") {
      setAdminNotesList(getFilteredList(toggleChecked ? ACTIVE : INACTIVE));
    }
  };

  const toggleUserStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const userStatus = event.target.checked;
    setToggleChecked(userStatus);
    setFilterByStatus(userStatus);
    setTemplateName(undefined);
  };

  const setFilterByStatus = (value: boolean) => {
    if (value) {
      if (selectedOption != 0) {
        filtereByVersionStatus(selectedOption, value);
        setIsActive(ACTIVE);
      } else {
        setIsActive(ACTIVE);
        setAdminNotesList(getFilteredList(ACTIVE));
        setFilteredTemplateList(getFilteredList(ACTIVE));
      }
    } else {
      if (selectedOption != 0) {
        filtereByVersionStatus(selectedOption, value);
        setIsActive(INACTIVE);
      } else {
        setIsActive(INACTIVE);
        setAdminNotesList(getFilteredList(INACTIVE));
        setFilteredTemplateList(getFilteredList(INACTIVE));
      }
    }
  };

  const getFilteredList = (value: boolean) => {
    const templates =
      adminNotesData.response &&
      adminNotesData.response.searchResponse.filter(
        (v: AdminNote) => v.isActive === value
      );
    return templates;
  };

  const onClearFilter = () => {
    setTemplateName(undefined);
    setAdminNotesList(getFilteredList(ACTIVE));
    setIsActive(ACTIVE);
    setToggleChecked(true);
    setSelectedOption(0);
    setFilteredTemplateList(getFilteredList(ACTIVE));
  };
  const searchData = {
    templateName: "",
    categoryName: "",
    isActive: null,
    pageNo: 1,
    pageSize: ADMIN_LIST_PAGE_SIZE,
    sortColumn: "noteTemplateId",
    sortOrder: DESC,
  };

  useEffect(() => {
    setOpenModal(false);
    if (!loadingAdminNotesList) {
      getAdminNotesList(searchData);
    }
    resetStates([
      UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET,
      SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET,
    ]);
  }, []);
  useEffect(() => {
    if (UpdateNotesResponse && UpdateNotesResponse.templateCategoryId != -1) {
      getAdminNotesList(searchData);
    }
    if (SaveNotesRespData && SaveNotesRespData.templateCategoryId != -1) {
      getAdminNotesList(searchData);
    }
    if (deleteAdminNotesListResponse) {
      getAdminNotesList(searchData);
      resetStates([
        AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_RESET,
      ]);
    }
    if (deactivateAdminNotesListResponse) {
      getAdminNotesList(searchData);
      resetStates([DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_RESET]);
    }
  }, [
    deleteAdminNotesListResponse,
    deactivateAdminNotesListResponse,
    SaveNotesRespData,
    UpdateNotesResponse,
  ]);

  useEffect(() => {
    if (
      !loadingAdminNotesList &&
      Array.isArray(
        adminNotesListResponse && adminNotesListResponse.searchResponse
      )
    ) {
      if (
        length(
          adminNotesData.response && adminNotesData.response.searchResponse
        )
      ) {
        setAdminNotesList(getFilteredList(isActive));
      }
    }
  }, [loadingAdminNotesList]);

  useEffect(() => {
    setFilterByStatus(isActive);
  }, [adminNotesListResponse]);

  const filtereByVersionStatus = (
    approvalStatusId: number,
    status: boolean
  ) => {
    if (status) {
      const templates =
        adminNotesListResponse &&
        adminNotesListResponse.searchResponse.filter(
          (v: AdminNote) =>
            v.approvalStatusId === approvalStatusId && v.isActive === ACTIVE
        );
      setAdminNotesList(templates);
      setFilteredTemplateList(templates);
      setTemplateName(undefined);
    } else {
      const templates =
        adminNotesListResponse &&
        adminNotesListResponse.searchResponse.filter(
          (v: AdminNote) =>
            v.approvalStatusId === approvalStatusId && v.isActive === INACTIVE
        );
      setAdminNotesList(templates);
      setFilteredTemplateList(templates);
      setTemplateName(undefined);
    }
  };

  const handleNavigate = () => {
    resetStates([
      SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET,
      UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET,
    ]);
    navigate(ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT_CREATE_TEMPLATE));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === " " && !event.target.value.trim()) {
      event.preventDefault();
    }
  };

  const handleSort = (property: any) => {
    const isAsc = valueToOrderBy === property && orderDirection === ASC;
    setOrderDirection(isAsc ? DESC : ASC);
    setValueToOrderBy(property);

    const searchData = {
      templateName: "",
      categoryName: "",
      approvalStatusName: "",
      isActive: null,
      version: null,
      pageNo: 1,
      pageSize: ADMIN_LIST_PAGE_SIZE,
      sortColumn: property,
      sortOrder: isAsc ? DESC : ASC,
      userCreated: null,
    };
    getAdminNotesList(searchData);
  };
  return (
    <>
      <Main>
        <Head
          title={"NOTE MANAGEMENT"}
          description={"View and take actions on note based settings"}
        >
          <Box sx={ExportIcon}>
            <Permission
              controlId={`${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_CREATE}|${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_READ}|`}
              passThrough
            >
              {(isDisabled: boolean) => (
                <Button
                  variant="contained"
                  onClick={() => handleNavigate()}
                  disabled={isDisabled}
                >
                  Create New Template
                </Button>
              )}
            </Permission>
          </Box>
        </Head>
        <Body>
          <Grid item xs={12} sx={AutoCompleteBootom}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={2.9}>
                <Grid item xs={12} sx={{ paddingRight: "1.5rem" }}>
                  <Box sx={PatientSearchLabel}>
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[2]}
                      color={colors.fonts[2]}
                    >
                      Search Note Template:
                    </Typography>
                  </Box>
                  <Autocomplete
                    sx={AutoComplete}
                    {...defaultProps(filteredTempalteList)}
                    id="auto-complete"
                    autoComplete
                    onChange={(e, v, reason) => {
                      onSelection(e, v, reason);
                    }}
                    includeInputInList
                    value={templateName || null}
                    noOptionsText={NO_RECORD_FOUND}
                    PopperComponent={StyledPopperCheckBoxAutocomplete}
                    disabled={isNil(adminNotesList) ? true : false}
                    isOptionEqualToValue={(option, value) =>
                      option.noteTemplateId === value.noteTemplateId
                    }
                    onOpen={onOpen}
                    onClose={onClose}
                    renderInput={(params) => {
                      params.inputProps.maxLength = 50;
                      return (
                        <Box sx={Search}>
                          <TextField
                            {...params}
                            variant="standard"
                            onKeyDown={handleKeyDown}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    <IconFaSearch />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        </Box>
                      );
                    }}
                    onInputChange={(_event, value) => value !== "" && onOpen}
                    open={open}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.noteTemplateId}>
                          {option.templateName}
                        </li>
                      );
                    }}
                    data-testid="noteManagement-autocomplete"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6.5} sx={{ paddingLeft: "1rem" }}>
                <Grid item xs={12}>
                  <Box sx={FilterIcon}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      marginTop={1}
                    >
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        textAlign="center"
                        flexItem
                        sx={DividerHeight}
                      />
                      <IconFilter />
                      <Typography
                        variant="subtitle1"
                        color={colors.black[5]}
                        fontWeight={fontWeight.Weight[4]}
                      >
                        Filter by Status:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={alpha(colors.black[4], 0.8)}
                        fontWeight={fontWeight.Weight[4]}
                        sx={FilterByStatus}
                      >
                        {isActive ? "Active" : "Inactive"}
                      </Typography>
                      <Switch
                        {...label}
                        disabled={isNil(adminNotesList) ? true : false}
                        onChange={toggleUserStatus}
                        checked={toggleChecked}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                xs={2.4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  sx={ButtonStyle(false)}
                  onClick={onClearFilter}
                  disabled={isNil(adminNotesList) ? true : false}
                >
                  <IconFaUndo />
                  <Typography
                    variant="subtitle2"
                    color={colors.fonts[18]}
                    fontWeight={fontWeight.Weight[5]}
                  >
                    Reset Filter
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <GridList item xs={12}>
            <NoteTemplateList
              totalRows={
                adminNotesListResponse && adminNotesListResponse.totalRows
                  ? adminNotesListResponse?.totalRows
                  : 0
              }
              list={adminNotesList}
              loading={loadingAdminNotesList}
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleSort={handleSort}
              orderDirection={orderDirection}
              valueToOrderBy={valueToOrderBy}
              deleteAdminNotesTemplate={deleteAdminNotesTemplate}
              deactivateNotesTemplate={deactivateNotesTemplate}
            />
          </GridList>
        </Body>
      </Main>
    </>
  );
};

export default NoteManagement;

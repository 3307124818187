import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../store/ApplicationState";
import { getUserModule } from "../../actions/Modules";
import { ModulesDispatchTypes } from "../../models/DispatchTypes/Modules";
import { UserDetailsPayload } from "../../models/User";
import Menus from "../components/Menus";
import { getUserPermissionCall } from "../../actions/User";

const mapStateToProps = ({
  userModuleState,
  loggedInUserState,
}: ApplicationState) => ({
  item: userModuleState,
  user: loggedInUserState.response as UserDetailsPayload,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ModulesDispatchTypes>
  >
) => {
  return {
    getUserModules: async (userId: number) => {
      dispatch(await getUserModule(userId));
    },
    getUserPermission: (userId: number) => {
      dispatch(getUserPermissionCall(userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menus);

import { Reducer } from "redux";

import { PatientDetailResponse } from "../models/Api/AllPatientRecord";
import { PatientDetailActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { PatientDetailDispatchTypes } from "../constants/AllPatientRecord";

const initialState: PatientDetailResponse = {
  loading: false,
  response: {
    referralId: 0,
    patientId: 0,
    blobId: 0,
    memberName: "",
    gender: "",
    dateOfBirth: "",
    subscriberId: 0,
    authId: 0,
    medicareBeneficiaryIdentifier: "",
    languagePreferenceValue: "",
    healthPlan: "",
    benifitPlan: "",
    stateOfInsurance: "",
    product: "",
    datesApplicable: "",
    referralStageId: 0,
    referralStageName: "",
    blobFileName: "",
    patientEligibilityId: 0,
  },
};

const PatientRecordReducer: Reducer<
  PatientDetailResponse,
  PatientDetailActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientDetailDispatchTypes.PATIENT_DETAIL_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientDetailDispatchTypes.PATIENT_DETAIL_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientRecordReducer;

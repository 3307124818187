import {
  Headcell130,
  Headcell150,
  Headcell160,
  Headcell170,
  Headcell250,
  Headcell60,
  Headcell80,
} from "../routes/MyPatients/styles/style";
import fontWeight from "../styles/mui/fontWeight";
import { AssignStaffHeaderLabelType } from "./AllPatientRecord";

export enum StaffingListActionDispatchTypes {
  STAFFING_LIST_ERROR = "STAFFING_LIST_ERROR",
  STAFFING_LIST_INVOKE = "STAFFING_LIST_INVOKE",
  STAFFING_LIST_SUCCESS = "STAFFING_LIST_SUCCESS",
  STAFFING_LIST_RESET = "STAFFING_LIST_RESET",
}
export enum StaffingListTitle {
  ACTION = "",
  ASSIGNED_TO = "ASSIGNED TO",
  DATE_TIME_REQUESTED = "DATE/TIME REQ. RECEIVED",
  HEALTH_PLAN = "HEALTH PLAN",
  NAME = "NAME",
  SERVICE_PROVIDER = "SERVICE PROVIDER",
  STATE = "STATE",
  REFERRAL_SOURCE = "REFERRAL SOURCE",
  URGENCY = "URGENCY",
  STAFFING_STATUS = "STAFFING STATUS",
  AUTH_ID = "AUTH ID",
  COUNTY = "COUNTY",
  STAGE = "STAGE"
}

export const StaffingListHeaderLabel: AssignStaffHeaderLabelType[] = [
  {
    label: StaffingListTitle.NAME,
    style: { cell: Headcell160 },
    key: 1,
    name: "fullName",
    width: "14%",
  },
  {
    label: StaffingListTitle.URGENCY,
    style: { cell: Headcell80 },
    key: 2,
    name: "urgencyStatus",
    width: "5%",
  },
  {
    label: StaffingListTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
    key: 3,
    name: "requestDateTime",
    width: "14%",
  },
  {
    label: StaffingListTitle.STAGE,
    style: { cell: Headcell80 },
    key: 4,
    name: "stage",
    width: "15%",
  },
  {
    label: StaffingListTitle.STAFFING_STATUS,
    style: { cell: Headcell170 },
    key: 5,
    name: "staffingStatus",
    width: "9%",
  },
  {
    label: StaffingListTitle.ASSIGNED_TO,
    style: { cell: Headcell250 },
    key: 6,
    name: "assignedUserFullName",
    width: "14%",
  },
  {
    label: StaffingListTitle.HEALTH_PLAN,
    style: { cell: Headcell160 },
    key: 7,
    name: "healthPlanName",
    width: "9%",
  },
  {
    label: StaffingListTitle.AUTH_ID,
    style: { cell: Headcell130 },
    key: 8,
    name: "authorizationNumber",
    width: "5%",
  },
  {
    label: StaffingListTitle.COUNTY,
    style: { cell: Headcell130 },
    key: 9,
    name: "county",
    width: "7%",
  },

  {
    label: StaffingListTitle.STATE,
    style: { cell: Headcell60 },
    key: 10,
    name: "patientState",
    width: "4%",
  },
  {
    label: StaffingListTitle.REFERRAL_SOURCE,
    style: { cell: Headcell170 },
    key: 11,
    name: "facilityTypeName",
    width: "10%",
  },
  {
    label: StaffingListTitle.SERVICE_PROVIDER,
    style: { cell: Headcell170 },
    key: 12,
    name: "providerNpi",
    width: "10%",
  },
];

export const StaffingListData = [
  {
    value: "fullName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "urgencyStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "requestDateTime",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "stage",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "staffingStatus",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "assignedUserFullName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "healthPlanName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "authorizationNumber",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "county",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "patientState",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "facilityTypeName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "providerNpi",
    fieldValue: "providerName",
    fontWeight: fontWeight.Weight[5],
  },
];

export const staffingListFilterBy = [
  {
    value: "StaffingStatus",
    label: "Staffing Status",
    paramLabel: "staffingStatus",
  },
  {
    value: "HEALTHPLAN",
    label: "Health Plan",
    paramLabel: "healthPlanName",
  },
  {
    value: "ASSIGNEDTO",
    label: "Assigned To",
    paramLabel: "assignUser",
  },
  {
    value: "GetPatientStates",
    label: "State",
    paramLabel: "state",
  },
  {
    value: "ServiceProvider",
    label: "Service Provider",
    paramLabel: "providerNpi",
  },
  {
    value: "GetPatientCounty",
    label: "County",
    paramLabel: "county",
  },
  {
    value: "Stage",
    label: "Stage",
    paramLabel: "stage",
  },
  {
    value: "ReferralSource",
    label: "Referral Source",
    paramLabel: "facilityTypeName",
  },
];

export enum StaffingAssignStaffActionDispatchTypes {
  STAFFING_ASSIGN_STAFF_ERROR = "STAFFING_ASSIGN_STAFF_ERROR",
  STAFFING_ASSIGN_STAFF_INVOKE = "STAFFING_ASSIGN_STAFF_INVOKE",
  STAFFING_ASSIGN_STAFF_SUCCESS = "STAFFING_ASSIGN_STAFF_SUCCESS",
  STAFFING_ASSIGN_STAFF_RESET = "STAFFING_ASSIGN_STAFF_RESET",
}

export enum StaffingUnassignStaffActionDispatchTypes {
  STAFFING_UNASSIGN_STAFF_ERROR = "STAFFING_UNASSIGN_STAFF_ERROR",
  STAFFING_UNASSIGN_STAFF_INVOKE = "STAFFING_UNASSIGN_STAFF_INVOKE",
  STAFFING_UNASSIGN_STAFF_SUCCESS = "STAFFING_UNASSIGN_STAFF_SUCCESS",
  STAFFING_UNASSIGN_STAFF_RESET = "STAFFING_UNASSIGN_STAFF_RESET",
}

export const RemoveStaffingHeaderLabel = [
  { label: "" },
  { label: StaffingListTitle.NAME, style: { cell: Headcell160 } },
  { label: StaffingListTitle.URGENCY, style: { cell: Headcell80 } },
  {
    label: StaffingListTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
  },
  {
    label: StaffingListTitle.STAGE, style: { cell: Headcell150 }
  },
  { label: StaffingListTitle.STAFFING_STATUS, style: { cell: Headcell150 } },
  {
    label: StaffingListTitle.ASSIGNED_TO,
    style: { cell: Headcell250 },
  },
  { label: StaffingListTitle.HEALTH_PLAN, style: { cell: Headcell160 } },
  { label: StaffingListTitle.AUTH_ID, style: { cell: Headcell130 } },
  {
    label: StaffingListTitle.COUNTY,
    style: { cell: Headcell170 },
  },
  {
    label: StaffingListTitle.STATE,
    style: { cell: Headcell60 },
  },
  { label: StaffingListTitle.REFERRAL_SOURCE, style: { cell: Headcell170 } },
  { label: StaffingListTitle.SERVICE_PROVIDER, style: { cell: Headcell170 } },
];

export enum RemoveStaffingPatientActionDispatchTypes {
  REMOVE_STAFFING_PATIENT_ERROR = "REMOVE_STAFFING_PATIENT_ERROR",
  REMOVE_STAFFING_PATIENT_INVOKE = "REMOVE_STAFFING_PATIENT_INVOKE",
  REMOVE_STAFFING_PATIENT_SUCCESS = "REMOVE_STAFFING_PATIENT_SUCCESS",
  REMOVE_STAFFING_PATIENT_RESET = "REMOVE_STAFFING_PATIENT_RESET",
}

export enum ActionType {
  ASSIGN_STAFF = "ASSIGN STAFF",
  UNASSIGN_STAFF = "UNASSIGN STAFF",
  REMOVE_FROM_LIST = "REMOVE FROM LIST",
  ASSIGN = "ASSIGN",
  UNASSIGN = "UNASSIGN",
}

export enum StaffingStatus {
  STAFFED = "Staffed",
}

export const StageStatus = [
  {
    stageId: 1,
    stageStatus: "Approved"
  },
  {
    stageId: 2,
    stageStatus: "Not Approved"
  }
]

import React from "react";
import { Grid } from "@mui/material";
import { styles } from "../styles/styles";

type MainProps = {
  children: React.ReactNode;
};

const Main = ({ children }: MainProps) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={styles.mainBodyGridMargin}>
          <Grid container>{children}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Main;

import Divider from "@mui/material/Divider";

import { IconNames } from "../../constants/FormInputIconName";
import {
  FaFaxIcon,
  FaPhoneInputDevider,
  FaPhoneInputIcon,
  FaRegEnvelopeIcon,
  FaSearchIcon,
  FaUserMdIcon,
} from "./styles/style";

const FormInputIcons = (iconName: string, isOnlyIcon?: boolean) => {
  switch (iconName) {
    case IconNames.FA_PHONE:
      return (
        <>
          <FaPhoneInputIcon />
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            sx={FaPhoneInputDevider}
          />
        </>
      );

    case IconNames.HI_OUTLINE_MAIL:
      return (
        <>
          <FaRegEnvelopeIcon />
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            sx={FaPhoneInputDevider}
          />
        </>
      );
    case IconNames.FA_USER_MD:
      return (
          <FaUserMdIcon />
      );
    case IconNames.FA_FAX:
      return (
        <>
          <FaFaxIcon />
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            sx={FaPhoneInputDevider}
          />
        </>
      );
    case IconNames.FA_SEARCH:
      return (
        <>
          {!isOnlyIcon ? (
            <>
              <FaSearchIcon />
              <Divider
                orientation="vertical"
                variant="fullWidth"
                flexItem
                sx={FaPhoneInputDevider}
              />
            </>
          ) : (
            <>
              <FaSearchIcon />
            </>
          )}
        </>
      );

    default:
      return <></>;
  }
};

export default FormInputIcons;

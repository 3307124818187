import { Reducer } from "redux";

import { AppConfigsActionDispatchTypes } from "../constants/AppConfigs";
import { AppConfigsDispatchTypes } from "../models/DispatchTypes/AppConfigs";
import { AppConfigsResponse } from "../models/Api/AppConfigs";

const initialState: AppConfigsResponse = {
  loading: false,
  response: {
    REACT_APP_HOST_CONFIG_URL: "",
    REACT_APP_HOST_CONFIG_URL_USER: "",
    REACT_APP_HOST_CONFIG_URL_REFERRAL: "",
    REACT_APP_HOST_CONFIG_URL_OPERATOR: "",
    REACT_APP_APPINSIGHTS_CONNECTION_STRING: "",
    REACT_APP_CLIENT_ID: "",
    REACT_APP_HOST_CONFIG_URL_SCHEDULER: "",
    REACT_APP_HOST_CONFIG_URL_BLOB: "",
    REACT_APP_HOST_CONFIG_URL_CHECK_ELIGIBILITY: "",
    REACT_APP_HOST_CONFIG_URL_NOTE: "",
    REACT_APP_HOST_CONFIG_URL_PAYER_ELIGIBILITY: "",
    REACT_APP_LOGIN_URL: "",
    REACT_APP_DEBUG: false,
    REACT_APP_GOOGLE_MAP_KEY: "",
    REACT_APP_SURVEY_JS_KEY: "",
    REACT_APP_GOOGLE_MAP_API_URL: "",
    REACT_APP_DOCUMENT_MAX_SIZE: "",
    REACT_APP_DOCUMENT_MIN_SIZE: "",
    REACT_APP_FAX_MAX_SIZE: "",
    REACT_APP_IS_PRODUCTION_ENV: 1,
    REACT_APP_DINA_DEFAULT_URL: ""
  },
};

const AppConfigsReducer: Reducer<
  AppConfigsResponse,
  AppConfigsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AppConfigsActionDispatchTypes.APP_CONFIG_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AppConfigsActionDispatchTypes.APP_CONFIG_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AppConfigsReducer;

import { Reducer } from "redux";

import { DeleteAdminNotesTemplateDispatchTypes } from "../models/DispatchTypes/Notes";
import { AdminNotesTemplateDeleteActionDispatchTypes } from "../constants/Notes";
import { DeleteResponse } from "../models/Api/Delete";

export const initialState: DeleteResponse = {
  loading: false,
  response: false,
};

const DeleteAdminNotesTemplateReducer: Reducer<
  DeleteResponse,
  DeleteAdminNotesTemplateDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_INVOKE: {
      return {
        ...state,
        loading: true,
        response: false,
      };
    }
    case AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case AdminNotesTemplateDeleteActionDispatchTypes.ADMIN_NOTES_TEMPLATE_DELETE_RESET: {
      return {
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DeleteAdminNotesTemplateReducer;

import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { isArray } from "lodash";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  GridSize,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { colors } from "../../styles/colors";
import fontWeight from "../../styles/mui/fontWeight";
import { length } from "../../utils";

type FormInputMultiCheckboxProps = {
  control: Control<any> | undefined;
  name: string;
  options: CheckboxListType[];
  setState: (list: CheckboxListType[]) => void;
  setValue: UseFormSetValue<any>;
  customListFormat?: (list: CheckboxListType[]) => any[] | string;
  rules?: object;
  style?: SxProps<Theme> | undefined;
  gridSize?: boolean | GridSize | undefined;
};

const FormInputMultiCheckbox = ({
  control,
  name,
  rules,
  options,
  setState,
  setValue,
  customListFormat,
  style,
  gridSize,
}: FormInputMultiCheckboxProps) => {
  const handleChange = (event: any) => {
    const value = event.target.value;
    const checked = event.target.checked;
    const list = [...options];
    if (value) {
      list.forEach((option) => {
        // not used strict check for string and number compatibility ( no need to check and convert manually)
        if (option.value == value) {
          option.checked = checked;
        }
      });
    }
    // transform list using callback and set useForm value
    setValue(
      name,
      customListFormat ? customListFormat(list) : defaultListFormat(list)
    );
    // set state to display checkbox changes
    setState(list);
  };

  return (
    <Controller
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <>
          <Grid container {...field} onChange={handleChange}>
            {options.map((item) => (
              <Grid item xs={gridSize || 4} key={item.value}>
                <Box>{checkBox(item, style)}</Box>
              </Grid>
            ))}
          </Grid>
          <FormHelperText>{error ? error.message : null}</FormHelperText>
        </>
      )}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default FormInputMultiCheckbox;

type MasterListType = {
  label: string;
  value: string | number;
};

export type CheckboxListType = {
  checked: boolean;
  disabled?: boolean;
} & MasterListType;

export const generateCheckboxData = (
  masterList: MasterListType[],
  selectedItemList?: number[] | string[],
  isCheckboxDisabled?: (value: string) => boolean
): CheckboxListType[] => {
  let selectedItemListToString: string[] = [];

  // if selected checkbox is not needed skip it.
  if (isArray(selectedItemList)) {
    selectedItemListToString = selectedItemList.map((item) => item.toString());
  }

  return masterList.map((item) => ({
    label: item.label,
    value: item.value,
    checked: length(selectedItemListToString)
      ? selectedItemListToString.includes(item.value.toString())
      : false,
    disabled:
      typeof isCheckboxDisabled === "function"
        ? isCheckboxDisabled(item.value.toString())
        : false,
  }));
};

const checkBox = (item: CheckboxListType, sx?: SxProps<Theme> | undefined) => (
  <FormControlLabel
    key={item.value}
    control={
      <Checkbox
        value={item.value}
        size="small"
        disabled={item.disabled}
        checked={item.checked}
        sx={sx}
      />
    }
    label={
      <Typography
        variant="subtitle2"
        color={colors.black}
        fontWeight={fontWeight.Weight[5]}
        mr={"3rem"}
      >
        {item.label}
      </Typography>
    }
  />
);

const defaultListFormat = (list: CheckboxListType[]) =>
  list
    .filter((item) => item.checked)
    .map((item) => item.value)
    .join(", ");

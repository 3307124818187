import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

export const HeaderBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
}));

export const rowOne = {
  boxSizing: "border-box",
  margin: "2px 4px",
  padding: "1px",
};
export const title = {
  textAlign: "left",
};

export const back = {
  display: "flex",
  margin: "1px 3px",
  textAlign: "left",
  textDecoration: "underline",
  font: "normal normal bold 12px/16px Roboto",
  letterSpacing: "0px",
  color: colors.fonts[18],
  fontWeight: fontWeight.Weight[5],
  textTransform: "uppercase",
  opacity: "1",
};

export const description = {
  textAlign: "left",
};

// User Profile HEAD properties start
export const head = {
  padding: "1rem 0 1.25rem 1.2rem",
};

export const h_backbutton = [
  rowOne,
  back,
  {
    background: "inherit",
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
    padding: 0,
    paddingLeft: "1.1rem",
    margin: 0,
  },
];

export const h_main = {
  textAlign: "left",
  boxSizing: "border-box",
};

export const h_title = [rowOne, title];
export const h_desp = [rowOne, description];

export const h_buttons = {
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
};

export const h_btn = {
  border: "1px solid",
  borderRadius: "20px",
  font: "normal normal bold 10px/16px Roboto",
  letterSpacing: "0px",
  color: "#1D4289",
  textTransform: "uppercase",
  boxSizing: "border-box",
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  height: "25px",
  whiteSpace: "nowrap",
};
export const h_btn_cancel = [
  h_btn,
  {
    "&:hover": {
      border: `1px solid ${colors.hover.main}`,
      color: colors.hover.main,
    },
  },
];

export const h_btn_savenexit = [
  h_btn,
  {
    background: `${colors.primary.main} 0% 0% no-repeat padding-box`,
    color: colors.white[1],
    "&:hover": {
      backgroundColor: colors.hover.main,
      color: colors.white[1],
    },
  },
];

export const h_btn_save = h_btn_savenexit;
// User Profile HEAD properties end

// User Profile BODY properties start
export const body = {
  backgroundColor: colors.backgroundColor[2],
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "5px",
  marginRight: 2,
  borderRadius: "10px",
};

export const body_row1col1 = {
  main: { textAlign: "left", padding: "30px 10px" },
  title: [rowOne, title, { color: "#303030" }],
  desp: [rowOne, description],
};

export const body_row1col2 = {
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 10px",
  },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [rowOne, title, { color: "#303030" }],
};

export const body_row1col3 = {
  main: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    padding: "40px 2px",
  },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [rowOne, title, { color: "#303030" }],
};

export const body_row2col1 = {
  c1: [title, { color: "#3E3E3E", textAlign: "center", opacity: 0.88 }],
  c2: {
    height: "80px",
    width: "80px",
    borderRadius: "50%",
    margin: "5px auto",
    padding: "20px 10px",
  },
};

export const body_row2col2 = {
  main: {
    padding: "50px 10px",
  },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [rowOne, title, { color: "#4E5264", textTransform: "none" }],
  c3: [
    rowOne,
    title,
    {
      color: "#4E5264",
      font: "normal normal bold 12px/16px Roboto",
      textTransform: "none",
    },
  ],
};

export const body_row2col3 = {
  main: { padding: "50px 10px" },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [
    rowOne,
    title,
    {
      color: "#4E5264",
      font: "normal normal bold 12px/16px Roboto",
      textTransform: "none",
    },
  ],
};
export const body_row2col4 = {
  main: { padding: "50px 10px" },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [
    rowOne,
    title,
    {
      color: "#4E5264",
      font: "normal normal bold 12px/16px Roboto",
      textTransform: "none",
    },
  ],
};
export const body_row2col5 = {
  main: { padding: "50px 5px" },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [
    rowOne,
    title,
    {
      color: "#4E5264",
      font: "normal normal bold 12px/16px Roboto",
      textTransform: "none",
      display: "flex",
      alignItems: "center",
    },
  ],
  c3: { paddingLeft: "5px" },
};
export const body_row2col6 = {
  main: { padding: "50px 5px" },
  c1: [rowOne, description, { color: "#636A91" }],
  c2: [
    rowOne,
    title,
    {
      color: "#4E5264",
      font: "normal normal bold 12px/16px Roboto",
      textTransform: "none",
      display: "flex",
      alignItems: "center",
    },
  ],
  c3: { paddingLeft: "5px" },
};

export const body_row3col1 = {
  main: [
    rowOne,
    title,
    {
      color: "#3E3E3E",
      textTransform: "none",
      padding: "20px 10px",
      font: "normal normal medium 15px/20px Roboto",
      opacity: 0.88,
    },
  ],
};

export const body_row4 = {
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 5px",
  },
  c1: [rowOne, title, { color: "#4E5264", textTransform: "none" }],
};

export const body_row4col1 = {
  main: [
    rowOne,
    description,
    {
      color: "#3E3E3E",
      textTransform: "none",
      display: "flex",
      justifyContent: "center",
      padding: "20px 5px",
      whiteSpace: "nowrap",
    },
  ],
  c1: [
    rowOne,
    description,
    {
      color: "#3E3E3E",
      textTransform: "none",
      display: "flex",
      justifyContent: "left",
      padding: "8px 5px",
    },
  ],
};
// User Profile BODY properties end

// User Profile Footer properties start

export const footer = {
  background: "#E5F5FF",
  opacity: 1,
  borderRadius: "10px",
};

export const footer_row = {
  display: "flex",
  alignItems: "center",
  float: "right",
  padding: "10px 5px",
};

export const flex = {
  display: "flex",
};

export const backButtonStyle = {
  boxSizing: "border-box",
  marginRight: "5px",
};

export const keyboardBackSpaceIcon = {
  boxSizing: "border-box",
  marginRight: "5px",
};

export const dividerMargin = {
  margin: "2px 10px",
};

export const textAlignLeft = {
  textAlign: "left",
};

export const bodyStyle = {
  backgroundColor: colors.backgroundColor[7],
  padding: "15px",
  borderRadius: "12px",
};

export const mainBodyGridMargin = {
  padding: "6px 20px",
};
// User Profile Footer properties ends

// global export of all styles
export const styles = {
  h_backbutton,
  rowOne,
  head,
  h_main,
  h_title,
  h_desp,
  h_buttons,
  h_btn,
  h_btn_cancel,
  h_btn_save,
  h_btn_savenexit,
  body,
  body_row1col1,
  body_row1col2,
  body_row1col3,
  body_row2col1,
  body_row2col2,
  body_row2col3,
  body_row2col4,
  body_row2col5,
  body_row2col6,
  body_row3col1,
  body_row4col1,
  body_row4,
  footer,
  footer_row,
  flex,
  backButtonStyle,
  dividerMargin,
  textAlignLeft,
  keyboardBackSpaceIcon,
  mainBodyGridMargin,
  bodyStyle,
};

import { Reducer } from "redux";

import { NotesTemplateJsonResp } from "../models/Api/Notes";
import { NotesTemplateDispatchTypes } from "../models/DispatchTypes/Notes";
import { GetNotesTemplateActionDispatchTypes } from "../constants/Notes";

export const initialState: NotesTemplateJsonResp = {
  loading: false,
  response: {
    noteTemplateId: -1,
    templateName: "",
    templateJson: "",
    templateCategoryId: "",
    categoryName: "",
  },
};

const NotesTemplateJsonReducer: Reducer<
  NotesTemplateJsonResp,
  NotesTemplateDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetNotesTemplateActionDispatchTypes.NOTES_TEMPLATE_JSON_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GetNotesTemplateActionDispatchTypes.NOTES_TEMPLATE_JSON_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GetNotesTemplateActionDispatchTypes.NOTES_TEMPLATE_JSON_RESET: {
      return initialState;
    }
  }
  return state;
};

export default NotesTemplateJsonReducer;

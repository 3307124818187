import styled from "styled-components";
import { Box } from "@mui/material";

import { colors, others } from "../../../../../styles/colors";
import { TimelineStagesStatusList } from "../../../../../constants/Authorization";

export const Gridbody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.2rem 1.6rem 1.5rem 1.5rem",
  marginBottom: "0.8rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const StageCircle = (status?: string) => ({
  border: `2px solid ${colors.circleBorder.border}`,
  borderRadius: "100%",
  width: "2.188rem",
  height: "2.188rem",
  lineHeight: "2.188rem",
  textAlign: "center",
  backgroundColor: status === "COMPLETED" ? colors.brand["secondary"] : "none",
  zIndex: 2,
  opacity: 0.8,
  borderColor: colors.brand["secondary"],
});

export const VerticalDivider = () => ({
  margin: "0 1.2rem",
});

export const NavLine = () => ({
  border: `1px dotted ${colors.circleBorder.oliveDrab}`,
  width: "4rem",
  height: 0,
  content: '""',
  zIndex: 1,
});

export const TimelineGridContainer = (value: boolean) => ({
  padding: value ? "1.5rem 0.8rem 1.5rem 0rem" : "1.5rem 0.8rem 0rem 0rem",
});

export const StageInfoGrid = () => ({
  paddingLeft: "1.4rem",
  height: "9.3rem",
  width: "100%",
});

export const IconBox = () => ({
  paddingTop: "0.4rem",
});

export const DateText = () => ({
  paddingTop: "0.5rem",
});

export const TextStyle = () => ({
  paddingTop: "0.5rem",
  fontStyle: "italic",
});

export const StageLabel = () => ({
  paddingTop: "0.6rem",
  paddingLeft: "1.4rem",
  textDecoration: "underline",
});

export const StageLine = () => ({
  paddingTop: "0.9rem",
  paddingLeft: "0.8rem",
});

export const UnderlineNtu = (id: number, status: string) => ({
  paddingTop: "0.6rem",
  textDecoration:
    id === 5 &&
    (status === TimelineStagesStatusList.COMPLETED ||
      TimelineStagesStatusList.YET_TO_START)
      ? "underline"
      : "none",
});
export const UnderlineCompleted = (id: number, status: string) => ({
  paddingTop: "0.6rem",
  textDecoration:
    (id === 6 && status === TimelineStagesStatusList.COMPLETED) ||
    status === TimelineStagesStatusList.YET_TO_START
      ? "underline"
      : "none",
});
export const Description = () => ({
  lineHeight: "0.7rem",
});

export const IconStyle = {
  width: "100%",
  height: "100%",
  color: colors.fonts[22],
};
export const labelColor = {
  paddingTop: "0.5rem",
  color: colors.fonts[22],
};

export const AiIconStyle = {
  width: "100%",
  height: "100%",
};

export const HeaderTableRow = () => ({
  height: "1.2rem",
});

export const TablecellStyle = {
  borderBottom: "none",
};

export const TableBoxMargin = () => ({
  margin: "0 0.2rem",
});

export const Header: any = styled(Box)(() => ({
  color: colors.black[2],
}));

export const ListHeader = {
  height: "auto",
};

export const Data = {
  lineHeight: "0.5rem",
};

export const pendingCountStyle = {
  paddingLeft: "1rem",
  textDecoration: "underline",
  cursor: "pointer",
};

export enum MyPatientAccess {
  MY_PATIENT_SEARCH_READ = "myPatientSearchRead",
  MY_PATIENT_REMOVE_FROM_LIST_UPDATE = "myPatientRemoveFromListUpdate",
  ALL_PENDING_SEARCH_READ = "myPatientAllPendingSearchRead",
  ALL_PENDING_ASSIGN_STAFF_UPDATE = "myPatientAllPendingAssignStaffUpdate",
  INCOMPLETE_SEARCH_READ = "myPatientIncompleteSearchRead",
  INCOMPLETE_ASSIGN_STAFF_UPDATE = "myPatientIncompleteAssignStaffUpdate",
  STAFFING_QUEUE_SEARCH_READ = "myPatientStaffingQueueSearchRead",
  STAFFING_QUEUE_ASSIGN_STAFF_UPDATE = "myPatientStaffingQueueAssignStaffUpdate",
  STAFFING_QUEUE_UNASSIGN_STAFF_UPDATE = "myPatientStaffingQueueUnassignStaffUpdate",
  STAFFING_QUEUE_REMOVE_FROM_LIST_UPDATE = "myPatientStaffingQueueRemoveFromListUpdate",
}

export enum PatientRecordsAccess {
  PATIENT_RECORD_SEARCH_READ = "patientRecordSearchRead",
  CHECK_ELIGIBILITY_CREATE = "checkEligibilityCreate",
  CREATE_RECORD = "createRecord",
  PATIENT_DETAILS_FORM_READ = "patientDetailsFormRead",
  PATIENT_DETAILS_FORM_UPDATE = "patientDetailsFormUpdate",
  PATIENT_DETAILS_FORM_SAVE_AND_EXIT_CREATE = "patientDetailsFormSaveAndExitCreate",
  CREATE_PATIENT_TO_CREATE_REFERRAL = "createPatientToCreateReferral",
  AUTHORIZATION_HISTORY_READ = "authorizationHistoryRead",
  ELIGIBILITY_HISTORY_READ = "eligibilityHistoryRead",
  PATIENT_DETAILS_HEADER_READ = "patientDetailsHeaderRead",
  PATIENT_DETAILS_TO_CREATE_REFERRAL = "patientDetailsToCreateReferral",
  SAVE_AND_PROCEED_TO_NEXT_CREATE = "saveAndProceedToNextCreate",
  REFERRAL_INTAKE_SAVE_AND_EXIT_CREATE = "referralIntakeSaveAndExitCreate",
  SAVE_DETAILS_CREATE = "saveDetailsCreate",
  RE_AUTH_SAVE_AND_PROCEED_TO_NEXT_CREATE = "reAuthSaveAndProceedToNextCreate",
  RE_AUTH_SAVE_AND_EXIT_CREATE = "reAuthSaveAndExitCreate",
  RE_AUTH_SAVE_DETAILS_CREATE = "reAuthSaveDetailsCreate",
  DOCUMENT_READ = "documentsRead",
  DOCUMENT_CREATE = "documentsCreate",
  DOCUMENT_UPDATE = "documentsUpdate",
  DIAGNOSIS_CODE_READ = "diagnosisCodeRead",
  DIAGNOSIS_CODE_CREATE = "diagnosisCodeCreate",
}

export enum AuthDetailsAccess {
  AUTH_DETAILS_CREATE_RE_AUTH_REQUEST = "authDetailsCreateReAuthRequest",
  AUTH_DETAILS_SAVE_PATIENT_DATA_UPDATE = "authDetailsSavePatientDataUpdate",
  AUTH_DETAILS_SAVE_AND_EXIT_UPDATE = "authDetailsSaveAndExitUpdate",
  MEDICAL_HISTORY_UPDATE = "medicalHistoryAuthDetailsUpdate",
  MEDICAL_HISTORY_READ = "medicalHistoryAuthDetailsRead",
  GENERAL_INFO_READ = "generalInfoAuthDetailsRead",
  GENERAL_INFO_CREATE = "generalInfoAuthDetailsCreate",
  AUTH_DETAILS_ADD_NOTES_READ = "authorizationDetailsAddNotesRead",
  AUTH_DETAILS_ADD_NOTES_CREATE = "authorizationDetailsAddNotesCreate",
  AUTH_DETAILS_ADD_NOTES_UPDATE = "authorizationDetailsAddNotesUpdate",
  DISCIPLINE_AUTH_DETAILS_READ = "disciplineAuthDetailsRead",
  DISCIPLINE_AUTH_DETAILS_UPDATE = "disciplineAuthDetailsUpdate",
  SERVICE_PROVIDER_AUTH_DETAILS_READ = "serviceProviderAuthDetailsRead",
  SERVICE_PROVIDER_AUTH_DETAILS_UPDATE = "serviceProviderAuthDetailsUpdate",
  ORDERING_PHYSICIAN_AUTH_DETAILS_READ = "orderingProviderAuthDetailsRead",
  ORDERING_PHYSICIAN_AUTH_DETAILS_UPDATE = "orderingProviderAuthDetailsUpdate",
  FOLLOWING_PHYSICIAN_AUTH_DETAILS_READ = "followingProviderAuthDetailsRead",
  FOLLOWING_PHYSICIAN_AUTH_DETAILS_UPDATE = "followingProviderAuthDetailsUpdate",
  DISCIPLINE_SERVICE_DETAILS_READ = "disciplineServiceDetailsRead",
  DISCIPLINE_SERVICE_DETAILS_UPDATE = "disciplineServiceDetailsUpdate",
  DISCIPLINE_SERVICE_DETAILS_VISIT_READ = "disciplineServiceDetailsVisitRead",
  DISCIPLINE_SERVICE_DETAILS_VISIT_UPDATE = "disciplineServiceDetailsVisitUpdate",
  DISCIPLINE_SERVICE_DETAILS_URGENCY_READ = "disciplineServiceDetailsUrgencyRead",
  DISCIPLINE_SERVICE_DETAILS_URGENCY_UPDATE = "disciplineServiceDetailsUrgencyUpdate",
  DISCIPLINE_SERVICE_DETAILS_STATUS_READ = "disciplineServiceDetailsStatusRead",
  DISCIPLINE_SERVICE_DETAILS_STATUS_UPDATE = "disciplineServiceDetailsStatusUpdate",
  DISCIPLINE_SERVICE_DETAILS_HCPCS_READ = "disciplineServiceDetailsHCPCSRead",
  DISCIPLINE_SERVICE_DETAILS_HCPCS_UPDATE = "disciplineServiceDetailsHCPCSUpdate",
  DISCIPLINE_SERVICE_DETAILS_PHYSICIAN_REVIEW_READ = "disciplineServiceDetailsPhysicianReviewRead",
  DISCIPLINE_SERVICE_DETAILS_PHYSICIAN_REVIEW_UPDATE = "disciplineServiceDetailsPhysicianReviewUpdate",
  DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_READ = "disciplineServiceDetailsAddNotesRead",
  DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_CREATE = "disciplineServiceDetailsAddNotesCreate",
  DISCIPLINE_SERVICE_DETAILS_ADD_NOTES_UPDATE = "disciplineServiceDetailsAddNotesUpdate",
  DISCIPLINE_SAVE_SERVICE_DETAILS_UPDATE = "disciplineSaveServiceDetailsUpdate",
  ATTACH_LETTER_READ = "attachLetterAuthDetailsRead",
  ATTACH_LETTER_CREATE = "attachLetterAuthDetailsCreate"
}

export enum AdminCenterAccess {
  ADMIN_CENTER_USER_MANAGEMENT_READ = "adminCenterUserManagementRead",
  ADMIN_CENTER_USER_MANAGEMENT_UPDATE = "adminCenterUserManagementUpdate",
  ADMIN_CENTER_ROLE_MANAGEMENT_READ = "adminCenterRoleManagementRead",
  ADMIN_CENTER_ROLE_MANAGEMENT_UPDATE = "adminCenterRoleManagementUpdate",
  ADMIN_CENTER_ROLE_MANAGEMENT_CREATE = "adminCenterRoleManagementCreate",
  ADMIN_CENTER_NOTE_MANAGEMENT_READ = "adminCenterNoteManagementRead",
  ADMIN_CENTER_NOTE_MANAGEMENT_CREATE = "adminCenterNoteManagementCreate",
  ADMIN_CENTER_NOTE_MANAGEMENT_UPDATE = "adminCenterNoteManagementUpdate",
}

import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import PatientInformationView from "../components/PatientInformation";
import {
  getPatients,
  patientInformationReset,
} from "../../../../../actions/PatientInformation";
import { authorizationHistoryReset } from "../../../../../actions/AuthorizationHistory";
import { checkEligibility } from "../../../../../actions/AllPatientRecord";
import { eligibilityData } from "../../../../../utils/Eligibility";
import {
  getEligibilityRecord,
  getMasterEligibilityPayer,
} from "../../../../../actions/Master";
import { getListResult } from "../../../../../utils";
import { HealthPlanPayload } from "../../../../../models/Patient";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { resetStates } from "../../../../../actions/Common";
import { UserDetailsPayload } from "../../../../../models/User";

const mapStateToProps = ({
  checkEligibilityState,
  eligibilityRecordState,
  patientInformationViewState,
  patientPhotoUpdateState,
  payerState,
  loggedInUserState,
  updatePatientHeaderState,
  patientInformationReceivedState,
}: ApplicationState) => ({
  checkEligibilityResponse: checkEligibilityState,
  data: patientInformationViewState as PatientInformationViewResponse,
  eligibilityRecord: eligibilityData(
    eligibilityRecordState.response,
    checkEligibilityState.response
  ),
  patientPhotoData: patientPhotoUpdateState,
  payerState: getListResult(payerState.response),
  user: loggedInUserState.response as UserDetailsPayload,
  updatePatientHeaderData: updatePatientHeaderState,
  infoReceivedResp:
    patientInformationReceivedState as PatientInformationViewResponse,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getMasterEligibilityPayer: () => {
      dispatch(getMasterEligibilityPayer());
    },
    getPatient: (id: number, type: number) => {
      dispatch(getPatients(id, type));
    },
    patientInformationReset: () => {
      dispatch(patientInformationReset());
    },
    checkEligibility: (payload: HealthPlanPayload) => {
      dispatch(checkEligibility(payload));
    },
    authorizationHistoryReset: () => {
      dispatch(authorizationHistoryReset());
    },
    getEligibilityData: (benefitPlanUid: string) => {
      dispatch(getEligibilityRecord(benefitPlanUid));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInformationView);

import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import ReauthRequestor from "../components/ReauthRequestor";
import { getReAuthReferralSourceMasterResp } from "../../../../../actions/Master";

const mapStateToProps = ({ reAuthReferralSourceState }: ApplicationState) => ({
  reAuthReferralSourceState: reAuthReferralSourceState,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getReAuthReferralSourceMasterResp: () => {
      dispatch(getReAuthReferralSourceMasterResp());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReauthRequestor);

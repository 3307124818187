import { colors, others } from "../../../../../styles/colors";

export const baseContainer = () => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  background: others.otherColors[33],
  padding: "1.5rem",
  borderRadius: ".487rem",
  widht: "100%",
});
export const headerStyle = () => ({
  backgroundColor: colors.backgroundColor[8],
  borderRadius: ".487rem",
  padding: "1.2rem .975rem",
  width: "100%",
  display: "flex",
});
export const rowStyle = () => ({
  minHeight: "13.625rem",
});
export const AvatarBox = () => ({
  width: "3rem",
  height: "3rem",
});
export const CreateSchedule = () => ({
  backgroundColor: colors.primary.main,
  color: others.otherColors[33],
  borderRadius: "1rem",
  "&:hover": {
    backgroundColor: colors.primary.main,
    color: others.otherColors[33],
  },
});
export const patientDetailStyle = () => ({
  width: "100%",
  padding: ".975rem",
  display: "flex",
  alignItems: "center",
});
export const insuranceDetailsContainer = () => ({
  padding: "1rem 2rem 0 0rem",
});
export const InsuranceGrid = () => ({
  borderRadius: "0.7rem",
  padding: "1rem 2rem 1rem 2.5rem",
  background: colors.backgroundColor[9],
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
});

export const InsuranceTable = () => ({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    padding: ".5rem 0 0 0",
  },
});

export const RecordGridContainer = () => ({
  padding: "1rem 0.7rem 1rem 1rem",
});

export const StatusValue = () => ({
  textDecoration: "underline",
  marginLeft: "0.2rem",
});

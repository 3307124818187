import { Reducer } from "redux";

import { NPPESDataResponse } from "../models/Api/PhysicianDetails";
import { ReferralIntakePhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import { GetNPPESDataActionDispatchTypes, NPPESinitialRecord } from "../constants/PhysicianDetails";

const initialState: NPPESDataResponse = {
  loading: true,
  response: NPPESinitialRecord
};

const NppesDetailsDataReducer: Reducer<
NPPESDataResponse,
  ReferralIntakePhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_RESET: {
      return initialState;
    }
  }
  return state;
};

export default NppesDetailsDataReducer;

const Weight = {
  1: "300",
  2: "400",
  3: "500",
  4: "600",
  5: "700",
  6: "800",
  7: "900"
};
export default { Weight };

import { get } from "lodash";

import { NonIndexRouteWithFullPath, Module, SubModule } from "../../models/Modules";
import { RouteUrlInfo } from "../../models/User";

/**
 * This function returns formatted menu item data
 * @param {userModuleResponse[]}  - module api response stored in state
 *
 * @returns route menu item data array
 */
export const prepareMenuItems = (userModuleResponse: Module[]): Module[] => {
  const inputArray = JSON.parse(JSON.stringify(userModuleResponse));

  // Create a map to store submodules by their subModuleId
  const subModuleMap: { [key: number]: SubModule } = {};

  // Iterate over the submodules and store them in the map
  inputArray.forEach((module: Module) => {
    module.subModules.forEach((subModule) => {
      const { subModuleId } = subModule;
      subModuleMap[subModuleId] = subModule;
    });
  });
  inputArray.forEach((module: Module) => {
    module.subModules.forEach((subModule) => {
      const { parentSubModuleId } = subModule;
      if (parentSubModuleId !== -1) {
        const parentSubModule = subModuleMap[parentSubModuleId];
        if (parentSubModule) {
          if (!parentSubModule.subModules) {
            parentSubModule.subModules = [];
          }
          parentSubModule.subModules.push(subModule);
        }
      }
    });
  });
  inputArray.forEach((module: Module) => {
    module.subModules = module.subModules.filter(
      (subModule) => subModule.parentSubModuleId === -1
    );
  });

  const updateModuleFullRoutePath = (
    subModule: SubModule,
    parentRoutePath = ""
  ): void => {
    const fullRoutePath = `${parentRoutePath}/${subModule.routeUrl}`;
    subModule.fullRoutePath = fullRoutePath;
    if (subModule.subModules) {
      subModule.subModules.forEach((childSubModule) => {
        updateModuleFullRoutePath(childSubModule, fullRoutePath);
      });
    }
  };

  inputArray.forEach((module: Module) => {
    module.subModules.forEach((subModule) => {
      updateModuleFullRoutePath(subModule, module.routeUrl);
    });
  });
  return inputArray;
};

/**
 * This function returns matching route url from webpage and checkroutestatus state data
 * @param {Module[]}  userModuleData - module api response stored in state
 * @param {string} pathname - react router dom path name of current webpage
 * @param {any} matchPath - match path function from react router dom
 *
 * @returns returns matching modules and submodules id from nested route structure
 */

const findModuleSubmoduleId = (
  userModuleData: Module[],
  pathName: string,
  matchPath: any
) : Module | SubModule => {
  const filteredRouteData = prepareMenuItems(userModuleData);
  let tempRouteData: Module | SubModule = filteredRouteData[0];
  const findFullRoutePath = (
    subModule: SubModule[] | undefined,
    subModulePathName: string
  ): SubModule | null => {
    subModule &&
      subModule.forEach((subModule) => {
        if (matchPath(subModule.fullRoutePath, subModulePathName)) {
          tempRouteData = subModule;
          return;
        } else if (
          Object.prototype.hasOwnProperty.call(subModule, "subModules")
        ) {
          return findFullRoutePath(subModule.subModules, subModulePathName);
        }
      });
    return null;
  };
  filteredRouteData.forEach((module: Module | SubModule) => {
    if (module.routeUrl === pathName) {
      tempRouteData = module;
      return;
    } else if (Object.prototype.hasOwnProperty.call(module, "subModules")) {
      return findFullRoutePath(module.subModules, pathName);
    }
  });
  return tempRouteData;
};

/**
 * This function returns matching route url from webpage and checkroutestatus state data
 * @param {NonIndexRouteWithFullPath[]}  routeMappingArray - predefined routes
 * @returns updated route whose structure matches with the structure with module api response
 */

export const updateRouteObjectFullRoutePath = (
  routeMappingArray: NonIndexRouteWithFullPath[]
) => {
  const updateRouteFullRoutePath = (
    routeObj: NonIndexRouteWithFullPath,
    parentPath: string | undefined
  ) => {
    routeObj.fullRoutePath = `${parentPath}/${routeObj.path}`;
    if (routeObj.children) {
      routeObj.children.forEach((routeObjChild: NonIndexRouteWithFullPath) => {
        updateRouteFullRoutePath(routeObjChild, routeObj.fullRoutePath);
      });
    }
    return routeObj;
  };

  routeMappingArray.forEach((routeObj: NonIndexRouteWithFullPath) => {
    routeObj.fullRoutePath = routeObj.path;
    if (routeObj.children) {
      routeObj.children.forEach((routeObjChild: NonIndexRouteWithFullPath) => {
        updateRouteFullRoutePath(routeObjChild, routeObj.path);
      });
    }
  });

  return routeMappingArray;
};

/**
 * This function returns matching route url from webpage and checkroutestatus state data
 * @param {Module[]}  usermodule - module api response stored in state
 * @param {string} pathname - react router dom path name of current webpage
 * @param {React.Dispatch<React.SetStateAction<any>>} saveRouteUrlInfo -set state function
 * @param {any} matchPath - match path function from react router dom
 *
 * @returns {React.Dispatch<React.SetStateAction<any>>} route path set data to redux store
 */

export const setActiveModuleSubModule = (
  usermodule: Module[],
  pathname: string,
  saveRouteUrlInfo: (routeUrlInfo: RouteUrlInfo) => void,
  matchPath: any
) => {
  const filteredModuledata = findModuleSubmoduleId(
    usermodule,
    pathname,
    matchPath
  );
  const moduleSubModuleData = {
    routeUrl: get(filteredModuledata, 'routeUrl', null),
    moduleId: get(filteredModuledata, 'moduleId', null),
    subModuleId: get(filteredModuledata, 'subModuleId', null)
  };
  saveRouteUrlInfo(moduleSubModuleData);
};

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { isNil } from "lodash";

import { FormInputProps } from "./FormInputProps";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";
import useMenuItem from "../../hooks/useMenuItem";
import { DateFormats } from "../../constants/Date";

const DatePicker = ({
  allowFutureDate,
  control,
  disabled,
  helper,
  label,
  maximumdate,
  minimumDate,
  name,
  onChangeHandler,
  InputStyle,
  readOnly,
}: FormInputProps) => {

  const { open, onOpen, onClose } = useMenuItem();

  //watch is required as props for allowFutureDate validation

    // TBD - Ajit
    // // in case watch is not passed yet
    // const dateValue =
    //   isNil(datePickerValue) && !isNil(value) ? value : datePickerValue;
    // if (!isNull(dateValue)) {
    //   if (dateValue && dateValue.hasOwnProperty('isValid') && !dateValue.isValid()) {
    //     return "Invalid date";
    //   }
    //   if (
    //     false === allowFutureDate &&
    //     !dateValue.isBefore(dayjs().hour(0).minute(0))
    //   ) {
    //     return "Future date not allowed";
    //   }
    // }
  const customDateValidator = (value: any) => {
    if (!isNil(value)) {
      let dateObj = value;
      if (value && typeof value === "string") {
        dateObj = dayjs(value);
      }

      if (dateObj && !dateObj.isValid()) {
        return "Invalid date";
      }

      if (dateObj && dateObj.format("YYYY") < 1900) {
        return "Invalid date";
      }
      
      if (
        maximumdate &&
        maximumdate.isValid() &&
        !dateObj.isSame(maximumdate, "day") &&
        !dateObj.isBefore(maximumdate, "day")
      ) {
        return `Allowed till ${maximumdate.format(DateFormats.MM_DD_YYYY)}`;
      }
      
      if (
        minimumDate &&
        minimumDate.isValid() &&
        !dateObj.isSame(minimumDate, "day") &&
        !dateObj.isAfter(minimumDate, "day")
      ) {
        return `Allowed from ${minimumDate.format(DateFormats.MM_DD_YYYY)}`;
      }

      if (
        false === allowFutureDate &&
        !dateObj.isSame(dayjs(), "day") &&
        !dateObj.isBefore(dayjs(), "day")
      ) {
        return "Future date not allowed";
      }
    }
    return true;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        rules={{ ...helper, ...{ validate: customDateValidator } }}
        render={({
          field: { onChange, value, ref, onBlur, ...fieldProps },
          fieldState: { error },
        }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                {...fieldProps}
                onClose={onClose}
                open={open}
                onOpen={onOpen}
                disabled={disabled ? disabled : false}
                readOnly={readOnly}
                inputRef={ref}
                label={
                  label ? (
                    <Typography
                      variant="h5"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                    >
                      {label}
                    </Typography>
                  ) : (
                    label
                  )
                }
                value={dayjs(value)}
                onChange={(e) => {
                  onChangeHandler && onChangeHandler(e);
                  onChange(e);
                }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    required:
                      helper && helper.required && helper.required.value,
                    error: error !== undefined,
                    helperText: error ? error.message : null,
                    onBlur: onBlur,
                    InputLabelProps: { shrink: true },
                  },
                }}
                format="MM/DD/YYYY"
                maxDate={!allowFutureDate ? dayjs(new Date()) : maximumdate}
                minDate={!isNil(minimumDate) ? minimumDate : null}
                disableFuture={!allowFutureDate}
                sx={InputStyle}
              />
            </LocalizationProvider>
          );
        }}
      />
    </>
  );
};

export default DatePicker;

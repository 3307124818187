export const physicianRecords = [
  {
    physicianId: 0,
    physicianName: "NAME",
    physicianNpiNumber: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    fax: "",
    region: "",
    sourceId: "",
    sourceName: "",
  },
];
export const physicianDefaultObject = {
  physicianId: 0,
  physicianName: "",
  physicianNpiNumber: "",
  physicianState: "",
  streetAddress1: "",
  streetAddress2: "",
  postalCode: "",
  city: "",
  phone: "",
  fax: "",
  street: "",
};

export enum PhysicianListDefaultHeader {
  NAME = "NAME",
  NPINumber = "NPI Number",
  Action = "Action",
}

export enum UserListDefaultHeader {
  NAME = "NAME",
  USER_ID = "USER ID",
  Action = "Action",
}

export enum MessageType {
  SELECT_PHYSICIAN = 0,
  PHYSICIAN_DETAILS_SAVED,
}

export enum PhysicianTypeID {
  DEFAULT = 0,
  PRIMARY = 1,
  ORDERING = 2,
  FOLLOWING = 3,
}

export const ResponseMessages = (value: number) => {
  switch (value) {
    case MessageType.SELECT_PHYSICIAN:
      return "Select physician name";
    case MessageType.PHYSICIAN_DETAILS_SAVED:
      return "Physician details saved successfully !";
    default:
      return "";
  }
};

// Patient Record Ordering Physician new wireframe
export enum GetPhysicianRecordsActionDispatchTypes {
  GET_PHYSICIAN_RECORDS_INVOKE = "GET_PHYSICIAN_RECORDS_INVOKE",
  GET_PHYSICIAN_RECORDS_SUCCESS = "GET_PHYSICIAN_RECORDS_SUCCESS",
  RESET_PHYSICIAN_RECORDS = "RESET_PHYSICIAN_RECORDS",
}

export enum GetPhysicianDetailsActionDispatchTypes {
  GET_PHYSICIAN_DETAILS_INVOKE = "GET_PHYSICIAN_DETAILS_INVOKE",
  GET_PHYSICIAN_DETAILS_SUCCESS = "GET_PHYSICIAN_DETAILS_SUCCESS",
  GET_PHYSICIAN_DETAILS_RESET = "GET_PHYSICIAN_DETAILS_RESET",
}

export enum GetNPPESDataActionDispatchTypes {
  GET_NPPES_DATA_INVOKE = "GET_NPPES_DATA_INVOKE",
  GET_NPPES_DATA_SUCCESS = "GET_NPPES_DATA_SUCCESS",
  GET_NPPES_DATA_RESET = "GET_NPPES_DATA_RESET",
}

export enum PutPhysicianDetailsActionDispatchTypes {
  PUT_PHYSICIAN_DETAILS_ERROR = "PUT_PHYSICIAN_DETAILS_ERROR",
  PUT_PHYSICIAN_DETAILS_INVOKE = "PUT_PHYSICIAN_DETAILS_INVOKE",
  PUT_PHYSICIAN_DETAILS_SUCCESS = "PUT_PHYSICIAN_DETAILS_SUCCESS",
}

export enum PostPhysicianDetailsActionDispatchTypes {
  POST_PHYSICIAN_DETAILS_ERROR = "POST_PHYSICIAN_DETAILS_ERROR",
  POST_PHYSICIAN_DETAILS_INVOKE = "POST_PHYSICIAN_DETAILS_INVOKE",
  POST_PHYSICIAN_DETAILS_SUCCESS = "POST_PHYSICIAN_DETAILS_SUCCESS",
  POST_PHYSICIAN_DETAILS_RESET = "POST_PHYSICIAN_DETAILS_RESET",
}

export enum DeletePhysicianDetailsActionDispatchTypes {
  DELETE_PHYSICIAN_DETAILS_INVOKE = "DELETE_PHYSICIAN_DETAILS_INVOKE",
  DELETE_PHYSICIAN_DETAILS_SUCCESS = "DELETE_PHYSICIAN_DETAILS_SUCCESS",
}

// Patient Record Following Physician
export enum GetFollowingPhysicianDetailsActionDispatchTypes {
  GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE = "GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE",
  GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS = "GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS",
}

export enum PutFollowingPhysicianDetailsActionDispatchTypes {
  PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR = "PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR",
  PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE = "PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE",
  PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS = "PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS",
}

export enum PostFollowingPhysicianDetailsActionDispatchTypes {
  POST_FOLLOWING_PHYSICIAN_DETAILS_ERROR = "POST_FOLLOWING_PHYSICIAN_DETAILS_ERROR",
  POST_FOLLOWING_PHYSICIAN_DETAILS_INVOKE = "POST_FOLLOWING_PHYSICIAN_DETAILS_INVOKE",
  POST_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS = "POST_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS",
  FOLLOWING_PHYSICIAN_DETAILS_RESET = "FOLLOWING_PHYSICIAN_DETAILS_RESET",
}
export const physicianInputFormLabels = (names: any) => [
  {
    label: "StreetName1 ",
    name: names.address[0],
  },
  {
    label: "StreetName2 ",
    name: names.address[1],
  },
  {
    label: "City ",
    name: names.address[2],
  },
  {
    label: "County ",
    name: names.address[3],
  },
  {
    label: "Zipcode ",
    name: names.address[4],
  },
  {
    label: "State ",
    name: names.address[5],
  },
];

export enum AuthOrderingPhysicianDetailsActionDispatchType {
  GET_ORDERING_PHYSICIAN_DETAILS_INVOKE = "GET_ORDERING_PHYSICIAN_DETAILS_INVOKE",
  GET_ORDERING_PHYSICIAN_DETAILS_SUCCESS = "GET_ORDERING_PHYSICIAN_DETAILS_SUCCESS",
  GET_ORDERING_PHYSICIAN_DETAILS_RESET = "GET_ORDERING_PHYSICIAN_DETAILS_RESET",
  PUT_ORDERING_PHYSICIAN_DETAILS_ERROR = "PUT_ORDERING_PHYSICIAN_DETAILS_ERROR",
  PUT_ORDERING_PHYSICIAN_DETAILS_INVOKE = "PUT_ORDERING_PHYSICIAN_DETAILS_INVOKE",
  PUT_ORDERING_PHYSICIAN_DETAILS_SUCCESS = "PUT_ORDERING_PHYSICIAN_DETAILS_SUCCESS",
}

export enum AuthFollowingPhysicianDetailsActionDispatchType {
  GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE = "GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE",
  GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS = "GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS",
  GET_FOLLOWING_PHYSICIAN_DETAILS_RESET = "GET_FOLLOWING_PHYSICIAN_DETAILS_RESET",
  PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR = "PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR",
  PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE = "PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE",
  PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS = "PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS",
}

export enum PhysicianDetailsLabel {
  ADDRESS = "Address",
  FAX = "Fax",
  NPI = "NPI",
  PHONE = "Phone",
  PHYSICIAN_NAME = "Physician Name",
  PRIMARY_PHYSICIAN_NAME = "Primary Physician Name",
}

export const followingPhysicianFormNames = {
  physicianName: "physicianName",
  physicianNpiId: "physicianNpiId",
  phoneNo: "phoneNo",
  phoneExt: "phoneExt",
  taxId: "taxId",
  address: [
    "streetName1",
    "streetName2",
    "city",
    "county",
    "physicianState",
    "zipCode",
  ],
  fax: "fax",
};

export enum PhysicianDetailsDetailsHeader {
  NPI = "NPI:",
  PHYSICIAN_NAME = "Physician Name:",
}

export const primaryPhysicianInitialData = {
  referralId: 0,
  referralPhysicianDetailId: 0,
  physicianId: -1,
  physicianName: "",
  physicianNpiId: "",
  phoneExt: "",
  phoneNo: "",
  taxId: "",
  streetName: "",
  county: "",
  city: "",
  physicianState: "",
  zipCode: "",
  userCreated: null,
  physicianTypeId: PhysicianTypeID.PRIMARY,
  physicianUid: "",
};

export const physicianInitialData = {
  ...primaryPhysicianInitialData,
  physicianTypeId: -1,
};

export const primaryPhysicianFormNames = {
  physicianName: "primaryPhysician.physicianName",
  physicianNpiId: "primaryPhysician.physicianNpiId",
  phoneNo: "primaryPhysician.phoneNo",
  phoneExt: "primaryPhysician.phoneExt",
  taxId: "primaryPhysician.taxId",
  address: [
    "primaryPhysician.streetName1",
    "primaryPhysician.streetName2",
    "primaryPhysician.city",
    "primaryPhysician.county",
    "primaryPhysician.physicianState",
    "primaryPhysician.zipCode",
  ],
  fax: "primaryPhysician.fax",
};

export const NppesNameErrorMsg = "Last Name or First name is required.";

export const NPPESinitialRecord = [
  {
    physicianId: 0,
    physicianName: "",
    physicianNpiId: "",
    physicianAddress: "",
    phoneNo: "",
    taxId: "",
    phoneExt: "",
    county: "",
    streetName1: "",
    streetName2: "",
    fax: "",
    city: "",
    physicianState: "",
    zipCode: "",
    physicianUid: "",
  },
];

export enum NppesApiCall {
  BY_NPI = "byNpi",
  BY_NAME = "byName",
}

export type AutocompleteDropDownPlacement =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "left-start"
  | "left-end"
  | "right-start"
  | "right-end";

import { Dispatch } from "redux";
import { loadInsights } from "../utils/logHandlers/appInsight";
import { AppConfigsActionDispatchTypes } from "../constants/AppConfigs";
import { updateConfigs } from "../constants/Config";

export const appConfigMiddleware = () => (next: Dispatch) => (action: any) => {
  if (action.type === AppConfigsActionDispatchTypes.APP_CONFIG_SUCCESS) {
    const { response } = action;
    loadInsights(response.REACT_APP_APPINSIGHTS_CONNECTION_STRING);
    updateConfigs(response);
  }

  return next(action);
};

import { Box, Typography } from "@mui/material";

import fontWeight from "../../styles/mui/fontWeight";
import { colors } from "../../styles/colors";
import {
  ReadOnlyFieldContainer,
  ReadOnlyFieldIcon,
  ReadOnlyFieldLabel,
  ReadOnlyFieldValue,
} from "./styles/style";

type ReadOnlyFieldProps = {
  label: string;
  value: string | number;
  icon?: any;
};

const ReadOnlyField = (props: ReadOnlyFieldProps) => {
  const { label, value, icon } = props;
  return (
    <Box sx={ReadOnlyFieldContainer}>
      <Box sx={ReadOnlyFieldLabel}>
        <Typography fontWeight={fontWeight.Weight[3]} color={colors.fonts[2]}>
          {label}
        </Typography>
      </Box>
      <Box sx={ReadOnlyFieldValue}>
        {icon && <Box sx={ReadOnlyFieldIcon}>{icon}</Box>}
        <Box color={colors.black[1]}>
          <Typography fontWeight={fontWeight.Weight[4]} lineHeight="1.3">
            {value || ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReadOnlyField;

import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { others } from "../../../../../styles/colors";

export const BreadcrumbsRoot = () => ({
  ".MuiBreadcrumbs-separator": {
    margin: 0,
  },
});

export const BreadcrumbsContainer = () => ({
  padding: "1.4rem .75rem 0 .75rem",
});

export const HeaderBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0.3rem",
  marginTop: "1rem",
}));

export const InnerBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const HeaderText = () => ({
  paddingLeft: "1rem",
  paddingRight: "1.7rem",
  paddingTop: "0.5rem",
});

export const HeaderAuthText = () => ({
  paddingLeft: "1.5rem",
  paddingTop: "0.7rem",
});

export const AuthText = () => ({
  paddingLeft: "1.5rem",
  paddingTop: "0.7rem",
  textDecoration: "underline"
});

export const BackBtn = () => ({
  width: "4rem",
});

export const SaveBtn = () => ({
  background: others.otherColors[33],
});

export const FooterBox = () => ({
  margin: "0.5rem",
  padding: "1.5rem 1rem",
  display: "flex",
  justifyContent: "end",
});

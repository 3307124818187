import { Box, styled, Tabs } from "@mui/material";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

import colors from "../../../../../styles/colors/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const GeneralInfoTabBorderBottom = styled(Box)(() => ({
    borderBottom: `1px solid ${colors.borders[1]}`,
    position: 'absolute',
    top: '-.07rem',
    width: '100%'
}));

export const GeneralInfoItemsTabs = styled(Tabs)(() => ({
    height: '100%',
    "& .MuiTabs-scroller": {
        overflow: "visible"
    },
    "& .MuiTabs-indicator": {
        zIndex: 1,
        height: '3px',
        borderRadius: '1rem'
    },
    "&.Mui-selected": {
        color: colors.fonts[18],
        fontWeight: fontWeight.Weight[6],
    },
    minHeight: '2.5rem',
    display: 'flex',
}));

export const MinimizeMaximizeIcon = () => ({
    paddingBottom: " 0.6rem",
    paddingLeft: " 0.5rem",
    display: 'flex',
    alignItems: 'center'
});

export const MainGrid = () => ({
    backgroundColor: colors.backgroundColor[2],
    borderRadius: "1rem",
    marginBottom: '0.8rem',
    padding: '1rem 1.5rem',
    boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.borders[1]}`
});

export const HeaderTabs = () => ({
    margin: '0rem 1.8rem 0.6rem 1.8rem '
});

export const Mandatory = () => ({
    marginBottom: '1.5rem',
    display: "flex",
});

export const TabelPannelGrid = () => ({
    position: "relative"
});

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
    color: colors.fonts[18],
    fontSize: '1.2rem',
    cursor: 'pointer'
}));

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
    color: colors.fonts[18],
    fontSize: '1.2rem',
    cursor: 'pointer'
}));
import { Reducer } from "redux";

import { get404InitialData } from "../utils";
import { AllPatientRecordResponse } from "../models/Api/AllPatientRecord";
import { AllPatientDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { PatientRecordDispatchTypes } from "../constants/AllPatientRecord";

const initialState: AllPatientRecordResponse = {
  loading: false,
  response: {
    totalRows: "",
    patientResponse: [],
  },
};

const AllPatientRecordReducer: Reducer<
  AllPatientRecordResponse,
  AllPatientDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientRecordDispatchTypes.PATIENT_RECORD_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientRecordDispatchTypes.PATIENT_RECORD_SUCCESS: {
      return {
        ...state,
        response: get404InitialData(action.response, []),
        loading: false,
      };
    }
  }
  return state;
};

export default AllPatientRecordReducer;

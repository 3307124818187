import { Box, Grid, Typography } from "@mui/material";
import { ReactElement } from "react";

import {
  AddressContainer,
  AddressFeild,
  AddressInfoContainer,
  FaMapMarkerAltIcon,
  FaRegEnvelopeIcon,
  FieldValue,
} from "../style";
import fontWeight from "../../../../../../styles/mui/fontWeight";
import colors from "../../../../../../styles/colors/colors";
import { onlyAlphabets } from "../../../../../../constants/Patterns";
import { flexAlignCentre } from "../../../../../../styles/mui/styles/display";
import { PatientInformationView } from "../../../../../../models/Patient";
import { getValue } from "../../../../../../utils";
import { PatientNameGrid } from "./MemberInfo";

export interface PropsFromState {
  patientData: PatientInformationView;
}
type AllProps = PropsFromState;

const AddressInfo: React.FC<AllProps> = ({ patientData }: AllProps) => {
  const addressFieldRender = (
    icon: ReactElement<any, any>,
    label: string,
    value: string
  ) => {
    return (
      <Box>
        <Box sx={flexAlignCentre}>
          {icon}
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={colors.fonts[5]}
            pl={"0.5rem"}
            mt={"0.2rem"}
          >
            {label}:
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[4]}
          sx={FieldValue}
        >
          {value}
        </Typography>
      </Box>
    );
  };
  return (
    <Grid container sx={AddressInfoContainer}>
      {PatientNameGrid(patientData, "patientName")}
      <Grid item xs={11}>
        <Grid container sx={AddressContainer}>
          <Grid item xs={"auto"} sx={AddressFeild}>
            {addressFieldRender(
              <FaMapMarkerAltIcon size="18" />,
              "Residential Address",
              onlyAlphabets.test(
                getValue(patientData, "addressInfo.residentialAddress")
              )
                ? getValue(patientData, "addressInfo.residentialAddress")
                : ""
            )}
          </Grid>
          <Grid item xs={"auto"} sx={AddressFeild}>
            {addressFieldRender(
              <FaRegEnvelopeIcon size="18" />,
              "Mailing Address",
              onlyAlphabets.test(
                getValue(patientData, "addressInfo.mailingAddress")
              )
                ? getValue(patientData, "addressInfo.mailingAddress")
                : ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressInfo;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { HealthPlanDetailsForm } from "../../../../../models/HealthPlan";
import { InformationReceivedDispatchTypes } from "../../../../../models/DispatchTypes/InformationReceived";
import CreateReferral from "../components/CreateReferral";
import {
  PatientReferralInfo,
  SaveInformation,
} from "../../../../../models/Patient";
import { EligibilityHistoryPayload } from "../../../../../models/AuthorizationHistory";
import { AuthorizationHistoryDispatchTypes } from "../../../../../models/DispatchTypes/AuthorizationHistory";
import { AuthorizationHistoryResponse } from "../../../../../models/Api/AuthorizationHistory";
import {
  getEligibilityHistory,
} from "../../../../../actions/AuthorizationHistory";
import { getEligibilityRecord } from "../../../../../actions/Master";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { SaveInformationReceivedData } from "../../../../../actions/PatientInformation";
import { getListResult } from "../../../../../utils";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  authorizationhistoryState,
  informationReceivedState,
  loggedInUserState,
  patientInformationReceivedState,
  patientInformationViewState,
  patientReferralInfoState,
  eligibilityRecordState,
  payerState,
  SavePatientInformationState,
}: ApplicationState) => ({
  authorizationHistory:
    authorizationhistoryState as AuthorizationHistoryResponse,
  data: patientInformationViewState as PatientInformationViewResponse,
  infoReceivedResp:
    patientInformationReceivedState as PatientInformationViewResponse,
  informationReceived:
    informationReceivedState.response as HealthPlanDetailsForm,
  patientReferralInfo: patientReferralInfoState.response as PatientReferralInfo,
  user: loggedInUserState.response,
  eligibilityRecordResponse: eligibilityRecordState.response,
  payerState: getListResult(payerState.response),
  savePatientInformationResponse: SavePatientInformationState.response,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<InformationReceivedDispatchTypes | AuthorizationHistoryDispatchTypes>
  >
) => {
  return {
    saveInfoReceived: (info: SaveInformation) => {
      dispatch(SaveInformationReceivedData(info));
    },
    getEligibilityData: (benefitPlanUid: string) => {
      dispatch(getEligibilityRecord(benefitPlanUid));
    },
    getEligibilityHistoryRecord: (
      payload: EligibilityHistoryPayload
    ) => {
      dispatch(getEligibilityHistory(payload));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral);

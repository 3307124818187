import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { isEmpty } from "lodash";

import { CheckEligibilityResponse } from "../../../../../models/Api/AllPatientRecord";
import { EligibilityRecord } from "../../../../../models/Master";
import {
  EligibilityCheckDispatchTypes,
  Icd10RecordsActionDispatchTypes,
  PatientDetailType,
  PatientRequestDispatchTypes,
  ReAuthReferralRequestDispatchType,
} from "../../../../../constants/AllPatientRecord";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import CreateReferral from "../containers/CreateReferral";
import CreateReferralHeader from "../../../containers/CreateReferralHeader";
import HistoryTabs from "../containers/HistoryTabs";
import {
  HealthPlanPayload,
  PatientFilterOptions,
} from "../../../../../models/Patient";
import { DateFormats } from "../../../../../constants/Date";
import { getValue, showErrorMessagesFromResponse } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { UserDetailsPayload } from "../../../../../models/User";
import { EligibilityRecordActionDispatchTypes } from "../../../../../constants/Master";
import { GetPhysicianDetailsActionDispatchTypes } from "../../../../../constants/PhysicianDetails";
import {
  ServiceProviderActionDispatchType,
  ServiceRequestActionDispatchType,
} from "../../../../../constants/Service";
import { DocumentReviewActionDispatchTypes } from "../../../../../constants/Document";
import {
  PatientInformationActionDispatchTypesView,
  PatientInformationReceivedActionDispatchTypes,
} from "../../../../../constants/PatientInformation";
import UpdateExistingEligibility from "./UpdateExistingEligibility";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { defaultHistoryObject } from "../../../../../constants/Patient";
import { rules } from "../../../../../utils/validation/Validation";
import { PageStatus } from "../../../../../constants/Authorization";
import PatientHeader from "../../../components/ActiveAuthCommon/PatientHeader/containers";
export interface PropsFromState {
  checkEligibilityResponse: CheckEligibilityResponse;
  data: PatientInformationViewResponse;
  eligibilityRecord: EligibilityRecord;
  payerState: PatientFilterOptions[];
  user: UserDetailsPayload;
  updatePatientHeaderData: PatientInformationViewResponse;
  infoReceivedResp: PatientInformationViewResponse;
}
export interface PropsDispatch {
  authorizationHistoryReset: () => void;
  checkEligibility: (payload: HealthPlanPayload) => void;
  getEligibilityData: (benefitPLanUid: string) => void;
  getPatient: (id: number, type: number) => void;
  getMasterEligibilityPayer: () => void;
  patientInformationReset: () => void;
  resetStates: (actionType: string[]) => void;
}

export interface HistoryTab {
  page: number;
  tabNumber: number;
}

type AllProps = PropsFromState & PropsDispatch;

const PatientInformationView: React.FC<AllProps> = ({
  authorizationHistoryReset,
  checkEligibility,
  checkEligibilityResponse,
  data,
  eligibilityRecord,
  getEligibilityData,
  getPatient,
  patientInformationReset,
  getMasterEligibilityPayer,
  payerState,
  resetStates,
  updatePatientHeaderData,
  infoReceivedResp,
}: AllProps) => {
  const { patientId } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [tabNumber, setTabNumber] = useState<HistoryTab>(defaultHistoryObject);
  const [checkEligibilityBtnClick, setCheckEligibilityBtnClick] =
    useState(false);
  const [updatedPayerDetails, setUpdatedPayerDetails] = useState<any>();

  const { error: eligibilityCheckError } = checkEligibilityResponse;
  const { response: updatePatientHeaderResponse } = updatePatientHeaderData;

  const [errorMessages, setErrorMessages] = useState<any>();
  const [isManual, setIsManual] = useState<boolean>(false);

  useEffect(() => {
    getMasterEligibilityPayer();
    return () => {
      resetStates([
        EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
        EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET,
        ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_RESET,
        PatientRequestDispatchTypes.PATIENT_REQUEST_RESET,
        Icd10RecordsActionDispatchTypes.ICD_10_RESET,
        GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET,
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET,
        ServiceRequestActionDispatchType.SERVICE_REQUEST_RESET,
        DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET,
        PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
        PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    getPatient(Number(patientId || 0), PatientDetailType.Patient);
  }, [updatePatientHeaderResponse]);

  useEffect(() => {
    if (getValue(infoReceivedResp, "response.patientId") > 0) {
      getPatient(Number(patientId || 0), PatientDetailType.Patient);
    }
  }, [infoReceivedResp]);

  const handleCheckEligibility = () => {
    resetStates([
      EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
      EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET,
    ]);
    setOpenModal(true);
  };
  const [infoReceivedFlag, setInfoReceivedFlag] = useState<boolean>(false);

  const getPayload = () => {
    const name = data && data.response.patientName.split(" ").reverse();
    const payload = {
      lastName: name && name[0] ? name[0] : "", //TBD - need API response for first name and last name as well  - Ajit
      dob: formatDate(
        getValue(data, "response.memberInfo.dateOfBirth"),
        DateFormats.YYYY_MM_DD
      ),
      payerId: getValue(data, "response.eligibilityPayerId", ""), // TBD - need API response for this
      policyId: getValue(data, "response.memberInfo.subscriberId"),
      payerName: getValue(data, "response.eligibilityPayerName"),
    };

    return payload;
  };

  const getUpdatedHealthPlan = (updatedInfo: any) => {
    setOpenModal(false);
    updatedInfo.policyId
      ? checkEligibility(updatedInfo)
      : setErrorMessages([rules.patientDetails.message]);
    setUpdatedPayerDetails(updatedInfo);
  };

  useEffect(() => {
    if (
      checkEligibilityResponse.response != null &&
      checkEligibilityResponse.loading === false &&
      !isEmpty(
        getValue(
          checkEligibilityResponse,
          "response.additionalNotes.benefitPlanUid",
          ""
        )
      ) &&
      getValue(
        checkEligibilityResponse,
        "response.additionalNotes.benefitPlanUid",
        ""
      ).length > 0
    ) {
      getEligibilityData(
        checkEligibilityResponse.response.additionalNotes.benefitPlanUid
      );
    }

    !isEmpty(eligibilityCheckError) &&
      setErrorMessages(showErrorMessagesFromResponse(eligibilityCheckError));
  }, [checkEligibilityResponse]);

  return (
    <>
      <CreateReferralHeader
        TabFlag={"patientInformation"}
        setInfoReceivedFlag={setInfoReceivedFlag}
        patientInformationReset={patientInformationReset}
        handleCheckEligibility={handleCheckEligibility}
        authorizationHistoryReset={authorizationHistoryReset}
        setCheckEligibilityBtnClick={setCheckEligibilityBtnClick}
        resetStates={resetStates}
      />
      <PatientHeader patientData={data} />
      <CreateReferral
        responseData={data}
        pageStatus={PageStatus.PATIENT_DETAILS}
        eligibilityRecord={eligibilityRecord}
        htmlControlId={`${PatientRecordsAccess.PATIENT_DETAILS_TO_CREATE_REFERRAL}||`}
        isModified={isModified}
        updatedPayerDetails={updatedPayerDetails}
        tab={tabNumber}
        checkEligibilityBtnClick={checkEligibilityBtnClick}
        setIsManual={setIsManual}
        isManual={isManual}
        errorMessages={errorMessages}
        infoReceivedFlag={infoReceivedFlag}
        isProceedManually={true}
        loading={checkEligibilityResponse.loading}
        checkEligibilityOpenModal={openModal}
      />
      <HistoryTabs setTabNumber={setTabNumber} />
      <UpdateExistingEligibility
        data={data}
        payerState={payerState}
        openModal={openModal}
        closeModal={setOpenModal}
        getUpdatedHealthPlan={getUpdatedHealthPlan}
        getPayload={getPayload}
        setIsModified={setIsModified}
      />
    </>
  );
};

export default PatientInformationView;

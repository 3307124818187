import { Box, Grid, Paper, alpha, styled } from "@mui/material";
import { FiCheck } from "react-icons/fi";
import { StyledComponent } from '@emotion/styled';
import type { BoxProps, PaperProps, GridProps } from '@mui/material';

import { accent, colors } from "../../../../styles/colors";

export const BreadcumbPaperCreateReferral: StyledComponent<PaperProps> = styled(Paper)(() => ({
  backgroundColor: "inherit",
}));

export const BreadcumbLeftGridCreateReferral: StyledComponent<GridProps> = styled(Grid)(() => ({
  padding: "1.4rem .75rem 1.4rem .75rem",
}));

export const BreadcumbRightGridCreateReferral: StyledComponent<GridProps> = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "end",
  padding: "0",
}));

export const BreadCumbButtonBoxCreateReferral: StyledComponent<BoxProps> = styled(Box)(() => ({
  alignSelf: "center",
}));

export const BackButtonHolder = () => ({
  paddingTop: "1.1rem",
});

export const NewReferralLabel = () => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "1rem",
});

export const GridNavHolder = () => ({
  borderRadius: "10px",
  backgroundColor: colors.backgrounds[1],
});

export const NavHolderLeft = () => ({
  width: "18.5%",
  minWidth: "16.5rem",
});

export const NavHolderRight = () => ({
  width: "81.5%",
  maxWidth: "calc(100% - 16.75rem)",
});

export const LeftNavBarMenu = (stepNumber: number, isActive: boolean) => ({
  width: "100%",
  height: "9rem",
  backgroundColor: isActive ? colors.white[1] : "inherit",
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px",
  borderTop: isActive
    ? `1px solid ${colors.black[7]}`
    : `1px solid ${colors.backgrounds[1]}`,
  borderRight: "none",
  borderBottom: isActive
    ? `1px solid ${colors.black[7]}`
    : `1px solid ${colors.backgrounds[1]}`,
  borderLeft: isActive
    ? `1px solid ${colors.black[7]}`
    : `1px solid ${colors.backgrounds[1]}`,
  boxSizing: "border-box",
  "&::after": {
    position: "absolute",
    border: `1px solid ${colors.white[1]}`,
    width: "1px",
    height: `calc(9rem - 2px)`,
    content: '""',
    right: "-1px",
    zIndex: 1,
    top: `calc(${(stepNumber - 1) * 9}rem + 1px)`,
    display: isActive ? "block" : "none",
  },
});

export const StepCircleHolder = () => ({
  height: "100%",
  minWidth: "3.75rem",
});

export const NavBarStepCircleContainer = () => ({
  height: "100%",
  width: "100%",
});

export const NavBarStepBorderTop = (index: number, editPageId: number) => ({
  width: "2px",
  height: "0.8rem",
  borderRight:
    index === 1
      ? "none"
      : `2px ${index < editPageId ? "solid" : "dashed"} 
      ${index <= editPageId ? colors.borders[3] : colors.black[7]
      }`,
  boxSizing: "border-box",
  margin: "0 auto",
});

export const NavBarStepCircle = (index: number, editPageId: number) => ({
  width: "2.1rem",
  height: "2.1rem",
  borderRadius: "50%",
  border: `2px solid ${index <= editPageId ? colors.borders[3] : colors.black[7]
    }`,
  margin: "0 auto",
  boxSizing: "border-box",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  backgroundColor: `${index < editPageId ? colors.borders[3] : "none"}`,
});

export const NavBarStepBorderBottom = (index: number, editPageId: number) => ({
  width: "2px",
  borderRight:
    index === 4
      ? "none"
      : `2px ${index < editPageId ? "solid" : "dashed"} 
      ${index <= editPageId ? colors.borders[3] : colors.black[7]
      }`,
  height: "calc(100% - 2.9rem)",
  boxSizing: "border-box",
  margin: "0 auto",
});

export const StepDetailsHolder = () => ({
  height: "100%",
  minWidth: "calc(100% - 3.75rem)",
  maxWidth: "calc(100% - 3.75rem)",
});

export const StepDetailsContainer = () => ({
  padding: ".95rem 0 .85rem 0",
  height: "100%",
});

export const StepDetailsHeader = () => ({
  padding: "0 1.2rem .65rem 0",
});

export const StepDetailsContent = () => ({
  padding: "0 2.75rem 0 0",
});

export const NavDescription = () => ({
  lineHeight: "1.05",
});

export const HeightFitContent = () => ({
  height: "fit-content",
});

export const CompleteText = () => ({
  display: "flex",
  paddingLeft: ".2rem",
});

export const FiCheckReferral: StyledComponent<any> = styled(FiCheck)(() => ({
  color: accent.accentGreen[100],
  height: "1.2rem",
  width: "1rem",
}));

export const CompleteActionHolder = () => ({
  padding: ".5rem 2rem 0 0",
});

export const NavHolderRightConatiner = () => ({
  backgroundColor: colors.white[1],
  borderTopRightRadius: "10px",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  borderTop: `1px solid ${colors.black[7]}`,
  borderRight: `1px solid ${colors.black[7]}`,
  borderBottom: `1px solid ${colors.black[7]}`,
  borderLeft: `1px solid ${colors.black[7]}`,
  minHeight: "38rem",
  marginBottom: "1rem",
});

export const ButtonStyle = (loading?: boolean) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0.7rem",
  background: loading ? alpha(colors.grey[1], 0.5) : colors.primary.main,
  padding: "0 0.9rem 0 0.9rem",
  height: "1.8rem",
  borderRadius: "0.8rem",
  color: colors.primary.main,
  cursor: loading ? "not-allowed" : "pointer",
});

import { Box, Button } from "@mui/material";
import { styles } from "../styles/style";
import { SyntheticEvent } from "react";

type ButtonProps = {
  dataTestId?: string;
  disabled?: boolean;
  handleClick?: (event: SyntheticEvent) => void;
  icon?: JSX.Element;
  label: string;
  secondary?: boolean;
  small?: boolean;
};

const CustomButton = ({
  dataTestId,
  disabled,
  handleClick,
  icon,
  label,
  secondary,
  small,
}: ButtonProps) => {
  if (icon) {
    return (
      <>
        <Box sx={styles.box}>
          <Button
            onClick={handleClick}
            disabled={disabled}
            data-testid={dataTestId}
            sx={
              small
                ? styles.small
                : secondary
                ? styles.secondary
                : styles.primary
            }
          >
            {icon}
            <Box sx={styles.label}>{label}</Box>
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box sx={styles.box}>
        <Button
          onClick={handleClick}
          disabled={disabled}
          data-testid={dataTestId}
          sx={secondary ? styles.secondary : styles.primary}
        >
          {label}
        </Button>
      </Box>
    </>
  );
};

export default CustomButton;

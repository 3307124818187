import { Reducer } from "redux";

import { ActiveUserListResponse } from "../models/Api/User";
import { UserListWithFilterDispatchTypes } from "../models/DispatchTypes/User";
import { UserListWithFilterActionDispatchTypes } from "../constants/User";

const initialState: ActiveUserListResponse = {
  loading: true,
  response: [],
};

const UserListWithFilterReducer: Reducer<ActiveUserListResponse, UserListWithFilterDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserListWithFilterActionDispatchTypes.GET_USER_LIST_WITH_FILTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserListWithFilterActionDispatchTypes.GET_USER_LIST_WITH_FILTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default UserListWithFilterReducer;

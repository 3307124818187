import { Reducer } from "redux";

import { NonCoveredServicesResponse } from "../models/Api/Master";
import { NonCoveredServicesDispatchTypes } from "../models/DispatchTypes/Master";
import { NonCoveredServicesActionDispatchTypes } from "../constants/Master";

const initialState: NonCoveredServicesResponse = {
  loading: false,
  response: [
    {
      serviceId: 0,
      serviceName: "",
    },
  ],
};

const NonCoveredService: Reducer<
  NonCoveredServicesResponse,
  NonCoveredServicesDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case NonCoveredServicesActionDispatchTypes.NONCOVERED_SERVICES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case NonCoveredServicesActionDispatchTypes.NONCOVERED_SERVICES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default NonCoveredService;

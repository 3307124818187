import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material";
import { FaSearch } from "react-icons/fa";

import { colors, others } from "../../../../../styles/colors";

export const rowOne = {
  boxSizing: "border-box",
  margin: "0.09rem 0.24rem",
  padding: "0.05rem",
};
export const title = {
  textAlign: "left",
  color: colors.black[2],
  textTransform: "uppercase",
  opacity: "1",
};

export const back = {
  display: "flex",
  margin: "1px 3px",
  textAlign: "left",
  textDecoration: "underline",
  letterSpacing: "0px",
  color: colors.primary.main,
  textTransform: "uppercase",
  opacity: "1",
};

export const description = {
  textAlign: "left",
  color: colors.black[2],
  opacity: "1",
};

// RoleManagement HEAD properties start
export const head = {
  justifyContent: "space-between",
  display: "flex",
  boxSizing: "border-box",
  margin: "0.09rem 0.24rem",
  padding: "0.97rem 0.5rem",
};

export const tableheading = {
  letterSpacing: "0px",
  textTransform: "uppercase",
  borderBottom: "unset",
  "&.MuiTableCell-root:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: "none",
  },
  "&.MuiTableCell-root:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: "none",
    paddingLeft: "4rem",
  },
};

export const tablecontent = {
  letterSpacing: "0px",
  opacity: "1",
};

export const h_buttons = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const body = {
  background: "white",
  opacity: 1,
  padding: "0.5rem",
  borderRadius: "0.5rem",
};

const searchbox = {
  display: "flex",
  alignItems: "center",
  padding: "0.97rem 0.24rem",
};

export const bodytable = {
  "& .MuiTableCell-root": {
    borderBottom: `0.05rem solid ${colors.borders[1]}`,
    borderRight: "none",
    borderLeft: "none",
  },
  "& th:first-of-type": {
    borderRadius: "1em 0 0 1em",
  },
  "& th:last-child": {
    borderRadius: "0 1em 1em 0",
  },
};

const tableheadbg = {
  background: colors.primary.light,
};

export const tablerow = { "& > *": { borderBottom: "unset" } };

export const idbox = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingLeft: "1.5rem",
};

export const id = {
  display: "flex",
  alignSelf: "center",
  width: "100%",
  marginLeft: "0.5rem",
};

export const RoleIdTablecell = {
  alignSelf: "center",
  paddingLeft: "2.2rem",
};

export const toggleiconbox = { alignItems: "left" };

export const toggleiconcolor = { color: colors.primary.main };

export const actionbox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const actionicon = {
  margin: "0.24rem 0.5rem",
  color: colors.fonts[18],
  backgroundColor: "inherit",
};

export const deleteicon = {
  margin: "0.24rem 0.5rem",
  color: colors.arrowIcon[2]
};

export const innertable = { paddingBottom: 0, paddingTop: 0 };

const innertablehead = {
  background: others.otherColors[41],
  borderRadius: "0.97rem",
};

const accesslevelbox = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
};

const chips = {
  marginLeft: "0.24rem",
  background: others.otherColors[41],
  borderRadius: "0.97rem",
  width: "4rem",
};
// RoleManagement Footer properties ends

const searchicon = {
  display: "flex",
  color: colors.black[5],
  alignItems: "flexstart",
  justifyContent: "left",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
};

const reset = {
  alignSelf: "flex-end",
  justifyContent: "space-between",
  alignContent: "flex-end",
};

const exporticon = {
  textAlign: "left",
  marginRight: "0.5rem",
  opacity: 1,
};

const edit = {
  color: colors.primary.main,
};

export const GridLayoutHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  height: "3.6rem",
  margin: "0.89rem 1.6rem 0 1.6rem",
}));

export const GridLayoutCollapseHeader = styled(Grid)(() => ({
  margin: "0.89rem 1.6rem 0.89rem 1.6rem ",
}));

export const HeaderItem = {
  backgroundColor: colors.backgroundColor[8],
  borderRadius: "0.8rem",
};

export const IconFaSearch = styled(FaSearch)(() => ({
  display: "flex",
  color: alpha(colors.fonts[4], 0.32),
  justifyContent: "left",
}));

export const AutoCompleteWidth = {
  width: "16rem",
};
export const BackDropStyle = {
  color: colors.white[1],
  position: "absolute",
  opacity: 0.5,
};

export const SummaryContainerBody = () => ({
  padding: "0",
});

export const PatientSearchLabel = () => ({
  padding: "0 0 .75rem 0",
  textAlign: "initial",
});

export const DoneItemIcon = () => ({
  color:colors.fonts[18]
});

export const ModuleTablecell = () => ({
  paddingLeft: "4rem"
});

// global export of all styles
export const styles = {
  rowOne,
  head,
  h_buttons,
  body,
  searchicon,
  reset,
  exporticon,
  tableheading,
  tablecontent,
  edit,
  searchbox,
  bodytable,
  tableheadbg,
  tablerow,
  idbox,
  id,
  toggleiconbox,
  toggleiconcolor,
  actionbox,
  actionicon,
  deleteicon,
  innertable,
  innertablehead,
  accesslevelbox,
  chips,
  DoneItemIcon,
  ModuleTablecell
};

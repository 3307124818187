import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import { SearchFilterModel } from "../../../models/Filter";
import MyPatients from "../components/MyPatientsTab/MyPatientsTab";
import { storeFilterPayload } from "../../../actions/Filter";

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    storeFilterPayload: (type: number, payload?: SearchFilterModel) => {
      dispatch(storeFilterPayload(type, payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(MyPatients);

import { Reducer } from "redux";

import { ReAuthPatientDetailResponse } from "../models/Api/AllPatientRecord";
import { ReAuthPatientDetailActionDispatchTypes } from "../constants/AllPatientRecord";
import { ReAuthPatientDetailDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";

const initialState: ReAuthPatientDetailResponse = {
  loading: false,
  response: {
    referralId: 0,
    patientId: 0,
    blobId: 0,
    memberName: "",
    gender: "",
    dateOfBirth: "",
    subscriberId: 0,
    authId: 0,
    medicareBeneficiaryIdentifier: "",
    languagePreferenceValue: "",
    healthPlan: "",
    benifitPlan: "",
    stateOfInsurance: "",
    product: "",
    datesApplicable: "",
    referralStageId: 0,
    referralStageName: "",
    reAuthId: 0,
    insuranceState: "",
    blobFileName: "",
    patientEligibilityId: 0,
  },
};

const PatientAuthOverviewInfoReducer: Reducer<
  ReAuthPatientDetailResponse,
  ReAuthPatientDetailDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthPatientDetailActionDispatchTypes.RE_AUTH_PATIENT_DETAIL_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthPatientDetailActionDispatchTypes.RE_AUTH_PATIENT_DETAIL_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    case ReAuthPatientDetailActionDispatchTypes.RE_AUTH_PATIENT_DETAIL_RESET: {
      return initialState;
    }
  }
  return state;
};

export default PatientAuthOverviewInfoReducer;

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { postRole, roleDetailsReset } from "../../../../../actions/Roles";
import { RoleDetailsAuth } from "../../../../../models/Roles";
import { getMasterAccess } from "../../../../../actions/Master";
import { MasterAccessResponse } from "../../../../../models/Api/Master";
import CreateRole from "../components/CreateRole";
import { RoleDetailsDispatchTypes } from "../../../../../models/DispatchTypes/Roles";
import { RoleDetailsResponse } from "../../../../../models/Api/Roles";

const mapStateToProps = ({
  masterAccessState,
  roleDetailsState,
}: ApplicationState) => ({
  masterAccessState: masterAccessState as MasterAccessResponse,
  roleDetailsState: roleDetailsState as RoleDetailsResponse,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<RoleDetailsDispatchTypes>
  >
) => {
  return {
    getMasterAccess: () => {
      dispatch(getMasterAccess());
    },
    postRole: (payload: RoleDetailsAuth) => {
      dispatch(postRole(payload));
    },
    roleDetailsReset: () => {
      dispatch(roleDetailsReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../store/ApplicationState";
import { getLogin, putLoggedInUserDetails } from "../../actions/User";
import { LoggedInUserDetailsDispatchTypes } from "../../models/DispatchTypes/User";
import { UserDetailsPayload } from "../../models/User";
import Header from "../components/Header";

const mapStateToProps = ({ loggedInUserState }: ApplicationState) => ({
  user: loggedInUserState.response as UserDetailsPayload,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<LoggedInUserDetailsDispatchTypes>
  >
) => {
  return {
    putLoggedInUserDetails: (userId: string, payload: UserDetailsPayload) => {
      dispatch(putLoggedInUserDetails(userId, payload));
    },
    getLogin: async () => {
      dispatch(await getLogin());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

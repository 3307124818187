import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { colors } from "../../../../../styles/colors";
import {
  ContainerStyle,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  GridTableContent,
  Gridbody,
  HeaderTitle,
  HorizontalDivider,
  TableMain,
  TableMainHead,
  TableMainHeadRow,
  TableNameDetails,
  VerticalDivider,
} from "../styles/ResumeIntake";
import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import { length } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { ResumeIntakeHeaderLabel } from "../../../../../constants/AllPatientRecord";
import { ResumeIntake as ResumeIntakeInformation } from "../../../../../models/Authorization";
import fontWeight from "../../../../../styles/mui/fontWeight";

export interface PropsFromDispatch {
  getResumeIntakeInformation: (referralId: number) => void;
}
export interface PropsFromState {
  resumeIntakeInformation: ResumeIntakeInformation[];
  referralId: string;
}

type AllProps = PropsFromDispatch & PropsFromState;
const ResumeIntake: React.FC<AllProps> = ({
  resumeIntakeInformation,
  getResumeIntakeInformation,
  referralId,
}) => {
  const [showResumeIntake, setShowResumeIntake] = useState(false);
  const [resumeIntakeData, setResumeIntakeData] = useState<
    ResumeIntakeInformation[] | any[]
  >();
  const navigate = useNavigate();

  const handleResumeIntake = (reAuthId: number) => {
    navigate(
      ModuleLinks(PatientRecordsModule.REAUTH_RESUME, {
        reAuthId: reAuthId,
        referralId: referralId,
      })
    );
  };
  useEffect(() => {
    if (referralId) {
      getResumeIntakeInformation(Number(referralId));
    }
  }, [referralId]);

  useEffect(() => {
    setResumeIntakeData(resumeIntakeInformation);
  }, [resumeIntakeInformation]);

  return (
    <Grid container sx={Gridbody}>
      <Grid item xs={12} sx={displayFlex}>
        {showResumeIntake ? (
          <FaChevronCircleDownIcon onClick={() => setShowResumeIntake(false)} />
        ) : !showResumeIntake ? (
          <FaChevronCircleUpIcon onClick={() => setShowResumeIntake(true)} />
        ) : (
          ""
        )}
        <Typography
          variant="h6"
          color={colors.fonts[28]}
          fontWeight={fontWeight.Weight[5]}
          sx={HeaderTitle}
        >
          Resume Intake
        </Typography>
        <Divider orientation="vertical" flexItem sx={VerticalDivider} />
        <Typography
          variant="subtitle2"
          fontWeight={fontWeight.Weight[2]}
          color={colors.black[2]}
          pt={"0.3rem"}
        >
          Below reauthorizations are pending in intake stage, please click on
          resume to continue:
        </Typography>
      </Grid>
      <Divider light sx={HorizontalDivider} />
      {!showResumeIntake && (
        <Grid item xs={12} sx={GridTableContent}>
          <TableContainer sx={ContainerStyle} component={Paper}>
            <TableMain>
              <TableMainHead>
                <TableMainHeadRow>
                  {ResumeIntakeHeaderLabel.map((tableCell: any, index: any) => (
                    <TableCell key={index}>
                      <Box
                        sx={tableCell.style.cell}
                        ml={
                          index === 0
                            ? "4.5rem"
                            : index === 4
                            ? "0.5rem"
                            : "0rem"
                        }
                      >
                        <Box sx={flexAlignCentre}>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[4]}
                            color={colors.fonts[4]}
                          >
                            {tableCell.label}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  ))}
                </TableMainHeadRow>
              </TableMainHead>
              <TableBody>
                {length(resumeIntakeData) &&
                  resumeIntakeData &&
                  resumeIntakeData.map((row: any, rowIndex: number) => {
                    return (
                      <TableRow key={rowIndex}>
                        <TableCell
                          sx={TableNameDetails(
                            rowIndex,
                            resumeIntakeData.length
                          )}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.fonts[18]}
                            ml={"4.5rem"}
                          >
                            {row.serviceRequestId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.black[2]}
                          >
                            {formatDate(row.dateTimeReceived)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.black[2]}
                          >
                            {formatDate(row.lastModified)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.black[2]}
                          >
                            {row.userName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleResumeIntake(row.serviceRequestId)
                            }
                          >
                            RESUME INTAKE
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </TableMain>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default ResumeIntake;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../store/ApplicationState";
import NppesSearchComponent from "../components/NppesSearchComponent";
import { AllPatientTabDispatchTypes } from "../../../../models/DispatchTypes/AllPatientRecord";
import { getNPPEsDetailsData } from "../../../../actions/PhysicianDetails";
import { resetStates } from "../../../../actions/Common";

const mapStateToProps = ({
  NPPESDataState,
}: ApplicationState) => ({
  PhysicianDetailsData: NPPESDataState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    getNPPESDetails: (payload: any) => {
      dispatch(getNPPEsDetailsData(payload));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NppesSearchComponent);

import { Reducer } from "redux";

import { ReferralSubmitResponse } from "../models/Api/AllPatientRecord";
import { ReauthReferralSubmitActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { ReAuthReferralSubmitDispatchTypes } from "../constants/AllPatientRecord";

export const initialState: ReferralSubmitResponse = {
  loading: false,
  response: {
    isCompleted: null,
    referralStage: [],
  },
  error: null,
};

const ReauthReferralIntakeSubmit: Reducer<
  ReferralSubmitResponse,
  ReauthReferralSubmitActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
    case ReAuthReferralSubmitDispatchTypes.RE_AUTH_REFERRAL_SUBMIT_RESET: {
        return initialState
    }
  }
  return state;
};

export default ReauthReferralIntakeSubmit;

import { Component } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import axios from "axios";

import { appInsights } from "../utils/logHandlers/appInsight";
import { ErrorResponse } from "../utils";
import { utcDateToUser, userDate } from "../utils/DateTime";
import { loginRequest, msalInstance } from "..";
import Logger from "../utils/logHandlers/Logger";
import SnackbarUtils from "../utils/SnackbarProvider";

export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST";
export const ERROR_INVALID_SESSION = "sessionInvalidMessage";
export const ERROR_KEY = "REDIRECT_MESSAGE";
export const ERROR_NOT_FOUND = "notFoundMessage";
export const ERROR_PERMISSION_DENIED = "permissionDeniedMessage";
export const ERROR_SESSION_EXPIRED = "sessionExpiredMessage";
export const FORCE_REDIRECT_KEY = "INVALID_SESSION_FORCE_REDIRECT";
export const NOT_FOUND_ERROR_STATUS = 404;
export const REFRESH_TOKEN_EXPIRED_STATUS = 403;
export const UNAUTHORIZED_ERROR_STATUS = 401;

type Methods = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

class API extends Component {
  static call(method: Methods, url: string, type: any, ...rest: any[]) {
    const req = userDate(rest);
    return axios[method](url, ...req)
      .then((response) => {
        if (type && type == "file") {
          const responseData = utcDateToUser(response);
          return responseData;
        } else {
          const responseData = utcDateToUser(response.data);
          return responseData;
        }
      })
      .catch((err) => {
        if (type && type == "file") {
          return err;
        }
        if (err.response.status === 404) {
          return null;
        }
        if (err.response && err.response.status) {
          if (appInsights !== undefined) {
            appInsights.trackException({
              error: err.response.status,
              severityLevel: SeverityLevel.Error,
            });
          }
        }
        const msg = "Something went wrong!";
        SnackbarUtils.warning(msg);
        ///throw err;//TBD
      });
  }

  static callAPI(method: Methods, url: string, ...rest: any[]) {
    const req = userDate(rest);
    return axios[method](url, ...req)
      .then((response) => {
        const responseData = utcDateToUser(response);
        return responseData;
      })
      .catch((err) => {
        if (err.response && err.response.status) {
          if (appInsights !== undefined) {
            appInsights.trackException({
              error: err.response.status,
              severityLevel: SeverityLevel.Error,
            });
          }
        }
        return ErrorResponse(err.response);
      });
  }

  static getBlob(method: Methods, url: string, header: any) {
    return axios({
      url: url,
      method: method,
      headers: header.headers,
      responseType: "blob",
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.response && err.response.status) {
          if (appInsights !== undefined) {
            appInsights.trackException({
              error: err.response.status,
              severityLevel: SeverityLevel.Error,
            });
          }
        }
        return ErrorResponse(err.response);
      });
  }

  //-------------------------------------------
  //For token system
  static userId: number;
  static getRootUrl(url: string) {
    return url.toString();
  }
  static getRefreshTokenUrl(): string {
    if (typeof window !== "undefined") {
      return "";
    }
    return "";
  }

  static setUserId(userId: number) {
    this.userId = userId;
  }
  static getUserId() {
    return this.userId;
  }

  static APIResponse(
    method: Methods,
    url: string,
    data?: any[],
    isPostPut?: boolean,
    response?: any
  ) {
    const header = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
    };
    const fileType = data as any;
    if (fileType && (fileType == "excel" || fileType == "file")) {
      return API.getBlob(method, url, header);
    } else if (isPostPut) {
      return API.call(method, url, "", data, header);
    } else if (method == "get" || method == "delete") {
      return API.call(method, url, data, header);
    } else {
      return API.callAPI(method, url, data, header);
    }
  }
  //-----------------------------------------------
  static APICall(
    method: Methods,
    url: string,
    data?: any[],
    isPostPut?: boolean
  ) {
    const account = msalInstance.getActiveAccount();
    return msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: account as any,
      })
      .then((response) => {
        return API.APIResponse(method, url, data, isPostPut, response);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance
            .acquireTokenPopup({
              ...loginRequest,
              account: account as any,
            })
            .then(function (response) {
              return API.APIResponse(method, url, data, isPostPut, response);
            })
            .catch(function (error) {
              Logger.error(error);
            });
        }
      });
  }

  static getApi(url: string, config?: any) {
    return API.call("get", url, config);
  }

  static get(url: string, type?: any) {
    return API.APICall("get", url, type);
  }

  static post(url: string, data: any) {
    return API.APICall("post", url, data, true);
  }
  static put(url: string, data: any) {
    return API.APICall("put", url, data, true);
  }
  static delete(url: string) {
    return API.APICall("delete", url);
  }
  static putApi(url: string, data: any) {
    return API.APICall("put", url, data);
  }
  static postApi(url: string, data: any) {
    return API.APICall("post", url, data);
  }
}
export default API;

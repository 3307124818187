import { Reducer } from "redux";

import { DeleteResponse } from "../models/Api/Delete";
import { ServiceProviderDetailsDispatchTypes } from "../models/DispatchTypes/Authorization";
import { ServiceProviderDetailsActionDispatchTypes } from "../constants/Authorization";


export const initialState: DeleteResponse = {
    loading: false,
    response: false,
};

const DeleteServiceProviderReducer: Reducer<DeleteResponse, ServiceProviderDetailsDispatchTypes> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_INVOKE: {
            return {
                ...state,
                loading: true,
            };
        }
        case ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_ERROR: {
            return {
                ...state,
                error: action.response,
                loading: false,
            };
        }
        case ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_SUCCESS: {
            return {
                ...state,
                response: action.response,
                loading: false,
            };
        }
        case ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_RESET: {
            return {
                response: initialState.response,
                loading: false,
            };
        }
    }
    return state;
};

export default DeleteServiceProviderReducer;

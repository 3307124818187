import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import { getReAuthReview } from "../../../../../../../actions/Review";
import { ReviewDispatchTypes } from "../../../../../../../models/DispatchTypes/Review";
import ReAuthReviewRequest from "../components/ReAuthReviewRequest";

const mapStateToProps = ({ reAuthReviewState }: ApplicationState) => ({
  reAuthRequestDetails: reAuthReviewState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ReviewDispatchTypes>
  >
) => {
  return {
    getReAuthReviewDetails: (reAuthId: number) => {
      dispatch(getReAuthReview(reAuthId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReAuthReviewRequest);

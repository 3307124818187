import { Reducer } from "redux";

import { LevelOfFunctionRecordsResponse } from "../models/Api/Notes";
import { LevelOfFunctionDispatchTypes } from "../models/DispatchTypes/Notes";
import { LevelOfFunctionActionDispatchTypes } from "../constants/Notes";

export const initialState: LevelOfFunctionRecordsResponse = {
  loading: false,
  response: [
    {
      dateCreated: "",
      noteEntryJson: "",
      documentationType: -1,
      orderNumber: -1,
      disciplineName: "",
    },
  ],
};

const LevelOfFunctionReducer: Reducer<
  LevelOfFunctionRecordsResponse,
  LevelOfFunctionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case LevelOfFunctionActionDispatchTypes.LEVEL_OF_FUNCTION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LevelOfFunctionActionDispatchTypes.LEVEL_OF_FUNCTION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default LevelOfFunctionReducer;

import { Dispatch, ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import { configUrlForUserManagement } from "../constants/Config";
import { GetModules } from "../services/Module";
import { ModuleActionDispatchTypes } from "../constants/Modules";
import { ModulesDispatchTypes } from "../models/DispatchTypes/Modules";

export const getUserModule: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<ModulesDispatchTypes>
  >
> = (userId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ModuleActionDispatchTypes.MODULE_INVOKE,
    });
    const suffix = `${userId}/modules`;
    const response = await GetModules(configUrlForUserManagement, suffix);
    dispatch({
      response,
      type: ModuleActionDispatchTypes.MODULE_SUCCESS,
    });
  };
};

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { PatientInformationDispatchType } from "../../../../../models/DispatchTypes/Patient";
import PatientAuthorizationDetails from "../components/PatientAuthorizationDetails";
import {
  getReauthComplete,
  getReAuthTimeline,
  getResumeIntakeInformation,
  getServiceStatusByType,
  updateDisciplinesData,
  updatePhysicianReviewData,
} from "../../../../../actions/AuthorizationDetails";
import { PatientSelectedAuthDispatchTypes } from "../../../../../models/DispatchTypes/Authorization";
import {
  ReAuthComplete,
  SelectedAuthorization,
} from "../../../../../models/Authorization";
import { getUrgencyStatusMasterResp } from "../../../../../actions/Patient";
import {
  getDisciplineRequestorResp,
  getDisciplineTemplateResp,
  getDisciplineTypeResp,
  getServiceStatusReasonResp,
  getPsychologicalHistory,
  getDocumentType,
  getAuthorizationStatusMasterResp,
  getStaffingStatusMasterResp,
  getCareGiverMasterResp,
  getDischargeReasonMasterResp,
  getHealthProgram,
  getCareCoordinationProgram,
  getPastMedicalHistory,
  getReAuthReferralSourceMasterResp,
  getPriorAuthReferralSourceMasterResp,
} from "../../../../../actions/Master";
import { eligibilityData } from "../../../../../utils/Eligibility";
import { getPatients } from "../../../../../actions/PatientInformation";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { RationaleTemplatePayload } from "../../../../../models/Master";
import {
  ReferralDiscipline,
  UpdatedReferralDiscipline,
} from "../../../../../models/Disciplines";
import { ResumeIntakeResponse } from "../../../../../models/Api/Authorization";
import { PsychologicalHistoryResponse } from "../../../../../models/Api/Master";
import {
  getLevelOfFunction,
  updateSurveyDetails,
} from "../../../../../actions/Notes";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({
  checkEligibilityState,
  disciplineRequestorState,
  disciplineTemplateState,
  disciplineTypeState,
  documentReviewState,
  eligibilityRecordState,
  patientInformationViewState,
  patientSelectedAuthStatusState,
  ReauthCompleteState,
  reAuthTimelineState,
  referralServiceStatusState,
  ResumeIntakeState,
  serviceStatusReasonState,
  updateDisciplineState,
  urgencyStatusState,
  psychologicalHistoryState,
  priorAuthReferralSourceState,
  documentTypeState,
  putAuthorizationDetailTabState,
  reAuthReferralSourceState,
}: ApplicationState) => ({
  disciplineRequestorStatus: disciplineRequestorState,
  disciplineTemplateStatus: disciplineTemplateState,
  disciplineTypeStatus: disciplineTypeState,
  documentReview: documentReviewState,
  eligibilityRecord: eligibilityData(
    eligibilityRecordState.response,
    checkEligibilityState.response
  ),
  patientDetailData:
    patientInformationViewState as PatientInformationViewResponse,
  patientSelectedAuthStatus:
    patientSelectedAuthStatusState.response as SelectedAuthorization,
  reauthCompleteData: ReauthCompleteState.response as ReAuthComplete,
  psychologicalHistoryData:
    psychologicalHistoryState as PsychologicalHistoryResponse,
  reAuthTimeline: reAuthTimelineState,
  referralServiceStatus: referralServiceStatusState,
  resumeIntakeInformation: ResumeIntakeState as ResumeIntakeResponse,
  serviceStatusReasonStatus: serviceStatusReasonState,
  updatedDisciplineResp: updateDisciplineState,
  urgencyStatus: urgencyStatusState,
  priorAuthReferralSource: priorAuthReferralSourceState,
  reAuthReferralSourceFacility: reAuthReferralSourceState,
  documentTypeResponse: documentTypeState,
  putAuthorizationDetailTabData: putAuthorizationDetailTabState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<PatientSelectedAuthDispatchTypes | PatientInformationDispatchType>
  >
) => {
  return {
    getUrgencyMasterResp: () => {
      dispatch(getUrgencyStatusMasterResp());
    },
    getReferralServiceStatusResp: (value: number) => {
      dispatch(getServiceStatusByType(value));
    },
    getDisciplineTypeResp: () => {
      dispatch(getDisciplineTypeResp());
    },
    getServiceStatusReasonResp: (serviceId: number) => {
      dispatch(getServiceStatusReasonResp(serviceId));
    },
    getDisciplineRequestorResp: () => {
      dispatch(getDisciplineRequestorResp());
    },
    updateDisciplinesData: (
      authDisciplineId: number,
      payload: UpdatedReferralDiscipline
    ) => {
      dispatch(updateDisciplinesData(authDisciplineId, payload));
    },
    getUpdateSurveyDetails: (payload: string) => {
      dispatch(updateSurveyDetails(payload));
    },
    updatePhysicianReviewData: (
      authDisciplineId: number,
      payload: ReferralDiscipline
    ) => {
      dispatch(updatePhysicianReviewData(authDisciplineId, payload));
    },
    getDisciplineTemplateResp: (payload: RationaleTemplatePayload) => {
      dispatch(getDisciplineTemplateResp(payload));
    },
    getPatient: (id: number, type: number) => {
      dispatch(getPatients(id, type));
    },
    getResumeIntakeInformation: (referralId: number) => {
      dispatch(getResumeIntakeInformation(referralId));
    },
    getReauthComplete: (referralId: number) => {
      dispatch(getReauthComplete(referralId));
    },
    getReAuthTimelineData: (referralId: string) => {
      dispatch(getReAuthTimeline(referralId));
    },

    getPsychologicalHistory: () => {
      dispatch(getPsychologicalHistory());
    },
    getPriorAuthReferralSourceFacility: () => {
      dispatch(getPriorAuthReferralSourceMasterResp());
    },
    getReAuthReferralSourceFacility: () => {
      dispatch(getReAuthReferralSourceMasterResp());
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getDocumentType: () => {
      dispatch(getDocumentType());
    },
    getUrgencyStatusMasterResp: () => {
      dispatch(getUrgencyStatusMasterResp());
    },
    getAuthorizationStatusMasterResp: () => {
      dispatch(getAuthorizationStatusMasterResp());
    },
    getStaffingStatusMasterResp: () => {
      dispatch(getStaffingStatusMasterResp());
    },
    getCareGiverMasterResp: () => {
      dispatch(getCareGiverMasterResp());
    },
    getDischargeReasonMasterResp: () => {
      dispatch(getDischargeReasonMasterResp());
    },
    getHealthProgram: () => {
      dispatch(getHealthProgram());
    },
    getCareCoordinationProgram: () => {
      dispatch(getCareCoordinationProgram());
    },
    getPastMedicalHistory: () => {
      dispatch(getPastMedicalHistory());
    },
    getLevelOfFunctionRecords: (referralId: number) => {
      dispatch(getLevelOfFunction(referralId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAuthorizationDetails);

import { Reducer } from "redux";

import { CareGiverActionDispatchTypes } from "../constants/Master";
import { CareGiverMasterResponse } from "../models/Api/Master";
import { CareGiverDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: CareGiverMasterResponse = {
  loading: false,
  response: [
    {
      caregiverId: 0,
      caregiver: "",
    },
  ],
};

const CareGiverReducer: Reducer<
  CareGiverMasterResponse,
  CareGiverDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case CareGiverActionDispatchTypes.CARE_GIVER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CareGiverActionDispatchTypes.CARE_GIVER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default CareGiverReducer;

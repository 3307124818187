import { AutocompleteDropDownPlacement } from "../constants/PhysicianDetails";

export interface CreateUpdatePhysicianDetails extends PhysicianDetails {
  dateCreated: string;
  isActive: boolean;
  physicianState: string;
  referralId: number;
  referralPhysicianDetailsId: number;
  streetAddress1: string;
  streetAddress2: string;
  uid: string;
  userCreated: string | null;
}

export interface BasicPhysicianDetails {
  city: string;
  fax?: string;
  phoneNo: string;
  physicianAddress?: string;
  physicianId: number;
  physicianName: string;
  physicianNpiId: string;
  physicianState?: string;
  physicianTypeId?: number;
  streetName1?: string;
  streetName2?: string;
  taxId: string;
  zipCode: string;
}

export interface PhysicianDetails extends BasicPhysicianDetails {
  address?: string;
  county?: string | null;
  faxId?: string;
  phoneExt?: string;
  physicianUid?: string;
  orderingPhysicianUid?: string;
  state?: string;
  streetName?: string;
}

export interface ReferralIntakePhysicianDetails {
  primaryPhysician: PrimaryPhysicianDetails;
  orderingPhysician: OrderingPhysicianDetails;
}

export interface PrimaryPhysicianDetails extends PhysicianDetails {
  county: string | null;
  fax?: string;
  phoneExt: string;
  physicianAddress?: string;
  physicianState: string;
  physicianType?: number;
  physicianUid?: string;
  reAuthId?: number;
  reAuthPhysicianId?: number;
  referralId: number;
  referralPhysicianDetailId: number;
  userCreated: string | null;
}

export interface OrderingPhysicianDetails extends PhysicianDetails {
  county: string | null;
  fax?: string;
  phoneExt: string;
  physicianState: string;
  physicianType?: number;
  reAuthId?: number;
  referralId: number;
  referralPhysicianDetailId?: number;
  state: string;
  orderingPhysicianUid?: string;
  physicianSameAsPrimary: boolean;
}

export interface FollowingPhysicianDetails {
  city: string;
  county: string;
  fax: string;
  followingPhysicianId: number;
  phoneExt: string;
  phoneNo: string;
  physicianAddress?: string;
  physicianId: number;
  physicianName: string;
  physicianNpiId: string;
  physicianState: string;
  physicianUid: string;
  referralId: number;
  serviceRequestId: number;
  streetName1?: string;
  streetName2?: string;
  taxId: string;
  userCreated: string | null;
  userUpdated: string;
  zipCode: string;
  physicianSameAsPrimary: false;
  physicianSameAsOrdering: false;
}

export interface ReAuthPrimaryPhysicianDetails extends PrimaryPhysicianDetails {
  serviceRequestId: number;
}

export interface ReAuthOrderingPhysicianDetails
  extends OrderingPhysicianDetails {
  serviceRequestId: number;
}

export interface ReAuthFollowingPhysicianDetails
  extends FollowingPhysicianDetails {}

export const formatAddress = (
  streetName1: string | undefined | null,
  city: string | undefined | null,
  state: string | undefined | null,
  zipcode?: string | undefined | null,
  streetName2?: string | undefined | null,
  county?: string | undefined | null
) => {
  const address = [streetName1, streetName2, county, city, state, zipcode];
  const newAddress = address.filter((item) => item);
  return newAddress.toString();
};

export const FollowingPhysicianDetailsForm: FollowingPhysicianDetails = {
  city: "",
  county: "",
  fax: "",
  followingPhysicianId: 0,
  phoneExt: "",
  phoneNo: "",
  physicianAddress: "",
  physicianId: 0,
  physicianName: "",
  physicianNpiId: "",
  physicianState: "",
  physicianUid: "",
  referralId: 0,
  serviceRequestId: 0,
  streetName1: "",
  streetName2: "",
  taxId: "",
  userCreated: null,
  userUpdated: "",
  zipCode: "",
  physicianSameAsPrimary: false,
  physicianSameAsOrdering: false,
};

export interface AuthPhysicianDetails extends BasicPhysicianDetails {
  followingPhysicianId?: number;
  physicianUid?: string;
  referralId: number;
  referralPhysicianDetailId: number;
  serviceRequestId?: number;
  streetName1: string;
  userCreated: string | null;
  userUpdated: string;
  phoneExt?: string;
  county?: string | null;
}

export interface NppesPayloadData {
  firstName: string;
  lastName: string;
  state: string;
}

export interface NppesStateList {
  stateId: string;
  stateName: string;
}

export interface AutoCompleteDropDownPositionType {
  placement?: AutocompleteDropDownPlacement;
}

export interface AutocompleteDropDownPaddingType {
  offset?: [number, number];
}

import { Reducer } from "redux";

import { HealthProgramResponse } from "../models/Api/Master";
import { HealthProgramDispatchTypes } from "../models/DispatchTypes/Master";
import { HealthProgramActionDispatchTypes } from "../constants/Master";

export const initialState: HealthProgramResponse = {
  loading: false,
  response: [],
};

const HealthProgramReducer: Reducer<
  HealthProgramResponse,
  HealthProgramDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case HealthProgramActionDispatchTypes.HEALTH_PROGRAM_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case HealthProgramActionDispatchTypes.HEALTH_PROGRAM_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default HealthProgramReducer;

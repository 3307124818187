import { styled } from "@mui/material";
import { FaSearch } from "react-icons/fa";

export const PatientSearchLabel = {
  padding: "0 0 .3rem 0",
  textAlign: "initial",
};
export const AutoComplete = {
  minWidth: "10rem",
  maxWidth: "30rem",
};
export const AutocompleteContainer = {
  margin: ".5rem",
  marginLeft: "1.5rem",
};
export const SearchButtonBox = {
  display: "flex",
  paddingTop: "1.5rem"
};
export const Search = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
export const FaSearchIcon = styled(FaSearch)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const SearchButton = () => ({
  marginLeft: "0.5rem",
  width: "7.8rem",
});

import { connect } from "react-redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import Icd10 from "../components/ReviewIcd10";

const mapStateToProps = ({ reviewState }: ApplicationState) => ({
  icd10code: reviewState,
});

export default connect(mapStateToProps)(Icd10);

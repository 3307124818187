import { Reducer } from "redux";

import { MyPatientActionDispatchTypes } from "../constants/AllPatientRecord";
import { MyPatientResponse } from "../models/Api/AllPatientRecord";
import { MyPatientDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";

const initialState: MyPatientResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [],
  },
};
const MyPatientListReducer: Reducer<
  MyPatientResponse,
  MyPatientDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case MyPatientActionDispatchTypes.POST_PATIENTLIST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MyPatientActionDispatchTypes.POST_PATIENTLIST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case MyPatientActionDispatchTypes.POST_PATIENTLIST_RESET: {
      return {
        response: initialState.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MyPatientListReducer;

import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography, Button, Avatar } from "@mui/material";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material";
import { isNil } from "lodash";

import {
  styles,
  TitleBox,
  SwitchTitle,
  GridLayoutHeader,
  GridLayoutContainer,
  HeaderGrid,
  AvatarIcon,
  SwitchBox,
  GridPadding,
} from "../styles/userProfile";
import { Head, Body, Main } from "../../../../../components/custom/structure";
import { Checkbox } from "../../../../../components/custom/checkbox";
import { Switch } from "../../../../../components/custom/switch";
import { UserDetailsPayload, Roles } from "../../../../../models/User";
import { GetRoleResponse } from "../../../../../models/Api/AllRoles";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  flexAlignCentre,
  justifyContentLeft,
} from "../../../../../styles/mui/styles/display";
import {
  FaPhoneInputIcon,
  FaRegEnvelopeIcon,
} from "../../../../../components/formComponents/styles/style";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import {
  defaultFemaleImage,
  defaultMaleImage,
} from "../../../../../constants/ImagePaths";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import Permission from "../../../../Permission";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import { USER_MANAGEMENT_DESCRIPTION } from "../../../../../constants/User";

export interface PropsDispatch {
  getUserDetails: (id: number) => void;
  postUserDetails: (userId: string, form: UserDetailsPayload) => void;
  getAllRoles: () => void;
  userDetailsReset: () => void;
}
export interface PropsState {
  userDetails: any;
  roles: GetRoleResponse;
}
type UserDetailsProps = PropsState & PropsDispatch;

interface UserDetailsAndAllRoles {
  roles: Roles[];
  userId: number;
  fullName: string;
  userPhoto: string;
  userName: string;
  email: string;
  userStatus: string;
  lastLogin: string;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  userDetails,
  roles,
  getUserDetails,
  postUserDetails,
  getAllRoles,
  userDetailsReset,
}: UserDetailsProps) => {
  const { userid } = useParams();
  const { response, loading, error } = userDetails;

  const [userDetailsAndRoles, setUserDetailsAndRoles] =
    useState<UserDetailsAndAllRoles>({
      ...response,
      roles: roles.response,
    });
  const [userDetailsState, setUserDetailsState] =
    useState<UserDetailsPayload>(response);

  const [apiError, setApiError] = useState<boolean>(false);
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false);

  useEffect(() => {
    if (!isNil(error)) {
      if (error.status === 400 || error.status === 422) setApiError(true);
    } else {
      setApiError(false);
      if (saveAndExit && !isNil(response)) {
        userDetailsReset();
        navigate(ModuleLinks(PatientRecordsModule.USER_MANAGEMENT));
        setSaveAndExit(false);
      }
    }
  }, [error, response]);

  useEffect(() => {
    if (userid) {
      getUserDetails(parseInt(userid)); //TBD
      getAllRoles();
    }
  }, [userid]);

  useEffect(() => {
    if (
      !loading &&
      !roles.loading &&
      response &&
      response.userId !== -1 &&
      roles.response[0].roleId !== -1
    ) {
      setUserDetailsAndRoles({
        ...response,
        roles: roles.response,
      });
      const formPayload = {
        ...response,
        userPhotoBlobId: 0,
        userCreated: response && response.email ? response.email : "",
        dateCreated: formatDate(new Date().toString(), DateFormats.YYYY_MM_DD),
      };
      setForm(formPayload);
      setUserDetailsState(response);
    }
  }, [loading, roles.loading]);

  const formInitialState = {
    userId: 1,
    userPhoto: "",
    firstName: "",
    lastName: "",
    fullName: "",
    userName: "",
    email: "",
    userPhotoBlobId: 0,
    designation: "",
    gender: "",
    phoneNo: "",
    userAddress: "",
    lastLogin: "",
    userStatus: "",
    isSuspended: false,
    dateCreated: "",
    userCreated: null,
    userRoles: [],
  };

  const [form, setForm] = useState<UserDetailsPayload>(formInitialState);
  const [phoneNo, setPhoneNo] = useState<any>(form.phoneNo);
  const [extension, setExtension] = useState<any>("");

  const methods = useForm<UserDetailsPayload>({
    defaultValues: userDetails.response,
  });
  const { handleSubmit, control, reset } = methods;

  const onSubmit = (data: UserDetailsPayload) => {
    data.userId = form.userId;
    data.userPhoto = form.userPhoto;
    data.fullName = form.fullName;
    data.userName = form.userName;
    data.userPhotoBlobId = form.userPhotoBlobId;
    data.designation = form.designation;
    data.gender = form.gender;
    data.userAddress = form.userAddress;
    data.lastLogin = form.lastLogin;
    data.userStatus = form.isSuspended ? "Suspended" : "Active";
    data.isSuspended = form.isSuspended;
    data.userRoles = form.userRoles;
    data.userPhotoBlobId = 1;
    data.dateCreated = formatDate(
      new Date().toString(),
      DateFormats.YYYY_MM_DD
    );
    data.phoneNo = phoneNo;
    if (userid) {
      postUserDetails(data.userId.toString(), data);
    }
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(ModuleLinks(PatientRecordsModule.USER_MANAGEMENT));
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
    setSaveAndExit(false);
  };

  const handleSaveAndExit = () => {
    handleSubmit(onSubmit)();
    setSaveAndExit(true);
  };

  const handleCancel = () => {
    navigate(ModuleLinks(PatientRecordsModule.USER_MANAGEMENT));
  };

  return (
    <>
      <Main>
        <Head
          title={"USER DETAILS"}
          description={USER_MANAGEMENT_DESCRIPTION}
          back
          handleBack={handleBack}
          dataTestId={"userProfile-back"}
        >
          <Button
            data-testid={"userProfile-cancel"}
            onClick={handleCancel}
            variant="text"
          >
            {" "}
            CANCEL
          </Button>
          <Permission
            controlId={`||${AdminCenterAccess.ADMIN_CENTER_USER_MANAGEMENT_UPDATE}`}
          >
            <Button
              data-testid={"userProfile-saveAndExit"}
              onClick={handleSaveAndExit}
              variant="contained"
            >
              {" "}
              SAVE AND EXIT
            </Button>
            <Button
              data-testid={"userProfile-save"}
              onClick={handleSave}
              variant="contained"
            >
              {" "}
              SAVE
            </Button>
          </Permission>
        </Head>
        <Body>
          <UserProfileBody
            form={form}
            setForm={setForm}
            setPhoneNo={setPhoneNo}
            setExtension={setExtension}
            userDetailsAndRoles={userDetailsAndRoles}
            userDetails={userDetailsState}
            control={control}
            reset={reset}
            phoneNo={phoneNo}
            extension={extension}
            apiError={apiError}
          />
        </Body>
        <UserProfileFooter
          handleCancel={handleCancel}
          handleSaveAndExit={handleSaveAndExit}
          handleSave={handleSave}
        />
      </Main>
    </>
  );
};

type UserDetailsBodyProps = {
  form: UserDetailsPayload;
  setForm: (form: UserDetailsPayload) => void;
  userDetailsAndRoles: UserDetailsAndAllRoles;
  userDetails: UserDetailsPayload;
  control: any;
  reset: any;
  setPhoneNo: (phoneNo: string) => void;
  setExtension: (extension: string) => void;
  phoneNo: string;
  extension: string;
  apiError: boolean;
};

const UserProfileBody = (props: UserDetailsBodyProps) => {
  const {
    form,
    setForm,
    setPhoneNo,
    userDetailsAndRoles,
    userDetails,
    reset,
    phoneNo,
    extension,
    apiError,
  } = props;

  useEffect(() => {
    if (userDetails) {
      const payload = {
        ...userDetails,
        userPhotoBlobId: 0,
        userCreated: userDetails.email,
        dateCreated: formatDate(new Date().toString()),
      };

      reset(payload);
      setForm(payload);
      setPhoneNo(form.phoneNo);
    }
  }, [userDetails]);
  const handleSuspend = (event: ChangeEvent<HTMLInputElement>) => {
    const status = event.target.checked;
    const checked = status;
    setForm({ ...form, isSuspended: checked });
  };

  const checkedLabels = (userRoles: Roles[]) => {
    const rolesLabels =
      userRoles && userRoles.map((role: Roles) => role.roleId);
    return rolesLabels;
  };

  const checkBoxLabels = (roles: Roles[]) => {
    const rolesLabels = roles.map((role: Roles) => ({
      label: role.roleName,
      value: role.roleId,
    }));
    return rolesLabels;
  };

  const handleAssignRole = (event: ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.value);
    const role: Roles | undefined = form.userRoles.find(
      (role: Roles) => role.roleId === id
    );
    if (role) {
      const roles = form.userRoles.filter((role: Roles) => role.roleId !== id);
      setForm({ ...form, userRoles: roles });
    } else {
      const newRole = userDetailsAndRoles.roles.find(
        (role: Roles) => role.roleId === id
      );
      if (newRole)
        setForm({ ...form, userRoles: [...form.userRoles, newRole] });
    }
  };

  const headerStyle = (
    marginBottom: number,
    title: string,
    isDescriptionAvailable?: boolean,
    description?: string
  ) => {
    return (
      <>
        <TitleBox mb={marginBottom}>
          <Typography
            variant="subtitle1"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
          >
            {title}:
          </Typography>
          {isDescriptionAvailable && (
            <Typography
              mt={1}
              variant="subtitle2"
              color={alpha(colors.black[5], 0.8)}
              fontWeight={fontWeight.Weight[3]}
            >
              {description}
            </Typography>
          )}
        </TitleBox>
      </>
    );
  };
  const subTitleStyle = (
    subTitle: string,
    isValuePresent?: boolean,
    value?: string
  ) => {
    return (
      <>
        <SwitchTitle display="flex">
          <Typography
            variant="subtitle2"
            color={alpha(colors.black[5], 0.8)}
            fontWeight={fontWeight.Weight[4]}
          >
            {subTitle}:
          </Typography>
          {isValuePresent && (
            <Typography
              variant="subtitle1"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[4]}
              ml={"0.97rem"}
            >
              {value}
            </Typography>
          )}
        </SwitchTitle>
      </>
    );
  };

  const getUserName = (userDetails: UserDetailsPayload) => {
    if (!isNil(userDetails)) {
      const { fullName, firstName, lastName } = userDetails;
      if (fullName) return fullName;
      else if (firstName && lastName) return `${firstName} ${lastName}`;
      else if (firstName) return firstName;
      else return lastName;
    }
    return "";
  };

  return (
    <Grid container sx={styles.body}>
      <Grid item xs={12}>
        <Box textAlign={"left"} pl="1.3rem">
          {apiError && (
            <Typography
              mt={1}
              variant="subtitle2"
              color={colors.red[100]}
              fontWeight={fontWeight.Weight[3]}
            >
              Failed to update data
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={7.5}>
        {headerStyle(
          2,
          "USER DETAILS",
          true,
          "Details specific to user profile"
        )}
      </Grid>
      <Grid item xs={2} style={HeaderGrid}>
        <GridLayoutHeader>{subTitleStyle("Suspend User")}</GridLayoutHeader>
        <GridLayoutContainer>
          <Box sx={SwitchBox}>
            <Switch
              label=""
              onChange={handleSuspend}
              dataTestId={"userProfileBody-suspendUser"}
              checked={form.isSuspended}
            />
          </Box>
        </GridLayoutContainer>
      </Grid>
      <Grid item xs={2.2} style={HeaderGrid}>
        <GridLayoutHeader data-testid="userProfileBody-lastLogin">
          {userDetails &&
            subTitleStyle(
              "Last Login",
              true,
              formatDate(userDetails.lastLogin)
            )}
        </GridLayoutHeader>
      </Grid>

      <Grid item xs={12} mt={0} mb={3}>
        <Divider
          orientation="horizontal"
          variant="middle"
          textAlign="center"
          flexItem
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={1.5}>
            <Box
              sx={[flexAlignCentre]}
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Box mt={2}>
                {/* {userDetails && userDetails.userPhotoBlobId && userDetails.userPhotoBlobId != 0 ?   //TBD patient photo
                  <Avatar src={ProfileImage(userDetails.userPhotoBlobId)} sx={AvatarIcon} /> : */}
                {userDetails && userDetails.gender == "Female" ? (
                  <Avatar src={defaultFemaleImage} sx={AvatarIcon} />
                ) : (
                  <Avatar src={defaultMaleImage} sx={AvatarIcon} />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7} pt={"1.8rem"}>
            <Grid container>
              <Grid item xs={4}>
                <Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]}>
                    <Typography
                      variant="subtitle2"
                      color={alpha(colors.black[5], 0.8)}
                      fontWeight={fontWeight.Weight[3]}
                    >
                      Name:
                    </Typography>
                  </Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]} pt="0.9rem">
                    <Typography
                      variant="h5"
                      color={colors.fonts[18]}
                      fontWeight={fontWeight.Weight[6]}
                    >
                      {getUserName(userDetails)}
                    </Typography>
                  </Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]} pt="0.7rem">
                    <Typography
                      variant="subtitle2"
                      color={colors.black[4]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {userDetails && userDetails.designation}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3.5}>
                <Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]}>
                    <Typography
                      variant="subtitle2"
                      color={alpha(colors.black[5], 0.8)}
                      fontWeight={fontWeight.Weight[3]}
                    >
                      Phone Number:
                    </Typography>
                  </Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]} pt="0.9rem">
                    {phoneNo && <FaPhoneInputIcon size={"0.9rem"} />}
                    <Typography
                      variant="subtitle1"
                      color={colors.black[4]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {phoneNo && `${phoneNo} ${extension || ""}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]}>
                    <Typography
                      variant="subtitle2"
                      color={alpha(colors.black[5], 0.8)}
                      fontWeight={fontWeight.Weight[3]}
                    >
                      Email ID:
                    </Typography>
                  </Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]} pt="0.9rem">
                    <FaRegEnvelopeIcon />
                    <Typography
                      variant="subtitle1"
                      color={colors.black[4]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      {userDetails && userDetails.email}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={4} sx={GridPadding}>
        <Divider
          orientation="horizontal"
          variant="middle"
          textAlign="center"
          flexItem
        />
      </Grid>

      <Grid item xs={12} sx={GridPadding}>
        <Box
          p="2rem 0 0 1.3rem"
          pb="0.8rem"
          sx={[flexAlignCentre, justifyContentLeft]}
        >
          <Typography
            variant="subtitle2"
            color={colors.fonts[4]}
            fontWeight={fontWeight.Weight[4]}
          >
            User will have access to below roles:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={GridPadding}>
        {headerStyle(2, "Assign Roles")}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}>
            <Box sx={styles.body_row4.main}>
              <Checkbox
                radioGroup
                label=""
                labels={checkBoxLabels(userDetailsAndRoles.roles)}
                checkedLabels={checkedLabels(form.userRoles)}
                handleChange={handleAssignRole}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const UserProfileFooter = (props: any) => {
  const { handleCancel, handleSaveAndExit, handleSave } = props;
  return (
    <Grid container sx={styles.footer}>
      <Grid item xs={12}>
        <Box sx={styles.footer_row}>
          <Box sx={styles.rowOne}>
            <Button
              data-testid={"userProfileFooter-cancel"}
              onClick={handleCancel}
              variant="text"
            >
              {" "}
              CANCEL
            </Button>
          </Box>
          <Permission
            controlId={`||${AdminCenterAccess.ADMIN_CENTER_USER_MANAGEMENT_UPDATE}`}
          >
            <Box sx={styles.rowOne}>
              <Button
                data-testid={"userProfileFooter-saveAndExit"}
                onClick={handleSaveAndExit}
                variant="contained"
              >
                {" "}
                SAVE AND EXIT
              </Button>
            </Box>
            <Box sx={styles.rowOne}>
              <Button
                data-testid={"userProfileFooter-save"}
                onClick={handleSave}
                variant="contained"
              >
                {" "}
                SAVE
              </Button>
            </Box>
          </Permission>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserDetails;

import { Reducer } from "redux";

import { GetNotesResponse } from "../models/Api/Notes";
import { GetNotesDispatchTypes } from "../models/DispatchTypes/Notes";
import { GetAdminNotesByVersionIdActionDispatchTypes } from "../constants/Notes";

export const initialState: GetNotesResponse = {
  loading: false,
  response: {
    templateVersionId: -1,
    noteTemplateId: -1,
    version: -1,
    templateJson: "",
    approvalStatusId: -1,
    approvalStatusName: "",
    templateCategoryId: null,
    templateCategoryName: "",
    templateName: "",
    dateCreated: "",
    userCreated: "",
  },
};

const GetNotesReducer: Reducer<GetNotesResponse, GetNotesDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default GetNotesReducer;

import { Reducer } from "redux";

import { MasterAccessResponse } from "../models/Api/Master";
import { MasterAccessDispatchTypes } from "../models/DispatchTypes/Master";
import { MasterAccessActionDispatchTypes } from "../constants/Master";

export const initialState: MasterAccessResponse = {
  loading: false,
  response: [
    {
      moduleId: -1,
      moduleName: "",
      subModule: [
        {
          moduleId: -1,
          subModuleId: -1,
          subModuleName: "",
          access: [
            {
              moduleId: -1,
              subModuleId: -1,
              accessId: -1,
              accessName: "",
            },
          ],
        },
      ],
    },
  ],
};

const MasterAccessReducer: Reducer<
  MasterAccessResponse,
  MasterAccessDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case MasterAccessActionDispatchTypes.MASTER_ACCESS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MasterAccessActionDispatchTypes.MASTER_ACCESS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterAccessReducer;

import {
  AppInsightsErrorBoundary,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import React, { createContext, useEffect } from "react";
import { isUndefined } from "lodash";

import { AppConfigs } from "../../models/AppConfigs";
import { GetConfigFileName, getValue } from "../../utils";
import { loginRequest } from "../..";
import { reactPlugin } from "../../utils/logHandlers/appInsight";
import CentralLoader from "./CentralLoader";
import ErrorPage from "../../components/errorPages/404"; //TBD
import Header from "../containers/Header";
import RoutesList from "../../routes";
import { UserModuleResponse } from "../../models/Api/Modules";
import UnAuthorizedPage from "../../components/errorPages/401";
import { RouteUrlInfo, UserPermission } from "../../models/User";

export interface PropsFromState {
  config: AppConfigs;
  moduleData: UserModuleResponse;
  userPermission: UserPermission[];
  routeUrlInfo: RouteUrlInfo;
  permissionState: string[];
}

export interface PropsFromDispatch {
  getConfig: (configFileName: string) => void;
  saveRouteUrlInfo: (routeUrlInfo: RouteUrlInfo) => void;
  getAccessControlId: (controlIds: string[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

export const PermissionContext = createContext<string[]>([]);

const App: React.FC<AllProps> = ({
  config,
  getConfig,
  moduleData,
  userPermission,
  saveRouteUrlInfo,
  routeUrlInfo,
  getAccessControlId,
  permissionState,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    const configFileName: string = GetConfigFileName();
    getConfig(configFileName);
  }, []);

  useEffect(() => {
    if (getValue(routeUrlInfo, "moduleId") >= 1) {
      const moduleId = routeUrlInfo.moduleId;
      const subModuleId = isUndefined(routeUrlInfo.subModuleId)
        ? null
        : routeUrlInfo.subModuleId;
      const userAccess = userPermission.filter(
        (permission: UserPermission) =>
          permission.moduleId == moduleId &&
          permission.subModuleId == subModuleId
      );
      const accessControlId = userAccess.map(
        (permission: UserPermission) => permission.htmlControlId || ""
      );
      getAccessControlId(accessControlId);
    }
  }, [routeUrlInfo, userPermission]);

  return (
    <div>
      <AuthenticatedTemplate>
        {config.REACT_APP_CLIENT_ID ? (
          <React.Fragment>
            <AppInsightsErrorBoundary
              onError={() => ErrorPage()}
              appInsights={reactPlugin}
            >
              <BrowserRouter>
                <PermissionContext.Provider value={permissionState}>
                  <Header />
                  {moduleData.response !== null ? (
                    <RoutesList
                      moduleData={moduleData}
                      saveRouteUrlInfo={saveRouteUrlInfo}
                    />
                  ) : (
                    <UnAuthorizedPage></UnAuthorizedPage>
                  )}
                  <CentralLoader />
                </PermissionContext.Provider>
              </BrowserRouter>
            </AppInsightsErrorBoundary>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default withAITracking(reactPlugin, App);

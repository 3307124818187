import { RoleDetailsStateType } from "../models/Roles";

export enum RoleDetailsActionDispatchTypes {
  POST_ROLE_ERROR = "POST_ROLE_ERROR",
  POST_ROLE_INVOKE = "POST_ROLE_INVOKE",
  POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS",
  ROLE_DETAILS_INVOKE = "ROLE_DETAILS_INVOKE",
  ROLE_DETAILS_RESET = "ROLE_DETAILS_RESET",
  ROLE_DETAILS_SUCCESS = "ROLE_DETAILS_SUCCESS",
  UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR",
  UPDATE_ROLE_INVOKE = "UPDATE_ROLE_INVOKE",
  UPDATE_ROLE_RESET = "UPDATE_ROLE_RESET",
  UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",
}

export enum RoleManagementActionDispatchTypes {
  DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR",
  DELETE_ROLE_INVOKE = "DELETE_ROLE_INVOKE",
  DELETE_ROLE_RESET = "DELETE_ROLE_RESET",
  DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS",
  ROLE_MANAGEMENT_INVOKE = "ROLE_MANAGEMENT_INVOKE",
  ROLE_MANAGEMENT_SUCCESS = "ROLE_MANAGEMENT_SUCCESS",
}

export const roleDetailsInitialState: RoleDetailsStateType = {
  roleName: "",
  description: "",
};

export enum RoleDetailsApiFailed {
  ADD_ROLE_FAILED = "Failed to add New Role",
  DUPLICATE_ROLE = "Role name already exists.",
  UPDATE_FAILED = "Failed to update role details",
}

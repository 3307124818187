import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  ContentGrid,
  DateBox,
  DividerStyle,
  DocSubTitle,
  DocumentBox,
  FormLabelSpace,
  HeaderBox,
  Question,
  SupportingDocsOption,
} from "../styles/Document";
import { colors, others } from "../../../../../styles/colors";
import { DocumentType } from "../../../../../models/Master";
import { length } from "../../../../../utils";
import { NonCoveredCheckbox } from "../styles/requestStyle";
import { ReferralDocument } from "../../../../../models/Document";
import { ReviewDocumentationDetailQuestions } from "../../../../../constants/Review";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import fontWeight from "../../../../../styles/mui/fontWeight";

export interface PropsFromState {
  control: any;
  documentType: DocumentType[];
  setValue: (id: any, value: any, boolean: any) => void;
  values: ReferralDocument;
}
type AllProps = PropsFromState;

const Documents: React.FC<AllProps> = ({
  control,
  documentType,
  setValue,
  values,
}: AllProps) => {
  const [supportingDocumentTypeList, setSupportingDocumentTypeList] =
    useState<DocumentType[]>();
  const [hasSupportingDoc, setHasSupportingDoc] = useState<boolean | null>(
    null
  );
  const [supportingDocList, setsupportingDocList] = useState<any[]>([]);

  useEffect(() => {
    if (documentType) setSupportingDocumentTypeList(documentType);
  }, [documentType]);

  useEffect(() => {
    setHasSupportingDoc(
      values && values.containsSupportedDoc
        ? true
        : values.containsSupportedDoc == null
        ? null
        : false
    );
    if (values && values.documentId && length(values.documentId)) {
      setsupportingDocList(
        values && values.documentId && values.documentId.length > 0
          ? values.documentId.map((el) => el.trim())
          : []
      );
    }
  }, [values]);

  const handleSupportingDocChanges = (e: any) => {
    const isSupportingDoc = e.target.value == "true" ? true : false;
    setValue("containsSupportedDoc", isSupportingDoc, true);
    if (!isSupportingDoc) {
      setsupportingDocList([]);
      setValue("documentationDate", null, true);
      setValue("documentId", [], true);
    }
    setHasSupportingDoc(isSupportingDoc);
  };

  const handleSupportingDocumentType = (e: any) => {
    const supportingDoc =
      supportingDocList && length(supportingDocList) ? supportingDocList : [];

    const docsUnderIds =
      supportingDoc &&
      length(supportingDoc) &&
      supportingDoc.map((disc) => disc);

    if (docsUnderIds && docsUnderIds.includes(e.target.value)) {
      const newDocs =
        supportingDoc && length(supportingDoc)
          ? supportingDoc.filter((item) => {
              return item != e.target.value;
            })
          : [];
      setsupportingDocList(newDocs);
    } else {
      const doc = e.target.value;
      const newArrWithAddedDiscipline = [...supportingDoc, doc];
      setsupportingDocList(newArrWithAddedDiscipline);
    }
  };

  useEffect(() => {
    setValue("documentId", supportingDocList, true);
  }, [supportingDocList]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={HeaderBox}>
          <Box>
            <Typography
              variant="h6"
              fontWeight={fontWeight.Weight[5]}
              color={colors.black[3]}
            >
              ADD REFERRAL DOCUMENTATION
            </Typography>
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.black[5]}
              sx={DocSubTitle}
            >
              Please choose the options specific to the documents needed for the
              referral.
            </Typography>
          </Box>
          <Divider sx={DividerStyle} />
        </Grid>
        <Grid item xs={12} sx={ContentGrid}>
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[4]}
              sx={Question}
            >
              {ReviewDocumentationDetailQuestions.SUPPORTING_DOCUMENTS}
            </Typography>
            <Box sx={SupportingDocsOption}>
              <RadioGroup
                row
                value={hasSupportingDoc}
                onChange={handleSupportingDocChanges}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  sx={FormLabelSpace}
                  label={
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[4]}
                    >
                      YES
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={
                    <Typography
                      variant="subtitle1"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      NO
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
            <Box>
              {hasSupportingDoc ? (
                <>
                  <Box sx={DocumentBox}>
                    <Grid container>
                      <FormGroup
                        onChange={handleSupportingDocumentType}
                        sx={NonCoveredCheckbox}
                      >
                        {supportingDocumentTypeList &&
                          length(supportingDocumentTypeList) &&
                          supportingDocumentTypeList.map(
                            (supportingDocumentType: DocumentType) => {
                              return (
                                <FormControlLabel
                                  key={supportingDocumentType.documentId}
                                  control={
                                    <Checkbox
                                      value={supportingDocumentType.documentId}
                                      size="small"
                                      checked={
                                        supportingDocList &&
                                        supportingDocList.includes(
                                          supportingDocumentType.documentId.toString()
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="subtitle1"
                                      color={others.otherColors[63]}
                                      fontWeight={fontWeight.Weight[5]}
                                      mr={"3rem"}
                                    >
                                      {supportingDocumentType.documentName}
                                    </Typography>
                                  }
                                />
                              );
                            }
                          )}
                      </FormGroup>
                    </Grid>
                  </Box>
                  <Box sx={DateBox}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[4]}
                    >
                      Date of most recent documentation:
                    </Typography>
                    <Box>
                      <DatePicker
                        name="documentationDate"
                        control={control}
                        label=""
                        allowFutureDate={false}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};

export default Documents;

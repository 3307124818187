import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import { documentDownload } from "../../../../../../../actions/AllPatientRecord";
import { ReviewDispatchTypes } from "../../../../../../../models/DispatchTypes/Review";
import ReAuthReviewDocumentationDetails from "../components/ReAuthReviewDocumentationDetails";

const mapStateToProps = ({
  reAuthReviewState,
  documentDownloadState,
}: ApplicationState) => ({
  reAuthReviewDetails: reAuthReviewState,
  documentDownloadData: documentDownloadState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ReviewDispatchTypes>
  >
) => {
  return {
    documentDownload: (blobId: number) => {
      dispatch(documentDownload(blobId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReAuthReviewDocumentationDetails);

import colors from "../../../../../../../styles/colors/colors";

export const Icd10CodeOutterGrid = () => ({
  backgroundColor: colors.backgroundColor[2],
});

export const Icd10CodeLeftColumnGrid = () => ({
  padding: "1rem 2.2rem 0 0",
});

export const Icd10CodeLeftColumnHeadingGrid = () => ({
  padding: "0.1rem 0 0.6rem 1.5rem",
});

export const DividerStyle = () => ({
  padding: "0.6rem 0",
});

export const Icd10CodeLeftColumnTableGrid = () => ({
  padding: "1rem 0 0 2.8rem",
});

export const Icd10CodeLeftColumnTableHead = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0rem 1.9rem 1.5rem ",
    borderBottom: `2px solid ${colors.borders[1]}`,
  },
});

export const Icd10CodeLeftColumnTableHeadCell = () => ({
  paddingLeft: "0.5rem",
  margin: "0.5rem 0",
});

export const Icd10CodeRightColumnGrid = () => ({
  marginTop: "0.2rem",
  padding: " 0 1.2rem 0 3rem",
  borderLeft: `1px solid ${colors.borders[1]}`,
});

export const Icd10CodeRightPrimaryPhysicianGrid = () => ({
  borderBottom: `1px solid ${colors.borders[1]}`,
  paddingBottom: "1.2rem",
});

export const Icd10CodeRightPrimaryPhysicianHeadingGrid = () => ({
  display: "flex",
  alignItems: "center",
  padding: "0.5rem 0 1.7rem 0.4rem",
});

export const Icd10CodeRightOrderingPhysicianHeadingGrid = () => ({
  display: "flex",
  alignItems: "center",
  padding: "2rem 0 1.7rem 0.4rem ",
});

export const Icd10CodeServiceProviderHeadingGrid = () => ({
  display: "flex",
  alignItems: "center",
  padding: "1.8rem 0rem 0rem 2.8rem ",
  borderTop: `1px solid ${colors.borders[1]}`,
});

export const Icd10CodeServiceProviderFeildsGrid = () => ({
  display: "flex",
  padding: "0rem 0 2.3rem 5rem",
});

export const Icd10CodeLeftColumnTablebody = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0.5rem 2.1rem 0.5rem ",
  },
});

export const Icd10CodeRightOrderingPhysicianGrid = () => ({
  paddingBottom: "1.2rem",
});

export const Icd10CodeFeildHeading = () => ({
  paddingLeft: "0.5rem",
});

export const Icd10CodeTableBody = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0.5rem 2.5rem 1.5rem",
  },
});

export const Icd10CodeMdIcon = () => ({
  paddingRight: "1rem",
});

export const Icd10CodeSVDgrid = () => ({
  margin: "0 1.9rem",
});

import { Reducer } from "redux";

import { GenerateAuthTemplateResponse } from "../models/Api/Document";
import { GenerateAuthTemplateActionDispatchTypes } from "../models/DispatchTypes/Document";
import { GenerateAuthTemplateDispatchTypes } from "../constants/Document";

const initialState: GenerateAuthTemplateResponse = {
  loading: false,
  response: [
    {
      referralBlobId: 0,
      blobId: 0,
      dateTimeReceived: "",
      documentSourceName: "",
      documentSourceId: 0,
      documentId: 0,
      createdBy: "",
      originalDocumentName: "",
      documentTypeName: "",
      mediaType: "",
      userFullName: "",
      documentCategoryId: 0,
      documentCategoryName: "",
      sendFax: false,
      faxStatus: "",
      blobContentLength: -1,
      showDateTime: true,
      isLetterDocument: null,
      sequenceNo: 0,
    },
  ],
};

const GenerateAuthTemplateReducer: Reducer<
  GenerateAuthTemplateResponse,
  GenerateAuthTemplateActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GenerateAuthTemplateDispatchTypes.GENERATE_AUTH_TEMPLATE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GenerateAuthTemplateDispatchTypes.GENERATE_AUTH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default GenerateAuthTemplateReducer;

import { Grid, GridSize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FaPhone } from "react-icons/fa";
import { Control } from "react-hook-form";

import { displayFlex } from "../../../../../../styles/mui/styles/display";
import colors from "../../../../../../styles/colors/colors";
import fontWeight from "../../../../../../styles/mui/fontWeight";
import {
  FaMobileAltIcon,
  ItemIconBox,
  LowerContainer,
  LowerContainerItem,
  MemberInfoContainer,
  MemberInfoFeild,
  MemberInfoFormFeild,
  MemberInfoFormFeildDropdown,
  MemberInfoSave,
  PatientDetailsValue,
  patientName,
} from "../style";
import {
  getFormattedName,
  getFormattedPhoneNo,
  getPhoneNoAndExtension,
  getValue,
} from "../../../../../../utils";
import { DateFormats } from "../../../../../../constants/Date";
import { FormInputText } from "../../../../../../components/formComponents/FormInputText";
import { PhoneFaxExtInput } from "../../../../../../components/formComponents/PhoneFaxExtInput";
import { FormInputDropdown } from "../../../../../../components/formComponents/FormInputDropdown";
import { PatientInformationView } from "../../../../../../models/Patient";
import { DropdownOption } from "../../../../../../components/formComponents/constant/FormComponents";
import { SaveButton } from ".";
import { formatDate } from "../../../../../../utils/DateTime";
import { rules } from "../../../../../../utils/validation/Validation";
import { GENDER_DROPDOWN_OPTIONS } from "../../../../../../constants/Constants";

export interface PropsFromState {
  control: Control<any, any>;
  handleSave: any;
  patientData: PatientInformationView;
  langaugeMasterList: DropdownOption[];
  accomodationMasterList: DropdownOption[];
}
type AllProps = PropsFromState;

export const PatientNameGrid = (
  patientData: PatientInformationView,
  name: string
) => {
  return (
    <Grid item xs={1} sx={patientName}>
      <Typography
        variant="h2"
        fontWeight={fontWeight.Weight[7]}
        lineHeight={"1.8rem"}
        sx={{ paddingTop: "0.6rem" }}
        color={colors.fonts[18]}
      >
        {getFormattedName(getValue(patientData, name))}
      </Typography>
    </Grid>
  );
};

const MemberInfo: React.FC<AllProps> = ({
  control,
  handleSave,
  patientData,
  langaugeMasterList,
  accomodationMasterList,
}: AllProps) => {
  const fieldRender = (
    label: string,
    xs: GridSize,
    value: string | number | null,
    icon?: any
  ) => {
    return (
      <Grid item xs={xs} sx={MemberInfoFeild}>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
        >
          {label}
        </Typography>
        <Box sx={displayFlex}>
          {icon && value ? <Box sx={ItemIconBox}>{icon}</Box> : ""}
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
            sx={PatientDetailsValue}
          >
            {value}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid container>
      {PatientNameGrid(patientData, "patientName")}
      <Grid item xs={10.8} sx={{ paddingTop: "0.5rem" }}>
        <Grid container>
          <Grid item xs={11.5}>
            <Grid container sx={MemberInfoContainer}>
              {fieldRender(
                "DOB:",
                "auto",
                formatDate(
                  getValue(patientData, "memberInfo.dateOfBirth"),
                  DateFormats.MM_DD_YYYY
                )
              )}
              <Grid item xs={1} mr={"0.5rem"} sx={MemberInfoFormFeildDropdown}>
                <FormInputDropdown
                  name="memberInfo.gender"
                  fieldrequired="required"
                  control={control}
                  label="Gender:"
                  helper={rules.gender}
                  list={GENDER_DROPDOWN_OPTIONS}
                />
              </Grid>
              {fieldRender(
                "Subscriber ID:",
                "auto",
                getValue(patientData, "memberInfo.subscriberId")
              )}
              <Grid item xs={1.15} sx={MemberInfoFormFeildDropdown}>
                <FormInputText
                  name="memberInfo.medicareBeneficiaryIdentifier"
                  control={control}
                  helper={rules.mbiNumber}
                  label="MBI Number:"
                  textLength={11}
                />
              </Grid>
              {fieldRender(
                "Phone:",
                "auto",
                getValue(patientData, "memberInfo.phoneNo") &&
                  getPhoneNoAndExtension(
                    getFormattedPhoneNo(
                      getValue(patientData, "memberInfo.phoneNo")
                    ),
                    getValue(patientData, "memberInfo.extension")
                  ),
                <FaPhone size="14" color={colors.fonts[18]} />
              )}
              <Grid item xs={1.65} sx={MemberInfoFormFeild}>
                <PhoneFaxExtInput
                  label="Mobile Phone:"
                  control={control}
                  phoneFaxName={`memberInfo.cellPhone`}
                  icon={<FaMobileAltIcon />}
                  rules={rules.mobilePhone}
                />
              </Grid>
              <Grid item xs={1.65} sx={MemberInfoFormFeild}>
                <PhoneFaxExtInput
                  label="Alt Phone:"
                  control={control}
                  phoneFaxName={`memberInfo.alternatePhone`}
                  rules={rules.mobilePhone}
                />
              </Grid>
              <Grid item xs={1} sx={MemberInfoFormFeildDropdown}>
                <FormInputDropdown
                  name="memberInfo.languageId"
                  fieldrequired="required"
                  control={control}
                  label="Language:"
                  list={langaugeMasterList}
                />
              </Grid>
              <Grid item xs={1.25} sx={MemberInfoFormFeildDropdown}>
                <FormInputDropdown
                  name="memberInfo.accommodationId"
                  fieldrequired="required"
                  control={control}
                  label="Alt Accomodation:"
                  list={accomodationMasterList}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11.5}>
            <Grid container sx={LowerContainer}>
              <Grid item xs={10.5} sx={LowerContainerItem}>
                {fieldRender(
                  "Health Plan:",
                  "auto",
                  getValue(patientData, "memberInfo.healthPlan")
                )}
                {fieldRender(
                  "Benefit Plan:",
                  "auto",
                  getValue(patientData, "memberInfo.benefitPlan")
                )}
                {fieldRender(
                  "Product:",
                  "auto",
                  getValue(patientData, "memberInfo.product")
                )}
                {fieldRender(
                  "State of Issuance:",
                  "auto",
                  getValue(patientData, "memberInfo.insuranceState")
                )}
                <Grid item xs={2.3} sx={MemberInfoFormFeild}>
                  <FormInputText
                    name="memberInfo.contractIdPbp" 
                    control={control}
                    label="Contract ID-PBP:"
                    textLength={25}
                    placeholder="Add here..."
                    helper={rules.ContractIdPBP}
                  />
                </Grid>
                {fieldRender(
                  "Eligibility Dates:",
                  "auto",
                  formatDate(
                    getValue(patientData, "memberInfo.effectiveDate"),
                    DateFormats.MM_DD_YYYY
                  ) +
                    " - " +
                    formatDate(
                      getValue(patientData, "memberInfo.effectiveThrough"),
                      DateFormats.MM_DD_YYYY
                    )
                )}
              </Grid>
              <Grid item xs={1.5} sx={MemberInfoSave}>
                {SaveButton(handleSave)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MemberInfo;

import { connect } from "react-redux";

import Requestor from "../components/Requestor";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { PriorAuthReferralSourceMasterResponse } from "../../../../../models/Api/Master";
import { ReferralSourceFacility } from "../../../../../models/Referral";

const getReferralSourceState = (
  getReferralSourceState: PriorAuthReferralSourceMasterResponse
) => {
  const result = getReferralSourceState.response;
  return result;
};

const mapStateToProps = ({
  priorAuthReferralSourceState,
}: ApplicationState) => ({
  priorAuthReferralSourceResponse: getReferralSourceState(
    priorAuthReferralSourceState
  ) as ReferralSourceFacility[],
});

export default connect(mapStateToProps)(Requestor);

import { Reducer } from "redux";

import { FilterModel } from "../models/Api/Filter";
import { MyPatientFilterDispatchTypes } from "../models/DispatchTypes/Filter";
import { MyPatientFilterActionDispatchTypes } from "../constants/Filter";
import { getSortColumn, SortColumn } from "../constants/AllPatientRecord";

export const AuthRecordsPayload = {
  assignedToStaffId: null,
  assignUser: null,
  healthPlanName: null,
  providerNpi: null,
  state: null,
  skill: null,
  serviceStatus: { label: "Pending", value: "Pending" }, //Default for All pending Tab
  serviceRequestType: null,
  serviceStatusReason: null,
  sortColumn: getSortColumn(SortColumn.DATE_TIME_REQUESTED),
  sortOrder: "asc",
  contains: true,
  pageNo: "1",
  pageSize: 10,
  firstName: null,
  lastName: null,
};

export const AllPending = {
  ...AuthRecordsPayload,
  urgencyStatus: { label: "Expedited", value: "Expedited" },
  isPrivateDuty: false,
};

export const MyPatient = {
  healthPlanName: null,
  urgencyStatus: null,
  providerNpi: null,
  state: null,
  skill: null,
  serviceStatus: null,
  serviceStatusReason: null,
  sortColumn: getSortColumn(SortColumn.DATE_TIME_REQUESTED),
  sortOrder: "asc",
  contains: true,
  pageNo: "1",
  pageSize: 10,
  firstName: null,
  lastName: null,
  isPrivateDuty: null,
};

export const IncompleteRequest = {
  healthPlanName: null,
  urgencyStatus: null,
  state: null,
  facilityTypeName: null,
  userCreated: null,
  firstName: null,
  lastName: null,
  serviceRequestType: null,
  sortColumn: getSortColumn(SortColumn.NAME),
  sortOrder: "asc",
  contains: true,
  pageNo: "1",
  pageSize: 10,
};

export const StaffingQueue = {
  assignedToStaffId: null,
  assignUser: null,
  assignedToStaffName: null,
  healthPlanName: null,
  state: null,
  providerNpi: null,
  facilityTypeName: null,
  staffingStatus: null,
  county: null,
  firstName: null,
  lastName: null,
  sortColumn: getSortColumn(SortColumn.NAME),
  sortOrder: "asc",
  contains: true,
  pageNo: "1",
  pageSize: 10,
  stage: null,
};

export const PrivateDutyAuth = {
  ...AuthRecordsPayload,
  urgencyStatus: null,
  isPrivateDuty: true,
};

export const initialResponseFilter = {
  myPatient: MyPatient,
  allPending: AllPending,
  incompleteRequest: IncompleteRequest,
  staffingQueue: StaffingQueue,
  privateDutyAuth: PrivateDutyAuth,
};

const initialState: FilterModel | any = {
  response: initialResponseFilter,
};

const StoreFilterPayload: Reducer<FilterModel, MyPatientFilterDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_UPDATE:
    case MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_RESET: {
      return {
        ...state,
        response: action.response,
      };
    }
  }
  return state;
};

export default StoreFilterPayload;

import { GetRoleResponse } from "../models/Api/AllRoles";
import { Reducer } from "redux";
import { GetRoleDispatchTypes } from "../models/DispatchTypes/AllRoles";
import { RolesActionDispatchTypes } from "../constants/AllRoles";

export const initialState: GetRoleResponse = {
  loading: false,
  response: [
    {
      roleId: -1,
      roleName: "",
      roleDescription: "",
    },
  ],
};

const RoleReducer: Reducer<GetRoleResponse, GetRoleDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RolesActionDispatchTypes.GET_ROLES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RolesActionDispatchTypes.GET_ROLES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    
  }
  return state;
};

export default RoleReducer;

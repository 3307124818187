import { Reducer } from "redux";

import { ReauthCompletedResponse } from "../models/Api/Authorization";
import { ReauthCompleteDispatchTypes } from "../models/DispatchTypes/Authorization";
import { ReauthCompleteActionDispatchTypes } from "../constants/Authorization";

export const initialState: ReauthCompletedResponse = {
  loading: false,
  response: {
    serviceRequestId: -1,
    completedOn: "",
  },
};

const ReauthCompleteReducer: Reducer<
  ReauthCompletedResponse,
  ReauthCompleteDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReauthCompleteActionDispatchTypes.REAUTH_COMPLETE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReauthCompleteActionDispatchTypes.REAUTH_COMPLETE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReauthCompleteReducer;

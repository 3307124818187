import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { CareTypeResponse } from "../../../../../models/Api/Master";
import { getDisciplines } from "../../../../../actions/Master";
import ServiceRequest from "../components/ServiceRequest";
import { PatientInformationView } from "../../../../../models/Patient";
import { resetStates } from "../../../../../actions/Common";
import { DisciplineSearch } from "../../../../../models/Service";

const mapStateToProps = ({
  careTypeState,
  disciplinesState,
  patientInformationViewState,
}: ApplicationState) => ({
  careTypes: careTypeState as CareTypeResponse,
  disciplinesState,
  reauthPatientDetails:
    patientInformationViewState.response as PatientInformationView,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getDisciplines: (payload: DisciplineSearch) => {
      dispatch(getDisciplines(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);

import React from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { Grid } from "@mui/material";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

import "../styles/notesStyles.css";
import { SurveyStyling } from "../styles/styles";

const creatorOptions = {
  showLogicTab: true,
  showDesignerTab: true,
  showState: true,
};

const NotesDesigner: React.FC<any> = ({
  model,
  saveSurveyJson,
  setJson,
}: any) => {
  if (creatorOptions != null) {
    const creator = new SurveyCreator(creatorOptions, {
      theme: SurveyStyling,
    });

    creator.text = model;
    creator.isAutoSave = true;
    creator.autoSaveDelay = 200;
    creator.showSurveyTitle = false;

    creator.onModified.add(() => {
      setJson(creator.text);
    })

    creator.saveSurveyFunc = (saveNo: any, callback: any) => {
      setJson(creator.text);
      saveSurveyJson(creator.JSON, saveNo, () => {
        callback(saveNo, true);
      });
    };
    return <SurveyCreatorComponent creator={creator} />;
  }

  return <Grid></Grid>;
};

export default NotesDesigner;

export const ASC = "asc";
export const AUTH_TABS = 7;
export const DEFAULT_PAGE = 1;
export const DESC = "desc";
export const FULLNAME = "fullName";
export const NAME = "NAME";
export const URGENCY = "URGENCY";
export const URGENCY_STATUS = "UrgencyStatus";
export const PER_PAGE = 10;
export const ADMIN_LIST_PAGE_SIZE = 9999;
export const DISCIPLINE_PER_PAGE = 5;
export const ELIGIBILITY_CHECK_DATE = "eligibilityCheckDate";

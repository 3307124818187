import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import {
  LoggedInUserDetailsDispatchTypes,
  PermissionDispatchTypes,
  UserDetailsDispatchTypes,
} from "../models/DispatchTypes/User";
import { GetRoleDispatchTypes } from "../models/DispatchTypes/AllRoles";
import { RolesActionDispatchTypes } from "../constants/AllRoles";
import {
  UserListActionDispatchTypes,
  LoggedInUserDetailsActionDispatchTypes,
  UserDetailsActionDispatchTypes,
  UserPermissionActionDispatchTypes,
  RouteUrlInfoActionDispatchTypes,
  PermissionActionDispatchTypes,
  UserListWithFilterActionDispatchTypes,
} from "../constants/User";
import {
  UserListDispatchTypes,
  UserPermissionDispatchTypes,
  RouteUrlInfoDispatchTypes,
} from "../models/DispatchTypes/User";
import {
  GetRoles,
  GetUserDetails,
  PutLoggedInUserDetails,
  PutUserDetails,
  GetUserPermissionCall,
  GetUserListWithFilter,
} from "../services/User";
import { configUrlForUserManagement } from "../constants/Config";
import { GetUserList } from "../services/User";
import { MessageType, ResponseMessages } from "../constants/ToastMessage";
import { msalInstance } from "../index";
import { UserDetailsPayload, RouteUrlInfo, UserListWithFilterPayload, } from "../models/User";
import SnackbarUtils from "../utils/SnackbarProvider";

export const getLogin: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    LoggedInUserDetailsDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoggedInUserDetailsActionDispatchTypes.LOGIN_INVOKE,
    });
    const account = msalInstance.getActiveAccount();
    const username = account && account.username ? account.username : "";
    const response = await GetUserDetails(configUrlForUserManagement, username);

    dispatch({
      response,
      type: LoggedInUserDetailsActionDispatchTypes.LOGIN_SUCCESS,
    });
  };
};

export const putLoggedInUserDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserDetailsDispatchTypes
  >
> = (userId: string, payload: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_INVOKE,
    });

    const response = await PutLoggedInUserDetails(
      configUrlForUserManagement,
      userId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            type: LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_ERROR,
            response,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const getUserDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserDetailsDispatchTypes
  >
> = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserDetailsActionDispatchTypes.USER_DETAILS_INVOKE,
    });
    const suffix = `${id}`;
    const response = await GetUserDetails(configUrlForUserManagement, suffix);

    dispatch({
      response,
      type: UserDetailsActionDispatchTypes.USER_DETAILS_SUCCESS,
    });
  };
};

export const putUserDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserDetailsDispatchTypes
  >
> = (userId: string, payload: UserDetailsPayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserDetailsActionDispatchTypes.USER_DETAILS_POST_INVOKE,
    });

    const response = await PutUserDetails(
      configUrlForUserManagement,
      userId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            type: UserDetailsActionDispatchTypes.USER_DETAILS_POST_ERROR,
            response,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UserDetailsActionDispatchTypes.USER_DETAILS_POST_SUCCESS,
          });
          SnackbarUtils.success(ResponseMessages(MessageType.PUT_SUCCESS));
          break;
        default:
          break;
      }
    }
  };
};

export const userDetailsReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserDetailsDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserDetailsActionDispatchTypes.USER_DETAILS_RESET,
    });
  };
};

export const getUserList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserListDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserListActionDispatchTypes.USER_LIST_INVOKE,
    });

    const response = await GetUserList(configUrlForUserManagement);

    dispatch({
      response,
      type: UserListActionDispatchTypes.USER_LIST_SUCCESS,
    });
  };
};

export const getRoles: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    GetRoleDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RolesActionDispatchTypes.GET_ROLES_INVOKE,
    });
    const response = await GetRoles(configUrlForUserManagement);

    dispatch({
      response,
      type: RolesActionDispatchTypes.GET_ROLES_SUCCESS,
    });
  };
};

export const getUserPermissionCall: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserPermissionDispatchTypes
  >
> = (userId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserPermissionActionDispatchTypes.USER_PERMISSION_INVOKE,
    });
    const response = await GetUserPermissionCall(
      configUrlForUserManagement,
      userId
    );
    dispatch({
      response,
      type: UserPermissionActionDispatchTypes.USER_PERMISSION_SUCCESS,
    });
  };
};

export const saveRouteUrlInfo: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RouteUrlInfoDispatchTypes
  >
> = (routeUrlInfo: RouteUrlInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RouteUrlInfoActionDispatchTypes.ROUTE_URL_INFO_INVOKE,
    });
    const response = routeUrlInfo;
    dispatch({
      response,
      type: RouteUrlInfoActionDispatchTypes.ROUTE_URL_INFO_SUCCESS,
    });
  };
};

export const getAccessControlId: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PermissionDispatchTypes
  >
> = (controlIds: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PermissionActionDispatchTypes.PERMISSION_INVOKE,
    });
    const response = controlIds;
    dispatch({
      response,
      type: PermissionActionDispatchTypes.PERMISSION_SUCCESS,
    });
  };
};

export const getUserListWithFilter: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UserListDispatchTypes
  >
> = (payload: UserListWithFilterPayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserListWithFilterActionDispatchTypes.GET_USER_LIST_WITH_FILTER_INVOKE,
    });
    const response = await GetUserListWithFilter(configUrlForUserManagement, payload);
    dispatch({
      response,
      type:UserListWithFilterActionDispatchTypes.GET_USER_LIST_WITH_FILTER_SUCCESS,
    });
  };
};
import { Reducer } from "redux";

import { FollowingPhysicianDetailsResponse } from "../models/Api/PhysicianDetails";
import { ReAuthPhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  GetFollowingPhysicianDetailsActionDispatchTypes,
  PostFollowingPhysicianDetailsActionDispatchTypes,
  PutFollowingPhysicianDetailsActionDispatchTypes,
} from "../constants/PhysicianDetails";
import { FollowingPhysicianDetailsForm } from "../models/PhysicianDetails";

const initialState: FollowingPhysicianDetailsResponse = {
  loading: true,
  response: FollowingPhysicianDetailsForm,
  error: null,
};

const FollowingPhysicianDetailsReducer: Reducer<
  FollowingPhysicianDetailsResponse,
  ReAuthPhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  const invoke = (state: FollowingPhysicianDetailsResponse) => ({
    ...state,
    loading: true,
  });

  const success = (state: FollowingPhysicianDetailsResponse, action: any) => ({
    ...state,
    response: action.response,
    loading: false,
    error: {},
  });

  const error = (state: FollowingPhysicianDetailsResponse, action: any) => ({
    ...state,
    error: action.response,
    loading: false,
  });

  switch (action.type) {
    case GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR: {
      return error(state, action);
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return invoke(state);
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return success(state, action);
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_ERROR: {
      return error(state, action);
    }

    case PostFollowingPhysicianDetailsActionDispatchTypes.FOLLOWING_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default FollowingPhysicianDetailsReducer;

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Mypatient from "../components";
import { ApplicationState } from "../../../store/ApplicationState";
import { storeFilterPayload } from "../../../actions/Filter";
import { SearchFilterModel } from "../../../models/Filter";

const mapStateToProps = ({
  filterState
}: ApplicationState) => ({
  filterState: filterState
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    storeFilterPayload: (payload: SearchFilterModel, type: number) => {
      dispatch(storeFilterPayload(payload, type));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mypatient);

import { isNil } from "lodash";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { FormInputText } from "./FormInputText";
import { initMapScript, extractPatientAddress } from "../../utils/Address";
import { others } from "../../styles/colors";
import fontWeight from "../../styles/mui/fontWeight";

interface AddressProps {
  control: any;
  getValues: any;
  labels: any;
  nestedObj?: string;
  reset: any;
  searchInput: any;
  setValue?: any;
  addressLabel: string;
  addressVariant: any;
  addressStyle?: string;
  disable?: boolean;
  clearErrors?: any;
}

type InputLabel = {
  helper?: any;
  label: string;
  name: string;
  textLength?: number;
};

type AllProps = AddressProps;

export const AddressFormInputUseForm: React.FC<AllProps> = ({
  control,
  getValues,
  labels,
  nestedObj,
  reset,
  searchInput,
  setValue,
  addressLabel,
  addressVariant,
  addressStyle,
  disable,
}: AllProps) => {
  const initAutocomplete = () => {
    if (searchInput.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        searchInput.current
      );
      autocomplete.setFields(["address_component", "geometry"]);
      autocomplete.addListener("place_changed", () =>
        onChangeAddress(autocomplete)
      );
    }
  };

  const getStreetName1 = (
    streetNumber: string | null,
    streetName1: string | null
  ) =>
    streetNumber && streetName1
      ? streetNumber + " " + streetName1
      : streetName1;

  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const address = extractPatientAddress(place);
    const {
      streetName1,
      streetName2,
      county,
      city,
      patientState,
      zipCode,
      streetNumber,
    } = address;

    const obj = {
      [labels[0].name]: getStreetName1(streetNumber, streetName1),
      [labels[1].name]: streetName2,
      [labels[2].name]: county,
      [labels[3].name]: city,
      [labels[4].name]: patientState,
      [labels[5].name]: zipCode,
    };
    if (streetName1 !== null && county !== null) {
      if (isNil(setValue)) {
        if (nestedObj) {
          // if form has nested objects
          const object = getValues(nestedObj);
          reset({
            ...getValues(),
            [nestedObj]: {
              ...object,
              ...obj,
            },
          });
        } else {
          reset({
            ...getValues(),
            ...obj,
          });
        }
      } else {
        setValue(labels[0].name, getStreetName1(streetNumber, streetName1), {
          shouldValidate: true,
        });
        setValue(labels[1].name, streetName2, {
          shouldValidate: true,
        });
        setValue(labels[2].name, county, {
          shouldValidate: true,
        });
        setValue(labels[3].name, city, {
          shouldValidate: true,
        });
        setValue(labels[4].name, patientState, {
          shouldValidate: true,
        });
        setValue(labels[5].name, zipCode, {
          shouldValidate: true,
        });
      }
    }
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  // creates dynamic names according to nested object
  const namesForNestedObj = (keyName: string, name: string) => {
    return `${keyName}.${name}`;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ padding: "0.5rem 0 " }}>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant={addressVariant}
              color={others.otherColors[63]}
              fontWeight={fontWeight.Weight[5]}
              mb={addressStyle}
            >
              {addressLabel}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {labels.map((label: InputLabel, index: number) => (
              <Grid
                item
                xs={index === 0 || index === 1 ? 2.5 : 1.75}
                p={"0.9rem 1.2rem 1.2rem 0"}
                key={label.name}
              >
                <FormInputText
                  name={
                    nestedObj
                      ? namesForNestedObj(nestedObj, label.name)
                      : label.name
                  }
                  placeholder="Add here..."
                  control={control}
                  label={label.label}
                  textLength={label.textLength}
                  searchInputRef={index === 0 ? searchInput : null}
                  helper={label.helper || undefined}
                  disabled={disable}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddressFormInputUseForm;

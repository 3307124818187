import { Reducer } from "redux";

import { CareCoordinationProgramResponse } from "../models/Api/Master";
import { CareCoordinationProgramDispatchTypes } from "../models/DispatchTypes/Master";
import { CareCoordinationProgramActionDispatchTypes } from "../constants/Master";

export const initialState: CareCoordinationProgramResponse = {
  loading: false,
  response: [],
};

const CareCordinationReducer: Reducer<
  CareCoordinationProgramResponse,
  CareCoordinationProgramDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case CareCoordinationProgramActionDispatchTypes.CARECORDINATION_PROGRAM_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CareCoordinationProgramActionDispatchTypes.CARECORDINATION_PROGRAM_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default CareCordinationReducer;

import {
  Typography,
  Grid,
  Button,
  Autocomplete,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { matchSorter } from "match-sorter";
import { Controller } from "react-hook-form";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthNumberControl,
  AutoComplete,
  ButtonBox,
  DateControl,
  FilterParameterGrid,
  SearchButton,
  SelectControl,
  SubscriberControl,
  warnStyle,
} from "../styles/style";
import {
  PatientFilterOptions,
  PatientRecordSearch,
} from "../../../../../models/Patient";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { IconNames } from "../../../../../constants/FormInputIconName";
import { rules } from "../../../../../utils/validation/Validation";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { PayerResponse } from "../../../../../models/Api/Master";
import { getListResult, getValue } from "../../../../../utils";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { HealthPlanData } from "../../../../../constants/PatientList";
import useMenuItem from "../../../../../hooks/useMenuItem";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface PropsFromState {
  control: any;
  handleSearchValidate: (type: string) => void;
  patientRecord: PatientRecordSearch | null;
  setType: React.Dispatch<string>;
  setValue: (name: string, value: string, boolean: any) => void;
  type: string;
  values: any;
  payerState: PayerResponse;
  healthPlan: PatientFilterOptions | undefined;
  setHealthPlan: (healthPlan: PatientFilterOptions) => void;
  clearErrors: (name: string) => void;
  checkEligibilityBtnFlag: boolean
}

export interface PropsFromDispatch {
  getMasterEligibilityPayer: () => void;
}

type Props = PropsFromState & PropsFromDispatch;

const PatientFilter = ({
  control,
  getMasterEligibilityPayer,
  handleSearchValidate,
  payerState,
  setType,
  type,
  setValue,
  healthPlan,
  setHealthPlan,
  clearErrors,
  checkEligibilityBtnFlag
}: Props) => {
  const [payer, setPayer] = useState<PatientFilterOptions[]>([HealthPlanData]);
  const { open, onOpen, onClose } = useMenuItem();

  useEffect(() => {
    setPayer(getListResult(payerState.response));
  }, [payerState.response]);

  useEffect(() => {
    if (
      payerState &&
      !payerState.loading &&
      getValue(payerState, "response", []).length === 0
    ) {
      getMasterEligibilityPayer();
    }
  }, []);

  const handleSearchClick = (type: string) => {
    setType(type);
    setValue("pageNo", "1", true);
    handleSearchValidate(type);
  };

  const handleValidateClick = (type: string) => {
    setType(type);
    setValue("pageNo", "1", true);
    handleSearchValidate(type);
  };

  const onHealthPlanSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    onClose();
    setHealthPlan(value);
    setValue("healthPlan", value.value, "");
    clearErrors("healthPlan");
  };

  const handleKeyDown = (event: any) => {
    if (event.key === " " && !event.target.value.trim()) {
      event.preventDefault();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={warnStyle}>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.red[100]}
        >
          *&nbsp;
        </Typography>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[2]}
          color={colors.fonts[4]}
        >
          Mandatory criteria, required for eligibility check
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              fontWeight={fontWeight.Weight[5]}
              color={colors.black[3]}
            >
              FILTER / SEARCH RECORDS:
            </Typography>
          </Grid>
          <Grid item xs={6} sx={warnStyle}>
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[2]}
              color={colors.red[90]}
            >
              Search for member before checking eligibility to create a request
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={"1rem"} sx={displayFlex}>
        <Grid container>
          <Grid item xs={1.42} sx={FilterParameterGrid}>
            <FormControl sx={SelectControl} variant="standard">
              <FormInputText
                name="firstName"
                control={control}
                label="First Name:"
                textLength={64}
                iconName={IconNames.FA_SEARCH}
                helper={rules.patientFirstName}
                inputType="onlyTextSpaceAndHyphen"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.42} sx={FilterParameterGrid}>
            <FormControl sx={SelectControl} variant="standard">
              <FormInputText
                name="lastName"
                control={control}
                label="Last Name:"
                textLength={64}
                iconName={IconNames.FA_SEARCH}
                helper={
                  type == "validate"
                    ? rules.checkEligibilityLastName
                    : rules.patientLastName
                }
                inputType="onlyTextSpaceAndHyphen"
                mandatorySymbol={type == "validate" ? false : true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.8} sx={FilterParameterGrid}>
            <FormControl sx={AuthNumberControl} variant="standard">
              <FormInputText
                name="authorizationNumber"
                control={control}
                label="Authorization:"
                textLength={20}
                helper={rules.authorizationNumber}
                inputType="onlyNumAlphabetRegex"
                iconName={IconNames.FA_SEARCH}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.12} sx={FilterParameterGrid}>
            <FormControl variant="standard" sx={DateControl}>
              <DatePicker
                name="dateOfBirth"
                control={control}
                label={"Date of Birth:"}
                helper={
                  type == "validate"
                    ? rules.dateOfBirthEligibility
                    : rules.dateOfBirthOptional
                }
                allowFutureDate={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.4} sx={FilterParameterGrid}>
            <FormControl variant="standard" sx={SubscriberControl}>
              <FormInputText
                name="subscriberId"
                control={control}
                label="Subscriber ID:"
                placeholder=""
                textLength={15}
                helper={
                  type == "validate"
                    ? rules.checkEligibilitySubscriberId
                    : rules.subscriberId
                }
                mandatorySymbol={type == "validate" ? false : true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.8} pt={"0.7rem"} sx={FilterParameterGrid}>
            <Box sx={displayFlex}>
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[2]}
                color={colors.fonts[2]}
              >
                Health Plan:
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[2]}
                color={colors.red[100]}
                pl={"0.3rem"}
              >
                *
              </Typography>
            </Box>
            <Controller
              render={({ fieldState: { error } }) => (
                <>
                  <Autocomplete
                    sx={AutoComplete}
                    options={payer as PatientFilterOptions[]}
                    freeSolo
                    forcePopupIcon
                    getOptionLabel={(option: PatientFilterOptions | string) =>
                      typeof option === "string" ? option : option.label
                    }
                    open={open}
                    onOpen={() => onOpen()}
                    onClose={() => onClose()}
                    PopperComponent={StyledPopperCheckBoxAutocomplete}
                    filterOptions={(options: any, { inputValue }) =>
                      matchSorter(options, inputValue, { keys: ["label"] })
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    id="auto-complete"
                    autoComplete
                    disableClearable
                    clearIcon={""}
                    onChange={onHealthPlanSelection}
                    includeInputInList
                    value={healthPlan}
                    noOptionsText={NO_RECORD_FOUND}
                    renderInput={(params: any) => {
                      params.inputProps.maxLength = 50;
                      return (
                        <Box>
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Choose"
                            onKeyDown={handleKeyDown}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        </Box>
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Typography
                          {...props}
                          variant="body1"
                          fontWeight={fontWeight.Weight[4]}
                          color={colors.fonts[25]}
                        >
                          {option.label}
                        </Typography>
                      );
                    }}
                  />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </>
              )}
              control={control}
              name="healthPlan"
              rules={
                type == "validate"
                  ? rules.checkEligibilityHealthPlan
                  : rules.healthPlanDropdown
              }
            />
          </Grid>
          <Grid item xs={2.9} sx={ButtonBox}>
            <Permission
              controlId={`|${PatientRecordsAccess.PATIENT_RECORD_SEARCH_READ}|`}
              passThrough
            >
              {(isDisabled: boolean) => (
                <Button
                  variant="contained"
                  sx={SearchButton}
                  onClick={() => handleSearchClick("search")}
                  disabled={!isDisabled}
                >
                  SEARCH
                </Button>
              )}
            </Permission>
            <Permission
              controlId={`${PatientRecordsAccess.CHECK_ELIGIBILITY_CREATE}||`}
            >
              <Button
                variant="outlined"
                onClick={() => handleValidateClick("validate")}
                disabled={checkEligibilityBtnFlag}
              >
                CHECK ELIGIBILITY
              </Button>
            </Permission>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PatientFilter;

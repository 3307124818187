import { DisciplinePayload } from "../models/Disciplines";
import { GetQueryString } from "../utils";
import API from "./api";

type Model = /*unresolved*/ any;

export const GetReAuthTimelineData = (
  configUrl: string,
  referralId: string
) => {
  return API.get(`${configUrl}api/referral/AuthTimelineStages/${referralId}`);
};

export const GetServiceProviderDetails = (
  configUrl: string,
  referralId: number
) => {
  return API.get(
    `${configUrl}api/serviceprovider/ServiceProvider/${referralId}`
  );
};

export const DeleteServiceProviderDetails = (
  configUrl: string,
  referralId: number
) => {
  return API.delete(
    `${configUrl}api/serviceprovider/${referralId}`
  );
};

export const PutReAuthPhysicianDetails = (payload: Model) => {
  return payload;
};

export const PostReAuthPhysicianDetails = (payload: Model) => {
  return payload;
};

export const GetDisciplines = (configUrl: string, payload: DisciplinePayload) =>
  API.get(
    `${configUrl}api/disciplines/${payload.referralId}?${GetQueryString(
      payload
    )}`
  );

export const PutDisciplines = (
  configUrl: string,
  authDisciplineId: number,
  disciplineData: any
) =>
  API.putApi(`${configUrl}api/disciplines/${authDisciplineId}`, disciplineData);

export const PutPhysicianReview = (
  configUrl: string,
  authDisciplineId: number,
  disciplineData: any
) =>
  API.putApi(
    `${configUrl}api/disciplines/PhysicianReview/${authDisciplineId}`,
    disciplineData
  );

export const GetReferralDisciplines = (
  configUrl: string,
  referralDisciplineId: number
) => API.get(`${configUrl}api/disciplines/Discipline/${referralDisciplineId}`);

export const PutServiceProviderDetails = (
  configUrl: string,
  authNo: number,
  payLoad: any
) =>
  API.putApi(
    `${configUrl}api/serviceprovider/ServiceProvider/${authNo}`,
    payLoad
  );

export const PutAuthorizationDetailTabs = (
  configUrl: string,
  suffix: string,
  data: any
) => API.putApi(`${configUrl}api/authorization/${suffix}`, data);

export const GetAuthorizationDetailTabs = (
  configUrl: any,
  referralId: number
) => API.get(`${configUrl}api/authorization/${referralId}`);

export const GetResumeIntakeInformation = (
  configUrl: any,
  referralId: number
) => API.get(`${configUrl}api/authorization/ReAuth/${referralId}`);

export const GetReauthCompleteInfo = (configUrl: any, referralId: number) =>
  API.get(`${configUrl}api/authorization/reAuthCompleteIntake/${referralId}`);

export const PostInitiateFax = (configUrl: any, payload: FormData) =>
  API.postApi(`${configUrl}api/fax/FaxParameter`, payload);

import { colors } from "../../../../../styles/colors";

export const TableNameDetails = (removeBorder: boolean) => ({
  borderRight: 0,
  padding: 0,
  paddingLeft: "2.5rem",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const PatientTableCell = (removeBorder: boolean) => ({
  borderRight: 0,
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
  borderLeft: 0,
  padding: 0,
  textAlign: "left",
  paddingRight: "2%",
});

export const PatientDataTableBody = () => ({
  border: `1px solid ${colors.borders[1]}`,
});

export const NameContainer = () => ({
  alignItems: "center",
  height: "100%",
});

export const PatientTableHeadCell = () => ({
  backgroundColor: colors.backgroundColor[8],
  opacity: 1,
  padding: "1.2rem 0 1.2rem 0",
  borderBottom: "unset",
  textAlign: "left",
  "&.MuiTableCell-root:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    paddingRight: "2%",
    borderBottom: "none",
  },
  "&.MuiTableCell-root:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    paddingLeft: "2.5rem",
    textAlign: "left",
    borderBottom: "none",
  },
});

export const EligibiliyHeadCell = () => ({
  backgroundColor: colors.backgroundColor[8],
  opacity: 1,
  padding: "1.2rem 0 1.2rem 2rem",
  borderBottom: "unset",
  textAlign: "left",
  "&.MuiTableCell-root:last-child": {
    borderRadius: "0.7rem",
    paddingLeft: "4.5rem",
    borderBottom: "none",
  },
});

export const SelectControl = () => ({
  margin: "0.7rem",
  minWidth: "8.5rem",
  width: "100%"
});

export const AuthNumberControl = () => ({
  margin: "0.7rem",
  minWidth: "11rem",
  width: "100%"
});

export const SubscriberControl = () => ({
  margin: "0.7rem 1rem 0.7rem 1rem",
  minWidth: "7rem",
  width: "100%"
});

export const DateControl = () => ({
  margin: "0.7rem 0.4rem 0.7rem 0.7rem",
  minWidth: "6.8rem",
  width: "100%"
});

export const DateTextField = () => ({
  svg: {
    color: colors.primary.main,
  },
});

export const ButtonBox = () => ({
  margin: "1.4rem 0 0.7rem 0.7rem",
  display: "flex"
});

export const ValidateButton = () => ({
  marginLeft: "1.5rem",
});

export const EligibiliyBodyCell = (removeBorder: boolean) => ({
  borderRight: 0,
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
  borderLeft: 0,
  textAlign: "left",
  paddingLeft: "2rem",
});

export const ViewButton = () => ({
  width: "4.5rem",
});

export const SearchButton = () => ({
  width: "6.5rem",
});

export const warnStyle = () => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingRight: "1.2rem"
});

export const AutoComplete = {
  width: "12.5rem",
  minWidth: "7rem",
  maxWidth: "20rem",
  marginTop: "0.2rem",
  "& .MuiAutocomplete-inputRoot": {
    paddingLeft: 0
  },
};



export const RecordListRow = () => ({
  height: "4rem",
});

export const FilterParameterGrid = () => ({
  paddingRight: "1.8rem",
});

export const FilterTitle = () => ({
  padding: "0.8rem 0 0 0.5rem",
});
import { connect } from "react-redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import ReAuthReviewRequestor from "../components/ReAuthReviewRequestor";

const mapStateToProps = ({ reAuthReviewState }: ApplicationState) => ({
  reAuthRequestorDetails: reAuthReviewState,
});

export default connect(mapStateToProps)(ReAuthReviewRequestor);

import { useContext } from "react";

import { PermissionContext } from "../App/components";

const Permission = ({ controlId, passThrough, children }: any) => {
  const permission = useContext(PermissionContext);
  const controlIdArray = controlId.split("|");

  const isCreateControlIdPresent = permission.indexOf(controlIdArray[0]) >= 0;
  const isReadControlIdPresent = permission.indexOf(controlIdArray[1]) >= 0;
  const isUpdateControlIdPresent = permission.indexOf(controlIdArray[2]) >= 0;

  if (isCreateControlIdPresent || isUpdateControlIdPresent) {
    if (passThrough) {
      return children(false);
    }
    return children;
  } else if (isReadControlIdPresent) {
    if (passThrough) {
      return children(true);
    }
    return children(false);
  }
};

export default Permission;

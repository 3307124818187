import { styled } from "@mui/system";
import { TableCell } from "@mui/material";

import { others } from "../../../styles/colors";

export const TableCellStickyLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 40,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableCellStickyHeaderLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 50,
  backgroundColor: others.SchedulerColors[1],
}));
export const TableCellStickyHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  width: "3rem",
  left: 0,
  backgroundColor: others.SchedulerColors[1],
}));

export const checkBoxStyle = {
  "& span": {
    padding: "0rem ",
  },
};

export const tableRowHeight = {
  height: "4rem",
};

export const errorMessageBoxHeight = {
  height: "1rem",
  width: "100%",
};
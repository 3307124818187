import colors from "../../../../../../../styles/colors/colors";

export const RequestDetailsFeildsGrid = () => ({
  padding: "1rem 0.3rem",
});

export const RequestDetailsFeildsGridEmergencyDetails = () => ({
  padding: "0 0.5rem",
});

export const RequestDetailsFeildDescription = (isIcon: boolean) => ({
  display: "flex",
  marginTop: isIcon ? "0.7rem" : "1rem",
  alignItems: "center",
});
export const RequestDetailsFeildDescriptionEmergencyDetails = () => ({
  display: "flex",
  alignItems: "center",
});
export const RequestDetailsFeildDescriptionIcon = (isIcon: boolean) => ({
  marginRight: isIcon ? "0.5rem" : "0rem",
  color: colors.fonts[18],
});

export const Extention = (isExtention: boolean | undefined) => ({
  marginRight: isExtention ? "0.5rem" : "0rem",
  marginLeft: isExtention ? "0.5rem" : "0rem",
});

export const FlexStyle = () => ({
  display: "flex",
});

export const RequestDetailsOutterGrid = () => ({
  backgroundColor: colors.backgroundColor[2],
  paddingLeft: "0.5rem",
});

export const RequestDetailsHeadingGrid = () => ({
  padding: "2.5rem 0 0 0.7rem",
  margin: "0 1rem",
});

export const DividerStyle = () => ({
  margin: "1rem 0 0 0",
});

export const RequestDetailsRowGrid = () => ({
  borderBottom: `1px solid ${colors.borders[1]}`,
  margin: "0 1rem",
});

export const RequestDetails1stRowLeftColumn = () => ({
  display: "flex",
  justifyContent: "space-between",
  borderRight: `1px solid ${colors.borders[1]}`,
  margin: "0.5rem 0 1rem 0",
  padding: "0.8rem 2rem 1rem 0.5rem",
});

export const RequestDetails1stRowLRightColumn = () => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "0.5rem 0 1rem 0",
  padding: "0.8rem 2rem 1rem 3rem",
});

export const RequestDetails2ndRowLeftColumn = () => ({
  borderRight: `1px solid ${colors.borders[1]}`,
  margin: "1.6rem 0",
  padding: "0rem 0rem 1rem 0.5rem",
});

export const RequestDetails2nd3rdRowLeftColumnHeading = () => ({
  display: "flex",
  alignItems: "center",
  padding: "0 0.5rem",
});
export const RequestDetails2ndRowGrid = () => ({
  borderBottom: `1px solid ${colors.borders[1]}`,
  margin: "0.5rem 1.5rem",
});

export const RequestDetails2ndRowRightColumn = () => ({
  margin: "1.6rem 0",
  padding: "0 2rem",
});

export const RequestDetails3rdRowRightColumn = () => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "0.3rem  0",
  padding: "1.6rem 1.5rem 0.5rem 1.5rem",
});
export const RequestDetails3rdRowLeftColumn = () => ({
  display: "flex",
  justifyContent: "space-between",
  borderRight: `1px solid ${colors.borders[1]}`,
  margin: "0.3rem  0",
  padding: "1rem 2rem 0.5rem 0.5rem",
});

export const RequestDetails4thRowGrid = () => ({
  margin: "0 0.8rem",
  paddingBottom: "1.8rem",
});

export const RequestDetails4thRow1stColGrid = () => ({
  diaplay: "flex",
  justifyContent: "space-around",
  borderRight: `1px solid ${colors.borders[1]}`,
  margin: "0.8rem 0 ",
});

export const RequestDetails4thRow3RDColGrid = () => ({
  diaplay: "flex",
  justifyContent: "space-around",
  margin: "0.8rem 0 ",
});

export const RequestDetails4thRowDescriptionBox = () => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
});

export const QuestionAnswerText = () => ({
  textTransform: "uppercase",
});

export const RequestDetails4thRowDescription = () => ({
  marginLeft: "0.1rem",
  display: "flex",
  alignItems: "center",
});
export const RequestDetails4thRow2ndColGrid = () => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "2rem",
});

export const RequestDetailEmergencyContactTable = () => ({
  "& .MuiTableCell-root": {
    border: 0,
    padding: "0.5rem 0 0.5rem 0.2rem",
  },
});

export const TableHeadStyle = () => ({
  backgroundColor: "none",
  marginBottom: "0.2rem",
  "& .MuiTableCell-root": {
    padding: "0.8rem 0.3rem 0.2rem",
    borderBottom: "none",
  },
  "& th:first-of-type": {
    borderBottom: "none",
  },
  "& th:last-of-type": {
    borderBottom: "none",
  },
});

export const PaddingLeft = () => ({
  PaddingLeft: "0.5rem",
});

export const RequestDetailsFeild = () => ({
  paddingLeft: "0.5rem",
});

export const RequestDetailsHeadingIcon = () => ({
  paddingRight: "1rem",
});

export const RequestDetailsEMCtable = () => ({
  padding: "1rem 0.5rem 0 0 ",
});

export const RequestDetailsEMCtablePhone = () => ({
  display: "flex",
  alignItems: "center",
});

export const RequestDetailsAD = () => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0.8rem 0 0 0 ",
});

export const RequestDetailsQuestion = () => ({
  padding: "1.3rem 1rem",
});

export const RequestDetailsOrderDate = () => ({
  marginRight: "0.5rem",
});

export const IconStyle = {
  color: colors.primary.main,
  marginRight: "0.2rem",
};

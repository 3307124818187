import { colors, others } from "../../../../../styles/colors";

export const ModalGrid = () => ({
  position: "absolute",
  top: "25%",
  left: "20%",
  width: "55%",
  height: "auto",
  bgcolor: "background.paper",
  padding: "1.5rem 1.5rem",
  borderRadius: "0.5rem",
});

export const ContentBox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const ModalHeaderBox = () => ({
  padding: "0 1rem 1rem 1rem",
});

export const OuterBodyGrid = () => ({
  backgroundColor: colors.backgroundColor[3],
  opacity: 1,
  padding: "0.5rem 1rem 1.2rem 0.7rem",
  borderRadius: "1.2rem",
});

export const GridBody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "2rem 1.5rem",
  paddingLeft: "2.3rem",
  margin: "0.5rem",
  paddingBottom: "3.2rem",
  borderRadius: "1.2rem",
  display: "flex",
});

export const DetailsDivider = () => ({
  height: "4.7rem",
  margin: "0 0.5rem",
});

export const GridData = () => ({
  paddingTop: "2rem",
  paddingLeft: "2.4rem",
});

export const npiBox = {
  paddingLeft: "0.5rem",
};

export const GridFooter = () => ({
  background: others.otherColors[33],
  opacity: 1,
  margin: "0.5rem",
  borderRadius: "1rem",
  display: "flex",
  padding: "0.8rem 0.8rem",
  justifyContent: "end",
});

export const ModalTitle = {
  paddingTop: "0.4rem",
  paddingBottom: "0.5rem",
};

export const Info = () => ({
  paddingTop: "0.8rem",
  lineHeight: "1rem"
});

export const InfoBox = () => ({
  paddingTop: "1rem",
});

export const Question = () => ({
  paddingTop: "0.4rem",
});

export const Crossicon = {
  color: colors.red[80],
  opacity: 2,
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
};


export const QuestionIcon = () => ({
  fontSize: "1.5rem",
  paddingLeft: "1rem",
  paddingRight: "2rem",
  color: colors.fonts[18],
});

import { connect } from "react-redux";

import Header from "../components/Header/Header";
import { ApplicationState } from "../../../store/ApplicationState";
import { PatientInformationViewResponse } from "../../../models/Api/PatientInformation";

const mapStateToProps = ({
  checkEligibilityState,
  patientInformationViewState,
}: ApplicationState) => ({
  data: patientInformationViewState as PatientInformationViewResponse,
  checkEligibilityData: checkEligibilityState,
});

export default connect(mapStateToProps, null)(Header);

import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FaFax,
  FaHouseUser,
  FaMapMarkerAlt,
  FaPhone,
  FaRegCalendarCheck,
  FaRegClock,
  FaUserClock,
  FaUserEdit,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DateFormats } from "../../../../../../../constants/Date";
import colors from "../../../../../../../styles/colors/colors";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  DividerStyle,
  Extention,
  FlexStyle,
  IconStyle,
  QuestionAnswerText,
  RequestDetailEmergencyContactTable,
  RequestDetails1stRowLeftColumn,
  RequestDetails1stRowLRightColumn,
  RequestDetails2nd3rdRowLeftColumnHeading,
  RequestDetails2ndRowGrid,
  RequestDetails2ndRowLeftColumn,
  RequestDetails2ndRowRightColumn,
  RequestDetails3rdRowLeftColumn,
  RequestDetails3rdRowRightColumn,
  RequestDetails4thRow1stColGrid,
  RequestDetails4thRow2ndColGrid,
  RequestDetails4thRow3RDColGrid,
  RequestDetails4thRowDescription,
  RequestDetails4thRowDescriptionBox,
  RequestDetails4thRowGrid,
  RequestDetailsAD,
  RequestDetailsEMCtable,
  RequestDetailsEMCtablePhone,
  RequestDetailsFeildDescription,
  RequestDetailsFeildDescriptionIcon,
  RequestDetailsFeildsGrid,
  RequestDetailsHeadingGrid,
  RequestDetailsHeadingIcon,
  RequestDetailsOrderDate,
  RequestDetailsOutterGrid,
  RequestDetailsQuestion,
  TableHeadStyle,
} from "../styles/styles";
import { getValue } from "../../../../../../../utils";
import { formatDate } from "../../../../../../../utils/DateTime";
import { Review } from "../../../../../../../models/Patient";
import {
  ReviewDetails,
  ReviewRequestDetailsQuestions,
} from "../../../../../../../constants/Review";
import { ReviewResponse } from "../../../../../../../models/Api/Review";

export interface PropsFromState {
  requestDetail: ReviewResponse;
}

export interface PropsFromDispatch {
  getReview: (referralId: number) => void;
}

type RequestDetailsProps = PropsFromState & PropsFromDispatch;

interface Props {
  color: string;
  Description: string | number | boolean;
  extention?: string | null;
  heading: string;
  Icon?: React.ElementType;
  isExtention?: boolean | undefined;
  isIcon?: any;
}

const ReviewFeilds: React.FC<Props> = ({
  color,
  Description,
  extention,
  heading,
  Icon,
  isExtention,
  isIcon,
}: Props) => {
  return (
    <Grid item xs="auto" sx={RequestDetailsFeildsGrid}>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
      >
        {heading}
      </Typography>
      <Box sx={RequestDetailsFeildDescription(isIcon)}>
        <Box sx={RequestDetailsFeildDescriptionIcon(isIcon)}>
          {Description && Icon && <Icon size={16} />}
        </Box>
        <Box sx={FlexStyle}>
          <Typography
            variant={heading === ReviewDetails.NAME ? "h6" : "subtitle2"}
            fontWeight={fontWeight.Weight[5]}
            color={color}
          >
            {Description}
          </Typography>
          {extention && (
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={color}
              sx={Extention(isExtention)}
            >
              ext. {extention}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const tableHeadTitle = (title: string) => {
  return (
    <TableCell>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
        pl={"0.5rem"}
      >
        {title}
      </Typography>
    </TableCell>
  );
};

const ReviewRequestDetails: React.FC<RequestDetailsProps> = ({
  requestDetail,
  getReview,
}: RequestDetailsProps) => {
  const [requestDetails, setRequestDetails] = useState<Review>();
  const { loading, response } = requestDetail;
  const { referralId } = useParams();

  useEffect(() => {
    if (!loading && referralId) {
      getReview(parseInt(referralId));
    }
  }, [referralId]);
  useEffect(() => {
    if (response) {
      setRequestDetails(response);
    }
  }, [response]);

  return (
    <Grid container sx={RequestDetailsOutterGrid}>
      <Grid container sx={RequestDetailsHeadingGrid}>
        <Grid item xs={12} pl={"0.5rem"}>
          <Typography
            variant="h6"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[20]}
          >
            REQUEST DETAILS:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={DividerStyle} light />
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={RequestDetails2ndRowGrid}>
        <Grid item xs={7.5} sx={RequestDetails1stRowLeftColumn}>
          <ReviewFeilds
            heading="Date Request Received:"
            Description={
              requestDetails && requestDetails.referralDetail.receivedDateTime
                ? formatDate(
                    requestDetails.referralDetail.receivedDateTime,
                    DateFormats.MM_DD_YYYY
                  )
                : ""
            }
            Icon={FaRegCalendarCheck}
            isIcon={true}
            color={colors.fonts[25]}
          />
          <ReviewFeilds
            heading="Time Request Received:"
            Description={
              requestDetails && requestDetails.referralDetail.receivedDateTime
                ? formatDate(
                    requestDetails.referralDetail.receivedDateTime,
                    DateFormats.TIME_HH_MM_SS
                  )
                : ""
            }
            Icon={FaRegClock}
            color={colors.fonts[25]}
            isIcon={true}
          />
          <ReviewFeilds
            heading="Urgency Status:"
            Description={getValue(
              requestDetails,
              "referralDetail.urgencyStatus",
              ""
            )}
            color={
              requestDetails &&
              requestDetails.referralDetail.urgencyStatus === "Standard"
                ? colors.fonts[25]
                : colors.red[102]
            }
          />
          <ReviewFeilds
            heading="Authorization Type:"
            Description={getValue(
              requestDetails,
              "referralDetail.authorizationTypeName",
              ""
            )}
            color={colors.fonts[25]}
          />
        </Grid>
        <Grid item xs={4} sx={RequestDetails1stRowLRightColumn}>
          <ReviewFeilds
            heading="Received Methods"
            Description={getValue(
              requestDetails,
              "referralDetail.receivedMethodName",
              ""
            )}
            color={colors.fonts[25]}
          />
        </Grid>
      </Grid>
      <Grid container sx={RequestDetails2ndRowGrid} pb={"0.5rem"}>
        <Grid item xs={6.1} sx={RequestDetails2ndRowLeftColumn}>
          <Box sx={RequestDetails2nd3rdRowLeftColumnHeading}>
            <Box sx={RequestDetailsHeadingIcon}>
              <FaUserClock color={colors.fonts[18]} size="24" />
            </Box>
            <Typography
              variant="subtitle1"
              color={colors.fonts[20]}
              fontWeight={fontWeight.Weight[5]}
            >
              EMERGENCY CONTACT DETAILS:
            </Typography>
          </Box>
          <Box sx={RequestDetailsEMCtable}>
            <TableContainer component={Grid}>
              <Table
                sx={RequestDetailEmergencyContactTable}
                aria-label="simple table"
              >
                <TableHead sx={TableHeadStyle}>
                  <TableRow>
                    {tableHeadTitle("Emergency Contact Name:")}
                    {tableHeadTitle(" Relationship:")}
                    {tableHeadTitle(" Phone:")}
                    {tableHeadTitle(" Member Preferred Language:")}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestDetails &&
                    requestDetails.emergencyContact.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ width: "25%" }}>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.fonts[25]}
                            pl={"0.5rem"}
                          >
                            {data.contactName}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "16%" }}>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.fonts[25]}
                            pl={"0.5rem"}
                          >
                            {data.relationshipName}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "29%", minWidth: "6.5rem" }}>
                          <Box sx={RequestDetailsEMCtablePhone}>
                            <Box sx={{ paddingRight: "0.3rem" }}>
                              <FaPhone color={colors.fonts[18]} size={16} />
                            </Box>
                            <Typography
                              variant="subtitle2"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[25]}
                              pl={"0.3rem"}
                            >
                              {data.phoneNo} &nbsp; ext.{data.extension}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: "27%" }}>
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.fonts[25]}
                            pl={"0.5rem"}
                          >
                            {data.preferredLanguage}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={5.9} sx={RequestDetails2ndRowRightColumn}>
          <Box sx={RequestDetails2nd3rdRowLeftColumnHeading}>
            <Box sx={RequestDetailsHeadingIcon}>
              <FaMapMarkerAlt color={colors.fonts[18]} size="22" />
            </Box>
            <Typography
              variant="subtitle1"
              color={colors.fonts[20]}
              fontWeight={fontWeight.Weight[5]}
            >
              ADDRESS DETAILS:
            </Typography>
          </Box>
          <Grid container sx={RequestDetailsAD}>
            <ReviewFeilds
              heading="Street Name:"
              Description={getValue(requestDetails, "address.streetName1", "")}
              color={colors.fonts[25]}
            />
            <ReviewFeilds
              heading="County:"
              Description={getValue(requestDetails, "address.county", "")}
              color={colors.fonts[25]}
            />
            <ReviewFeilds
              heading="City:"
              Description={getValue(requestDetails, "address.city", "")}
              color={colors.fonts[25]}
            />
            <ReviewFeilds
              heading="State:"
              Description={getValue(requestDetails, "address.patientState", "")}
              color={colors.fonts[25]}
            />
            <ReviewFeilds
              heading="Zipcode:"
              Description={getValue(requestDetails, "address.zipCode", "")}
              color={colors.fonts[25]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={RequestDetails2ndRowGrid}>
        <Grid item xs={6.1} sx={RequestDetails3rdRowLeftColumn}>
          <Grid container>
            <Grid item xs={12} sx={RequestDetails2nd3rdRowLeftColumnHeading}>
              <Box sx={RequestDetailsHeadingIcon}>
                <FaUserEdit color={colors.fonts[18]} size="25" />
              </Box>
              <Typography
                variant="subtitle1"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
              >
                REQUESTOR:
              </Typography>
            </Grid>
            <Grid item xs={12} sx={RequestDetailsAD}>
              <ReviewFeilds
                heading="Type: "
                Description={getValue(
                  requestDetails,
                  "requester.facilityTypeName",
                  ""
                )}
                color={colors.fonts[25]}
              />
              <ReviewFeilds
                heading="Name:"
                Description={getValue(
                  requestDetails,
                  "requester.requesterName",
                  ""
                )}
                color={colors.fonts[25]}
              />
              <ReviewFeilds
                heading="Phone:"
                Description={getValue(
                  requestDetails,
                  "requester.requesterPhoneNo",
                  ""
                )}
                color={colors.fonts[25]}
                Icon={FaPhone}
                isIcon={true}
                isExtention={true}
                extention={getValue(
                  requestDetails,
                  "requester.requesterExtension",
                  ""
                )}
              />
              <ReviewFeilds
                heading="Fax No:"
                Description={getValue(
                  requestDetails,
                  "requester.requesterFax",
                  ""
                )}
                color={colors.fonts[25]}
                Icon={FaFax}
                isIcon={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5.9} sx={RequestDetails3rdRowRightColumn}>
          <Grid container>
            <Grid item xs={12} sx={RequestDetails2nd3rdRowLeftColumnHeading}>
              <Box sx={RequestDetailsHeadingIcon}>
                <FaHouseUser color={colors.fonts[18]} size="22" />
              </Box>
              <Typography
                variant="subtitle1"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
              >
                REFERRAL SOURCE:
              </Typography>
            </Grid>
            <Grid item xs={12} sx={RequestDetailsAD}>
              <ReviewFeilds
                heading="Type: "
                Description={getValue(
                  requestDetails,
                  "referralSource.facilityTypeName",
                  ""
                )}
                color={colors.fonts[25]}
              />
              <ReviewFeilds
                heading="Name:"
                Description={getValue(
                  requestDetails,
                  "referralSource.referralSourceName",
                  ""
                )}
                color={colors.fonts[25]}
              />
              <ReviewFeilds
                heading="Phone:"
                Description={getValue(
                  requestDetails,
                  "referralSource.referralSourcePhoneNo",
                  ""
                )}
                color={colors.fonts[25]}
                Icon={FaPhone}
                isIcon={true}
                isExtention={true}
                extention={getValue(
                  requestDetails,
                  "referralSource.referralSourceExtension",
                  ""
                )}
              />
              <ReviewFeilds
                heading="Fax No:"
                Description={getValue(
                  requestDetails,
                  "referralSource.referralSourceFax",
                  null
                )}
                color={colors.fonts[25]}
                Icon={FaFax}
                isIcon={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={RequestDetails4thRowGrid}>
        <Grid item xs={3.5} sx={RequestDetails4thRow1stColGrid}>
          <Box sx={RequestDetailsQuestion}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[4]}
              color={colors.fonts[4]}
            >
              {ReviewRequestDetailsQuestions.HOME_HEALTH_ORDER}
            </Typography>
          </Box>
          <Box sx={RequestDetails4thRowDescriptionBox}>
            <Box>
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[6]}
                color={colors.fonts[20]}
                sx={QuestionAnswerText}
              >
                {getValue(
                  requestDetails,
                  "referralDetail.isSignedHealthOrder",
                  ""
                )}
              </Typography>
            </Box>
            <Box sx={RequestDetails4thRowDescription}>
              <Typography
                sx={RequestDetailsOrderDate}
                variant="subtitle2"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[4]}
              >
                Order Date:
              </Typography>
              {getValue(
                requestDetails,
                "referralDetail.signedOrderDate",
                ""
              ) && <FaRegCalendarCheck size={18} style={IconStyle} />}
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[25]}
              >
                &nbsp;{" "}
                {requestDetails &&
                requestDetails.referralDetail &&
                requestDetails.referralDetail.signedOrderDate
                  ? formatDate(
                      requestDetails.referralDetail.signedOrderDate,
                      DateFormats.MM_DD_YYYY
                    )
                  : ""}
              </Typography>
            </Box>
            <Box></Box>
            <Box></Box>
          </Box>
        </Grid>
        <Grid item xs={4.5} sx={RequestDetails4thRow1stColGrid}>
          <Box sx={RequestDetailsQuestion}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[4]}
              color={colors.fonts[4]}
              pl={"0.5rem"}
            >
              {ReviewRequestDetailsQuestions.CUSTODIAL_CARE}
            </Typography>
          </Box>
          <Box sx={RequestDetails4thRow2ndColGrid}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[6]}
              color={colors.fonts[20]}
              sx={QuestionAnswerText}
            >
              {getValue(requestDetails, "referralDetail.isCustodialCare", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={RequestDetails4thRow3RDColGrid}>
          <Box sx={RequestDetailsQuestion}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[4]}
              color={colors.fonts[4]}
              pl={"0.5rem"}
            >
              {ReviewRequestDetailsQuestions.NON_COVERED_SERVICES}
            </Typography>
          </Box>
          <Box sx={RequestDetails4thRowDescriptionBox}>
            <Box>
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[6]}
                color={colors.fonts[20]}
                sx={QuestionAnswerText}
              >
                {getValue(
                  requestDetails,
                  "referralDetail.isNonCoveredService",
                  ""
                )}
              </Typography>
            </Box>
            <Box sx={RequestDetails4thRowDescription}>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[4]}
              >
                Services:
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[20]}
                pl={"0.4rem"}
              >
                {getValue(
                  requestDetails,
                  "referralDetail.nonCoveredServiceNames",
                  ""
                )}
              </Typography>
            </Box>
            <Box></Box>
            <Box></Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewRequestDetails;

import { Reducer } from "redux";

import { ServiceProviderDataResponse } from "../models/Api/Service";
import { ServiceProviderDetailDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceProviderActionDispatchType } from "../constants/Service";

const initialState: ServiceProviderDataResponse = {
  loading: false,
  response: {
    referralId: -1,
    referralServiceProviderId: 0,
    serviceProviderId: null,
    providerName: "",
    providerNpi: "",
    paymentStructure: "",
    streetName1: null,
    streetName2: null,
    providerCity: null,
    providerCounty: null,
    providerState: null,
    zipCode: null,
    userCreated: null,
    providerId: 0,
    plexisOfficeId: 0,
    phone: "",
    extension: "",
  },
};

const ServiceProviderDataReducer: Reducer<
  ServiceProviderDataResponse,
  ServiceProviderDetailDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ServiceProviderDataReducer;

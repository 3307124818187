import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import axios from "axios";

import { BackdropSection } from "../styles/styles";

function CentralLoader() {
  const [loading, setLoading] = useState(true);

  let numberOfAjaxCAllPending = 0;

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      numberOfAjaxCAllPending++;

      if (!loading) {
        setLoading(true);
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      numberOfAjaxCAllPending--;

      if (numberOfAjaxCAllPending == 0) {
        setLoading(false);
      }
      return response;
    },
    function (error) {
      numberOfAjaxCAllPending--;
      if (numberOfAjaxCAllPending == 0) {
        setLoading(false);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return (
    <Backdrop sx={BackdropSection} open={loading}>
      <CircularProgress />
    </Backdrop>
  );
}

export default CentralLoader;

import { Box, Divider, Grid, Typography } from "@mui/material";
import { FaFax, FaPhone, FaUserEdit } from "react-icons/fa";
import { useEffect, useState } from "react";

import fontWeight from "../../../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../../../styles/colors";
import {
  DividerGrid,
  Extention,
  FieldBox,
  FieldLabel,
  HorizontalDivider,
  RequestDetailsFieldDescription,
  RequestDetailsFieldDescriptionIcon,
  RequestDetailsInnerGrid,
  RequestDetailsOuterGrid,
  RequestDetailsRowGrid,
  SubtitleIcon,
} from "../styles/ReAuthReviewRequest";
import { displayFlex } from "../../../../../../../styles/mui/styles/display";
import { getFormattedPhoneNo } from "../../../../../../../utils";
import { ReAuthReview } from "../../../../../../../models/Patient";
import { ReAuthReviewResponse } from "../../../../../../../models/Api/Review";
import { ReviewRequestorLabel } from "../../../../../../../constants/Review";

export interface PropsFromState {
  reAuthRequestorDetails: ReAuthReviewResponse;
}

type ReAuthReviewRequestProp = PropsFromState;

const ReAuthReviewRequestor: React.FC<ReAuthReviewRequestProp> = ({
  reAuthRequestorDetails,
}: ReAuthReviewRequestProp) => {
  const [requestorDetails, setRequestDetails] = useState<ReAuthReview>();
  const { response } = reAuthRequestorDetails;

  const reauthReferralRequest =
    requestorDetails && requestorDetails.reAuthRequester;

  useEffect(() => {
    if (response) {
      setRequestDetails(response);
    }
  }, [response]);

  return (
    <Grid container sx={RequestDetailsOuterGrid}>
      <Grid item xs={12} sx={RequestDetailsInnerGrid}>
        <Typography
          variant="h6"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[20]}
        >
          REQUEST DETAILS:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={DividerGrid}>
        <Divider light sx={HorizontalDivider} />
      </Grid>
      <Grid item xs={12} sx={RequestDetailsInnerGrid}>
        <Box sx={displayFlex}>
          <Box sx={SubtitleIcon}>
            <FaUserEdit color={colors.primary.main} size="24" />
          </Box>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
            pt={"0.5rem"}
          >
            REQUESTOR:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sx={RequestDetailsRowGrid}>
        <ReviewField
          label={ReviewRequestorLabel.TYPE}
          value={getValue(
            requestorDetails,
            reauthReferralRequest && reauthReferralRequest.facilityTypeName
          )}
          color={colors.fonts[25]}
        />
        <ReviewField
          label={ReviewRequestorLabel.NAME}
          value={getValue(
            requestorDetails,
            reauthReferralRequest && reauthReferralRequest.requesterName
          )}
          color={colors.fonts[25]}
        />
        <ReviewField
          label={ReviewRequestorLabel.PHONE}
          value={getValue(
            requestorDetails,
            reauthReferralRequest &&
            reauthReferralRequest.requesterPhoneNo &&
            getFormattedPhoneNo(reauthReferralRequest.requesterPhoneNo)
          )}
          color={colors.fonts[25]}
          Icon={FaPhone}
          isIcon={true}
          isExtention={true}
          extention={getValue(
            requestorDetails,
            reauthReferralRequest && reauthReferralRequest.requesterExtension
          )}
        />
        <ReviewField
          label={ReviewRequestorLabel.FAX_NO}
          value={getValue(
            requestorDetails,
            reauthReferralRequest &&
            reauthReferralRequest.requesterFax &&
            getFormattedPhoneNo(reauthReferralRequest.requesterFax)
          )}
          color={colors.fonts[25]}
          Icon={FaFax}
          isIcon={true}
        />
      </Grid>
    </Grid>
  );
};

const getValue = (object: ReAuthReview | undefined, entity: any) => {
  return object && entity ? entity : "";
};

interface Props {
  Icon?: React.ElementType;
  value: string | number | boolean;
  label: string;
  isIcon?: any;
  isExtention?: boolean | undefined;
  extention?: string | null;
  color: string;
}

const ReviewField: React.FC<Props> = ({
  Icon,
  value,
  label,
  isIcon,
  isExtention,
  extention,
  color,
}: Props) => {
  return (
    <Box sx={FieldBox}>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
        sx={FieldLabel}
      >
        {label}:
      </Typography>
      <Box sx={RequestDetailsFieldDescription}>
        <Box sx={RequestDetailsFieldDescriptionIcon(isIcon)}>
          {value && Icon && <Icon size={17} />}
        </Box>
        <Box sx={displayFlex}>
          <Typography
            variant={label === ReviewRequestorLabel.NAME ? "h6" : "subtitle2"}
            fontWeight={fontWeight.Weight[5]}
            color={color}
          >
            {value}
          </Typography>
          {extention && (
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={color}
              sx={Extention(isExtention)}
            >
              ext. {extention}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReAuthReviewRequestor;

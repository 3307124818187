import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  HistoryBox,
  HistoryTab,
  HistoryTabsBox,
  PanelBox,
  TabsPanelSectionBox,
  titleBox,
} from "../styles/HistoryTabs";
import { a11yProps } from "../../../../../utils";
import { colors } from "../../../../../styles/colors";
import { HistoryHeaderTabs } from "../../../../../constants/AllPatientRecord";
import { width100PCT } from "../../../../../styles/mui/styles/widths";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthorizationHistoryResponse,
  EligibilityHistoryResponse,
} from "../../../../../models/Api/AuthorizationHistory";
import AuthorizationHistory from "./AuthorizationHistory";
import EligibilityHistory from "../containers/EligibilityHistory";
import { AuthAndEligibilityHistoryPayload } from "../../../../../models/AuthorizationHistory";
import {
  AuthorizationHistoryActionDispatchTypes,
  EligibilityHistoryActionDispatchTypes,
} from "../../../../../constants/AuthorizationHistory";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import { PermissionContext } from "../../../../../App/components";
import { defaultHistoryObject } from "../../../../../constants/Patient";

export interface PropsFromState {
  authorizationHistory: AuthorizationHistoryResponse;
  eligibilityHistoryResp: EligibilityHistoryResponse;
}

export interface PropsFromDispatch {
  getAuthorizationHistoryRecord: (
    payload: AuthAndEligibilityHistoryPayload
  ) => void;
  resetStates: (actionType: string[]) => void;
  setTabNumber: (value: any) => void;
}

type Props = PropsFromState & PropsFromDispatch;

const HistoryTabs: React.FC<Props> = ({
  authorizationHistory,
  getAuthorizationHistoryRecord,
  resetStates,
  setTabNumber,
}: Props) => {
  const { patientId } = useParams();

  const { response: authorizationHistoryResponse } = authorizationHistory;
  const permission = useContext(PermissionContext);
  const [activeTab, setActiveTab] = useState(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    return () => {
      resetStates([
        AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_RESET,
        EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_RESET,
      ]);
      setTabNumber({ ...defaultHistoryObject, page: pageNumber, tabNumber: 0 });
    };
  }, []);

  useEffect(() => {
    const isAuthorizationHistoryPresent =
      permission.indexOf(PatientRecordsAccess.AUTHORIZATION_HISTORY_READ) >= 0;
    const isEligibilityHistoryPresent =
      permission.indexOf(PatientRecordsAccess.ELIGIBILITY_HISTORY_READ) >= 0;

    if (isAuthorizationHistoryPresent && isEligibilityHistoryPresent) {
      setIsVisible(true);
    } else if (isEligibilityHistoryPresent) {
      setActiveTab(1);
    }
  }, [permission]);

  useEffect(() => {
    setTabNumber({
      ...defaultHistoryObject,
      page: pageNumber,
      tabNumber: activeTab,
    });
  }, [pageNumber, activeTab]);

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setActiveTab(newValue);
    setTabNumber({ ...defaultHistoryObject, page: 1, tabNumber: newValue });
  };

  return (
    <Box sx={HistoryBox}>
      {isVisible && (
        <Typography
          variant={"h4"}
          fontWeight={fontWeight.Weight[5]}
          color={colors.black[3]}
          sx={titleBox}
        >
          HISTORY:
        </Typography>
      )}
      <Box sx={width100PCT}>
        <Permission
          controlId={
            activeTab === 0
              ? `|${PatientRecordsAccess.AUTHORIZATION_HISTORY_READ}|`
              : `|${PatientRecordsAccess.ELIGIBILITY_HISTORY_READ}|`
          }
          passThrough
        >
          {(isVisible: boolean) =>
            isVisible && (
              <HistoryTabsBox
                value={activeTab}
                onChange={handleChange}
                sx={{ marginLeft: "1.5rem" }}
              >
                <HistoryTab
                  {...a11yProps(0)}
                  label={HistoryHeaderTabs[0].label}
                />
                <HistoryTab
                  {...a11yProps(1)}
                  label={HistoryHeaderTabs[1].label}
                />
              </HistoryTabsBox>
            )
          }
        </Permission>
        <Permission
          controlId={`|${PatientRecordsAccess.AUTHORIZATION_HISTORY_READ}|`}
          passThrough
        >
          {(isVisible: boolean) =>
            isVisible && (
              <TabsPanelSection value={activeTab} index={0}>
                <AuthorizationHistory
                  authorizationHistory={authorizationHistoryResponse}
                  getAuthorizationHistoryRecord={getAuthorizationHistoryRecord}
                />
              </TabsPanelSection>
            )
          }
        </Permission>
        <Permission
          controlId={`|${PatientRecordsAccess.ELIGIBILITY_HISTORY_READ}|`}
          passThrough
        >
          {(isVisible: boolean) =>
            isVisible && (
              <TabsPanelSection value={activeTab} index={1}>
                <EligibilityHistory
                  patientId={patientId}
                  setPageNumber={setPageNumber}
                />
              </TabsPanelSection>
            )
          }
        </Permission>
      </Box>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

function TabsPanelSection(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelSectionBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <PanelBox>
          <Box>{children}</Box>
        </PanelBox>
      )}
    </TabsPanelSectionBox>
  );
}

export default HistoryTabs;

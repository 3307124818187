import { Reducer } from "redux";

import { LoggedInUserResponse } from "../models/Api/User";
import { LoggedInUserDetailsDispatchTypes } from "../models/DispatchTypes/User";
import { LoggedInUserDetailsActionDispatchTypes } from "../constants/User";

export const initialState: LoggedInUserResponse = {
  loading: false,
  response: {
    userId: -1,
    firstName: "",
    lastName: "",
    fullName: "",
    userName: "",
    email: "",
    userPhoto: "",
    designation: "",
    gender: "",
    phoneNo: "",
    userAddress: "",
    userStatus: "",
    lastLogin: "",
    isSuspended: false,
    userRoles: [],
    userPhotoBlobId: 0,
    dateCreated: "",
  },
  error: null,
};

const LoggedInUserReducer: Reducer<
  LoggedInUserResponse,
  LoggedInUserDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case LoggedInUserDetailsActionDispatchTypes.LOGIN_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoggedInUserDetailsActionDispatchTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    case LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case LoggedInUserDetailsActionDispatchTypes.PUT_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default LoggedInUserReducer;

import { Reducer } from "redux";

import { PatientInformationViewResponse } from "../models/Api/PatientInformation";
import { PatientInformationDispatchType } from "../models/DispatchTypes/PatientInformation";
import { PatientInformationActionDispatchTypesView } from "../constants/PatientInformation";
import { PatientHeaderDefaultValues } from "../constants/Patient";

const initialState: PatientInformationViewResponse = {
  loading: false,
  response: PatientHeaderDefaultValues,
};

const PatientInformationViewReducer: Reducer<
  PatientInformationViewResponse,
  PatientInformationDispatchType
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET: {
      return initialState;
    }
  }
  return state;
};

export default PatientInformationViewReducer;

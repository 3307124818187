import { Reducer } from "redux";

import { NotifyInternalDataResponse } from "../models/Api/PatientInformation";
import { NotifyInternalDataUpdateDispatchTypes } from "../models/DispatchTypes/Patient";
import { NotifyInternalDataUpdateActionDispatchTypes } from "../constants/Patient";

const initialState: NotifyInternalDataResponse = {
  loading: false,
  response: {
    type: "",
    value: "",
  },
};

const NotifyInternalDataValuesReducer: Reducer<
  NotifyInternalDataResponse,
  NotifyInternalDataUpdateDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case NotifyInternalDataUpdateActionDispatchTypes.NOTIFY_INTERNAL_DATA_UPDATE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case NotifyInternalDataUpdateActionDispatchTypes.NOTIFY_INTERNAL_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case NotifyInternalDataUpdateActionDispatchTypes.NOTIFY_INTERNAL_DATA_UPDATE_RESET: {
      return initialState;
    }
  }
  return state;
};

export default NotifyInternalDataValuesReducer;

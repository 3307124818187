import { AuthAndEligibilityHistoryPayload, EligibilityHistoryPayload } from "../models/AuthorizationHistory";
import { GetQueryString } from "../utils";
import API from "./api";

export const GetAuthorizationHistory = (
  configUrl: any,
  payload: AuthAndEligibilityHistoryPayload
) =>
  API.get(
    `${configUrl}api/referral/AuthorizationHistory/${payload.patientId
    }?${GetQueryString(payload)}`
  );

export const GetEligibilityHistory = (
  configUrl: any,
  payload: EligibilityHistoryPayload
) =>
  API.get(
    `${configUrl}api/referral/EligibilityHistory/${payload.patientId
    }?${GetQueryString(payload)}`
  );

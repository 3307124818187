import { SyntheticEvent, useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { matchSorter } from "match-sorter";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
} from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import {
  CreateReferralButton,
  GridContainer,
  InfoIcon,
  infoReceivedBox,
  InfoText,
  ItemGrid,
} from "../styles/CreateReferral";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { colors } from "../../../../../styles/colors";
import { PatientContainerBox } from "../styles/patientDetailsStyle";
import fontWeight from "../../../../../styles/mui/fontWeight";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { rules } from "../../../../../utils/validation/Validation";
import {
  AutoComplete,
  CreatedByWidth,
  DataTextField,
  FieldGrid,
  Search,
} from "../styles/AddEligibilityDetails";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { eligibilityStatus } from "../../../../../constants/Patient";
import {
  BenefitPlanDetails,
  HealthPlanMaster,
} from "../../../../../models/Master";
import {
  BenefitPlanInitialData,
  HealthPlanInitialData,
} from "../../../../../constants/PatientList";
import { getListResult, getValue } from "../../../../../utils";
import useMenuItem from "../../../../../hooks/useMenuItem";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { FaSearchIcon } from "../../../../../components/formComponents/styles/style";
import { BenefitPlan, HealthPlan } from "../../../../../models/Patient";
import { EligibilityInformation } from "../../../../../constants/PatientInformation";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface PropsFromState {
  healthPlanMaster: HealthPlanMaster[];
  benefitPlanDetails: BenefitPlanDetails[];
}
export interface PropsFromDispatch {
  getHealthPlanMasterResp: () => void;
  getBenefitPlanDetailsMasterResp: (healthPlanId: number) => void;
}
export interface EligibilityDetailsProps {
  control: Control<any, any>;
  onSubmit: () => void;
  setCreateRecordStatus: (status: string) => void;
  clearErrors: UseFormClearErrors<any>;
  setError: UseFormSetError<any>;
  getValues: UseFormGetValues<any>;
  reset: UseFormReset<any>;
  isUpdateManually?: boolean;
  isReAuth?: boolean;
}

type AllProps = EligibilityDetailsProps & PropsFromState & PropsFromDispatch;

const AddEligibilityDetails: React.FC<AllProps> = ({
  control,
  healthPlanMaster,
  getHealthPlanMasterResp,
  benefitPlanDetails,
  getBenefitPlanDetailsMasterResp,
  onSubmit,
  setCreateRecordStatus,
  clearErrors,
  getValues,
  reset,
  isUpdateManually,
  isReAuth,
}: AllProps) => {
  const [healthPlanList, setHealthPlanList] = useState<HealthPlan[]>([
    HealthPlanInitialData,
  ]);
  const [healthPlan, setHealthPlan] = useState<HealthPlan>(
    HealthPlanInitialData
  );
  const [benefitPlanList, setBenefitPlanList] = useState<BenefitPlan[]>([
    BenefitPlanInitialData,
  ]);
  const [benefitPlan, setBenefitPlan] = useState<BenefitPlan>(
    BenefitPlanInitialData
  );

  const minDate = getValues("informationReceived.effectiveDate");

  const maxDate = isEmpty(getValues("informationReceived.effectiveThrough"))
    ? dayjs()
    : getValues("informationReceived.effectiveThrough");

  const {
    open: healthPlanDropdownOpen,
    onOpen: healthPlanDropdownOnOpen,
    onClose: healthPlanDropdownOnClose,
  } = useMenuItem();
  const {
    open: benefitPlanDropdownOpen,
    onOpen: benefitPlanDropdownOnOpen,
    onClose: benefitPlanDropdownOnClose,
  } = useMenuItem();

  const header = (header: string) => {
    return (
      <Box sx={displayFlex}>
        <Typography
          variant="body2"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
        >
          {header}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.red[100]}
          pl={"0.4rem"}
        >
          *
        </Typography>
      </Box>
    );
  };

  const onHealthPlanSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: HealthPlan
  ) => {
    healthPlanDropdownOnClose();
    setHealthPlan(value);
    reset(
      {
        ...getValues(),
        informationReceived: {
          ...getValues("informationReceived"),
          healthPlan: value.label,
          benefitPlanName: "",
          product: "",
          healthPlanState: "",
          healthPlanUid: "",
          benefitPlanUid: "",
        },
      },
      { keepErrors: true }
    );

    setBenefitPlan(BenefitPlanInitialData);
    const healthPlanId = getValue(value, "healthPlanId");
    getBenefitPlanDetailsMasterResp(healthPlanId);
    setBenefitPlanList([BenefitPlanInitialData]);
    clearErrors("informationReceived.healthPlan");
  };

  const onBenefitPlanSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: BenefitPlan
  ) => {
    benefitPlanDropdownOnClose();
    setBenefitPlan(value);
    reset(
      {
        ...getValues(),
        informationReceived: {
          ...getValues("informationReceived"),
          benefitPlanName: value.benefitPlanName,
          product: getValue(value, "product"),
          healthPlanState: getValue(value, "healthPlanState"),
          healthPlanUid: getValue(value, "healthPlanUid"),
          benefitPlanUid: getValue(value, "benefitPlanUid"),
        },
      },
      { keepErrors: true }
    );

    clearErrors("informationReceived.benefitPlanName");
    clearErrors("informationReceived.product");
    clearErrors("informationReceived.healthPlanState");
  };

  const handleCreateMember = (isCreateReferral: boolean) => {
    onSubmit();
    if (isCreateReferral) {
      setCreateRecordStatus("createPatient");
    } else {
      setCreateRecordStatus("saveAndExit");
    }
  };

  const handleCreateReferral = () => {
    onSubmit();
  };

  useEffect(() => {
    getHealthPlanMasterResp();
  }, []);

  useEffect(() => {
    if (getValue(healthPlanMaster, "[0].healthPlanId") > 0) {
      setHealthPlanList(getListResult(healthPlanMaster, true));
    }
  }, [healthPlanMaster]);

  useEffect(() => {
    if (getValue(benefitPlanDetails, "[0].benefitPlanId") > 0) {
      const benefitPlanData = getListResult(benefitPlanDetails, true);
      setBenefitPlanList(benefitPlanData);
    }
  }, [benefitPlanDetails]);

  return (
    <>
      <PatientContainerBox sx={displayFlex}>
        <Grid container sx={GridContainer}>
          <Grid item xs={12}>
            <Grid container pl={"0.8rem"}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color={colors.fonts[20]}
                  fontWeight={fontWeight.Weight[5]}
                >
                  {isUpdateManually
                    ? EligibilityInformation.UPDATE
                    : EligibilityInformation.ADD}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={displayFlex}>
              <Grid container sx={ItemGrid}>
                <Grid item xs={3} pt={"1.5rem"}>
                  {header("Health Plan:")}
                  <Controller
                    defaultValue={""}
                    render={({ fieldState: { error } }) => {
                      return (
                        <>
                          <Autocomplete
                            sx={AutoComplete}
                            options={healthPlanList}
                            getOptionLabel={(option: any) => option.label}
                            open={healthPlanDropdownOpen}
                            onOpen={() => healthPlanDropdownOnOpen()}
                            onClose={() => healthPlanDropdownOnClose()}
                            onBlur={() => {
                              healthPlanDropdownOnClose();
                            }}
                            onFocus={() => {
                              healthPlanDropdownOnOpen();
                            }}
                            PopperComponent={StyledPopperCheckBoxAutocomplete}
                            id="auto-complete"
                            autoComplete
                            disableClearable
                            clearIcon={""}
                            includeInputInList
                            value={healthPlan}
                            onChange={onHealthPlanSelection}
                            filterOptions={(options: any, { inputValue }) =>
                              matchSorter(options, inputValue, {
                                keys: ["label"],
                              })
                            }
                            noOptionsText={
                              <Typography
                                variant="subtitle2"
                                fontWeight={fontWeight.Weight[3]}
                                color={colors.fonts[25]}
                              >
                                {NO_RECORD_FOUND}
                              </Typography>
                            }
                            renderInput={(params) => {
                              params.inputProps.maxLength = 50;
                              return (
                                <Box sx={Search}>
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Choose Health Plan"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position="start">
                                            <FaSearchIcon />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                </Box>
                              );
                            }}
                            renderOption={(props, option) => {
                              return (
                                <Typography
                                  {...props}
                                  variant="subtitle2"
                                  fontWeight={fontWeight.Weight[3]}
                                  color={colors.fonts[25]}
                                  pb={"0.2rem"}
                                >
                                  {option.label}
                                </Typography>
                              );
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      );
                    }}
                    name="informationReceived.healthPlan"
                    control={control}
                    rules={rules.healthPlan}
                  />
                </Grid>
                <Grid item xs={3} pt={"1.5rem"}>
                  {header("Benefit Plan:")}
                  <Controller
                    defaultValue={""}
                    render={({ fieldState: { error } }) => {
                      return (
                        <>
                          <Autocomplete
                            sx={AutoComplete}
                            options={benefitPlanList}
                            getOptionLabel={(option: any) => option.label}
                            open={
                              healthPlan.label !== "" && benefitPlanDropdownOpen
                            }
                            onOpen={() => benefitPlanDropdownOnOpen()}
                            onClose={() => benefitPlanDropdownOnClose()}
                            onBlur={() => {
                              benefitPlanDropdownOnClose &&
                                benefitPlanDropdownOnClose();
                            }}
                            onFocus={() => {
                              benefitPlanDropdownOnOpen &&
                                benefitPlanDropdownOnOpen();
                            }}
                            PopperComponent={StyledPopperCheckBoxAutocomplete}
                            id="auto-complete"
                            autoComplete
                            disableClearable
                            clearIcon={""}
                            includeInputInList
                            value={benefitPlan}
                            onChange={onBenefitPlanSelection}
                            filterOptions={(options: any, { inputValue }) =>
                              matchSorter(options, inputValue, {
                                keys: ["label"],
                              })
                            }
                            readOnly={healthPlan.label === "" ? true : false}
                            noOptionsText={
                              <Typography
                                variant="subtitle2"
                                fontWeight={fontWeight.Weight[3]}
                                color={colors.fonts[25]}
                              >
                                {NO_RECORD_FOUND}
                              </Typography>
                            }
                            renderInput={(params) => {
                              params.inputProps.maxLength = 50;
                              return (
                                <Box sx={Search}>
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Choose Benefit Plan"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          <InputAdornment position="start">
                                            <FaSearchIcon />
                                          </InputAdornment>
                                          {params.InputProps.startAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                </Box>
                              );
                            }}
                            renderOption={(props, option) => {
                              return (
                                <Typography
                                  {...props}
                                  variant="subtitle2"
                                  fontWeight={fontWeight.Weight[3]}
                                  color={colors.fonts[25]}
                                  pb={"0.2rem"}
                                >
                                  {option.label}
                                </Typography>
                              );
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      );
                    }}
                    name="informationReceived.benefitPlanName"
                    control={control}
                    rules={rules.benefitPlan}
                  />
                </Grid>
                <Grid item xs={2} sx={FieldGrid}>
                  <FormInputText
                    name="informationReceived.product"
                    control={control}
                    helper={rules.product}
                    InputStyle={DataTextField}
                    label="Product:"
                    readOnly
                  />
                </Grid>
                <Grid item xs={1.7} sx={FieldGrid}>
                  <FormInputText
                    name="informationReceived.healthPlanState"
                    control={control}
                    helper={rules.healthPlanState}
                    InputStyle={DataTextField}
                    label="State:"
                    readOnly
                  />
                </Grid>
                <Grid item xs={2} sx={FieldGrid}>
                  <FormInputDropdown
                    name="informationReceived.eligibilityStatus"
                    control={control}
                    list={eligibilityStatus}
                    InputStyle={DataTextField}
                    label="Status:"
                    helper={rules.status}
                  />
                </Grid>
                <Grid item xs={2.5} sx={FieldGrid}>
                  <FormControl variant="standard" fullWidth sx={CreatedByWidth}>
                    <DatePicker
                      name="informationReceived.effectiveDate"
                      control={control}
                      helper={rules.referralReceivedDate}
                      InputStyle={DataTextField}
                      label="Effective From:"
                      maximumdate={maxDate}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.5} sx={FieldGrid}>
                  <FormControl variant="standard" fullWidth sx={CreatedByWidth}>
                    <DatePicker
                      name="informationReceived.effectiveThrough"
                      control={control}
                      helper={rules.referralReceivedDate}
                      InputStyle={DataTextField}
                      label="Effective Through:"
                      minimumDate={minDate}
                      allowFutureDate={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.3} sx={FieldGrid}>
                  <FormInputText
                    name="informationReceived.subscriberId"
                    control={control}
                    helper={rules.infoSubscriberId}
                    InputStyle={DataTextField}
                    label="Subscriber ID:"
                    textLength={15}
                  />
                </Grid>
                <Grid item xs={2.3} sx={FieldGrid}>
                  <FormInputText
                    name="informationReceived.medicareBeneficiaryIdentifier"
                    control={control}
                    helper={rules.mbiNumber}
                    InputStyle={DataTextField}
                    label="MBI Number:"
                    textLength={11}
                  />
                </Grid>
                <Grid item xs={2.3} sx={FieldGrid}>
                  <FormInputText
                    name="informationReceived.ahcccsId"
                    control={control}
                    InputStyle={DataTextField}
                    helper={rules.ahccsNumber}
                    label="AHCCS Number:"
                  />
                </Grid>
              </Grid>
              <Box pt={"2.6rem"}>
                {!isUpdateManually && (
                  <Box sx={CreateReferralButton}>
                    <Button
                      variant="outlined"
                      onClick={() => handleCreateMember(false)}
                    >
                      CREATE MEMBER
                    </Button>
                  </Box>
                )}
                <Box sx={infoReceivedBox}>
                  <Box sx={InfoIcon}>
                    <FaInfoCircle />
                  </Box>
                  <Box>
                    {infoRender(" You may begin intake process based")}
                    {infoRender("on eligibility information:", InfoText)}
                  </Box>
                </Box>
                <Box sx={CreateReferralButton}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      isUpdateManually
                        ? handleCreateReferral()
                        : handleCreateMember(true)
                    }
                  >
                    {isReAuth ? "CREATE RE-AUTH" : "CREATE REFERRAL"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PatientContainerBox>
    </>
  );
};

const infoRender = (label: string, sx?: any) => {
  return (
    <Typography
      variant="body1"
      color={colors.fonts[13]}
      fontWeight={fontWeight.Weight[3]}
      sx={sx}
    >
      {label}
    </Typography>
  );
};

export default AddEligibilityDetails;

export enum MasterAccessActionDispatchTypes {
  MASTER_ACCESS_INVOKE = "MASTER_ACCESS_INVOKE",
  MASTER_ACCESS_SUCCESS = "MASTER_ACCESS_SUCCESS",
}

export enum PayerActionDispatchTypes {
  PAYER_INVOKE = "PAYER_INVOKE",
  PAYER_SUCCESS = "PAYER_SUCCESS",
}

export enum MasterCallOutcomeActionDispatchTypes {
  MASTER_CALLOUTCOME_INVOKE = "MASTER_CALLOUTCOME_INVOKE",
  MASTER_CALLOUTCOME_SUCCESS = "MASTER_CALLOUTCOME_SUCCESS",
}

export enum IcdCodeTypeActionDispatchTypes {
  ICD_CODE_TYPE_INVOKE = "ICD_CODE_TYPE_INVOKE",
  ICD_CODE_TYPE_SUCCESS = "ICD_CODE_TYPE_SUCCESS",
}

export enum DiseaseTypeActionDispatchTypes {
  DISEASE_TYPE_INVOKE = "DISEASE_TYPE_INVOKE",
  DISEASE_TYPE_SUCCESS = "DISEASE_TYPE_SUCCESS",
}

export enum HealthProgramActionDispatchTypes {
  HEALTH_PROGRAM_INVOKE = "HEALTH_PROGRAM_INVOKE",
  HEALTH_PROGRAM_SUCCESS = "HEALTH_PROGRAM_SUCCESS",
}

export enum CareCoordinationProgramActionDispatchTypes {
  CARECORDINATION_PROGRAM_INVOKE = "CARECORDINATION_PROGRAM_INVOKE",
  CARECORDINATION_PROGRAM_SUCCESS = "CARECORDINATION_PROGRAM_SUCCESS",
}

export enum NonCoveredServicesActionDispatchTypes {
  NONCOVERED_SERVICES_INVOKE = "NONCOVERED_SERVICES_INVOKE",
  NONCOVERED_SERVICES_SUCCESS = "NONCOVERED_SERVICES_SUCCESS",
}

export enum OrderingSourceActionDispatchTypes {
  ORDERING_SOURCE_INVOKE = "ORDERING_SOURCE_INVOKE",
  ORDERING_SOURCE_SUCCESS = "ORDERING_SOURCE_SUCCESS",
}

export enum OrderAcceptorActionDispatchTypes {
  ORDER_ACCEPTOR_INVOKE = "ORDER_ACCEPTOR_INVOKE",
  ORDER_ACCEPTOR_SUCCESS = "ORDER_ACCEPTOR_SUCCESS",
}

export enum DisciplinesActionDispatchTypes {
  DISCIPLINES_INVOKE = "DISCIPLINES_INVOKE",
  DISCIPLINES_SUCCESS = "DISCIPLINES_SUCCESS",
  DISCIPLINES_RESET = "DISCIPLINES_RESET",
}

export enum CareTypeActionDispatchTypes {
  CARE_TYPE_INVOKE = "CARE_TYPE_INVOKE",
  CARE_TYPE_SUCCESS = "CARE_TYPE_SUCCESS",
}

export enum PastMedicalHistoryActionDispatchTypes {
  PAST_MEDICAL_HISTORY_INVOKE = "PAST_MEDICAL_HISTORY_INVOKE",
  PAST_MEDICAL_HISTORY_SUCCESS = "PAST_MEDICAL_HISTORY_SUCCESS",
}
export enum PsychologicalHistoryActionDispatchTypes {
  PSYCHOLOGICAL_HISTORY_INVOKE = "PSYCHOLOGICAL_HISTORY_INVOKE",
  PSYCHOLOGICAL_HISTORY_SUCCESS = "PSYCHOLOGICAL_HISTORY_SUCCESS",
}

export enum DocumentActionDispatchTypes {
  MASTER_DOCUMENT_INVOKE = "MASTER_DOCUMENT_INVOKE",
  MASTER_DOCUMENT_SUCCESS = "MASTER_DOCUMENT_SUCCESS",
}

export enum AuthorizationStatusActionDispatchTypes {
  AUTHORIZATION_STATUS_INVOKE = "AUTHORIZATION_STATUS_INVOKE",
  AUTHORIZATION_STATUS_SUCCESS = "AUTHORIZATION_STATUS_SUCCESS",
}

export enum PriorAuthReferralSourceActionDispatchTypes {
  PRIOR_AUTH_REFERRAL_SOURCE_INVOKE = "PRIOR_AUTH_REFERRAL_SOURCE_INVOKE",
  PRIOR_AUTH_REFERRAL_SOURCE_SUCCESS = "PRIOR_AUTH_REFERRAL_SOURCE_SUCCESS",
}

export enum ReAuthReferralSourceActionDispatchTypes {
  RE_AUTH_REFERRAL_SOURCE_INVOKE = "RE_AUTH_REFERRAL_SOURCE_INVOKE",
  RE_AUTH_REFERRAL_SOURCE_SUCCESS = "RE_AUTH_REFERRAL_SOURCE_SUCCESS",
}

export enum StaffingStatusActionDispatchTypes {
  STAFFING_STATUS_INVOKE = "STAFFING_STATUS_INVOKE",
  STAFFING_STATUS_SUCCESS = "STAFFING_STATUS_SUCCESS",
}

export enum CareGiverActionDispatchTypes {
  CARE_GIVER_INVOKE = "CARE_GIVER_INVOKE",
  CARE_GIVER_SUCCESS = "CARE_GIVER_SUCCESS",
}

export enum DischargeReasonActionDispatchTypes {
  DISCHARGE_REASON_INVOKE = "DISCHARGE_REASON_INVOKE",
  DISCHARGE_REASON_SUCCESS = "DISCHARGE_REASON_SUCCESS",
}
export enum DocumentTypeActionDispatchTypes {
  DOCUMENT_TYPE_INVOKE = "DOCUMENT_TYPE_INVOKE",
  DOCUMENT_TYPE_SUCCESS = "DOCUMENT_TYPE_SUCCESS",
}

export enum DocumentSourceActionDispatchTypes {
  DOCUMENT_SOURCE_INVOKE = "DOCUMENT_SOURCE_INVOKE",
  DOCUMENT_SOURCE_SUCCESS = "DOCUMENT_SOURCE_SUCCESS",
}

export enum CreatedByActionDispatchTypes {
  CREATED_BY_INVOKE = "CREATED_BY_INVOKE",
  CREATED_BY_SUCCESS = "CREATED_BY_SUCCESS",
}

export enum ReferralServiceStatusActionDispatchTypes {
  REFERRAL_SERVICE_STATUS_INVOKE = "REFERRAL_SERVICE_STATUS_INVOKE",
  REFERRAL_SERVICE_STATUS_SUCCESS = "REFERRAL_SERVICE_STATUS_SUCCESS",
}

export enum ServiceStatusReasonActionDispatchTypes {
  SERVICE_REASON_STATUS_INVOKE = "SERVICE_REASON_STATUS_INVOKE",
  SERVICE_REASON_STATUS_SUCCESS = "SERVICE_REASON_STATUS_SUCCESS",
}

export enum DisciplineTypeActionDispatchTypes {
  DISCIPLINE_TYPE_INVOKE = "DISCIPLINE_TYPE_INVOKE",
  DISCIPLINE_TYPE_SUCCESS = "DISCIPLINE_TYPE_SUCCESS",
}

export enum ServiceStatusActionDispatchTypes {
  SERVICE_STATUS_INVOKE = "SERVICE_STATUS_INVOKE",
  SERVICE_STATUS_SUCCESS = "SERVICE_STATUS_SUCCESS",
}

export enum DisciplineTemplateActionDispatchTypes {
  DISCIPLINE_TEMPLATE_INVOKE = "DISCIPLINE_TEMPLATE_INVOKE",
  DISCIPLINE_TEMPLATE_SUCCESS = "DISCIPLINE_TEMPLATE_SUCCESS",
}

export enum DisciplineRequestorActionDispatchTypes {
  DISCIPLINE_REQUESTOR_INVOKE = "DISCIPLINE_REQUESTOR_INVOKE",
  DISCIPLINE_REQUESTOR_SUCCESS = "DISCIPLINE_REQUESTOR_SUCCESS",
}

export enum ClinicalGroupActionDispatchTypes {
  CLINICAL_GROUP_INVOKE = "CLINICAL_GROUP_INVOKE",
  CLINICAL_GROUP_SUCCESS = "CLINICAL_GROUP_SUCCESS",
}
export enum EligibilityRecordActionDispatchTypes {
  ELIGIBILITY_RECORD_ERROR = "ELIGIBILITY_RECORD_ERROR",
  ELIGIBILITY_RECORD_INVOKE = "ELIGIBILITY_RECORD_INVOKE",
  ELIGIBILITY_RECORD_SUCCESS = "ELIGIBILITY_RECORD_SUCCESS",
  ELIGIBILITY_RECORD_RESET = "ELIGIBILITY_RECORD_RESET",
}

export enum NotesApprovalStatusMasterActionDispatchTypes {
  NOTES_APPROVAL_STATUS_MASTER_INVOKE = "NOTES_APPROVAL_STATUS_MASTER_INVOKE",
  NOTES_APPROVAL_STATUS_MASTER_SUCCESS = "NOTES_APPROVAL_STATUS_MASTER_SUCCESS",
}

export enum ServiceRequestType {
  AUTH_ID = 1,
  RE_AUTH_ID = 2,
}

export enum HealthPlanActionDispatchTypes {
  HEALTH_PLAN_MASTER_INVOKE = "HEALTH_PLAN_MASTER_INVOKE",
  HEALTH_PLAN_MASTER_SUCCESS = "HEALTH_PLAN_MASTER_SUCCESS",
}

export const FilterByState = [
  {
    label: "",
    value: "",
  },
];

export enum BenefitPlanDetailsMasterActionDispatchTypes {
  BENEFIT_PLAN_DETAILS_MASTER_INVOKE = "BENEFIT_PLAN_DETAILS_MASTER_INVOKE",
  BENEFIT_PLAN_DETAILS_MASTER_SUCCESS = "BENEFIT_PLAN_DETAILS_MASTER_SUCCESS",
}

export enum AllServiceStatusReasonMasterActionDispatchTypes {
  ALL_SERVICE_STATUS_REASON_INVOKE = "ALL_SERVICE_STATUS_REASON_INVOKE",
  ALL_SERVICE_STATUS_REASON_SUCCESS = "ALL_SERVICE_STATUS_REASON_SUCCESS",
}

export enum LanguageMasterActionDispatchTypes {
  LANGUAGE_MASTER_INVOKE = "LANGUAGE_MASTER_INVOKE",
  LANGUAGE_MASTER_SUCCESS = "LANGUAGE_MASTER_SUCCESS",
}

export enum AccomodationMasterActionDispatchTypes {
  ACCOMODATION_MASTER_INVOKE = "ACCOMODATION_MASTER_INVOKE",
  ACCOMODATION_MASTER_SUCCESS = "ACCOMODATION_MASTER_SUCCESS",
}

export enum AgencyCommunicationMasterActionDispatchTypes {
  GET_AGENCY_COMMUNICATION_TEMPLATE_INVOKE = "GET_AGENCY_COMMUNICATION_TEMPLATE_INVOKE",
  GET_AGENCY_COMMUNICATION_TEMPLATE_SUCCESS = "GET_AGENCY_COMMUNICATION_TEMPLATE_SUCCESS",
}

export const PENDING_SERVICE_STATUS_ID = 1;

export enum NtuReasonMasterActionDispatchTypes {
  GET_NTU_REASON_INVOKE = "GET_NTU_REASON_INVOKE",
  GET_NTU_REASON_SUCCESS = "GET_NTU_REASON_SUCCESS",
}

export enum AuthorizationTypeMasterActionDispatchTypes {
  AUTHORIZATION_TYPE_INVOKE = "AUTHORIZATION_TYPE_INVOKE",
  AUTHORIZATION_TYPE_SUCCESS = "AUTHORIZATION_TYPE_SUCCESS",
}

export enum ServiceStatusValue {
  Pending = 1,
  Approved = 2,
  Partially_Approved = 3,
  Denied = 4,
  Withdrawal = 5,
  Cancel = 6,
}

export enum FaxFormMasterActionDispatchTypes {
  FAX_FORM_MASTER_INVOKE = "FAX_FORM_MASTER_INVOKE",
  FAX_FORM_MASTER_SUCCESS = "FAX_FORM_MASTER_SUCCESS",
}

export enum FaxFormOptionMasterActionDispatchTypes {
  GET_FAX_FORM_OPTION_MASTER_INVOKE = "GET_FAX_FORM_OPTION_MASTER_INVOKE",
  GET_FAX_FORM_OPTION_MASTER_SUCCESS = "GET_FAX_FORM_OPTION_MASTER_SUCCESS",
  GET_FAX_FORM_OPTION_MASTER_RESET = "GET_FAX_FORM_OPTION_MASTER_RESET",
}

export enum LetterCopyTypeMasterActionDispatchTypes {
  GET_LETTER_COPY_TYPE_MASTER_INVOKE = "GET_LETTER_COPY_TYPE_MASTER_INVOKE",
  GET_LETTER_COPY_TYPE_MASTER_SUCCESS = "GET_LETTER_COPY_TYPE_MASTER_SUCCESS",
}

export enum ServiceRequestNumberMasterActionDispatchTypes {
  GET_SERVICE_REQUEST_NUMBER_MASTER_INVOKE = "GET_SERVICE_REQUEST_NUMBER_MASTER_INVOKE",
  GET_SERVICE_REQUEST_NUMBER_MASTER_SUCCESS = "GET_SERVICE_REQUEST_NUMBER_MASTER_SUCCESS",
}

import { Reducer } from "redux";

import { ReferralNoteResponse } from "../models/Api/Notes";
import { GetReferralNoteDispatchTypes } from "../models/DispatchTypes/Notes";
import { ReferralNoteActionDispatchTypes } from "../constants/Notes";

const initialState: ReferralNoteResponse = {
  loading: false,
  response: {
    totalRows: -1,
    referralNoteDetail: [],
  },
};
const ReferralNoteReducer: Reducer<
  ReferralNoteResponse,
  GetReferralNoteDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReferralNoteActionDispatchTypes.GET_REFERRAL_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ReferralNoteReducer;

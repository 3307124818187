import {
  Button,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { ReferralSubmit } from "../../../../../models/Patient";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { AllPatientIntakeTabResponse } from "../../../../../models/Api/AllPatientRecord";

export interface Props {
  handleCloseAlert: () => void;
  isCompleted: boolean;
  referralStage: ReferralSubmit[] | any[];
  currentTab: number;
  tabList: AllPatientIntakeTabResponse;
  setIsCompleted: (value: boolean) => void;
}
const IncompleteDialog = ({
  isCompleted,
  handleCloseAlert,
  referralStage,
  currentTab,
  tabList,
  setIsCompleted,
}: Props) => {
  const handleClose = () => {
    const tab = tabList && tabList.response ? tabList.response.length - 1 : 0;
    if (currentTab < tab) {
      setIsCompleted(true);
    } else {
      handleCloseAlert();
    }
  };
  return (
    <Dialog
      open={!isCompleted}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h6"
          fontWeight={fontWeight.Weight[6]}
          color={colors.fonts[18]}
        >
          Below are the tabs which yet to be submitted.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {referralStage &&
          referralStage.length > 0 &&
          referralStage.map((item, index) => {
            return (
              <DialogContentText
                id="alert-dialog-description"
                key={item.referralStageId}
                mb={"0.5rem"}
              >
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[4]}
                  color={colors.black[2]}
                >
                  {index + 1}. {item.referralStageName || item.reAuthStageName}
                </Typography>
              </DialogContentText>
            );
          })}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end" }}>
        <Button variant="contained" onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncompleteDialog;

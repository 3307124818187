import { ThunkAction } from "redux-thunk";
import { Dispatch, ActionCreator, Action } from "redux";

import { ApplicationState } from "../store/ApplicationState";
import { NotifyInternalDataUpdateDispatchTypes } from "../models/DispatchTypes/Patient";
import { NotifyInternalData } from "../models/Patient";
import { NotifyInternalDataUpdateActionDispatchTypes } from "../constants/Patient";

export const resetStates: ActionCreator<
  ThunkAction<void, ApplicationState, Record<string, never>, Action<any>>
> = (actionType: string[]) => {
  return async (dispatch: Dispatch) => {
    return actionType.map((singleAction) => {
      dispatch({
        type: singleAction,
      });
    });
  };
};

export const notifyInternalDataUpdates: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    NotifyInternalDataUpdateDispatchTypes
  >
> = (value: NotifyInternalData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NotifyInternalDataUpdateActionDispatchTypes.NOTIFY_INTERNAL_DATA_UPDATE_INVOKE,
    });
    dispatch({
      response: value,
      type: NotifyInternalDataUpdateActionDispatchTypes.NOTIFY_INTERNAL_DATA_UPDATE_SUCCESS,
    });
  };
};

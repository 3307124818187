import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";

import {
  PaginationStyle,
  PatientContainerBox,
  TableGrid,
} from "../../styles/style";
import { getPatientFilterPayload, isNil, length } from "../../../../utils";
import { AllPatientDetails, MyPatient } from "../../../../models/Patient";
import {
  AllPendingHeaderLabel,
  MyPatientActionDispatchTypes,
  allPendingData,
  getSortColumn,
} from "../../../../constants/AllPatientRecord";
import {
  ASC,
  DESC,
  PER_PAGE,
} from "../../../../constants/FunctionalUtils";
import { AssignStaffHeaderLabel } from "../../../../constants/AllPatientRecord";
import AssignStaff from "../../containers/AssignStaff";
import TableComponent from "../../../../components/formComponents/TableComponent";
import PaginationItem from "../../../../components/custom/pagination/components/PaginationItem";
import { SearchFilterModel } from "../../../../models/Filter";
import { FilterModel } from "../../../../models/Api/Filter";
export interface PropsFromState {
  activeTab: number;
  fieldValues: any;
  postMyPatientList: (payload: SearchFilterModel) => void;
  handleClear: () => void;
  openModal: boolean;
  errorFlag: boolean;
  patientRecord: MyPatient | null;
  patientSelected: AllPatientDetails[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setPatientSelected: React.Dispatch<React.SetStateAction<AllPatientDetails[]>>;
  setValue: (name: string, value: number | string, boolean: any) => void;
  getValues: UseFormGetValues<any>;
  setPage: (page: number) => void;
  page: number;
  action: string;
  filterState: FilterModel;
  setSelectedFilterByArray: (arr: string[] | any) => void;
  storeFilterPayload: (type: number, payload?: FilterModel) => void;
  valueToOrderBy: string;
  setValueToOrderBy: (valueToOrderBy: string) => void;
  getMyPatientListReset: (actionType: string[]) => void;
}

type Props = PropsFromState;

const AllPendings = ({
  activeTab,
  postMyPatientList,
  handleClear,
  openModal,
  errorFlag,
  patientRecord,
  patientSelected,
  setOpenModal,
  setErrorFlag,
  setPatientSelected,
  setValue,
  getValues,
  setPage,
  page,
  action,
  filterState,
  valueToOrderBy,
  setValueToOrderBy,
  getMyPatientListReset
}: Props) => {
  const [allSelect, setAllSelect] = useState(false);
  const [orderDirection, setOrderDirection] = useState(ASC);
  const [allPendingList, setAllPendingList] = useState(
    patientRecord &&
      patientRecord.searchResponse &&
      length(patientRecord.searchResponse) &&
      patientRecord.searchResponse[0].patientId != 0
      ? patientRecord.searchResponse
      : []
  );
  const [allSelectedPatients, setAllSelectedPatients] = useState<
    AllPatientDetails[]
  >([]);

  const count = Math.ceil(
    patientRecord && patientRecord.totalRows
      ? Number(patientRecord.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );
  /**------------------------------------------------------------------------------------- */

  const handleSort = (property: any) => {
    onAction(false, 1, property);
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param e is mandatory
  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    onAction(true, page);
  };
  const onAction = (isPageChange: boolean, page: number, property?: any) => {
    setPage(isPageChange ? page : 1);
    setValue("pageNo", isPageChange ? page : 1, true);
    if (!isPageChange) {
      const isAsc =
        valueToOrderBy === getSortColumn(property) && orderDirection === ASC;
      setOrderDirection(isAsc ? DESC : ASC);
      setValueToOrderBy(getSortColumn(property));
      setValue("sortColumn", getSortColumn(property), true);
      setValue("sortOrder", isAsc ? DESC : ASC, true);
      postMyPatientList({
        ...getPatientFilterPayload(
          filterState.response.allPending
        ),
        pageNo: getValues("pageNo"),
        sortColumn: getSortColumn(property),
        sortOrder: isAsc ? DESC : ASC,
      });
      return;
    }
    postMyPatientList({
      ...getPatientFilterPayload(
        filterState.response.allPending
      ),
      pageNo: page,
      sortColumn: valueToOrderBy,
      sortOrder: orderDirection,
    });
  };

  /**------------------------------------------------------------------------------------- */

  useEffect(() => {
    const allPendingList =
      patientRecord &&
        patientRecord.searchResponse &&
        length(patientRecord.searchResponse) &&
        patientRecord.searchResponse[0].patientId != 0
        ? patientRecord.searchResponse
        : [];
    setAllPendingList(allPendingList);
  }, [patientRecord]);

  useEffect(() => {
    return () => {
      getMyPatientListReset([
        MyPatientActionDispatchTypes.POST_PATIENTLIST_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    const isPresent =
      allPendingList.length !== 0 &&
      allPendingList.every((val) =>
        allSelectedPatients.some((item) =>
          Object.entries(val).every(
            ([key, value]) => item[key as keyof AllPatientDetails] === value
          )
        )
      );

    if (isPresent === true) {
      setAllSelect(true);
      setPatientSelected(allSelectedPatients);
    } else {
      setAllSelect(false);
      setPatientSelected(allSelectedPatients);
    }
  }, [allSelectedPatients, page, allPendingList, patientSelected]);

  useEffect(() => {
    if (openModal) {
      if (patientSelected.length == 0) {
        setErrorFlag(true);
        setOpenModal(false);
      }
    }
    if (length(patientSelected)) {
      setErrorFlag(false);
    }
  }, [openModal, patientSelected]);

  const handleCheck = (e: any) => {
    if (e.target.value == "all") {
      setAllSelect(!allSelect);
      if (!allSelect) {
        const selectList = allPendingList;
        const isAllSelectedPatients = selectList.every((val) =>
          allSelectedPatients.some((item) =>
            Object.entries(val).every(
              ([key, value]) => item[key as keyof AllPatientDetails] === value
            )
          )
        );
        const filteredArray = selectList.filter(
          (item) =>
            !allSelectedPatients.some(
              (value) =>
                value.serviceRequestDisciplineId ===
                item.serviceRequestDisciplineId
            )
        );

        if (isAllSelectedPatients === false) {
          setAllSelectedPatients([...allSelectedPatients, ...filteredArray]);
        }
        setPatientSelected(selectList);
        return;
      }
      const uncheckArray = allSelectedPatients.filter(
        (items) =>
          !allPendingList.some(
            (val) =>
              val.serviceRequestDisciplineId ===
              items.serviceRequestDisciplineId
          )
      );
      setPatientSelected([]);
      setAllSelectedPatients(uncheckArray);
    } else {
      const patientListOriginal: AllPatientDetails[] = allPendingList;
      const patientList = patientSelected ? patientSelected : [];
      const patientAuthNos =
        patientList &&
        patientList.map((patient) => patient.serviceRequestDisciplineId);
      if (patientAuthNos && patientAuthNos.includes(Number(e.target.value))) {
        const newPatients = patientList
          ? patientList.filter((item) => {
            return item.serviceRequestDisciplineId != e.target.value;
          })
          : [];

        const newSelectedPatients = allSelectedPatients
          ? allSelectedPatients.filter((item) => {
            return item.serviceRequestDisciplineId != e.target.value;
          })
          : [];
        setPatientSelected(newPatients);
        setAllSelectedPatients(newSelectedPatients);
        setAllSelect(false);
      } else {
        const patient = patientListOriginal.filter(
          (item) => item.serviceRequestDisciplineId == e.target.value
        );
        const newArrayWithAddedPatients = [...patientList, patient[0]];
        setPatientSelected(newArrayWithAddedPatients);
        setAllSelectedPatients(newArrayWithAddedPatients);
      }
    }
  };
  const checkPatient = (Id: any) => {
    const patientChecked = patientSelected.filter(
      (patient) => patient.serviceRequestDisciplineId == Id
    );
    if (patientChecked.length == 0) return false;
    else return true;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PatientContainerBox m={"0 1rem"} sx={TableGrid}>
          <TableComponent
            allSelect={allSelect}
            checkPatient={checkPatient}
            handleCheck={handleCheck}
            handleSort={handleSort}
            HeaderLabel={AllPendingHeaderLabel}
            list={allPendingData}
            orderDirection={orderDirection}
            patientList={allPendingList}
            type="allPendings"
            valueToOrderBy={valueToOrderBy}
          />
        </PatientContainerBox>
      </Grid>
      {length(allPendingList) && (
        <Grid item xs={12} sx={PaginationStyle}>
          <PaginationItem
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Grid>
      )}
      {!isNil(patientSelected) && openModal && !errorFlag && (
        <AssignStaff
          action={action}
          activeTab={activeTab}
          handleClear={handleClear}
          openModal={openModal}
          patientList={AssignStaffHeaderLabel}
          selectedList={patientSelected}
          setAllSelect={setAllSelect}
          setOpenModal={setOpenModal}
          setPage={setPage}
          setPatientSelected={setPatientSelected}
          setAllSelectedPatients={setAllSelectedPatients}
          type="pending"
        />
      )}
    </Grid>
  );
};

export default AllPendings;

import { ChangeEvent, useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { Grid } from "@mui/material";

import PaginationItem from "./PaginationItem";
import { PaginationItemStyle, TablePaginationStyle } from "../styles/style";
import { DEFAULT_PAGE, PER_PAGE } from "../../../../constants/FunctionalUtils";
import { PaginationPayload } from "../../../../models/Disciplines";

type PaginationProps = {
  totalRecords: number;
  handlePagination: (paginationData: PaginationPayload) => void;
  paginationData: PaginationPayload;
};

const CustomTablePagination = ({
  totalRecords,
  handlePagination,
  paginationData
}: PaginationProps) => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(PER_PAGE);

  const count = Math.ceil(
    totalRecords ? Number(totalRecords) / rowsPerPage : 1 / rowsPerPage
  );

  useEffect(() => {
    setPage(paginationData.pageNo)
    setRowsPerPage(paginationData.pageSize);
  }, [paginationData]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const paginationData = {
      pageNo: newPage,
      pageSize: rowsPerPage,
    };
    handlePagination(paginationData);
  };

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    const paginationData = {
      pageNo: page,
      pageSize: rowsPerPage,
    };
    handlePagination(paginationData);
  };

  const handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(DEFAULT_PAGE);
    const paginationData = {
      pageNo: DEFAULT_PAGE,
      pageSize: parseInt(e.target.value, 10),
    };
    handlePagination(paginationData);
  };

  const paginationActions = () => {
    return (
      <Grid item xs={"auto"} style={PaginationItemStyle}>
        <PaginationItem
          count={count}
          page={page}
          handlePageChange={handlePageChange}
          isCustomTablePagination={true}
        />
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TablePagination
          component="div"
          count={totalRecords}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={paginationActions}
          labelRowsPerPage={"Records per page:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count} Records`
          }
          sx={TablePaginationStyle}
        />
      </Grid>
    </Grid>
  );
};

export default CustomTablePagination;

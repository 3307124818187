import { get, isEmpty } from "lodash";

import { AppConfigs } from "../models/AppConfigs";
import { AppEnvironmentConfig } from "./AppConfigs";
import { initAfterConfigLoad } from "../utils";

/* Config from config.json - START */
export const updateConfigs = (data: AppConfigs) => {
  configs = data;
  configUrl = data.REACT_APP_HOST_CONFIG_URL;
  configUrlForUserManagement = data.REACT_APP_HOST_CONFIG_URL_USER;
  configUrlForReferral = data.REACT_APP_HOST_CONFIG_URL_REFERRAL;
  configUrlForNote = data.REACT_APP_HOST_CONFIG_URL_NOTE;
  configUrlForOperator = data.REACT_APP_HOST_CONFIG_URL_OPERATOR;
  appInsightConnString = data.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
  appClientId = data.REACT_APP_CLIENT_ID;
  configUrlForScheduler = data.REACT_APP_HOST_CONFIG_URL_SCHEDULER;
  configUrlForBlob = data.REACT_APP_HOST_CONFIG_URL_BLOB;
  configUrlForCheckEligibility =
    data.REACT_APP_HOST_CONFIG_URL_CHECK_ELIGIBILITY;
  configUrlForPayerEligibility =
    data.REACT_APP_HOST_CONFIG_URL_PAYER_ELIGIBILITY;
  appLoginURL = data.REACT_APP_LOGIN_URL;
  reactAppDebug = data.REACT_APP_DEBUG === 1;
  googleMapKey = data.REACT_APP_GOOGLE_MAP_KEY;
  surveyJsKey = data.REACT_APP_SURVEY_JS_KEY;
  googleMapUrl = data.REACT_APP_GOOGLE_MAP_API_URL;
  documentMaxSize = data.REACT_APP_DOCUMENT_MAX_SIZE;
  documentMinSize = !isEmpty(data.REACT_APP_DOCUMENT_MIN_SIZE) ? data.REACT_APP_DOCUMENT_MIN_SIZE : "1KB";
  documentFaxMaxSize = data.REACT_APP_FAX_MAX_SIZE.split("MB")[0];
  isProductionEnvironment = data.REACT_APP_IS_PRODUCTION_ENV === 1;
  defaultDinaUrl = data.REACT_APP_DINA_DEFAULT_URL;

  initAfterConfigLoad();
};

export let configs: AppConfigs = {
  REACT_APP_HOST_CONFIG_URL: "",
  REACT_APP_HOST_CONFIG_URL_USER: "",
  REACT_APP_HOST_CONFIG_URL_REFERRAL: "",
  REACT_APP_HOST_CONFIG_URL_NOTE: "",
  REACT_APP_HOST_CONFIG_URL_OPERATOR: "",
  REACT_APP_APPINSIGHTS_CONNECTION_STRING: "",
  REACT_APP_CLIENT_ID: "",
  REACT_APP_HOST_CONFIG_URL_SCHEDULER: "",
  REACT_APP_HOST_CONFIG_URL_BLOB: "",
  REACT_APP_HOST_CONFIG_URL_CHECK_ELIGIBILITY: "",
  REACT_APP_HOST_CONFIG_URL_PAYER_ELIGIBILITY: "",
  REACT_APP_LOGIN_URL: "",
  REACT_APP_DEBUG: false,
  REACT_APP_GOOGLE_MAP_KEY: "",
  REACT_APP_SURVEY_JS_KEY: "",
  REACT_APP_GOOGLE_MAP_API_URL: "",
  REACT_APP_DOCUMENT_MAX_SIZE: "",
  REACT_APP_DOCUMENT_MIN_SIZE: "",
  REACT_APP_FAX_MAX_SIZE: "",
  REACT_APP_IS_PRODUCTION_ENV: 1,
  REACT_APP_DINA_DEFAULT_URL: ""
};

export let configUrl = configs.REACT_APP_HOST_CONFIG_URL as string;
export let configUrlForUserManagement =
  configs.REACT_APP_HOST_CONFIG_URL_USER as string;
export let configUrlForReferral =
  configs.REACT_APP_HOST_CONFIG_URL_REFERRAL as string;
export let configUrlForNote = configs.REACT_APP_HOST_CONFIG_URL_NOTE as string;
export let configUrlForScheduler =
  configs.REACT_APP_HOST_CONFIG_URL_SCHEDULER as string;
export let configUrlForOperator =
  configs.REACT_APP_HOST_CONFIG_URL_OPERATOR as string;
export let appInsightConnString =
  configs.REACT_APP_APPINSIGHTS_CONNECTION_STRING as string;
export let configUrlForBlob = configs.REACT_APP_HOST_CONFIG_URL_BLOB as string;
export let configUrlForCheckEligibility =
  configs.REACT_APP_HOST_CONFIG_URL_CHECK_ELIGIBILITY as string;
export let configUrlForPayerEligibility =
  configs.REACT_APP_HOST_CONFIG_URL_PAYER_ELIGIBILITY as string;
export let appClientId = configs.REACT_APP_CLIENT_ID as string;
export let googleMapKey = configs.REACT_APP_GOOGLE_MAP_KEY as string;
export let surveyJsKey = configs.REACT_APP_SURVEY_JS_KEY as string;
export let googleMapUrl = configs.REACT_APP_GOOGLE_MAP_API_URL as string;
export let appLoginURL = configs.REACT_APP_LOGIN_URL as string;
export let reactAppDebug = configs.REACT_APP_DEBUG as boolean;
export let documentMaxSize = configs.REACT_APP_DOCUMENT_MAX_SIZE as string;
export let documentMinSize = configs.REACT_APP_DOCUMENT_MIN_SIZE as string;
export let documentFaxMaxSize = configs.REACT_APP_FAX_MAX_SIZE as string;
export let isProductionEnvironment = configs.REACT_APP_IS_PRODUCTION_ENV as boolean;
export let defaultDinaUrl = configs.REACT_APP_DINA_DEFAULT_URL as string;

/* Config from config.json - END */

/* App level config constants - START */

//Check if running as localhost
export const IS_LOCALHOST: boolean =
  get(process.env, "NODE_ENV", "") === AppEnvironmentConfig.DEV;

//Applied endpoint for localhost -> can be changed via CLI e.g. set REACT_APP_ENDPOINT_ENV=SB
export const REACT_APP_ENDPOINT_ENV = get(
  process.env,
  "REACT_APP_ENDPOINT_ENV",
  ""
);

/* App level config constants - END */

import * as React from "react";
import { useState } from "react";
import { Grid, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormState, UseFormGetValues, UseFormTrigger } from "react-hook-form";

import { HeaderLabel } from "../../../../../constants/Authorization";
import colors from "../../../../../styles/colors/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import {
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  GeneralInfoItemsTabs,
  GeneralInfoTabBorderBottom,
  HeaderTabs,
  MainGrid,
  Mandatory,
  MinimizeMaximizeIcon,
  TabelPannelGrid,
} from "../styles/AuthorizationDetailsTabs";
import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import { SaveIcd10Records } from "../../../../../models/Patient";
import AuthGeneralInfo from "../containers/GeneralInfo";
import AuthPastMedicalHistoryUI from "../containers/PastMedicalHistory";
import DiagnosisCode from "./DiagnosisCode";
import { PsychologicalHistoryResponse } from "../../../../../models/Api/Master";
import InfoDialog from "../../../../../components/custom/infoDialogue/components/InfoDialog";
import { MandatoryFieldAlertMessage } from "../../../../../constants/AllPatientRecord";
import { a11yProps } from "../../../../../utils";
import RequestDetails from "./RequestDetails";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface Props {
  clearErrors: (name: string) => void;
  trigger: UseFormTrigger<any>;
  control: any;
  getValues: UseFormGetValues<any>;
  icd10RecordsAppend: any;
  icd10RecordsFields: any[];
  icd10RecordsRemove: any;
  icd10Response: any;
  icdvalues: SaveIcd10Records; //TBD;
  reset: any;
  setValue: (id: any, value: any, boolean: any) => void;
  values: AuthorizationDetailTabs;
  watch: any;
  psychologicalHistoryData: PsychologicalHistoryResponse;
  formState: FormState<any>;
  authorizationDetailTabs: AuthorizationDetailTabs;
}
const AuthorizationDetailsTabs: React.FC<Props> = ({
  clearErrors,
  trigger,
  control,
  getValues,
  icd10RecordsAppend,
  icd10RecordsFields,
  icd10RecordsRemove,
  icdvalues,
  reset,
  setValue,
  values,
  watch,
  psychologicalHistoryData,
  formState,
  authorizationDetailTabs,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [minimizeTab, setMinimizeTab] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { errors } = formState;

  const handleChange = async (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    switch (activeTab) {
      case 0:
      case 2:
      case 3:
        trigger && (await trigger());
        break;
      case 1:
        await triggerDiagnosisCode();
        break;
    }
    if (errors && Object.keys(errors).length > 0) {
      setIsOpen(true);
    } else {
      setActiveTab(newValue);
      setMinimizeTab(false);
    }
  };

  const triggerDiagnosisCode = async () => {
    const index = values.icdCodesInfoDto.findIndex(
      (icdCode: any) => icdCode.diagnosisCode === ""
    );
    if (index >= 0) {
      trigger && (await trigger(`icdCodesInfoDto.${index}.icdDiagnosisCodeId`));
    }
  };

  return (
    <Box>
      <Grid container sx={MainGrid}>
        <Grid item xs={11}>
          <Box sx={displayFlex}>
            <Box sx={MinimizeMaximizeIcon}>
              {minimizeTab ? (
                <FaChevronCircleDownIcon
                  onClick={() => setMinimizeTab(false)}
                />
              ) : (
                <FaChevronCircleUpIcon onClick={() => setMinimizeTab(true)} />
              )}
            </Box>
            <Box sx={flexAlignCentre}>
              <GeneralInfoItemsTabs
                value={activeTab}
                onChange={handleChange}
                aria-label="Authorization details screen tabs"
              >
                {HeaderLabel.map((tablecell, index) => (
                  <Tab
                    sx={HeaderTabs}
                    key={index}
                    label={
                      <Typography
                        variant="subtitle1"
                        fontWeight={fontWeight.Weight[5]}
                      >
                        {tablecell.label}
                      </Typography>
                    }
                    {...a11yProps(index)}
                  />
                ))}
              </GeneralInfoItemsTabs>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" pt={"1rem"}>
          <Box sx={Mandatory}>
            <Typography variant="subtitle1" color={colors.red[100]}>
              *
            </Typography>
            <Typography
              variant="body2"
              color={colors.black[5]}
              fontWeight={fontWeight.Weight[3]}
              ml={"0.3rem"}
            >
              Mandatory fields
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={TabelPannelGrid}>
          <GeneralInfoTabBorderBottom />
        </Grid>
        <Grid item xs={12}>
          {!minimizeTab && (
            <>
              <TabPanel value={activeTab} index={0}>
                <AuthGeneralInfo
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  values={values}
                  reset={reset}
                  trigger={trigger}
                  clearErrors={clearErrors}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <DiagnosisCode
                  control={control}
                  fields={icd10RecordsFields}
                  append={icd10RecordsAppend}
                  remove={icd10RecordsRemove}
                  icd10Response={
                    values &&
                    values.icdCodesInfoDto &&
                    values.icdCodesInfoDto.length > 0
                      ? values.icdCodesInfoDto
                      : []
                  }
                  icdvalues={icdvalues}
                  setValue={setValue}
                  getValues={getValues}
                  reset={reset}
                  clearErrors={clearErrors}
                  watch={watch}
                />
              </TabPanel>

              <TabPanel value={activeTab} index={2}>
                {psychologicalHistoryData &&
                  psychologicalHistoryData.response.length >= 1 && (
                    <AuthPastMedicalHistoryUI
                      control={control}
                      setValue={setValue}
                      values={values}
                    />
                  )}
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <RequestDetails
                  values={values}
                  control={control}
                  setValue={setValue}
                  patientRequestDetail={authorizationDetailTabs}
                  clearErrors={clearErrors}
                  reset={reset}
                  watch={watch}
                  getValues={getValues}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
      <InfoDialog
        alertMessage={MandatoryFieldAlertMessage}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Box>
  );
};

export default AuthorizationDetailsTabs;

import { styled } from "@mui/system";
import { FaCheckCircle, FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { Table, TableHead, TableRow } from "@mui/material";

import { colors, others } from "../../../../../styles/colors";

export const Gridbody = () => ({
    background: others.otherColors[33],
    boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
    opacity: 1,
    padding: "1.5rem 1.5rem 0 2rem",
    marginBottom: "0.8rem",
    paddingBottom: "1.2rem",
    borderRadius: "0.8rem",
    display: "flex",
});

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
    color: colors.fonts[18],
    fontSize: "1.2rem",
    cursor: "pointer",
}));

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
    color: colors.fonts[18],
    fontSize: "1.2rem",
    cursor: "pointer",
}));

export const FaCheckCircleIcon = styled(FaCheckCircle)(() => ({
    color: colors.brand.secondary,
    fontSize: "1.2rem"
}))

export const HeaderTitle = () => ({
    paddingTop: "0.3rem",
    paddingLeft: "1rem",
});

export const CompletedTitlestyle = () => ({
    paddingTop: "0.3rem",
    marginLeft: "0.5rem"
})

export const VerticalDivider = () => ({
    margin: "0 1.2rem",
});

export const HorizontalDivider = () => ({
    margin: "1rem 0",
    minWidth: "100%",
    width: "95%",
});

export const GridTableContent = () => ({
    padding: "1rem 0 1rem 1rem",
});

export const ContainerStyle = {
    padding: "0 2rem 1rem 1rem ",
    "&::-webkit-scrollbar": {
        width: "2rem",
        height: "0.2rem",
    },
    "&::-webkit-scrollbar-track": {
        width: 0.1,
        marginLeft: "10rem",
        marginRight: "10rem",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.black[7],
        borderRadius: 0.1,
        width: 0.1,
    },
};

export const TableMain = styled(Table)(() => ({
    "& .MuiTableCell-root": {
        borderBottom: `1px solid ${others.otherColors[41]}`,
        borderRight: "none",
        borderLeft: "none",
        paddingLeft: "0.88rem",
    },
    "& th:first-of-type": {
        borderRadius: "0.4875rem 0 0 0.4875rem",
        borderBottom: "unset",
    },
    "& th:last-of-type": {
        borderRadius: "0 0.4875rem 0.4875rem 0",
    },
}));

export const TableMainHead = styled(TableHead)(() => ({
    backgroundColor: colors.backgroundColor[8],
    borderBottom: "unset",
    padding: ".4rem 0 0 0",
    "& th:first-of-type": {
        borderRadius: "0.7rem 0 0 0.7rem",
        borderBottom: "none",
    },
    "& th:last-child": {
        borderRadius: "0 0.7rem 0.7rem 0",
        borderBottom: "none",
    },
}));

export const TableMainHeadRow = styled(TableRow)(() => ({
    height: "3rem",
}));

export const TableNameDetails = (rowIndex: number, totalRow: number) => ({
    textDecoration: "underline",
    textDecorationColor: colors.fonts[18],
    borderBottom:
        rowIndex === totalRow - 1
            ? `1px solid ${others.DashboardReferralProgress[1]}`
            : 0,
    borderRight: 0,
    borderTop:
        rowIndex === 0 ? `1px solid ${others.DashboardReferralProgress[1]}` : 0,
    padding: 0,
    textAlign: "left",
    paddingLeft: "2rem",
});



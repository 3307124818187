import { useState, useEffect } from "react";

// Custom hook to show hide menu items on scroll
const useMenuItem = (defaultOpen = false) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    window.addEventListener("scroll", onClose);
    return () => {
      window.removeEventListener("scroll", onClose);
    };
  }, []);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return {
    open,
    onOpen,
    onClose,
  };
};

export default useMenuItem;

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import RoleDetails from "../components/RoleDetails";
import {
  getRoleDetails,
  putRoleDetails,
  roleDetailsReset,
  updateRoleDetailsReset,
} from "../../../../../actions/Roles";
import { RoleDetailsDispatchTypes } from "../../../../../models/DispatchTypes/Roles";
import { RoleDetailsResponse } from "../../../../../models/Api/Roles";
import { RoleDetailsAuth } from "../../../../../models/Roles";
import { getMasterAccess } from "../../../../../actions/Master";
import { MasterAccessResponse } from "../../../../../models/Api/Master";

const mapStateToProps = ({
  roleDetailsState,
  masterAccessState,
  updateRoleDetailsState
}: ApplicationState) => ({
  roleDetails: {
    roleDetailsState: roleDetailsState as RoleDetailsResponse,
    masterAccessState: masterAccessState as MasterAccessResponse,
  },
  updateRoleDetails: updateRoleDetailsState 
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<RoleDetailsDispatchTypes>
  >
) => {
  return {
    getRoleDetails: (userId: string) => {
      dispatch(getRoleDetails(userId));
    },
    getMasterAccess: () => {
      dispatch(getMasterAccess());
    },
    putRoleDetails: (roleId: string, payload: RoleDetailsAuth) => {
      dispatch(putRoleDetails(roleId, payload));
    },
    roleDetailsReset: () => {
      dispatch(roleDetailsReset());
    },
    updateRoleDetailsReset: () => {
      dispatch(updateRoleDetailsReset())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetails);

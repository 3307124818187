import { Reducer } from "redux";

import { AuthorizationStatusActionDispatchTypes } from "../constants/Master";
import { AuthorizationStatusMasterResponse } from "../models/Api/Master";
import { AuthorizationStatusDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: AuthorizationStatusMasterResponse = {
  loading: false,
  response: [],
};

const AuthorizationStatusReducer: Reducer<
  AuthorizationStatusMasterResponse,
  AuthorizationStatusDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthorizationStatusActionDispatchTypes.AUTHORIZATION_STATUS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthorizationStatusActionDispatchTypes.AUTHORIZATION_STATUS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default AuthorizationStatusReducer;

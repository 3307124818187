import { Controller, UseFormTrigger } from "react-hook-form";
import { MutableRefObject, useRef, useEffect } from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { initMapScript, extractPatientAddress } from "../../utils/Address";
import {
  FaPhoneInputDevider,
  FaRegEnvelopeIcon,
  ReferralAddressInputBox,
  ReferralAddressInputBoxForZipcode,
} from "./styles/style";
import { flexAllCentre } from "../../styles/mui/styles/display";
import { FormInputText } from "./FormInputText";
import { rules } from "../../utils/validation/Validation";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";
import { getConcatStringOrNull } from "../../utils";
import { PhoneFaxExtInput } from "./PhoneFaxExtInput";

interface AddressInfo {
  control?: any;
  setValue: (id: any, value: any, boolean: any) => void;
  values: any;
  readOnly?: boolean;
  trigger?: UseFormTrigger<any>;
  isRequired?: boolean;
}

type AllProps = AddressInfo;

export const AddressFormInput: React.FC<AllProps> = ({
  control,
  setValue,
  values,
  readOnly,
  trigger,
  isRequired,
}: AllProps) => {
  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const handleKeyStrokes = () => {
    setValue(
      "streetName1",
      getConcatStringOrNull(values.patientResidentialAddress.streetName1),
      true
    );
    setValue(
      "streetName2",
      getConcatStringOrNull(values.patientResidentialAddress.streetName2),
      true
    );
    setValue(
      "county",
      getConcatStringOrNull(values.patientResidentialAddress.county),
      true
    );
    setValue(
      "city",
      getConcatStringOrNull(values.patientResidentialAddress.city),
      true
    );
    setValue(
      "patientState",
      getConcatStringOrNull(values.patientResidentialAddress.patientState),
      true
    );
    setValue(
      "zipCode",
      getConcatStringOrNull(values.patientResidentialAddress.zipCode),
      true
    );
  };

  const initAutocomplete = () => {
    if (!searchInput.current) {
      return;
    } else {
      const autocomplete = new window.google.maps.places.Autocomplete(
        searchInput.current
      );
      autocomplete.setFields(["address_component", "geometry"]);
      autocomplete.addListener("place_changed", () =>
        onChangeAddress(autocomplete)
      );
    }
  };

  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const address = extractPatientAddress(place);
    setValue(
      "patientResidentialAddress.streetName1",
      getConcatStringOrNull(address.streetNumber, address.streetName1),
      true
    );
    setValue(
      "patientResidentialAddress.streetName2",
      getConcatStringOrNull(address.streetName2),
      true
    );
    setValue("patientResidentialAddress.county", address.county, true);
    setValue("patientResidentialAddress.city", address.city, true);
    setValue(
      "patientResidentialAddress.patientState",
      address.patientState,
      true
    );
    setValue("patientResidentialAddress.zipCode", address.zipCode, true);
    trigger &&
      trigger([
        "patientResidentialAddress.streetName1",
        "patientResidentialAddress.city",
        "patientResidentialAddress.patientState",
        "patientResidentialAddress.zipCode",
      ]);
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  const handleEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("createPatient.emailAddress", event.target.value, true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12} sx={ReferralAddressInputBox}>
              <FormInputText
                name="patientResidentialAddress.streetName1"
                control={control}
                label="Street Name 1:"
                placeholder="Add here..."
                helper={
                  isRequired ? rules.streetName1Required : rules.streetName2
                }
                onKeyPress={handleKeyStrokes}
                searchInputRef={searchInput}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12} sx={ReferralAddressInputBox}>
              <FormInputText
                name="patientResidentialAddress.streetName2"
                control={control}
                label="Street Name 2:"
                placeholder="Add here..."
                helper={rules.streetName2}
                onKeyPress={handleKeyStrokes}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={12} sx={ReferralAddressInputBox}>
              <FormInputText
                name="patientResidentialAddress.county"
                control={control}
                label="County:"
                placeholder="Add here..."
                helper={rules.county}
                onKeyPress={handleKeyStrokes}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sx={ReferralAddressInputBox}>
          <FormInputText
            name="patientResidentialAddress.city"
            control={control}
            label="City:"
            placeholder="Choose"
            helper={isRequired ? rules.cityRequired : rules.city}
            onKeyPress={handleKeyStrokes}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={2} sx={ReferralAddressInputBox}>
          <FormInputText
            name="patientResidentialAddress.patientState"
            control={control}
            label="State:"
            placeholder="Choose"
            helper={isRequired ? rules.stateRequired : rules.state}
            onKeyPress={handleKeyStrokes}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Grid container pt={"2rem"}>
        <Grid item xs={3} sx={ReferralAddressInputBoxForZipcode}>
          <FormInputText
            name="patientResidentialAddress.zipCode"
            control={control}
            label="Zipcode:"
            placeholder="Add here..."
            helper={isRequired ? rules.zipCodeRequired : rules.zipcode}
            onKeyPress={handleKeyStrokes}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={3} sx={ReferralAddressInputBox}>
          <PhoneFaxExtInput
            label="Phone Number:"
            control={control}
            phoneFaxName={"createPatient.phoneNo"}
            extName={"createPatient.extNo"}
            rules={isRequired ? rules.phone : rules.phoneNumber}
          />
        </Grid>
        <Grid item xs={3} sx={ReferralAddressInputBox}>
          <Controller
            defaultValue={""}
            render={({ field, fieldState: { error } }) => (
              <TextField
                variant="standard"
                {...field}
                error={error && error.message ? true : false}
                helperText={error ? error.message : null}
                InputProps={{
                  readOnly: readOnly,
                  startAdornment: (
                    <Stack direction="row" sx={flexAllCentre}>
                      <FaRegEnvelopeIcon />
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                        sx={FaPhoneInputDevider}
                      />
                    </Stack>
                  ),
                }}
                label={
                  <Typography
                    variant="h5"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[2]}
                  >
                    Email Address:
                  </Typography>
                }
                placeholder="Add"
                onChange={handleEmailAddress}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
              />
            )}
            name="createPatient.emailAddress"
            control={control}
            rules={rules.emailAddress}
          />
        </Grid>
      </Grid>
    </>
  );
};

import { Grid, styled } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import type { GridProps } from "@mui/material";

import { colors } from "../../../../../styles/colors";

export const CareDateGrid = () => ({
  alignItems: "center",
  paddingTop: "0.4rem",
});

export const AuthPeriodTablecell = () => ({
  marginBottom: "0.6rem",
  textAlign: "center",
});

export const DisciplineTableTitle = () => ({
  alignItems: "center",
  paddingTop: "2.2rem",
});

export const DisciplineButtons = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  paddingRight: "3rem",
});

export const VisitCountTablecell = () => ({
  textAlign: "center",
});

export const TotalVisitCount = () => ({
  textAlign: "left",
  paddingLeft: "4rem",
});

export const TotalTablecell = () => ({
  width: "26%",
  borderRight: "unset !important",
  paddingLeft: "2rem",
});

export const SelectHeader = () => ({
  paddingLeft: "0.6rem",
});

export const DisciplineHeader = () => ({
  paddingLeft: "6rem",
});

export const DisciplineBodyTableRow = () => ({
  paddingTop: "1.2rem",
});

export const DisciplineBox = () => ({
  paddingLeft: "3.2rem",
});

export const DisciplineDataTablecell = () => ({
  paddingLeft: "6rem",
  paddingTop: "0.6rem",
});

export const TotalVisitsDataTablecell = () => ({
  borderRight: "unset !important",
  paddingLeft: "7rem",
});

export const CareTypeTableTitle = () => ({
  padding: "2.5rem 0rem",
});

export const CounterVisitsBox = () => ({
  display: "flex",
  justifyContent: "center",
  minWidth: "10rem",
  position: "relative",
});

export const IconDisableStyle = {
  pointer: "none",
  opacity: "0.8",
  width: "1.8rem",
  cursor: "not-allowed",
  color: colors.fonts[25],
};

export const CareTypeBox = {
  background: colors.borders[5],
  borderRadius: ".5rem",
  padding: "1.2rem 4rem 1rem 1.5rem",
};
export const CareTypeBoxPaddingStyle = {
  paddingTop: "2rem",
  marginBottom: "2.2rem",
};

export const CareTypePaper = {
  alignItems: "center",
  display: "flex",
  borderRadius: "2rem",
  padding: "0 0.8rem",
};

export const HeaderModuleName = () => ({
  textTransform: "uppercase",
  marginBottom: "0.8rem",
});

export const Title = {
  marginBottom: "1.5rem",
  textTransform: "uppercase",
};

export const InputBaseStyle = {
  overflow: "hidden",
  width: "80%",
  padding: "0 1rem",
};
export const BoxContainer = () => ({
  padding: "0.8rem 3rem 2.8rem 2.5rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0 0 0",
  width: "98%",
});

export const CounterDivider = () => ({
  margin: "0.5rem 0",
  width: "100%",
});

export const ErrorMessage = () => ({
  width: "100%",
  position: "absolute",
  top: "1.1rem",
});

export const TableWidth = () => ({
  width: "98%",
  padding: "0 0.5rem 1.2rem 0",
});

export const BodyTable = () => ({
  "& .MuiTableCell-root": {
    borderBottom: `0.08rem solid ${colors.primary.light}`,
    borderRight: `0.08rem solid ${colors.primary.light}`,
    borderLeft: "none",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.5rem 0 0 0.5rem",
    paddingLeft: "2.5rem",
  },
  "& th:last-child": {
    borderRadius: "0 0.5rem 0.5rem 0",
  },
});

export const HeaderItem = () => ({
  backgroundColor: colors.backgroundColor[8],
});

export const IconBox = {
  color: colors.primary.main,
  width: "1.8rem",
  cursor: "pointer",
};

export const GridScrolling = {
  overflowY: "scroll",
  height: "25rem",
};

export const CareTypeGrid: StyledComponent<GridProps> = styled(Grid)(() => ({
  padding: "1.25rem 3.25rem 3.25rem 3.25rem",
}));

export const PaddingAll = {
  padding: ".5rem",
  width: "10%",
};

export const CareTypeDividerStyle = {
  opacity: "0.4",
  marginTop: " 0.97rem",
  marginBottom: " 0.97rem",
};

export const VisitCount = {
  padding: "0 4rem",
};

export const VisitsStyle = () => ({
  marginTop: "-1.6rem",
  "& .MuiFormHelperText-root": {
    position: "absolute",
    width: "180%",
    top: "2.7rem",
  },
});

export const FaMinusIconGrid = () => ({
  paddingLeft: "1rem",
  paddingTop: "1.2rem",
});

export const RequestedVisitIcon = () => ({
  paddingLeft: "1rem",
  paddingTop: "1.2rem",
});
import { Pagination, Button, SxProps } from "@mui/material";
import { ChangeEvent } from "react";

import {
  CustomPaginationButton,
  ItemPagination,
  ListPageNumber,
  PaginationButton,
} from "../styles/style";

type PaginationProps = {
  count: number;
  page: number;
  handlePageChange: (e: ChangeEvent<unknown>, page: number) => void;
  isCustomTablePagination?: boolean;
};

const PaginationItem = ({
  count,
  page,
  handlePageChange,
  isCustomTablePagination,
}: PaginationProps) => {
  const handleFirst = (e: ChangeEvent<unknown>) => {
    handlePageChange(e, 1);
  };

  const handleBack = (e: ChangeEvent<unknown>) => {
    handlePageChange(e, page - 1);
  };

  const handleNext = (e: ChangeEvent<unknown>) => {
    if (page <= count) {
      handlePageChange(e, page + 1);
    }
  };

  const handleLast = (e: ChangeEvent<unknown>) => {
    handlePageChange(e, count);
  };

  return (
    <ItemPagination>
      {isCustomTablePagination &&
        renderButton("FIRST", handleFirst, page === 1, CustomPaginationButton)}

      {renderButton(
        isCustomTablePagination ? "PREVIOUS" : "BACK",
        handleBack,
        page === 1,
        isCustomTablePagination
          ? CustomPaginationButton
          : PaginationButton(page === 1)
      )}
      <Pagination
        count={count}
        size={"small"}
        page={page}
        onChange={handlePageChange}
        hidePrevButton={page === 1}
        hideNextButton={page === count}
        sx={ListPageNumber}
      />
      {renderButton(
        "NEXT",
        handleNext,
        page === count,
        isCustomTablePagination
          ? CustomPaginationButton
          : PaginationButton(page === count)
      )}

      {isCustomTablePagination &&
        renderButton(
          "LAST",
          handleLast,
          page === count,
          CustomPaginationButton
        )}
    </ItemPagination>
  );
};

const renderButton = (
  text: string,
  onClick: (e: ChangeEvent<unknown>) => void,
  disabled: boolean,
  sx: SxProps
) => (
  <Button onClick={onClick} disabled={disabled} sx={sx}>
    {text}
  </Button>
);

export default PaginationItem;

import { accent } from "../../../../../../../styles/colors";
import colors from "../../../../../../../styles/colors/colors";

export const GridContainer = () => ({
  backgroundColor: colors.backgroundColor[2],
  borderBottom: `1px solid ${colors.borders[1]}`,
  paddingLeft: "1rem",
});

export const DisciplineBodyTableRow = () => ({
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
});

export const HorizontalDivider = () => ({
  margin: "1.2rem 0 0.8rem 0.3rem",
  width: "100%",
});

export const ItemIconBox = () => ({
  color: accent.accentGreen[100],
  paddingRight: "0.6rem",
});

export const DocumentIconBox = () => ({
  color: colors.primary.main,
  paddingRight: "0.5rem",
});

export const GridBody = () => ({
  padding: "0.5rem 0.5rem 0 2rem",
});

export const GridLeftBody = () => ({
  padding: "1.5rem 0 3rem 2rem",
});

export const DocumentsGrid = () => ({
  padding: "1.5rem 1rem 0 0.4rem ",
  display: "flex",
});

export const SupportingDocumentsGrid = () => ({
  padding: "0.8rem 0.5rem 3.5rem 2.5rem",
});

export const FieldBox = () => ({
  paddingTop: "1rem ",
});

export const FieldLabel = () => ({
  padding: "0.6rem 0",
});

export const DocumentFieldGrid = () => ({
  paddingLeft: "2.8rem",
});

export const DocumentNameTypeBox = () => ({
  paddingLeft: "2.7rem",
});

export const DocumentDataGrid = () => ({
  paddingLeft: "2.8rem",
  display: "flex",
});

export const HorizontalDividerGrid = () => ({
  paddingRight: "1.5rem",
});

export const FileNameField = () => ({
  paddingTop: "0.4rem",
  textDecoration: "underline",
});

export const blobDocumentGrid = (hideRightBorder: boolean) => ({
  borderRight: hideRightBorder
    ? `1px solid ${colors.white[1]}`
    : `1px solid ${colors.dropShadow[4]}`,
  padding: "2rem 0.5rem 1.5rem 1rem",
});

export const DownloadLink = () => ({
  display: "flex",
});

export const BlobDocumentContainer = () => ({
  paddingTop: "1rem",
});

export const VerticalDivider = () => ({
  padding: "0.8rem 0",
});

export const DocumentationDate = () => ({
  paddingTop: "0.2rem",
  paddingLeft: "0.4rem",
});

export const DocumentationDateGrid = () => ({
  paddingLeft: "1rem",
});

export const DocumentationDateContainer = () => ({
  padding: "2.8rem 0 0.5rem 0.4rem",
});

export const SupportingDocumentsData = () => ({
  paddingTop: "2rem",
});

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  DisciplineList,
  DisciplineNoteSearch,
  DisciplinePayload,
  ReferralDiscipline,
} from "../../../../../models/Disciplines";
import { DisciplineDispatchTypes } from "../../../../../models/DispatchTypes/Discipline";
import Disciplines from "../components/Disciplines";
import {
  getDisciplines,
  getReferralDisciplines,
} from "../../../../../actions/AuthorizationDetails";
import { UpdateDisciplineResponse } from "../../../../../models/Api/Discipline";
import { resetStates } from "../../../../../actions/Common";
import {
  SaveDisciplineLevelNotesData,
  UpdateDisciplineLevelNoteData,
  getDisciplineLevelNotes,
  getDisciplineNoteList,
} from "../../../../../actions/Notes";
import { DisciplineLevelNote } from "../../../../../models/Notes";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { getUserListWithFilter } from "../../../../../actions/User";
import { UserListWithFilterPayload, User } from "../../../../../models/User";
import { AssignedStaff } from "../../../../../models/Patient";
import { PutMyPatientListRecord } from "../../../../../actions/AllPatientRecord";
import { getReceivedMethodMasterResp } from "../../../../../actions/Patient";

const mapStateToProps = ({
  disciplineState,
  referralDisciplineState,
  updateDisciplineState,
  disciplineNoteListState,
  GetDisciplineLevelNotesState,
  UpdateDisciplineLevelNotesState,
  SaveDisciplineLevelNotesState,
  patientInformationViewState,
  loggedInUserState,
  authorizationDetailTabsState,
  userListWithFilterState,
  receivedMethodState,
  putMyPatientListState
}: ApplicationState) => ({
  disciplines: disciplineState.response as DisciplineList,
  referralDisciplines: referralDisciplineState.response as ReferralDiscipline,
  updateDiscipline: updateDisciplineState as UpdateDisciplineResponse,
  disciplineServiceDetailsError: updateDisciplineState.error,
  disciplineNoteListData: disciplineNoteListState,
  GetDisciplineLevelNotesData: GetDisciplineLevelNotesState,
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNotesState,
  updateDisciplineLevelNoteDataResp: UpdateDisciplineLevelNotesState,
  patientData: patientInformationViewState as PatientInformationViewResponse,
  userDetails: loggedInUserState,
  authorizationDetailTabsData: authorizationDetailTabsState,
  userList: userListWithFilterState.response as User[],
  receivedMethodData: receivedMethodState,
  putMyPatientListData :putMyPatientListState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<DisciplineDispatchTypes>
  >
) => {
  return {
    getDisciplinesData: (payload: DisciplinePayload) => {
      dispatch(getDisciplines(payload));
    },
    getReferralDisciplinesData: (referralDisciplineId: number) => {
      dispatch(getReferralDisciplines(referralDisciplineId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    getDisciplineNoteList: (payload: DisciplineNoteSearch) => {
      dispatch(getDisciplineNoteList(payload));
    },
    getDisciplineLevelNotes: (disciplineNoteId: number) => {
      dispatch(getDisciplineLevelNotes(disciplineNoteId));
    },
    saveDisciplineLevelNotesData: (payload: DisciplineLevelNote) => {
      dispatch(SaveDisciplineLevelNotesData(payload));
    },
    updateDisciplineLevelNoteData: (
      disciplineNoteId: number,
      payload: DisciplineLevelNote
    ) => {
      dispatch(UpdateDisciplineLevelNoteData(disciplineNoteId, payload));
    },
    getStaffList: (payload: UserListWithFilterPayload) => {
      dispatch(getUserListWithFilter(payload));
    },
    updateAssignStaff: (
      assignedToStaffId: number,
      payload: AssignedStaff
    ) => {
      dispatch(PutMyPatientListRecord(assignedToStaffId, payload));
    },
    getReceivedMethodDetails: (serviceRequestTypeId: number) => {
      dispatch(getReceivedMethodMasterResp(serviceRequestTypeId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Disciplines);

import React from "react";
import { FormGroup, FormControlLabel, Switch, Typography } from "@mui/material";

import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

type SwitchProps = {
  checked?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, values?: any) => void;
};
const CustomSwitch = ({
  checked,
  dataTestId,
  disabled,
  label,
  onChange,
}: SwitchProps) => {
  if (disabled) {
    return (
      <FormGroup>
        <FormControlLabel
          disabled
          control={
            <Switch
              checked={checked}
              data-testid={dataTestId}
              onChange={onChange}
            />
          }
          label={
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[4]}
              color={colors.black[2]}
              pl={"0.5rem"}
            >
              {label}
            </Typography>
          }
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            data-testid={dataTestId}
            onChange={onChange}
          />
        }
        label={
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[4]}
            color={colors.black[2]}
            pl={"0.5rem"}
          >
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default CustomSwitch;

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Table, TableHead, alpha } from "@mui/material";
import {
  FaSearch,
  FaFilter,
  FaUndo,
  FaFilePdf,
  FaPencilAlt,
  FaRegEye,
  FaTrashAlt,
  FaPowerOff,
  FaInfoCircle,
} from "react-icons/fa";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const rowOne = {
  boxSizing: "border-box",
  margin: "0.09rem 0.24rem",
  padding: "0.05rem",
};
export const title = {
  textAlign: "left",
  color: colors.black[2],
  textTransform: "uppercase",
  opacity: "1",
};
export const IconFaSearch = styled(FaSearch)(() => ({
  display: "flex",
  color: alpha(colors.fonts[4], 0.32),
  justifyContent: "left",
}));

export const IconFaPencilAlt = styled(FaPencilAlt)(() => ({
  color: colors.fonts[18],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const IconFaInfoAlt = styled(FaInfoCircle)(() => ({
  color: colors.fonts[18],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const IconFaRegEye = styled(FaRegEye)(() => ({
  color: colors.black[1],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const IconFaTrashAlt = styled(FaTrashAlt)(() => ({
  color: colors.arrowIcon[2],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const IconFaPowerOff = styled(FaPowerOff)(() => ({
  color: colors.red[90],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const IconFaPowerOn = styled(FaPowerOff)(() => ({
  color: colors.fonts[22],
  cursor: "pointer",
  marginRight: "1.5rem",
}));

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderBottom: `0.05rem solid ${others.otherColors[41]}`,
    borderRight: "none",
    borderLeft: "none",
    padding: "0.5rem 0.5rem 0.5rem 0.97rem",
  },
  "& th:first-of-type": {
    borderRadius: "1em 0 0 1em",
  },
  "& th:last-child": {
    borderRadius: "0 1em 1em 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[8],
  borderRadius: "0.42rem",
}));

export const HeaderTableRow = () => ({
  height: "2.8rem",
});

export const back = {
  display: "flex",
  margin: "0.05rem 0.15rem",
  textAlign: "left",
  textDecoration: "underline",
  color: colors.primary.main,
  textTransform: "uppercase",
  opacity: "1",
};

export const description = {
  textAlign: "left",
  paddingTop: 1,
  opacity: "1",
  lineHeight: "1.3",
};

// User Profile BODY properties start
export const body = {
  background: others.otherColors[33],
  opacity: 1,
  padding: "0.24rem 0.24rem 0.24rem 0.24rem",
  marginRight: 2,
  borderRadius: "0.5rem",
};

export const ItemMain = styled(Box)(() => ({
  backgroundColor: others.otherColors[33],
  color: others.otherColors[33],
  borderRadius: "0.24rem",
}));

export const ItemMainUM = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "space-between",
}));

export const Header = styled(Box)(() => ({
  color: colors.black[2],
}));

export const PaginationStyles = {
  backgroundColor: others.otherColors[33],
  color: colors.black[2],
};
export const ItemPagination = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "end",
}));

const removeavatarborder = { padding: "0.5rem", borderRight: "none" };
export const avatarSize = { width: "100%", height: "100%" };
export const backdrop = {
  color: others.otherColors[33],
  zIndex: (theme: any) => theme.zIndex.drawer - 1,
  position: "absolute",
  opacity: 0.5,
};

export const dividerRightWidth = {
  borderRightWidth: 2,
  height: "1.45rem",
  marginRight: "0.5rem",
};

export const DividerHeight = {
  height: "2.2rem",
};

const dividerborder = { width: "0.09rem", height: "9rem", marginLeft: "1rem" };
export const layout = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "0.32rem 0.72rem 0.22rem  0.72rem",
};

export const innerLayoutHeader = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "0.42rem 0 0.42rem 0",
  background: others.otherColors[33],
};

export const BreadcumbLeftGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  padding: "1rem 1.5rem 1rem 1.5rem",
}));

export const BreadcumbRightGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "end",
  padding: "0 1.5rem 0 0",
}));

export const BreadcumbPaper = styled(Paper)(() => ({
  backgroundColor: "inherit",
}));

export const ExportIcon = {
  testAlign: "left",
  marginRight: ".5rem",
  position: "relative",
};

export const ButtonStyle = (loading?: boolean) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0.3rem",
  background: loading ? alpha(colors.grey[1], 0.5) : others.MainBackground[1],
  padding: "0 0.9rem 0 0.9rem",
  height: "1.8rem",
  borderRadius: "0.8rem",
  color: colors.fonts[18],
  cursor: loading ? "not-allowed" : "pointer",
});

export const AutoCompleteBootom = {
  borderBottomColor: others.otherColors[35],
  display: "flex",
  flexDrection: "row",
  margin: "1rem 0 0 1.6rem",
};

export const AutoComplete = {
  width: "18rem",
  borderBottom: others.otherColors[35],
  borderBottomWidth: "0.06rem",
  marginRight: "1.5rem",
};

export const NppesAutoComplete = {
  width: "100%",
  borderBottom: others.otherColors[35],
  borderBottomWidth: "0.06rem",
  marginRight: "1.5rem",
};

export const Search = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const searchicon = {
  display: "flex",
  color: others.otherColors[36],
  alignItems: "flex-start",
  justifyContent: "left",
  margin: "0.625rem 0.625rem 0 0.625rem",
};

export const FilterIcon = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

export const TablecellStyle = {
  borderBottom: "none",
};

export const ListHeader = {
  height: "auto",
  display: "flex",
  padding: "0.3rem",
  wordBreak: "break-word",
};
export const ListActionHeader = {
  height: "auto",
  display: "flex",
  padding: "0.3rem",
};
export const Data = {
  lineHeight: "1rem",
};

export const HeaderItem = {
  backgroundColor: alpha(colors.black[7], 0.35),
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
};

export const LastLogin = {
  color: colors.black[2],
  justifyContent: "flex-start",
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
  wordBreak: "break-all",
};

export const TitleBox = styled(Box)(() => ({
  textAlign: "initial",
  color: colors.fonts[4],
  padding: "0 0 0 1.3rem",
  width: "100%",
  marginTop: "1.21rem",
}));

export const SwitchTitle = styled(Box)(() => ({
  textAlign: "initial",
  color: colors.fonts[4],
  padding: "0 0 0 0",
  width: "100%",
}));

export const InputBox = styled(Grid)(() => ({
  padding: "0.5rem",
  display: "flex",
  flexDirection: "row",
}));

export const GridLayoutHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  marginTop: "2.3rem",
  marginBottom: "1rem",
}));

export const GridLayoutContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  margin: "1.5rem 0 1.6rem 1rem",
  width: "30%",
}));

export const IconFilter = styled(FaFilter)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  fontSize: "1.0rem",
  justifyContent: "left",
  color: colors.fonts[18],
  margin: "1.0rem 0.72rem 0 0.72rem",
}));

export const IconFaUndo = styled(FaUndo)(() => ({
  testAlign: "left",
  marginRight: ".5rem",
  font: fontWeight.Weight[4],
  color: colors.fonts[18],
}));

export const IconFaFilePdf = styled(FaFilePdf)(() => ({
  testAlign: "left",
  marginRight: ".5rem",
  color: colors.fonts[18],
}));

export const HeaderGrid = {
  display: "flex",
  width: "50%",
};

export const CategoryStatus = () => ({
  padding: "1.2rem 1rem 2.5rem 1rem",
  textAlign: "start",
});

export const TemplateNameStyle = () => ({
  padding: "1.1rem 1rem 2.2rem 1rem",
  textAlign: "start",
});

export const CategoryWidth = () => ({
  maxWidth: "11.5rem",
});

export const templateNameStyle = () => ({
  width: "25rem",
});

export const AssessmentDesignderStyle = {
  zIndex: "1",
  height: "32rem",
};

export const GridList = styled(Grid)(() => ({
  margin: "1rem 1.6rem 0 1.6rem",
  minHeight: "78vh",
}));

export const GridInputFields = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
}));

export const AlignmentHeader = {
  font: fontWeight.Weight[4],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "3.0rem",
};

export const SummaryContainerBody = () => ({
  padding: "0",
});

export const PatientSearchLabel = () => ({
  padding: "0 0 .75rem 0",
  textAlign: "initial",
});

export const AvatarIcon = () => ({
  width: "4.5rem",
  height: "4.5rem",
});

export const FilterByStatus = () => ({
  minWidth: "3rem",
  marginLeft: "1rem",
});

export const excelLoader = () => ({
  color: colors.brand.primary,
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-4px",
  marginLeft: "-12px",
  zIndex: 1,
});

export const IconContainer = () => ({
  position: "relative",
  display: "inline-block",
  "&:hover .tooltip": {
    opacity: 1,
    zIndex: 2,
  },
});

export const IconName = () => ({
  position: "absolute",
  top: "-1rem",
  left: "25%",
  transform: "translateX(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: "0rem 0.4rem",
  borderRadius: "0.2rem",
  fontSize: "0.7rem",
  opacity: "0",
  transition: "opacity 0.3s ease",
});

export const SwitchBox = () => ({
  marginTop: "0.7rem",
  paddingLeft: "0.5rem",
});

export const GridPadding = () => ({
  paddingLeft: "1.5rem",
});

export const TableBoxMargin = () => ({
  margin: "0 0.5rem",
});

export const CreateButton = () => ({
  width: "4.5rem",
});
export const SortBox = () => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5rem",
  cursor: "pointer",
  svg: {
    "&:first-of-type": {
      marginBottom: "-0.8rem",
    },
  },
});
export const styles = {
  AvatarIcon,
  rowOne,
  body,
  removeavatarborder,
  avatarSize,
  backdrop,
  dividerRightWidth,
  dividerborder,
  layout,
  SwitchBox,
  GridPadding,
  HeaderTableRow,
  TableBoxMargin,
};

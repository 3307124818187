import { Reducer } from "redux";

import { get404InitialData } from "../utils";
import { ResumeIntakeResponse } from "../models/Api/Authorization";
import { ResumeIntakeDispatchTypes } from "../models/DispatchTypes/Authorization";
import { ResumeIntakeActionDispatchTypes } from "../constants/Authorization";

export const initialState: ResumeIntakeResponse = {
  loading: false,
  response: [],
};

const ResumeIntakeReducer: Reducer<
  ResumeIntakeResponse,
  ResumeIntakeDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ResumeIntakeActionDispatchTypes.RESUME_INTAKE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ResumeIntakeActionDispatchTypes.RESUME_INTAKE_SUCCESS: {
      return {
        ...state,
        response: get404InitialData(action.response, []),
        loading: false,
      };
    }
    case ResumeIntakeActionDispatchTypes.RESUME_INTAKE_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ResumeIntakeReducer;

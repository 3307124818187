import { Reducer } from "redux";

import { UrgencyStatusActionDispatchTypes } from "../constants/Patient";
import { UrgencyStatusMasterResponse } from "../models/Api/Patient";
import { UrgencyStatusDispatchTypes } from "../models/DispatchTypes/Patient";

export const initialState: UrgencyStatusMasterResponse = {
  loading: false,
  response: [
    {
      urgencyId: 0,
      urgencyStatus: "",
    },
  ],
};

const UrgencyStatusReducer: Reducer<
  UrgencyStatusMasterResponse,
  UrgencyStatusDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case UrgencyStatusActionDispatchTypes.URGENCY_STATUS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UrgencyStatusActionDispatchTypes.URGENCY_STATUS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default UrgencyStatusReducer;

import { Reducer } from "redux";

import { ReferralSubmitResponse } from "../models/Api/AllPatientRecord";
import { ReferralSubmitActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { ReferralSubmitDispatchTypes } from "../constants/AllPatientRecord";

export const initialState: ReferralSubmitResponse = {
  loading: false,

  response: {
    isCompleted: null,
    referralStage: [],
  },
  error: null,
};

const ReferralIntakeSubmit: Reducer<
  ReferralSubmitResponse,
  ReferralSubmitActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_RESET: {
      return {
        ...initialState,
      };
    }
  }
  return state;
};

export default ReferralIntakeSubmit;

import { Reducer } from "redux";

import { StaffingAssignStaffResponse } from "../models/Api/Staffing";
import { StaffingAssignStaffDispatchTypes } from "../models/DispatchTypes/Staffing";
import { StaffingAssignStaffActionDispatchTypes } from "../constants/Staffing";

export const initialState: StaffingAssignStaffResponse = {
  loading: false,
  response: {
    searchResponse: [],
    totalRows: 0,
  },
};

const StaffingAssignStaffReducer: Reducer<
  StaffingAssignStaffResponse,
  StaffingAssignStaffDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  }
  return state;
};

export default StaffingAssignStaffReducer;

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { isNull } from "lodash";

import {
  a11yProps,
  getLocalStorage,
  length,
  setLocalStorage,
} from "../../../../utils";
import { width100PCT } from "../../../../styles/mui/styles/widths";
import MypatientDetails from "../../containers/MypatientDetails";
import {
  PanelBox,
  RefTab,
  RefTabs,
  RefTabsBox,
  TabsPanelSectionBox,
} from "../../styles/style";
import { initialResponseFilter } from "../../../../reducers/StoreFilterPayload";
import { SearchFilterModel } from "../../../../models/Filter";

interface TabPanelProps {
  children?: React.ReactNode;
  className?: string;
  index: number;
  value: number;
}

function TabsPanelSection(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelSectionBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <PanelBox>{children}</PanelBox>}
    </TabsPanelSectionBox>
  );
}

export interface PropsFromDispatch {
  storeFilterPayload: (type: number, payload?: SearchFilterModel) => void;
}

type Props = PropsFromDispatch;

const MyPatients = ({ storeFilterPayload }: Props) => {
  const Tabs = [
    { id: 1, label: "My Patients" },
    { id: 2, label: "All Pendings" },
    { id: 3, label: "Incomplete Requests" },
    { id: 4, label: "Staffing Queue" },
    { id: 5, label: "PDN" },
  ];
  const [activeTab, setActiveTab] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param event is mandatory
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const filterStorage = getLocalStorage("myPatientFilterStorage");
    if (isNull(filterStorage)) {
      const storageData = {
        myPatient: initialResponseFilter.myPatient,
        allPending: initialResponseFilter.allPending,
        incompleteRequest: initialResponseFilter.incompleteRequest,
        staffingQueue: initialResponseFilter.staffingQueue,
        privateDutyAuth: initialResponseFilter.privateDutyAuth,
      };
      setLocalStorage("myPatientFilterStorage", storageData);
    } else {
      const storageData = { ...filterStorage };
      if (
        filterStorage.allPending &&
        filterStorage.allPending.serviceStatus &&
        "string" === typeof filterStorage.allPending.serviceStatus
      ) {
        filterStorage.allPending.serviceStatus =
          initialResponseFilter.allPending.serviceStatus;
        setLocalStorage("myPatientFilterStorage", filterStorage);
        storeFilterPayload(1, filterStorage);
      }
      if (
        !Object.prototype.hasOwnProperty.call(storageData, "privateDutyAuth")
      ) {
        storageData.privateDutyAuth = initialResponseFilter.privateDutyAuth;
        setLocalStorage("myPatientFilterStorage", storageData);
        storeFilterPayload(1, storageData);
      }
      if (
        Object.prototype.hasOwnProperty.call(storageData, "myPatient") &&
        !Object.prototype.hasOwnProperty.call(
          storageData.myPatient,
          "isPrivateDuty"
        )
      ) {
        storageData.myPatient.isPrivateDuty =
          initialResponseFilter.myPatient.isPrivateDuty;
        setLocalStorage("myPatientFilterStorage", storageData);
        storeFilterPayload(1, storageData);
      }
      if (
        Object.prototype.hasOwnProperty.call(storageData, "allPending") &&
        !Object.prototype.hasOwnProperty.call(
          storageData.allPending,
          "isPrivateDuty"
        )
      ) {
        storageData.allPending.isPrivateDuty =
          initialResponseFilter.allPending.isPrivateDuty;
        setLocalStorage("myPatientFilterStorage", storageData);
        storeFilterPayload(1, storageData);
      }
    }
  }, []);

  return (
    <>
      <Box sx={width100PCT}>
        <Box>
          <RefTabs value={activeTab} onChange={handleChange} sx={RefTabsBox}>
            {Tabs &&
              length(Tabs) &&
              Tabs.map((item, index) => {
                return (
                  <RefTab
                    key={index}
                    {...a11yProps(index)}
                    label={item.label}
                  />
                );
              })}
          </RefTabs>
        </Box>
        <TabsPanelSection value={activeTab} index={0}>
          <MypatientDetails activeTab={activeTab} />
        </TabsPanelSection>
        <TabsPanelSection value={activeTab} index={1}>
          <MypatientDetails activeTab={activeTab} />
        </TabsPanelSection>
        <TabsPanelSection value={activeTab} index={2}>
          <MypatientDetails activeTab={activeTab} />
        </TabsPanelSection>
        <TabsPanelSection value={activeTab} index={3}>
          <MypatientDetails activeTab={activeTab} />
        </TabsPanelSection>
        <TabsPanelSection value={activeTab} index={4}>
          <MypatientDetails activeTab={activeTab} />
        </TabsPanelSection>
      </Box>
    </>
  );
};

export default MyPatients;

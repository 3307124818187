import { Reducer } from "redux";

import { RemovePatientActionDispatchTypes } from "../constants/Patient";
import { RemovePatientDispatchTypes } from "../models/DispatchTypes/Patient";
import { RemovePatientResponse } from "../models/Api/Patient";

const initialState: RemovePatientResponse = {
  loading: false,
  response: {
    searchResponse:[],
    totalRows: 0
  },
};

const RemovePatientReducer: Reducer<
  RemovePatientResponse,
  RemovePatientDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RemovePatientActionDispatchTypes.REMOVE_PATIENT_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RemovePatientActionDispatchTypes.REMOVE_PATIENT_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case RemovePatientActionDispatchTypes.REMOVE_PATIENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
  }
  return state;
};

export default RemovePatientReducer;

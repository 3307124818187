import { Reducer } from "redux";

import { ReferralDisciplineActionDispatchTypes } from "../constants/Discipline";
import { referralDisciplineResponse } from "../models/Api/Discipline";
import { ReferralDisciplineDispatchTypes } from "../models/DispatchTypes/Discipline";

export const initialPostState: referralDisciplineResponse = {
  loading: false,
  response: {
    serviceRequestDisciplineId: -1,
    disciplineId: -1,
    requestDateTime: "",
    serviceRequestTypeId: null,
    serviceRequestTypeName: "",
    urgencyId: null,
    urgencyChanged: false,
    urgencyChangeDateTime: "",
    requestedVisits: 0,
    approvedVisits: 0,
    deniedVisits: -1,
    originallyRequestedVisits: 0,
    acceptedVisits: false,
    serviceStatusId: null,
    serviceStatusReasonId: null,
    hcpcs: "",
    hcpcsDescription: "",
    serviceNumber: -1,
    effectiveFromDate: "",
    effectiveThroughDate: "",
    requestCreatedOn: "",
    requestCreatedBy: "",
    decidedBy: "",
    decidedDateTime: "",
    adjudicatedBy: "",
    adjudicatedDateTime: "",
    inRuleSuggestion: "",
    userCreated: null,
    facilityTypeId: null,
    rationaleTemplateId: null,
    rationale: "",
    resourceDescription: "",
    agreedByPhysician: false,
    agreementDateTime: "",
    stageName: "",
    otherRationale: "",
    otherResourceDescription: "",
    createdUserFullName: "",
    statusReasonRequester: null,
    receivedMethodId: null,
    assignedUserId: null,
  },
};

const ReferralDisciplineReducer: Reducer<
  referralDisciplineResponse,
  ReferralDisciplineDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_RESET: {
      return {
        ...state,
        response: initialPostState.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReferralDisciplineReducer;

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import { ReviewDispatchTypes } from "../../../../../../../models/DispatchTypes/Review";
import ReviewRequestDetails from "../components/ReviewRequestDetail";
import { getReview } from "../../../../../../../actions/Review";

const mapStateToProps = ({ reviewState }: ApplicationState) => ({
  requestDetail: reviewState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ReviewDispatchTypes>
  >
) => {
  return {
    getReview: (referralId: number) => {
      dispatch(getReview(referralId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewRequestDetails);

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, isUndefined } from "lodash";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import useMenuItem from "../../hooks/useMenuItem";
import { Checkbox } from "../custom/checkbox";
import {
  Cursor,
  selectBox,
  StyledPopperCheckBoxAutocomplete,
} from "../styles/styles";
import {
  IconFaSearch,
  Search,
} from "../../routes/AdminCenter/routes/UserManagement/styles/userProfile";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = "Search";

export interface ListOptionsData {
  options: any[];
  value: any | null;
  handleChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => void;
  control: any;
  name: string;
  sx?: any;
  labelKey: string;
  label?: string;
}
const AutocompleteMultiCheckInput: React.FC<ListOptionsData> = ({
  options,
  handleChange,
  sx,
  name,
  control,
  value,
  labelKey,
  label,
}: ListOptionsData) => {
  const { open, onOpen, onClose } = useMenuItem();

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Autocomplete
          multiple
          sx={sx}
          options={!isEmpty(options) ? options : []}
          disableCloseOnSelect
          PopperComponent={StyledPopperCheckBoxAutocomplete}
          getOptionLabel={(option) => option[labelKey]}
          renderOption={(props, option, { selected }) => {
            return (
              <ListItem {...props} key={uuidv4()} sx={selectBox}>
                <Checkbox checked={selected} />
                <ListItemText primary={option[labelKey]} sx={Cursor} />
              </ListItem>
            );
          }}
          renderInput={(params) => (
            <Box>
              {!isEmpty(label) && !isUndefined(label) && (
                <Box pb={"0.3rem"}>
                  <Typography
                    variant="body1"
                    color={colors.fonts[2]}
                    fontWeight={fontWeight.Weight[2]}
                  >
                    {label}:
                  </Typography>
                </Box>
              )}
              <Box sx={Search}>
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Choose"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <IconFaSearch />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              </Box>
            </Box>
          )}
          open={open}
          onOpen={() => onOpen()}
          onClose={() => onClose()}
          onBlur={() => onClose()}
          onChange={handleChange}
          value={value}
          renderTags={() => null}
        />
      )}
    />
  );
};
export default AutocompleteMultiCheckInput;

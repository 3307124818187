import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { isNil, isNull, isUndefined } from "lodash";
import { useEffect, useState } from "react";

import {
  AlignCenter,
  ButtonStyles,
  CheckboxFormControl,
  checkBoxStyle,
  DisplayFlex,
  ErrorMessage,
  ModalGrid,
  PatientTableHeadCell,
  RemoveTableCellStyle,
  TableCellRendererStyle,
  TableContainerStyle,
  TableMainHead,
  TableMainHeadRow,
  TableNameDetails,
  TableRowStyle,
  TableStyle,
  TableStyleModal,
  Width,
} from "../../styles/modal";
import { PatientDetailBox } from "../../styles/style";
import { PutRemovePatientData } from "../../../../models/Patient";
import {
  PatientList,
} from "../../../../constants/AllPatientRecord";
import { colors } from "../../../../styles/colors";
import { DateFormats } from "../../../../constants/Date";
import { getValue, length, serviceStatusColor, urgencyStatusColor } from "../../../../utils";
import { formatDate } from "../../../../utils/DateTime";
import { User, UserDetailsPayload } from "../../../../models/User";
import fontWeight from "../../../../styles/mui/fontWeight";
import {
  RemoveStaffingPatient,
  StaffingReferralDetail,
} from "../../../../models/Staffing";
import { StaffingStatus } from "../../../../constants/Staffing";
import {
  ModalGridContainer,
  TooltipStyle,
} from "../../../../styles/common/style";

export interface PropsFromState {
  activeTab: number;
  allSelect: boolean;
  handleSort: (property: any) => any;
  openModal: boolean;
  patientList: any;
  selectedList: any | undefined;
  setAllSelect: (value: boolean) => void;
  setCheckRemovePatient: (value: boolean) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPatientSelected: React.Dispatch<React.SetStateAction<any>>;
  setAllSelectedPatients: React.Dispatch<React.SetStateAction<any>>;
  user: UserDetailsPayload;
  userList: User[];
  setPage: (page: number) => void;
  list: any;
  action?: string;
}
export interface PropsFromDispatch {
  updateRemovePatientList: (
    payload: PutRemovePatientData,
    staffId: number
  ) => void;
  postRemoveStaffingPatientList: (payload: RemoveStaffingPatient) => void;
}
type Props = PropsFromState & PropsFromDispatch;
const RemovePatient = ({
  handleSort,
  openModal,
  patientList,
  selectedList,
  setAllSelect,
  setCheckRemovePatient,
  setOpenModal,
  setPatientSelected,
  setAllSelectedPatients,
  updateRemovePatientList,
  user,
  setPage,
  list,
  activeTab,
  postRemoveStaffingPatientList,
}: Props) => {
  const [selectedPatients, setSelectedPatients] = useState<any>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);

  const isAllSelected =
    selectedPatients.length > 0 && selected.length === selectedPatients.length;

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value === "all") {
      if (activeTab === 0) {
        const newData = selectedPatients.map(
          (list: any) => list.serviceRequestDisciplineId
        );
        setSelected(selected.length === selectedPatients.length ? [] : newData);
      } else {
        const newData = selectedPatients.map((list: any) => list.referralId);
        setSelected(selected.length === selectedPatients.length ? [] : newData);
      }
      return;
    }
    const list = [...selected];
    const index = list.indexOf(Number(value));

    index === -1 ? list.push(value) : list.splice(index, 1);
    const newList = list.map(Number);
    setSelected(newList);
  };

  useEffect(() => {
    if (selectedList) {
      setSelectedPatients(selectedList ? selectedList : []);
      let newIds = [];
      let listPropertyName = "referralId";
      if (activeTab === 0) {
        listPropertyName = "serviceRequestDisciplineId";
      }
      newIds = selectedList.map((list: any) => list[listPropertyName]);
      const newRequestId = newIds.map(Number);
      setSelected(newRequestId);
    }
  }, [selectedList]);

  const handleRemove = () => {
    setPage(1);
    if (!isNil(selected)) {
      if (activeTab === 0) {
        const data: PutRemovePatientData = {
          assignedUserId: user.userId,
          createdBy: user.email,
          serviceRequestDisciplineIds: selected,
        };
        updateRemovePatientList(data, user.userId);
        setCheckRemovePatient(true);
        setOpenModal(false);
        setAllSelect(false);
        setPatientSelected([]);
        setAllSelectedPatients([]);
      } else {
        const filteredSelectedList = selectedList.filter(
          (list: StaffingReferralDetail) => selected.includes(list.referralId)
        );
        const isRemove = filteredSelectedList.every(
          (list: StaffingReferralDetail) =>
            list.staffingStatus === StaffingStatus.STAFFED ||
            list.isReferralActive === false
        );

        const removeStaffer = filteredSelectedList.map(
          (list: StaffingReferralDetail) => {
            return {
              referralId: list.referralId,
              staffingStatus: list.staffingStatus,
              isReferralActive: list.isReferralActive,
            };
          }
        );
        if (isRemove) {
          postRemoveStaffingPatientList({ removeStaffer: removeStaffer });
          setCheckRemovePatient(true);
          setOpenModal(false);
          setAllSelect(false);
          setErrorFlag(false);
          setPatientSelected([]);
          setAllSelectedPatients([]);
        } else {
          setErrorFlag(true);
        }
      }
    }
  };

  const handleClose = () => {
    setPatientSelected([]);
    setAllSelectedPatients([]);
    setOpenModal(false);
    setAllSelect(false);
    setPage(1);
  };

  const handleCheck = (id: number) => {
    return selected.includes(id);
  };

  const tableRenderer = () => {
    return (
      <TableContainer sx={TableContainerStyle}>
        <Table sx={TableStyleModal} stickyHeader aria-label="sticky table">
          <TableMainHead>
            <TableMainHeadRow>
              {patientList.map((tablecell: any, index: number) => {
                return (
                  <TableCell
                    key={index}
                    sx={PatientTableHeadCell}
                    onClick={() => handleSort(tablecell.label)}
                  >
                    <Box sx={tablecell.style?.cell}>
                      <Box sx={AlignCenter}>
                        <Typography
                          variant="body2"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.black[14]}
                        >
                          {tablecell.label}
                        </Typography>
                        {tablecell.label === "" && (
                          <Box sx={checkBoxStyle}>
                            <Checkbox
                              value={"all"}
                              onChange={handleChange}
                              checked={isAllSelected}
                              size="small"
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                );
              })}
            </TableMainHeadRow>
          </TableMainHead>
          <TableBody>
            {length(selectedList) ? (
              selectedList.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex} sx={TableRowStyle}>
                  <TableCell
                    sx={RemoveTableCellStyle(selectedList.length, rowIndex)}
                  >
                    <FormControlLabel
                      sx={CheckboxFormControl}
                      control={
                        <Checkbox
                          value={
                            activeTab === 0
                              ? row.serviceRequestDisciplineId
                              : row.referralId
                          }
                          onChange={handleChange}
                          checked={handleCheck(
                            activeTab === 0
                              ? row.serviceRequestDisciplineId
                              : row.referralId
                          )}
                          size="small"
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  {list.map((data: PatientList, index: number) => {
                    if (data.value === list[0].value) {
                      return (
                        <TableCell
                          sx={TableNameDetails(selectedList.length, rowIndex)}
                          key={index}
                        >
                          <Typography
                            variant="subtitle2"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.fonts[18]}
                          >
                            {row[data.value]}
                          </Typography>
                        </TableCell>
                      );
                    } else if (
                      (activeTab === 3 && data.value === list[9].value) ||
                      (!isNull(getValue(data, "value", null)) &&
                        data.value === "providerNpi")
                    ) {
                      return (
                        <TableCell
                          sx={TableCellRendererStyle(
                            selectedList.length - 1 === rowIndex
                          )}
                          key={index}
                        >
                          {!isUndefined(data.fieldValue) &&
                            !isNull(row[data.fieldValue]) &&
                            !isNull(row[data.value]) && (
                              <Tooltip
                                title={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={fontWeight.Weight[4]}
                                  >
                                    {row[data.fieldValue]}
                                  </Typography>
                                }
                                arrow
                                slotProps={{
                                  popper: {
                                    sx: TooltipStyle,
                                  },
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={data.fontWeight}
                                  color={colors.black[5]}
                                >
                                  {row[data.value]}
                                </Typography>
                              </Tooltip>
                            )}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        sx={TableCellRendererStyle(
                          selectedList.length - 1 === rowIndex
                        )}
                        key={index}
                      >
                        <Typography
                          variant="subtitle2"
                          fontWeight={data.fontWeight}
                          color={
                            data.value === list[1].value ? urgencyStatusColor(row[data.value]) :
                              data.value === list[6].value
                                ? serviceStatusColor(row[data.value])
                                : colors.black[5]
                          }
                        >
                          {data.value === list[2].value
                            ? formatDate(
                                row[data.value],
                                DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
                              )
                            : row[data.value]}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={activeTab === 0 ? 12 : 9}>
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ModalGridContainer}
    >
      <Box sx={ModalGrid}>
        <Grid container pt={"0.5rem"}>
          <Grid item xs={12} pl={"1rem"}>
            <Box>
              <Typography
                variant="h4"
                color={colors.fonts[4]}
                fontWeight={fontWeight.Weight[5]}
              >
                Remove from list
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} pl={"1rem"}>
            <Box mt={".7rem"}>
              <Typography
                variant="h6"
                color={colors.fonts[4]}
                fontWeight={fontWeight.Weight[3]}
              >
                Are you sure you want to remove the patient from list
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={ErrorMessage}>
            {errorFlag && (
              <Box>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.red[100]}
                >
                  Confirm authorization and staffing status before removing from
                  list
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <PatientDetailBox>
              <Box sx={DisplayFlex}>
                <Grid sx={Width}>
                  <Grid sx={TableStyle}>{tableRenderer()}</Grid>
                </Grid>
              </Box>
            </PatientDetailBox>
          </Grid>

          <Grid container sx={ButtonStyles}>
            <Button variant="outlined" onClick={handleClose}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleRemove}
              disabled={length(selected) ? false : true}
            >
              CONFIRM
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default RemovePatient;

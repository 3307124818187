import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import NoteTemplateList from "../components/NoteTemplateList";
import { ApplicationState } from "../../../../../store/ApplicationState";

import { GetNotesSectionByVersionId } from "../../../../../actions/Notes";
import { NotesApprovalStatusMasterDispatchTypes } from "../../../../../models/DispatchTypes/Master";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({ GetadminNotesState }: ApplicationState) => ({
  NotesDataById: GetadminNotesState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<NotesApprovalStatusMasterDispatchTypes>
  >
) => {
  return {
    GetNotesSectionByVersionId: (noteTemplateVersionId: number) => {
      dispatch(GetNotesSectionByVersionId(noteTemplateVersionId));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteTemplateList);

import { Button, Grid, Typography } from "@mui/material";

import { colors } from "../../../../../styles/colors";
import {
  CompletedTitlestyle,
  FaCheckCircleIcon,
  Gridbody,
  HeaderTitle,
} from "../styles/ResumeIntake";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { getValue } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { ReAuthComplete } from "../../../../../models/Authorization";
import fontWeight from "../../../../../styles/mui/fontWeight";

export interface PropsFromState {
  handleRef: React.MouseEventHandler;
  reauthCompleteData: ReAuthComplete;
}

type AllProps = PropsFromState;

export const ReauthCompleted: React.FC<AllProps> = ({
  reauthCompleteData,
  handleRef,
}) => {
  const handleClick = (e: any) => {
    handleRef(e);
  };

  return (
    <>
      <Grid container sx={Gridbody}>
        <Grid item xs={10} sx={displayFlex}>
          <FaCheckCircleIcon />
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[6]}
            color={colors.black[2]}
            sx={HeaderTitle}
          >
            Rauth. ID:{" "}
            {reauthCompleteData && reauthCompleteData.serviceRequestId}
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
            sx={CompletedTitlestyle}
          >
            is created successfully on{" "}
            {formatDate(getValue(reauthCompleteData, "completedOn"))}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            sx={{ marginLeft: "3rem" }}
            onClick={handleClick}
          >
            VIEW SERVICES
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ReauthCompleted;

import { FaInfoCircle } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { alpha, styled } from "@mui/system";
import { Box } from "@mui/material";

import { colors, others } from "../../../../styles/colors";

export const ModalGrid = () => ({
    position: "absolute",
    top: "20%",
    left: "5%",
    width: "90%",
    height: "auto",
    bgcolor: "background.paper",
    padding: "1rem",
    borderRadius: "1.5rem",
    paddingTop: "1rem",
    marginLeft: "1rem",
});

export const RequiredSymbol = () => ({
    paddingTop: "0.1rem",
    paddingLeft: "0.4rem",
});

export const AutoComplete = {
    width: "18rem",
    borderBottom: others.otherColors[35],
    borderBottomWidth: "0.06rem",
    marginRight: "1.5rem",
};

export const Search = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
};

export const ErrorMessage = () => ({
    paddingTop: "0.5rem",
    paddingLeft: "1rem",
});

export const Width = () => ({
    width: "100%",
});

export const SelectedReferralsBox = () => ({
    padding: "1rem 0 1rem 2rem",
});

export const TableStyle = () => ({
    width: "100%",
    boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
    backgroundColor: colors.backgroundColor[2],
    borderRadius: "0.5rem",
    padding: "1rem",
});

export const ButtonStyles = () => ({
    justifyContent: "flex-end",
    padding: "1rem ",
});

export const BackButton = () => ({
    width: "8rem",
});

export const AssignButton = () => ({
    width: "10.2rem",
});

export const DisplayFlex = () => ({
    display: "flex",
    padding: "1rem",
  });
export const DisciplineModalGrid = () => ({
    position: "absolute",
    left: "25%",
    top: "35%",
    width: "39%",
    height: "auto",
    bgcolor: "background.paper",
    padding: "2.5rem 1rem 1rem 1.5rem",
    borderRadius: "1.5rem",
    marginLeft: "1rem",
});

export const IconFaSearch = styled(FaSearch)(() => ({
    display: "flex",
    color: alpha(colors.fonts[4], 0.32),
    justifyContent: "left",
  }));

  export const PatientDetailBox = styled(Box)(() => ({
    backgroundColor: colors.backgroundColor[7],
    borderRadius: "0.7rem",
    margin: "1rem .25rem 0",
    padding: "0.5rem 0.6rem 1rem 0.4rem",
  }));

export const DisciplineGridbodyInfo = () => ({
    background: colors.backgroundColor[7],
    opacity: 1,
    padding: "0.5rem 0.5rem 0.5rem 1rem",
    marginLeft: "1.2rem",
    borderRadius: "0.6rem",
    display: "flex",
    marginTop: "-0.5rem"
});

export const IconFaInfoAlt = styled(FaInfoCircle)(() => ({
    color: colors.fonts[18],
    cursor: "pointer",
    marginRight: "1.5rem",
}));
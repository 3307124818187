import { Reducer } from "redux";

import { ReceivedMethodMasterResponse } from "../models/Api/Patient";
import { SaveReceivedMethodDispatchTypes } from "../models/DispatchTypes/Patient";
import { SaveReceivedMethodActionDispatchTypes } from "../constants/Patient";

const initialState: ReceivedMethodMasterResponse = {
  loading: false,
  response: [
    {
      isIntegrationMethod: false,
      receivedMethodId: -1,
      receivedMethodName: "",
    },
  ],
};

const ReceivedMethodReducer: Reducer<
  ReceivedMethodMasterResponse,
  SaveReceivedMethodDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case SaveReceivedMethodActionDispatchTypes.SAVE_RECEIVED_METHOD_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SaveReceivedMethodActionDispatchTypes.SAVE_RECEIVED_METHOD_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReceivedMethodReducer;

import { Reducer } from "redux";

import { UpdateDisciplineActionDispatchTypes } from "../constants/Discipline";
import { UpdateDisciplineResponse } from "../models/Api/Discipline";
import { UpdateDisciplineDispatchTypes } from "../models/DispatchTypes/Discipline";

export const initialPutState: UpdateDisciplineResponse = {
  loading: false,
  response: {
    serviceRequestDisciplineId: -1,
    disciplineId: -1,
    requestDateTime: "",
    serviceRequestTypeId: 0,
    serviceRequestTypeName: "",
    urgencyId: 0,
    urgencyChanged: false,
    urgencyChangeDateTime: "",
    requestedVisits: -1,
    approvedVisits: -1,
    deniedVisits: -1,
    originallyRequestedVisits: -1,
    acceptedVisits: false,
    serviceStatusId: 0,
    serviceStatusReasonId: 0,
    hcpcs: "",
    hcpcsDescription: "",
    serviceNumber: -1,
    effectiveFromDate: "",
    effectiveThroughDate: "",
    requestCreatedOn: "",
    requestCreatedBy: "",
    decidedBy: "",
    decidedDateTime: "",
    adjudicatedBy: "",
    adjudicatedDateTime: "",
    inRuleSuggestion: "",
    userCreated: null,
    facilityTypeId: 0,
    rationaleTemplateId: -1,
    rationale: "",
    resourceDescription: "",
    agreedByPhysician: false,
    agreementDateTime: "",
    stageName: "",
    otherRationale: "",
    otherResourceDescription: "",
    requestingUserId: -1,
    statusReasonRequester: null,
    receivedMethodId: null,
    assignedUserId: null
  },
  error: null,
};

const DisciplineReducer: Reducer<
  UpdateDisciplineResponse,
  UpdateDisciplineDispatchTypes
> = (state = initialPutState, action) => {
  switch (action.type) {
    case UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    case UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET: {
      return initialPutState;
    }
  }
  return state;
};

export default DisciplineReducer;

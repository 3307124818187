import { Reducer } from "redux";

import { AgencyCommunicationNoteResponse } from "../models/Api/Notes";
import { AgencyCommunicationNoteDispatchTypes } from "../models/DispatchTypes/Notes";
import { PostAgencyCommunicationNoteActionDispatchTypes } from "../constants/Notes";

export const initialState: AgencyCommunicationNoteResponse = {
  loading: false,
  response: false
};

const AgencyCommunicationNoteReducer: Reducer<
AgencyCommunicationNoteResponse,
AgencyCommunicationNoteDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PostAgencyCommunicationNoteActionDispatchTypes.AGENCY_COMMUNICATION_NOTE_RESET: {
      return initialState;
    }
  }
  return state;
};

export default AgencyCommunicationNoteReducer;

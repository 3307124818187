import MyPatients from "../containers/MyPatients";

const Mypatient = () => {
  return (
    <div>
      <MyPatients />
    </div>
  );
};

export default Mypatient;

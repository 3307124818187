import { Box, Button, Grid } from "@mui/material";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";
import React from "react";
import { isEmpty } from "lodash";

import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { PhysicianDetails } from "../../../../../models/PhysicianDetails";
import { FormInputBox, SwitchGrid, Body } from "../styles/OrderingPhysician";
import { rules } from "../../../../../utils/validation/Validation";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import NppesSearchComponent from "../../../../../components/custom/nppesSearchComponent/container/NppesSearchComponent";
import { AutocompleteDropDownPlacement } from "../../../../../constants/PhysicianDetails";
import { GetNPPESDataActionDispatchTypes } from "../../../../../constants/PhysicianDetails";
import { PhysicianInfoSave } from "../../../components/ActiveAuthCommon/PatientHeader/style";

export interface PropsFromState {
  control?: any;
  getValues?: any;
  setValue?: any;
  reset?: any;
  choosePhysician: (option: PhysicianDetails) => void;
  addManually?: boolean;
  handleClearPhysician?: () => void;
  physician: PhysicianDetails | null;
  names: any;
  name: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setAddManually?: (value: boolean) => void;
  setOpenAutocomplete: Dispatch<SetStateAction<boolean>>;
  openAutocomplete: boolean;
  autocompleteDropDownPosition?: AutocompleteDropDownPlacement;
  autocompleteDropdownPadding?: number;
  isReadOnly?: boolean;
  noValidate?: boolean;
  showClearBtn?: boolean;
}
export interface PropsFromDispatch {
  resetStates: (actionType: string[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const PhysicianInputForm: React.FC<AllProps> = ({
  addManually,
  choosePhysician,
  control,
  getValues,
  handleClearPhysician,
  names,
  open,
  reset,
  setOpen,
  setValue,
  name,
  setAddManually,
  resetStates,
  openAutocomplete,
  setOpenAutocomplete,
  autocompleteDropDownPosition,
  autocompleteDropdownPadding,
  isReadOnly,
  noValidate,
  showClearBtn,
}: AllProps) => {
  const [disbableTaxId, setDisableTaxId] = useState(false);
  const handleSelect = (option: any) => {
    choosePhysician(option);
    if (!isEmpty(name)) {
      setValue(name, option.physicianName || null);
    }
    if (!isEmpty(option.taxId) && option.taxId !== undefined) {
      setDisableTaxId(true);
    }
    setAddManually && setAddManually(false);
    resetStates &&
      resetStates([GetNPPESDataActionDispatchTypes.GET_NPPES_DATA_RESET]);
    setOpen && setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <NppesSearchComponent
            NppesOpen={open}
            NppesSetOpen={setOpen}
            handleSelect={handleSelect}
            openAutocomplete={openAutocomplete}
            setOpenAutocomplete={setOpenAutocomplete}
            autocompleteDropDownPosition={autocompleteDropDownPosition}
            autocompleteDropdownPadding={autocompleteDropdownPadding}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={[Body, SwitchGrid(isReadOnly ? isReadOnly : false)]}
        >
          <PhysicianFormInputs
            control={control}
            reset={reset}
            getValues={getValues}
            setValue={setValue}
            names={names}
            addManually={addManually}
            noValidate={noValidate}
            disbableTaxId={disbableTaxId}
            handleClearPhysician={handleClearPhysician}
            showClearBtn={showClearBtn}
          />
        </Grid>
      </Grid>
    </>
  );
};

type PhysicianFormInputsProps = {
  control: any;
  reset: any;
  getValues: any;
  setValue: any;
  names: any;
  isHeader?: boolean;
  addManually?: boolean;
  noValidate?: boolean;
  disbableTaxId?: boolean;
  handleClearPhysician?: () => void;
  showClearBtn?: boolean;
};
export const PhysicianFormInputs = (props: PhysicianFormInputsProps) => {
  const {
    control,
    reset,
    getValues,
    setValue,
    names,
    isHeader,
    noValidate,
    disbableTaxId,
    handleClearPhysician,
    showClearBtn,
  } = props;

  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;
  const labels = [
    {
      label: "Street Name 1: ",
      name: names.address[0],
      helper: noValidate ? false : rules.streetName1,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: names.address[1],
      textLength: 100,
    },
    {
      label: "County: ",
      name: names.address[3],
      helper: noValidate ? false : rules.physicianCounty,
      textLength: 100,
    },
    {
      label: "City: ",
      name: names.address[2],
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: names.address[4],
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: names.address[5],
      helper: noValidate ? false : rules.physicianZipcode,
      textLength: 10,
    },
  ];

  return (
    <>
      <Grid item xs={12} pt={isHeader ? "0.5rem" : "1.5rem"}>
        <Grid container>
          <Grid item xs={2.8}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.physicianName}
                control={control}
                label="Physician Name:"
                fieldrequired={"true"}
                helper={noValidate ? false : rules.physicianName}
                placeholder="Add here..."
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.physicianNpiId}
                control={control}
                label="NPI:"
                textLength={10}
                fieldrequired={"true"}
                helper={noValidate ? false : rules.physicianNpi}
                placeholder="Add here..."
                inputType="onlyNumber"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.taxId}
                control={control}
                label="Tax ID:"
                textLength={14}
                fieldrequired={"true"}
                disabled={disbableTaxId && disbableTaxId}
                helper={noValidate ? false : rules.physicianTaxId}
                placeholder="Add here..."
              />
            </Box>
          </Grid>
          <Grid item xs={2.8}>
            <Box sx={FormInputBox}>
              <PhoneFaxExtInput
                label="Phone Number:"
                control={control}
                phoneFaxName={names.phoneNo}
                extName={names.phoneExt}
                rules={
                  noValidate
                    ? false
                    : isHeader
                    ? rules.mobilePhone
                    : rules.phone
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box sx={FormInputBox}>
              <PhoneFaxExtInput
                label="Fax:"
                control={control}
                phoneFaxName={names.fax}
                rules={rules.fax}
                isFax
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={showClearBtn === false ? 12 : 11}>
          <AddressFormInputUseForm
            control={control}
            searchInput={searchInput}
            reset={reset}
            getValues={getValues}
            setValue={setValue}
            labels={labels}
            addressLabel="ADDRESS DETAILS:"
            addressVariant="subtitle1"
          />
        </Grid>
        {showClearBtn === true && (
          <Grid item xs={1} sx={PhysicianInfoSave}>
            <Button
              variant="outlined"
              onClick={() => handleClearPhysician && handleClearPhysician()}
            >
              CLEAR
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PhysicianInputForm;

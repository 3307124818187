import { Button, Link, Typography, Breadcrumbs, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import {
  BackButtonHolder,
  BreadCumbButtonBoxCreateReferral,
  BreadcumbLeftGridCreateReferral,
  BreadcumbPaperCreateReferral,
  BreadcumbRightGridCreateReferral,
  NewReferralLabel,
  ButtonStyle,
} from "../styles/styles";
import { colors } from "../../../../styles/colors";
import { BackButton, BreadcrumbsRoot } from "../../../../styles/common/style";
import fontWeight from "../../../../styles/mui/fontWeight";
import { ModuleLinks, PatientRecordsModule } from "../../../AllRoutes";
import { PatientInformationViewResponse } from "../../../../models/Api/PatientInformation";
import { CheckEligibilityResponse } from "../../../../models/Api/AllPatientRecord";
import Permission from "../../../Permission";
import { PatientRecordsAccess } from "../../../../constants/Permission";
import { PatientInformationReceivedActionDispatchTypes } from "../../../../constants/PatientInformation";

export interface PropsFromState {
  onSubmit?: any;
  TabFlag?: any;
  setInfoReceivedFlag?: any;
  setCreateRecordStatus?: any;
  saveDataLoading?: boolean;
  patientInformationReset: () => void;
  data: PatientInformationViewResponse;
  checkEligibilityData: CheckEligibilityResponse;
  handleCheckEligibility?: () => void;
  authorizationHistoryReset: () => void;
  setCallUpdateHistory?: () => void;
  setCheckEligibilityBtnClick?: (value: boolean) => void
  resetStates?: (actionType: string[]) => void;
}
type AllProps = PropsFromState

const CreateReferralHeader: React.FC<AllProps> = ({
  onSubmit,
  TabFlag,
  setInfoReceivedFlag,
  setCreateRecordStatus,
  saveDataLoading,
  handleCheckEligibility,
  patientInformationReset,
  authorizationHistoryReset,
  resetStates
}: AllProps) => {
  const navigate = useNavigate();

  const handleBackOp = () => {
    patientInformationReset();
    authorizationHistoryReset();
    navigate(-1);
  };
  const handleEligibility = () => {
    if (handleCheckEligibility) {
      handleCheckEligibility();
      resetStates && resetStates([
        PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_RESET,
      ]);
    }
    setInfoReceivedFlag(true);
  };

  const saveAndExit = () => {
    onSubmit();
    setCreateRecordStatus("saveAndExit");
  };

  return (
    <BreadcumbPaperCreateReferral elevation={3}>
      <Grid container>
        <BreadcumbLeftGridCreateReferral item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid>
                  <Breadcrumbs
                    separator={
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[4]}
                        color={colors.black[4]}
                      >
                        &nbsp;&gt;&nbsp;
                      </Typography>
                    }
                    aria-label="breadcrumb"
                    sx={BreadcrumbsRoot}
                  >
                    <Box
                      onClick={() =>
                        navigate(
                          ModuleLinks(PatientRecordsModule.PATIENT_RECORD)
                        )
                      }
                    >
                      <Link component="button" key="1">
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[4]}
                          color={colors.fonts[8]}
                        >
                          Patient records
                        </Typography>
                      </Link>
                    </Box>
                    <Typography
                      key="2"
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[4]}
                      color={colors.black[4]}
                    >
                      Patient Details
                    </Typography>
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={BackButtonHolder}>
              <Grid container>
                <Grid>
                  <Button
                    variant="contained"
                    sx={BackButton}
                    onClick={handleBackOp}
                  >
                    BACK
                  </Button>
                </Grid>
                <Grid sx={NewReferralLabel}>
                  <Typography
                    variant="h2"
                    fontWeight={fontWeight.Weight[6]}
                    color={colors.black[3]}
                  >
                    PATIENT DETAILS
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BreadcumbLeftGridCreateReferral>
        {TabFlag === "patientInformation" ? (
          <BreadcumbRightGridCreateReferral item xs={3.8}>
            <BreadCumbButtonBoxCreateReferral>
              <Permission
                controlId={`${PatientRecordsAccess.CHECK_ELIGIBILITY_CREATE}||`}
              >
                <Button variant="contained" onClick={handleEligibility}>
                  CHECK ELIGIBILITY
                </Button>
              </Permission>
            </BreadCumbButtonBoxCreateReferral>
          </BreadcumbRightGridCreateReferral>
        ) : (
          <BreadcumbRightGridCreateReferral item xs={3.8}>
            <BreadCumbButtonBoxCreateReferral>
              <Permission
                controlId={`${PatientRecordsAccess.PATIENT_DETAILS_FORM_SAVE_AND_EXIT_CREATE}||`}
              >
                <Button
                  variant="contained"
                  onClick={saveAndExit}
                  disabled={saveDataLoading}
                  sx={ButtonStyle(saveDataLoading)}
                >
                  <Typography
                    variant="subtitle2"
                    color={
                      saveDataLoading ? colors.primary.main : colors.white[1]
                    }
                    fontWeight={fontWeight.Weight[4]}
                  >
                    SAVE AND EXIT
                  </Typography>
                </Button>
              </Permission>
            </BreadCumbButtonBoxCreateReferral>
          </BreadcumbRightGridCreateReferral>
        )}
      </Grid>
    </BreadcumbPaperCreateReferral>
  );
};

export default CreateReferralHeader;

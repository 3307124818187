import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { getResumeIntakeInformation } from "../../../../../actions/AuthorizationDetails";
import { ResumeIntake as ResumeIntakeInformation } from "../../../../../models/Authorization";
import { ResumeIntakeDispatchTypes } from "../../../../../models/DispatchTypes/Authorization";
import ResumeIntake from "../components/ResumeIntake";

const mapStateToProps = ({ ResumeIntakeState }: ApplicationState) => ({
    resumeIntakeInformation: ResumeIntakeState.response as ResumeIntakeInformation[]
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<
        ApplicationState,
        Record<string, never>,
        Action<ResumeIntakeDispatchTypes>
    >
) => {
    return {
        getResumeIntakeInformation: (referralId: number) => {
            dispatch(getResumeIntakeInformation(referralId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeIntake);


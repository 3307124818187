import { Reducer } from "redux";

import { PriorAuthReferralSourceActionDispatchTypes } from "../constants/Master";
import { PriorAuthReferralSourceMasterResponse } from "../models/Api/Master";
import { PriorAuthReferralSourceDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: PriorAuthReferralSourceMasterResponse = {
  loading: false,
  response: [],
};

const PriorAuthReferralSourceReducer: Reducer<
  PriorAuthReferralSourceMasterResponse,
  PriorAuthReferralSourceDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PriorAuthReferralSourceActionDispatchTypes.PRIOR_AUTH_REFERRAL_SOURCE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PriorAuthReferralSourceActionDispatchTypes.PRIOR_AUTH_REFERRAL_SOURCE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PriorAuthReferralSourceReducer;

import { connect } from "react-redux";

import { ApplicationState } from "../../../../../../../store/ApplicationState";
import ReAuthReviewServiceRequest from "../components/ReAuthReviewServiceRequest";

const mapStateToProps = ({ reAuthReviewState }: ApplicationState) => ({
  reAuthReviewDetails: reAuthReviewState,
});

export default connect(mapStateToProps)(ReAuthReviewServiceRequest);

export const surveyJSON = {
  description: "Add Notes for selected template :",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "question1",
          isRequired: true,
        },
        {
          type: "text",
          name: "question3",
          startWithNewLine: false,
          title: "Phone No Q2",
          inputType: "tel",
        },
        {
          type: "multipletext",
          name: "question4",
          items: [
            {
              name: "text1",
            },
            {
              name: "text2",
            },
          ],
        },
        {
          type: "text",
          name: "question2",
          startWithNewLine: false,
          title: "Email Q3",
          isRequired: true,
          inputType: "email",
        },
        {
          type: "dropdown",
          name: "question5",
          isRequired: true,
          choices: ["Item 1", "Item 2", "Item 3"],
        },
        {
          type: "checkbox",
          name: "question6",
          startWithNewLine: false,
          choices: ["Item 1", "Item 2", "Item 3"],
        },
        {
          type: "radiogroup",
          name: "question7",
          startWithNewLine: false,
          isRequired: true,
          choices: ["Item 1", "Item 2", "Item 3"],
        },
        {
          type: "text",
          name: "question8",
          inputType: "datetime-local",
        },
        {
          type: "boolean",
          name: "question9",
          startWithNewLine: false,
          isRequired: true,
        },
        {
          type: "tagbox",
          name: "question10",
          startWithNewLine: false,
          choices: ["Item 1", "Item 2", "Item 3"],
        },
      ],
    },
  ],
};


import React from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";
import { styles, HeaderBox } from "../styles/styles";

type HeadProps = {
  title: string;
  description: string;
  back?: boolean;
  dataTestId?: string;
  handleBack?: () => void;
  children: React.ReactNode;
};

const HeaderStyle = (title: string, description: string) => {
  return (
    <>
      <HeaderBox>
        <Typography
          variant="subtitle1"
          color={colors.fonts[20]}
          fontWeight={fontWeight.Weight[6]}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          color={colors.fonts[5]}
          fontWeight={fontWeight.Weight[3]}
          pt={"0.5rem"}
        >
          {description}
        </Typography>
      </HeaderBox>
    </>
  );
};

const Head = ({
  title,
  description,
  back,
  children,
  dataTestId,
  handleBack,
}: HeadProps) => {
  if (back) {
    return (
      <>
        <Grid item xs={12}>
          <Grid container sx={styles.head}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box sx={styles.flex}>
                <Box
                  onClick={handleBack}
                  data-testid={dataTestId}
                  sx={styles.h_backbutton}
                  component={"button"}
                >
                   <Typography
                  variant="subtitle1"
                  color={colors.primary.main}
                  fontWeight={fontWeight.Weight[5]}
                  p={"0.1rem 0 0 0rem"}
                >Back</Typography>
                  
                </Box>

                <Typography
                  variant="subtitle1"
                  color={colors.fonts[20]}
                  fontWeight={fontWeight.Weight[6]}
                  p={"0.1rem 0 0 1rem"}
                >
                  {title}
                </Typography>
              </Box>
              <Box sx={styles.flex}>
                <Typography
                  variant="body1"
                  color={colors.fonts[5]}
                  fontWeight={fontWeight.Weight[3]}
                  pt={"0.5rem"}
                  pl={"1rem"}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} sx={styles.h_buttons}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container sx={styles.head}>
          <Grid item xs={12} sm={12} md={7} lg={7} sx={styles.flex}>
            {HeaderStyle(title, description)}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} sx={styles.h_buttons}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Head;

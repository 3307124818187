import { Reducer } from "redux";

import { StaffingStatusMasterResponse } from "../models/Api/Master";
import { StaffingStatusDispatchTypes } from "../models/DispatchTypes/Master";
import { StaffingStatusActionDispatchTypes } from "../constants/Master";

export const initialState: StaffingStatusMasterResponse = {
  loading: false,
  response: [
    {
      staffingStatusId: 0,
      staffingStatusName: "",
    },
  ],
};

const StaffingStatusReducer: Reducer<
  StaffingStatusMasterResponse,
  StaffingStatusDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case StaffingStatusActionDispatchTypes.STAFFING_STATUS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case StaffingStatusActionDispatchTypes.STAFFING_STATUS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default StaffingStatusReducer;

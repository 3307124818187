import { Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import isBewteen from "dayjs/plugin/isBetween";

import NotesPreview from "../formComponents/NotesPreview";
import { formatDate } from "../../utils/DateTime";
import { DisciplineLevelNote, ReferralLevelNote } from "../../models/Notes";
import { DateFormats } from "../../constants/Date";
import { UserDetailsPayload } from "../../models/User";
export interface AssessmentViewer {
  isSubmit: boolean;
  assessmentViewData?: any;
  editFlag?: boolean;
  list?: any;
  setEditNotesPreview?: Dispatch<SetStateAction<boolean>>;
  endpointcallFlag?: string;
  saveDisciplineLevelNotesData?: (payload: DisciplineLevelNote) => void;
  saveReferralLevelNoteData?: (payload: ReferralLevelNote) => void;
  GetDisciplineLevelNotesData?: DisciplineLevelNote;
  getReferrallevelNotesData?: ReferralLevelNote;
  updateDisciplineLevelNoteData?: (
    disciplineNoteId: number,
    payload: DisciplineLevelNote
  ) => void;
  updateReferralLevelNotesData?: (
    disciplineNoteId: number,
    payload: ReferralLevelNote
  ) => void;
  serviceRequestDisciplineId?: number;
  notesTemplateName?: string;
  notesTemplateId?: number;
  user?: UserDetailsPayload;
  authNo?: any;
  tempDisciplineNoteId?: number;
  tempReferralNoteId?: number;
  tempNoteEntryId?: number;
  title?: string;
  tempCreatedDate?: string;
  setShowError?: Dispatch<SetStateAction<boolean>>;
  setNotesData?: Dispatch<SetStateAction<any>>;
}
const AssessmentViewer: React.FC<AssessmentViewer> = ({
  isSubmit,
  assessmentViewData,
  editFlag,
  endpointcallFlag,
  saveDisciplineLevelNotesData,
  saveReferralLevelNoteData,
  notesTemplateId,
  notesTemplateName,
  user,
  authNo,
  updateDisciplineLevelNoteData,
  updateReferralLevelNotesData,
  tempDisciplineNoteId,
  tempReferralNoteId,
  GetDisciplineLevelNotesData,
  getReferrallevelNotesData,
  serviceRequestDisciplineId,
  setEditNotesPreview,
  tempCreatedDate,
  setShowError,
  setNotesData,
}) => {
  dayjs.extend(isBewteen);
  const onCompleteSurvey = (survey: any) => {
    const checkDateRange = dayjs().isBetween(
      formatDate(
        dayjs(tempCreatedDate).toString(),
        DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
      ),
      formatDate(
        dayjs(tempCreatedDate).add(1, "day").toString(),
        DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
      )
    );
    if (
      endpointcallFlag === "addDiscipline" ||
      (endpointcallFlag === "editDiscipline" && checkDateRange === true)
    ) {
      setShowError && setShowError(false);
      const saveObj = {
        disciplineNoteId:
          endpointcallFlag === "addDiscipline"
            ? 0
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.disciplineNoteId
            : 0,
        serviceRequestDisciplineId:
          endpointcallFlag === "addDiscipline"
            ? serviceRequestDisciplineId
              ? serviceRequestDisciplineId
              : 0
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.serviceRequestDisciplineId
            : 0,
        noteEntryId:
          endpointcallFlag === "addDiscipline"
            ? 0
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.noteEntryId
            : 0,
        dateCreated:
          endpointcallFlag === "addDiscipline"
            ? formatDate(new Date().toString(), DateFormats.YYYY_MM_DD)
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.dateCreated
            : "",
        dateUpdated:
          endpointcallFlag === "addDiscipline"
            ? formatDate(new Date().toString(), DateFormats.YYYY_MM_DD)
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.dateUpdated
            : "",
        userCreated: user && user.email ? user.email : "",
        fullNameCreated:
          endpointcallFlag === "addDiscipline"
            ? ""
            : GetDisciplineLevelNotesData
            ? GetDisciplineLevelNotesData.fullNameCreated
            : "",
        noteTemplateId: notesTemplateId ? notesTemplateId : 0,
        templateName:
          endpointcallFlag === "addDiscipline"
            ? notesTemplateName
              ? notesTemplateName
              : ""
            : notesTemplateName
            ? notesTemplateName
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.templateName
            : "",
        noteEntryJson: JSON.stringify(survey.data),
        comment:
          Object.prototype.hasOwnProperty.call(survey.data, "comment") === true
            ? survey.data.comment
            : null,
      };

      if (endpointcallFlag === "addDiscipline") {
        saveDisciplineLevelNotesData && saveDisciplineLevelNotesData(saveObj);
      } else {
        updateDisciplineLevelNoteData &&
          updateDisciplineLevelNoteData(
            tempDisciplineNoteId ? tempDisciplineNoteId : 0,
            saveObj
          );
      }
    }

    if (
      endpointcallFlag === "addReferral" ||
      (endpointcallFlag === "editReferral" && checkDateRange === true)
    ) {
      setShowError && setShowError(false);
      const saveObj = {
        referralNoteId:
          endpointcallFlag === "addReferral"
            ? 0
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.referralNoteId
            : 0,
        referralId:
          endpointcallFlag === "addReferral"
            ? Number(authNo)
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.referralId
            : 0,
        noteEntryId:
          endpointcallFlag === "addReferral"
            ? 0
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.noteEntryId
            : 0,
        dateCreated:
          endpointcallFlag === "addReferral"
            ? formatDate(new Date().toString(), DateFormats.YYYY_MM_DD)
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.dateCreated
            : "",
        dateUpdated:
          endpointcallFlag === "addReferral"
            ? formatDate(new Date().toString(), DateFormats.YYYY_MM_DD)
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.dateUpdated
            : "",
        userCreated: user && user.email ? user.email : "",
        noteTemplateId: notesTemplateId ? notesTemplateId : 0,
        templateName:
          endpointcallFlag === "addReferral"
            ? notesTemplateName
              ? notesTemplateName
              : ""
            : notesTemplateName
            ? notesTemplateName
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.templateName
            : "",
        noteEntryJson: JSON.stringify(survey.data),
        comment:
          Object.prototype.hasOwnProperty.call(survey.data, "comment") === true
            ? survey.data.comment
            : null,
        fullNameCreated:
          endpointcallFlag === "addReferral"
            ? ""
            : getReferrallevelNotesData
            ? getReferrallevelNotesData.fullNameCreated
            : "",
      };
      if (endpointcallFlag === "addReferral") {
        saveReferralLevelNoteData && saveReferralLevelNoteData(saveObj);
      } else {
        updateReferralLevelNotesData &&
          updateReferralLevelNotesData(Number(tempReferralNoteId), saveObj);
      }
    }
    if (
      (endpointcallFlag === "editReferral" ||
        endpointcallFlag === "editDiscipline") &&
      checkDateRange === false
    ) {
      setShowError && setShowError(true);
    }
  };
  return (
    <Grid container>
      <NotesPreview
        model={assessmentViewData.length !== 0 ? assessmentViewData : ""}
        data={
          assessmentViewData.length !== 0 ? JSON.parse(assessmentViewData) : ""
        }
        onComplete={onCompleteSurvey}
        isSubmit={isSubmit}
        editFlag={editFlag}
        setEditNotesPreview={setEditNotesPreview}
        setNotesData={setNotesData}
      />
    </Grid>
  );
};

export default AssessmentViewer;

const accentBlue = {
  100: "#1D4289",
  75: "#5671A7",
  50: "#8EA1C4",
  30: "#BCC7DC",
  10: "#E9ECF3",
};

const accentSKy = {
  100: "#E2F4FF",
  75: "#E9F7FF",
  50: "#F1FAFF",
  30: "#F6FCFF",
  10: "#FCFEFF",
};

const accentOcean = {
  100: "#A8DEFF",
  75: "#BEE6FF",
  50: "#D4EFFF",
  30: "#E5F5FF",
  10: "#F6FCFF",
};

const accentOrange = {
  100: "#F8A268",
  75: "#FAB98E",
  50: "#FCD1B4",
  30: "#FDE3D2",
  10: "#FEF6F0",
};

const accentPink = {
  100: "#FF8290",
  75: "#FFA1AC",
  50: "#FFC1C8",
  30: "#FFDADE",
  10: "#FFF3F4",
};

const accentGreen = {
  100: "#5C9818",
  75: "#85B252",
  50: "#AECC8C",
  30: "#CEE0BA",
  10: "#EFF5E8",
};

export default {
  accentBlue,
  accentSKy,
  accentOcean,
  accentOrange,
  accentPink,
  accentGreen,
};

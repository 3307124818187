import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { authorizationHistoryReset } from "../../../../../actions/AuthorizationHistory";
import { checkEligibility } from "../../../../../actions/AllPatientRecord";
import {
  getEligibilityRecord,
} from "../../../../../actions/Master";
import {
  patientInformationReset,
} from "../../../../../actions/PatientInformation";
import {
  PatientInformation,
  PatientInfoResponse,
} from "../../../../../models/Patient";
import { SavepatientInformationCall } from "../../../../../actions/Patient";
import {
  PatientInformationResponse,
  SavePatientInformationResponse,
} from "../../../../../models/Api/Patient";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { UserDetailsPayload } from "../../../../../models/User";
import PatientInfo from "../components";
import { resetStates } from "../../../../../actions/Common";

const getPatientInformation = (
  patientInformationState: PatientInformationResponse
) => {
  const result = patientInformationState.response;
  return result;
};

const savePatientInformation = (
  SavePatientInformationState: SavePatientInformationResponse
) => {
  const result = SavePatientInformationState.response;
  return result;
};

const mapStateToProps = ({
  checkEligibilityState,
  loggedInUserState,
  patientInformationState,
  patientInformationViewState,
  SavePatientInformationState,
  eligibilityData
}: ApplicationState) => ({
  patientInformationResponse: getPatientInformation(
    patientInformationState
  ) as PatientInformation,
  savePatientInformationResponse: savePatientInformation(
    SavePatientInformationState
  ) as PatientInfoResponse,
  savePatientInformationLoading: SavePatientInformationState.loading,
  data: patientInformationViewState as PatientInformationViewResponse,
  checkEligibilityData: checkEligibilityState,
  user: loggedInUserState.response as UserDetailsPayload,
  eligibilityRecord: eligibilityData.response
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    savePatientInformationDetails: (data: FormData) => {
      dispatch(SavepatientInformationCall(data));
    },
    checkEligibility: (payload: any) => {
      dispatch(checkEligibility(payload));
    },
    patientInformationReset: () => {
      dispatch(patientInformationReset());
    },
    authorizationHistoryReset: () => {
      dispatch(authorizationHistoryReset());
    },
    getEligibilityData: (benefitPlanUid: string) => {
      dispatch(getEligibilityRecord(benefitPlanUid));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);

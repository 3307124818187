import { Reducer } from "redux";

import { ClinicalGroupActionDispatchTypes } from "../constants/Master";
import { ClinicalGroupResponse } from "../models/Api/Master";
import { ClinicalGroupDispatchTypes } from "../models/DispatchTypes/Master";

export const initialPostState: ClinicalGroupResponse = {
  loading: false,
  response: [],
};

const ClinicalGroupingReducer: Reducer<
  ClinicalGroupResponse,
  ClinicalGroupDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case ClinicalGroupActionDispatchTypes.CLINICAL_GROUP_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ClinicalGroupActionDispatchTypes.CLINICAL_GROUP_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ClinicalGroupingReducer;

import { PatientDetailsResponse } from "../models/Api/Patient";
import { Reducer } from "redux";

import { PatientDetailsDispatchTypes } from "../models/DispatchTypes/Patient";
import { PatientDetailsActionDispatchTypes } from "../constants/Patient";

const initialState: PatientDetailsResponse = {
  loading: false,
  response: {
    patientEntity: {
      patientId: 0,
      referralId: 0,
      pictureBlobId: null,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dateOfBirth: null,
      dateCreated: "",
      userCreated: null,
    },
    addressEntity: {
      addressId: 0,
      streetName: "",
      city: "",
      patientState: "",
      zipCode: "",
      phoneNo: "",
    },
    referralEntity: {
      receivedDateTime: null,
      receivedMethod: 0,
      receivedReferralSystem: null,
      isNavihealth: false,
    },
  },
};

const PatientDetailsReducer: Reducer<
  PatientDetailsResponse,
  PatientDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientDetailsActionDispatchTypes.PATIENT_DETAILS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientDetailsActionDispatchTypes.PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientDetailsReducer;

import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../../store/ApplicationState";
import {
  getPhysicianRecords,
  resetPhysicianRecords,
} from "../../../../../../actions/AllPatientRecord";
import { AllPatientTabDispatchTypes } from "../../../../../../models/DispatchTypes/AllPatientRecord";
import PhysicianInfo from "../components/PhysicianInfo";

const mapStateToProps = ({
  physicianRecordsState,
}: ApplicationState) => ({
  physicianRecords: physicianRecordsState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    getPhysicianRecords: (value: string) => {
      dispatch(getPhysicianRecords(value));
    },
    resetPhysicianRecords: () => {
      dispatch(resetPhysicianRecords());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianInfo);

import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import axios from "axios";

import { loginScope, msalConfig } from "./utils/authentication/authConfig";
import configureAppStore from "./store/ConfigureStore";
import { App } from "./App";
import "./index.css";
import theme from "./styles/mui/theme";
import { updateConfigs } from "./constants/Config";
import { GetConfigFileName } from "./utils";
import Logger from "./utils/logHandlers/Logger";

export let msalInstance: PublicClientApplication;
export let loginRequest: any;

const store = configureAppStore();
const container = document.getElementById("root")!;
const root = createRoot(container);

const AppWithAuthentication = () => {
  const [config, setConfig] = useState<any>();
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!config) {
        const configFileName: string = GetConfigFileName();
        await axios
          .get(configFileName)
          .then(function (response) {
            setConfig(response.data);
            updateConfigs(response.data);
            msalInstance = new PublicClientApplication(
              msalConfig(response.data)
            );
            msalInstance.initialize();
            msalInstance.enableAccountStorageEvents();
            msalInstance.addEventCallback((event) => {
              if (
                event.eventType === EventType.LOGIN_SUCCESS &&
                event.payload
              ) {
                const account: any = event.payload;
                msalInstance.setActiveAccount(account);
              }
            });
            loginRequest = loginScope(response.data.REACT_APP_CLIENT_ID);
            setIsConfigLoaded(true);
          })
          .catch(function (error) {
            Logger.error("Could not load application configuration.");
            Logger.error(error);
          });
      }
    })();
  }, []);

  return (
    <div>
      {isConfigLoaded ? (
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </Provider>
          </MsalProvider>
        </React.StrictMode>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

root.render(<AppWithAuthentication />);

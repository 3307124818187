import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import { IoLocationSharp } from "react-icons/io5";
import { RiDeleteBinLine, RiFileTextLine } from "react-icons/ri";

import { colors, others, accent } from "../../../../../styles/colors";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? colors.black[12] : colors.white[1],
  padding: theme.spacing(1),
  textAlign: "center",
}));

export const IoIosPinIcon = styled(IoLocationSharp)((props) => ({
  marginRight: "0.7rem",
  color: props.theme.palette.primary.main,
  fontSize: "1.5rem",
}));

export const horizontalDivider = () => ({
  width: "100%",
  maxWidth: "100rem",
  paddingLeft: "20rem",
});

export const borderRediusStyle = {
  borderRadius: "20rem",
};

export const labelStyle = {
  pt: 2,
};

export const searchBoxPadding = {
  pt: 1,
};

export const dividerStyle = {
  pt: 3,
};

export const dividerStyleforfile = {
  pt: 1,
};

export const verticalDeviderforTime = {
  height: "60rem",
};

export const veticalStyle = {
  marginTop: "10rem",
};
export const dividerRightWidth = {
  borderRightWidth: 2,
};

export const autoCompleteWidth = {
  width: 350,
};

export const tableStyle = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};

export const genderStyle = {
  m: 1,
  minWidth: 120,
};

export const addressStyle = {
  width: 350,
};

export const addresSdetailsStyle = {
  m: 1,
  minWidth: 150,
};

export const gridPadding = {
  p: 1,
};

export const dropFileGridStyle = {
  paddingLeft: 22,
  pt: 2,
};

export const searchDivStyle = {
  paddingRight: 16,
};

export const receivedTodayStyle = {
  textAlign: "right",
};

export const tableWidthStyle = {
  minWidth: 180,
};

export const tableContainerWidth = {
  minWidth: 150,
};

export const radioButtonStyle = {
  width: 110,
};

export const languagePreferenceStyle = {
  paddingLeft: 1,
};

export const savePatientBtn = {
  borderRadius: "25rem",
  margin: "10rem",
  color: colors.white[1],
  backgroundColor: colors.primary.main,
  width: "6.25rem",
};

export const radioBtnBackground = {
  backgroundColor: colors.black[13],
  borderRadius: "20rem",
  marginTop: 2,
};

export const LabelFont = {
  color: colors.black[13],
  fontSize: "0.7rem",
};

export const inlineStyle = {
  color: colors.black[13],
  fontSize: "0.7rem",
  opacity: 0.7,
};
export const errorMsg = {
  color: colors.red[100],
  fontSize: "0.7rem",
  opacity: 0.7,
};
export const boxScroller = {
  height: 60,
  width: 350,
  overflow: "hidden",
  overflowY: "scroll",
  marginTop: "80rem",
};
export const boxWithoutScroller = {
  height: 60,
  width: 350,
  marginTop: "80rem",
};
export const iconStyle = {
  paddingRight: "10rem",
  marginRight: "8rem",
  paddingLeft: "6rem",
};
export const buttonStyle = {
  width: "212rem",
  height: "40rem",
  backgroundColor: `${colors.black[7]} 0% 0% no-repeat padding-box`,
  borderRadius: "1.875rem",
};
export const smalltext = {
  color: colors.fonts[10],
  width: "19rem",
  height: "19rem",
  marginLeft: "70rem",
};
export const radioLabelFont = {
  fontSize: "0.8rem",
};

export const riIconStyle = {
  marginLeft: "30rem",
  color: colors.riIcon,
};

export const SummaryItemContainer = () => ({
  padding: "1rem",
  backgroundColor: colors.backgroundColor[2],
  borderRadius: "0.3rem",
  marginBottom: "1.8rem",
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
});

export const SummaryContainerHead = () => ({
  padding: ".6rem 0 1.1rem 0",
  borderBottom: `0.0625rem solid ${colors.borders[4]}`,
});

export const pateintContainerHead = () => ({
  padding: "0rem 0 1.1rem 0",
  borderTop: `0.0625rem solid ${colors.borders[4]}`,
});

export const ParentGridBody = () => ({
  padding: "0.1rem",
});

export const urgencyStatus = () => ({
  width: "100%",
  maxWidth: "12rem",
});

export const AdditionalDetialsContainerHead = () => ({
  padding: ".6rem 0 .75rem 0",
  borderBottom: `0.0625rem solid ${colors.borders[4]}`,
});

export const SummaryHeadTexts = () => ({
  padding: "0 3rem 0 1rem",
});

export const SummaryHeadPatientName = () => ({
  marginBottom: ".45rem",
});

export const MandatoryHeadStar = () => ({
  marginTop: "-0.5rem",
  marginRight: "0.5rem",
});

export const SummaryContainerBody = () => ({
  padding: "1.3rem 1rem 1rem 3rem",
});

export const PatientSearchLabel = () => ({
  padding: "0 0 .75rem 0",
});

export const AutocompleteUI = () => ({
  width: "19rem",
  position: "relative",
});

export const AutocompleteHolder = () => ({
  display: "flex",
  padding: "0 0 .7rem 0",
});

export const ORConatiner = () => ({
  padding: "0 2rem 0 10rem",
  alignItems: "center",
  display: "flex",
});

export const AutoCompleteNote = () => ({
  padding: "0 .25rem 0 .25rem",
});

export const AutocompleteTableHolder = () => ({
  width: "50rem",
  left: "-2rem",
  top: "1rem",
  backgroundColor: `${colors.white[1]} !important`, //TBD
  borderRadius: "0.3rem",
});

export const TableHeadMargin = (isHeader: boolean) => ({
  marginTop: isHeader ? "1rem" : "auto",
});

export const SearchedResultTableHead = () => ({
  padding: ".75rem 0 .75rem 1.5rem",
  backgroundColor: others.MainBackground[1],
  borderBottom: 0,
  "&.MuiTableCell-root:first-of-type": {
    borderTopLeftRadius: "0.3rem",
    borderBottomLeftRadius: "0.3rem",
    paddingLeft: "1.5rem",
  },
  "&.MuiTableCell-root:last-child": {
    borderTopRightRadius: "0.3rem",
    borderBottomRightRadius: "0.3rem",
  },
});

export const SearchedResultTableBody = () => ({
  padding: ".5rem 0 .4rem 1.5rem",
  textTransform: "capitalize",
  border: 0,
  borderBottom: `0.0625rem solid ${colors.borders[1]}`,
  "&.MuiTableCell-root:first-of-type": {
    paddingLeft: "1.5rem",
  },
});

export const PatientDetailsContainerBody = () => ({
  padding: "1rem 1rem 0 1rem",
});

export const AddressDetailsContainerBody = () => ({
  padding: "1.1rem 1rem 1rem 0",
});

export const PatientInputContainer = () => ({
  padding: "1rem .5rem 1rem 2rem",
});

export const PatientInputHolder = (maxWidth: string) => ({
  padding: "2rem 1rem 3rem 0rem",
  "&.MuiGrid-root": {
    maxWidth: maxWidth,
  },
});

export const AddressHeader = () => ({
  padding: ".5rem 0 1rem 0.4rem",
});

export const AdditionalDetailsBody = () => ({
  padding: "1rem 1rem 1rem 2rem",
});

export const AdditionalDetailsDevider = () => ({
  height: "3rem",
  width: "0.0625rem",
});

export const ORTextHolder = () => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
});

export const RefFileTitle = () => ({
  paddingRight: "1rem",
});

export const RefFileTitleHead = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  paddingLeft: "2.75rem",
});

export const SwitchHolder = () => ({
  paddingTop: "2.5rem",
});

export const RefFileContainer = () => ({
  borderLeft: `0.0625rem solid ${colors.borders[1]}`,
});

export const DocumentUI = () => ({
  paddingTop: ".75rem",
});

export const RootUploadedDoc = () => ({
  padding: ".25rem 1rem 0 2rem",
});

export const RiDeleteIcon = styled(RiDeleteBinLine)(() => ({
  fontSize: "1.15rem",
  color: accent.accentOrange[100],
  cursor: "pointer",
}));

export const RiFileTextLineIcon = styled(RiFileTextLine)(() => ({
  fontSize: "1.5rem",
  color: colors.black[2],
  cursor: "pointer",
}));

export const AutoCompletePaper = () => ({
  "&.MuiAutocomplete-paper": {
    width: "50rem",
  },
});

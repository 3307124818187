export enum ReviewActionDispatchTypes {
  REVIEW_ERROR = "REVIEW_ERROR",
  REVIEW_INVOKE = "REVIEW_INVOKE",
  REVIEW_SUCCESS = "REVIEW_SUCCESS",
}

export enum ReviewRequestDetailsQuestions {
  CUSTODIAL_CARE = `- Does the order call for custodial care (ADLs, Cooking and Cleaning, etc.) ?`,
  HOME_HEALTH_ORDER = "- Does the referral contain a valid home health order ?",
  NON_COVERED_SERVICES = "- Does the order include any non covered services ?",
}

export enum ReviewDocumentationDetailQuestions {
  SUPPORTING_DOCUMENTS = "- Does the referral contain supporting documentation ? ",
}

export enum ReAuthReviewActionDispatchType {
  RE_AUTH_REVIEW_ERROR = "RE_AUTH_REVIEW_ERROR",
  RE_AUTH_REVIEW_INVOKE = "RE_AUTH_REVIEW_INVOKE",
  RE_AUTH_REVIEW_SUCCESS = "RE_AUTH_REVIEW_SUCCESS",
}

export enum ReviewDisciplineTableHeader {
  CURRENT_AUTH_PERIOD = "Current Auth. Period",
  DISCIPLINES = "Disciplines",
  NEXT_AUTH_PERIOD = "Next Auth. Period",
  PREVIOUSLY_REQUESTED = "Previously Requested",
  REQUESTED_NUMBER_OF_VISITS = "Requested No. Of Visits",
}

export enum ReviewRequestLabel {
  DATE_REQUEST_RECEIVED = "Date Request Received:",
  RECEIVED_METHOD = "Received Methods",
  TIME_REQUEST_RECEIVED = "Time Request Received:",
  URGENCY_STATUS = "Urgency Status:",
}

export enum ReviewRequestorLabel {
  FAX_NO = "Fax No",
  NAME = "Name",
  PHONE = "Phone",
  TYPE = "Type",
  PHYSICIAN_NAME = "Physician Name",
  NPI = "NPI",
}

export enum SupportingDocumentsLabel {
  CREATED_BY = "Created By",
  DOC_TYPE = "Doc Type",
  RECEIVED_ON = "Date / Time",
  SOURCE = "Source",
}

export enum ReceivedMethods {
  FAX = "Fax",
  PHONE = "Phone",
  EMAIL = "Email",
  CLAIM = "Claim",
  OTHERS = "Others",
}

export enum ReviewDetails {
  NAME = "Name:",
  PHYSICIAN_NAME = "Physician Name:",
  NPI = "NPI:",
  TAX_ID = "Tax ID:",
  ADDRESS = "Address:",
}

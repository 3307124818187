import { Controller } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { displayFlex, flexAllCentre } from "../../styles/mui/styles/display";
import { FormInputProps } from "./FormInputProps";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";
import FormInputIcons from "./FormInputIcons";
import { checkValidationMsgSymbol, handleInputEvent } from "../../utils";
import { getValue } from "../../utils";
import { InputTextLabel } from "../styles/styles";

export const FormInputText = ({
  control,
  dataTestId,
  disabled,
  handleChange,
  helper,
  iconName,
  isOnlyIcon,
  label,
  maxRow,
  multiline,
  name,
  onKeyPress,
  placeholder,
  searchInputRef,
  textLength,
  inputType,
  mandatorySymbol,
  InputStyle,
  inputVariant,
  inputRows,
  readOnly,
}: FormInputProps) => {
  let props = {};
  if (inputRows) {
    props = {
      rows: inputRows,
    };
  } else {
    props = {
      maxRows: maxRow || 2,
    };
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={helper}
      render={({
        field: { onChange, value, ref, onBlur },
        fieldState: { error },
      }) => (
        <TextField
          required={
            checkValidationMsgSymbol(
              getValue(helper, "required.message", " ")
            ) &&
            helper &&
            helper.required &&
            helper.required.value
          }
          error={error !== undefined}
          helperText={error ? error.message : ""}
          {...props}
          onChange={(e) => {
            onChange(e);
            handleChange && handleChange(e);
          }}
          onBlur={onBlur}
          value={value || ""} // handle null/undefined
          onKeyUp={(e) => {
            onKeyPress && onKeyPress(e);
          }}
          onInput={(e) => {
            handleInputEvent(e, inputType);
          }}
          fullWidth
          sx={InputStyle ? InputStyle : InputTextLabel}
          label={
            label && (
              <Box sx={displayFlex}>
                <Typography
                  variant="h5"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[2]}
                >
                  {label}
                </Typography>
                {!getValue(helper, "required.value", "") && mandatorySymbol && (
                  <Typography
                    variant="h3"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.red[100]}
                    pl={"0.4rem"}
                  >
                    *
                  </Typography>
                )}
              </Box>
            )
          }
          placeholder={
            name.includes("streetName1") || name.includes("streetName2")
              ? "Add here..."
              : placeholder
          }
          disabled={disabled}
          multiline={multiline || false}
          inputProps={{
            maxLength: textLength ? textLength : 50,
            ref: searchInputRef ? searchInputRef : null,
            autoComplete: "none",
          }} // text
          InputProps={{
            readOnly: readOnly,
            startAdornment: iconName && (
              <Stack direction="row" sx={flexAllCentre}>
                {FormInputIcons(iconName, isOnlyIcon)}
              </Stack>
            ),
          }}
          variant={inputVariant ? "outlined" : "standard"}
          inputRef={ref}
          InputLabelProps={{ shrink: true }}
          data-testid={dataTestId}
        />
      )}
    />
  );
};

import colors from "../../../../../styles/colors/colors";

export const PMHcontainer = () => ({
  padding: "3rem 0rem 1rem 1.8rem",
});

export const BorderBottom = () => ({
  borderBottom: `1px solid ${colors.borders[1]}`,
});

export const Heading = () => ({
  padding: "1rem",
});

export const Description = () => ({
  padding: "0.5rem",
});

export const CheckBoxGrid = () => ({
  padding: "1rem",
});
export const CheckBoxAlingment = () => ({
  display: "flex",
  flexDirection: "row",
});

export const PHcontainer = () => ({
  padding: "2rem 0rem 1rem 1.8rem",
});

export const InputFeild = () => ({
  paddingLeft: "2rem ",
});

export const PastMedicalHistoryTitle = () => ({
  paddingBottom: "0.8rem",
});

export const DiseaseName = () => ({
  marginRight: "3rem",
});

import { Reducer } from "redux";

import { UpdateReferralLevelNote } from "../models/Api/Notes";
import { UpdateReferralLevelNotesDispatchTypes } from "../models/DispatchTypes/Notes";
import { UpdateReferralLevelNotesActionDispatchTypes } from "../constants/Notes";

export const initialState: UpdateReferralLevelNote = {
  loading: false,
  response: {
    referralNoteId: -1,
    referralId: -1,
    noteEntryId: -1,
    dateCreated: "",
    dateUpdated: "",
    userCreated: "",
    noteTemplateId: -1,
    templateName: "",
    noteEntryJson: "",
    comment: "",
    fullNameCreated: "",
  },
};

const UpdateReferralLevelNotesReducer: Reducer<
  UpdateReferralLevelNote,
  UpdateReferralLevelNotesDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case UpdateReferralLevelNotesActionDispatchTypes.UPDATE_REFERRAL_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default UpdateReferralLevelNotesReducer;

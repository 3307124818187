import { Reducer } from "redux";

import { PermissionActionDispatchTypes } from "../constants/User";
import { PermissionResponse } from "../models/Api/User";
import { PermissionDispatchTypes } from "../models/DispatchTypes/User";

const initialState: PermissionResponse = {
  loading: true,
  response: [],
};

const PermissionReducer: Reducer<
  PermissionResponse,
  PermissionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PermissionActionDispatchTypes.PERMISSION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PermissionActionDispatchTypes.PERMISSION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PermissionReducer;

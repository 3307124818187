import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  FaFax,
  FaPhone,
  FaRegCalendarCheck,
  FaRegEnvelope,
  FaRegEdit,
  FaRegFile,
  FaRegClock,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { colors } from "../../../../../../../styles/colors";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  FieldLabel,
  RequestDetailsFieldDescription,
  RequestDetailsFieldDescriptionIcon,
  RequestDetailsOuterGrid,
  RequestDetailsRowGrid,
  VerticalDivider,
} from "../styles/ReAuthReviewRequest";
import { DateFormats } from "../../../../../../../constants/Date";
import { formatDate } from "../../../../../../../utils/DateTime";
import { ReAuthReview } from "../../../../../../../models/Patient";
import { ReAuthReviewResponse } from "../../../../../../../models/Api/Review";
import {
  ReceivedMethods,
  ReviewRequestLabel,
} from "../../../../../../../constants/Review";

export interface PropsFromState {
  reAuthRequestDetails: ReAuthReviewResponse;
}

export interface PropsFromDispatch {
  getReAuthReviewDetails: (reAuthId: number) => void;
}

type ReAuthReviewRequestProps = PropsFromState & PropsFromDispatch;

const ReAuthReviewRequest: React.FC<ReAuthReviewRequestProps> = ({
  reAuthRequestDetails,
  getReAuthReviewDetails,
}: ReAuthReviewRequestProps) => {
  const [requestDetails, setRequestDetails] = useState<ReAuthReview>();
  const { loading, response } = reAuthRequestDetails;

  const { reAuthId } = useParams();

  const reauthReferralRequest =
    requestDetails && requestDetails.reAuthReferralDetail;

  useEffect(() => {
    if (!loading && reAuthId) {
      getReAuthReviewDetails(parseInt(reAuthId));
    }
  }, [reAuthId]);

  useEffect(() => {
    if (response) {
      setRequestDetails(response);
    }
  }, [response]);

  const getReceivedMethod = (value: string | undefined) => {
    let method;
    if (value) {
      switch (value) {
        case ReceivedMethods.FAX:
          method = { value: ReceivedMethods.FAX, icon: FaFax };
          return method;
        case ReceivedMethods.PHONE:
          method = { value: ReceivedMethods.PHONE, icon: FaPhone };
          return method;
        case ReceivedMethods.CLAIM:
          method = { value: ReceivedMethods.CLAIM, icon: FaRegEdit };
          return method;
        case ReceivedMethods.EMAIL:
          method = { value: ReceivedMethods.EMAIL, icon: FaRegEnvelope };
          return method;
        default:
          method = { value: value, icon: FaRegFile };
          return method;
      }
    }
    return {};
  };

  return (
    <Grid container sx={RequestDetailsOuterGrid}>
      <Grid item xs={6} sx={RequestDetailsRowGrid}>
        <ReviewField
          label={ReviewRequestLabel.DATE_REQUEST_RECEIVED}
          value={
            reauthReferralRequest && reauthReferralRequest.receivedDateTime
              ? formatDate(
                  reauthReferralRequest.receivedDateTime,
                  DateFormats.MM_DD_YYYY
                )
              : ""
          }
          Icon={FaRegCalendarCheck}
          isIcon={true}
          color={colors.fonts[25]}
        />
        <ReviewField
          label={ReviewRequestLabel.TIME_REQUEST_RECEIVED}
          value={
            reauthReferralRequest && reauthReferralRequest.receivedDateTime
              ? formatDate(
                  reauthReferralRequest.receivedDateTime,
                  DateFormats.TIME_AM_PM
                )
              : ""
          }
          Icon={FaRegClock}
          color={colors.fonts[25]}
          isIcon={true}
        />
        <ReviewField
          label={ReviewRequestLabel.URGENCY_STATUS}
          value={getValue(
            requestDetails,
            reauthReferralRequest && reauthReferralRequest.urgencyStatus
          )}
          color={
            reauthReferralRequest &&
            reauthReferralRequest.urgencyStatus === "Standard"
              ? colors.fonts[25]
              : colors.red[102]
          }
        />
        {<Divider flexItem orientation="vertical" sx={VerticalDivider} />}
      </Grid>
      {
        <Grid item xs={3.5} sx={RequestDetailsRowGrid}>
          <ReviewField
            label={ReviewRequestLabel.RECEIVED_METHOD}
            value={
              (reauthReferralRequest &&
                reauthReferralRequest.receivedMethodName &&
                getReceivedMethod(
                  reauthReferralRequest &&
                    reauthReferralRequest.receivedMethodName
                ).value) ||
              ""
            }
            Icon={
              getReceivedMethod(
                (reauthReferralRequest &&
                  reauthReferralRequest.receivedMethodName) ||
                  ""
              ).icon
            }
            color={colors.fonts[25]}
            isIcon={true}
          />
        </Grid>
      }
    </Grid>
  );
};

const getValue = (object: ReAuthReview | undefined, entity: any) => {
  return object && entity ? entity : "";
};

interface Props {
  Icon?: React.ElementType;
  value: string | number | boolean;
  label: string;
  isIcon?: any;
  color: string;
}

const ReviewField: React.FC<Props> = ({
  Icon,
  value,
  label,
  isIcon,
  color,
}: Props) => {
  return (
    <Box>
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
          sx={FieldLabel}
        >
          {label}
        </Typography>
      </Box>
      <Box>
        {label == ReviewRequestLabel.RECEIVED_METHOD ? (
          <Box sx={RequestDetailsFieldDescription}>
            <Box sx={RequestDetailsFieldDescriptionIcon(isIcon)}>
              {value && Icon && <Icon size={17} color={colors.primary.main} />}
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[6]}
              color={color}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <Box sx={RequestDetailsFieldDescription}>
            <Box sx={RequestDetailsFieldDescriptionIcon(isIcon)}>
              {value && Icon && <Icon size={18} color={colors.primary.main} />}
            </Box>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={color}
            >
              {value}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReAuthReviewRequest;

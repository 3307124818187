import { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import { colors, others as otherColor } from "../../../../../styles/colors";
import { PsychologicalHistory } from "../../../../../models/Service";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  PastMedicalHistoryResponse,
  PsychologicalHistoryResponse,
} from "../../../../../models/Api/Master";
import { getValue, length } from "../../../../../utils";
import {
  BorderBottom,
  CheckBoxAlingment,
  CheckBoxGrid,
  DiseaseName,
  Heading,
  PHcontainer,
} from "../styles/PastMedicalHistory";
import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import { OuterBodyGrid, TopGrid } from "../styles/GeneralInfo";
import LevelOfFunction from "../containers/LevelOfFunction";
export interface PropsState {
  control: any;
  pastMedicalHistoryData: PastMedicalHistoryResponse;
  psychologicalHistoryData: PsychologicalHistoryResponse;
  setValue: (id: any, value: any, boolean: any) => void;
  values: AuthorizationDetailTabs;
}
type AllProps = PropsState;

const AuthPastMedicalHistoryUI: React.FC<AllProps> = ({
  psychologicalHistoryData,
  setValue,
  values,
}: AllProps) => {
  const [psycologicalHistoryRecord, setPsycologicalHistoryRecord] = useState(
    psychologicalHistoryData.response
  );

  const [psychologicalHistoryList, setPsychologicalHistoryList] =
    useState<any[]>();

  const filterPsychologicalDiseasName = (val: any) => {
    const diseases = psychologicalHistoryData.response.filter(
      (obj: any) => obj.psychologicalDiseaseId === val
    )[0];
    return getValue(diseases, "psychologicalDiseaseName", "");
  };

  const handlePsychologicalHistoryList = (e: any) => {
    const psychologicalDiseases = psychologicalHistoryList
      ? psychologicalHistoryList
      : [];
    const psychologicalDiseasesUnderIds =
      psychologicalDiseases &&
      length(psychologicalDiseases) &&
      psychologicalDiseases.map(
        (psychologicaldis) => psychologicaldis.psychologicalDiseaseId
      );
    if (
      psychologicalDiseasesUnderIds &&
      psychologicalDiseasesUnderIds.includes(Number(e.target.value))
    ) {
      const newPsychologicalHistory =
        psychologicalDiseases && length(psychologicalDiseases)
          ? psychologicalDiseases.filter(
              (item) => item.psychologicalDiseaseId != e.target.value
            )
          : [];
      setPsychologicalHistoryList(newPsychologicalHistory);
      setValue("psychologicalDiseasesDto", newPsychologicalHistory, true);
    } else {
      const psychologicalDiseas = {
        psychologicalDiseaseId: Number(e.target.value),
        psychologicalDiseaseName: filterPsychologicalDiseasName(
          Number(e.target.value)
        ),
      };

      const newArrWithAddedPsychologicalDiseas = [
        ...psychologicalDiseases,
        psychologicalDiseas,
      ];
      setPsychologicalHistoryList(newArrWithAddedPsychologicalDiseas);
      setValue(
        "psychologicalDiseasesDto",
        newArrWithAddedPsychologicalDiseas,
        true
      );
    }
  };

  useEffect(() => {
    setPsycologicalHistoryRecord(psychologicalHistoryData.response);
    setPsychologicalHistoryList(
      values && values.psychologicalDiseasesDto
        ? values.psychologicalDiseasesDto
        : []
    );
  }, [
    psychologicalHistoryData.response,
    values && values.psychologicalDiseasesDto,
  ]);

  return (
    <>
      <LevelOfFunction></LevelOfFunction>
      <Grid container sx={OuterBodyGrid}>
        <Grid item xs={12} sx={TopGrid}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={PHcontainer}>
                <Grid item xs={12} sx={BorderBottom}>
                  <Box sx={Heading}>
                    <Typography
                      variant="h6"
                      color={colors.fonts[20]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      Psychological History:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={10.2}>
                  <Permission
                    controlId={`|${AuthDetailsAccess.MEDICAL_HISTORY_READ}|${AuthDetailsAccess.MEDICAL_HISTORY_UPDATE}`}
                    passThrough
                  >
                    {(isDisabled: boolean) => (
                      <Grid container sx={CheckBoxGrid}>
                        <FormGroup
                          onChange={handlePsychologicalHistoryList}
                          sx={CheckBoxAlingment}
                        >
                          {psycologicalHistoryRecord &&
                            length(psycologicalHistoryRecord) &&
                            psycologicalHistoryRecord.map(
                              (item: PsychologicalHistory) => {
                                const psychologicalDiseasesId =
                                  psychologicalHistoryList &&
                                  length(psychologicalHistoryList) &&
                                  psychologicalHistoryList.map(
                                    (dis) => dis.psychologicalDiseaseId
                                  );
                                return (
                                  <Grid
                                    key={item.psychologicalDiseaseId}
                                    item
                                    xs={2.4}
                                  >
                                    <FormControlLabel
                                      key={item.psychologicalDiseaseId}
                                      control={
                                        <Checkbox
                                          value={item.psychologicalDiseaseId}
                                          size="small"
                                          checked={
                                            psychologicalDiseasesId &&
                                            psychologicalDiseasesId.includes(
                                              item.psychologicalDiseaseId
                                            )
                                              ? true
                                              : false
                                          }
                                          disabled={isDisabled}
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="subtitle2"
                                          color={otherColor.otherColors[63]}
                                          fontWeight={fontWeight.Weight[5]}
                                          sx={DiseaseName}
                                        >
                                          {item.psychologicalDiseaseName}
                                        </Typography>
                                      }
                                    />
                                  </Grid>
                                );
                              }
                            )}
                        </FormGroup>
                      </Grid>
                    )}
                  </Permission>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthPastMedicalHistoryUI;

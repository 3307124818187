import { Reducer } from "redux";

import { GetDisciplineLevelNote } from "../models/Api/Notes";
import { GetDisciplineLevelNotesDispatchTypes } from "../models/DispatchTypes/Notes";
import { GetDisciplineLevelNotesActionDispatchTypes } from "../constants/Notes";

export const initialState: GetDisciplineLevelNote = {
  loading: false,
  response: {
    disciplineNoteId: -1,
    serviceRequestDisciplineId: -1,
    noteEntryId: -1,
    dateCreated: "",
    dateUpdated: "",
    userCreated: "",
    fullNameCreated: "",
    noteTemplateId: -1,
    templateName: "",
    noteEntryJson: "",
    comment: "",
  },
};

const GetDisciplineLevelNotesReducer: Reducer<
  GetDisciplineLevelNote,
  GetDisciplineLevelNotesDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GetDisciplineLevelNotesActionDispatchTypes.GET_DISCIPLINE_LEVEL_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default GetDisciplineLevelNotesReducer;

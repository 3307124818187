import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { colors } from "../../../../../../../styles/colors";
import { Review } from "../../../../../../../models/Patient";
import { ReviewResponse } from "../../../../../../../models/Api/Review";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  DisciplineCareTypesdGrid,
  DisciplineHeadingGrid,
  DisciplineLeftGrid,
  DisciplineLeftHeadingGrid,
  DisciplineLeftHeadingSubtitleGrid,
  DisciplineLeftListGrid,
  DisciplineLeftListItemGrid,
  DisciplineMainGrid,
  DisciplinePHlistItem,
  DisciplinePHlistItemGrid,
  DisciplineRightGrid,
  DisciplineRightListGrid,
  DividerStyle,
} from "../styles/DesciplinesRequestedStyles";
import { length } from "../../../../../../../utils";

export interface PropsFromState {
  disciplineRequest: ReviewResponse;
}

type DisciplineRequestProps = PropsFromState;

const disciplineData = (discipline: string, index: any) => {
  return (
    <Grid item xs="auto">
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[5]}
        color={colors.fonts[25]}
        sx={{ padding: "1rem 0.5rem" }}
      >
        {index + 1}) &nbsp; {discipline}
      </Typography>
    </Grid>
  );
};

const ReviewDisciplineRequest: React.FC<DisciplineRequestProps> = ({
  disciplineRequest,
}: DisciplineRequestProps) => {
  const [disciplineRequests, setdisciplineRequests] = useState<Review>();
  const { response } = disciplineRequest;

  useEffect(() => {
    if (response) {
      setdisciplineRequests(response);
    }
  }, [response]);

  return (
    <Grid container sx={DisciplineMainGrid}>
      <Grid item xs={6.1}>
        <Grid container sx={DisciplineRightGrid}>
          <Grid item xs={12} sx={DisciplineHeadingGrid}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              DISCIPLINES REQUESTED:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={DividerStyle} light />
          </Grid>
          <Grid container sx={DisciplineRightListGrid}>
            {disciplineRequests &&
              disciplineRequests.serviceRequest &&
              disciplineRequests.serviceRequest.descipline &&
              length(disciplineRequests.serviceRequest.descipline) &&
              disciplineRequests.serviceRequest.descipline.map(
                (data, index) => (
                  <Grid item xs={4} key={index}>
                    {disciplineData(data, index)}
                  </Grid>
                )
              )}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={DividerStyle} light />
          </Grid>
        </Grid>
        <Grid container sx={DisciplineRightGrid}>
          <Grid item xs={12} sx={DisciplineHeadingGrid}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              PSYCHOLOGOCAL HISTORY:
            </Typography>
          </Grid>
          <Grid container sx={DisciplineLeftListGrid}>
            {disciplineRequests &&
              disciplineRequests.serviceRequest &&
              disciplineRequests.serviceRequest.psychologicalHistory &&
              length(disciplineRequests.serviceRequest.psychologicalHistory) &&
              disciplineRequests.serviceRequest.psychologicalHistory.map(
                (data, index) => (
                  <Grid item xs={3} key={index} sx={DisciplinePHlistItemGrid}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[25]}
                      sx={DisciplinePHlistItem}
                    >
                      - {data}
                    </Typography>
                  </Grid>
                )
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5.9} sx={DisciplineLeftGrid}>
        <Grid container sx={DisciplineCareTypesdGrid}>
          <Grid item xs={12} sx={DisciplineLeftHeadingGrid}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              CARE TYPES:
            </Typography>
          </Grid>
          <Grid item xs={12} sx={DisciplineLeftHeadingSubtitleGrid}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[4]}
              fontStyle="italic"
            >
              Care Types Required:
            </Typography>
          </Grid>
          <Grid container sx={DisciplineLeftListGrid}>
            {disciplineRequests &&
              disciplineRequests.serviceRequest &&
              disciplineRequests.serviceRequest.careTypes &&
              length(disciplineRequests.serviceRequest.careTypes) &&
              disciplineRequests.serviceRequest.careTypes.map((data, index) => (
                <Grid item xs={4.5} key={index} sx={DisciplineLeftListItemGrid}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.fonts[25]}
                  >
                    - {data}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReviewDisciplineRequest;

import { Reducer } from "redux";

import { CategoryResponse } from "../models/Api/Notes";
import { CategoryDispatchTypes } from "../models/DispatchTypes/Notes";
import { CategoryActionDispatchTypes } from "../constants/Notes";

export const initialState: CategoryResponse = {
  loading: false,
  response: [
    {
      templateCategoryId: -1,
      templateName:"",
      categoryName: "",
      fullNameCreated: "",
    },
  ],
};

const MasterNotesCategoryReducer: Reducer<
  CategoryResponse,
  CategoryDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionDispatchTypes.MASTER_CATEGORY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CategoryActionDispatchTypes.MASTER_CATEGORY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterNotesCategoryReducer;

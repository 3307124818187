import { colors, others } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

const box = {
  boxSizing: "border-box",
  margin: "0.09rem 0.36rem",
  padding: "0.05rem",
};

const btn = {
  border: "0.05rem solid",
  borderRadius: "0.9rem",
  font: fontWeight.Weight[4],
  letterSpacing: "0px",
  color: colors.primary.main,
  textTransform: "uppercase",
  boxSizing: "border-box",
  padding: "0.5rem 0.73rem 0.5rem 0.73rem",
  height: "1.95rem",
  whiteSpace: "nowrap",
};
const small = [
  btn,
  {
    width: "2.438rem",
  },
];
const secondary = [
  btn,
  {
    "&:hover": {
      border: `1px solid ${colors.hover.main}`,
      color: colors.hover.main,
    },
    "&:disabled": {
      opacity: 0.4,
      border: `1px solid ${colors.hover.main}`,
      color: colors.hover.main,
    },
  },
];

const primary = [
  btn,
  {
    height: "1.95rem",
    border: `0.05rem solid ${colors.primary.main}`,
    background: colors.primary.main,
    color: others.otherColors[33],
    "&:hover": {
      backgroundColor: colors.hover.main,
      color: others.otherColors[33],
    },
    "&:disabled": {
      opacity: 0.4,
      backgroundColor: colors.hover.main,
      color: others.otherColors[33],
    },
  },
];

const label = { marginLeft: "0.34rem", padding: "0px 0.14rem" };

export const styles = {
  box,
  btn,
  secondary,
  primary,
  label,
  small,
};

import { MyPatientAccess } from "./Permission";

export enum MyPatientFilterActionDispatchTypes {
  MY_PATIENT_FILTER_UPDATE = "MY_PATIENT_FILTER_UPDATE",
  MY_PATIENT_FILTER_RESET = "MY_PATIENT_FILTER_RESET",
}

export const myPatientTabs = {
  myPatient: {
    assignButton: true,
    unAssignButton: false,
    remmoveListButton: true,
    assignButtonPermission: null,
    unAssignButtonPermission: null,
    remmoveListButtonPermission: `||${MyPatientAccess.MY_PATIENT_REMOVE_FROM_LIST_UPDATE}`,
  },
  allPending: {
    assignButton: true,
    unAssignButton: true,
    remmoveListButton: false,
    assignButtonPermission: `||${MyPatientAccess.ALL_PENDING_ASSIGN_STAFF_UPDATE}`,
    unAssignButtonPermission: null,
    remmoveListButtonPermission: null,
  },
  incompleteRequest: {
    assignButton: true,
    unAssignButton: false,
    remmoveListButton: false,
    assignButtonPermission: `||${MyPatientAccess.INCOMPLETE_ASSIGN_STAFF_UPDATE}`,
    unAssignButtonPermission: null,
    remmoveListButtonPermission: null,
  },
  staffingQueue: {
    assignButton: true,
    unAssignButton: true,
    remmoveListButton: true,
    assignButtonPermission: `||${MyPatientAccess.STAFFING_QUEUE_ASSIGN_STAFF_UPDATE}`,
    unAssignButtonPermission: `||${MyPatientAccess.STAFFING_QUEUE_UNASSIGN_STAFF_UPDATE}`,
    remmoveListButtonPermission: `||${MyPatientAccess.STAFFING_QUEUE_REMOVE_FROM_LIST_UPDATE}`,
  },
  pdn: {
    assignButton: true,
    unAssignButton: false,
    remmoveListButton: false,
    assignButtonPermission: null,
    unAssignButtonPermission: null,
    remmoveListButtonPermission: null,
  },
};

export const getCurrentTabBtnDetails = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return myPatientTabs.myPatient;
    case 1:
      return myPatientTabs.allPending;
    case 2:
      return myPatientTabs.incompleteRequest;
    case 3:
      return myPatientTabs.staffingQueue;
    case 4:
      return myPatientTabs.pdn;
  }
  return myPatientTabs.myPatient;
};

export enum AllPendingUnassignStaffActionDispatchTypes {
  ALL_PENDING_UNASSIGN_STAFF_ERROR = "ALL_PENDING_UNASSIGN_STAFF_ERROR",
  ALL_PENDING_UNASSIGN_STAFF_INVOKE = "ALL_PENDING_UNASSIGN_STAFF_INVOKE",
  ALL_PENDING_UNASSIGN_STAFF_SUCCESS = "ALL_PENDING_UNASSIGN_STAFF_SUCCESS",
  ALL_PENDING_UNASSIGN_STAFF_RESET = "ALL_PENDING_UNASSIGN_STAFF_RESET",
}
import { Reducer } from "redux";

import { StaffingUnassignStaffResponse } from "../models/Api/Staffing";
import { StaffingUnassignStaffDispatchTypes } from "../models/DispatchTypes/Staffing";
import { StaffingUnassignStaffActionDispatchTypes } from "../constants/Staffing";

export const initialState: StaffingUnassignStaffResponse = {
  loading: false,
  response: {
    searchResponse: [],
    totalRows: 0,
  },
};

const StaffingUnassignStaffReducer: Reducer<
  StaffingUnassignStaffResponse,
  StaffingUnassignStaffDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
  }
  return state;
};

export default StaffingUnassignStaffReducer;

import { useEffect, useState, Dispatch } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Control, UseFormReset, useForm } from "react-hook-form";
import { Grid, Typography, Button, FormControl } from "@mui/material";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material";
import dayjs from "dayjs";

import NotesDesigner from "../../../../../components/formComponents/NotesDesigner";
import {
  styles,
  TitleBox,
  CategoryStatus,
  TemplateNameStyle,
  templateNameStyle,
  CategoryWidth,
  AssessmentDesignderStyle,
} from "../styles/NotesList";
import { Head, Body, Main } from "../../../../../components/custom/structure";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  getValue,
  isNil,
  length,
  serverSideErrorMsg,
} from "../../../../../utils";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import {
  CategoryResponse,
  GetNotesResponse,
  SaveNotesResponse,
} from "../../../../../models/Api/Notes";
import { CategoryMaster, SaveNotes } from "../../../../../models/Notes";
import { rules } from "../../../../../utils/validation/Validation";
import {
  GetAdminNotesByVersionIdActionDispatchTypes,
  SaveAdminNotesActionDispatchTypes,
  UpdateAdminNotesActionDispatchTypes,
  formInitialState,
} from "../../../../../constants/Notes";
import { UserDetailsPayload } from "../../../../../models/User";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
export interface PropsDispatch {
  getNotesCategoryMaster: () => void;
  saveNotesSection: (payload: any) => void;
  GetNotesSectionByVersionId: (noteTemplateVersionId: number) => void;
  updateNotesSection: (id: number, payload: any) => void;
  resetStates: (actionType: string[]) => void;
}
export interface PropsState {
  categorydata: CategoryResponse;
  NotesDataById: GetNotesResponse;
  SaveNotesResp: SaveNotesResponse;
  UpdateNotesResponse: SaveNotesResponse;
  user: UserDetailsPayload;
}
type UserDetailsProps = PropsState & PropsDispatch;

const CreateNoteTemplate: React.FC<UserDetailsProps> = ({
  categorydata,
  getNotesCategoryMaster,
  saveNotesSection,
  GetNotesSectionByVersionId,
  NotesDataById,
  updateNotesSection,
  user,
  SaveNotesResp,
  UpdateNotesResponse,
  resetStates,
}: UserDetailsProps) => {
  const defaultJson = {
    pages: [{}],
  };
  const { templateId } = useParams();
  const paramId = window.location.pathname.split("/").pop();
  const [categoryList, setCategoryList] = useState<CategoryMaster[] | any[]>(
    []
  );

  const { loading: loadingCategorydataStatus, response: categorydataResponse } =
    categorydata;

  const { loading: loadingNotesDataById, response: NotesDataByIdResponse } =
    NotesDataById;

  const { response: SaveNotesRespData, error: SaveNotesRespError } =
    SaveNotesResp;

  const { response: UpdateNotesResponseData, error: UpdateNotesResponseError } =
    UpdateNotesResponse;

  const navigate = useNavigate();

  const [saveAndExit, setSaveAndExit] = useState<boolean>(false);
  const [category, setCategory] = useState<boolean>(false);
  const [json, setJson] = useState<string | null>(JSON.stringify(defaultJson));
  const [approvalStatusName, setApprovalStatusName] = useState<string>("");
  const [templateCategoryName, setTemplateCategoryName] = useState<string>("");
  const [tempNoteTemplateId, setTempNoteTemplateId] = useState<number | null>();

  const methods = useForm<SaveNotes>({
    defaultValues: formInitialState,
  });
  const { handleSubmit, control, reset, watch, setError } = methods;

  const watchCategory = watch("templateCategoryId");

  const onSubmit = (data: SaveNotes) => {
    const newData = {
      ...data,
      templateJson: json,
      version: 1,
      approvalStatusId: 1,
      userCreated: user && user.email ? user.email : null,
      dateCreated: dayjs(),
      templateCategoryName: templateCategoryName,
    };
    if (templateId === undefined) {
      saveNotesSection({
        ...newData,
        approvalStatusName: "Approved",
        templateVersionId: 0,
        noteTemplateId: 0,
      });
    } else {
      updateNotesSection(Number(paramId), {
        ...newData,
        approvalStatusName: approvalStatusName,
        noteTemplateId: Number(tempNoteTemplateId),
        templateVersionId: Number(paramId),
      });
    }
  };

  useEffect(() => {
    if (!isNil(SaveNotesRespError)) {
      serverSideErrorMsg(SaveNotesRespError, setError);
    }
  }, [SaveNotesRespError]);

  useEffect(() => {
    if (!isNil(UpdateNotesResponseError)) {
      serverSideErrorMsg(UpdateNotesResponseError, setError);
    }
  }, [UpdateNotesResponseError]);

  useEffect(() => {
    if (
      SaveNotesRespData &&
      SaveNotesRespData.templateVersionId !== -1 &&
      saveAndExit === false
    ) {
      resetStates([SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET]);
      setTempNoteTemplateId(Number(SaveNotesRespData.noteTemplateId));
      setApprovalStatusName(SaveNotesRespData.approvalStatusName);
      navigate(
        ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT_EDIT_TEMPLATE, {
          templateVersionId: Number(SaveNotesRespData.templateVersionId),
        })
      );
      setJson(SaveNotesRespData.templateJson);
    }
    if (saveAndExit === true && SaveNotesRespData.templateVersionId !== -1) {
      navigate(ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT));
    }
  }, [SaveNotesRespData, saveAndExit]);

  useEffect(() => {
    if (
      UpdateNotesResponseData &&
      UpdateNotesResponseData.templateVersionId !== -1 &&
      saveAndExit === false
    ) {
      resetStates([UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET]);
      setJson(UpdateNotesResponseData.templateJson);
    }
    if (
      saveAndExit === true &&
      UpdateNotesResponseData.templateVersionId !== -1
    ) {
      resetStates([
        GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
      ]);
      navigate(ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT));
    }
  }, [UpdateNotesResponseData, saveAndExit]);

  useEffect(() => {
    const getCategoryName = categoryList.filter(
      (val) => val.value === watchCategory
    );
    setTemplateCategoryName(
      (getCategoryName.length !== 0 && getCategoryName[0].label) || ""
    );
  }, [watchCategory]);

  useEffect(() => {
    if (!loadingCategorydataStatus) {
      getNotesCategoryMaster();
    }
  }, []);

  useEffect(() => {
    if (!loadingNotesDataById) {
      if (templateId !== undefined) {
        GetNotesSectionByVersionId(Number(templateId));
      } else {
        resetStates([
          GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
        ]);
      }
    }
  }, [templateId]);

  useEffect(() => {
    if (templateId !== undefined) {
      if (NotesDataByIdResponse) {
        if (getValue(NotesDataByIdResponse, "templateCategoryId") > 0) {
          setCategory(true);
        }
        setJson(JSON.parse(JSON.stringify(NotesDataByIdResponse.templateJson)));
        setApprovalStatusName(NotesDataByIdResponse.approvalStatusName);
        setTemplateCategoryName(NotesDataByIdResponse.templateCategoryName);
        setTempNoteTemplateId(Number(NotesDataByIdResponse.noteTemplateId));
        setJson(NotesDataByIdResponse.templateJson);
        reset(NotesDataByIdResponse);
      }
    } else {
      if (json === undefined) {
        setJson(null);
      }
      reset(formInitialState);
    }
  }, [NotesDataByIdResponse]);

  useEffect(() => {
    const categoryStatus =
      categorydataResponse &&
      length(categorydataResponse) &&
      categorydataResponse.map((item) => {
        return {
          label: item.categoryName,
          value: item.templateCategoryId,
        };
      });
    setCategoryList(categoryStatus || []);
  }, [categorydataResponse]);

  const handleBack = () => {
    resetStates([
      GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
      UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET,
      SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET,
    ]);
    navigate(ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT));
  };
  const handleSave = () => {
    setSaveAndExit(false);
    submit();
  };

  const handleSaveAndExit = () => {
    setSaveAndExit(true);
    submit();
  };

  const submit = () => {
    handleSubmit(onSubmit)();
  };

  const handleCancel = () => {
    resetStates([
      GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET,
      UpdateAdminNotesActionDispatchTypes.UPDATE_NOTES_RESET,
      SaveAdminNotesActionDispatchTypes.SAVE_NOTES_RESET,
    ]);
    navigate(ModuleLinks(PatientRecordsModule.NOTE_MANAGEMENT));
  };

  return (
    <>
      <Main>
        <Head
          title={"NOTE MANAGEMENT"}
          description={"View and take actions on notes based settings"}
          back
          handleBack={handleBack}
          dataTestId={"userProfile-back"}
        >
          <Button onClick={handleCancel} variant="text">
            CANCEL
          </Button>
          <Permission
            controlId={`${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_CREATE}||${AdminCenterAccess.ADMIN_CENTER_NOTE_MANAGEMENT_UPDATE}`}
          >
            <Button onClick={handleSaveAndExit} variant="contained">
              SAVE AND EXIT
            </Button>
            <Button onClick={handleSave} variant="contained">
              SAVE
            </Button>
          </Permission>
        </Head>
        <Body>
          <NoteDesignerBody
            control={control}
            reset={reset}
            setJson={setJson}
            categoryList={categoryList}
            setCategory={setCategory}
            category={category}
            json={json}
          />
        </Body>
      </Main>
    </>
  );
};

type NoteDesignerBodyProps = {
  control: Control<SaveNotes, any>;
  reset: UseFormReset<SaveNotes>;
  setJson: Dispatch<React.SetStateAction<string | null>>;
  categoryList: CategoryMaster[];
  setCategory: Dispatch<React.SetStateAction<boolean>>;
  category: boolean;
  json: string | null;
};

const NoteDesignerBody = (props: NoteDesignerBodyProps) => {
  const { control, categoryList, category, setCategory, json, setJson } = props;

  const headerStyle = (
    marginBottom: number,
    title: string,
    isDescriptionAvailable?: boolean,
    description?: string
  ) => {
    return (
      <>
        <TitleBox mb={marginBottom}>
          <Typography
            variant="subtitle1"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
          >
            {title}:
          </Typography>
          {isDescriptionAvailable && (
            <Typography
              mt={1}
              variant="subtitle2"
              color={alpha(colors.black[5], 0.8)}
              fontWeight={fontWeight.Weight[3]}
            >
              {description}
            </Typography>
          )}
        </TitleBox>
      </>
    );
  };

  const handleCategorySelection = (e: any) => {
    if (e.target.value > 0) {
      setCategory(true);
    }
  };

  const saveSurveyJson = (json: any) => {
    setJson(JSON.stringify(json));
  };

  return (
    <Grid container sx={styles.body}>
      <Grid item xs={3}>
        {headerStyle(2, "Note Designer", true, "Details specific to note form")}
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={2.5} sx={CategoryStatus}>
            <FormControl variant="standard" fullWidth sx={CategoryWidth}>
              <FormInputDropdown
                onChangeHandler={(e: any) => handleCategorySelection(e)}
                name="templateCategoryId"
                fieldrequired="required"
                control={control}
                label="Category:"
                helper={rules.TemplateCategory}
                list={categoryList}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2.5} sx={TemplateNameStyle}>
            <FormControl variant="standard" fullWidth sx={templateNameStyle}>
              <FormInputText
                name="templateName"
                control={control}
                label="Template Name:"
                textLength={128}
                helper={rules.TemplateName}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={0} mb={3}>
        <Divider
          orientation="horizontal"
          variant="middle"
          textAlign="center"
          flexItem
        />
      </Grid>
      <Grid item xs={12} mt={0} mb={3} sx={AssessmentDesignderStyle}>
        {category && (
          <NotesDesigner
            model={json}
            saveSurveyJson={saveSurveyJson}
            setJson={setJson}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateNoteTemplate;

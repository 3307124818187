import { Reducer } from "redux";

import { MyPatientResponse } from "../models/Api/AllPatientRecord";
import { UpdateMyPatientListDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { MyPatientListActionDispatchTypes } from "../constants/AllPatientRecord";

const initialState: MyPatientResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [],
  },
};

const PutMyPatientListReducer: Reducer<
  MyPatientResponse,
  UpdateMyPatientListDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case MyPatientListActionDispatchTypes.MY_PATIENT_LIST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MyPatientListActionDispatchTypes.MY_PATIENT_LIST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case MyPatientListActionDispatchTypes.MY_PATIENT_LIST_ERROR: {
      return {
        ...state,
        loading: true,
      };
    }
  }
  return state;
};
export default PutMyPatientListReducer;

import { Reducer } from "redux";

import { ModulesDispatchTypes } from "../models/DispatchTypes/Modules";
import { UserModuleResponse } from "../models/Api/Modules";
import { ModuleActionDispatchTypes } from "../constants/Modules";

export const initialState: UserModuleResponse = {
  loading: false,
  response: [
    {
      moduleId: -1,
      moduleName: "",
      moduleDescription: "",
      moduleOrder: -1,
      routeUrl: "",
      subModules: [],
    },
  ],
};

const UserModule: Reducer<UserModuleResponse, ModulesDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ModuleActionDispatchTypes.MODULE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ModuleActionDispatchTypes.MODULE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default UserModule;

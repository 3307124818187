import { colors } from "../../../../../styles/colors";

export const CreatedByWidth = () => ({
  minWidth: "7rem",
});

export const DataTextField = () => ({
  "& .MuiInput-input": {
    WebkitBoxShadow: `0 0 0 62.5rem ${colors.backgroundColor[9]} inset`,
  },
  "& .MuiInputLabel-root": {
    display: "flex",
  },
});

export const FieldGrid = () => ({
  textAlign: "left",
  padding: "1.8rem 2rem 1.8rem 0",
});

export const AutoComplete = {
  paddingTop: "0.6rem",
  width: "14rem",
  minWidth: "7rem",
  maxWidth: "250rem",
  "& .MuiInput-input": {
    WebkitBoxShadow: `0 0 0 62.5rem ${colors.backgroundColor[9]} inset`,
  },
};


export const Search = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

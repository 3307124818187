import { reactAppDebug } from "../../constants/Config";

const suppressConsole = () => {
  if (!reactAppDebug) {
    console.info = () => undefined;
    console.warn = () => undefined;
    console.error = () => undefined;
    console.log = () => undefined;
  }
};

const log = (message: any) => {
  console.log(message);
};

const warn = (message: any) => {
  console.warn(message);
};

const info = (message: any) => {
  console.info(message);
};

const error = (message: any) => {
  console.error(message);
};

const debug = (message: any) => {
  console.debug(message);
};

export default {
  log,
  warn,
  info,
  error,
  debug,
  suppressConsole,
};

import { Reducer } from "redux";

import { RelationshipActionDispatchTypes } from "../constants/Patient";
import { RelationshipMasterResponse } from "../models/Api/Patient";
import { RelationshipDispatchTypes } from "../models/DispatchTypes/Patient";

export const initialState: RelationshipMasterResponse = {
  loading: false,
  response: [
    {
      relationId: 0,
      relationshipName: "",
    },
  ],
};

const RelationshipReducer: Reducer<
  RelationshipMasterResponse,
  RelationshipDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RelationshipActionDispatchTypes.RELATIONSHIP_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RelationshipActionDispatchTypes.RELATIONSHIP_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default RelationshipReducer;

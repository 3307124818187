import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { HeaderLabel } from "../../../../../constants/User";
import {
  Header,
  ListHeader,
  TableMain,
  TableMainHead,
  TablecellStyle,
  Data,
  HeaderTableRow,
  TableBoxMargin,
  PaginationGrid,
  IconFaPencilAlt,
} from "../styles/userProfile";
import usePagination from "../../../../../utils/Pagination";
import {
  PER_PAGE,
  DEFAULT_PAGE,
} from "../../../../../constants/FunctionalUtils";
import { formatDate } from "../../../../../utils/DateTime";
import { User } from "../../../../../models/User";
import { avator } from "../../../../../utils";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { Variant } from "@mui/material/styles/createTypography";
import { flexAllCentre } from "../../../../../styles/mui/styles/display";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import { IconDisabled } from "../../../../../styles/common/style";

export interface PropsFromState {
  list: User[];
}

const UserList: React.FC<PropsFromState> = ({ list }) => {
  const [page, setPage] = useState(1);
  const count = Math.ceil(list.length / PER_PAGE);
  const userListData = usePagination(list, PER_PAGE);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param e is mandatory
  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    userListData.jump(page);
  };

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    userListData.jump(DEFAULT_PAGE);
  }, [list]);

  const handleNavigate = (userId: number) => {
    navigate(
      ModuleLinks(PatientRecordsModule.USER_MANAGEMENT_DETAILS, {
        userId: userId,
      })
    );
  };

  const tableCell = (
    value: string | number,
    variant: Variant,
    color: string,
    fontWeight: string
  ) => {
    return (
      <TableCell>
        <Header>
          <Box sx={ListHeader}>
            <Typography
              variant={variant}
              color={color}
              fontWeight={fontWeight}
              m={"0 0.5rem"}
              sx={Data}
            >
              {value}
            </Typography>
          </Box>
        </Header>
      </TableCell>
    );
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <TableMain aria-label="simple table">
                <TableMainHead>
                  <TableRow sx={HeaderTableRow}>
                    {HeaderLabel.map((tablecell, index) => (
                      <TableCell
                        key={index}
                        width={tablecell.width}
                        style={TablecellStyle}
                      >
                        <Typography
                          variant="body1"
                          color={colors.black[2]}
                          fontWeight={fontWeight.Weight[3]}
                          sx={TableBoxMargin}
                        >
                          {tablecell.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableMainHead>
                <TableBody>
                  {userListData.currentData().length > 0 ? (
                    userListData.currentData().map((user: User) => {
                      return (
                        <TableRow key={user.userId}>
                          <TableCell>
                            <Header>
                              <Box sx={ListHeader} m={"0 0.5rem"}>
                                <Avatar src={avator(user.userPhoto)} />
                              </Box>
                            </Header>
                          </TableCell>
                          {tableCell(
                            user.fullName,
                            "subtitle1",
                            colors.fonts[18],
                            fontWeight.Weight[5]
                          )}
                          {tableCell(
                            user.email,
                            "subtitle2",
                            colors.fonts[1],
                            fontWeight.Weight[4]
                          )}
                          {tableCell(
                            user.userStatus,
                            "subtitle2",
                            user.userStatus == "Suspended"
                              ? colors.red[100]
                              : colors.fonts[22],
                            fontWeight.Weight[4]
                          )}
                          {tableCell(
                            formatDate(user.lastLogin),
                            "subtitle2",
                            colors.fonts[1],
                            fontWeight.Weight[4]
                          )}
                          {tableCell(
                            user.roles || "",
                            "subtitle2",
                            colors.fonts[1],
                            fontWeight.Weight[4]
                          )}
                          <TableCell>
                            <Header>
                              <Box sx={ListHeader}>
                                <Permission
                                  controlId={`|${AdminCenterAccess.ADMIN_CENTER_USER_MANAGEMENT_READ}|${AdminCenterAccess.ADMIN_CENTER_USER_MANAGEMENT_UPDATE}`}
                                  passThrough
                                >
                                  {(isDisabled: boolean) => (
                                    <IconFaPencilAlt
                                      data-testid={`userList-edit${user.userId}`}
                                      onClick={() =>
                                        handleNavigate(user.userId)
                                      }
                                      style={isDisabled ? IconDisabled : {}}
                                    />
                                  )}
                                </Permission>
                              </Box>
                            </Header>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableCell colSpan={7}>
                      <Header>
                        <Box sx={flexAllCentre} pt="1rem" pb="1rem">
                          <Typography
                            variant="subtitle2"
                            color={colors.fonts[1]}
                            fontWeight={fontWeight.Weight[4]}
                            sx={TableBoxMargin}
                          >
                            No data available
                          </Typography>
                        </Box>
                      </Header>
                    </TableCell>
                  )}
                </TableBody>
              </TableMain>
            </TableContainer>
          </Grid>
          {userListData.currentData().length > 0 && (
            <Grid item xs={12} sx={PaginationGrid}>
              <PaginationItem
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default UserList;

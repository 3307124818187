import { IcdCodeTypeResponse } from "../models/Api/Master";
import { IcdCodeTypeDispatchTypes } from "../models/DispatchTypes/Master";
import { Reducer } from "redux";
import { IcdCodeTypeActionDispatchTypes } from "../constants/Master";

const initialState: IcdCodeTypeResponse = {
  loading: false,
  response: [
    {
      diagnosisTypeId: 0,
      diagnosisType: "",
    },
  ],
};

const IcdCodeTypeReducer: Reducer<
  IcdCodeTypeResponse,
  IcdCodeTypeDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case IcdCodeTypeActionDispatchTypes.ICD_CODE_TYPE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IcdCodeTypeActionDispatchTypes.ICD_CODE_TYPE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default IcdCodeTypeReducer;

import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";

import {
  PaginationStyle,
  PatientContainerBox,
  TableGrid,
} from "../../styles/style";
import { getPatientFilterPayload, isNil, length } from "../../../../utils";
import {
  IncompletePatientSearch,
  IncompleteRequestSearchResp,
} from "../../../../models/Patient";
import {
  ASC,
  DESC,
  PER_PAGE,
} from "../../../../constants/FunctionalUtils";
import AssignStaff from "../../containers/AssignStaff";
import {
  getSortColumn,
  IncompleteRequestAssignStaffHeaderLabel,
  incompleteRequestData,
  IncompleteRequestHeaderLabel,
} from "../../../../constants/AllPatientRecord";
import TableComponent from "../../../../components/formComponents/TableComponent";
import PaginationItem from "../../../../components/custom/pagination/components/PaginationItem";
import { FilterModel } from "../../../../models/Api/Filter";
export interface PropsFromState {
  activeTab: number;
  fieldValues: any;
  postIncompletePatientList: (payload: any) => void;
  handleClear: () => void;
  openModal: boolean;
  errorFlag: boolean;
  patientRecord: IncompletePatientSearch | null;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: (name: string, value: number | string, boolean: any) => void;
  getValues: UseFormGetValues<any>;
  setPage: (page: number) => void;
  page: number;
  setFilter: (filter: string) => void;
  setPatientSelectedForIncomplete: React.Dispatch<
    React.SetStateAction<IncompleteRequestSearchResp[]>
  >;
  patientSelectedForIncomplete: IncompleteRequestSearchResp[];
  action: string;
  setSelectedFilterByArray: (arr: string[] | any) => void;
  storeFilterPayload: (type: number, payload?: any) => void;
  filterState: FilterModel;
  valueToOrderBy: string;
  setValueToOrderBy: (valueToOrderBy: string) => void;
}

type Props = PropsFromState;

const IncompleteRequests = ({
  activeTab,
  postIncompletePatientList,
  handleClear,
  openModal,
  errorFlag,
  patientRecord,
  setOpenModal,
  setErrorFlag,
  setValue,
  getValues,
  setPage,
  page,
  setPatientSelectedForIncomplete,
  patientSelectedForIncomplete,
  action,
  filterState,
  valueToOrderBy,
  setValueToOrderBy
}: Props) => {
  const [allSelect, setAllSelect] = useState(false);
  const [patientSelected, setPatientSelected] = useState<
    IncompleteRequestSearchResp[]
  >([]);
  const [orderDirection, setOrderDirection] = useState(ASC);
  const [incompleteRequestList, setIncompleteRequestList] = useState(
    patientRecord &&
      patientRecord.searchResponse &&
      length(patientRecord.searchResponse) &&
      patientRecord.searchResponse[0].patientId != 0
      ? patientRecord.searchResponse
      : []
  );

  const [allSelectedPatients, setAllSelectedPatients] = useState<
    IncompleteRequestSearchResp[]
  >([]);

  const count = Math.ceil(
    patientRecord && patientRecord.totalRows
      ? Number(patientRecord.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );
  /**------------------------------------------------------------------------------------- */
  const handleSort = (property: string | undefined) => {
    onAction(false, 1, property);
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param e is mandatory
  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    onAction(true, page);
  };
  const onAction = (
    isPageChange: boolean,
    page: number,
    property?: string | undefined
  ) => {
    setPage(isPageChange ? page : 1);
    setValue("pageNo", isPageChange ? page : 1, true);
    if (!isPageChange) {
      const isAsc =
        valueToOrderBy === getSortColumn(property) && orderDirection === ASC;
      setOrderDirection(isAsc ? DESC : ASC);
      setValueToOrderBy(getSortColumn(property));
      setValue("sortColumn", getSortColumn(property), true);
      setValue("sortOrder", isAsc ? DESC : ASC, true);
      postIncompletePatientList({
        ...getPatientFilterPayload(filterState.response.incompleteRequest),
        pageNo: getValues("pageNo"),
        sortColumn: getSortColumn(property),
        sortOrder: isAsc ? DESC : ASC,
      });
      return;
    }
    postIncompletePatientList({
      ...getPatientFilterPayload(filterState.response.incompleteRequest),
      pageNo: page,
      sortColumn: valueToOrderBy,
      sortOrder: orderDirection,
    });
  };

  /**------------------------------------------------------------------------------------- */

  useEffect(() => {
    const incompleteRequestList =
      patientRecord &&
        patientRecord.searchResponse &&
        length(patientRecord.searchResponse) &&
        patientRecord.searchResponse[0].patientId != 0
        ? patientRecord.searchResponse
        : [];
    setIncompleteRequestList(incompleteRequestList);
  }, [patientRecord]);

  useEffect(() => {
    setPatientSelectedForIncomplete(patientSelected);
  }, [patientSelected]);

  useEffect(() => {
    if (openModal) {
      if (patientSelectedForIncomplete.length == 0) {
        setErrorFlag(true);
        setOpenModal(false);
      }
      if (length(patientSelectedForIncomplete)) {
        setErrorFlag(false);
      }
    }
  }, [openModal, patientSelectedForIncomplete]);

  useEffect(() => {
    if (openModal) {
      if (patientSelected.length == 0) {
        setErrorFlag(true);
        setOpenModal(false);
      }
    }
    if (length(patientSelected)) {
      setErrorFlag(false);
    }
  }, [openModal, patientSelected]);

  useEffect(() => {
    const isPresent =
      incompleteRequestList.length !== 0 &&
      incompleteRequestList.every((val) =>
        allSelectedPatients.some((item) =>
          Object.entries(val).every(
            ([key, value]) =>
              item[key as keyof IncompleteRequestSearchResp] === value
          )
        )
      );

    if (isPresent === true) {
      setAllSelect(true);
      setPatientSelected(allSelectedPatients);
    } else {
      setAllSelect(false);
      setPatientSelected(allSelectedPatients);
    }
  }, [allSelectedPatients, page, incompleteRequestList, patientSelected]);

  const handleCheck = (e: any) => {
    if (e.target.value == "all") {
      setAllSelect(!allSelect);
      if (!allSelect) {
        const selectList = incompleteRequestList;
        const isAllSelectedPatients = selectList.every((val) =>
          allSelectedPatients.some((item) =>
            Object.entries(val).every(
              ([key, value]) =>
                item[key as keyof IncompleteRequestSearchResp] === value
            )
          )
        );
        const filteredArray = selectList.filter(
          (item) =>
            !allSelectedPatients.some(
              (value) => value.serviceRequestId === item.serviceRequestId
            )
        );

        if (isAllSelectedPatients === false) {
          setAllSelectedPatients([...allSelectedPatients, ...filteredArray]);
        }

        setPatientSelected(selectList);
        return;
      }
      const uncheckArray = allSelectedPatients.filter(
        (items) =>
          !incompleteRequestList.some(
            (val) => val.serviceRequestId === items.serviceRequestId
          )
      );
      setPatientSelected([]);
      setAllSelectedPatients(uncheckArray);
    } else {
      const patientListOriginal: IncompleteRequestSearchResp[] =
        incompleteRequestList;
      const patientList = patientSelected ? patientSelected : [];
      const patientAuthNos =
        patientList && patientList.map((patient) => patient.serviceRequestId);
      if (patientAuthNos && patientAuthNos.includes(Number(e.target.value))) {
        const newPatients = patientList
          ? patientList.filter((item) => {
            return item.serviceRequestId != e.target.value;
          })
          : [];
        const newSelectedPatients = allSelectedPatients
          ? allSelectedPatients.filter((item) => {
            return item.serviceRequestId != e.target.value;
          })
          : [];
        setPatientSelected(newPatients);
        setAllSelectedPatients(newSelectedPatients);
        setAllSelect(false);
      } else {
        const patient = patientListOriginal.filter(
          (item) => item.serviceRequestId == e.target.value
        );
        const newArrayWithAddedPatients = [...patientList, patient[0]];
        setPatientSelected(newArrayWithAddedPatients);
        setAllSelectedPatients(newArrayWithAddedPatients);
      }
    }
  };

  const checkPatient = (Id: any) => {
    const patientChecked = patientSelected.filter(
      (patient) => patient.serviceRequestId == Id
    );
    if (patientChecked.length == 0) return false;
    else return true;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PatientContainerBox m={"0 1rem"} sx={TableGrid}>
          <TableComponent
            allSelect={allSelect}
            checkPatient={checkPatient}
            handleCheck={handleCheck}
            handleSort={handleSort}
            HeaderLabel={IncompleteRequestHeaderLabel}
            list={incompleteRequestData}
            orderDirection={orderDirection}
            patientList={incompleteRequestList}
            type="incompleteRequest"
            valueToOrderBy={valueToOrderBy}
          />
        </PatientContainerBox>
      </Grid>
      {length(incompleteRequestList) && (
        <Grid item xs={12} sx={PaginationStyle}>
          <PaginationItem
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Grid>
      )}
      {!isNil(patientSelected) && openModal && !errorFlag && (
        <AssignStaff
          activeTab={activeTab}
          handleClear={handleClear}
          openModal={openModal}
          patientList={IncompleteRequestAssignStaffHeaderLabel}
          selectedList={patientSelected}
          setAllSelect={setAllSelect}
          setOpenModal={setOpenModal}
          setPage={setPage}
          setPatientSelected={setPatientSelected}
          type="incompleteRequest"
          setAllSelectedPatients={setAllSelectedPatients}
          action={action}
        />
      )}
    </Grid>
  );
};

export default IncompleteRequests;

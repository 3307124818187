import { Reducer } from "redux";

import { ReAuthServiceRequestResponse } from "../models/Api/Service";
import { ReAuthServiceRequestActionDispatchType } from "../constants/Service";
import { ReAuthServiceRequestDispatchTypes } from "../models/DispatchTypes/Service";

const initialState: ReAuthServiceRequestResponse = {
  loading: false,
  response: {
    serviceRequestId: -1,
    referralId: -1,
    userCreated: null,
    startOfCareDate: "",
    startOfCareVerified: false,
    currentStartDate: "",
    currentEndDate: "",
    nextStartDate: "",
    nextEndDate: "",
    reAuthDiscipline: [],
    currentDate: "",
  },
  error: null,
};

const ReAuthServiceRequestReducer: Reducer<
  ReAuthServiceRequestResponse,
  ReAuthServiceRequestDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ReAuthServiceRequestReducer;

import React from "react";
import { useEffect, useState } from "react";
import { FaTimes, FaCheck } from "react-icons/fa";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { colors } from "../../../../../styles/colors";
import {
  baseContainer,
  CreateSchedule,
  headerStyle,
  insuranceDetailsContainer,
  InsuranceGrid,
  InsuranceTable,
  patientDetailStyle,
  RecordGridContainer,
  rowStyle,
  StatusValue,
} from "../styles/validateRecordsStyle";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  flexAlignCentre,
  flexAllEnd,
  overFlowHidden,
} from "../../../../../styles/mui/styles/display";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import {
  CheckEligibility,
  PatientFilterOptions,
} from "../../../../../models/Patient";
import { CheckEligibilityResponse } from "../../../../../models/Api/AllPatientRecord";
import { DateFormats } from "../../../../../constants/Date";
import { EligibilityRecord } from "../../../../../models/Master";
import {
  AllPatientRecordsLabel,
  EligibilityCheckDispatchTypes,
  EligibilityRecordLabel,
} from "../../../../../constants/AllPatientRecord";
import { getValue } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { isEmpty } from "lodash";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { EligibilityData } from "../../../../../models/EligibilityData";

interface Props {
  checkEligibility: CheckEligibility | null;
  CheckEligibilityData: CheckEligibilityResponse;
  eligibilityRecord: EligibilityRecord | undefined;
  fieldValues: any;
  payerState: PatientFilterOptions[];
  resetStates: (actionType: string[]) => void;
  storeEligibilityData: (data: EligibilityData) => void
}

const ValidateRecord: React.FC<Props> = ({
  CheckEligibilityData,
  eligibilityRecord,
  fieldValues,
  resetStates,
  storeEligibilityData
}: Props) => {
  const [isNotLoading, setIsNotLoading] = useState<boolean>(true);

  useEffect(() => {
    if (CheckEligibilityData.loading === true) {
      setIsNotLoading(false);
    }
    if (
      CheckEligibilityData.loading === false &&
      isEmpty(
        getValue(CheckEligibilityData, "response.record.subscriberId", null)
      )
    ) {
      setIsNotLoading(true);
    }
  }, [CheckEligibilityData.loading]);

  const navigate = useNavigate();

  const handleView = (isManual: number) => {
    const urlParams = {
      lastName: fieldValues.lastName,
      dob:
        getValue(fieldValues, "dateOfBirth") &&
        formatDate(fieldValues.dateOfBirth, DateFormats.YYYY_MM_DD),
      healthPlan: fieldValues.healthPlan,
      policyId:
        eligibilityRecord && eligibilityRecord.subscriberId
          ? eligibilityRecord.subscriberId
          : 0,
      isManual: isManual,
    };

    setIsNotLoading(false);
    const data = {...eligibilityRecord, checkEligibilityResponse: CheckEligibilityData}
    storeEligibilityData(data)
    
    setTimeout(() => {
      navigate(
        ModuleLinks(PatientRecordsModule.PATIENT_INFORMATION_VIEW, urlParams)
      );
      resetStates &&
        resetStates([EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET]);
    }, 100);
  };

  return (
    <Box m={"0 1rem"} sx={baseContainer}>
      <Box sx={headerStyle}>{header()}</Box>
      {getValue(CheckEligibilityData, "response.record.subscriberId", null) &&
      !CheckEligibilityData.loading ? (
        <Box sx={rowStyle}>
          <Row
            checkEligibility={getValue(CheckEligibilityData, "response")}
            eligibilityRecord={eligibilityRecord}
            handleView={handleView}
          />
        </Box>
      ) : (
        <Box>
          {isNotLoading && (
            <Grid container sx={RecordGridContainer}>
              <Grid item xs={9.6}>
                <Typography
                  variant="h6"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[4]}
                  align="center"
                  pt={"0.4rem"}
                >
                  No records found
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                {!isEmpty(
                  getValue(
                    CheckEligibilityData,
                    "response.additionalNotes.apiRequestId",
                    ""
                  )
                ) &&
                  getValue(
                    CheckEligibilityData,
                    "response.additionalNotes.apiRequestId"
                  ).length > 0 && (
                    <Button
                      variant="contained"
                      sx={CreateSchedule}
                      onClick={() => handleView(1)}
                    >
                      MANUALLY ADD MEMBER
                    </Button>
                  )}
              </Grid>
            </Grid>
          )}
          <Divider />
        </Box>
      )}
    </Box>
  );
};

type RowProps = {
  checkEligibility: CheckEligibility | null;
  eligibilityRecord?: EligibilityRecord | undefined;
  handleView: (isManual: number) => void;
};

const Row = (props: RowProps) => {
  const { checkEligibility, eligibilityRecord, handleView } = props;

  return (
    <>
      <Box sx={patientDetailStyle}>
        {checkEligibility && (
          <PatientDetails
            checkEligibility={checkEligibility}
            eligibilityRecord={eligibilityRecord}
            handleView={handleView}
          />
        )}
      </Box>
      <Box sx={insuranceDetailsContainer}>
        {insuranceDetails(eligibilityRecord)}
      </Box>
    </>
  );
};

interface PatientProps {
  checkEligibility: CheckEligibility | null;
  eligibilityRecord?: EligibilityRecord | undefined;
  handleView: (isManual: number) => void;
}
const PatientDetails: React.FC<PatientProps> = ({
  checkEligibility,
  eligibilityRecord,
  handleView,
}: PatientProps) => {
  const eligibilityDate =
    getValue(
      checkEligibility,
      "supplementalInformation.info[0].healthCoverage.beginDate",
      ""
    ).length > 0
      ? `${formatDate(
          getValue(
            checkEligibility,
            "supplementalInformation.info[0].healthCoverage.beginDate"
          ),
          DateFormats.MM_DD_YYYY,
          DateFormats.YYYY_MM_DD
        )} - ${formatDate(
          getValue(
            checkEligibility,
            "supplementalInformation.info[0].healthCoverage.endDate"
          ),
          DateFormats.MM_DD_YYYY,
          DateFormats.YYYY_MM_DD
        )}`
      : "";
  return (
    <Grid container columnSpacing={1} sx={flexAlignCentre}>
      <Grid item xs={2}>
        <Typography
          variant="h4"
          fontWeight={fontWeight.Weight[4]}
          color={colors.fonts[18]}
        >
          {`${getValue(
            checkEligibility,
            "record.subscriberName.first"
          )} ${getValue(checkEligibility, "record.subscriberName.last")}`}
        </Typography>
      </Grid>
      {fieldDataValue(
        0.8,
        formatDate(
          getValue(checkEligibility, "record.subscriberDOB"),
          DateFormats.MM_DD_YYYY,
          DateFormats.YYYYMMDD
        )
      )}
      {fieldDataValue(
        1.1,
        getValue(checkEligibility, "supplementalInformation.info[0].mbi")
      )}
      {fieldValue(1.6, eligibilityDate)}
      {fieldValue(
        2.1,
        getValue(checkEligibility, "record.subscriberAddress.street")
      )}
      {fieldValue(
        1.1,
        getValue(checkEligibility, "record.subscriberAddress.city")
      )}
      {fieldValue(
        0.8,
        getValue(checkEligibility, "record.subscriberAddress.state")
      )}
      <Grid item xs={2.5} sx={flexAllEnd}>
        <Permission controlId={`${PatientRecordsAccess.CREATE_RECORD}||`}>
          {getEligibilityStatus(eligibilityRecord) ? (
            <Button
              variant="contained"
              sx={CreateSchedule}
              onClick={() => handleView(0)}
            >
              CREATE RECORD
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={CreateSchedule}
              onClick={() => handleView(1)}
            >
              MANUALLY ADD MEMBER
            </Button>
          )}
        </Permission>
      </Grid>
    </Grid>
  );
};

const fieldValue = (xsValue: number, data: any) => {
  return (
    <Grid item xs={xsValue}>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[1]}
      >
        {data}
      </Typography>
    </Grid>
  );
};

const fieldDataValue = (xsValue: number, data: any) => {
  return (
    <Grid item xs={xsValue}>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[4]}
        color={colors.fonts[1]}
      >
        {data}
      </Typography>
    </Grid>
  );
};

const getEligibilityStatus = (
  eligibilityRecord: EligibilityRecord | undefined
) => {
  const eligibilityStatus = getValue(
    eligibilityRecord,
    "eligibilityStatus",
    ""
  );
  return !isEmpty(eligibilityStatus)
    ? eligibilityStatus.trim() === "True"
    : false;
};

const insuranceDetails = (eligibilityData: EligibilityRecord | undefined) => {
  return (
    <Grid container sx={InsuranceGrid}>
      <TableContainer sx={overFlowHidden}>
        <Table aria-label="simple table" sx={InsuranceTable}>
          <TableHead>
            <TableRow>
              {EligibilityRecordLabel.map((tablecell, index) => (
                <TableCell key={index}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                    >
                      {tablecell.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {eligibilityData && (
            <TableBody>
              <TableRow>
                {insuranceField(eligibilityData.healthPlan)}
                {insuranceField(eligibilityData.subscriberId)}
                {insuranceField(eligibilityData.product)}
                {insuranceField(eligibilityData.benefitPlanName)}
                {insuranceField(eligibilityData.healthPlanState)}
                {insuranceField(eligibilityData.mbi)}
                {insuranceField("")}
                <TableCell>
                  <Box
                    sx={flexAlignCentre}
                    color={
                      getEligibilityStatus(eligibilityData)
                        ? colors.fonts[22]
                        : colors.red[100]
                    }
                  >
                    {getEligibilityStatus(eligibilityData) ? (
                      <FaCheck size={16} />
                    ) : (
                      <FaTimes size={16} />
                    )}
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[4]}
                      sx={StatusValue}
                    >
                      {getEligibilityStatus(eligibilityData) ? (
                        <>Eligible</>
                      ) : (
                        <>Not Eligible</>
                      )}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );
};

const insuranceField = (value: string) => {
  return (
    <TableCell>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[4]}
        color={colors.fonts[4]}
      >
        {value}
      </Typography>
    </TableCell>
  );
};

const header = () => {
  return (
    <Grid container columnSpacing={1}>
      <>
        {AllPatientRecordsLabel.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {headerField(item.xs, item.label)}
          </React.Fragment>
        ))}
      </>
    </Grid>
  );
};

const headerField = (gridSize: number, value: string) => {
  return (
    <Grid item xs={gridSize} key={value}>
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[3]}
        color={colors.black[2]}
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default ValidateRecord;

import { colors, others } from "../../../../../styles/colors";

export const GridContainer = () => ({
  paddingTop: "0.8rem",
  paddingBottom: "1rem",
});

export const HeaderText = () => ({
  paddingTop: "2rem",
  marginLeft: "1rem",
});

export const BoxText = () => ({
  paddingTop: "0.5rem",
});

export const InfoText = () => ({
  paddingTop: "0.25rem",
});

export const UnderlineText = () => ({
  marginTop: "0.7rem",
});

export const InfoContainer = () => ({
  paddingLeft: "2.5rem",
  margin: "1.2rem",
});

export const Griddivbackground = () => ({
  margin: "0.4rem",
  padding: "0.3rem",
  backgroundColor: colors.backgroundColor[2],
  borderRadius: "0.625rem",
  paddingBottom: "2rem",
});

export const FlexBox = () => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "2rem",
});

export const Data = () => ({
  marginTop: "0.7rem",
  lineHeight: "1rem",
});

export const Item = () => ({
  textAlign: "left",
  paddingTop: "1.8rem",
  paddingBottom: "1.8rem",
});

export const ItemGrid = () => ({
  backgroundColor: colors.backgroundColor[9],
  borderRadius: "0.8rem",
  paddingLeft: "2.2rem",
  marginTop: "1.5rem",
  width: "78%",
});

export const ActiveStatus = () => ({
  display: "flex",
  flexDirection: "row",
  color: colors.fonts[22],
  marginTop: "0.7rem",
});

export const InactiveStatus = () => ({
  display: "flex",
  flexDirection: "row",
  color: colors.red[100],
  marginTop: "0.7rem",
});

export const InfoBox = () => ({
  textAlign: "left",
  marginLeft: "3rem",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "1.5rem",
  paddingTop: "0.5rem",
});

export const infoReceivedBox = () => ({
  textAlign: "left",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "2.5rem",
  paddingTop: "1.5rem",
});

export const baseContainer = () => ({
  background: others.otherColors[33],
  padding: "0 .975rem .5rem",
  borderRadius: ".487rem",
  width: "100%",
});

export const InfoIcon = () => ({
  fontSize: "1.2rem",
  paddingRight: "1rem",
  color: colors.primary.main,
});

export const CreateReferralButton = () => ({
  paddingLeft: "4rem",
  paddingTop: "1.5rem",
});

export const StatusValue = () => ({
  textDecoration: "underline",
  marginLeft: "0.2rem",
});

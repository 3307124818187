import { Reducer } from "redux";

import { DocumentReviewResponse } from "../models/Api/Document";
import { DocumentReviewActionDispatchTypes } from "../constants/Document";
import { DocumentReviewDispatchTypes } from "../models/DispatchTypes/Document";

export const initialPostState: DocumentReviewResponse = {
  loading: false,
  response: {
    id: 0,
    containsSupportedDoc: null,
    documentationDate: "",
    documentId: [],
    userCreated: null,
    type: 0,
    addedDocuments: [],
  },
};

const DocumentReviewReducer: Reducer<
  DocumentReviewResponse,
  DocumentReviewDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }

    case DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET: {
      return initialPostState;
    }
  }
  return state;
};

export default DocumentReviewReducer;

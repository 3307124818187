import { isEmpty } from "lodash";

import { isNil } from "../utils";
import { Values } from "../models/Filter";
import { staffingListFilterBy } from "./Staffing";

export enum PatientFilterByActionDispatchTypes {
  PATIENT_FILTER_BY_FAIL = "PATIENT_FILTER_BY_FAIL",
  PATIENT_FILTER_BY_INVOKE = "PATIENT_FILTER_BY_INVOKE",
  PATIENT_FILTER_BY_SUCCESS = "PATIENT_FILTER_BY_SUCCESS",
}

export const enum FilterOptions {
  ASSIGNED_TO = "ASSIGNEDTO",
  CREATED_BY = "CREATEDBY",
  DISCIPLINES = "Disciplines",
  GET_PATIENT_COUNTY = "GetPatientCounty",
  GET_PATIENT_STATES = "GetPatientStates",
  HEALTH_PLAN = "HEALTHPLAN",
  REFERRAL_STATUS = "ReferralServiceStatus",
  SERVICE_STATUS_REASON = "ServiceStatusReason",
  SERVICE_PROVIDER = "ServiceProvider",
  FILTER_SERVICE_PROVIDER = "Service Provider",
  SERVICE_REQUEST_TYPE = "ServiceType",
  URGENCY = "Urgency",
  STAFFING_STATUS = "StaffingStatus",
  STAGE = "Stage",
  REFERRAL_SOURCE = "ReferralSource",
}
export enum urlBuildEnum {
  HEALTHPLAN = "healthPlanName",
  Urgency = "urgencyStatus",
  Disciplines = "skill",
  GetPatientCounty = "county",
  GetPatientStates = "state",
  ServiceProvider = "providerNpi",
  ASSIGNEDTO = "assignUser",
  ALL_PENDING_ASSIGNED_TO = "assignedTo",
  ReferralServiceStatus = "serviceStatus",
  ReferralServiceStatusReason = "serviceStatusReason",
  MyPatientStatusReason = "serviceStatusReason",
  CREATEDBY = "userCreated",
  ServiceType = "serviceRequestType",
  StaffingStatus = "staffingStatus",
  ServiceStatusReason = "serviceStatusReason",
  Stage = "stage",
  ReferralSource = "facilityTypeName",
}

/** new filter enum */
export enum UrlBuildFilterByEnum {
  HEALTH_PLAN = "healthPlanName",
  HEALTH_PLAN_STAFFING = "healthPlanName",
  URGENCY = "urgencyStatus",
  DISCIPLINE = "skill",
  GET_PATIENT_STATE = "state",
  SERVICE_PROVIDER = "providerNpi",
  ASSIGNED_TO_USER_ID = "assignUser",
  ALL_PENDING_ASSIGNED_TO = "assignUser",
  REFERRAL_SERVICE_STATUS = "serviceStatus",
  CREATED_BY = "userCreated",
  SERVICE_REQUEST_TYPE = "serviceRequestType",
  STAFFING_STATUS = "staffingStatus",
  SERVICE_STATUS_REASON = "serviceStatusReason",
  COUNTY = "county",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  STAGE = "stage",
  REFERRAL_SOURCE = "facilityTypeName",
}
export const convertedJSON: object = {};

export const enum Labels {
  ASSIGNED_TO = "Assigned To",
  CHOOSE = "Choose",
  CREATED_BY = "CREATEDBY",
  CREATED_BY_SMALL = "Created By",
  SERVICE_PROVIDER = "SERVICE PROVIDER",
  SKILL = "Skill",
  COUNTY = "County",
  STATE = "State",
  HEALTH_PLAN = "Health Plan",
  STAFFING_STATUS = "Staffing Status",
  STATUS = "Status",
  STATUS_REASON = "Status Reason",
  ALL_SERVICE_STATUS_REASON = "Status Reason",
  SERVICE_REQUEST_TYPE = "Service Request Type",
  AUTH_TYPE = "Auth Type",
  URGENCY = "Urgency",
  SERVICE_STATUS_REASON = "Service Status Reason",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  STAGE = "Stage",
  REFERRAL_SOURCE = "Referral Source",
}

export const myPatientListFilterBy: Values[] = [
  {
    value: "HEALTHPLAN",
    label: "Health Plan",
    paramLabel: "healthPlanName",
  },
  {
    value: "GetPatientStates",
    label: "State",
    paramLabel: "state",
  },
  {
    value: "Urgency",
    label: "Urgency",
    paramLabel: "urgencyStatus",
  },
  {
    value: "ServiceProvider",
    label: "Service Provider",
    paramLabel: "providerNpi",
  },
  {
    value: "ReferralServiceStatus",
    label: "Status",
    paramLabel: "serviceStatus",
  },
  {
    value: "ServiceStatusReason",
    label: "Service Status Reason",
    paramLabel: "serviceStatusReason",
  },
  {
    value: "Disciplines",
    label: "Skill",
    paramLabel: "skill",
  },
];
const filterTagMap = new Map();
filterTagMap.set(FilterOptions.GET_PATIENT_COUNTY, "county");
filterTagMap.set(FilterOptions.GET_PATIENT_STATES, "States");
filterTagMap.set(FilterOptions.REFERRAL_STATUS, "Status");
filterTagMap.set(FilterOptions.SERVICE_REQUEST_TYPE, "Service Request Type");
filterTagMap.set(FilterOptions.HEALTH_PLAN, "Health Plan");
filterTagMap.set(FilterOptions.ASSIGNED_TO, Labels.ASSIGNED_TO);
filterTagMap.set(FilterOptions.CREATED_BY, Labels.CREATED_BY_SMALL);
filterTagMap.set(FilterOptions.DISCIPLINES, Labels.SKILL);
filterTagMap.set(FilterOptions.SERVICE_PROVIDER, Labels.SERVICE_PROVIDER);
filterTagMap.set(FilterOptions.STAFFING_STATUS, Labels.STAFFING_STATUS);

export const getFilterTag = (value: string) => {
  const result = filterTagMap.get(value);
  if (!isNil(result)) {
    return filterTagMap.get(value);
  } else return value;
};
export const patientListFilterBy: Values[] = [
  {
    value: "HEALTHPLAN",
    label: "Health Plan",
    paramLabel: "healthPlanName",
  },
  {
    value: "Disciplines",
    label: "Skill",
    paramLabel: "skill",
  },
  {
    value: "GetPatientStates",
    label: "State",
    paramLabel: "state",
  },
  {
    value: "Urgency",
    label: "Urgency",
    paramLabel: "urgencyStatus",
  },
  {
    value: "ASSIGNEDTO",
    label: "Assigned To",
    paramLabel: "assignUser",
  },
  {
    value: "ServiceProvider",
    label: "Service Provider",
    paramLabel: "providerNpi",
  },
  {
    value: "ServiceType",
    label: "Service Request Type",
    paramLabel: "serviceRequestType",
  },
  {
    value: "ReferralServiceStatus",
    label: "Status",
    paramLabel: "serviceStatus",
  },
  {
    value: "ServiceStatusReason",
    label: "Service Status Reason",
    paramLabel: "serviceStatusReason",
  },
];
export const patientListFilterBy_2: Values[] = [
  {
    value: "HEALTHPLAN",
    label: "Health Plan",
    paramLabel: "healthPlanName",
  },
  {
    value: "Disciplines",
    label: "Skill",
    paramLabel: "skill",
  },
  {
    value: "GetPatientStates",
    label: "State",
    paramLabel: "state",
  },
  {
    value: "Urgency",
    label: "Urgency",
    paramLabel: "urgencyStatus",
  },
  {
    value: "ASSIGNEDTO",
    label: "Assigned To",
    paramLabel: "assignUser",
  },
  {
    value: "ServiceProvider",
    label: "Service Provider",
    paramLabel: "providerNpi",
  },
  {
    value: "ServiceType",
    label: "Service Request Type",
    paramLabel: "serviceRequestType",
  },
  {
    value: "ReferralServiceStatus",
    label: "Status",
    paramLabel: "serviceStatus",
  },
  {
    value: "ServiceStatusReason",
    label: "Service Status Reason",
    paramLabel: "serviceStatusReason",
  },
];
export const incompleteRequestFilterBy = [
  {
    value: "Urgency",
    label: "Urgency",
    paramLabel: "urgencyStatus",
  },
  {
    value: "HEALTHPLAN",
    label: "Health Plan",
    paramLabel: "healthPlanName",
  },

  {
    value: "ASSIGNEDTO",
    label: "Assigned To",
    paramLabel: "assignUser",
  },
  {
    value: "GetPatientStates",
    label: "State",
    paramLabel: "state",
  },
  {
    value: "CREATEDBY",
    label: "Created By",
    paramLabel: "userCreated",
  },
  {
    value: "ServiceType",
    label: "Auth Type",
    paramLabel: "serviceRequestType",
  },
  {
    value: "ReferralSource",
    label: "Referral Source",
    paramLabel: "facilityTypeName",
  },
];

export const extractFields = (
  item: any,
  isPatientRecords = false,
  includeOriginalObject = false
) => {
  if (isPatientRecords) {
    if (!isNil(item.healthPlanName)) {
      return {
        label: item.healthPlanName,
        value: item.healthPlanId,
      };
    }
  }
  if (!isNil(item.urgencyStatus)) {
    return {
      label: item.urgencyStatus,
      value: item.urgencyStatus,
    };
  }
  if (!isNil(item.serviceStatusReason)) {
    return {
      label: item.serviceStatusReason,
      value: item.serviceStatusReason,
    };
  }
  if (!isNil(item.serviceStatus)) {
    return {
      label: item.serviceStatus,
      value: item.serviceStatus,
    };
  }
  if (!isNil(item.serviceStatusReasonId)) {
    return {
      label: item.serviceStatusReason,
      value: item.serviceStatusReason,
    };
  }
  if (!isNil(item.serviceRequestTypeName)) {
    return {
      label: item.serviceRequestTypeName,
      value: item.serviceRequestTypeName,
    };
  }
  if (!isNil(item.disciplineName)) {
    return {
      label: item.disciplineName,
      value: item.disciplineName,
    };
  }
  if (!isNil(item.healthPlanName)) {
    const addedObject = includeOriginalObject ? item : {};
    return {
      label: item.healthPlanName,
      value: item.healthPlanName,
      ...addedObject,
    };
  }
  if (!isNil(item.payerName)) {
    return {
      label: item.payerName,
      value: btoa(`${item.payerId}|||${item.payerName}`),
    };
  }
  if (!isNil(item.staffingStatusName)) {
    return {
      label: item.staffingStatusName,
      value: item.staffingStatusName,
    };
  }
  if (!isNil(item.benefitPlanNm)) {
    const addedObject = includeOriginalObject ? item : {};
    return {
      label: item.benefitPlanNm,
      value: item.benefitPlanNm,
      ...addedObject,
    };
  }
  if (!isNil(item.facilityTypeName)) {
    return {
      label: item.facilityTypeName,
      value: item.facilityTypeId,
    };
  }
  if (!isNil(item.stageStatus)) {
    return {
      label: item.stageStatus,
      value: item.stageStatus,
    };
  }
  if (!isNil(item.referralNtuReason)) {
    return {
      label: item.referralNtuReason,
      value: item.referralNtuReasonId,
    };
  }
  return item;
};

export const dropdownFilter = (list: any, isPatientRecords = false) => {
  if (!isEmpty(list)) {
    const firstValue = list[0];
    if (firstValue.value != "") {
      if (
        !isNil(
          firstValue.urgencyId ||
            firstValue.healthPlanId ||
            firstValue.serviceStatus ||
            firstValue.disciplineName ||
            firstValue.serviceRequestTypeName ||
            firstValue.payerId ||
            firstValue.staffingStatusId ||
            firstValue.serviceStatusReasonId ||
            firstValue.stageStatus ||
            firstValue.facilityTypeId
        )
      ) {
        const result = list.map((v: any) => extractFields(v, isPatientRecords));
        return result;
      }
    }
  }
  return [];
};

export const HealthPlanData = {
  label: "",
  value: "",
};

export const UserData = {
  roles: "",
  userPhoto: "",
  email: "",
  firstName: "",
  fullName: "",
  lastLogin: "",
  lastName: "",
  userId: 0,
  userName: "",
  userStatus: "",
};

export const HealthPlanInitialData = {
  label: "",
  value: "",
  healthPlanId: 0,
  healthPlanName: "",
};

export const BenefitPlanInitialData = {
  label: "",
  value: "",
  benefitPlanId: 0,
  benefitPlanName: "",
  healthPlanId: 0,
  product: "",
  benefitPlanUid: "",
  healthPlanUid: "",
  healthPlanState: "",
  benefitPlanNm: "",
};

export const getPrefixChipName = (name: string, activeTab: number) => {
  switch (name) {
    case UrlBuildFilterByEnum.SERVICE_PROVIDER:
      return Labels.SERVICE_PROVIDER;
    case UrlBuildFilterByEnum.ASSIGNED_TO_USER_ID:
    case UrlBuildFilterByEnum.ALL_PENDING_ASSIGNED_TO:
      return Labels.ASSIGNED_TO;
    case UrlBuildFilterByEnum.HEALTH_PLAN:
    case UrlBuildFilterByEnum.HEALTH_PLAN_STAFFING:
      return Labels.HEALTH_PLAN;
    case UrlBuildFilterByEnum.URGENCY:
      return Labels.URGENCY;
    case UrlBuildFilterByEnum.CREATED_BY:
      return Labels.CREATED_BY_SMALL;
    case UrlBuildFilterByEnum.SERVICE_REQUEST_TYPE:
      return activeTab === 2 ? Labels.AUTH_TYPE : Labels.SERVICE_REQUEST_TYPE;
    case UrlBuildFilterByEnum.DISCIPLINE:
      return Labels.SKILL;
    case UrlBuildFilterByEnum.GET_PATIENT_STATE:
      return Labels.STATE;
    case UrlBuildFilterByEnum.REFERRAL_SERVICE_STATUS:
      return Labels.STATUS;
    case UrlBuildFilterByEnum.SERVICE_STATUS_REASON:
      return Labels.SERVICE_STATUS_REASON;
    case UrlBuildFilterByEnum.COUNTY:
      return Labels.COUNTY;
    case UrlBuildFilterByEnum.STAFFING_STATUS:
      return Labels.STAFFING_STATUS;
    case UrlBuildFilterByEnum.FIRST_NAME:
      return Labels.FIRST_NAME;
    case UrlBuildFilterByEnum.LAST_NAME:
      return Labels.LAST_NAME;
    case UrlBuildFilterByEnum.STAGE:
      return Labels.STAGE;
    case UrlBuildFilterByEnum.REFERRAL_SOURCE:
      return Labels.REFERRAL_SOURCE;
    default:
      return "";
  }
};

export const getFilterDropDownList = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return myPatientListFilterBy;
      break;
    case 1:
      return patientListFilterBy_2;
      break;
    case 2:
      return incompleteRequestFilterBy;
      break;
    case 3:
      return staffingListFilterBy;
      break;
    case 4:
      return patientListFilterBy_2;
      break;
    default:
      break;
  }
};

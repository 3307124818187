import { FaRegCalendarCheck, FaRegFileAlt } from "react-icons/fa";
import { useEffect, useState } from "react";

import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";

import { ReviewDocumentationDetailQuestions } from "../../../../../../../constants/Review";
import colors from "../../../../../../../styles/colors/colors";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  DividerStyle,
  DocumentationDetailsDocumentsHeading,
  DocumentationDetailsDocumentsPdfIcon,
  DocumentationDetailsHeadingGrid,
  DocumentationDetailsLeftGrid,
  DocumentationDetailsLeftGrid1stColumn,
  DocumentationDetailsLeftGrid2ndColumn,
  DocumentationDetailsLeftGrid2ndColumnHeading,
  DocumentationDetailsLeftGrid3rdColumn,
  DocumentationDetailsLeftGrid3rdColumnDate,
  DocumentationDetailsMainGrid,
  DocumentationDetailsQuestion,
  DocumentationDetailsRightGrid,
  DocumentationDetailsRightGridTableBody,
  DocumentationDetailsRightGridTableCellDocumentName,
  DocumentationDetailsRightGridTableCellMargin,
  DocumentationDetailsRightGridTableHead,
} from "../styles/DocumentationDetailStyles";
import { DateFormats } from "../../../../../../../constants/Date";
import { getValue, length, extractDocType } from "../../../../../../../utils";
import { formatDate } from "../../../../../../../utils/DateTime";
import { DocumentDownloadResponse } from "../../../../../../../models/Api/Document";
import { IconStyle } from "../styles/styles";
import { MessageType } from "../../../../../../../constants/ToastMessage";
import { ReferralResponseMessages } from "../../../../../../../constants/AllPatientRecord";
import { Review } from "../../../../../../../models/Patient";
import { ReviewResponse } from "../../../../../../../models/Api/Review";
import SnackbarUtils from "../../../../../../../utils/SnackbarProvider";
import { getDocTypeIcon } from "../../../../../../../utils/DocIcons";
import {
  DocumentName,
  DocumentNameUnderline,
} from "../../../styles/DocumentReview";
import { Data } from "../../../../../../AdminCenter/routes/NoteManagement/styles/NotesList";

export interface PropsFromState {
  documentationDetail: ReviewResponse;
  documentDownloadData: DocumentDownloadResponse;
}

export interface PropsFromDispatch {
  documentDownload: (blobId: any) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const tableHeadTitle = (title: string) => {
  return (
    <TableCell>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[2]}
        color={colors.black[14]}
        ml={"1rem"}
      >
        {title}
      </Typography>
    </TableCell>
  );
};

const ReviewDocumentationDetail: React.FC<AllProps> = ({
  documentationDetail,
  documentDownload,
  documentDownloadData,
}: AllProps) => {
  const [documentationDetails, setDocumentationDetails] = useState<Review>();
  const [fileName, setFileName] = useState<string>("");
  const { response: documentDownloadResponse, error: documentDownloadError } =
    documentDownloadData;
  const { response } = documentationDetail;
  const referralDocument =
    documentationDetails && documentationDetails.referralDocument;

  const downloadFile = (blobId: number, fileName: string) => {
    documentDownload(blobId);
    setFileName(fileName);
  };

  useEffect(() => {
    if (documentDownloadResponse && documentDownloadResponse.blob) {
      const blob = new Blob([documentDownloadResponse.blob], {
        type: documentDownloadResponse.headers["content-type"],
      });
      FileSaver.saveAs(blob, fileName);
      setFileName("");
    } else if (
      documentDownloadError &&
      documentDownloadError.error &&
      Object.keys(documentDownloadError.error).length == 0
    ) {
      SnackbarUtils.error(
        ReferralResponseMessages(MessageType.DOCUMENT_DOWNLOAD_ERROR)
      );
    }
  }, [documentDownloadResponse, documentDownloadError]);

  useEffect(() => {
    if (response) {
      setDocumentationDetails(response);
    }
  }, [response]);
  return (
    <Grid container sx={DocumentationDetailsMainGrid}>
      <Grid item xs={12} sx={DocumentationDetailsHeadingGrid}>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[20]}
        >
          DOCUMENTATION DETAILS:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={DividerStyle} light />
      </Grid>
      <Grid item xs={3.3} sx={DocumentationDetailsLeftGrid}>
        <Grid item xs={12} sx={DocumentationDetailsLeftGrid1stColumn}>
          <Box>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[4]}
            >
              {ReviewDocumentationDetailQuestions.SUPPORTING_DOCUMENTS}
            </Typography>
          </Box>
          <Box sx={DocumentationDetailsQuestion}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[6]}
              color={colors.fonts[20]}
            >
              {documentationDetails &&
              documentationDetails.referralDocument.containsSupportedDoc ===
                true
                ? "YES"
                : "NO"}
            </Typography>
          </Box>
        </Grid>
        {documentationDetails &&
          documentationDetails.referralDocument.containsSupportedDoc ===
            true && (
            <>
              <Grid container sx={DocumentationDetailsLeftGrid2ndColumnHeading}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.fonts[20]}
                    sx={DocumentationDetailsDocumentsHeading}
                  >
                    DOCUMENTS:
                  </Typography>
                </Grid>
                {referralDocument &&
                  referralDocument.documentName &&
                  length(referralDocument.documentName) &&
                  documentationDetails.referralDocument.documentName.map(
                    (data, index) => (
                      <Grid item xs={6} key={index} pr={"0.5rem"}>
                        <Box sx={DocumentationDetailsLeftGrid2ndColumn}>
                          <Box pr={"0.5rem"}>
                            <FaRegFileAlt size={19} style={IconStyle} />
                          </Box>
                          <Typography
                            variant="subtitle2"
                            color={colors.fonts[4]}
                            fontWeight={fontWeight.Weight[5]}
                          >
                            {data}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
              <Grid item xs={12} sx={DocumentationDetailsLeftGrid3rdColumn}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[4]}
                  >
                    Date of Documentation:
                  </Typography>
                </Box>
                <Box sx={DocumentationDetailsLeftGrid3rdColumnDate}>
                  <Box sx={DocumentationDetailsDocumentsPdfIcon}>
                    {getValue(
                      documentationDetails,
                      "referralDocument.documentationDate"
                    ) && <FaRegCalendarCheck size={18} style={IconStyle} />}
                  </Box>
                  <Typography
                    variant="subtitle2"
                    color={colors.fonts[25]}
                    fontWeight={fontWeight.Weight[5]}
                  >
                    {formatDate(
                      documentationDetails &&
                        documentationDetails.referralDocument.documentationDate
                        ? documentationDetails.referralDocument
                            .documentationDate
                        : ""
                    )}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
      </Grid>
      <Grid item xs={8.7} sx={DocumentationDetailsRightGrid}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead sx={DocumentationDetailsRightGridTableHead}>
              <TableRow>
                {tableHeadTitle("DOCUMENT NAME")}
                {tableHeadTitle(" DATE / TIME")}
                {tableHeadTitle(" SOURCE")}
                {tableHeadTitle(" DOC TYPE")}
                {tableHeadTitle(" CREATED BY")}
              </TableRow>
            </TableHead>
            <TableBody>
              {documentationDetails &&
                documentationDetails.blobDocument &&
                length(documentationDetails.blobDocument) &&
                documentationDetails.blobDocument.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={DocumentationDetailsRightGridTableBody}
                  >
                    <TableCell>
                      <Box
                        sx={DocumentationDetailsRightGridTableCellDocumentName}
                      >
                        <Box sx={DocumentationDetailsDocumentsPdfIcon}>
                          {getDocTypeIcon(extractDocType(data.mediaType))}
                        </Box>
                        <Typography
                          variant="h6"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.primary.main}
                        >
                          <Link
                            variant="h6"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.primary.main}
                            onClick={() =>
                              downloadFile(
                                data.blobId,
                                data.originalDocumentName
                              )
                            }
                            sx={DocumentNameUnderline}
                          >
                            <Tooltip
                              title={
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={fontWeight.Weight[3]}
                                  sx={Data}
                                >
                                  {data.originalDocumentName}
                                </Typography>
                              }
                              arrow
                            >
                              <Typography
                                variant="subtitle1"
                                fontWeight={fontWeight.Weight[5]}
                                color={colors.fonts[18]}
                                sx={DocumentName}
                              >
                                {data.originalDocumentName}
                              </Typography>
                            </Tooltip>
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[4]}
                        sx={DocumentationDetailsRightGridTableCellMargin}
                      >
                        {data.showDateTime
                          ? formatDate(
                              data.dateTimeReceived,
                              DateFormats.MM_DD_YYYY_hh_mm_ss_a
                            )
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.fonts[4]}
                        sx={DocumentationDetailsRightGridTableCellMargin}
                      >
                        {data.documentSourceName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.fonts[4]}
                        sx={DocumentationDetailsRightGridTableCellMargin}
                      >
                        {data.blobContentType}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.fonts[4]}
                        sx={DocumentationDetailsRightGridTableCellMargin}
                      >
                        {data.userFullName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ReviewDocumentationDetail;

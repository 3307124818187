export const HeaderLabel = [
  { key: "categoryName", label: "Category", width: "17%" },
  { key: "noteTemplateId", label: "Template ID", width: "15%" },
  { key: "templateName", label: "Name", width: "43%" },
  { key: "isActive", label: "Status", width: "10%" },
  { key: "", label: "Action", width: "15%" },
];

export enum NoteTemplateListActionDispatchTypes {
  POST_NOTE_TEMPLATE_LIST_INVOKE = "POST_NOTE_TEMPLATE_LIST_INVOKE",
  POST_NOTE_TEMPLATE_LIST_SUCCESS = "POST_NOTE_TEMPLATE_LIST_SUCCESS",
  POST_NOTE_TEMPLATE_LIST_RESET = "POST_NOTE_TEMPLATE_LIST_RESET",
  POST_NOTE_TEMPLATE_LIST_ERROR = "POST_NOTE_TEMPLATE_LIST_ERROR",
}

export enum DeactivateNoteActionDispatchTypes {
  DEACTIVATE_NOTE_INVOKE = "DEACTIVATE_NOTE_INVOKE",
  DEACTIVATE_NOTE_SUCCESS = "DEACTIVATE_NOTE_SUCCESS",
  DEACTIVATE_NOTE_ERROR = "DEACTIVATE_NOTE_ERROR",
  DEACTIVATE_NOTE_RESET = "DEACTIVATE_NOTE_RESET",
}

export enum AdminNotesTemplateDeleteActionDispatchTypes {
  ADMIN_NOTES_TEMPLATE_DELETE_ERROR = "ADMIN_NOTES_TEMPLATE_DELETE_ERROR",
  ADMIN_NOTES_TEMPLATE_DELETE_INVOKE = "ADMIN_NOTES_TEMPLATE_DELETE_INVOKE",
  ADMIN_NOTES_TEMPLATE_DELETE_SUCCESS = "ADMIN_NOTES_TEMPLATE_DELETE_SUCCESS",
  ADMIN_NOTES_TEMPLATE_DELETE_RESET = "ADMIN_NOTES_TEMPLATE_DELETE_RESET",
}

export enum CategoryActionDispatchTypes {
  MASTER_CATEGORY_INVOKE = "MASTER_CATEGORY_INVOKE",
  MASTER_CATEGORY_SUCCESS = "MASTER_CATEGORY_SUCCESS",
}

export enum SaveAdminNotesActionDispatchTypes {
  SAVE_NOTES_INVOKE = "SAVE_NOTES_INVOKE",
  SAVE_NOTES_SUCCESS = "SAVE_NOTES_SUCCESS",
  SAVE_NOTES_ERROR = "SAVE_NOTES_ERROR",
  SAVE_NOTES_RESET = "SAVE_NOTES_RESET",
}

export const ACTIVE = true;
export const INACTIVE = false;

export enum UpdateAdminNotesActionDispatchTypes {
  UPDATE_NOTES_INVOKE = "UPDATE_NOTES_INVOKE",
  UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS",
  UPDATE_NOTES_ERROR = "UPDATE_NOTES_ERROR",
  UPDATE_NOTES_RESET = "UPDATE_NOTES_RESET",
}

export enum GetAdminNotesByVersionIdActionDispatchTypes {
  GET_NOTES_INVOKE = "GET_NOTES_INVOKE",
  GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS",
  GET_NOTES_ERROR = "GET_NOTES_ERROR",
  GET_NOTES_RESET = "GET_NOTES_RESET",
}

export enum DisciplineNoteActionDispatchTypes {
  GET_DISCIPLINE_NOTES_INVOKE = "GET_DISCIPLINE_NOTES_INVOKE",
  GET_DISCIPLINE_NOTES_RESET = "GET_DISCIPLINE_NOTES_RESET",
  GET_DISCIPLINE_NOTES_SUCCESS = "GET_DISCIPLINE_NOTES_SUCCESS",
}

export const formInitialState = {
  templateVersionId: null,
  noteTemplateId: null,
  templateJson: "",
  approvalStatusId: null,
  templateCategoryId: null,
  templateCategoryName: "",
  templateName: "",
  dateCreated: "",
};

export enum SurveyDetailsUpdateActionDispatchType {
  SURVEY_REQUEST_POST_ERROR = "SURVEY_REQUEST_POST_ERROR",
  SURVEY_REQUEST_POST_INVOKE = "SURVEY_REQUEST_POST_INVOKE",
  SURVEY_REQUEST_POST_SUCCESS = "SURVEY_REQUEST_POST_SUCCESS",
  SURVEY_REQUEST_POST_RESET = "SURVEY_REQUEST_POST_RESET",
}

export enum NotesReviewData {
  NOTES_MODE = "edit",
  NOTES_TITLE = "Cancel",
  DISCIPLINE_NOTES = "discipline",
  REFERRAL_NOTES = "referral",
  NOTES_UPDATE_ERROR = "Failed to update Notes !",
  NOTE_REFERRAL_LABEL = "Referral Note",
  NOTE_DISCIPLINE_LABEL = "Discipline Note",
  SERVICE_PROVIDER_NOTE = "Service Provider Details",
  INRULE_REQUEST_NOTE = "Request Note",
  PORTAL = "Portal",
}

export enum MasterDisciplineTemplateNotesActionDispatchTypes {
  DISCIPLINE_NOTES_INVOKE = "DISCIPLINE_NOTES_INVOKE",
  DISCIPLINE_NOTES_SUCCESS = "DISCIPLINE_NOTES_SUCCESS",
}

export enum GetNotesTemplateActionDispatchTypes {
  NOTES_TEMPLATE_JSON_INVOKE = "NOTES_TEMPLATE_JSON_INVOKE",
  NOTES_TEMPLATE_JSON_SUCCESS = "NOTES_TEMPLATE_JSON_SUCCESS",
  NOTES_TEMPLATE_JSON_ERROR = "NOTES_TEMPLATE_JSON_ERROR",
  NOTES_TEMPLATE_JSON_RESET = "NOTES_TEMPLATE_JSON_RESET",
}

export enum GetDisciplineLevelNotesActionDispatchTypes {
  GET_DISCIPLINE_LEVEL_NOTES_INVOKE = "GET_DISCIPLINE_LEVEL_NOTES_INVOKE",
  GET_DISCIPLINE_LEVEL_NOTES_SUCCESS = "GET_DISCIPLINE_LEVEL_NOTES_SUCCESS",
  GET_DISCIPLINE_LEVEL_NOTES_RESET = "GET_DISCIPLINE_LEVEL_NOTES_RESET",
  GET_DISCIPLINE_LEVEL_NOTES_ERROR = "GET_DISCIPLINE_LEVEL_NOTES_ERROR",
}

export enum SaveDisciplineLevelNotesActionDispatchTypes {
  SAVE_DISCIPLINE_NOTES_INVOKE = "SAVE_DISCIPLINE_NOTES_INVOKE",
  SAVE_DISCIPLINE_NOTES_SUCCESS = "SAVE_DISCIPLINE_NOTES_SUCCESS",
  SAVE_DISCIPLINE_NOTES_RESET = "SAVE_DISCIPLINE_NOTES_RESET",
  SAVE_DISCIPLINE_NOTES_ERROR = "SAVE_DISCIPLINE_NOTES_ERROR",
}

export enum UpdateDisciplineLevelNotesActionDispatchTypes {
  UPDATE_DISCIPLINE_NOTES_INVOKE = "UPDATE_DISCIPLINE_NOTES_INVOKE",
  UPDATE_DISCIPLINE_NOTES_SUCCESS = "UPDATE_DISCIPLINE_NOTES_SUCCESS",
  UPDATE_DISCIPLINE_NOTES_RESET = "UPDATE_DISCIPLINE_NOTES_RESET",
  UPDATE_DISCIPLINE_NOTES_ERROR = "UPDATE_DISCIPLINE_NOTES_ERROR",
}

export enum ReferralNoteActionDispatchTypes {
  GET_REFERRAL_NOTES_INVOKE = "GET_REFERRAL_NOTES_INVOKE",
  GET_REFERRAL_NOTES_RESET = "GET_REFERRAL_NOTES_RESET",
  GET_REFERRAL_NOTES_SUCCESS = "GET_REFERRAL_NOTES_SUCCESS",
}

export const DisciplineNoteValues = [
  { headerLabel: "Note Date/Time", width: "20%", key: 1, name: "dateCreated" },
  { headerLabel: "Note Type", width: "20%", key: 2, name: "templateName" },
  { headerLabel: "Comment", width: "30%", key: 3, name: "comment" },
  { headerLabel: "Created By", width: "20%", key: 4, name: "fullNameCreated" },
  { headerLabel: "", width: "10%", key: 5, name: "action" },
];

export const ReferralNoteValues = [
  { headerLabel: "Note Date/Time", width: "17%", key: 1, name: "dateCreated" },
  { headerLabel: "Note Level", width: "9%", key: 2, name: "noteLevel" },
  { headerLabel: "Note Type", width: "13%", key: 3, name: "templateName" },
  { headerLabel: "Auth/SN", width: "7%", key: 4, name: "authorizationNumber" },
  { headerLabel: "Discipline", width: "5%", key: 5, name: "disciplineName" },
  { headerLabel: "Note", width: "29%", key: 6, name: "note" },
  { headerLabel: "Created By", width: "15%", key: 7, name: "fullNameCreated" },
  { headerLabel: "", width: "5%", key: 8, name: "action" },
];

export enum GetReferralLevelNotesActionDispatchTypes {
  GET_REFERRAL_LEVEL_NOTES_INVOKE = "GET_REFERRAL_LEVEL_NOTES_INVOKE",
  GET_REFERRAL_LEVEL_NOTES_SUCCESS = "GET_REFERRAL_LEVEL_NOTES_SUCCESS",
  GET_REFERRAL_LEVEL_NOTES_RESET = "GET_REFERRAL_LEVEL_NOTES_RESET",
  GET_REFERRAL_LEVEL_NOTES_ERROR = "GET_REFERRAL_LEVEL_NOTES_ERROR",
}

export enum SaveReferralLevelNotesActionDispatchTypes {
  SAVE_REFERRAL_NOTES_INVOKE = "SAVE_REFERRAL_NOTES_INVOKE",
  SAVE_REFERRAL_NOTES_SUCCESS = "SAVE_REFERRAL_NOTES_SUCCESS",
  SAVE_REFERRAL_NOTES_RESET = "SAVE_REFERRAL_NOTES_RESET",
  SAVE_REFERRAL_NOTES_ERROR = "SAVE_REFERRAL_NOTES_ERROR",
}

export enum UpdateReferralLevelNotesActionDispatchTypes {
  UPDATE_REFERRAL_NOTES_INVOKE = "UPDATE_REFERRAL_NOTES_INVOKE",
  UPDATE_REFERRAL_NOTES_SUCCESS = "UPDATE_REFERRAL_NOTES_SUCCESS",
  UPDATE_REFERRAL_NOTES_RESET = "UPDATE_REFERRAL_NOTES_RESET",
  UPDATE_REFERRAL_NOTES_ERROR = "UPDATE_REFERRAL_NOTES_ERROR",
}

export enum PostAgencyCommunicationNoteActionDispatchTypes {
  AGENCY_COMMUNICATION_NOTE_INVOKE = "AGENCY_COMMUNICATION_NOTE_INVOKE",
  AGENCY_COMMUNICATION_NOTE_SUCCESS = "AGENCY_COMMUNICATION_NOTE_SUCCESS",
  AGENCY_COMMUNICATION_NOTE_RESET = "AGENCY_COMMUNICATION_NOTE_RESET",
  AGENCY_COMMUNICATION_NOTE_ERROR = "AGENCY_COMMUNICATION_NOTE_ERROR",
}
export const NotesData = {
  noteTemplateId: null,
};

export const AdminDefaultNoteList = {
  totalRows: -1,
  searchResponse: [
    {
      templateVersionId: -1,
      noteTemplateId: -1,
      approvalStatusId: -1,
      isActive: true,
      templateCategoryId: -1,
      templateName: "",
      categoryName: "",
      dateCreated: "",
      userCreated: "",
      dateUpdated: "",
      versionStatus: "",
    },
  ],
};
export const NotesCommentSectionMaxCharLength = 56;
export const AgencyCommunicationNoteTextLength = 1500;
export const LevelOfFunctionMaxCharLength = 20;

export enum ProviderNote {
  SERVICE_PROVIDER_DETAILS = "Service Provider Details",
  REQUEST_NOTE = "Request Note",
  AGENCY_COMMUNICATION = "Agency Communication",
  PORTAL = "Portal",
}

export enum NoteType {
  DISCIPLINE_NOTE = "Discipline Notes",
  REFERRAL_NOTE = "Notes",
}

export enum LevelOfFunctionActionDispatchTypes {
  LEVEL_OF_FUNCTION_INVOKE = "LEVEL_OF_FUNCTION_INVOKE",
  LEVEL_OF_FUNCTION_SUCCESS = "LEVEL_OF_FUNCTION_SUCCESS",
  LEVEL_OF_FUNCTION_RESET = "LEVEL_OF_FUNCTION_RESET",
  LEVEL_OF_FUNCTION_ERROR = "LEVEL_OF_FUNCTION_ERROR",
}

import { Grid } from "@mui/material";

import EmergencyContactData from "../../../../../../components/custom/emergencyContact/EmergencyContact";
import {
  EmergencyContact,
  PatientInformationView,
  RelationshipMaster,
} from "../../../../../../models/Patient";
import { AddressInfoContainer, PhysicianInfoSave } from "../style";
import { PatientNameGrid } from "./MemberInfo";
import { SaveButton } from ".";
import { EmergencyContactTypes } from "../../../../../../constants/Constants";

export interface AlternateContactProps {
  emergencyContactValues: EmergencyContact[];
  control: any;
  fields: any[];
  relationshipList: RelationshipMaster[];
  handleRemove: (index: any) => void;
  append: any;
  patientData: PatientInformationView;
  handleSave: any;
}

type AllProps = AlternateContactProps;

const AlternateContact: React.FC<any> = ({
  emergencyContactValues,
  control,
  fields,
  relationshipList,
  handleRemove,
  append,
  patientData,
  handleSave,
}: AllProps) => {
  return (
    <Grid container sx={AddressInfoContainer}>
      {PatientNameGrid(patientData, "patientName")}
      <Grid item xs={7.5} pb={"1rem"}>
        <EmergencyContactData
          fields={fields}
          emergencyContactValues={emergencyContactValues}
          control={control}
          relationshipList={relationshipList}
          handleRemove={handleRemove}
          append={append}
          contactType={EmergencyContactTypes.ALTERNATE_CONTACT}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={1} sx={PhysicianInfoSave}>
        {SaveButton(handleSave)}
      </Grid>
    </Grid>
  );
};

export default AlternateContact;

import { useSnackbar, VariantType, ProviderContext } from "notistack";
import React from "react";

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const toast = (msg: string, variant: VariantType = "default") => {
  if (useSnackbarRef !== undefined) {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  }
}

export default {
  success(msg: string) {
    toast(msg, "success");
  },
  warning(msg: string) {
    toast(msg, "warning");
  },
  info(msg: string) {
    toast(msg, "info");
  },
  error(msg: string) {
    toast(msg, "error");
  }
};
import { tooltipClasses } from "@mui/material";

export const NotesTooltip = () => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "18rem",
  display: "-webkit-box",
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
});

export const NotesCommentTooltip = () => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "18rem",
  display: "-webkit-inline-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
});

export const NotesTooltipStyle = {
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: "0.4rem",
      marginLeft: "-5rem",
      maxWidth: "50rem",
    },
};

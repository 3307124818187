import { Reducer } from "redux";

import { DeactivateNoteDispatchTypes } from "../models/DispatchTypes/Notes";
import { DeactivateNoteActionDispatchTypes } from "../constants/Notes";
import { DeactivateNoteResponse } from "../models/Api/Notes";

const initialState: DeactivateNoteResponse = {
  loading: false,
  response: false,
};

const DeactivateNotesTemplateReducer: Reducer<
  DeactivateNoteResponse,
  DeactivateNoteDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_INVOKE: {
      return {
        ...state,
        loading: true,
        response: false,
      };
    }
    case DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case DeactivateNoteActionDispatchTypes.DEACTIVATE_NOTE_RESET: {
      return {
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};
export default DeactivateNotesTemplateReducer;

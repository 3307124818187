import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { FaRegTrashAlt, FaSearch } from "react-icons/fa";

import { accent, colors, others } from "../../../../../styles/colors";

export const SubHeaderBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  hr: {
    margin: "0 2rem",
  },
}));

export const DividerLine = () => ({
  margin: "0 1.8rem 0 2.5rem",
  height: "2rem",
  borderColor: colors.borders[6],
});

export const DividerLineforReauth = () => ({
  margin: "0 2rem",
  height: "2rem",
  borderColor: colors.borders[6],
});

export const ReferralGrid = () => ({
  display: "block",
  marginTop: "0.5rem",
  padding: "0 0.8rem",
});

export const DetailBox: any = styled(Grid)(() => ({
  backgroundColor: colors.backgroundColor[10],
  padding: "0 1.5rem",
  left: 0,
  right: 0,
  margin: "1.5rem -1.5rem 0 -1.5rem",
  overflow: "hidden",
  paddingTop: "1.5rem",
}));

export const PatientContainer = () => ({
  padding: "1.5rem 2.5rem",
  marginTop: "1.5rem",
});

export const PatientDetailBox = () => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  padding: "1rem",
  borderRadius: "1rem",
  background: others.otherColors[33],
});

export const PatientLeftSection = () => ({
  width: "20%",
  padding: "0.5rem",
  borderRight: `1px solid ${colors.dropShadow[4]}`,
  display: "flex",
  alignItems: "center",
});

export const PatientAvatar = () => ({
  marginRight: "1.5rem",
  width: "3.75rem",
  height: "3.75rem",
});

export const PatientDetailContainer = () => ({
  display: "flex",
  justifyContent: "space-between",
  flex: 0.97,
  marginLeft: "1.5rem",
  textTransform: "capitalize",
});

export const NextStepButton = () => ({
  svg: {
    marginRight: "1rem",
  },
});

export const SaveButton = () => ({
  width: "18rem",
  svg: {
    marginRight: "1rem",
  },
});

export const TableHeadStyle = () => ({
  "& .MuiTableCell-root": {
    padding: "0.8rem 0.3rem 1.5rem 0",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
});

export const EmergencyContactTableHeadStyle = () => ({
  "& .MuiTableCell-root": {
    padding: "0.8rem 1rem 0.8rem 0",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: `0.125rem solid ${colors.borders[7]}`,
  },
});

export const TableBodyStyle = () => ({
  "& .MuiTableCell-root": {
    padding: "0rem 1rem 0.1rem 0",
  },
  "& td:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    paddingBottom: "0.4rem",
    paddingRight: "1.5rem",
  },
  "& td:nth-of-type(2)": {
    borderRadius: "0.7rem 0 0 0.7rem",
    paddingBottom: "0.3rem",
  },
  "& td:nth-of-type(3)": {
    borderRadius: "0.7rem 0 0 0.7rem",
    paddingBottom: "0.4rem",
    paddingRight: "1.5rem",
  },
  "& td:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
  },
});

export const PaddingLeft = () => ({
  PaddingLeft: "0.5rem",
});

export const DividerVertical = () => ({
  border: "1px",
});

export const SummaryItemContainer = () => ({
  padding: "2rem",
  minHeight: "80vh",
});

export const FaRegTrashAltIcon = styled(FaRegTrashAlt)(() => ({
  fontSize: "1.2rem",
  color: accent.accentOrange[100],
}));

export const horizontalDivider = () => ({
  paddingTop: "1rem",
  width: "100%",
  maxWidth: "84rem",
});

export const InputBox = () => ({
  padding: "0 .5rem 0 .5rem",
});

export const AutocompleteUI = () => ({
  paddingTop: "1rem",
  minWidth: "15.5rem",
  color: colors.fonts[4],
});

export const tableCellColor = () => ({
  backgroundColor: colors.backgrounds[1],
  "& .MuiInput-input": {
    WebkitBoxShadow: `0 0 0 1000px ${colors.backgroundColor[1]} inset`,
  },
});

export const RemoveRowDeleteButton = () => ({
  padding: "1rem 0.3rem 1.2rem 0.3rem",
});

export const DeleteButton = {
  paddingLeft: "0",
};

export const ValidationMessage = () => ({
  color: colors.red[100],
});
export const tableCellNoColor = () => ({
  color: colors.backgrounds[6],
});

export const primaryMainColor = () => ({
  color: colors.primary.main,
});

export const secondaryColor = () => ({
  color: colors.black[1],
});

export const RefDetail = () => ({
  "&:empty::after": {
    content: '"\0"',
    visibility: "hidden",
  },
});
export const FaSearchIcon = styled(FaSearch)(() => ({
  fontSize: "0.8rem",
}));

export const tableHead = () => ({
  backgroundColor: colors.backgroundColor[9],
});

export const AutocompleteTabelCell = () => ({
  padding: "1rem 0rem 1.2rem 0.3rem",
});

export const DescriptionTabelCell = () => ({
  padding: "1rem 0.3rem 1.2rem 0.3rem",
  "& .MuiTextField-root": {
    width: "92%",
  },
});
export const ReauthOpacity = {
  opacity: "0.6",
};

export const BackButton = () => ({
  width: "4.5rem",
  height: "2rem",
});


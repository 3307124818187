import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export interface Props {
  alertMessage: string;
  setOpenAlert: (value: boolean) => void;
  openAlert: boolean;
  back?: boolean;
  handleSubmit?: () => any | ((isActive?: boolean) => void);
  handleBackClick?: () => any;
}
const AlertDialog = ({
  openAlert,
  setOpenAlert,
  handleSubmit,
  alertMessage,
  handleBackClick,
  back
}: Props) => {
  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <Dialog
      open={openAlert}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="body1"
          fontSize=".85rem"
          fontWeight={fontWeight.Weight[6]}
          color={colors.fonts[18]}
        >
          {alertMessage}
        </Typography>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={back ? handleBackClick :  handleSubmit} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

import {
  AutocompleteInputChangeReason,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { MutableRefObject, useRef, useState, useEffect } from "react";
import { debounce, isEmpty } from "lodash";
import { Controller } from "react-hook-form";

import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { ServiceProviderResponse } from "../../../../../models/Api/Service";
import {
  ServiceProvider as ServiceProviderDetails,
  ServiceProviderPayload,
} from "../../../../../models/Service";
import colors from "../../../../../styles/colors/colors";
import others from "../../../../../styles/colors/others";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { getValue, isNil } from "../../../../../utils";
import { rules } from "../../../../../utils/validation/Validation";
import {
  AddManually,
  AddManuallyGridContainer,
  AutocompleteUI,
  Body,
  DetailsGridContainer,
  DetailsInfoContainer,
  DividerStyle,
  FormInputBox,
  FormInputBoxAutoComplete,
  GridTitle,
  IconFaUserNurse,
  OrDividerStyle,
  OuterGridContainer,
  TitleBox,
  TitleGridItem,
} from "../styles/ServiceProvider";
import { formatAddress } from "../../../../../models/PhysicianDetails";
import VirtualizeAutocomplete from "../../../../../components/formComponents/AutocompleteList";
import { ProviderListDefaultHeader } from "../../../../../constants/Authorization";
import {
  flexAlignCentre,
  justifyContentLeft,
} from "../../../../../styles/mui/styles/display";
import { TypographyBox } from "../../../components/ActiveAuthCommon/AuthAndReAuthDetailsCommon";
import {
  getTableHead,
  getTableBody,
  TableDropdown,
} from "../../../../../constants/AllPatientRecord";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import { resetStates } from "../../../../../actions/Common";
import { ServiceProviderActionDispatchType } from "../../../../../constants/Service";

export interface PropsDispatch {
  getServiceProviderList: (value: string) => void;
  resetServiceProviderList: () => void;
}
export interface PropsState {
  serviceProviderList: ServiceProviderResponse;
  control: any;
  getValues: any;
  reset: any;
  serviceProviderDetails: ServiceProviderPayload;
  watch: any;
  setValue: any;
  clearErrors: (name: string) => void;
}
type AllProps = PropsState & PropsDispatch;

const ServiceProvider: React.FC<AllProps> = ({
  serviceProviderList,
  control,
  getValues,
  reset,
  getServiceProviderList,
  serviceProviderDetails,
  watch,
  setValue,
  resetServiceProviderList,
}: AllProps) => {
  const [serviceProviderListResp, setServiceProviderListResp] = useState<
    ServiceProviderDetails[]
  >(serviceProviderList.response);

  const [addManually, setAddManually] = useState(false);
  const [openProvider, setOpenProvider] = useState(false);
  const [serviceProvider, setServiceProvider] =
    useState<ServiceProviderDetails>();

  useEffect(() => {
    resetServiceProviderList();
    return () => {
      resetServiceProviderList();
      resetStates([
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    if (!isNil(serviceProviderDetails)) {
      if (
        isNil(serviceProviderDetails.serviceProviderId) &&
        !isEmpty(serviceProviderDetails.providerName)
      ) {
        setAddManually(true);
        setServiceProvider(undefined);
      } else if (
        serviceProviderDetails.serviceProviderId &&
        serviceProviderDetails.serviceProviderId > 0
      ) {
        setAddManually(false);
        const {
          streetName1,
          streetName2,
          providerCity,
          providerCounty,
          providerState,
          zipCode,
        } = serviceProviderDetails;
        const address = formatAddress(
          streetName1,
          providerCity,
          providerState,
          zipCode,
          streetName2,
          providerCounty
        );
        const provider: ServiceProviderDetails = {
          serviceProviderId: serviceProviderDetails.serviceProviderId,
          providerName: serviceProviderDetails.providerName,
          providerNpi: serviceProviderDetails.providerNpi,
          providerAddress: address,
          paymentStructure: serviceProviderDetails.paymentStructure,
          streetName1: serviceProviderDetails.streetName1,
          streetName2: serviceProviderDetails.streetName2,
          providerCity: serviceProviderDetails.providerCity,
          county: serviceProviderDetails.providerCounty,
          providerState: serviceProviderDetails.providerState,
          zipCode: serviceProviderDetails.zipCode,
          phone: serviceProviderDetails.phone,
          extension: serviceProviderDetails.extension,
        };
        setServiceProvider(provider);
      } else if (
        isNil(serviceProviderDetails.serviceProviderId) &&
        isEmpty(serviceProviderDetails.providerName)
      ) {
        setAddManually(false);
        setServiceProvider(undefined);
      }
    }
  }, [serviceProviderDetails]);

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (!isNil(value) && !isNil(name)) {
        if (name === "providerName") {
          const providerName = value.providerName || "";
          if (!isNil(providerName)) {
            setValue("serviceProviderId", null);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setServiceProviderListResp(serviceProviderList.response);
  }, [serviceProviderList.response]);

  const handleServiceProviderName = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (value) {
      if (value.length <= 2) {
        setServiceProviderListResp([]);
      }

      if (reason === "input") {
        if (value.trim().length >= 3 && !isNil(event)) {
          getServiceProviderList(value);
          setOpenProvider(true);
          const newServiceProviderList = serviceProviderListResp;
          if (newServiceProviderList && newServiceProviderList.length != 0) {
            setServiceProviderListResp([...newServiceProviderList]);
          }
        }
      }
    } else {
      setServiceProviderListResp(() => []);
      resetServiceProviderList();
    }
  };

  const debouncedSearch = debounce((event, value, reason) => {
    handleServiceProviderName(event, value, reason);
  }, 500);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debouncedSearch(event, value, reason);
  };

  const setFormValues = (option?: ServiceProviderDetails | undefined) => {
    setValue("paymentStructure", "");
    setValue("providerCity", getValue(option, "providerCity", null));
    setValue("providerCounty", getValue(option, "providerCounty", null));
    setValue("providerName", getValue(option, "providerName", null));
    setValue("providerNpi", getValue(option, "providerNpi", null));
    setValue("providerState", getValue(option, "providerState"));
    setValue("serviceProviderId", getValue(option, "serviceProviderId", null));
    setValue("streetName1", getValue(option, "streetName1", null));
    setValue("streetName2", getValue(option, "streetName2", null));
    setValue("zipCode", getValue(option, "zipCode", null));
    setValue("phone", getValue(option, "phone", ""));
    setValue("extension", getValue(option, "extension", ""));
    setValue("providerId", getValue(option, "providerId", 0));
    setValue("plexisOfficeId", getValue(option, "plexisOfficeId", 0));
  };

  const chooseServiceProvider = (option: ServiceProviderDetails) => {
    const value = serviceProviderListResp.find(
      (provider) => provider.serviceProviderId === option.serviceProviderId
    );
    setValue("providerName", value?.providerName || null);
    if (!isNil(option)) {
      setOpenProvider(false);
      setServiceProvider(option);
      setAddManually(false);
      setFormValues(option);
      reset({
        ...getValues(),
      });
      resetServiceProviderList();
      setServiceProviderListResp(() => []);
    }
  };

  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const labels = [
    {
      label: "Street Name 1: ",
      name: "streetName1",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: "streetName2",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "County: ",
      name: "providerCounty",
      helper: rules.county,
      textLength: 100,
    },
    {
      label: "City: ",
      name: "providerCity",
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: "providerState",
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: "zipCode",
      helper: rules.zipcode,
      textLength: 10,
    },
  ];

  const paymentType = [
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const handleAddManually = () => {
    setFormValues();
    setServiceProvider(undefined);
    setAddManually(true);
  };

  return (
    <>
      <Grid container sx={OuterGridContainer}>
        <Grid item xs={12}>
          <Grid container sx={GridTitle}>
            <Grid item xs={12} sx={TitleGridItem}>
              <Box sx={TitleBox}>
                <Typography
                  variant="h6"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[20]}
                >
                  SERVICE PROVIDER DETAILS:
                </Typography>
              </Box>
              <Box>
                <Typography
                  mt={"0.5rem"}
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[5]}
                >
                  Please add service provider details and continue:
                </Typography>
              </Box>
              <Divider sx={DividerStyle} light />
            </Grid>
          </Grid>
          <Grid container sx={Body}>
            <Grid item xs={12} pt={"0.5rem"}>
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[3]}
                color={colors.fonts[20]}
              >
                Name:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box sx={[FormInputBoxAutoComplete]}>
              <Controller
              name={"providerName"}
              control={control}
              defaultValue={null}
              render={() => {
                return (
                  <>
                  <VirtualizeAutocomplete
                  options={serviceProviderListResp}
                  value={null}
                  handleInputChange={handleInputChange}
                  sx={AutocompleteUI}
                  open={openProvider}
                  handleSelect={chooseServiceProvider}
                  tableHead={getTableHead(TableDropdown.SERVICE_PROVIDER)}
                  tableBody={getTableBody(TableDropdown.SERVICE_PROVIDER)}
                  autocompleteInputIcon={<IconFaUserNurse />}
                  setOpen={setOpenProvider}
                  maxLength={128}
                  width="55rem"
                  loading={serviceProviderList.loading}
                  placeholder="Search by name or NPI"
                />
                  </>
                );
              }}
            />
              </Box>
            </Grid>
            <Grid item xs={6} sx={FormInputBox}>
              <Box sx={[flexAlignCentre, justifyContentLeft]}>
                <Box pl={"1rem"}>
                  {TypographyBox(
                    "subtitle2",
                    "OR",
                    colors.fonts[4],
                    fontWeight.Weight[6]
                  )}
                </Box>
                <Box pl={"2.5rem"}>
                  <Button variant="contained" onClick={handleAddManually}>
                    <Typography
                      variant="body1"
                      fontWeight={fontWeight.Weight[4]}
                      color={others.otherColors[33]}
                    >
                      ADD MANUALLY
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {!isNil(serviceProvider) && !addManually && (
            <Grid container sx={DetailsGridContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[20]}
                >
                  DETAILS:
                </Typography>
              </Grid>
              {detailsSectionItem("Name", serviceProvider?.providerName, 2.5)}
              {detailsSectionItem("NPI", serviceProvider?.providerNpi, 1.5)}
              {detailsSectionItem(
                "Address",
                serviceProvider?.providerAddress,
                3.5
              )}
              {detailsSectionItem(
                "Payment Structure",
                serviceProvider?.paymentStructure,
                2
              )}
            </Grid>
          )}
          {addManually && (
            <Grid container sx={AddManuallyGridContainer}>
              <Grid item xs={12}>
                <Divider sx={OrDividerStyle} />
              </Grid>
              <Grid item xs={12} sx={AddManually}>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.fonts[20]}
                  >
                    ADD PROVIDER DETAILS MANUALLY:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={FormInputBox}>
                  <FormInputText
                    name={"providerName"}
                    control={control}
                    label="Service Provider Name:"
                    helper={rules.serviceProviderName}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={FormInputBox}>
                  <FormInputText
                    name={"providerNpi"}
                    control={control}
                    label="Service Provider NPI:"
                    helper={rules.npi}
                    textLength={10}
                    inputType="onlyNumber"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={FormInputBox}>
                  <PhoneFaxExtInput
                    label="Phone Number:"
                    control={control}
                    phoneFaxName={"phone"}
                    extName={"extension"}
                    rules={rules.phoneNumber}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box sx={FormInputBox}>
                  <FormInputDropdown
                    name={"paymentStructure"}
                    control={control}
                    label={"Payment Structure:"}
                    list={paymentType}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} pl={"1rem"}>
                <AddressFormInputUseForm
                  control={control}
                  searchInput={searchInput}
                  reset={reset}
                  getValues={getValues}
                  labels={labels}
                  addressLabel="ADDRESS DETAILS:"
                  addressVariant="subtitle1"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const detailsSectionItem = (
  headerTitle: string,
  value: string | undefined,
  xs: number
) => {
  return (
    <>
      <Grid item xs={xs} sx={DetailsInfoContainer}>
        <Typography
          variant="body1"
          color={colors.primary.main}
          fontWeight={fontWeight.Weight[2]}
        >
          {headerTitle}:
        </Typography>
        <Typography
          variant={
            headerTitle === ProviderListDefaultHeader.PROVIDER_NAME ||
              headerTitle === ProviderListDefaultHeader.NPI
              ? "h6"
              : "subtitle2"
          }
          color={colors.black[5]}
          fontWeight={fontWeight.Weight[5]}
          pt={2}
        >
          {value}
        </Typography>
      </Grid>
    </>
  );
};

export default ServiceProvider;

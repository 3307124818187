import { Reducer } from "redux";

import { UpdateRoleDetailsResponse } from "../models/Api/Roles";
import { RoleDetailsDispatchTypes } from "../models/DispatchTypes/Roles";
import { RoleDetailsActionDispatchTypes } from "../constants/Roles";

export const initialState: UpdateRoleDetailsResponse = {
  loading: false,
  response: {
    roleId: -1,
    roleName: "",
    roleDescription: "",
    roleModule: [
      {
        moduleId: -1,
        moduleName: "",
        roleId: -1,
        roleSubModule: [
          {
            moduleId: -1,
            roleId: -1,
            subModuleId: -1,
            subModuleName: "",
            accesses: [],
          },
        ],
      },
    ],
    loggedInUser: "",
  },
  error: null,
};

const error = (state: UpdateRoleDetailsResponse, action: any) => ({
  ...state,
  loading: false,
  error: action.response,
  response: null,
});

const invoke = (state: UpdateRoleDetailsResponse) => ({
  ...state,
  loading: true,
  response: null,
  error: null,
});

const success = (state: UpdateRoleDetailsResponse, action: any) => ({
  ...state,
  response: action.response,
  loading: false,
  error: null,
});

const reset = () => ({
  response: null,
  loading: false,
  error: null,
});

const UpdateRoleDetailsReducer: Reducer<
  UpdateRoleDetailsResponse,
  RoleDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_INVOKE: {
      return invoke(state);
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_SUCCESS: {
      return success(state, action);
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_ERROR: {
      return error(state, action);
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_RESET: {
      return reset();
    }
  }
  return state;
};

export default UpdateRoleDetailsReducer;

import { Reducer } from "redux";

import { OrderingSourceResponse } from "../models/Api/Master";
import { OrderingSourceDispatchTypes } from "../models/DispatchTypes/Master";
import { OrderingSourceActionDispatchTypes } from "../constants/Master";

const initialState: OrderingSourceResponse = {
  loading: false,
  response: [
    {
      orderedById: 0,
      orderedByName: "",
    },
  ],
};

const OrderingSource: Reducer<
  OrderingSourceResponse,
  OrderingSourceDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case OrderingSourceActionDispatchTypes.ORDERING_SOURCE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case OrderingSourceActionDispatchTypes.ORDERING_SOURCE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default OrderingSource;

import { Reducer } from "redux";

import { ReAuthReviewActionDispatchType } from "../constants/Review";
import { ReAuthReviewDispatchTypes } from "../models/DispatchTypes/Review";
import { ReAuthReviewResponse } from "../models/Api/Review";

const initialState: ReAuthReviewResponse = {
  loading: false,
  response: {
    reAuthReferralDetail: {
      receivedDateTime: "",
      urgencyId: 0,
      userCreated: null,
      urgencyStatus: "",
      referralId: 0,
      patientId: 0,
    },
    reAuthRequester: {
      facilityTypeId: 2,
      requesterName: "",
      requesterExtension: "",
      requesterPhoneNo: "",
      requesterEmail: "",
      requesterFax: "",
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      requesterState: null,
      zipCode: null,
      facilityTypeName: "",
      serviceRequestId: 0,
    },
    reAuthIcdCode: [
      {
        referralIcdCodeId: 0,
        diagnosisTypeId: 0,
        diagnosisCode: "",
        diagnosisCodeDescription: "",
        icdDiagnosisCodeId: 0,
      },
    ],
    reAuthPrimaryPhysician: {
      serviceRequestId: 0,
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName1: "",
      streetName2: "",
      fax: "",
      county: "",
      city: "",
      physicianState: "",
      zipCode: "",
      physicianTypeId: 0,
      userCreated: null,
      physicianAddress: "",
      physicianUid: "",
      referralId: 0,
      referralPhysicianDetailId: 0,
    },
    reAuthOrderingPhysician: {
      serviceRequestId: 0,
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName1: "",
      streetName2: "",
      fax: "",
      county: "",
      city: "",
      physicianState: "",
      zipCode: "",
      physicianTypeId: 0,
      physicianAddress: "",
      physicianUid: "",
      referralId: 0,
      state: "",
      physicianSameAsPrimary: false,
    },
    reAuthFollowingPhysicianDetail: {
      serviceRequestId: 0,
      followingPhysicianId: 0,
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      streetName1: "",
      streetName2: "",
      city: "",
      zipCode: "",
      phoneNo: "",
      taxId: "",
      fax: "",
      physicianState: "",
      userCreated: null,
      userUpdated: "",
      physicianUid: "",
      phoneExt: "",
      county: "",
      referralId: 0,
      physicianSameAsPrimary: false,
      physicianSameAsOrdering: false,
    },
    reAuthServiceRequestDetails: {
      serviceRequestId: 0,
      referralId: 0,
      userCreated: null,
      startOfCareDate: "",
      startOfCareVerified: false,
      currentStartDate: "",
      currentEndDate: "",
      nextStartDate: "",
      nextEndDate: "",
      reAuthDiscipline: [],
      currentDate: "",
    },
    reAuthReferralDocuments: {
      containsSupportedDoc: false,
      documentationDate: "",
      documentId: [],
      documentName: [],
      id: 0,
      userCreated: null,
      type: 0,
    },
    reAuthBlobDocumentDetail: [],
  },
};

const ReAuthReviewReducer: Reducer<
  ReAuthReviewResponse,
  ReAuthReviewDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReAuthReviewActionDispatchType.RE_AUTH_REVIEW_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReAuthReviewActionDispatchType.RE_AUTH_REVIEW_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ReAuthReviewActionDispatchType.RE_AUTH_REVIEW_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
  }
  return state;
};

export default ReAuthReviewReducer;

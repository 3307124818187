export enum AppConfigsActionDispatchTypes {
  APP_CONFIG_INVOKE = "APP_CONFIG_INVOKE",
  APP_CONFIG_SUCCESS = "APP_CONFIG_SUCCESS",
}

export enum AppEnvironmentConfig {
  DEV = "development",
  PROD = "production",
}

export enum CloudEnvironmentConfig {
  SB = "SB",
  QA = "QA",
  DEV = "DEV",
  STG = "STG",
  SE = "SE",
  DD = "DD",
  QZ = "QZ"
}

import colors from "../../../../../../../styles/colors/colors";

export const DocumentationDetailsMainGrid = () => ({
  paddingBottom: "2.4rem",
  backgroundColor: colors.backgroundColor[2],
});

export const DocumentationDetailsHeadingGrid = () => ({
  padding: "0.5rem 0rem 1rem 1.8rem",
});

export const DocumentationDetailsLeftGrid = () => ({
  padding: "0.4rem 1.8rem 2rem 2rem ",
  borderRight: `1px solid ${colors.borders[1]}`,
});

export const DocumentationDetailsLeftGrid1stColumn = () => ({
  paddingBottom: "2.7rem",
});

export const DividerStyle = () => ({
  margin: "0.6rem 3rem 2.5rem 1.5rem",
});

export const DocumentationDetailsQuestion = () => ({
  padding: "1.2rem 0 0 0.5rem",
});

export const DocumentationDetailsLeftGrid2ndColumnHeading = () => ({
  paddingBottom: " 3.2rem ",
});

export const DocumentationDetailsLeftGrid2ndColumn = () => ({
  paddingTop: "1rem",
  display: "flex",
  alignItems: "center",
});

export const DocumentationDetailsLeftGrid3rdColumn = () => ({
  paddingBottom: "1rem 0",
});

export const DocumentationDetailsLeftGrid3rdColumnDate = () => ({
  padding: "1.2rem 0 1rem 0",
  display: "flex",
  alignItems: "center",
});

export const DocumentationDetailsRightGrid = () => ({
  padding: "1.5rem 3.2rem 0 3.5rem",
});

export const DocumentationDetailsRightGridTableHead = () => ({
  backgroundColor: colors.backgroundColor[8],
  "& .MuiTableCell-root": {
    padding: "1rem 0.3rem",
    borderBottom: "none",
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: "none",
  },
  "& th:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: "none",
  },
});

export const DocumentationDetailsRightGridTableBody = () => ({
  "&:last-child td, &:last-child th": { border: 0 },
  "& .MuiTableCell-root": {
    padding: "0.8rem 0.3rem",
  },
});

export const DocumentationDetailsRightGridTableCellDocumentName = () => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "1rem",
});

export const DocumentationDetailsRightGridTableCellMargin = () => ({
  margin: "1.8rem 0",
  marginLeft: "1rem",
});

export const DocumentationDetailsDocumentsHeading = () => ({
  paddingBottom: "1rem",
});

export const DocumentationDetailsDocumentsPdfIcon = () => ({
  paddingRight: "0.5rem",
});

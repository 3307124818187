import React from "react";
import { Grid } from "@mui/material";
import { styles } from "../styles/styles";


type BodyProps = {
  children: React.ReactNode;
  sx?: object;
};

const Body = ({ children, sx }: BodyProps) => {
  return (
    <>
      <Grid item xs={12} sx={sx || styles.bodyStyle}>
        <Grid container sx={styles.body}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default Body;

import { Typography, Grid } from "@mui/material";

import { HeaderBox, HeaderModuleName } from "../../styles/style";
import fontWeight from "../../../../styles/mui/fontWeight";
import { colors } from "../../../../styles/colors";

const Header = () => {
  return (
    <>
      <HeaderBox container>
        <Grid item xs={10}>
          <Typography
            variant="h4"
            fontWeight={fontWeight.Weight[6]}
            sx={HeaderModuleName}
            color={colors.black[2]}
          >
            ALL PATIENTS
          </Typography>
        </Grid>
      </HeaderBox>
    </>
  );
};

export default Header;

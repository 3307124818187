import { Box, Divider, Grid, Modal, Typography } from "@mui/material";

import {
  CloseButton,
  MainBox,
  NotePreview,
  NotesDividerStyle,
  NotesGridStyle,
  NotesViewCloseIcon,
  NotesViewHeader,
  NotesViewHeaderTitle,
} from "../styles/NotesView";
import AssessmentViewer from "../../../../../components/surveyjs/AssessmentViewer";
import { GetAdminNotesByVersionIdActionDispatchTypes } from "../../../../../constants/Notes";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { IconFaTimesCircle, ModalGridContainer } from "../../../../../styles/common/style";

export interface PropsFromState {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  notesViewData: any;
  resetStates: (actionType: string[]) => void;
}

const NoteView: React.FC<PropsFromState> = ({
  openModal,
  setOpenModal,
  notesViewData,
  resetStates,
}) => {
  const handleClose = () => {
    resetStates([GetAdminNotesByVersionIdActionDispatchTypes.GET_NOTES_RESET]);
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      sx={ModalGridContainer}
    >
      <Box sx={MainBox}>
        <Grid container>
          <Grid item xs={12} sx={CloseButton}>
            <Grid container sx={NotesViewHeader}>
              <Grid item xs={3} sx={NotesGridStyle}>
                <Typography
                  variant="h3"
                  color={colors.black[2]}
                  fontWeight={fontWeight.Weight[6]}
                  sx={NotesViewHeaderTitle}
                >
                  {notesViewData && notesViewData.templateCategoryName}
                </Typography>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  flexItem
                  sx={NotesDividerStyle}
                />
                <Typography
                  variant="subtitle2"
                  color={colors.fonts[25]}
                  fontWeight={fontWeight.Weight[6]}
                  sx={NotesViewHeaderTitle}
                >
                  {notesViewData && notesViewData.templateName}
                </Typography>
              </Grid>
              <Grid item xs={8.8} sx={NotesViewCloseIcon}>
                <IconFaTimesCircle
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={NotePreview}>
          <AssessmentViewer
            isSubmit={false}
            assessmentViewData={notesViewData && notesViewData.templateJson}
            editFlag={false}
            setEditNotesPreview={setOpenModal}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default NoteView;

import { Reducer } from "redux";

import { PatientPhotoUpdateResponse } from "../models/Api/Patient";
import { UpdatePatientPhotoDispatchTypes } from "../models/DispatchTypes/Patient";
import { UpdatePatientPhotoActionDispatchTypes } from "../constants/Patient";

export const initialState: PatientPhotoUpdateResponse = {
  loading: false,
  response: {
    blobId: 0,
    patientPhoto: "",
    patientId: 0,
  },
  error: null,
};

const PatientPhotoReducer: Reducer<
  PatientPhotoUpdateResponse,
  UpdatePatientPhotoDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case UpdatePatientPhotoActionDispatchTypes.UPDATE_PATIENT_PHOTO_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UpdatePatientPhotoActionDispatchTypes.UPDATE_PATIENT_PHOTO_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case UpdatePatientPhotoActionDispatchTypes.UPDATE_PATIENT_PHOTO_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default PatientPhotoReducer;

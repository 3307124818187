import { Reducer } from "redux";

import { AccomodationMasterResponse } from "../models/Api/Master";
import { AccomodationDispatchTypes } from "../models/DispatchTypes/Master";
import { AccomodationMasterActionDispatchTypes } from "../constants/Master";

export const initialState: AccomodationMasterResponse = {
  loading: false,
  response: [],
};

const MasterAccomodationReducer: Reducer<
  AccomodationMasterResponse,
  AccomodationDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AccomodationMasterActionDispatchTypes.ACCOMODATION_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AccomodationMasterActionDispatchTypes.ACCOMODATION_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterAccomodationReducer;

import { styled } from "@mui/system";
import { Tabs, Tab, Box } from "@mui/material";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const HistoryBox = () => ({
  paddingBottom: "2rem",
});

export const titleBox = {
  padding: "0.5rem 1rem 0 0.6rem",
};

export const HistoryTabsBox = styled(Tabs)((props) => ({
  overflow: "visible",
  "& .MuiTabs-scroller": {
    overflow: "visible",
  },
  marginLeft: "6rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
  "& .Mui-selected": {
    border: `1px solid ${colors.borders[1]}`,
    color: colors.fonts[18],
    fontWeight: fontWeight.Weight[4],
    textTransform: "uppercase",
    borderBottom: `1px solid ${props.theme.palette.common.white}`,
  },
  "& .MuiTabs-flexContainer": {
    paddingTop: "1.8rem",
  },
}));

export const HistoryTab = styled(Tab)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  marginRight: "1rem",
  borderRadius: "10px",
  borderBottomRightRadius: "0px",
  borderBottomLeftRadius: "0px",
  border: `1px solid ${colors.borders[1]}`,
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  textTransform: "uppercase",
  overflow: "visible",
  color: colors.fonts[19],
  padding: "0.7rem 1.5rem 0 1.5rem",
  minHeight: "2.8rem",
  fontSize: ".75rem",
  fontWeight: fontWeight.Weight[3],
  opacity: 0.9,
}));

export const TabsPanelSectionBox = styled(Box)((props) => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: props.theme.palette.common.white,
  borderRadius: "10px",
  marginBottom: "1rem",
}));

export const PanelBox = styled(Box)(() => ({
  padding: "1.5rem 1.5rem 0 1.5rem",
}));

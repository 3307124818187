import { Reducer } from "redux";

import { DisciplineTypeActionDispatchTypes } from "../constants/Master";
import { DisciplineTypeResponse } from "../models/Api/Master";
import { DisciplineTypeDispatchTypes } from "../models/DispatchTypes/Master";

export const initialPostState: DisciplineTypeResponse = {
  loading: false,
  response: [
    {
      serviceRequestTypeId: -1,
      serviceRequestTypeName: "",
    },
  ],
};

const DisciplineReducer: Reducer<
  DisciplineTypeResponse,
  DisciplineTypeDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case DisciplineTypeActionDispatchTypes.DISCIPLINE_TYPE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DisciplineTypeActionDispatchTypes.DISCIPLINE_TYPE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DisciplineReducer;

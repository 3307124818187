import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { PatientDetailActionDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import ICD10 from "../components/ICD10";
import {
  getDiseaseTypeCall,
  getIcdCodeTypeData,
} from "../../../../../actions/Master";
import {
  DiseaseTypeResponse,
  IcdCodeTypeResponse,
} from "../../../../../models/Api/Master";
import { IcdCodeType, DiseaseType } from "../../../../../models/Master";

const getIcdCodeType = (icdCodeTypeState: IcdCodeTypeResponse) => {
  const result = icdCodeTypeState.response;
  return result;
};

const getDiseaseType = (disaseTypeState: DiseaseTypeResponse) => {
  const result = disaseTypeState.response;
  return result;
};

const mapStateToProps = ({
  disaseTypeState,
  icdCodeTypeState,
}: ApplicationState) => ({
  diseaseTypeResp: getDiseaseType(disaseTypeState) as DiseaseType[],
  icdCodeDataType: getIcdCodeType(icdCodeTypeState) as IcdCodeType[],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<PatientDetailActionDispatchTypes>
  >
) => {
  return {
    getIcdeCodeDataType: () => {
      dispatch(getIcdCodeTypeData());
    },
    getDiseaseTypeData: (diseaseIdentifier: string) => {
      dispatch(getDiseaseTypeCall(diseaseIdentifier));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ICD10);

import API from "./api";

import { DisciplineNoteSearch } from "../models/Disciplines";
import {
  AgencyCommunicationNote,
  DisciplineLevelNote,
  ReferralLevelNote,
} from "../models/Notes";

export const PostAdminNotesList = (configUrl: string, payload: any) =>
  API.postApi(`${configUrl}api/NoteTemplate/searchTemplatetRecords`, payload);

export const DeleteNotesTemplate = (configUrl: string, suffix: number) =>
  API.delete(`${configUrl}api/TemplateVersion/${suffix}`);

export const DeactivateNotesTemplate = (
  configUrl: string,
  id: number,
  isActive: boolean
) => {
  return API.delete(
    `${configUrl}api/NoteTemplate/${id}?toActivate=${isActive}`
  );
};

export const GetNotesCategoryMaster = (configUrl: string) =>
  API.get(`${configUrl}api/category`);

export const SaveNotesSection = (payload: any, configUrl: any) =>
  API.postApi(`${configUrl}api/TemplateVersion`, payload);

export const UpdateNotesSection = (id: number, payload: any, configUrl: any) =>
  API.putApi(`${configUrl}api/TemplateVersion/${id}`, payload);

export const GetNotesByTemplateVersionId = (
  templateId: number,
  configUrl: string
) => API.get(`${configUrl}api/TemplateVersion/${templateId}`);

export const GetTemplateById = (noteTemplateId: number, configUrl: string) =>
  API.get(`${configUrl}api/NoteTemplate/TemplateJson/${noteTemplateId}`);

export const GetDisciplineNoteList = (
  configUrl: string,
  payload: DisciplineNoteSearch
) => {
  return API.get(
    `${configUrl}api/disciplines/DisciplineNote/${payload.id}/${payload.pageNo}/${payload.pageSize}`
  );
};

export const GetReferralNoteList = (
  configUrl: string,
  payload: DisciplineNoteSearch
) => {
  return API.get(
    `${configUrl}api/referral/ReferralNote/${payload.id}/${payload.pageNo}/${payload.pageSize}`
  );
};

export const UpdateSurveyDetails = (payload: any) => {
  return API.postApi(`api/servicerequest`, payload);
};

export const GetDisciplineTemplateNotesMaster = (
  configUrl: string,
  categoryId?: number
) =>
  API.get(`${configUrl}api/NoteTemplate/categoryId?categoryId=${categoryId}`);

export const GetDisciplineNoteData = (
  configUrl: string,
  disciplineNoteId: number
) => API.get(`${configUrl}api/disciplines/DisciplineNote/${disciplineNoteId}`);

export const SaveDisciplineNoteData = (
  configUrl: string,
  payload: DisciplineLevelNote
) => API.postApi(`${configUrl}api/disciplines/DisciplineNote`, payload);

export const UpdateDisciplineNoteData = (
  configUrl: string,
  disciplineNoteId: number,
  payload: any
) =>
  API.putApi(
    `${configUrl}api/disciplines/DisciplineNote/${disciplineNoteId}`,
    payload
  );

export const GetReferralNoteData = (
  configUrl: string,
  referralNoteId: number
) => API.get(`${configUrl}api/referral/ReferralNote/${referralNoteId}`);

export const SaveReferralNoteData = (
  configUrl: string,
  payload: ReferralLevelNote
) => API.postApi(`${configUrl}api/referral/ReferralNote`, payload);

export const UpdateReferralNoteData = (
  configUrl: string,
  referralNoteId: number,
  payload: any
) =>
  API.putApi(
    `${configUrl}api/referral/ReferralNote/${referralNoteId}`,
    payload
  );

export const PostAgencyCommunicationNote = (
  configUrl: string,
  payload: AgencyCommunicationNote
) => API.postApi(`${configUrl}api/referral/AgencyCommunicationNote`, payload);

export const GetLevelOfFunction = (configUrl: string, referralNoteId: number) =>
  API.get(`${configUrl}api/disciplines/DisciplineNoteList/${referralNoteId}`);

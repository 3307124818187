import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, UseFormTrigger } from "react-hook-form";
import { MutableRefObject, useEffect, useRef, useState } from "react";

import {
  AddressHeaderBox,
  AddressHeaderBoxForReauth,
  BoxStyle,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  HorizontalDivider,
  RefSourceRadio,
  RequestHeaderBox,
  RequestorBox,
  RequestorGrid,
} from "../../ReferralIntake/styles/requestStyle";
import { colors, others } from "../../../../../styles/colors";
import { length } from "../../../../../utils";
import { ReAuthReferralRequest } from "../../../../../models/Patient";
import { ReferralSourceFacility } from "../../../../../models/Referral";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  FaPhoneInputDevider,
  FaRegEnvelopeIcon,
} from "../../../../../components/formComponents/styles/style";
import {
  flexAlignBaseline,
  flexAllCentre,
} from "../../../../../styles/mui/styles/display";
import { rules } from "../../../../../utils/validation/Validation";
import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import { ReAuthReferralSourceMasterResponse } from "../../../../../models/Api/Master";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";

export interface PropsFromState {
  clearErrors: (name: string) => void;
  control: any;
  patientRequestDetail: ReAuthReferralRequest | any;
  reAuthReferralSourceState: ReAuthReferralSourceMasterResponse;
  setValue: (id: any, value: any, boolean: any) => void;
  values: ReAuthReferralRequest;
  reset: () => void;
  getValues: any;
  trigger?: UseFormTrigger<any>;
}

export interface PropsFromDispatch {
  getReAuthReferralSourceMasterResp: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ReauthRequestor: React.FC<AllProps> = ({
  clearErrors,
  control,
  getReAuthReferralSourceMasterResp,
  reAuthReferralSourceState,
  setValue,
  reset,
  getValues,
}: AllProps) => {
  const { loading, response } = reAuthReferralSourceState;
  const [referralSource, setReferralSource] = useState<
    ReferralSourceFacility[] | any[]
  >([]);
  const [showRequestor, setShowRequestor] = useState(true);
  const [requesterEmail, setRequesterEmail] = useState<any>();

  const handleRequesterEmail = (e: any) => {
    setRequesterEmail(e.target.value);
    setValue("requester.requesterEmail", e.target.value, true);
  };

  useEffect(() => {
    clearErrors("requester.requesterEmail");
  }, [requesterEmail]);

  useEffect(() => {
    if (!loading) {
      getReAuthReferralSourceMasterResp();
    }
  }, []);

  useEffect(() => {
    setReferralSource(response || []);
  }, [response]);

  const handleChange = (e: any) => {
    const referralSourceData =
      referralSource &&
      length(referralSource) &&
      referralSource.filter((item) => {
        return item.facilityTypeId == e.target.value;
      });
    if (referralSourceData && length(referralSourceData)) {
      setValue(
        "requester.facilityTypeName",
        referralSourceData[0].facilityTypeName,
        true
      );
      setValue("requester.facilityTypeId", e.target.value, true);
    }
  };

  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const labels = [
    {
      label: "Street Name 1: ",
      name: "requester.streetName1",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: "requester.streetName2",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "County: ",
      name: "requester.county",
      helper: rules.county,
      textLength: 100,
    },
    {
      label: "City: ",
      name: "requester.city",
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: "requester.requesterState",
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: "requester.zipCode",
      helper: rules.zipcode,
      textLength: 10,
    },
  ];

  return (
    <Grid pb={"3.5rem"}>
      <Box sx={RequestHeaderBox(RequesterComponentInjectType.PRIOR_AUTH)}>
        {showRequestor ? (
          <FaChevronCircleUpIcon onClick={() => setShowRequestor(false)} />
        ) : (
          <FaChevronCircleDownIcon onClick={() => setShowRequestor(true)} />
        )}
        <Box>
          <Typography
            variant="h5"
            fontWeight={fontWeight.Weight[6]}
            color={colors.fonts[20]}
            sx={AddressHeaderBox}
          >
            REQUESTOR:
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={others.otherColors[36]}
            sx={AddressHeaderBoxForReauth}
            mt={"0.5rem"}
          >
            Please add below all details specific to primary requestor and
            original referral source.
          </Typography>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem sx={HorizontalDivider} />

      {showRequestor && (
        <Box sx={RequestorBox}>
          <Box sx={flexAlignBaseline}>
            <Controller
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <Box mb={"2rem"}>
                  <>
                    <RadioGroup
                      aria-labelledby="controlled-radio-buttons-group"
                      sx={RefSourceRadio(false)}
                      {...field}
                      onChange={handleChange}
                    >
                      {referralSource &&
                        length(referralSource) &&
                        referralSource.map(
                          (singleOption: ReferralSourceFacility) => (
                            <Box
                              key={singleOption.facilityTypeId}
                              sx={BoxStyle}
                            >
                              <FormControlLabel
                                value={Number(singleOption.facilityTypeId)}
                                label={
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={fontWeight.Weight[5]}
                                    color={colors.black[5]}
                                  >
                                    {singleOption.facilityTypeName}
                                  </Typography>
                                }
                                control={<Radio color="primary" />}
                              />
                            </Box>
                          )
                        )}
                    </RadioGroup>
                    <FormHelperText sx={{ ml: "1rem" }}>
                      {error ? error.message : null}
                    </FormHelperText>
                  </>
                </Box>
              )}
              name={`requester.facilityTypeId`}
              control={control}
              rules={rules.requestorFacilityTypeId}
            />
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.red[100]}
            >
              *
            </Typography>
          </Box>
          <Grid container spacing={"2rem"} sx={RequestorGrid(false)}>
            <Grid item xs={3}>
              <FormInputText
                name="requester.requesterName"
                label="Requestor:"
                control={control}
                textLength={100}
                placeholder="Add here..."
                helper={rules.requestorName}
              />
            </Grid>

            <Grid item xs={3}>
              <PhoneFaxExtInput
                label="Phone Number:"
                control={control}
                phoneFaxName={`requester.requesterPhoneNo`}
                extName={`requester.requesterExtension`}
                rules={rules.phone}
              />
            </Grid>

            <Grid item xs={3}>
              <Controller
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    {...field}
                    value={field.value || ""}
                    error={error && error.message ? true : false}
                    helperText={error ? error.message : null}
                    InputProps={{
                      startAdornment: (
                        <Stack direction="row" sx={flexAllCentre}>
                          <FaRegEnvelopeIcon />
                          <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            flexItem
                            sx={FaPhoneInputDevider}
                          />
                        </Stack>
                      ),
                    }}
                    placeholder="Add here..."
                    onChange={handleRequesterEmail}
                    label={
                      <Typography
                        variant="h5"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[2]}
                      >
                        Email ID:
                      </Typography>
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 255 }}
                  />
                )}
                name={`requester.requesterEmail`}
                control={control}
                rules={rules.emailAddress}
              />
            </Grid>
            <Grid item xs={3}>
              <PhoneFaxExtInput
                label="Fax:"
                control={control}
                phoneFaxName={`requester.requesterFax`}
                rules={rules.fax}
                isFax
              />
            </Grid>
          </Grid>

          <Grid container mt={"1rem"}>
            <Grid item xs={12}>
              <AddressFormInputUseForm
                control={control}
                searchInput={searchInput}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                labels={labels}
                addressLabel="Facility Address:"
                addressVariant="subtitle2"
                addressStyle="-1rem"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default ReauthRequestor;

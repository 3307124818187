import { Reducer } from "redux";

import { GetReferralLevelNote } from "../models/Api/Notes";
import { GetReferralLevelNotesDataDispatchTypes } from "../models/DispatchTypes/Notes";
import { GetReferralLevelNotesActionDispatchTypes } from "../constants/Notes";

export const initialState: GetReferralLevelNote = {
  loading: false,
  response: {
    referralNoteId: -1,
    referralId: -1,
    noteEntryId: -1,
    dateCreated: "",
    dateUpdated: "",
    userCreated: "",
    noteTemplateId: -1,
    templateName: "",
    noteEntryJson: "",
    comment: "",
    fullNameCreated: "",
  },
};

const GetReferralLevelNotesReducer: Reducer<
  GetReferralLevelNote,
  GetReferralLevelNotesDataDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case GetReferralLevelNotesActionDispatchTypes.GET_REFERRAL_LEVEL_NOTES_RESET: {
      return initialState;
    }
  }
  return state;
};

export default GetReferralLevelNotesReducer;

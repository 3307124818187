import { Reducer } from "redux";

import { EligibilityHistoryResponse } from "../models/Api/AuthorizationHistory";
import { EligibiltyHistoryDispatchTypes } from "../models/DispatchTypes/AuthorizationHistory";
import { EligibilityHistoryActionDispatchTypes } from "../constants/AuthorizationHistory";

const initialState: EligibilityHistoryResponse = {
  loading: false,
  response: {
    totalRows: 0,
    patientEligibilityHistory: [],
  },
};

const EligibilityHistoryReducer: Reducer<
  EligibilityHistoryResponse,
  EligibiltyHistoryDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
    case EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_RESET: {
      return initialState;
    }
  }
  return state;
};

export default EligibilityHistoryReducer;

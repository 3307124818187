import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FaChevronRight } from "react-icons/fa";
import {
  UseFormTrigger,
  UseFormWatch,
  UseFormResetField,
} from "react-hook-form";
import { isEmpty } from "lodash";

import { width100PCT } from "../../../../../styles/mui/styles/widths";
import { RefTab, RefTabs } from "../../../../AllPatientRecords/styles/style";
import { AllPatientIntakeTabResponse } from "../../../../../models/Api/AllPatientRecord";
import { a11yProps, getValue, length, scrollTop } from "../../../../../utils";
import {
  PanelBox,
  TabsPanelSectionBox,
  DisabledTab,
} from "../../../styles/style";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { NextStepButton, SaveButton } from "../styles/style";
import { flexAllEnd } from "../../../../../styles/mui/styles/display";
import {
  EmergencyContact,
  PatientRequest,
  Status,
} from "../../../../../models/Patient";
import RequestDetail from "../containers/Request";
import RequestAddressDetail from "./RequestAddressDetail";
import Requestor from "../containers/Requestor";
import OrderValidation from "../containers/OrderValidation";
import ServiceRequest from "../containers/ServiceRequest";
import Documents from "../components/Documents";
import ICD10 from "../containers/ICD10";
import {
  ServiceProviderPayload,
  ServiceRequest as ServiceRequestModel,
} from "../../../../../models/Service";
import ServiceProvider from "../containers/ServiceProvider";
import Header from "./Header";
import DocumentsReview from "../containers/DocumentsReview";
import { ReferralIntakePhysicianDetails } from "../../../../../models/PhysicianDetails";
import AlertDialog from "./AlertDialog";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors, others } from "../../../../../styles/colors";
import OrderingPhysician from "./OrderingPhysician";
import ReviewRequestDetail from "./Review/containers/ReviewRequestDetail";
import ReviewIcd10 from "./Review/containers/ReviewIcd10";
import ReviewDisciplineRequest from "./Review/containers/ReviewDisciplineRequest";
import ReviewDocumentationDetail from "./Review/containers/ReviewDocumentationDetail";
import { DocumentType } from "../../../../../models/Master";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { resetStates } from "../../../../../actions/Common";
import {
  Icd10RecordsActionDispatchTypes,
  PatientRequestDispatchTypes,
  ReferralIntakeLastTab,
} from "../../../../../constants/AllPatientRecord";
import { GetPhysicianDetailsActionDispatchTypes } from "../../../../../constants/PhysicianDetails";
import {
  ServiceProviderActionDispatchType,
  ServiceRequestActionDispatchType,
} from "../../../../../constants/Service";
import {
  DocumentReviewActionDispatchTypes,
  DocumentTabType,
} from "../../../../../constants/Document";
import { PatientInformationActionDispatchTypesView } from "../../../../../constants/Patient";
import {
  BackClickAlertMessage,
  SaveDetailsAlertMessage,
} from "../../../../../constants/Patient";
import { UserDetailsPayload } from "../../../../../models/User";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";

export interface PropsFromState {
  tabList: AllPatientIntakeTabResponse;
  values: any;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  control: any;
  reset: any;
  resetField: UseFormResetField<any>;
  setValue: (id: any, value: any, boolean: any) => void;
  getValues: any;
  fields: any[];
  append: any;
  remove: (id: number) => void;
  icd10RecordsFields: any[];
  icd10RecordsAppend: any;
  icd10RecordsRemove: any;
  icd10Response: any;
  patientRequestDetail: PatientRequest | any;
  serviceRequestInfo: ServiceRequestModel | any;
  setCurrentTab: (id: number) => void;
  handleSubmit: () => any;
  emergencyContactValues: EmergencyContact[];
  clearErrors: (name: string) => void;
  useWatch: any;
  physicianDetailsResp: ReferralIntakePhysicianDetails;
  currentTab: number;
  formState: any;
  formError: any;
  serviceProvider: ServiceProviderPayload;
  submitReferral: () => void;
  resetPhysicianRecords: () => void;
  documentType: DocumentType[];
  user: UserDetailsPayload;
  enableTabNumber: number | null;
  authTypeId: number;
}
export interface PropsFromDispatch {
  getPatientTabList: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

function TabsPanelSection(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelSectionBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <PanelBox>
          <Box>{children}</Box>
        </PanelBox>
      )}
    </TabsPanelSectionBox>
  );
}

const PatientTab: React.FC<AllProps> = ({
  tabList,
  getPatientTabList,
  values,
  control,
  trigger,
  setValue,
  reset,
  resetField,
  getValues,
  fields,
  append,
  remove,
  icd10RecordsAppend,
  icd10RecordsRemove,
  icd10Response,
  icd10RecordsFields,
  patientRequestDetail,
  serviceRequestInfo,
  setCurrentTab,
  handleSubmit,
  emergencyContactValues,
  clearErrors,
  watch,
  physicianDetailsResp,
  useWatch,
  currentTab,
  formState,
  formError,
  serviceProvider,
  submitReferral,
  resetPhysicianRecords,
  documentType,
  user,
  enableTabNumber,
  authTypeId
}: AllProps) => {
  const { loading, response } = tabList;
  const [patientTabList, setTabList] = useState<Status[] | any[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [backClick, setBackClick] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(-1);
  const [isTabChange, setIsTabChange] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [disableButtons, setDisabledButtons] = useState(false);

  useEffect(() => {
    if (!loading) {
      getPatientTabList();
    }
    setDisabledButtons(enableTabNumber == 4 ? true : false);
    return () => {
      resetStates([
        PatientRequestDispatchTypes.PATIENT_REQUEST_RESET,
        Icd10RecordsActionDispatchTypes.ICD_10_RESET,
        GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET,
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET,
        ServiceRequestActionDispatchType.SERVICE_REQUEST_RESET,
        DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET,
        PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
        ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    if (response) {
      setTabList(response);
    }
  }, [response]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param event is mandatory
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    if (currentTab != tabList) {
      setOpenAlert(true);
    }
    setActiveTab(newValue);
  };

  const handleNextStep = () => {
    handleSubmit();
    setIsTabChange(false);
    setIsNextStep(true);
    scrollTop();
  };

  useEffect(() => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    const formErrorLength = !isEmpty(getValue(formError, "response", {}))
      ? Object.keys(getValue(formError, "response", {})).length
      : 0;

    if (
      isTabChange &&
      formError &&
      !formError.loading &&
      formError.error &&
      Object.keys(formError.error).length == 0 &&
      formErrorLength >= 0
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      if (currentTab == tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      setIsTabChange(false);
    } else if (
      isNextStep &&
      formError &&
      !formError.loading &&
      formError.error &&
      Object.keys(formError.error).length == 0 &&
      formErrorLength >= 0
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(currentTab + 1);
      }

      if (currentTab == tabList) {
        setCurrentTab(activeTab);
      }
      setIsNextStep(false);
    } else if (
      (isTabChange && formError && !formError.loading && !formError.error) ||
      (isNextStep && formError && !formError.loading && !formError.error)
    ) {
      if (currentTab < tabList && currentTab != tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      if (currentTab == tabList) {
        setCurrentTab(activeTab);
        setOpenAlert(false);
      }
      setIsTabChange(false);
      setIsNextStep(false);
    }
  }, [formError]);

  useEffect(() => {
    const tabList = response && length(response) ? response.length - 1 : 0;
    if (currentTab == tabList) {
      setCurrentTab(activeTab);
    }
  }, [activeTab]);

  const handleSave = () => {
    handleSubmit();
    setIsTabChange(true);
    setOpenAlert(false);
  };

  const handleBackClick = () => {
    setOpenAlert(false);
    setBackClick(false);
    if (currentTab != 0) {
      setCurrentTab(currentTab - 1);
    }
  };

  const handleBackStep = () => {
    if (currentTab == ReferralIntakeLastTab) {
      setCurrentTab(currentTab - 1);
    } else {
      setOpenAlert(true);
      setBackClick(true);
    }
  };

  const handleSubmitCreate = () => {
    submitReferral();
  };

  return (
    <Box sx={width100PCT} pt={"0.5rem"}>
      <Grid container sx={flexAlignCentre} p={"0.5rem 1rem"}>
        <Header />
        <Grid item sx={flexAllEnd} xs={4}>
          {currentTab != 0 && (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={handleBackStep}
              disabled={disableButtons}
            >
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[4]}
                color={others.otherColors[33]}
              >
                BACK
              </Typography>
            </Button>
          )}
          {currentTab ==
          (response && length(response) && response.length - 1) ? (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={() => handleSubmitCreate()}
            >
              <FaChevronRight />
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[4]}
                color={others.otherColors[33]}
              >
                SUBMIT AND CREATE
              </Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={handleNextStep}
              disabled={disableButtons}
            >
              <FaChevronRight />
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[4]}
                color={others.otherColors[33]}
              >
                SAVE AND PROCEED TO NEXT STEP
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {formState && Object.keys(formState.errors).length > 0 ? (
        <Typography
          fontWeight={fontWeight.Weight[3]}
          variant="subtitle2"
          color={colors.red[100]}
          pl={"1rem"}
        >
          Please fill in all mandatory fields
        </Typography>
      ) : null}
      <Box>
        {currentTab !== -1 && patientTabList && length(patientTabList) && (
          <RefTabs
            value={currentTab}
            onChange={handleChange}
            sx={{ marginLeft: "2.8rem" }}
          >
            {patientTabList.map((item, index) => {
              return (
                <RefTab
                  sx={DisabledTab(enableTabNumber)}
                  key={index}
                  {...a11yProps(index)}
                  label={item.status}
                />
              );
            })}
          </RefTabs>
        )}
      </Box>

      <TabsPanelSection value={currentTab} index={0}>
        <RequestDetail
          values={values}
          control={control}
          setValue={setValue}
          fields={fields}
          append={append}
          remove={remove}
          emergencyContactValues={emergencyContactValues}
          clearErrors={clearErrors}
          useWatch={useWatch}
          watch={watch}
          trigger={trigger}
          getValues={getValues}
          patientRequestDetail={patientRequestDetail}
          resetField={resetField}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={0}>
        <RequestAddressDetail
          getValues={getValues}
          reset={reset}
          values={values}
          control={control}
          trigger={trigger}
          patientRequestDetail={patientRequestDetail}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={0}>
        <Requestor
          values={values}
          control={control}
          setValue={setValue}
          patientRequestDetail={patientRequestDetail}
          clearErrors={clearErrors}
          reset={reset}
          watch={watch}
          getValues={getValues}
          injectedFrom={RequesterComponentInjectType.PRIOR_AUTH}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={0}>
        <OrderValidation
          values={values}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={1}>
        <ICD10
          icdvalues={values}
          control={control}
          setValue={setValue}
          getValues={getValues}
          fields={icd10RecordsFields}
          append={icd10RecordsAppend}
          remove={icd10RecordsRemove}
          icd10Response={icd10Response}
          reset={reset}
          clearErrors={clearErrors}
          watch={watch}
          formState={formState}
        />
      </TabsPanelSection>
      <TabsPanelSection value={currentTab} index={2}>
        <OrderingPhysician
          control={control}
          getValues={getValues}
          setValue={setValue}
          reset={reset}
          watch={watch}
          physicianDetailsResp={physicianDetailsResp}
          resetPhysicianRecords={resetPhysicianRecords}
          clearErrors={clearErrors}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={3}>
        <ServiceProvider
          control={control}
          getValues={getValues}
          reset={reset}
          serviceProviderDetails={serviceProvider}
          watch={watch}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={4}>
        <ServiceRequest
          setValue={setValue}
          serviceRequestInfo={serviceRequestInfo}
          control={control}
          values={values}
          clearErrors={clearErrors}
          user={user}
          disableSections={disableButtons}
          authTypeId={authTypeId}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={5}>
        <Documents
          control={control}
          setValue={setValue}
          values={values}
          documentType={documentType}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={5}>
        <DocumentsReview
          isAuthDetails={false}
          documentType={documentType}
          type={DocumentTabType.DOCUMENT}
        />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={6}>
        <ReviewRequestDetail />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={6}>
        <ReviewIcd10 />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={6}>
        <ReviewDisciplineRequest />
      </TabsPanelSection>

      <TabsPanelSection value={currentTab} index={6}>
        <ReviewDocumentationDetail />
      </TabsPanelSection>

      <Box sx={flexAlignCentre}>
        {formState && Object.keys(formState.errors).length > 0 ? (
          <Typography
            fontWeight={fontWeight.Weight[3]}
            variant="subtitle2"
            color={colors.red[100]}
            pl={"1rem"}
            sx={width100PCT}
          >
            Please fill in all mandatory fields
          </Typography>
        ) : null}
        <Grid item sx={flexAllEnd} xs={12} m={"1.5rem"} ml={"auto"}>
          {currentTab != 0 && (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={handleBackStep}
              disabled={disableButtons}
            >
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[4]}
                color={others.otherColors[33]}
              >
                BACK
              </Typography>
            </Button>
          )}
          {currentTab ==
          (response && length(response) && response.length - 1) ? (
            <Button
              variant="contained"
              sx={NextStepButton}
              onClick={() => handleSubmitCreate()}
            >
              <FaChevronRight />
              <Typography
                variant="body1"
                fontWeight={fontWeight.Weight[4]}
                color={others.otherColors[33]}
              >
                SUBMIT AND CREATE
              </Typography>
            </Button>
          ) : (
            <Permission
              controlId={`${PatientRecordsAccess.SAVE_AND_PROCEED_TO_NEXT_CREATE}||`}
            >
              <Button
                variant="contained"
                sx={SaveButton}
                onClick={() => handleNextStep()}
                disabled={disableButtons}
              >
                <FaChevronRight />
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[4]}
                  color={others.otherColors[33]}
                >
                  SAVE AND PROCEED TO NEXT STEP
                </Typography>
              </Button>
            </Permission>
          )}
        </Grid>
      </Box>
      <AlertDialog
        alertMessage={
          backClick ? BackClickAlertMessage : SaveDetailsAlertMessage
        }
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleSubmit={handleSave}
        back={backClick}
        handleBackClick={handleBackClick}
      />
    </Box>
  );
};

export default PatientTab;

import { isEmpty } from "lodash";
import { SnackbarProvider } from "notistack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import {
  ProductionBannerMessage,
  HeaderActionBorder,
  HeaderArrowDropDownIcon,
  HeaderAvatarGridContainer,
  HeaderCustomAvatarGrid,
  HeaderHelpOutlineOutlinedIcon,
  HeaderToolbar,
  HeaderUserDetails,
  LogoImg,
  LogoLink,
} from "../../styles/styles";
import { colors } from "../../../styles/colors";
import { getConcatStringOrNull } from "../../../utils";
import { currentDate, formatDate } from "../../../utils/DateTime";
import { DateFormats } from "../../../constants/Date";
import { flexAlignCentre } from "../../../styles/mui/styles/display";
import { height100PCT } from "../../../styles/mui/styles/heights";
import { inputType } from "../../../constants/Constants";
import { msalInstance } from "../../..";
import { SnackbarUtilsConfigurator } from "../../../utils/SnackbarProvider";
import { tangoLogo } from "../../../constants/ImagePaths";
import { UserDetailsPayload } from "../../../models/User";
import FileUpload from "../../../components/formComponents/FileUpload";
import fontWeight from "../../../styles/mui/fontWeight";
import Menus from "../../containers/Menus";
import { DocumentTabType } from "../../../constants/Document";
import { isProductionEnvironment } from "../../../constants/Config";

export interface PropsFromState {
  user: UserDetailsPayload;
}
export interface PropsFromDispatch {
  getLogin: () => void;
  putLoggedInUserDetails: (userId: string, payload: UserDetailsPayload) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const Header: React.FC<AllProps> = ({ user, getLogin }) => {
  const [userDetails, setUserDetails] = useState<UserDetailsPayload>(user);
  const [files, setFiles] = useState<any>([]);
  const [userImage, setUserImage] = useState<any>([]);
  // const [profileImageUrl, setProfileImageUrl] = useState("");//TBD not in use for now will integrate later after confirmation
  const account = msalInstance.getActiveAccount();

  useEffect(() => {
    getLogin();
  }, []);

  useEffect(() => {
    setUserDetails(user);
  }, [user]);

  // useEffect(() => { //TBD not in use for now will integrate later after confirmation
  //   if (userDetails) {
  //     const userProfile = ProfileImage(131); // TBD
  //     if (userProfile) {
  //       setProfileImageUrl(userProfile);
  //     }
  //   }
  // }, [userDetails]);

  const updateUserDetails = (user: UserDetailsPayload) => {
    // TBD
    if (userDetails) {
      user.userId = userDetails.userId > 0 ? userDetails.userId : 0;
      user.userPhoto = userDetails.userPhoto;
      user.fullName = userDetails.fullName;
      user.userName = userDetails.userName;
      user.email = userDetails.email;
      user.userPhotoBlobId = userDetails.userPhotoBlobId;
      user.designation = userDetails.designation;
      user.gender = userDetails.gender;
      user.userAddress = userDetails.userAddress;
      user.lastLogin = currentDate();
      user.userStatus = userDetails.isSuspended ? "Suspended" : "Active";
      user.isSuspended = userDetails.isSuspended;
      user.userRoles = userDetails.userRoles;
      user.userCreated = getConcatStringOrNull(userDetails.userCreated);
      user.userPhotoBlobId = 1;
      user.objectId = "";
      user.dateCreated = formatDate(
        new Date().toString(),
        DateFormats.YYYY_MM_DD
      );
      user.phoneNo = userDetails.phoneNo;
      if (userDetails.userId === 0 && !isEmpty(user.email)) {
        // putLoggedInUserDetails(user.userId.toString(), user); //TBD on image upload functionality
      }
    }
  };

  useEffect(() => {
    const user: UserDetailsPayload = {
      ...userDetails,
      dateCreated: "",
      userName: "",
      email: "",
      userStatus: "",
      userPhotoBlobId: 1,
      userId: 0,
      userCreated: null,
      firstName: "",
      lastName: "",
      lastLogin: "",
    };
    updateUserDetails(user);
  }, [userDetails]);

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <SnackbarUtilsConfigurator />
        <Paper elevation={3}>
          <HeaderToolbar disableGutters>
            <Grid container sx={height100PCT} pl={3} pr={3}>
              <Grid item xs={4}>
                <Box sx={height100PCT}>
                  <Grid container sx={height100PCT}>
                    <Grid item sx={flexAlignCentre} xs={12}>
                      <LogoLink to="/">
                        <LogoImg alt="Pronet 2.0 Logo" src={tangoLogo} />
                      </LogoLink>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} sx={ProductionBannerMessage}>
                {!isProductionEnvironment && <Typography
                  variant="h4"
                  fontWeight={fontWeight.Weight[6]}
                  color={colors.red[100]}
                >
                  NOT PRODUCTION
                </Typography>}
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  sx={HeaderAvatarGridContainer}
                  justifyContent="flex-end"
                >
                  <Grid item sx={HeaderCustomAvatarGrid} mr={1}>
                    <FileUpload
                      disabled={true}
                      files={files}
                      imageUrl={undefined} //TBD not in use for now will integrate later after confirmation
                      inputType={inputType.userProfile}
                      patientImageProps={userImage}
                      setFiles={setFiles}
                      setPatientImage={setUserImage}
                      type={DocumentTabType.DOCUMENT}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      sx={HeaderUserDetails}
                      justifyContent={"flex-end"}
                    >
                      <Grid item xs={"auto"}>
                        <Typography variant="h6" color={colors.fonts[6]}>
                          Welcome
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Typography
                          variant="h6"
                          color={colors.fonts[26]}
                          fontWeight={fontWeight.Weight[6]}
                        >
                          {account ? account.name : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <HeaderArrowDropDownIcon />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <HeaderActionBorder />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <HeaderHelpOutlineOutlinedIcon></HeaderHelpOutlineOutlinedIcon>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </HeaderToolbar>
          <Menus />
        </Paper>
      </SnackbarProvider>
    </>
  );
};

export default Header;

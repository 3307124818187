import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import {
  RemoveStaffingPatientDispatchTypes,
  StaffingAssignStaffDispatchTypes,
  StaffingListDispatchTypes,
  StaffingUnassignStaffDispatchTypes,
} from "../models/DispatchTypes/Staffing";
import {
  RemoveStaffingPatientActionDispatchTypes,
  StaffingAssignStaffActionDispatchTypes,
  StaffingListActionDispatchTypes,
  StaffingUnassignStaffActionDispatchTypes,
} from "../constants/Staffing";
import {
  RemoveStaffingPatient,
  StaffingAssignStaff,
  StaffingUnassignStaff,
} from "../models/Staffing";
import { configUrlForReferral } from "../constants/Config";
import {
  PostStaffingList,
  PostRemoveStaffingPatient,
  PostStaffingAssignStaff,
  PostStaffingUnassignStaff,
} from "../services/Staffing";
import { StaffingListResponse } from "../models/Api/Staffing";
import { get404InitialData } from "../utils";
import { StaffingQueue } from "../models/Filter";

const initialState: StaffingListResponse = {
  loading: false,
  response: {
    totalRows: -1,
    searchResponse: [],
  },
};

export const postStaffingList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    StaffingListDispatchTypes
  >
> = (payload: StaffingQueue) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: StaffingListActionDispatchTypes.STAFFING_LIST_INVOKE,
    });
    const response = get404InitialData(
      await PostStaffingList(configUrlForReferral, payload),
      initialState
    );
    dispatch({
      response: response.data,
      type: StaffingListActionDispatchTypes.STAFFING_LIST_SUCCESS,
    });
  };
};

export const postStaffingAssignStaff: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    StaffingAssignStaffDispatchTypes
  >
> = (payload: StaffingAssignStaff) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_INVOKE,
    });

    const response = await PostStaffingAssignStaff(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: StaffingAssignStaffActionDispatchTypes.STAFFING_ASSIGN_STAFF_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const postStaffingUnassignStaff: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    StaffingUnassignStaffDispatchTypes
  >
> = (payload: StaffingUnassignStaff) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_INVOKE,
    });

    const response = await PostStaffingUnassignStaff(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: StaffingUnassignStaffActionDispatchTypes.STAFFING_UNASSIGN_STAFF_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

export const postRemoveStaffingPatient: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    RemoveStaffingPatientDispatchTypes
  >
> = (payload: RemoveStaffingPatient) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_INVOKE,
    });
    const response = await PostRemoveStaffingPatient(
      configUrlForReferral,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
        case 404:
          dispatch({
            response,
            type: RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: RemoveStaffingPatientActionDispatchTypes.REMOVE_STAFFING_PATIENT_SUCCESS,
          });
          break;
        default:
          break;
      }
    }
  };
};

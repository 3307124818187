import { Reducer } from "redux";

import { DocumentSourceResponse } from "../models/Api/Master";
import { DocumentSourceDispatchTypes } from "../models/DispatchTypes/Master";
import { DocumentSourceActionDispatchTypes } from "../constants/Master";

const initialState: DocumentSourceResponse = {
  loading: false,
  response: [
    {
      documentSourceId: 0,
      documentSourceName: "",
    },
  ],
};

const DocumentSourceReducer: Reducer<
  DocumentSourceResponse,
  DocumentSourceDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DocumentSourceActionDispatchTypes.DOCUMENT_SOURCE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DocumentSourceActionDispatchTypes.DOCUMENT_SOURCE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DocumentSourceReducer;

import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  ListItem,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";
import { matchSorter } from "match-sorter";
import { debounce, isEmpty, isUndefined } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { FormInputText } from "../../../../components/formComponents/FormInputText";
import { IconNames } from "../../../../constants/FormInputIconName";
import {
  County,
  HealthPlanOption,
  MyPatient,
  PatientFilterOptions,
  PatientState,
  ServiceProviderOption,
  UrgencyStatusMaster,
} from "../../../../models/Patient";
import {
  CenterTypography,
  ProviderListItem,
  SelectControl,
} from "../../styles/style";
import { colors } from "../../../../styles/colors";
import { FormInputDropdown } from "../../../../components/formComponents/FormInputDropdown";
import {
  getConcatStringOrNull,
  getCurrentTabName,
  getListResult,
  getList,
  getValue,
  isNil,
  length,
  isExpedited,
} from "../../../../utils";
import { rules } from "../../../../utils/validation/Validation";
import fontWeight from "../../../../styles/mui/fontWeight";
import {
  dropdownFilter,
  FilterOptions,
  HealthPlanData,
  incompleteRequestFilterBy,
  Labels,
  myPatientListFilterBy,
  patientListFilterBy_2,
  urlBuildEnum,
  UrlBuildFilterByEnum,
  UserData,
} from "../../../../constants/PatientList";
import { CreatedByResponse } from "../../../../models/Api/Master";
import { NO_RECORD_FOUND } from "../../../../constants/ToastMessage";
import {
  Discipline,
  DisciplineSearch,
  ServiceProviderMaster,
} from "../../../../models/Service";
import { User } from "../../../../models/User";
import {
  AutoComplete,
  AutocompleteContainer,
  FaSearchIcon,
  PatientSearchLabel,
  Search,
  SearchButton,
  SearchButtonBox,
} from "../../styles/filter";
import { defaultServiceProviderMaster } from "../../../../reducers/ServiceProviderMaster";
import { defaultPatientState } from "../../../../reducers/PatientState";
import { FilterType } from "../../../../constants/Patient";
import { ServiceProviderMasterActionDispatchType } from "../../../../constants/Service";
import useMenuItem from "../../../../hooks/useMenuItem";
import {
  staffingListFilterBy,
  StageStatus,
} from "../../../../constants/Staffing";
import { matchState } from "../../../../utils/StateSearch";
import Permission from "../../../Permission";
import { MyPatientAccess } from "../../../../constants/Permission";
import {
  DisciplineType,
  HealthPlanMaster,
  ReferralServiceStatus,
  PriorAuthReferralSourceMaster,
  ServiceStatusReason,
  StaffingStatusMaster,
} from "../../../../models/Master";
import { FilterByState } from "../../../../constants/Master";
import { FilterModel } from "../../../../models/Api/Filter";
import { defaultCounty } from "../../../../reducers/County";
import { SearchFilterModel, Values } from "../../../../models/Filter";
import { UNASSIGNED_USER } from "../../../../constants/User";
import {
  PatientUrgencyStatus,
  ServiceStatus,
} from "../../../../constants/AllPatientRecord";
import { URGENCY_STATUS_EXPEDITED_ID } from "../../../../constants/Constants";
import AutocompleteMultiCheckInput from "../../../../components/formComponents/AutocompleteMultiCheckInput";
import { StyledPopperCheckBoxAutocomplete } from "../../../../components/styles/styles";

export interface PropsFromState {
  activeTab: number;
  availableStaff: CreatedByResponse;
  patientStateList: PatientState[];
  countyList: County[];
  userList: User[];
  staffingStatus: StaffingStatusMaster[];
  urgencyStatus: UrgencyStatusMaster[];
  referralServiceStatus: ReferralServiceStatus[];
  serviceRequestType: DisciplineType[];
  skill: Discipline[];
  healthPlanMaster: HealthPlanMaster[];
  serviceStatusReasonState: ServiceStatusReason[] | undefined;
  priorAuthReferralSource: PriorAuthReferralSourceMaster[];
}

export interface PropsFromDispatch {
  getPatientState: (selection: string) => void;
  getCounty: (selection: string) => void;
  getServiceProviderMaster: (selection: any) => void;
  resetStates: (actionType: string[]) => void;
  getStaffList: () => void;
  getStaffingStatusMasterResp: () => void;
  getUrgencyStatusMasterResp: () => void;
  getReferralServiceStatusMasterResp: (
    requiredIncompleteOption: boolean
  ) => void;
  getServiceStatusReasonMasterResp: (id: number) => void;
  getServiceRequestTypeMasterResp: () => void;
  getPriorAuthReferralSourceFacility: () => void;
  getSkillMasterResp: (payload: DisciplineSearch) => void;
  getHealthPlanMasterResp: () => void;
  storeFilterPayload: (type: number, payload?: any) => void;
}
export interface Prop {
  control: any;
  getValues: UseFormGetValues<any>;
  handleSearchValidate: (type: string) => void;
  patientRecords: MyPatient | null;
  setFieldValues: React.Dispatch<any>;
  setValue: (
    name: string,
    value: string | User[] | number[] | string[],
    boolean: any
  ) => void;
  values: any;
  watch: any;
  userName?: User | [];
  multipleAssignToStaffId?: User[] | [];
  setUserName?: (assignedTo: User) => void;
  setMultipleAssignToStaffId?: (assignedTo: User[]) => void;
  healthPlan: PatientFilterOptions | undefined;
  setHealthPlan: (healthPlan: PatientFilterOptions) => void;
  serviceProviderNPIList: ServiceProviderMaster[];
  setServiceProviderNPIList: (serviceProvider: ServiceProviderMaster[]) => void;
  setPatientStateSelected: (patientState: PatientState) => void;
  patientStateSelected: PatientState | null;
  countySelected: County | null;
  setCountySelected: (county: County) => void;
  setErrorFlag: React.Dispatch<React.SetStateAction<boolean>>;
  filterState: FilterModel;
  updatedFilterState: (payload: FilterModel) => void;
  setPatientFilterBy: (array: any[]) => void;
  setMyPatientFilterBy: (array: any[]) => void;
  patientFilterBy: any;
  myPatientFilterBy: Values[] | undefined
  setIncompleteFilterBy: (array: any[]) => void;
  incompleteFilterBy: Values[] | undefined
  setStaffingFilterBy: (array: any[]) => void;
  staffingFilterBy: Values[] | undefined
  setFilterPayload: (payload: SearchFilterModel) => void;
  filterPayload: SearchFilterModel;
  privateDutyAuthFilterBy: Values[] | undefined
  setPrivateDutyAuthFilterBy: (array: any[]) => void;
}
type Props = PropsFromState & PropsFromDispatch & Prop;

const MyPatientFilter = ({
  activeTab,
  control,
  getPatientState,
  getCounty,
  getServiceProviderMaster,
  resetStates,
  getStaffList,
  getValues,
  handleSearchValidate,
  patientStateList,
  countyList,
  serviceProviderNPIList,
  setValue,
  userList,
  values,
  watch,
  userName,
  multipleAssignToStaffId,
  setUserName,
  setMultipleAssignToStaffId,
  healthPlan,
  setHealthPlan,
  staffingStatus,
  urgencyStatus,
  serviceRequestType,
  skill,
  healthPlanMaster,
  serviceStatusReasonState,
  referralServiceStatus,
  getUrgencyStatusMasterResp,
  getStaffingStatusMasterResp,
  getReferralServiceStatusMasterResp,
  getServiceRequestTypeMasterResp,
  getServiceStatusReasonMasterResp,
  getSkillMasterResp,
  getHealthPlanMasterResp,
  patientStateSelected,
  setPatientStateSelected,
  countySelected,
  setCountySelected,
  setErrorFlag,
  setServiceProviderNPIList,
  storeFilterPayload,
  filterState,
  updatedFilterState,
  setPatientFilterBy,
  patientFilterBy,
  setFilterPayload,
  filterPayload,
  setMyPatientFilterBy,
  myPatientFilterBy,
  setIncompleteFilterBy,
  incompleteFilterBy,
  setStaffingFilterBy,
  staffingFilterBy,
  getPriorAuthReferralSourceFacility,
  priorAuthReferralSource,
  privateDutyAuthFilterBy,
  setPrivateDutyAuthFilterBy,
}: Props) => {
  const [filterByOption, setFilterByOption] = useState(FilterByState);
  const [serviceProvider, setServiceProvider] = useState<any>();
  const [stateList, setStateList] = useState(
    patientStateList ? patientStateList : []
  );
  const [patientCountyList, setPatientCountyList] = useState(
    countyList ? countyList : []
  );
  const [userManagementList, setUserManagementList] = useState(userList);

  const [filterBySelection, setFilterBySelection] = useState(values.filterBy);
  const { open, onOpen, onClose } = useMenuItem();

  const [healthPlanList, setHealthPlanList] = useState<PatientFilterOptions[]>([
    HealthPlanData,
  ]);
  const [urgencyDropDownId, setUrgencyDropDownId] = useState<number | null>(
    null
  );

  const getControl = () => {
    switch (activeTab) {
      case 0:
        return `|${MyPatientAccess.MY_PATIENT_SEARCH_READ}|`;
      case 1:
      case 4:
        return `|${MyPatientAccess.ALL_PENDING_SEARCH_READ}|`;
      case 2:
        return `|${MyPatientAccess.INCOMPLETE_SEARCH_READ}|`;
      case 3:
        return `|${MyPatientAccess.STAFFING_QUEUE_SEARCH_READ}|`;
    }
    return "";
  };

  useEffect(() => {
    if (filterBySelection === Labels.HEALTH_PLAN)
      setHealthPlanList(getListResult(filterByOption));
  }, [filterBySelection, filterByOption]);

  const getReferralStatusIdByName = (statusName: string) => {
    for (let i = 0; i < referralServiceStatus.length; i++) {
      if (referralServiceStatus[i].serviceStatus === statusName) {
        return referralServiceStatus[i].serviceStatusId;
      }
    }
    return 0;
  };
  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (!isNil(value) && !isNil(name)) {
        let filterListArray : Values[] | undefined = [];
        if (value.filterBy === "CREATEDBY") {
          filterListArray = incompleteFilterBy;
        } else if (activeTab == 0) {
          filterListArray = myPatientFilterBy;
        } else if (activeTab == 2) {
          filterListArray = incompleteFilterBy;
        } else if (activeTab == 1) {
          filterListArray = patientFilterBy;
        } else if (activeTab == 3) {
          filterListArray = staffingFilterBy;
        } else {
          filterListArray = privateDutyAuthFilterBy;
        }
        const Label = filterListArray && filterListArray.filter(
          (item: Values | undefined) =>
            !isEmpty(item) && item.value === value.filterBy
        );
        setFilterBySelection(Label && length(Label) ? Label[0].label : "");
        if (
          (value.filterBy === FilterOptions.ASSIGNED_TO ||
            value.filterBy === Labels.CREATED_BY) &&
          userManagementList.length === 1 &&
          userManagementList[0].userId === -1
        ) {
          getStaffList();
        } else if (
          name === "filterBy" &&
          value.filterBy !== "" &&
          value.filterBy !== FilterOptions.SERVICE_PROVIDER &&
          value.filterBy !== FilterOptions.GET_PATIENT_STATES &&
          value.filterBy !== FilterOptions.ASSIGNED_TO &&
          value.filterBy !== Labels.CREATED_BY
        ) {
          if (
            value.filterBy === FilterOptions.URGENCY &&
            urgencyStatus[0].urgencyId === 0
          ) {
            getUrgencyStatusMasterResp();
          } else if (
            value.filterBy === FilterOptions.STAFFING_STATUS &&
            staffingStatus[0].staffingStatusId === 0
          ) {
            getStaffingStatusMasterResp();
          } else if (
            value.filterBy === FilterOptions.REFERRAL_STATUS &&
            referralServiceStatus[0].serviceStatusId === -1
          ) {
            getReferralServiceStatusMasterResp(true);
          } else if (value.filterBy === FilterOptions.SERVICE_STATUS_REASON) {
            const statusName =
              activeTab === 1
                ? getValue(
                    filterState,
                    "response.allPending.serviceStatus.value"
                  )
                : activeTab === 4
                ? getValue(
                    filterState,
                    "response.privateDutyAuth.serviceStatus.value"
                  )
                : getValue(
                    filterState,
                    "response.myPatient.serviceStatus.value"
                  );
            const referralStatusId =
              statusName === ServiceStatus.PENDING
                ? 1
                : getReferralStatusIdByName(statusName);
            getServiceStatusReasonMasterResp(referralStatusId);
          } else if (
            value.filterBy === FilterOptions.SERVICE_REQUEST_TYPE &&
            serviceRequestType[0].serviceRequestTypeId === -1
          ) {
            getServiceRequestTypeMasterResp();
          } else if (
            value.filterBy === FilterOptions.DISCIPLINES &&
            skill[0].disciplineId === -1
          ) {
            const DisciplineSearchPayload: DisciplineSearch = {
              id: 0,
              type: 0,
              requiredTbd: true,
            };
            getSkillMasterResp(DisciplineSearchPayload);
          } else if (
            value.filterBy === FilterOptions.HEALTH_PLAN &&
            healthPlanMaster[0].healthPlanId === 0
          ) {
            getHealthPlanMasterResp();
          } else if (
            value.filterBy === FilterOptions.REFERRAL_SOURCE &&
            !length(priorAuthReferralSource)
          ) {
            getPriorAuthReferralSourceFacility();
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    getValues("filterBy"),
    userManagementList,
    patientFilterBy,
    myPatientFilterBy,
    staffingFilterBy,
    incompleteFilterBy,
    privateDutyAuthFilterBy,
  ]);
  useEffect(() => {
    if (filterBySelection === FilterOptions.URGENCY) {
      setFilterByOption(dropdownFilter(urgencyStatus));
    } else if (filterBySelection === Labels.STAFFING_STATUS) {
      setFilterByOption(dropdownFilter(staffingStatus));
    } else if (filterBySelection === Labels.STATUS) {
      setFilterByOption(
        getList(referralServiceStatus, {
          label: "serviceStatus",
          value: "serviceStatus",
          haveStatusReason: "haveStatusReason",
        })
      );
    } else if (
      filterBySelection === Labels.SERVICE_REQUEST_TYPE ||
      filterBySelection === Labels.AUTH_TYPE
    ) {
      setFilterByOption(dropdownFilter(serviceRequestType));
    } else if (filterBySelection === Labels.SKILL) {
      setFilterByOption(dropdownFilter(skill));
    } else if (filterBySelection === Labels.HEALTH_PLAN) {
      setFilterByOption(dropdownFilter(healthPlanMaster));
    } else if (filterBySelection === Labels.SERVICE_STATUS_REASON) {
      setFilterByOption(dropdownFilter(serviceStatusReasonState));
    } else if (filterBySelection === Labels.STAGE) {
      setFilterByOption(dropdownFilter(StageStatus));
    } else if (filterBySelection === Labels.REFERRAL_SOURCE) {
      setFilterByOption(
        getList(priorAuthReferralSource, {
          label: "facilityTypeName",
          value: "facilityTypeName",
        })
      );
    }
  }, [
    filterBySelection,
    urgencyStatus,
    staffingStatus,
    referralServiceStatus,
    serviceRequestType,
    skill,
    healthPlanMaster,
    serviceStatusReasonState,
    priorAuthReferralSource,
  ]);
  const proceedWithValidInput = () => {
    if (!isEmpty(getValues("firstName")) || !isEmpty(getValues("lastName"))) {
      if (
        getValues("firstName") === getValues("firstName").trim() &&
        getValues("lastName") === getValues("lastName").trim()
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleSearchClick = (type: string) => {
    if (proceedWithValidInput()) {
      setValue("pageNo", "1", true);
      handleSearchValidate(type);
      setErrorFlag(false);
    }
  };

  /**-------------------------------------------------------------------------------------- */

  useEffect(() => {
    const arr: any = [];
    let filterList: any = [];
    let tab: any = filterState && filterState.response;
    if (!isEmpty(filterState)) {
      switch (activeTab) {
        case 0:
          tab = filterState.response.myPatient;
          filterList = myPatientListFilterBy;
          break;
        case 1:
          tab = filterState.response.allPending;
          filterList = patientListFilterBy_2;
          break;
        case 2:
          tab = filterState.response.incompleteRequest;
          filterList = incompleteRequestFilterBy;
          break;
        case 3:
          tab = filterState.response.staffingQueue;
          filterList = staffingListFilterBy;
          break;
        case 4:
          tab = filterState.response.privateDutyAuth;
          filterList = patientListFilterBy_2;
          break;
        default:
          break;
      }
      filterList &&
        filterList.forEach((element: any) => {
          if (isEmpty(getValue(tab, `${element.paramLabel}`, ""))) {
            if (
              (activeTab === 0 &&
                element.paramLabel === "serviceStatusReason" &&
                tab &&
                !isEmpty(tab.serviceStatus)) ||
              element.paramLabel !== "serviceStatusReason" ||
              ((activeTab === 1 || activeTab === 4) &&
                element.paramLabel === "serviceStatusReason")
            ) {
              arr.push(element);
            }
          }
        });
      updatedFilterState(filterState);

      switch (activeTab) {
        case 0:
          setMyPatientFilterBy(arr);
          break;
        case 1:
          setPatientFilterBy(arr);
          break;
        case 2:
          setIncompleteFilterBy(arr);
          break;
        case 3:
          setStaffingFilterBy(arr);
          break;
        case 4:
          setPrivateDutyAuthFilterBy(arr);
          break;
        default:
          break;
      }
    }
  }, [filterState]);

  const updateFilterPayload = (
    filterBy: string,
    label: string,
    selectedValue: string,
    haveStatusReason?: boolean | null
  ) => {
    let payload: SearchFilterModel | any = filterPayload;
    if (!isEmpty(payload)) {
      if (activeTab === 0) {
        payload = payload.myPatient;
      } else if (activeTab === 1) {
        payload = payload.allPending;
      } else if (activeTab === 2) {
        payload = payload.incompleteRequest;
      } else if (activeTab === 3) {
        payload = payload.staffingQueue;
      } else if (activeTab === 4) {
        payload = payload.privateDutyAuth;
      }

      payload = {
        ...payload,
        [filterBy]: isUndefined(haveStatusReason)
          ? {
              label: label,
              value: selectedValue,
            }
          : {
              label: label,
              value: selectedValue,
              haveStatusReason: haveStatusReason,
            },
        pageNo: getValues("pageNo"),
        pageSize: getValues("pageSize"),
        sortColumn: getValues("sortColumn"),
        sortOrder:
          getValues("sortOrder").length !== 0 ? getValues("sortOrder") : "ASC",
      };
      setFilterPayload({
        ...filterPayload,
        [getCurrentTabName(activeTab)]: payload,
      });
      storeFilterPayload(1, {
        ...filterPayload,
        [getCurrentTabName(activeTab)]: payload,
      });
      setValue("filterBy", "", true);
    }
  };

  const getSwitchValue = (
    filterBy: string,
    selectedValue: any,
    haveStatusReason?: boolean | null
  ) => {
    if (!isEmpty(selectedValue)) {
      switch (filterBy) {
        case UrlBuildFilterByEnum.DISCIPLINE:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.CREATED_BY:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.HEALTH_PLAN:
        case UrlBuildFilterByEnum.HEALTH_PLAN_STAFFING:
          updateFilterPayload(
            activeTab === 3 ? "healthPlanName" : filterBy,
            selectedValue,
            selectedValue
          );
          break;
        case UrlBuildFilterByEnum.GET_PATIENT_STATE:
          updateFilterPayload(
            filterBy,
            matchState(selectedValue),
            matchState(selectedValue)
          );
          break;
        case UrlBuildFilterByEnum.ASSIGNED_TO_USER_ID:
        case UrlBuildFilterByEnum.ALL_PENDING_ASSIGNED_TO:
          updateFilterPayload(
            filterBy,
            selectedValue.map((user: { fullName: any }) => user.fullName),
            selectedValue.map((user: { userId: any }) => user.userId)
          );
          break;
        case UrlBuildFilterByEnum.REFERRAL_SERVICE_STATUS:
          updateFilterPayload(
            filterBy,
            selectedValue,
            selectedValue,
            haveStatusReason
          );
          break;
        case UrlBuildFilterByEnum.SERVICE_STATUS_REASON:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.URGENCY:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.SERVICE_REQUEST_TYPE:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.STAFFING_STATUS:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.SERVICE_PROVIDER:
          updateFilterPayload(
            filterBy,
            getServiceProviderLabel(selectedValue, serviceProviderNPIList),
            selectedValue
          );
          break;
        case UrlBuildFilterByEnum.COUNTY:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.STAGE:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        case UrlBuildFilterByEnum.REFERRAL_SOURCE:
          updateFilterPayload(filterBy, selectedValue, selectedValue);
          break;
        default:
          break;
      }
    }
  };

  const getServiceProviderLabel = (
    npi: string,
    list: ServiceProviderMaster[]
  ) => {
    if (!isNil(list)) {
      const providerSelected = list.filter(
        (s: ServiceProviderMaster) => s.providerNpi === npi
      );
      return length(providerSelected) ? providerSelected[0].providerName : "";
    } else {
      return values.dynamic;
    }
  };

  const addFilter = () => {
    const dynamicValue = getValues("filterBy").toString();
    const indexOfFilter = Object.keys(urlBuildEnum).indexOf(dynamicValue);
    const propName = Object.values(urlBuildEnum)[indexOfFilter];
    const selectedObject =
      referralServiceStatus &&
      referralServiceStatus.filter(
        (item: ReferralServiceStatus) =>
          item.serviceStatus === getValues("dynamic")
      );
    getSwitchValue(
      propName,
      getValues("dynamic"),
      getValue(selectedObject, "[0].haveStatusReason")
    );
  };

  /**-------------------------------------------------------------------------------------- */

  const handleErrorMessage = () => {
    setErrorFlag(false);
  };

  useEffect(() => {
    setUserManagementList(userList);
  }, [filterBySelection, userList]);

  useEffect(() => {
    !isNil(patientStateList)
      ? setStateList(patientStateList)
      : setStateList(defaultPatientState);
    !isNil(countyList)
      ? setPatientCountyList(countyList)
      : setPatientCountyList(defaultCounty);
  }, [patientStateList, countyList]);

  const handleKeyStrokes = (flag = false) => {
    if (flag) {
      setValue("dynamic", "", true);
      setServiceProvider(defaultServiceProviderMaster[0]);
      setPatientStateSelected(defaultPatientState[0]);
      setCountySelected(defaultCounty[0]);
      setHealthPlan(HealthPlanData);
      switch (activeTab) {
        case 1:
          setValue("assignedToStaffId", "", true);
          if (setMultipleAssignToStaffId) setMultipleAssignToStaffId([]);
          break;
        case 2:
          setValue("assignedToStaffId", "", true);
          setValue("assignedUserId", "", true);
          setValue("healthPlanName", "", true);
          setValue("urgencyStatus", "", true);
          setValue("userCreated", "", true);
          setValue("state", "", true);
          if (setUserName) setUserName(UserData);
          if (setMultipleAssignToStaffId) setMultipleAssignToStaffId([]);
          break;
        case 3:
          setValue("healthPlanName", "", true);
          setValue("staffingStatus", "", true);
          setValue("state", "", true);
          setValue("assignedToStaffId", "", true);
          setValue("assignedToStaffName", "", true);
          setValue("serviceProvider", "", true);
          setValue("county", "", true);
          if (setUserName) setUserName(UserData);
          if (setMultipleAssignToStaffId) setMultipleAssignToStaffId([]);
          break;
        default:
          break;
      }
    }
    setErrorFlag(false);
    urgencyDropDownChecker();
  };
  const urgencyDropDownChecker = () => {
    setTimeout(() => {
      if (
        getValues("dynamic") === PatientUrgencyStatus.EXPEDITED &&
        filterBySelection === FilterOptions.URGENCY
      ) {
        return setUrgencyDropDownId(URGENCY_STATUS_EXPEDITED_ID);
      }
      return setUrgencyDropDownId(null);
    }, 0);
  };
  const handleStateInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isState: boolean
  ) => {
    debouncedSearch(event, value, reason, isState);
  };

  const debouncedSearch = debounce((event, value, reason, isState) => {
    handlePatientState(event, value, reason, isState);
  }, 500);

  const handlePatientState: any = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isState: boolean
  ) => {
    if (isState) {
      if (!isNil(value) && !isNil(event)) {
        const updatedValue = matchState(getConcatStringOrNull(value));
        if (updatedValue && reason === "input") {
          onOpen();
          getPatientState(updatedValue);
        }
      }
    }
  };

  const handleCountyInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isCounty: boolean
  ) => {
    debouncedCountySearch(event, value, reason, isCounty);
  };

  const debouncedCountySearch = debounce((event, value, reason, isCounty) => {
    handleCountyChange(event, value, reason, isCounty);
  }, 500);

  const handleCountyChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isCounty: boolean
  ) => {
    if (isCounty) {
      if (!isNil(value) && !isNil(event)) {
        const updatedValue = value;
        if (updatedValue && reason === "input") {
          onOpen();
          getCounty(updatedValue);
        }
      }
    }
  };

  const debouncedServiceProviderSearch = debounce(
    (event, value, reason, isServiceProvider) => {
      handleServiceProviderName(event, value, reason, isServiceProvider);
    },
    500
  );

  const handleServiceProviderInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isServiceProvider: boolean
  ) => {
    debouncedServiceProviderSearch(event, value, reason, isServiceProvider);
  };
  const handleServiceProviderName: any = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
    isServiceProvider: boolean
  ) => {
    if (isServiceProvider) {
      if (value) {
        if (value.length <= 2) {
          onClose();
          resetStates([
            ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_RESET,
          ]);
        } else {
          if (value.length >= 3 && !isNil(event) && reason === "input") {
            onOpen();
            getServiceProviderMaster(value);
          }
        }
      }
    }
  };

  const handleAssignedToMultiSelect = (_e: any, value: User[]) => {
    if (isEmpty(value)) {
      if (setMultipleAssignToStaffId) setMultipleAssignToStaffId([]);
    }
    if (!isEmpty(value)) {
      if (setMultipleAssignToStaffId) setMultipleAssignToStaffId(value);
    }
    setValue("dynamic", value, true);
  };

  const onSelection = (_e: SyntheticEvent<Element, Event>, value: User) => {
    onClose();
    setValue("assignedToStaffId", value.userId.toString(), true);
    setValue("dynamic", value.fullName, true);
  };

  const onSelectionServiceProvider = (
    _e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setServiceProvider(value);
    setValue("dynamic", value.providerNpi.toString(), true);
    onClose();
  };

  const onSelectionState = (_e: SyntheticEvent<Element, Event>, value: any) => {
    setValue(
      "dynamic",
      matchState(getConcatStringOrNull(value.patientState)),
      true
    );
    onClose();
  };

  const onSelectionCounty = (
    _e: SyntheticEvent<Element, Event>,
    value: County
  ) => {
    setCountySelected(value);
    setValue("dynamic", value.county, true);
    onClose();
  };

  const onHealthPlanSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    onClose();
    setValue("dynamic", value.label, true);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === " " && !event.target.value.trim()) {
      event.preventDefault();
    }
  };
  const keyGenerator = (isServiceProvider: boolean, isState: boolean) => {
    if (isServiceProvider) return ""; //TBD
    if (isState) return ""; //TBD
    return !isNil(userManagementList) && length(userManagementList)
      ? userManagementList[0].userId
      : "";
  };

  const isOptionEqualToValue = (type: any, option: any, value: any) => {
    switch (type) {
      case FilterType.SERVICE_PROVIDER:
        return (
          getValue(option, "providerNpi") === getValue(value, "providerNpi")
        );
      case FilterType.STATE:
        return (
          getValue(option, "patientState") === getValue(value, "patientState")
        );
      case FilterType.HEALTH_PLAN:
        return getValue(option, "value") === getValue(value, "value");
      case FilterType.ASSIGN_TO:
        return getValue(option, "userId", "");
      default:
        return false;
    }
  };

  /*autocomplete functions start*/
  const autocompleteOptions = (
    type: number,
    isOpen?: boolean,
    option?: any,
    onChange?: boolean,
    value?: boolean,
    sorter?: boolean,
    options?: any,
    inputValue?: any
  ) => {
    switch (type) {
      case FilterType.SERVICE_PROVIDER:
        if (option) return option && option.providerName;
        if (onChange) return onSelectionServiceProvider;
        if (value) {
          return serviceProvider;
        }
        if (sorter)
          return matchSorter(options, inputValue, {
            keys: ["providerName", "providerNpi"],
          });

        return !isOpen
          ? serviceProviderNPIList
          : length(serviceProviderNPIList) &&
              serviceProviderNPIList[0].providerName != "";
      case FilterType.STATE:
        if (option) return option && option.patientState;
        if (onChange) return onSelectionState;
        if (value) {
          return patientStateSelected;
        }
        if (sorter)
          return matchSorter(
            options,
            matchState(getConcatStringOrNull(inputValue)),
            { keys: ["patientState"] }
          );

        return !isOpen
          ? stateList
          : length(stateList) && stateList[0].patientState != "";

      case FilterType.COUNTY:
        if (option) {
          return option && option.county;
        }
        if (onChange) {
          return onSelectionCounty;
        }
        if (value) {
          return countySelected;
        }
        if (sorter)
          return matchSorter(options, inputValue, { keys: ["county"] });

        return !isOpen
          ? patientCountyList
          : length(patientCountyList) && patientCountyList[0].county != "";
      case FilterType.HEALTH_PLAN:
        if (option) return option && option.label;
        if (onChange) return onHealthPlanSelection;
        if (value) {
          return healthPlan;
        }
        if (sorter)
          return matchSorter(options, inputValue, { keys: ["label"] });

        return !isOpen
          ? healthPlanList
          : length(healthPlanList) && healthPlanList[0].label != "";
      default:
        if (option) return option && option.fullName;
        if (onChange) return onSelection;
        if (value) return userName;
        if (sorter) {
          const sortedList = matchSorter(options, inputValue, {
            keys: ["fullName"],
          });
          const addedObject = sortedList.findIndex(
            (item: any) => item.userId === -1
          );
          if (addedObject !== -1) {
            sortedList.splice(addedObject, 1);
          }
          sortedList.unshift(UNASSIGNED_USER);
          return sortedList;
        }
        return !isOpen
          ? [UNASSIGNED_USER, ...userManagementList]
          : length(userManagementList) &&
              ((userManagementList[0].userId > 0) as any);
    }
  };

  const autocompleteFocus = () => {
    getValue(patientStateSelected, "patientState", "").length === 0 &&
      setStateList(defaultPatientState);
    if (getValue(serviceProvider, "providerName", "").length === 0) {
      setServiceProviderNPIList(defaultServiceProviderMaster);
      resetStates([
        ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_RESET,
      ]);
    }
    getValue(countySelected, "county", "").length === 0 &&
      setPatientCountyList(defaultCounty);

    return (
      (filterBySelection === Labels.ASSIGNED_TO ||
        filterBySelection === Labels.CREATED_BY_SMALL) &&
      onOpen &&
      onOpen()
    );
  };

  const getKey = (
    option: ServiceProviderOption | PatientState | HealthPlanOption | User,
    type: number
  ) => {
    switch (type) {
      case FilterType.SERVICE_PROVIDER:
        return uuidv4();
      case FilterType.STATE:
        return getValue(option, "patientState", "");
      case FilterType.HEALTH_PLAN:
        return getValue(option, "value", "");
      case FilterType.ASSIGN_TO:
        return getValue(option, "userId", "");
    }
    return "";
  };

  /**end*/
  const autocompleteRenderer = (
    isServiceProvider: boolean,
    isCounty: boolean,
    isState: boolean,
    isHealthPlan: boolean
  ) => {
    const type = isServiceProvider
      ? FilterType.SERVICE_PROVIDER
      : isCounty
      ? FilterType.COUNTY
      : isState
      ? FilterType.STATE
      : isHealthPlan
      ? FilterType.HEALTH_PLAN
      : FilterType.ASSIGN_TO;
    return (
      <Grid item sx={AutocompleteContainer}>
        <Box sx={PatientSearchLabel}>
          <Typography
            variant="body1"
            color={colors.fonts[2]}
            fontWeight={fontWeight.Weight[2]}
          >
            {filterBySelection}:
          </Typography>
        </Box>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <Autocomplete
            sx={AutoComplete}
            freeSolo
            forcePopupIcon={true}
            options={autocompleteOptions(type, false)}
            getOptionLabel={(option: any) =>
              autocompleteOptions(type, false, option)
            }
            open={open}
            onOpen={() => {
              autocompleteOptions(type, true) && onOpen();
            }}
            onClose={() => onClose()}
            onBlur={() => {
              onClose && onClose();
            }}
            onFocus={() => {
              autocompleteFocus();
            }}
            PopperComponent={StyledPopperCheckBoxAutocomplete}
            id="auto-complete"
            autoComplete
            disableClearable
            clearIcon={""}
            onChange={autocompleteOptions(type, false, false, true)}
            onInputChange={(event, value, reason) => {
              isState
                ? handleStateInputChange(event, value, reason, isState)
                : isCounty
                ? handleCountyInputChange(event, value, reason, isCounty)
                : handleServiceProviderInputChange(
                    event,
                    value,
                    reason,
                    isServiceProvider
                  );
            }}
            includeInputInList
            filterOptions={(options: any, { inputValue }) =>
              autocompleteOptions(
                type,
                false,
                false,
                false,
                false,
                true,
                options,
                inputValue
              )
            }
            value={autocompleteOptions(type, false, false, false, true)}
            key={keyGenerator(isServiceProvider, isState)}
            noOptionsText={NO_RECORD_FOUND}
            renderInput={(params) => {
              params.inputProps.maxLength = 50;
              return (
                <Box sx={Search}>
                  <TextField
                    {...params}
                    variant="standard"
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <FaSearchIcon />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                </Box>
              );
            }}
            renderOption={(props, option) => {
              if (isServiceProvider) {
                return (
                  <ListItem
                    {...props}
                    key={getKey(option, type)}
                    sx={ProviderListItem}
                  >
                    {option.providerNpi + " - "}
                    {option.providerName}
                  </ListItem>
                );
              }

              return (
                <Typography
                  {...props}
                  key={getKey(option, type)}
                  variant="h6"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[25]}
                  pb={"0.2rem"}
                >
                  {autocompleteOptions(type, false, option)}
                </Typography>
              );
            }}
            isOptionEqualToValue={(option, value) =>
              isOptionEqualToValue(type, option, value)
            }
            data-testid="myPatients-autocomplete"
          />
        </FormControl>
      </Grid>
    );
  };

  const checkDynamicRender = () => {
    return (
      filterByOption &&
      length(filterByOption) &&
      filterByOption[0].value != "" &&
      filterBySelection != "" &&
      filterBySelection != Labels.ASSIGNED_TO &&
      filterBySelection != Labels.STATE &&
      filterBySelection != Labels.CREATED_BY_SMALL &&
      filterBySelection != FilterOptions.FILTER_SERVICE_PROVIDER &&
      filterBySelection != Labels.HEALTH_PLAN &&
      filterBySelection != Labels.COUNTY
    );
  };

  return (
    <>
      <Box>
        <Typography
          color={colors.black[3]}
          fontWeight={fontWeight.Weight[5]}
          variant="h6"
        >
          FILTER / SEARCH RECORDS:
        </Typography>
        <Grid container mt={"1rem"}>
          <Grid item xs={12} container>
            <Grid item xs={2}>
              <FormControl sx={SelectControl} variant="standard">
                <FormInputText
                  control={control}
                  helper={rules.validFirstName}
                  iconName={IconNames.FA_SEARCH}
                  isOnlyIcon={true}
                  label="First Name:"
                  name="firstName"
                  onKeyPress={() => handleKeyStrokes}
                  textLength={64}
                  handleChange={handleErrorMessage}
                  inputType="onlyTextSpaceAndHyphen"
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={SelectControl} variant="standard">
                <FormInputText
                  control={control}
                  helper={rules.validLastName}
                  iconName={IconNames.FA_SEARCH}
                  isOnlyIcon={true}
                  label="Last Name:"
                  name="lastName"
                  onKeyPress={() => handleKeyStrokes}
                  handleChange={handleErrorMessage}
                  textLength={64}
                  inputType="onlyTextSpaceAndHyphen"
                />
              </FormControl>
            </Grid>

            <Grid item xs={1} sx={CenterTypography}>
              <Typography
                color={colors.black[1]}
                fontWeight={fontWeight.Weight[6]}
                variant="subtitle2"
              >
                AND
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={SelectControl} variant="standard">
                <FormInputDropdown
                  onChangeHandler={() => handleKeyStrokes(true)}
                  label="Filter By"
                  list={
                    activeTab === 0
                      ? myPatientFilterBy
                      : activeTab === 2
                      ? incompleteFilterBy
                      : activeTab === 1
                      ? patientFilterBy
                      : activeTab === 3
                      ? staffingFilterBy
                      : privateDutyAuthFilterBy
                  }
                  control={control}
                  name="filterBy"
                />
              </FormControl>
            </Grid>
            <Grid item xs={isEmpty(filterBySelection) ? 0 : 2.5}>
              {checkDynamicRender() ? (
                <FormControl sx={SelectControl} variant="standard">
                  <FormInputDropdown
                    control={control}
                    label={filterBySelection}
                    list={filterByOption}
                    name="dynamic"
                    onChangeHandler={handleKeyStrokes}
                    InputStyle={isExpedited(urgencyDropDownId)}
                  />
                </FormControl>
              ) : (
                <></>
              )}
              {(filterBySelection === FilterOptions.FILTER_SERVICE_PROVIDER ||
                filterBySelection === Labels.COUNTY ||
                filterBySelection === Labels.STATE ||
                filterBySelection === "Created By" ||
                filterBySelection === Labels.HEALTH_PLAN) &&
                autocompleteRenderer(
                  filterBySelection === FilterOptions.FILTER_SERVICE_PROVIDER,
                  filterBySelection === Labels.COUNTY,
                  filterBySelection === Labels.STATE,
                  filterBySelection === Labels.HEALTH_PLAN
                )}
              {filterBySelection === Labels.ASSIGNED_TO && (
                <Grid item sx={AutocompleteContainer}>
                  <AutocompleteMultiCheckInput
                    options={autocompleteOptions(FilterType.ASSIGN_TO, false)}
                    sx={AutoComplete}
                    handleChange={handleAssignedToMultiSelect}
                    value={multipleAssignToStaffId}
                    control={control}
                    name={"dynamic"}
                    labelKey={"fullName"}
                    label={filterBySelection}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={2.5} sx={SearchButtonBox}>
              <Permission controlId={getControl()} passThrough>
                {(isDisabled: boolean) => (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      sx={SearchButton}
                      onClick={() => addFilter()}
                      disabled={!isDisabled}
                    >
                      Add+
                    </Button>
                    <Button
                      variant="contained"
                      sx={SearchButton}
                      onClick={() => handleSearchClick("search")}
                      disabled={!isDisabled}
                    >
                      SEARCH
                    </Button>
                  </>
                )}
              </Permission>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyPatientFilter;

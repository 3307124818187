import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { ApplicationState } from "../../../../../store/ApplicationState";
import LevelOfFunction from "../components/LevelOfFunction";
import { LevelOfFunctionDispatchTypes } from "../../../../../models/DispatchTypes/Notes";
import { getLevelOfFunction } from "../../../../../actions/Notes";

const mapStateToProps = ({
  levelOfFunctionState,
  SaveDisciplineLevelNotesState,
  UpdateDisciplineLevelNotesState,
}: ApplicationState) => ({
  LevelOfFunctionRecordsData: levelOfFunctionState,
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNotesState,
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNotesState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<LevelOfFunctionDispatchTypes>
  >
) => {
  return {
    getLevelOfFunctionRecords: (referralId: number) => {
      dispatch(getLevelOfFunction(referralId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelOfFunction);

import { useState, useEffect, useRef, MutableRefObject } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { useParams } from "react-router-dom";
import {
  Divider,
  Grid,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Tooltip,
} from "@mui/material";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  LofContainerStyle,
  LOFInputDevider,
  LofFirstTableCellStickyHeaderLeftSecond,
  LofTableMain,
  LofSecondTableCellStickyHeaderLeftSecond,
  LofTableMainHead,
  LofTableCellHeaderLeft,
  LofTableMainStyle,
  LofHeaderBoxStyle,
  LofTableCellWidth,
  LofLatestColumn,
  LofTableCellBorderBottom,
  LofScrollLableTableCellStyle,
  LofLatestTableCellWidth,
  LoffirstFixedTableCellBorder,
  LofTableCellBorder,
  FaRightArrowIcon,
  LofLofHeaderTitleStyle,
  LofRowColumnsStyleTooltip,
} from "../../../../../components/formComponents/styles/style";
import {
  LevelOfFunctionRecordsResponse,
  SaveDisciplineLevelNote,
  UpdateDisciplineLevelNote,
} from "../../../../../models/Api/Notes";
import { LofOuterBodyGrid } from "../styles/GeneralInfo";
import {
  DefaultLOF,
  LofHeaderNames,
  LOFLabelNames,
} from "../../../../../constants/Constants";
import {
  CombinedLofEntry,
  DisciplineName,
  LevelOfFunctionRecord,
  NoteEntryDateTime,
} from "../../../../../models/Notes";
import {
  generateColumns,
  getNoteEntryValue,
  isNil,
  convertToObject,
  getColumnsForElement,
  getChoicesForElement,
  normalizeData,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import { isEmpty } from "lodash";
import { LevelOfFunctionMaxCharLength } from "../../../../../constants/Notes";

export interface PropsState {
  LevelOfFunctionRecordsData: LevelOfFunctionRecordsResponse;
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNote;
}

export interface PropsFromDispatch {
  getLevelOfFunctionRecords: (referralId: number) => void;
}

type AllProps = PropsState & PropsFromDispatch;

const LevelOfFunction: React.FC<AllProps> = ({
  LevelOfFunctionRecordsData,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataresp,
  getLevelOfFunctionRecords,
}: AllProps) => {
  const [levelOfFunctionHeader, setLevelOfFunctionHeader] = useState<
    CombinedLofEntry[]
  >([]);
  const [newColumns, setNewColumns] = useState<any>(DefaultLOF);
  const [secondColumnDocType, setSecondColumnDocType] = useState<number>(0);
  const [secondColumnOrderNo, setSecondColumnOrderNo] = useState<number>(0);
  const [indexForRecordWithTypeTwo, setIndexForRecordWithTypeTwo] =
    useState<number>(0);
  const [localPlofOptions, setLocalPlofOptions] = useState<any[]>([]);
  const [localLivingstatusOptions, setLocalLivingstatusOptions] = useState<
    any[]
  >([]);
  const [localAvailableAssistOptions, setLocalAvailableAssistOptions] =
    useState<any[]>([]);

  const [localCombinedDataOptions, setLocalCombinedDataOptions] = useState<
    any[]
  >([]);
  const [localAssistiveDeviceData, setLocalAssistiveDeviceData] = useState<
    any[]
  >([]);

  const containerRef = useRef<any>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(containerRef);
  const { response: LevelOfFunctionRsponse } = LevelOfFunctionRecordsData;
  const { authNo } = useParams();

  const columns = generateColumns(
    LevelOfFunctionRsponse ? LevelOfFunctionRsponse.length : 0
  );

  const getTexts = (pages: any[]) => {
    const plofColumns = getColumnsForElement(pages, LOFLabelNames.PLOF);
    const choices = plofColumns[0]?.choices || [];
    const uniqueChoices = Array.from(
      new Map(choices.map((choice: any) => [choice.value, choice])).values()
    );

    setLocalPlofOptions((prevChoices) => {
      const allChoices = [...prevChoices, ...uniqueChoices];

      return Array.from(
        new Map(allChoices.map((choice) => [choice.value, choice])).values()
      );
    });
    const LivingstatusColumns = getChoicesForElement(
      pages,
      LOFLabelNames.LIVING_STATUS_LABEL
    );
    const normilizedLvingStatusData = normalizeData(LivingstatusColumns);

    setLocalLivingstatusOptions((prevChoices) => {
      const allChoices = [...prevChoices, ...normilizedLvingStatusData];

      return Array.from(
        new Map(allChoices.map((choice) => [choice.value, choice])).values()
      );
    });
    const AvailableAssitColumns = getChoicesForElement(
      pages,
      LOFLabelNames.AVAILABLE_ASSISTANCE_LABEL
    );
    const tempAissitiveData = getChoicesForElement(
      pages,
      LOFLabelNames.PRIOR_ASSISTIVE_DEVICES
    );
    setLocalAssistiveDeviceData(tempAissitiveData);

    const normalizedAvailbaleAssitData = normalizeData(AvailableAssitColumns);

    setLocalAvailableAssistOptions((prevChoices) => {
      const allChoices = [...prevChoices, ...normalizedAvailbaleAssitData];

      return Array.from(
        new Map(allChoices.map((choice) => [choice.value, choice])).values()
      );
    });

    const inptCurrentLOFColumns = getColumnsForElement(
      pages,
      LOFLabelNames.INPT_CURRENT_LOF
    );

    const getCurrentColumnsClof = inptCurrentLOFColumns.filter(
      (data: any) => data.name === LOFLabelNames.CLOF
    );

    const normalizedData = normalizeData(getCurrentColumnsClof[0].choices);

    setLocalCombinedDataOptions((prevChoices) => {
      const allChoices = [...prevChoices, ...normalizedData];

      return Array.from(
        new Map(allChoices.map((choice) => [choice.value, choice])).values()
      );
    });
  };

  useEffect(() => {
    if (!isNil(LevelOfFunctionRsponse)) {
      const filteredLofResponse = LevelOfFunctionRsponse.filter(
        (data: any) => data.documentationType === 1
      );

      filteredLofResponse.forEach((item: any) => {
        getTexts(JSON.parse(item.noteEntryJson).pages);
      });
    }
  }, [LevelOfFunctionRsponse]);

  useEffect(() => {
    if (
      !isNil(saveDisciplineLevelNotesDataResp) &&
      saveDisciplineLevelNotesDataResp.response.disciplineNoteId !== -1
    ) {
      getLevelOfFunctionRecords(Number(authNo));
    }
    if (
      !isNil(updateDisciplineLevelNoteDataresp) &&
      updateDisciplineLevelNoteDataresp.response.disciplineNoteId !== -1
    ) {
      getLevelOfFunctionRecords(Number(authNo));
    }
  }, [saveDisciplineLevelNotesDataResp, updateDisciplineLevelNoteDataresp]);

  const extractValues = (
    dataArray: LevelOfFunctionRecord[],
    keyPath: string,
    key: string,
    valueMap?: any[],
    identityKey?: string
  ) => {
    const values: any = [];

    const map = Array.isArray(valueMap)
      ? Object.fromEntries(
          valueMap.map(({ value, text }: any) => [value, text])
        )
      : {};

    dataArray.forEach((item: any) => {
      const noteEntry =
        item.noteEntryJson !== "" ? JSON.parse(item.noteEntryJson) : "";
      const data = getNoteEntryValue(noteEntry, keyPath);
      if (Array.isArray(data)) {
        const mappedData =
          data
            .map((value) => {
              if (typeof value === "object" && value.text) {
                return value.text;
              } else if (typeof value === "string") {
                return map[value] || value;
              } else {
                return value;
              }
            })
            .join(", ") || "-";
        values.push(mappedData);
      } else {
        if (data) {
          if (identityKey) {
            if (valueMap) {
              const value = data[identityKey];
              values.push(valueMap[value] || "-");
            }
          } else {
            values.push(data ? data : "-");
          }
        } else {
          values.push("-");
        }
      }
    });
    return { [key]: values };
  };
  const [levelOfFunction, setLevelOfFunction] = useState<any>(DefaultLOF);
  useEffect(() => {
    setLevelOfFunction(DefaultLOF);
  }, [newColumns]);

  const updateState = (
    _parentObj: any,
    index1: number,
    _objChild: any,
    index2: number,
    newValues: any
  ) => {
    setNewColumns((prevData: any) => {
      const updatedValues = { ...prevData };
      !isEmpty(newValues[_objChild.key]) &&
        newValues[_objChild.key].forEach((value: any, index3: any) => {
          if (_objChild.key in newValues) {
            const columnKey = `column${index3 + 1}`;
            if (!isEmpty(updatedValues[index1])) {
              updatedValues[index1].LOF[index2][columnKey] = value;
            }
          }
        });
      return {
        ...prevData,
      };
    });
  };

  useEffect(() => {
    if (LevelOfFunctionRsponse) {
      const checkSecondSticyColumn =
        LevelOfFunctionRsponse && LevelOfFunctionRsponse[1];
      const firstRecordtWithTypeTwoIndex =
        LevelOfFunctionRsponse &&
        LevelOfFunctionRsponse.findIndex(
          (item) => item.documentationType === 2
        );
      setIndexForRecordWithTypeTwo(firstRecordtWithTypeTwoIndex);
      const documentationType =
        LevelOfFunctionRsponse.length > 1
          ? checkSecondSticyColumn.documentationType
          : 0;
      const orderNumber =
        LevelOfFunctionRsponse.length > 1
          ? checkSecondSticyColumn.orderNumber
          : 0;
      setSecondColumnDocType(documentationType);
      setSecondColumnOrderNo(orderNumber);
      const tempDisciplineNamesData: DisciplineName[] =
        LevelOfFunctionRsponse.map((item) => ({
          disciplineName: item.disciplineName,
        }));
      const tempNoteEntryDateTimeArray: NoteEntryDateTime[] =
        LevelOfFunctionRsponse.map((item) => ({
          dateCreated: formatDate(item.dateCreated, DateFormats.MM_DD_YYYY),
        }));
      const tempDocumentationType = LevelOfFunctionRsponse.map((item) => ({
        documentationType: item.documentationType,
      }));
      const temporderNumber = LevelOfFunctionRsponse.map((item) => ({
        orderNumber: item.orderNumber,
      }));
      const combinedArray: CombinedLofEntry[] = tempDisciplineNamesData.map(
        (item, index) => ({
          disciplineName: item.disciplineName,
          dateCreated:
            formatDate(
              tempNoteEntryDateTimeArray[index]?.dateCreated,
              DateFormats.MM_DD_YYYY
            ) || "",
          documentationType:
            tempDocumentationType[index]?.documentationType || 0,
          orderNumber: temporderNumber[index]?.orderNumber || 0,
        })
      );

      const PlofResult = localPlofOptions.reduce((accumulator, current) => {
        accumulator[current.value] = current.text;
        return accumulator;
      }, {});

      const CombinedLivingStatusData = localLivingstatusOptions.reduce(
        (accumulator, item) => {
          const obj =
            typeof item === "string" ? { value: item, text: item } : item;
          accumulator[obj.value] = obj.text;
          if (obj.value !== obj.text) {
            accumulator[obj.text] = obj.text;
          }
          return accumulator;
        },
        {}
      );

      const CombinedAvailableAssistData = convertToObject(
        localAvailableAssistOptions
      );
      const CombinedData = convertToObject(localCombinedDataOptions);

      const mapValues = (key: string) => {
        switch (key) {
          case LofHeaderNames.FUNCTIONAL_MOBILITY:
          case LofHeaderNames.ADLS:
            return PlofResult;
          case LofHeaderNames.LIVING_STATUS:
            return CombinedLivingStatusData;
          case LofHeaderNames.AVAILABLE_ASSISTANCE:
            return CombinedAvailableAssistData;
          case LofHeaderNames.BED_MOBILITY:
          case LofHeaderNames.AMBULATION:
          case LofHeaderNames.TRANSFER_SIT_TO_STAND:
          case LofHeaderNames.TOILETING_HYGIENE:
          case LofHeaderNames.TRANSFER_TOILET:
          case LofHeaderNames.TRANSFER_TUB_SHOWER:
          case LofHeaderNames.BATHING_SHOWERING:
          case LofHeaderNames.LOWER_BODY_DRESSING:
          case LofHeaderNames.UPPER_BODY_DRESSING:
          case LofHeaderNames.GROOMING:
            return CombinedData;
          case LofHeaderNames.INPATIENT:
          case LofHeaderNames.CURRENT:
          case LofHeaderNames.UPPER_EXTREMITIES:
          case LofHeaderNames.LOWER_EXTREMITIES:
          case LofHeaderNames.OTHER_DEVICES:
          case LofHeaderNames.ESTABLISHED:
          case LofHeaderNames.PLAN_TO_ESTABLISH_HEP:
            return null;
          case LofHeaderNames.PRIOR_DEVICES:
          case LofHeaderNames.CURRENT_DEVICES:
          case LofHeaderNames.RECOMMENDED_DEVICES:
            return localAssistiveDeviceData;

          default:
            return "";
        }
      };

      DefaultLOF.forEach((obj: any, index1: number) => {
        obj.LOF.forEach((lof: any, index2: number) => {
          updateState(
            obj,
            index1,
            lof,
            index2,
            extractValues(
              LevelOfFunctionRsponse,
              lof.keyPath,
              lof.key,
              mapValues(lof.key),
              lof.identityPath
            )
          );
        });
      });
      setLevelOfFunctionHeader(combinedArray);
    }
  }, [
    LevelOfFunctionRsponse,
    localPlofOptions,
    localLivingstatusOptions,
    localAvailableAssistOptions,
    localCombinedDataOptions,
    localAssistiveDeviceData,
  ]);

  return (
    <>
      <Grid container>
        <Box sx={LofLofHeaderTitleStyle(indexForRecordWithTypeTwo)}>
          <Typography
            variant="h6"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            pt={"2rem"}
            pl={"1.5rem"}
          >
            LEVEL OF FUNCTION
          </Typography>
          {LevelOfFunctionRsponse && LevelOfFunctionRsponse.length >= 3 && (
            <Divider
              orientation="vertical"
              variant="fullWidth"
              sx={LOFInputDevider}
              flexItem
            />
          )}
        </Box>
        {LevelOfFunctionRsponse && LevelOfFunctionRsponse.length >= 3 ? (
          <>
            <Grid item xs={0.2} pl={"0.5rem"} pt={"1.8rem"}>
              <FaRightArrowIcon />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[1]}
                color={colors.black[2]}
                pt={"2.16rem"}
                pl={"0.5rem"}
              >
                Please scroll for latest to oldest from here.
              </Typography>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      <Grid container sx={LofOuterBodyGrid(columns.length)}>
        <Grid item xs={columns.length > 0 ? 12 : 4}>
          <TableContainer
            component={Paper}
            {...events}
            ref={containerRef}
            sx={LofContainerStyle}
          >
            <LofTableMain sx={LofTableMainStyle(LevelOfFunctionRsponse)}>
              <LofTableMainHead>
                <LofTableCellHeaderLeft>
                  <Box sx={LofHeaderBoxStyle}>
                    <Typography
                      variant="subtitle2"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[5]}
                      pb={"1rem"}
                    >
                      NOTE TYPE:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[5]}
                      pb={"1rem"}
                    >
                      DATE
                    </Typography>
                  </Box>
                </LofTableCellHeaderLeft>
                {levelOfFunctionHeader.map((label: any, index: any) =>
                  label.documentationType === 1 ? (
                    label.orderNumber === 1 ? (
                      <LofFirstTableCellStickyHeaderLeftSecond
                        key={index}
                        sx={LoffirstFixedTableCellBorder(
                          indexForRecordWithTypeTwo
                        )}
                      >
                        <Box sx={LofTableCellWidth}>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"2.85rem"}
                            pb={"1rem"}
                          >
                            {label.disciplineName}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"1.3rem"}
                            pb={"0.7rem"}
                          >
                            {label.dateCreated}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[62]}
                            fontWeight={fontWeight.Weight[4]}
                          >
                            (First documented)
                          </Typography>
                        </Box>
                      </LofFirstTableCellStickyHeaderLeftSecond>
                    ) : (
                      <LofSecondTableCellStickyHeaderLeftSecond key={index}>
                        <Box sx={LofTableCellWidth}>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"2.5rem"}
                            pb={"1rem"}
                          >
                            {label.disciplineName}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"1.3rem"}
                          >
                            {label.dateCreated}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[62]}
                            fontWeight={fontWeight.Weight[4]}
                            pt={"0.7rem"}
                          >
                            (First documented)
                          </Typography>
                        </Box>
                      </LofSecondTableCellStickyHeaderLeftSecond>
                    )
                  ) : (
                    <TableCell
                      key={index}
                      sx={LofLatestColumn(indexForRecordWithTypeTwo, index)}
                    >
                      <Box sx={LofLatestTableCellWidth}>
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                          pb={"1rem"}
                          pl={"5rem"}
                        >
                          {label.disciplineName}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                          pl={"3.5rem"}
                        >
                          {label.dateCreated}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[62]}
                          fontWeight={fontWeight.Weight[4]}
                          pt={"0.75rem"}
                          pl={"4.5rem"}
                        >
                          {indexForRecordWithTypeTwo === index ? "Latest" : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                  )
                )}
              </LofTableMainHead>
              <TableBody>
                {!isEmpty(levelOfFunction) &&
                  levelOfFunction.map((row: any, rowIndex: any) => {
                    let isRowKeyRendered = false;
                    return (
                      <>
                        {!isEmpty(row) &&
                          row.LOF.map((rowChild: any, index: number) => {
                            const renderRowKey = !isRowKeyRendered;
                            if (renderRowKey) {
                              isRowKeyRendered = true;
                            }

                            return (
                              <TableRow key={rowIndex}>
                                <LofTableCellHeaderLeft
                                  sx={LofTableCellBorderBottom(rowChild.borderRequired)}
                                >
                                  <Box sx={LofHeaderBoxStyle}>
                                    <Grid container>
                                      {renderRowKey && (
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="subtitle1"
                                            color={others.otherColors[62]}
                                            fontWeight={fontWeight.Weight[5]}
                                          >
                                            {row.key}
                                          </Typography>
                                        </Grid>
                                      )}

                                      <Grid
                                        item
                                        xs={12}
                                        pl={"2.8rem"}
                                        key={index}
                                      >
                                        <Typography
                                          pt={renderRowKey ? "1.6rem" : "0rem"}
                                          variant="subtitle2"
                                          color={colors.black[2]}
                                          fontWeight={fontWeight.Weight[3]}
                                        >
                                          {rowChild.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </LofTableCellHeaderLeft>
                                {columns.map((column) => {
                                  if (column.id === "column1") {
                                    return (
                                      <LofFirstTableCellStickyHeaderLeftSecond
                                        key={column.id}
                                        sx={LofTableCellBorder(
                                          rowChild.borderRequired,
                                          indexForRecordWithTypeTwo
                                        )}
                                      >
                                        <Box sx={LofTableCellWidth}>
                                          {rowChild[
                                            column.id as keyof typeof rowChild
                                          ] &&
                                          rowChild[
                                            column.id as keyof typeof rowChild
                                          ].length >
                                            LevelOfFunctionMaxCharLength ? (
                                            <Tooltip
                                              title={
                                                <Typography
                                                  variant="subtitle2"
                                                  fontWeight={
                                                    fontWeight.Weight[5]
                                                  }
                                                  lineHeight="1rem"
                                                >
                                                  {
                                                    rowChild[
                                                      column.id as keyof typeof rowChild
                                                    ]
                                                  }
                                                </Typography>
                                              }
                                              arrow
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                color={others.otherColors[63]}
                                                fontWeight={
                                                  fontWeight.Weight[5]
                                                }
                                                sx={LofRowColumnsStyleTooltip(
                                                  rowChild.label
                                                )}
                                              >
                                                {
                                                  rowChild[
                                                    column.id as keyof typeof rowChild
                                                  ]
                                                }
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              color={others.otherColors[63]}
                                              fontWeight={fontWeight.Weight[5]}
                                              sx={LofRowColumnsStyleTooltip(
                                                rowChild.label
                                              )}
                                            >
                                              {
                                                rowChild[
                                                  column.id as keyof typeof rowChild
                                                ]
                                              }
                                            </Typography>
                                          )}
                                        </Box>
                                      </LofFirstTableCellStickyHeaderLeftSecond>
                                    );
                                  }
                                  if (
                                    column.id === "column2" &&
                                    secondColumnDocType === 1 &&
                                    secondColumnOrderNo === 2
                                  ) {
                                    return (
                                      <LofSecondTableCellStickyHeaderLeftSecond
                                        key={column.id}
                                        sx={LofTableCellBorderBottom(
                                          rowChild.borderRequired
                                        )}
                                      >
                                        <Box sx={LofTableCellWidth}>
                                          {rowChild[
                                            column.id as keyof typeof rowChild
                                          ] &&
                                          rowChild[
                                            column.id as keyof typeof rowChild
                                          ].length >
                                            LevelOfFunctionMaxCharLength ? (
                                            <Tooltip
                                              title={
                                                <Typography
                                                  variant="subtitle2"
                                                  fontWeight={
                                                    fontWeight.Weight[5]
                                                  }
                                                  lineHeight="1rem"
                                                >
                                                  {
                                                    rowChild[
                                                      column.id as keyof typeof rowChild
                                                    ]
                                                  }
                                                </Typography>
                                              }
                                              arrow
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                color={others.otherColors[63]}
                                                fontWeight={
                                                  fontWeight.Weight[5]
                                                }
                                                sx={LofRowColumnsStyleTooltip(
                                                  rowChild.label
                                                )}
                                              >
                                                {
                                                  rowChild[
                                                    column.id as keyof typeof rowChild
                                                  ]
                                                }
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              color={others.otherColors[63]}
                                              fontWeight={fontWeight.Weight[5]}
                                              sx={LofRowColumnsStyleTooltip(
                                                rowChild.label
                                              )}
                                            >
                                              {
                                                rowChild[
                                                  column.id as keyof typeof rowChild
                                                ]
                                              }
                                            </Typography>
                                          )}
                                        </Box>
                                      </LofSecondTableCellStickyHeaderLeftSecond>
                                    );
                                  } else {
                                    return (
                                      <TableCell
                                        key={column.id}
                                        sx={LofScrollLableTableCellStyle(
                                          rowChild.borderRequired,
                                          column.id,
                                          indexForRecordWithTypeTwo
                                        )}
                                      >
                                        {rowChild[
                                          column.id as keyof typeof rowChild
                                        ] &&
                                        rowChild[
                                          column.id as keyof typeof rowChild
                                        ].length >
                                          LevelOfFunctionMaxCharLength ? (
                                          <Tooltip
                                            title={
                                              <Typography
                                                variant="subtitle2"
                                                fontWeight={
                                                  fontWeight.Weight[5]
                                                }
                                                lineHeight="1rem"
                                              >
                                                {
                                                  rowChild[
                                                    column.id as keyof typeof rowChild
                                                  ]
                                                }
                                              </Typography>
                                            }
                                            arrow
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              color={others.otherColors[63]}
                                              fontWeight={fontWeight.Weight[5]}
                                              sx={LofRowColumnsStyleTooltip(
                                                rowChild.label
                                              )}
                                              pl={"2.2rem"}
                                            >
                                              {
                                                rowChild[
                                                  column.id as keyof typeof rowChild
                                                ]
                                              }
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            variant="subtitle2"
                                            color={others.otherColors[63]}
                                            fontWeight={fontWeight.Weight[5]}
                                            sx={LofRowColumnsStyleTooltip(
                                              rowChild.label
                                            )}
                                            pl={"2.2rem"}
                                          >
                                            {
                                              rowChild[
                                                column.id as keyof typeof rowChild
                                              ]
                                            }
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  }
                                })}
                              </TableRow>
                            );
                          })}
                      </>
                    );
                  })}
              </TableBody>
            </LofTableMain>
          </TableContainer>
        </Grid>
        {columns.length === 0 && (
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[7]}
              pt={"25rem"}
              pl={"20rem"}
            >
              No Data Found.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LevelOfFunction;

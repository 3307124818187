import { Reducer } from "redux";

import { CheckEligibilityResponse } from "../models/Api/AllPatientRecord";
import { EligibilityCheckActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";
import { EligibilityCheckDispatchTypes } from "../constants/AllPatientRecord";

const initialState: CheckEligibilityResponse = {
  loading: false,
  response: {
    additionalNotes: {
      apiRequestId: "",
      benefitPlanUid: "",
      healthPlanUid: "",
      scrubbedSubscriberID: "",
      scrubbedZipCode: "",
      scrubbedZipCodeExtension: "",
    },
    record: {
      subscriberGender: "",
      version: "",
      subscriberName: {
        last: "",
        first: "",
      },
      subscriberId: "",
      subscriberDOB: "",
      subscriberAddress: {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      subscriberDTLoop: {
        subscriberDT: [],
      },
      benefitsLoop: {
        benefits: [],
      },
    },
    supplementalInformation: {
      info: [
        {
          employeeId: "",
          groupOrPolicyNumber: "",
          employmentStatusCode: "",
          handicapIndicator: "",
          lastTouchedDate: "",
          hicNumber: "",
          mbi: "",
          medicaidId: "",
          mobilePhone: "",
          homePhone: "",
          alternatePhone: "",
          workPhone: "",
          emailAddress: "",
          employerName: "",
          studentStatusCode: "",
          userDefined1: "",
          userDefined2: "",
          userDefined3: "",
          userDefined4: "",
          userDefined5: "",
          userDefined6: "",
          memberFound: "",
          address: {
            addressType: "",
            addressLine: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
          },
          healthCoverage: {
            planCoverageDescription: "",
            groupOrPolicyNumber: "",
            insuranceLineCode: "",
            coverageLevelCode: "",
            beginDate: "",
            endDate: "",
            providerInfo: {
              orgOrLastName: "",
              firstName: "",
              providerIdentifier: "",
              addressLine: "",
              cityName: "",
              stateCode: "",
              zipCode: "",
              providerType: "",
            },
          },
        },
      ],
    },
  },
  error: null,
};

const CheckEligibilityReducer: Reducer<
  CheckEligibilityResponse,
  EligibilityCheckActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        error: null,
        response: action.response,
        loading: false,
      };
    }
    case EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_ERROR: {
      return {
        ...state,
        error: action.response,
        response: null,
        loading: false,
      };
    }
    case EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET: {
      return initialState;
    }
  }
  return state;
};

export default CheckEligibilityReducer;

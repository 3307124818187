import { Reducer } from "redux";

import { AllPendingUnassignStaffResponse } from "../models/Api/Filter";
import { AllPendingUnassignStaffDispatchTypes } from "../models/DispatchTypes/Filter";
import { AllPendingUnassignStaffActionDispatchTypes } from "../constants/Filter";

export const initialState: AllPendingUnassignStaffResponse = {
  loading: false,
  response: {
    searchResponse: [],
    totalRows: 0,
  },
};

const AllPendingUnassignStaffReducer: Reducer<
  AllPendingUnassignStaffResponse,
  AllPendingUnassignStaffDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case AllPendingUnassignStaffActionDispatchTypes.ALL_PENDING_UNASSIGN_STAFF_RESET: {
      return initialState;
    }
  }
  return state;
};

export default AllPendingUnassignStaffReducer;

export enum ModuleActionDispatchTypes {
  MODULE_FAIL = "MODULE_FAIL",
  MODULE_INVOKE = "MODULE_INVOKE",
  MODULE_SUCCESS = "MODULE_SUCCESS",
}
export enum SubModuleActionTypes {
  SUB_MODULE_FAIL = "SUB_MODULE_FAIL",
  SUB_MODULE_INVOKE = "SUB_MODULE_INVOKE",
  SUB_MODULE_SUCCESS = "SUB_MODULE_SUCCESS",
}

export enum Module {
  REFERRAL_DASHBOARD = 1,
  MY_PATIENT = 2,
  REPORTS = 5,
  ADMIN_CENTER = 6,
}

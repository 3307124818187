import { Reducer } from "redux";

import { CareTypeResponse } from "../models/Api/Master";
import { CareTypeActionDispatchTypes } from "../constants/Master";
import { CareTypeDispatchTypes } from "../models/DispatchTypes/Master";

const initialState: CareTypeResponse = {
  loading: false,
  response: [
    {
      careTypeId: -1,
      careTypeName: "",
    },
  ],
};

const CareTypeReducer: Reducer<CareTypeResponse, CareTypeDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CareTypeActionDispatchTypes.CARE_TYPE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CareTypeActionDispatchTypes.CARE_TYPE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default CareTypeReducer;

import { Reducer } from "redux";

import { PatientRequestResponse } from "../models/Api/AllPatientRecord";
import { PatientRequestDispatchTypes } from "../constants/AllPatientRecord";
import { PatientRequestActionDispatchTypes } from "../models/DispatchTypes/AllPatientRecord";

const initialState: PatientRequestResponse = {
  loading: false,
  response: {
    referralDetail: {
      referralId: 0,
      patientId: 0,
      receivedDateTime: "",
      urgencyId: null,
      receivedMethodId: null,
      receivedMethodName: null,
      isSignedHealthOrder: "",
      signedOrderDate: null,
      signedOrderType: "",
      orderedById: null,
      acceptedById: null,
      isCustodialCare: "",
      isNonCoveredService: "",
      userCreated: null,
      nonCoveredServices: null,
      healthProgramId: null,
      careProgramId: null,
      urgencyStatus: "",
      orderedByName: "",
      acceptedByName: "",
      nonCoveredServiceNames: "",
      patientEligibilityId: 0,
      authorizationTypeId: 0,
      authorizationTypeName: ""
    },
    emergencyContact: [],
    residentialAddress: {
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      patientState: null,
      zipCode: null,
      latitude: null,
      longitude: null,
      streetName: null,
      addressTypeId: null,
    },
    serviceAddress: {
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      patientState: null,
      zipCode: null,
      latitude: null,
      longitude: null,
      streetName: null,
      addressTypeId: null,
    },
    requester: {
      facilityTypeId: null,
      requesterName: "",
      requesterExtension: "",
      requesterPhoneNo: "",
      requesterEmail: null,
      requesterFax: "",
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      requesterState: null,
      zipCode: null,
      facilityTypeName: "",
      facilityName: "",
      requesterNpi: null,
    },
    referralSource: {
      facilityTypeId: null,
      referralSourceName: "",
      referralSourceExtension: "",
      referralSourcePhoneNo: "",
      referralSourceEmail: null,
      referralSourceFax: null,
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      referralSourceState: null,
      zipCode: null,
      sameAsRequestingFacility: false,
      dischargeDate: null,
      facilityTypeName: "",
      facilityName: "",
      referralSourceNpi: null,
    },
    isSameAsResidential: false,
  },
};

const PatientReferralRequest: Reducer<
  PatientRequestResponse,
  PatientRequestActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PatientRequestDispatchTypes.PATIENT_REQUEST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientRequestDispatchTypes.PATIENT_REQUEST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PatientRequestDispatchTypes.PATIENT_REQUEST_RESET: {
      return initialState;
    }
  }
  return state;
};

export default PatientReferralRequest;

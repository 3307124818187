import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AllPatientTabDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import { ApplicationState } from "../../../../../store/ApplicationState";
import PhysicianInputForm from "../components/PhysicianInputForm";
import { resetStates } from "../../../../../actions/Common";

const mapStateToProps = ({ physicianRecordsState }: ApplicationState) => ({
  physicianRecords: physicianRecordsState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianInputForm);
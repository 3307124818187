import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getReceivedMethodMasterResp,
  getRelationshipMasterResp,
  getUrgencyStatusMasterResp,
} from "../../../../../actions/Patient";
import RequestDetail from "../components/Request";
import {
  getCareCoordinationProgram,
  getHealthProgram,
  GetAuthorizationTypeMaster,
} from "../../../../../actions/Master";

const mapStateToProps = ({
  careCoordinationProgramState,
  healthProgramState,
  receivedMethodState,
  relationshipState,
  urgencyStatusState,
  MasterAuthorizationTypeState,
  priorAuthReferralSourceState,
}: ApplicationState) => ({
  careCoordination: careCoordinationProgramState,
  healthProgram: healthProgramState,
  receivedMethodData: receivedMethodState,
  relationshipData: relationshipState,
  urgencyStatus: urgencyStatusState,
  AuthorizationType: MasterAuthorizationTypeState,
  priorAuthReferralSourceResponse: priorAuthReferralSourceState,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getReceivedMethodDetails: (serviceRequestTypeId: number) => {
      dispatch(getReceivedMethodMasterResp(serviceRequestTypeId));
    },
    getUrgencyStatusMasterResp: () => {
      dispatch(getUrgencyStatusMasterResp());
    },
    getRelationshipMasterResp: () => {
      dispatch(getRelationshipMasterResp());
    },
    getHealthProgram: () => {
      dispatch(getHealthProgram());
    },
    getCareCoordinationProgram: () => {
      dispatch(getCareCoordinationProgram());
    },
    getAuthorizationTypeMaster: () => {
      dispatch(GetAuthorizationTypeMaster());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestDetail);

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Table, TableHead, alpha } from "@mui/material";
import {
  FaSearch,
  FaFilter,
  FaUndo,
  FaFilePdf,
  FaPencilAlt,
} from "react-icons/fa";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const rowOne = {
  boxSizing: "border-box",
  margin: "0.09rem 0.24rem",
  padding: "0.05rem",
};
export const title = {
  textAlign: "left",
  color: colors.black[2],
  textTransform: "uppercase",
  opacity: "1",
};
export const IconFaSearch = styled(FaSearch)(() => ({
  display: "flex",
  color: alpha(colors.fonts[4], 0.32),
  justifyContent: "left",
}));
export const IconFaPencilAlt = styled(FaPencilAlt)(() => ({
  color: colors.fonts[18],
  cursor: "pointer",
  marginLeft: "1.5rem",
}));

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderBottom: `0.05rem solid ${others.otherColors[41]}`,
    borderRight: "none",
    borderLeft: "none",
    padding: "0.5rem 0.5rem 0.5rem 0.97rem",
  },
  "& th:first-of-type": {
    borderRadius: "1em 0 0 1em",
  },
  "& th:last-child": {
    borderRadius: "0 1em 1em 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[8],
  borderRadius: "0.42rem",
}));

export const HeaderTableRow = () => ({
  height: "2.8rem",
});

export const back = {
  display: "flex",
  margin: "0.05rem 0.15rem",
  textAlign: "left",
  textDecoration: "underline",
  color: colors.primary.main,
  textTransform: "uppercase",
  opacity: "1",
};

export const description = {
  textAlign: "left",
  paddingTop: 1,
  opacity: "1",
  lineHeight: "1.3",
};

// User Profile HEAD properties start
export const head = {
  justifyContent: "space-between",
  display: "flex",
  boxSizing: "border-box",
  padding: "0.5rem 0",
  paddingBottom: "0.97rem",
};

export const h_backbutton = [
  rowOne,
  back,
  {
    background: "inherit",
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
];

export const h_main = {
  textAlign: "left",
  boxSizing: "border-box",
};

export const h_title = [rowOne, title];
export const h_desp = [rowOne, description];

export const h_buttons = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const h_btn = {
  border: "0.05rem solid",
  borderRadius: "0.97rem",
  color: colors.primary.main,
  textTransform: "uppercase",
  boxSizing: "border-box",
  padding: "0.5rem",
  paddingLeft: "0.73rem",
  paddingRight: "0.73rem",
  height: "1.2rem",
  whiteSpace: "nowrap",
};
export const h_btn_cancel = [
  h_btn,
  {
    "&:hover": {
      backgroundColor: colors.primary.main,
      color: others.otherColors[33],
    },
  },
];

export const h_btn_savenexit = [
  h_btn,
  {
    background: `${colors.primary.main} 0% 0% no-repeat padding-box`,
    color: others.otherColors[33],
    "&:hover": {
      backgroundColor: others.otherColors[33],
      color: colors.primary.main,
    },
  },
];

export const h_btn_save = h_btn_savenexit;
// User Profile HEAD properties end

// User Profile BODY properties start
export const body = {
  background: others.otherColors[33],
  opacity: 1,
  padding: "0.24rem",
  marginRight: 2,
  borderRadius: "0.5rem",
  paddingBottom: "2rem",
};

export const body_row1col1 = {
  main: { textAlign: "left", padding: "1.2rem 0.7rem 1.2rem 0.72rem" },
  title: [rowOne, title, { color: colors.black[3] }],
  desp: [rowOne, description],
};

export const body_row1col2 = {
  main: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    padding: "1.46rem 0.5rem",
  },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [rowOne, title, { color: colors.black[3] }],
};

export const body_row1col3 = {
  main: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    padding: "2rem 0.09rem",
  },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [rowOne, title, { color: colors.black[3] }],
};

export const body_row2col1 = {
  c1: [
    title,
    {
      color: colors.fonts[2],
      textAlign: "center",
      opacity: 0.88,
    },
  ],
  c2: {
    height: "4rem",
    width: "4rem",
    borderRadius: "50%",
    margin: "0.24rem auto",
    padding: "0.97rem 0.5rem",
  },
};

export const body_row2col2 = {
  main: {
    padding: "2.24rem  0.5rem",
  },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [rowOne, title, { color: colors.black[3], textTransform: "none" }],
  c3: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
    },
  ],
};

export const phoneinput = {
  main: {
    padding: "2.24rem 0.5rem",
  },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [rowOne, title, { color: colors.black[5], textTransform: "none" }],
  c3: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
    },
  ],
};

export const body_row2col3 = {
  main: { padding: "2.43rem 0.5rem" },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
    },
  ],
  c3: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
      margin: "0 0.2rem",
    },
  ],
};
export const body_row2col4 = {
  main: { padding: "2.43rem 0.5rem" },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
    },
  ],
};
export const body_row2col5 = {
  main: { padding: "2.43rem  0.24rem" },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
      display: "flex",
      alignItems: "center",
    },
  ],
  c3: { paddingLeft: "0.24rem" },
};
export const body_row2col6 = {
  main: { padding: "2.43rem 0.24rem" },
  c1: [rowOne, description, { color: colors.fonts[2] }],
  c2: [
    rowOne,
    title,
    {
      color: colors.black[5],
      textTransform: "none",
      display: "flex",
      alignItems: "center",
    },
  ],
  c3: { paddingLeft: "2.43rem" },
};

export const body_row3col1 = {
  main: [
    rowOne,
    title,
    {
      color: colors.fonts[2],
      textTransform: "none",
      padding: "0.97rem 0.4rem",
      paddingBottom: "0.24rem",
      opacity: 0.88,
    },
  ],
};

export const body_row4 = {
  main: [
    rowOne,
    title,
    {
      color: colors.fonts[2],
      textTransform: "none",
      opacity: 0.88,
      paddingLeft: "1.5rem",
    },
  ],
};

// User Profile BODY properties end

// User Profile Footer properties start

export const footer = {
  opacity: 1,
  borderRadius: "0.5rem",
};

export const footer_row = {
  display: "flex",
  alignItems: "center",
  float: "right",
  padding: "0.5rem 0.24rem",
};

// User Profile Footer properties ends

// global export of all styles

export const ItemMain = styled(Box)(() => ({
  backgroundColor: others.otherColors[33],
  color: others.otherColors[33],
  borderRadius: "0.24rem",
}));

export const ItemMainUM = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "space-between",
}));

export const Header = styled(Box)(() => ({
  color: colors.black[2],
}));

export const PaginationStyles = {
  backgroundColor: others.otherColors[33],
  color: colors.black[2],
};

const removeavatarborder = { padding: "0.5rem", borderRight: "none" };
export const avatarSize = { width: "100%", height: "100%" };
export const backdrop = {
  color: others.otherColors[33],
  zIndex: (theme: any) => theme.zIndex.drawer - 1,
  position: "absolute",
  opacity: 0.5,
};

export const dividerRightWidth = {
  borderRightWidth: 2,
  height: "1.45rem",
  marginRight: "0.5rem",
};

export const DividerHeight = {
  height: "2.2rem",
};

const dividerborder = { width: "0.09rem", height: "9rem", marginLeft: "1rem" };
export const layout = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "0.32rem 0.72rem 0.22rem  0.72rem",
};

export const innerLayoutHeader = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "0.42rem 0 0.42rem 0",
  background: others.otherColors[33],
};

export const BreadcumbLeftGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  padding: "1rem 1.5rem 1rem 1.5rem",
}));

export const BreadcumbRightGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "end",
  padding: "0 1.5rem 0 0",
}));

export const BreadcumbPaper = styled(Paper)(() => ({
  backgroundColor: "inherit",
}));

export const ExportIcon = {
  testAlign: "left",
  marginRight: ".5rem",
  position: "relative",
};

export const ButtonStyle = (loading?: boolean) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0.3rem",
  background: loading ? alpha(colors.grey[1], 0.5) : others.MainBackground[1],
  padding: "0 0.9rem 0 0.9rem",
  height: "1.8rem",
  borderRadius: "0.8rem",
  color: colors.fonts[18],
  cursor: loading ? "not-allowed" : "pointer",
});

export const AutoCompleteBootom = {
  borderBottomColor: others.otherColors[35],
  display: "flex",
  flexDrection: "row",
  margin: "1rem 0 0 1.6rem",
};

export const AutoComplete = {
  width: "18rem",
  borderBottom: others.otherColors[35],
  borderBottomWidth: "0.06rem",
  marginRight: "1.5rem",
};

export const Search = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const searchicon = {
  display: "flex",
  color: others.otherColors[36],
  alignItems: "flex-start",
  justifyContent: "left",
  margin: "0.625rem 0.625rem 0 0.625rem",
};

export const FilterIcon = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

export const TablecellStyle = {
  borderBottom: "none",
};

export const ListHeader = {
  height: "auto",
};

export const Data = {
  lineHeight: "1rem",
};

export const HeaderItem = {
  backgroundColor: alpha(colors.black[7], 0.35),
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
};

export const LastLogin = {
  color: colors.black[2],
  justifyContent: "flex-start",
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
  wordBreak: "break-all",
};

export const TitleBox = styled(Box)(() => ({
  textAlign: "initial",
  color: colors.fonts[4],
  padding: "0 0 0 1.3rem",
  width: "100%",
  marginTop: "1.21rem",
}));

export const SwitchTitle = styled(Box)(() => ({
  textAlign: "initial",
  color: colors.fonts[4],
  padding: "0 0 0 0",
  width: "100%",
}));

export const InputBox = styled(Grid)(() => ({
  padding: "0.5rem",
  display: "flex",
  flexDirection: "row",
}));

export const GridLayoutHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  marginTop: "2.3rem",
  marginBottom: "1rem",
}));

export const GridLayoutContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  margin: "1.5rem 0 1.6rem 1rem",
  width: "30%",
}));

export const IconFilter = styled(FaFilter)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  fontSize: "1.0rem",
  justifyContent: "left",
  color: colors.fonts[18],
  margin: "1.0rem 0.72rem 0 0.72rem",
}));

export const IconFaUndo = styled(FaUndo)(() => ({
  testAlign: "left",
  marginRight: ".5rem",
  font: fontWeight.Weight[4],
  color: colors.fonts[18],
}));

export const IconFaFilePdf = styled(FaFilePdf)(() => ({
  testAlign: "left",
  marginRight: ".5rem",
  color: colors.fonts[18],
}));

export const HeaderGrid = {
  display: "flex",
  width: "50%",
  justifyContent: "end",
};

export const GridList = styled(Grid)(() => ({
  margin: "1rem 1.6rem 0 1.6rem",
}));

export const GridInputFields = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
}));

export const AlignmentHeader = {
  font: fontWeight.Weight[4],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "3.0rem",
};

export const SummaryContainerBody = () => ({
  padding: "0",
});

export const PatientSearchLabel = () => ({
  padding: "0 0 .75rem 0",
  textAlign: "initial",
});

export const AvatarIcon = () => ({
  width: "4.5rem",
  height: "4.5rem",
});

export const excelLoader = () => ({
  color: colors.brand.primary,
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-4px",
  marginLeft: "-12px",
  zIndex: 1,
});

export const SwitchBox = () => ({
  marginTop: "0.7rem",
  paddingLeft: "0.5rem",
});

export const GridPadding = () => ({
  paddingLeft: "1.5rem",
});

export const TableBoxMargin = () => ({
  margin: "0 0.5rem",
});

export const PaginationGrid = () => ({
  padding: "1rem 0",
});

export const styles = {
  AvatarIcon,
  h_backbutton,
  rowOne,
  head,
  h_main,
  h_title,
  h_desp,
  h_buttons,
  h_btn,
  h_btn_cancel,
  h_btn_save,
  h_btn_savenexit,
  body,
  body_row1col1,
  body_row1col2,
  body_row1col3,
  body_row2col1,
  body_row2col2,
  body_row2col3,
  body_row2col4,
  body_row2col5,
  body_row2col6,
  body_row3col1,
  body_row4,
  footer,
  footer_row,
  removeavatarborder,
  avatarSize,
  backdrop,
  dividerRightWidth,
  phoneinput,
  dividerborder,
  layout,
  SwitchBox,
  GridPadding,
  HeaderTableRow,
  TableBoxMargin,
};

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { DateFormats } from "../../constants/Date";
import { FormInputProps } from "./FormInputProps";
import { spanStyle } from "./styles/style";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";

export const FormInputDate = ({
  control,
  label,
  name,
  rule,
  setDate,
}: FormInputProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        rules={rule}
        render={({
          field: { onChange, value, ref, onBlur, ...fieldProps },
          fieldState: { error },
        }) => {
          const givenDate = new Date(value);
          const currentDate = new Date();
          if (setDate) {
            setDate(value ? dayjs(value).format(DateFormats.MM_DD_YYYY) : "");
          }
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                {...fieldProps}
                inputRef={ref}
                label={
                  <Typography
                    variant="h5"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[2]}
                  >
                    {label}
                  </Typography>
                }
                value={dayjs(value)}
                onChange={onChange}
                slotProps={{
                  textField: {
                    variant: "standard",
                    required: rule && rule.required ? rule.required.value : false,
                    error: error && error.message ? true : false,
                    helperText:
                      givenDate.getTime() >= currentDate.getTime() ? (
                        <Box component="span" sx={spanStyle}>
                          Please enter valid date
                        </Box>
                      ) : error ? (
                        error.message
                      ) : null,
                    onBlur: onBlur,
                    InputLabelProps: { shrink: true },
                  },
                }}
                format={DateFormats.MM_DD_YYYY}
                disableFuture
              />
            </LocalizationProvider>
          );
        }}
      />
    </>
  );
};

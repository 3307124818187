import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

import { styled } from "@mui/system";

import { colors, others } from "../../../../../styles/colors";

export const Gridbody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 2rem 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const VerticalDivider = () => ({
  margin: "0 1.2rem",
});

export const ClearBtnVerticalDivider = () => ({
  margin: "0 0.5rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0",
  width: "98%",
});

export const SummaryBox = () => ({
  width: "100%",
  padding: "1rem ",
  display: "flex",
  justifyContent: "space-between",
});

export const Header = () => ({
  width: "100%",
  paddingRight: "1.5rem",
  paddingLeft: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
});

export const HeaderTitle = () => ({
  paddingTop: "0.3rem",
  paddingLeft: "1rem",
});

export const GridContent = () => ({
  paddingRight: "1.5rem",
  paddingLeft: "4rem",
});

export const Item = () => ({
  padding: "1rem",
});

export const FollowingUpPhysicianGrid = () => ({
  paddingTop: "1rem",
  paddingLeft: "1rem",
});

export const ItemIconBox = () => ({
  color: colors.primary.main,
  paddingRight: "0.5rem",
});

export const DocumentBody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 0 2rem",
  marginBottom: "0.8rem",
  borderRadius: "0.8rem",
});

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FieldLabel = () => ({
  marginBottom: "0.6rem",
});

export const FieldValue = () => ({
  paddingTop: "0.3rem",
  paddingRight: "0.5rem",
});

export const BreakWord = () => ({
  display: "flex",
  wordBreak: "break-word",
});

export const SerachTitleGrid = () => ({
  paddingLeft: "0.5rem",
});

export const SerachResultBox = () => ({
  paddingBottom: "0.8rem",
  paddingLeft: "1rem",
});

import { connect } from "react-redux";

import { ApplicationState } from "../../../store/ApplicationState";
import IncompleteRequests from "../components/MyPatientsDetails/IncompleteRequests";

const mapStateToProps = ({
  myPatientListState,
  filterState,
}: ApplicationState) => ({
  MyPatientsRecord: myPatientListState,
  filterState: filterState,
});

export default connect(mapStateToProps)(IncompleteRequests);

import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getReceivedMethodMasterResp,
  getUrgencyStatusMasterResp,
} from "../../../../../actions/Patient";
import ReauthRequestDetail from "../components/ReauthRequest";

const mapStateToProps = ({
  receivedMethodState,
  urgencyStatusState,
}: ApplicationState) => ({
  receivedMethodData: receivedMethodState,
  urgencyStatus: urgencyStatusState,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUrgencyStatusMasterResp: () => {
      dispatch(getUrgencyStatusMasterResp());
    },
    getReceivedMethodDetails: (serviceRequestTypeId: number) => {
      dispatch(getReceivedMethodMasterResp(serviceRequestTypeId));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReauthRequestDetail);

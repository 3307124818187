import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { configUrlForOperator, configUrlForReferral } from "../constants/Config";
import {
  GetCounty,
  GetPatientState,
  GetServiceProvider,
  GetServiceProviderMaster,
  GetServiceRequest,
} from "../services/Service";
import { ApplicationState } from "../store/ApplicationState";
import {
  CountyDispatchTypes,
  PatientStateDispatchTypes,
  ServiceProviderDispatchTypes,
  ServiceProviderMasterDispatchTypes,
  ServiceRequestDispatchTypes,
} from "../models/DispatchTypes/Service";
import {
  CountyActionDispatchType,
  PatientStateActionDispatchType,
  ServiceProviderActionDispatchType,
  ServiceProviderMasterActionDispatchType,
  ServiceRequestActionDispatchType,
} from "../constants/Service";
import { resetStates } from "./Common";

export const getServiceRequest: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceRequestDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceRequestActionDispatchType.SERVICE_REQUEST_INVOKE,
    });

    const response = await GetServiceRequest(configUrlForReferral, referralId);

    dispatch({
      response,
      type: ServiceRequestActionDispatchType.SERVICE_REQUEST_SUCCESS,
    });
  };
};


export const getServiceProviderMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceProviderMasterDispatchTypes
  >
> = (value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_INVOKE,
    });
    const response = await GetServiceProviderMaster(
      configUrlForReferral,
      value
    );

    dispatch({
      response,
      type: ServiceProviderMasterActionDispatchType.SERVICE_PROVIDER_MASTER_SUCCESS,
    });
  };
};

export const getServiceProviderList: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceProviderDispatchTypes
  >
> = (value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_INVOKE,
    });

    const suffix = `?searchText=${value}`;
    const response = await GetServiceProvider(configUrlForOperator, suffix);

    dispatch({
      response,
      type: ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_SUCCESS,
    });
  };
};

export const resetServiceProviderList = () => {
  return resetStates([
    ServiceProviderActionDispatchType.SERVICE_PROVIDER_LIST_RESET,
  ]);
};

export const getPatientState: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PatientStateDispatchTypes
  >
> = (value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PatientStateActionDispatchType.PATIENT_STATE_INVOKE,
    });
    const suffix = `?searchText=${value}`;
    const response = await GetPatientState(configUrlForReferral, suffix);

    dispatch({
      response,
      type: PatientStateActionDispatchType.PATIENT_STATE_SUCCESS,
    });
  };
};

export const getCounty: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CountyDispatchTypes
  >
> = (value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CountyActionDispatchType.GET_COUNTY_INVOKE,
    });
    const response = await GetCounty(configUrlForReferral, value);

    dispatch({
      response,
      type: CountyActionDispatchType.GET_COUNTY_SUCCESS,
    });
  };
};

import { Reducer } from "redux";

import { MasterCallOutcomeResponse } from "../models/Api/Master";
import { MasterCallOutcomeDispatchTypes } from "../models/DispatchTypes/Master";
import { MasterCallOutcomeActionDispatchTypes } from "../constants/Master";

export const initialState: MasterCallOutcomeResponse = {
  loading: false,
  response: [],
};

const MasterCallOutcomeReducer: Reducer<
  MasterCallOutcomeResponse,
  MasterCallOutcomeDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case MasterCallOutcomeActionDispatchTypes.MASTER_CALLOUTCOME_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MasterCallOutcomeActionDispatchTypes.MASTER_CALLOUTCOME_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterCallOutcomeReducer;

import { Reducer } from "redux";

import { UserPermissionActionDispatchTypes } from "../constants/User";
import { UserPermissionResponse } from "../models/Api/User";
import { UserPermissionDispatchTypes } from "../models/DispatchTypes/User";

const initialState: UserPermissionResponse = {
  loading: false,
  response: [
    { moduleId: -1, subModuleId: -1, htmlControlId: "", htmlText: "" },
  ],
};

const UserPermissionReducer: Reducer<
  UserPermissionResponse,
  UserPermissionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case UserPermissionActionDispatchTypes.USER_PERMISSION_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserPermissionActionDispatchTypes.USER_PERMISSION_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case UserPermissionActionDispatchTypes.USER_PERMISSION_RESET: {
      return initialState;
    }
  }
  return state;
};

export default UserPermissionReducer;

import { styled } from "@mui/system";
import { alpha } from "@mui/material";
import { Box, TableHead } from "@mui/material";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const ItemMain = styled(Box)(() => ({
  backgroundColor: others.otherColors[33],
  color: others.otherColors[33],
  borderRadius: "0.24rem",
}));

export const HeaderItem = {
  backgroundColor: alpha(colors.black[7], 0.35),
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
};
export const Header = styled(Box)(() => ({
  color: colors.black[2],
}));

export const AlignmentHeader = {
  font: fontWeight.Weight[4],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "3.0rem",
};

export const StyledTableHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[8],
  borderBottom: "unset",
  padding: ".5rem 0 0 1.5rem",

  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: "none",
  },
  "& th:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: "none",
  },
}));

export const inactiveButton = {
  fontWeight: fontWeight.Weight[4],
  color: colors.red[90],
  textDecoration: "underline",
};

export const cellPadding = {
  padding: ` .25rem 1.2rem`,
};

export const activeButton = {
  fontWeight: fontWeight.Weight[4],
  color: colors.fonts[22],
  textDecoration: "underline",
};

export const policyButton = {
  fontWeight: fontWeight.Weight[4],
  color: colors.fonts[21],
};

export const titleBox = {
  padding: "2rem 1rem 1.5rem 1rem",
};
export const eligibilityTitleBox = {
  padding: "2rem 0 1rem 4rem",
};

export const tableContainer = {
  padding: "2.4rem 4.2rem 2.8rem 4.2rem",
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: colors.backgroundColor[2],
  borderRadius: ".5rem",
};
export const EligibilityTableContainer = {
  padding: "2rem",
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: colors.backgroundColor[2],
  borderRadius: ".5rem",
};
export const tableCellStyle = {
  borderBottom: "unset",
  position: "relative",
};
export const tableCell = {
  borderBottom: "unset",
};
export const specialCell = {
  textAlign: "center",
};
export const borderLast = {
  borderBottom: "unset",
};

export const tableCellDynamic = (
  isFirstData: boolean,
  isLastData: boolean
) => ({
  borderLeft: isFirstData ? `1px solid ${colors.borders[2]}` : "none",
  borderBottom: isLastData ? "unset" : `1px solid ${colors.dropShadow[1]}`,
  position: "relative",
});

export const EligibilitytableCellDynamic = (
  isFirstData: boolean,
  isLastData: boolean
) => ({
  borderLeft: !isFirstData ? `1px solid ${colors.borders[2]}` : "none",
  borderBottom: isLastData ? "unset" : `1px solid ${colors.dropShadow[1]}`,
  position: "relative",
});

export const leftBorder = {
  borderLeft: `1px solid ${colors.dropShadow[1]}`,
  position: "absolute",
  top: "20%",
  left: "-1px",
  bottom: "0",
  textAlign: "center",
};

export const NoRecordsCell = {
  padding: "0.3rem 0",
};

export const PaginationGrid = () => ({
  marginTop: "1.5rem",
  paddingBottom: "1rem",
});

export const ContainerStyle = {
  "&::-webkit-scrollbar": {
    width: "0.5rem",
    height: "0.2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
    marginLeft: "10rem",
    marginRight: "50rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
};
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getUserDetails,
  getRoles,
  putUserDetails,
  userDetailsReset,
} from "../../../../../actions/User";
import { UserDetailsDispatchTypes } from "../../../../../models/DispatchTypes/User";
import UserDetails from "../components/UserDetails";
import { UserDetailsPayload } from "../../../../../models/User";

const mapStateToProps = ({ userDetailsState, RoleState }: ApplicationState) => {
  return { userDetails: userDetailsState, roles: RoleState };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<UserDetailsDispatchTypes>
  >
) => {
  return {
    getUserDetails: (id: number) => {
      dispatch(getUserDetails(id));
    },
    postUserDetails: (userId: number | string, payload: UserDetailsPayload) => {
      dispatch(putUserDetails(userId, payload));
    },
    getAllRoles: () => {
      dispatch(getRoles());
    },
    userDetailsReset: () => {
      dispatch(userDetailsReset());
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

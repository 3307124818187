import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isNil, isNull, isUndefined } from "lodash";

import { FormInputProps } from "./FormInputProps";
import {
  GenderInputUI,
  TimePickerError,
  timePickerField,
  timePickerFieldMargin,
  TimepickerHourMinute,
  TimePickerIcon,
  timePickerSpacing,
} from "./styles/style";
import { ScrollLock } from "../../styles/common/style";
import colors from "../../styles/colors/colors";
import { flexAllCentre } from "../../styles/mui/styles/display";
import { DateFormats } from "../../constants/Date";
import fontWeight from "../../styles/mui/fontWeight";
import useMenuItem from "../../hooks/useMenuItem";

/**
 * This is beta version of custom time picker. Further improvements are in progress...
 */

export const FormInputTimePicker: React.FC<FormInputProps> = ({
  control,
  disabled,
  helper,
  label,
  name,
  setValue,
  value,
  allowFutureTime,
  watch,
  trigger,
  clearErrors,
  InputStyle,
  readOnly,
}) => {
  const [amPm, setAmPm] = useState<string>("AM");
  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");
  const [isValidTime, setIsValidTime] = useState<boolean>(false);
  const dateValueProp: Dayjs | null =
    typeof value === "string" && dayjs(value).isValid() ? dayjs(value) : value;
  const [initialDateTime, setInitialDateTime] = useState<Dayjs | null>(
    dateValueProp
  );

  const [timeFieldsDateObject, setTimeFieldsDateObject] =
    useState<Dayjs | null>(null);
  const [isTimeSelectedFirst, setIsTimeSelectedFirst] =
    useState<boolean>(false);

  const {
    open: openHour,
    onOpen: onOpenHour,
    onClose: onCloseHour,
  } = useMenuItem();
  const {
    open: openMinute,
    onOpen: onOpenMinute,
    onClose: onCloseMinute,
  } = useMenuItem();

  const fieldName = `${name}_TimeField`;
  const fieldNameHour = `${name}_TimeField_hour`;
  const fieldNameMinute = `${name}_TimeField_minute`;

  const timePickerValue = watch ? watch(fieldName) : null;
  const customTimeValidator = (value: any) => {
    if (!isEmpty(value)) {
      if (
        false === allowFutureTime &&
        !value.isSame(dayjs(), "minute") &&
        !value.isBefore(dayjs(), "minute")
      ) {
        return "No future time.";
      }
    }
    return true;
  };

  const getDropDownOptions = (type: string) => {
    if ("hour" === type) {
      return Array(12)
        .fill(null)
        .map((_, i) => String(String(i + 1).padStart(2, "0")))
        .map((option: any) => (
          <MenuItem key={`${option}-${type}`} value={option}>
            {option}
          </MenuItem>
        ));
    }

    return Array(60)
      .fill(null)
      .map((_, i) => String(String(i).padStart(2, "0")))
      .map((option: any) => (
        <MenuItem key={`${option}-${type}`} value={option}>
          {option}
        </MenuItem>
      ));
  };

  const handleChange = (type: string, value: string) => {
    switch (type) {
      case "AMPM":
        if (!isEmpty(value)) {
          setAmPm(value);
        }
        break;
      case "hour":
        setHour(value);
        break;
      case "minute":
        setMinute(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (type: string, value: string) => {
    switch (type) {
      case "hour":
        setHour(value);
        break;
      case "minute":
        setMinute(value);
        break;
      default:
        break;
    }
  };

  const getHour = (amPM: string, hour: string): number => {
    return "PM" === amPM && Number(hour) < 12
      ? Number(hour) + 12
      : "AM" === amPm && Number(hour) === 12
      ? 0
      : Number(hour);
  };

  const prepareDateTime = () => {
    let dayjsTimeObj = null;
    if (amPm && hour && minute) {
      const hourValue = getHour(amPm, hour);
      dayjsTimeObj = dayjs().hour(Number(hourValue)).minute(Number(minute));
      setIsValidTime(dayjsTimeObj.isValid());
    } else {
      dayjsTimeObj = null;
      setIsValidTime(false);
    }
    setTimeFieldsDateObject(dayjsTimeObj);
    setFormDateTime(dayjsTimeObj);
  };

  const setFormDateTime = (dayjsTimeObj: Dayjs | null) => {
    const dateValue: Dayjs | null =
      typeof value === "string" && dayjs(value).isValid()
        ? dayjs(value)
        : value;
    if (dateValue && dayjsTimeObj) {
      const hourValue = getHour(
        dayjsTimeObj.format("A"),
        dayjsTimeObj.format("hh")
      );
      const formDateTime = dateValue
        .hour(hourValue)
        .minute(Number(dayjsTimeObj.format("mm")));
      setValue(name, formDateTime, true);
      setValue(fieldName, formDateTime, true);
    } else {
      setValue(fieldName, "", false);
      setValue(name, null, false);
      if (dayjsTimeObj && dayjsTimeObj.isValid()) {
        setIsTimeSelectedFirst(true);
      }
    }
  };

  const updateTimeFieldsUI = (dayjsObj: Dayjs) => {
    handleChange("AMPM", dayjsObj.format("A"));
    handleChange("hour", dayjsObj.format("hh"));
    handleChange("minute", dayjsObj.format("mm"));
  };

  useEffect(() => {
    if (
      trigger &&
      amPm &&
      hour &&
      minute &&
      typeof value !== "string" &&
      !isNull(value) &&
      !isUndefined(value)
    ) {
      trigger(fieldName);
    }
    return () => {
      if (clearErrors) {
        clearErrors(fieldName);
      }
    };
  }, [timePickerValue]);

  useEffect(() => {
    if (trigger && amPm && hour && minute && value) {
      trigger(fieldName);
    }
    prepareDateTime();
  }, [amPm, hour, minute]);

  useEffect(() => {
    const dateValue: Dayjs | null =
      typeof value === "string" && dayjs(value).isValid()
        ? dayjs(value)
        : value;
    if (dateValue && !isTimeSelectedFirst) {
      setValue(name, dateValue, false);
      setValue(fieldName, dateValue, false);
    }
    if (dateValue && dateValue.isValid()) {
      if (
        initialDateTime &&
        initialDateTime.isValid() &&
        dateValue.format(DateFormats.MM_DD_YYYY_hh_mm_ss_a) !==
          initialDateTime.format(DateFormats.MM_DD_YYYY_hh_mm_ss_a)
      ) {
        handleChange("hour", "");
        setInitialDateTime(dateValue);
      }
      if (
        isNil(initialDateTime) &&
        timeFieldsDateObject &&
        timeFieldsDateObject.isValid() &&
        dateValue.format(DateFormats.TIME_AM_PM) !==
          timeFieldsDateObject.format(DateFormats.TIME_AM_PM) &&
        isTimeSelectedFirst
      ) {
        //when null rendered and time selected first and date selected next
        const hourValue = getHour(
          timeFieldsDateObject.format("A"),
          timeFieldsDateObject.format("hh")
        );
        setIsTimeSelectedFirst(false);
        setInitialDateTime(dateValue);
        setValue(
          name,
          dateValue
            .hour(hourValue)
            .minute(Number(timeFieldsDateObject.format("mm"))),
          true
        );
        setValue(fieldName, dateValue, true);
      } else {
        updateTimeFieldsUI(dateValue);
      }
    }
  }, [value]);

  return (
    <FormGroup row sx={GenderInputUI}>
      {label && (
        <InputLabel
          required={
            helper && helper.required && helper.required.value
              ? helper.required.value
              : false
          }
          shrink={true}
          sx={flexAllCentre}
        >
          <Typography
            variant="h5"
            fontWeight={fontWeight.Weight[3]}
            color={colors.fonts[2]}
          >
            {label}
          </Typography>
        </InputLabel>
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={""}
        rules={{ ...helper, ...{ validate: customTimeValidator } }}
        render={({ field: { onChange, ref }, fieldState: { error } }) => {
          return (
            <>
              <FormControl variant="standard" size={"small"}>
                <Stack direction="row" sx={flexAllCentre}>
                  <TimePickerIcon />
                </Stack>
              </FormControl>
              <FormControl
                variant="standard"
                size={"small"}
                sx={timePickerSpacing}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={amPm}
                  disabled={readOnly}
                  exclusive
                  onChange={(_, val) => {
                    handleChange("AMPM", val);
                  }}
                  aria-label="AM PM"
                  sx={timePickerFieldMargin}
                >
                  <ToggleButton value="AM" sx={timePickerField}>
                    AM
                  </ToggleButton>
                  <ToggleButton value="PM" sx={timePickerField}>
                    PM
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
              <FormControl
                variant="standard"
                size={"small"}
                sx={timePickerSpacing}
              >
                <Select
                  required={helper && helper.required && helper.required.value}
                  MenuProps={ScrollLock()}
                  error={error !== undefined && !isValidTime}
                  onChange={(_, val: any) => {
                    handleChange("hour", val.props.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("hour", e.target.value);
                  }}
                  name={fieldNameHour}
                  value={!isNil(hour) ? hour : ""}
                  onInput={onChange}
                  inputRef={ref}
                  fullWidth
                  disabled={disabled}
                  displayEmpty
                  open={openHour}
                  onOpen={onOpenHour}
                  onClose={onCloseHour}
                  sx={InputStyle}
                  readOnly={readOnly}
                >
                  <MenuItem disabled value={""}>
                    12
                  </MenuItem>
                  {getDropDownOptions("hour")}
                </Select>
                <InputLabel shrink={true} sx={TimepickerHourMinute}>
                  <Typography
                    variant="h5"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[2]}
                  >
                    Hour
                  </Typography>
                </InputLabel>
              </FormControl>
              <FormControl variant="standard" size={"small"}>
                <Select
                  required={helper && helper.required && helper.required.value}
                  MenuProps={ScrollLock()}
                  error={error !== undefined && !isValidTime}
                  onChange={(_, val: any) => {
                    handleChange("minute", val.props.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("minute", e.target.value);
                  }}
                  name={fieldNameMinute}
                  value={!isNil(minute) ? minute : ""}
                  onInput={onChange}
                  fullWidth
                  disabled={disabled}
                  displayEmpty
                  open={openMinute}
                  onOpen={onOpenMinute}
                  onClose={onCloseMinute}
                  sx={InputStyle}
                  readOnly={readOnly}
                >
                  <MenuItem disabled value={""}>
                    00
                  </MenuItem>
                  {getDropDownOptions("minute")}
                </Select>
                <InputLabel shrink={true} sx={TimepickerHourMinute}>
                  <Typography
                    variant="h5"
                    fontWeight={fontWeight.Weight[3]}
                    color={colors.fonts[2]}
                  >
                    Minute
                  </Typography>
                </InputLabel>
              </FormControl>
              {error && error.message && (
                <FormHelperText sx={TimePickerError}>
                  {error ? error.message : null}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormGroup>
  );
};

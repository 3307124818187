import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AllPatientDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import { AllPatientValidateDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientValidate";
import { ApplicationState } from "../../../../../store/ApplicationState";
import Main from "../components";
import {
  checkEligibility,
  getAllPatientRecord,
} from "../../../../../actions/AllPatientRecord";
import { eligibilityData } from "../../../../../utils/Eligibility";
import { getEligibilityRecord } from "../../../../../actions/Master";
import { getListResult } from "../../../../../utils";
import { PatientSearch } from "../../../../../models/Patient";
import { resetStates } from "../../../../../actions/Common";
import { storeEligibilityData } from "../../../../../actions/PatientInformation";

const mapStateToProps = ({
  allPatientRecordState,
  checkEligibilityState,
  eligibilityRecordState,
  payerState,
}: ApplicationState) => ({
  allPatientRecord: allPatientRecordState,
  checkEligibilityData: checkEligibilityState,
  eligibilityRecord: eligibilityData(eligibilityRecordState.response, checkEligibilityState.response),
  payerState: getListResult(payerState.response),
  eligibilityRecordState: eligibilityRecordState
});

type DispatchTypes = AllPatientDispatchTypes & AllPatientValidateDispatchTypes;

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<DispatchTypes>
  >
) => {
  return {
    getAllPatientRecord: (payload?: PatientSearch) => {
      dispatch(getAllPatientRecord(payload));
    },
    checkEligibility: (payload: any) => {
      dispatch(checkEligibility(payload));
    },
    getEligibilityData: (benefitPlanUid: string) => {
      dispatch(getEligibilityRecord(benefitPlanUid));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    storeEligibilityData: (data: any) => {
      dispatch(storeEligibilityData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "lodash";
import { matchSorter } from "match-sorter";
import { SyntheticEvent, useEffect, useState } from "react";

import {
  AutoComplete,
  SubscriberControl,
} from "../../PatientRecords/styles/style";
import {
  BackButton,
  ContentBoxHeader,
  CreateBtn,
  CrossIconBox,
  DobBox,
  FilterParameterGrid,
  GridBody,
  GridCheckEligibility,
  GridFooter,
  HeaderBox,
  ModalGrid,
  ModalTitle,
  OuterBodyGrid,
  SubHeaderBox,
} from "../styles/Modal";
import {
  CurrentEligibilityInformation,
  UpdateCurrentEligibilityInformation,
  UpdateEligibilityInformationQuestion,
} from "../../../../../constants/Patient";
import { Data } from "../styles/CreateReferral";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import {
  getHealthPlanValueType,
  getListResult,
  getValue,
} from "../../../../../utils";
import { HealthPlanData } from "../../../../../constants/PatientList";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { PatientFilterOptions } from "../../../../../models/Patient";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { rules } from "../../../../../utils/validation/Validation";
import { numAlphabetOnlyRegex } from "../../../../../constants/Regex";
import colors from "../../../../../styles/colors/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import useMenuItem from "../../../../../hooks/useMenuItem";
import {
  IconFaTimesCircle,
  ModalGridContainer,
} from "../../../../../styles/common/style";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface Props {
  closeModal: (onClose: boolean) => void;
  data: PatientInformationViewResponse;
  getPayload: any;
  getUpdatedHealthPlan: (updatedHealthPlan: any) => void;
  openModal: boolean;
  payerState: PatientFilterOptions[];
  setIsModified: any;
}
const UpdateExistingEligibility = ({
  data,
  payerState,
  openModal,
  closeModal,
  getUpdatedHealthPlan,
  getPayload,
  setIsModified,
}: Props) => {
  const methods = useForm<any>({
    defaultValues: {
      payerId: "",
      policyId: "",
      payerName: "",
    },
  });

  const { control, handleSubmit, getValues, setValue, reset } = methods;
  const currentStateValues = useWatch({
    control,
  });

  const [update, setUpdate] = useState<boolean>(false);
  const [healthPlan, setHealthPlan] = useState<any>();
  const [clickable, setClickable] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [payer, setPayer] = useState<PatientFilterOptions[]>([HealthPlanData]);
  const { open, onOpen, onClose } = useMenuItem();
  const formValues = getValues();
  useEffect(() => {
    setPayer(getListResult(payerState));
  }, [payerState]);

  const handleUpdate = () => {
    setUpdate(true);
    setClickable(false);
  };
  const handleNoUpdate = () => {
    clear();
    setUpdate(false);
    setClickable(true);
    setIsModified(false);
    submitData(getPayload());
  };
  const handleClose = () => {
    setUpdate(false);
    closeModal(false);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === " " && !event.target.value.trim()) {
      event.preventDefault();
    }
  };
  const onHealthPlanSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setHealthPlan(value);
    setIsModified(true);
    onClose();
  };

  const submitData = (values: any) => {
    const eligibilityDetails = !update
      ? getPayload()
      : {
          ...getPayload(),
          payerId: values.payerId,
          payerName: values.payerName,
          policyId: values.policyId,
        };
    getUpdatedHealthPlan(eligibilityDetails);
    setUpdate(false);
    closeModal(false);
  };

  const CheckEligbility = () => {
    reset({
      ...getValues(),
      ...{
        ...formValues,
        payerId:
          healthPlan && healthPlan.value
            ? getHealthPlanValueType(healthPlan.value, 0)
            : "",
        payerName:
          healthPlan && healthPlan.value
            ? getHealthPlanValueType(healthPlan.value, 1)
            : "",
      },
    });
    handleSubmit(submitData)();
    clear();
  };

  const clear = () => {
    setValue("policyId", "");
    setValue("payerId", "");
    setValue("payerName", "");
    setHealthPlan(null);
  };

  useEffect(() => {
    setIsValid(numAlphabetOnlyRegex.test(currentStateValues.policyId));
    if (
      !isEmpty(currentStateValues.policyId) &&
      !isEmpty(currentStateValues.payerId) &&
      numAlphabetOnlyRegex.test(currentStateValues.policyId)
    ) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  }, [currentStateValues]);

  useEffect(() => {
    if (healthPlan) {
      setValue(
        "payerId",
        healthPlan && healthPlan.value
          ? getHealthPlanValueType(healthPlan.value, 0)
          : ""
      );
    }
    if (!update) {
      clear();
    }
  }, [healthPlan, update]);

  const planDetails = (label: string, value: string) => {
    return (
      <>
        <Grid item xs sx={DobBox} style={{ flex: 1 }}>
          <Box>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[2]}
            >
              {label}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.black[2]}
              sx={Data}
            >
              {getValue(data, value)}
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  const HeaderText = (type: number, value: string) => {
    return (
      <Grid
        item
        xs={type == 1 ? 11.5 : 12}
        sx={type == 1 ? HeaderBox : SubHeaderBox}
      >
        <Typography
          variant={type == 1 ? "h2" : "h3"}
          color={colors.fonts[4]}
          fontWeight={type == 1 ? fontWeight.Weight[5] : fontWeight.Weight[4]}
          sx={ModalTitle}
        >
          {value}
        </Typography>
      </Grid>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ModalGridContainer}
    >
      <Box sx={ModalGrid}>
        <Grid container sx={ContentBoxHeader}>
          {HeaderText(1, UpdateEligibilityInformationQuestion)}
          <Grid item xs={0.5} sx={CrossIconBox}>
            <IconFaTimesCircle onClick={handleClose} />
          </Grid>
        </Grid>
        <Grid container sx={OuterBodyGrid}>
          <Grid item xs={12} sx={GridCheckEligibility}>
            <Box>
              <Button
                variant="outlined"
                onClick={() => handleNoUpdate()}
                sx={BackButton}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => handleUpdate()}
                sx={CreateBtn}
              >
                Yes
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={GridBody}>
            <Grid container sx={ContentBoxHeader}>
              {HeaderText(2, CurrentEligibilityInformation)}
              <Grid container spacing={2}>
                {planDetails("Health Plan:", "response.memberInfo.healthPlan")}
                {planDetails(
                  "Subscriber Id:",
                  "response.memberInfo.subscriberId"
                )}
                {planDetails("Product:", "response.memberInfo.product")}
                {planDetails(
                  "Benefit Plan:",
                  "response.memberInfo.benefitPlan"
                )}
              </Grid>
              {update && (
                <Grid container pt={"2rem"} sx={ContentBoxHeader}>
                  <Grid item xs={12} mt={0} mb={3}>
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      textAlign="center"
                      flexItem
                    />
                  </Grid>
                  {HeaderText(2, UpdateCurrentEligibilityInformation)}
                  <Grid item xs={5} sx={FilterParameterGrid}>
                    <FormControl variant="standard" sx={SubscriberControl}>
                      <FormInputText
                        name="policyId"
                        control={control}
                        label="Subscriber ID:"
                        placeholder=""
                        textLength={15}
                        mandatorySymbol={true}
                      />
                    </FormControl>
                    {!isValid && (
                      <Grid item pl={"1rem"}>
                        <FormHelperText>
                          Please enter valid subscriber ID.
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    p={"0.7rem 0 0 2rem"}
                    sx={FilterParameterGrid}
                  >
                    <Box sx={displayFlex}>
                      <Typography
                        variant="body1"
                        fontWeight={fontWeight.Weight[2]}
                        color={colors.fonts[2]}
                      >
                        Health Plan:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[2]}
                        color={colors.red[100]}
                        pl={"0.3rem"}
                      >
                        *
                      </Typography>
                    </Box>
                    <Controller
                      render={({ fieldState: { error } }) => (
                        <>
                          <Autocomplete
                            sx={AutoComplete}
                            options={payer as PatientFilterOptions[]}
                            getOptionLabel={(option: PatientFilterOptions) =>
                              option.label
                            }
                            open={open}
                            onOpen={() => onOpen()}
                            onClose={() => onClose()}
                            filterOptions={(options: any, { inputValue }) =>
                              matchSorter(options, inputValue, {
                                keys: ["label"],
                              })
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            PopperComponent={StyledPopperCheckBoxAutocomplete}
                            id="auto-complete"
                            autoComplete
                            disableClearable
                            clearIcon={""}
                            onChange={onHealthPlanSelection}
                            includeInputInList
                            value={healthPlan}
                            noOptionsText={NO_RECORD_FOUND}
                            renderInput={(params: any) => {
                              params.inputProps.maxLength = 50;
                              return (
                                <Box>
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    onKeyDown={handleKeyDown}
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                </Box>
                              );
                            }}
                            renderOption={(props, option) => {
                              return (
                                <Typography
                                  {...props}
                                  variant="body1"
                                  fontWeight={fontWeight.Weight[4]}
                                  color={colors.fonts[25]}
                                >
                                  {option.label}
                                </Typography>
                              );
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      )}
                      control={control}
                      name="payerId"
                      rules={rules.checkEligibilityHealthPlan}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={GridFooter}>
            <Box>
              <Button
                disabled={!clickable}
                variant="contained"
                onClick={() => CheckEligbility()}
                sx={CreateBtn}
              >
                Check Eligibility
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UpdateExistingEligibility;

import { useNavigate, NavigateFunction, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import {
  borderLast,
  cellPadding,
  leftBorder,
  NoRecordsCell,
  specialCell,
  StyledTableHead,
  tableCell,
  tableCellDynamic,
  tableCellStyle,
} from "../styles/AuthorizationHistory";
import { colors } from "../../../../../styles/colors";
import { getValue, queryStringData } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import {
  HeaderLabelAuthHistory,
  PatientReferralStatus,
} from "../../../../../constants/AllPatientRecord";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthAndEligibilityHistoryPayload,
  AuthorizationHistory as AuthorizationHistoryList,
  AuthorizationHistoryPagination,
} from "../../../../../models/AuthorizationHistory";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import {
  CursorPointer,
  TooltipStyle,
} from "../../../../../styles/common/style";
import { PER_PAGE } from "../../../../../constants/FunctionalUtils";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";
import { DateFormats } from "../../../../../constants/Date";

type AuthHistoryProp = {
  authorizationHistory: AuthorizationHistoryPagination | null;
  getAuthorizationHistoryRecord: (
    payload: AuthAndEligibilityHistoryPayload
  ) => void;
};

const AuthorizationHistory = ({
  authorizationHistory,
  getAuthorizationHistoryRecord,
}: AuthHistoryProp) => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const count = Math.ceil(
    authorizationHistory && authorizationHistory.totalRows
      ? Number(authorizationHistory.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );
  const { patientId } = useParams();

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getRecords(Number(patientId), page, PER_PAGE);
  };

  const getRecords = (patientId: number, pageNo: number, pageSize: number) => {
    getAuthorizationHistoryRecord({
      patientId,
      contains: true,
      pageNo,
      pageSize,
    });
  };

  useEffect(() => {
    getAuthorizationHistoryRecord(queryStringData(Number(patientId)));
  }, []);

  return (
    <>
      <Grid container pb={"1rem"}>
        <Grid item xs={12} pt={"1.2rem"}>
          <TableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  {HeaderLabelAuthHistory.map((data: any, index: number) => (
                    <TableCell
                      key={index}
                      sx={data.flag ? tableCellStyle : tableCell}
                    >
                      <Box sx={data.flag ? leftBorder : {}}></Box>
                      <Typography
                        color={colors.fonts[4]}
                        variant="body1"
                        fontWeight={fontWeight.Weight[3]}
                        sx={data.flag ? specialCell : {}}
                      >
                        {data.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {rowRender(
                  getValue(authorizationHistory, "authorizationHistory", []),
                  navigate
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {authorizationHistory && (
          <Grid item xs={12} mt={"1.5rem"}>
            <PaginationItem
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
const rowRender = (
  data: AuthorizationHistoryList[],
  navigate: NavigateFunction
) => {
  return (
    <>
      {data ? (
        data.map((item: AuthorizationHistoryList, index: number) => (
          <TableRow key={index}>
            <TableCell sx={data.length - 1 === index ? borderLast : {}}>
              <Link
                variant="subtitle1"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[21]}
                sx={CursorPointer}
                onClick={() =>
                  navigate(
                    ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
                      Id: item.authId,
                    })
                  )
                }
              >
                {item.authorizationNumber}
              </Link>
            </TableCell>
            {cellRender(item.healthPlan, false, data.length - 1 === index)}
            {cellRender(item.subscriberId, false, data.length - 1 === index)}
            <TableCell sx={data.length - 1 === index ? borderLast : {}}>
              {isEmpty(item.referralStatusName) ? (
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                >
                  Incomplete
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={
                    item.referralStatusName == PatientReferralStatus.ACTIVE
                      ? colors.fonts[22]
                      : colors.fonts[4]
                  }
                >
                  {item.referralStatusName}
                </Typography>
              )}
            </TableCell>
            <TableCell sx={tableCellDynamic(false, data.length - 1 === index)}>
              <Tooltip
                title={
                  <Typography
                    variant="subtitle1"
                    fontWeight={fontWeight.Weight[4]}
                  >
                    {item.serviceProvider}
                  </Typography>
                }
                arrow
                slotProps={{
                  popper: {
                    sx: TooltipStyle,
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                  sx={CursorPointer}
                >
                  {item.serviceProviderNpi}
                </Typography>
              </Tooltip>
            </TableCell>
            {cellRender(
              formatDate(item.admitDate, DateFormats.MM_DD_YYYY),
              false,
              data.length - 1 === index
            )}
            {cellRender(
              formatDate(item.dischargeDate, DateFormats.MM_DD_YYYY),
              false,
              data.length - 1 === index
            )}
            {cellRender(item.totalApproved, false, data.length - 1 === index)}
            {cellRender(item.sn, true, data.length - 1 === index)}
            {cellRender(item.pt, true, data.length - 1 === index)}
            {cellRender(item.ot, true, data.length - 1 === index)}
            {cellRender(item.st, true, data.length - 1 === index)}
            {cellRender(item.msw, true, data.length - 1 === index)}
            {cellRender(item.hha, true, data.length - 1 === index)}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell align="center" colSpan={14}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[4]}
              sx={NoRecordsCell}
            >
              NO RECORDS FOUND
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
const cellRender = (data: any, flag = false, removeBorder: boolean) => {
  return (
    <>
      <TableCell sx={tableCellDynamic(flag, removeBorder)}>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[4]}
          sx={flag ? cellPadding : {}}
        >
          {data}
        </Typography>
      </TableCell>
    </>
  );
};
export default AuthorizationHistory;

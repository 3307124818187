import { Reducer } from "redux";

import { DisciplineActionDispatchTypes, DisciplineRecord } from "../constants/Discipline";
import { DisciplineResponse } from "../models/Api/Discipline";
import { DisciplineDispatchTypes } from "../models/DispatchTypes/Discipline";

export const initialPostState: DisciplineResponse = {
  loading: false,
  response: {
    totalRows: 0,
    disciplineList: [DisciplineRecord],
  },
};

const DisciplineReducer: Reducer<
  DisciplineResponse,
  DisciplineDispatchTypes
> = (state = initialPostState, action) => {
  switch (action.type) {
    case DisciplineActionDispatchTypes.DISCIPLINE_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DisciplineActionDispatchTypes.DISCIPLINE_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case DisciplineActionDispatchTypes.DISCIPLINE_RESET: {
      return initialPostState;
    }
  }
  return state;
};

export default DisciplineReducer;

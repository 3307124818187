import { SyntheticEvent, useEffect, useState } from "react";
import React from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import {
  FaTrashAlt,
  FaPencilAlt,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPlus,
} from "react-icons/fa";
import DoneIcon from "@mui/icons-material/Done";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Grid,
  Autocomplete,
  Typography,
  Box,
  Button,
  Link,
  TextField,
} from "@mui/material";

import {
  tableheading,
  tablecontent,
  idbox,
  id,
  toggleiconbox,
  toggleiconcolor,
  actionbox,
  actionicon,
  deleteicon,
  innertable,
  GridLayoutHeaderContainer,
  IconFaSearch,
  GridLayoutCollapseHeader,
  HeaderItem,
  AutoCompleteWidth,
  SummaryContainerBody,
  PatientSearchLabel,
  DoneItemIcon,
  ModuleTablecell,
  RoleIdTablecell,
} from "../styles/rolemanagement";
import { Head, Body, Main } from "../../../../../components/custom/structure";
import { RoleManagementResponse } from "../../../../../models/Api/Roles";
import {
  Access,
  Authorization,
  RoleManagementBodyProps,
  SubModule,
} from "../../../../../models/Roles";
import { initialState } from "../../../../../reducers/RoleManagement";
import { DeleteResponse } from "../../../../../models/Api/Delete";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import useMenuItem from "../../../../../hooks/useMenuItem";
import { length } from "../../../../../utils";
import Permission from "../../../../Permission";
import { AdminCenterAccess } from "../../../../../constants/Permission";
import { IconDisabled } from "../../../../../styles/common/style";
import { StyledPopperCheckBoxAutocomplete } from "../../../../../components/styles/styles";

export interface PropsDispatch {
  getRoleManagementList: () => void;
  deleteRole: (roleId: number) => void;
  deleteRoleReset: () => void;
}
export interface PropsState {
  roleManagementList: RoleManagementResponse;
  isDeleted: DeleteResponse;
}
type RoleManagementProps = PropsState & PropsDispatch;

const RoleManagement: React.FC<RoleManagementProps> = ({
  roleManagementList,
  getRoleManagementList,
  deleteRole,
  isDeleted,
  deleteRoleReset,
}) => {
  const { response } = roleManagementList as RoleManagementResponse;

  const [authorization, setAuthorization] = useState<Authorization[]>(
    initialState.response
  );
  const navigate = useNavigate();

  useEffect(() => {
    getRoleManagementList();
  }, []);

  useEffect(() => {
    if (isDeleted && isDeleted.response) {
      getRoleManagementList();
      deleteRoleReset();
    }
  }, [isDeleted]);

  useEffect(() => {
    setAuthorization(response);
  }, [roleManagementList.response]);

  const handleAdd = () => {
    navigate(ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT_CREATE_ROLE));
  };

  const handleDelete = (roleId: number) => {
    deleteRole(roleId);
  };

  const checkAuthorizationList = (authorization: Authorization[]) =>
    Array.isArray(authorization) && authorization[0].roleId !== -1;

  return (
    <>
      <Main>
        <Head
          title={"ROLE MANAGEMENT"}
          description={
            "View and take actions on role based settings, create/edit/assign rights to roles here."
          }
        >
          <Box>
            <Permission
              controlId={`${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_CREATE}|${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_READ}|`}
              passThrough
            >
              {(isDisabled: boolean) => (
                <Button
                  variant="contained"
                  startIcon={<FaPlus size={10} />}
                  onClick={handleAdd}
                  data-testid={"roleManagement-add"}
                  disabled={isDisabled}
                >
                  Add
                </Button>
              )}
            </Permission>
          </Box>
        </Head>
        <Body>
          {checkAuthorizationList(authorization) && (
            <RoleManagementBody
              authorization={authorization}
              handleDelete={handleDelete}
              navigate={navigate}
            />
          )}
        </Body>
      </Main>
    </>
  );
};

export const RoleManagementBody = ({
  authorization,
  handleDelete,
  navigate,
}: RoleManagementBodyProps) => {
  const [filteredRoles, setFilteredRoles] = useState(authorization);
  const { open, onOpen, onClose } = useMenuItem();

  useEffect(() => {
    setFilteredRoles(authorization);
  }, [authorization]);

  const [role, setRole] = useState<Authorization | null>();

  const defaultProps = (filteredRoles: Authorization[]) => ({
    options: !length(filteredRoles) ? [] : filteredRoles,
    getOptionLabel: (option: Authorization) => {
      return `${option.roleName}`;
    },
  });

  const handleFilter = (
    _event: SyntheticEvent<Element, Event>,
    value: Authorization | null
  ) => {
    setRole(value);
    onClose();
    if (value) {
      const role = authorization.filter(
        (role: Authorization) => role.roleId === Number(value.roleId)
      );
      if (role) setFilteredRoles(role);
    } else {
      setFilteredRoles(authorization);
    }
  };

  const tableHeader = (position: any, headerTitle: string) => {
    return (
      <>
        <TableCell align={position} sx={tableheading}>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={colors.black[2]}
          >
            {headerTitle}
          </Typography>
        </TableCell>
      </>
    );
  };

  const checkInitialData = (filteredRoles: Authorization[]) => {
    return filteredRoles && filteredRoles[0].roleId !== -1;
  };

  const filterOptions = (options: any[], state: any) => {
    if (!state.inputValue) {
      return options;
    }
    return options.filter((item: any) =>
      !isNaN(state.inputValue)
        ? item.roleId == Number(state.inputValue)
        : item.roleName.toUpperCase().includes(state.inputValue.toUpperCase())
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <GridLayoutHeaderContainer>
          <Grid container sx={SummaryContainerBody}>
            <Grid item xs={12}>
              <Box sx={PatientSearchLabel}>
                <Typography
                  variant="subtitle2"
                  color={colors.fonts[2]}
                  fontWeight={fontWeight.Weight[3]}
                >
                  Search for Role or Id
                </Typography>
              </Box>
              <Autocomplete
                sx={AutoCompleteWidth}
                {...defaultProps(authorization)}
                id="auto-complete"
                autoComplete
                onChange={(event, value) => handleFilter(event, value)}
                includeInputInList
                value={role || null}
                PopperComponent={StyledPopperCheckBoxAutocomplete}
                noOptionsText="role name/id not found"
                disabled={
                  Array.isArray(authorization) && authorization.length === 0
                }
                isOptionEqualToValue={(option, value) =>
                  option.roleName === value.roleName
                }
                renderInput={(params) => {
                  params.inputProps.maxLength = 50;
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Search for role"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <IconFaSearch />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
                onInputChange={(_event, value) => {
                  value !== "" ? onOpen() : onClose();
                }}
                open={open}
                onOpen={() => onOpen()}
                onClose={() => onClose()}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.roleId}>
                      {option.roleName}
                    </li>
                  );
                }}
                filterOptions={filterOptions}
                data-testid="roleManagement-searchByRole"
              />
            </Grid>
          </Grid>
        </GridLayoutHeaderContainer>
      </Grid>
      <GridLayoutCollapseHeader item xs={12}>
        <Box pb={"1rem"}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead sx={HeaderItem}>
                <TableRow>
                  {tableHeader("left", "Role ID")}
                  {tableHeader("left", "Role Name")}
                  {tableHeader("left", "Authorizations")}
                  {tableHeader("center", "Actions")}
                </TableRow>
              </TableHead>
              <TableBody>
                {checkInitialData(filteredRoles) &&
                  filteredRoles.map((row: Authorization, index: number) => (
                    <Row
                      key={index}
                      row={row}
                      access={row.roleModule}
                      handleDelete={handleDelete}
                      navigate={navigate}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </GridLayoutCollapseHeader>
    </>
  );
};

type RowProps = {
  row: Authorization;
  access: Access[];
  handleDelete: (roleId: number) => void;
  navigate: any;
};

export const Row = ({ row, access, navigate }: RowProps) => {
  const [open, setOpen] = React.useState(false);

  const totalModules = (row: Authorization) => {
    let total = "";
    if (row.roleModule.length > 0)
      total = `${row.roleModule[0].moduleName} ${
        row.roleModule.length - 1 > 0 ? `+${row.roleModule.length - 1}` : ""
      }`;
    else total = `${row.roleName}`;
    return total;
  };

  const typographyBody = (value: string) => {
    return (
      <>
        <Typography
          variant="subtitle1"
          color={colors.black[5]}
          fontWeight={fontWeight.Weight[5]}
        >
          {value}
        </Typography>
      </>
    );
  };
  const tableHeader = (position: any, headerTitle: string) => {
    return (
      <>
        <TableCell align={position} sx={tableheading}>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={colors.black[2]}
          >
            {headerTitle}
          </Typography>
        </TableCell>
      </>
    );
  };

  const handleRoleDetails = (roleId: number) => {
    navigate(
      ModuleLinks(PatientRecordsModule.ROLE_MANAGEMENT_ROLE_DETAILS, {
        roleId: roleId,
      })
    );
  };
  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left" component="th" scope="row" sx={tablecontent}>
          <Box sx={idbox}>
            <Box sx={toggleiconbox}>
              {access.length > 0 ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  data-testid={`roleManagement-toggle-row${row.roleId}`}
                >
                  {open ? (
                    <Box sx={toggleiconcolor}>
                      <FaChevronCircleUp />
                    </Box>
                  ) : (
                    <Box sx={toggleiconcolor}>
                      <FaChevronCircleDown />
                    </Box>
                  )}
                </IconButton>
              ) : null}
            </Box>
            <Box sx={access.length > 0 ? id : RoleIdTablecell}>
              {typographyBody(row.roleId.toString())}
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left" sx={tablecontent}>
          {typographyBody(row.roleName)}
        </TableCell>
        <TableCell align="left" sx={tablecontent}>
          {typographyBody(totalModules(row))}
        </TableCell>
        <TableCell align="center">
          <Box sx={actionbox}>
            <Box sx={actionicon}>
              <Permission
                controlId={`|${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_READ}|${AdminCenterAccess.ADMIN_CENTER_ROLE_MANAGEMENT_UPDATE}`}
                passThrough
              >
                {(isDisabled: boolean) => (
                  <Link
                    onClick={() => {
                      handleRoleDetails(row.roleId);
                    }}
                    data-testid="roleManagement-edit"
                    style={isDisabled ? IconDisabled : {}}
                  >
                    <FaPencilAlt
                      color={colors.fonts[18]}
                      style={isDisabled ? IconDisabled : {}}
                    />
                  </Link>
                )}
              </Permission>
            </Box>
            <Box sx={deleteicon}>
              <FaTrashAlt
                // onClick={() => handleDelete(row.roleId)} TBD
                data-testid="roleManagement-delete"
              />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={innertable} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 5 }}>
              <Table
                aria-label="purchases"
                data-testid="roleManagement-collapse"
              >
                <TableHead sx={HeaderItem}>
                  <TableRow>
                    {tableHeader("left", "Modules")}
                    {tableHeader("left", "Sub-Modules")}
                    {tableHeader("left", "View")}
                    {tableHeader("left", "Edit")}
                    {tableHeader("left", "Delete")}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {access.map((permission: Access) => {
                    return permission.roleSubModule.map(
                      (subModule: SubModule, index: number) => {
                        let module = "";
                        if (index === 0) module = permission.moduleName;
                        return (
                          <TableRow key={index}>
                            <TableCell align="left" sx={ModuleTablecell}>
                              {typographyBody(module)}
                            </TableCell>
                            <TableCell key={index}>
                              {typographyBody(subModule.subModuleName)}
                            </TableCell>
                            <TableCell align="left">
                              {subModule.accesses.includes("View") ? (
                                <DoneIcon
                                  sx={DoneItemIcon}
                                  data-testid={`roleManagement-moduleId${permission.moduleId}-subModuleId${subModule.subModuleId}-View`}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="left">
                              {subModule.accesses.includes("Edit") ? (
                                <DoneIcon
                                  sx={DoneItemIcon}
                                  data-testid={`roleManagement-moduleId${permission.moduleId}-subModuleId${subModule.subModuleId}-Edit`}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="left">
                              {subModule.accesses.includes("Delete") ? (
                                <DoneIcon
                                  sx={DoneItemIcon}
                                  data-testid={`roleManagement-moduleId${permission.moduleId}-subModuleId${subModule.subModuleId}-Delete`}
                                />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RoleManagement;

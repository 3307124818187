import {
  FaFile,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFileWord,
} from "react-icons/fa";
import { DocumentType } from "../constants/Document";
import { colors } from "../styles/colors";

export const getDocTypeIcon = (type: DocumentType, iconColor?: string) => {
  const iconSize = "1.5rem";
  const color = iconColor || colors.primary.main;
  switch (type) {
    case DocumentType.INVALID:
      return <FaFile size={iconSize} color={color} />;
    case DocumentType.DOC:
    case DocumentType.DOCX:
      return <FaRegFileWord size={iconSize} color={color} />;
    case DocumentType.PDF:
      return <FaRegFilePdf size={iconSize} color={color} />;
    case DocumentType.JPEG:
    case DocumentType.PNG:
    case DocumentType.JPG:
      return <FaRegFileImage size={iconSize} color={color} />;
  }
  return <FaFile size={iconSize} color={color} />;
};

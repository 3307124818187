import {
  TableBody,
  TableHead,
} from "../components/formComponents/constant/FormComponents";
import {
  PhysicianNameCol,
  PhysicianNpiCol,
  SelectPhysicianBtn,
} from "../components/formComponents/styles/style";
import {
  Headcell130,
  Headcell150,
  Headcell170,
  Headcell200,
  Headcell250,
  Headcell310,
  Headcell80,
  Headcell60,
  Headcell40,
  Headcell160,
  Headcell100,
  Headcell140,
} from "../routes/MyPatients/styles/style";
import { colors } from "../styles/colors";
import fontWeight from "../styles/mui/fontWeight";
import { StaffingListTitle } from "./Staffing";

export enum PatientRecordDispatchTypes {
  PATIENT_RECORD_FAIL = "PATIENT_RECORD_FAIL",
  PATIENT_RECORD_INVOKE = "PATIENT_RECORD_INVOKE",
  PATIENT_RECORD_SUCCESS = "PATIENT_RECORD_SUCCESS",
}

export enum PatientTabDispatchTypes {
  PATIENT_TAB_FAIL = "PATIENT_TAB_FAIL",
  PATIENT_TAB_INVOKE = "PATIENT_TAB_INVOKE",
  PATIENT_TAB_SUCCESS = "PATIENT_TAB_SUCCESS",
}

export enum PatientDetailDispatchTypes {
  PATIENT_DETAIL_FAIL = "PATIENT_DETAIL_FAIL",
  PATIENT_DETAIL_INVOKE = "PATIENT_DETAIL_INVOKE",
  PATIENT_DETAIL_SUCCESS = "PATIENT_DETAIL_SUCCESS",
}

export enum ReAuthPatientDetailActionDispatchTypes {
  RE_AUTH_PATIENT_DETAIL_FAIL = "RE_AUTH_PATIENT_DETAIL_FAIL",
  RE_AUTH_PATIENT_DETAIL_INVOKE = "RE_AUTH_PATIENT_DETAIL_INVOKE",
  RE_AUTH_PATIENT_DETAIL_SUCCESS = "RE_AUTH_PATIENT_DETAIL_SUCCESS",
  RE_AUTH_PATIENT_DETAIL_RESET = "RE_AUTH_PATIENT_DETAIL_RESET",
}
export enum PatientRequestDispatchTypes {
  PATIENT_REQUEST_FAIL = "PATIENT_REQUEST_FAIL",
  PATIENT_REQUEST_INVOKE = "PATIENT_REQUEST_INVOKE",
  PATIENT_REQUEST_SUCCESS = "PATIENT_REQUEST_SUCCESS",
  PATIENT_REQUEST_RESET = "PATIENT_REQUEST_RESET",
}

export enum Icd10RecordsActionDispatchTypes {
  ICD_10_ERROR = "ICD_10_ERROR",
  ICD_10_INVOKE = "ICD_10_INVOKE",
  ICD_10_RESET = "ICD_10_RESET",
  ICD_10_SUCCESS = "ICD_10_SUCCESS",
}

export enum SaveIcd10RecordsActionDispatchTypes {
  SAVE_ICD_10_ERROR = "SAVE_ICD_10_ERROR",
  SAVE_ICD_10_INVOKE = "SAVE_ICD_10_INVOKE",
  SAVE_ICD_10_SUCCESS = "SAVE_ICD_10_SUCCESS",
}

export enum SaveOrUpdatePatientRequestDispatchTypes {
  SAVE_PATIENT_REQUEST_ERROR = "SAVE_PATIENT_REQUEST_ERROR",
  SAVE_PATIENT_REQUEST_INVOKE = "SAVE_PATIENT_REQUEST_INVOKE",
  SAVE_PATIENT_REQUEST_RESET = "SAVE_PATIENT_REQUEST_RESET",
  SAVE_PATIENT_REQUEST_SUCCESS = "SAVE_PATIENT_REQUEST_SUCCESS",
}

export enum ReferralSubmitDispatchTypes {
  REFERRAL_SUBMIT_ERROR = "REFERRAL_SUBMIT_ERROR",
  REFERRAL_SUBMIT_INVOKE = "REFERRAL_SUBMIT_INVOKE",
  REFERRAL_SUBMIT_SUCCESS = "REFERRAL_SUBMIT_SUCCESS",
  REFERRAL_SUBMIT_RESET = "REFERRAL_SUBMIT_RESET",
}

export enum ReAuthReferralSubmitDispatchTypes {
  RE_AUTH_REFERRAL_SUBMIT_ERROR = "RE_AUTH_REFERRAL_SUBMIT_ERROR",
  RE_AUTH_REFERRAL_SUBMIT_INVOKE = "RE_AUTH_REFERRAL_SUBMIT_INVOKE",
  RE_AUTH_REFERRAL_SUBMIT_RESET = "RE_AUTH_REFERRAL_SUBMIT_RESET",
  RE_AUTH_REFERRAL_SUBMIT_SUCCESS = "RE_AUTH_REFERRAL_SUBMIT_SUCCESS",
}

export enum ReAuthReferralRequestDispatchType {
  RE_AUTH_REFERRAL_REQUEST_ERROR = "RE_AUTH_REFERRAL_REQUEST_ERROR",
  RE_AUTH_REFERRAL_REQUEST_INVOKE = "RE_AUTH_REFERRAL_REQUEST_INVOKE",
  RE_AUTH_REFERRAL_REQUEST_SUCCESS = "RE_AUTH_REFERRAL_REQUEST_SUCCESS",
  RE_AUTH_REFERRAL_REQUEST_RESET = "RE_AUTH_REFERRAL_REQUEST_RESET",
}

export enum EligibilityCheckDispatchTypes {
  CHECK_ELIGIBILITY_ERROR = "CHECK_ELIGIBILITY_ERROR",
  CHECK_ELIGIBILITY_INVOKE = "CHECK_ELIGIBILITY_INVOKE",
  CHECK_ELIGIBILITY_RESET = "CHECK_ELIGIBILITY_RESET",
  CHECK_ELIGIBILITY_SUCCESS = "CHECK_ELIGIBILITY_SUCCESS",
}

export enum MyPatientActionDispatchTypes {
  POST_PATIENTLIST_INVOKE = "POST_PATIENTLIST_INVOKE",
  POST_PATIENTLIST_SUCCESS = "POST_PATIENTLIST_SUCCESS",
  POST_PATIENTLIST_RESET = "POST_PATIENTLIST_RESET",
}

export enum PatientDataTitle {
  ACTION = "",
  ADDRESS = "ADDRESS",
  CITY = "CITY",
  DOB = "DOB",
  ELIGIBILITY_DATE = "ELIGIBILITY DATES",
  MBI = "MBI",
  NAME = "NAME",
  STATE = "STATE",
  SUBSCRIBER_ID = "SUBSCRIBER ID",
}

export const PatientHeaderLabel = [
  { label: PatientDataTitle.NAME, width: 260 },
  { label: PatientDataTitle.DOB, width: 100 },
  { label: PatientDataTitle.SUBSCRIBER_ID, width: 130 },
  { label: PatientDataTitle.MBI, width: 70 },
  { label: PatientDataTitle.ELIGIBILITY_DATE, width: 230 },
  { label: PatientDataTitle.ADDRESS, width: 250 },
  { label: PatientDataTitle.CITY, width: 120 },
  { label: PatientDataTitle.STATE, width: 70 },
  { label: PatientDataTitle.ACTION, width: 90 },
];

export enum MyPatientDataTitle {
  ACTION = "",
  ASSIGNED_TO = "ASSIGNED TO",
  AUTH = "AUTH ID",
  AUTH_TYPE = "AUTH. TYPE",
  CREATED_BY = "CREATED BY",
  DATE_TIME_REQUESTED = "DATE/TIME REQ. RECEIVED",
  HEALTH_PLAN = "HEALTH PLAN",
  NAME = "NAME",
  SERVICE_PROVIDER = "SERVICE PROVIDER",
  SERVICE_REQUEST_TYPE = "SERVICE REQ. TYPE",
  SKILL = "SKILL",
  STATE = "STATE",
  REFERRAL_SOURCE = "REFERRAL SOURCE",
  STATUS = "STATUS",
  STATUS_REASON = "STATUS REASON",
  URGENCY = "URGENCY",
}

export enum DisciplineData {
  ACTION = "Action",
  APPROVED_VISITS = "Approved Visits",
  ASSIGNED_TO = "Assigned To",
  DATE_TIME_REQUEST_RECEIVED = "Date/Time Request Received",
  DENIED_VISITS = "Denied visits",
  EFFECTIVE_END_DATE = "Effective end date",
  EFFECTIVE_START_DATE = "Effective start date",
  PROCEDURE_CODE = "Procedure Code",
  REQUESTED_VISITS = "Requested Visits",
  SERVICE_NO = "Service No.",
  STATUS = "Status",
  STATUS_REASON = "Status Reason",
  TYPE = "Type",
  URGENCY = "Urgency",
  SKILL = "Skill",
  SERVICE_REQ_NO = "Service Req. No.",
}

export enum ResumeIntakeTitle {
  ACTION = "Action",
  DATE_AND_TIME_RECEIVED = "Date and time received",
  INTAKE_ID = "Intake ID",
  LAST_MODIFIED = "Last modified:",
  MODIFIED_BY = "Modified by:",
}

export const MyPatientHeaderLabel = [
  { label: MyPatientDataTitle.NAME, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.URGENCY, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
  },
  { label: MyPatientDataTitle.AUTH, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.SERVICE_REQUEST_TYPE,
    style: { cell: Headcell170 },
  },
  { label: MyPatientDataTitle.SKILL, style: { cell: Headcell60 } },
  { label: MyPatientDataTitle.STATUS, style: { cell: Headcell160 } },
  {
    label: MyPatientDataTitle.STATUS_REASON,
    style: { cell: Headcell170 },
  },
  { label: MyPatientDataTitle.HEALTH_PLAN, style: { cell: Headcell150 } },
  { label: MyPatientDataTitle.STATE, style: { cell: Headcell60 } },
  { label: MyPatientDataTitle.SERVICE_PROVIDER, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.ACTION, style: { cell: Headcell60 } },
];

export const AllPendingHeaderLabel = [
  { label: MyPatientDataTitle.NAME, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.URGENCY, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
  },
  { label: MyPatientDataTitle.AUTH, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.SERVICE_REQUEST_TYPE,
    style: { cell: Headcell170 },
  },
  { label: MyPatientDataTitle.SKILL, style: { cell: Headcell60 } },
  { label: MyPatientDataTitle.STATUS, style: { cell: Headcell160 } },
  {
    label: MyPatientDataTitle.STATUS_REASON,
    style: { cell: Headcell150 },
  },
  { label: MyPatientDataTitle.HEALTH_PLAN, style: { cell: Headcell150 } },
  { label: MyPatientDataTitle.STATE, style: { cell: Headcell60 } },
  { label: MyPatientDataTitle.SERVICE_PROVIDER, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.ASSIGNED_TO, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.ACTION, style: { cell: Headcell60 } },
];

export type AssignStaffHeaderLabelType = {
  label: string;
  style: object;
  key: number;
  name: string;
  width?: string;
};

export const IncompleteRequestAssignStaffHeaderLabel: AssignStaffHeaderLabelType[] =
  [
    {
      label: MyPatientDataTitle.NAME,
      style: { cell: Headcell170 },
      key: 1,
      name: "fullName",
    },
    {
      label: MyPatientDataTitle.URGENCY,
      style: { cell: Headcell80 },
      key: 2,
      name: "urgencyStatus",
    },
    {
      label: MyPatientDataTitle.DATE_TIME_REQUESTED,
      style: { cell: Headcell250 },
      key: 3,
      name: "requestDateTime",
    },
    {
      label: MyPatientDataTitle.AUTH,
      style: { cell: Headcell80 },
      key: 4,
      name: "authorizationNumber",
    },
    {
      label: MyPatientDataTitle.HEALTH_PLAN,
      style: { cell: Headcell150 },
      key: 5,
      name: "healthPlanName",
    },
    {
      label: MyPatientDataTitle.STATE,
      style: { cell: Headcell60 },
      key: 6,
      name: "patientState",
    },
    {
      label: MyPatientDataTitle.REFERRAL_SOURCE,
      style: { cell: Headcell170 },
      key: 7,
      name: "referralSource",
    },
    {
      label: MyPatientDataTitle.ASSIGNED_TO,
      style: { cell: Headcell170 },
      key: 8,
      name: "assignedUserFullName",
    },
    {
      label: MyPatientDataTitle.CREATED_BY,
      style: { cell: Headcell170 },
      key: 9,
      name: "createdBy",
    },
    {
      label: MyPatientDataTitle.AUTH_TYPE,
      style: { cell: Headcell130 },
      key: 10,
      name: "serviceRequestTypeName",
    },
  ];

export const ResumeIntakeHeaderLabel = [
  { label: ResumeIntakeTitle.INTAKE_ID, style: { cell: Headcell100 } },
  { label: ResumeIntakeTitle.DATE_AND_TIME_RECEIVED, style: { Headcell310 } },
  { label: ResumeIntakeTitle.LAST_MODIFIED, style: { Headcell170 } },
  { label: ResumeIntakeTitle.MODIFIED_BY, style: { Headcell170 } },
  { label: ResumeIntakeTitle.ACTION, style: { Headcell310 } },
];
export const DisciplineHeaderLabel = [
  { label: DisciplineData.SERVICE_REQ_NO, style: { cell: Headcell140 } },
  { label: DisciplineData.SERVICE_NO, style: { cell: Headcell80 } },
  {
    label: DisciplineData.DATE_TIME_REQUEST_RECEIVED,
    style: { cell: Headcell250 },
  },
  { label: DisciplineData.TYPE, style: { cell: Headcell80 } },
  { label: DisciplineData.URGENCY, style: { cell: Headcell80 } },
  { label: DisciplineData.SKILL, style: { cell: Headcell40 } },
  { label: DisciplineData.EFFECTIVE_START_DATE, style: { cell: Headcell150 } },
  { label: DisciplineData.EFFECTIVE_END_DATE, style: { cell: Headcell150 } },
  { label: DisciplineData.REQUESTED_VISITS, style: { cell: Headcell130 } },
  { label: DisciplineData.APPROVED_VISITS, style: { cell: Headcell130 } },
  { label: DisciplineData.DENIED_VISITS, style: { cell: Headcell100 } },
  { label: DisciplineData.STATUS, style: { cell: Headcell100 } },
  { label: DisciplineData.STATUS_REASON, style: { cell: Headcell200 } },
  { label: DisciplineData.ASSIGNED_TO, style: { cell: Headcell170 } },
  { label: DisciplineData.ACTION, style: { cell: Headcell80 } },
];

export const DisciplineTable = [
  "Service No.",
  "Type",
  "Urgency",
  "HCPCS",
  "Effective Start Date",
  "Effective End Date",
  "Requested Visits",
  "Approved Visits",
  "Denied Visits",
  "Created On",
  "Clinical Review",
  "",
];
export enum PatientUrgencyStatus {
  EXPEDITED = "Expedited",
  STANDARD = "Standard",
}
export enum PatientReferralStatus {
  ACTIVE = "Active",
  DISCHARGED = "Discharged",
}

export const AssignStaffHeaderLabel: AssignStaffHeaderLabelType[] = [
  {
    label: MyPatientDataTitle.NAME,
    style: { cell: Headcell170 },
    key: 1,
    name: "fullName",
  },
  {
    label: MyPatientDataTitle.URGENCY,
    style: { cell: Headcell80 },
    key: 2,
    name: "urgencyStatus",
  },
  {
    label: MyPatientDataTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
    key: 3,
    name: "requestDateTime",
  },
  {
    label: MyPatientDataTitle.AUTH,
    style: { cell: Headcell80 },
    key: 4,
    name: "authorizationNumber",
  },
  {
    label: MyPatientDataTitle.SERVICE_REQUEST_TYPE,
    style: { cell: Headcell150 },
    key: 5,
    name: "serviceRequestTypeName",
  },
  {
    label: MyPatientDataTitle.SKILL,
    style: { cell: Headcell60 },
    key: 6,
    name: "disciplineName",
  },
  {
    label: MyPatientDataTitle.STATUS,
    style: { cell: Headcell150 },
    key: 7,
    name: "serviceStatus",
  },
  {
    label: MyPatientDataTitle.STATUS_REASON,
    style: { cell: Headcell150 },
    key: 8,
    name: "serviceStatusReason",
  },
  {
    label: MyPatientDataTitle.HEALTH_PLAN,
    style: { cell: Headcell130 },
    key: 9,
    name: "healthPlanName",
  },
  {
    label: MyPatientDataTitle.STATE,
    style: { cell: Headcell80 },
    key: 10,
    name: "patientState",
  },
  {
    label: MyPatientDataTitle.SERVICE_PROVIDER,
    style: { cell: Headcell150 },
    key: 11,
    name: "providerNpi",
  },
];

export const RemovePatientHeaderLabel = [
  { label: "" },
  { label: MyPatientDataTitle.NAME, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.URGENCY, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
  },
  { label: MyPatientDataTitle.AUTH, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.SERVICE_REQUEST_TYPE,
    style: { cell: Headcell150 },
  },
  { label: MyPatientDataTitle.SKILL, style: { cell: Headcell60 } },
  { label: MyPatientDataTitle.STATUS, style: { cell: Headcell150 } },
  { label: MyPatientDataTitle.STATUS_REASON, style: { cell: Headcell150 } },
  { label: MyPatientDataTitle.HEALTH_PLAN, style: { cell: Headcell130 } },
  { label: MyPatientDataTitle.STATE, style: { cell: Headcell80 } },
  { label: MyPatientDataTitle.SERVICE_PROVIDER, style: { cell: Headcell150 } },
];

export const IncompleteRequestHeaderLabel = [
  { label: MyPatientDataTitle.NAME, style: { cell: Headcell160 } },
  { label: MyPatientDataTitle.URGENCY, style: { cell: Headcell80 } },
  {
    label: MyPatientDataTitle.DATE_TIME_REQUESTED,
    style: { cell: Headcell250 },
  },
  { label: MyPatientDataTitle.AUTH, style: { cell: Headcell80 } },
  { label: MyPatientDataTitle.HEALTH_PLAN, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.STATE, style: { cell: Headcell80 } },
  { label: MyPatientDataTitle.REFERRAL_SOURCE, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.ASSIGNED_TO, style: { cell: Headcell170 } },
  { label: MyPatientDataTitle.CREATED_BY, style: { cell: Headcell310 } },
  { label: MyPatientDataTitle.AUTH_TYPE, style: { cell: Headcell130 } },
  { label: MyPatientDataTitle.ACTION, style: { cell: Headcell60 } },
];

export enum MyPatientListActionDispatchTypes {
  MY_PATIENT_LIST_SUCCESS = "MY_PATIENT_LIST_SUCCESS",
  MY_PATIENT_LIST_INVOKE = "MY_PATIENT_LIST_INVOKE",
  MY_PATIENT_LIST_ERROR = "MY_PATIENT_LIST_ERROR",
}
export const PatientInitialRequest = {
  referralDetail: {
    referralId: 0,
    patientId: 0,
    receivedDateTime: "",
    urgencyId: null,
    receivedMethodId: null,
    receivedMethodName: null,
    isSignedHealthOrder: "",
    signedOrderDate: null,
    signedOrderType: "",
    orderedById: null,
    acceptedById: null,
    isCustodialCare: "",
    isNonCoveredService: "",
    userCreated: null,
    nonCoveredServices: null,
    healthProgramId: null,
    careProgramId: null,
    urgencyStatus: "",
    orderedByName: "",
    acceptedByName: "",
    nonCoveredServiceNames: "",
    patientEligibilityId: 0,
    authorizationTypeId: 0,
  },
  emergencyContact: [],
  residentialAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    streetName: null,
    addressTypeId: null,
  },
  serviceAddress: {
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    patientState: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    streetName: null,
    addressTypeId: null,
  },
  requestor: {
    facilityTypeId: null,
    requesterName: "",
    requesterExtension: "",
    requesterPhoneNo: "",
    requesterEmail: null,
    requesterFax: "",
    requesterFacilityAddress: null,
    facilityTypeName: "",
  },
  referralSource: {
    facilityTypeId: null,
    referralSourceName: "",
    referralSourceExtension: "",
    referralSourcePhoneNo: "",
    referralSourceEmail: null,
    referralSourceFax: null,
    referralSourceFacilityAddress: null,
    sameAsRequestingFacility: false,
    dischargeDate: null,
    facilityTypeName: "",
  },
};
export const ReAuthInitialServiceRequest = {
  serviceRequestId: -1,
  referralId: -1,
  userCreated: null,
  startOfCareDate: "",
  startOfCareVerified: false,
  currentStartDate: "", // TBD
  currentEndDate: "", //TBD
  nextStartDate: "", //TBD
  nextEndDate: "", //TBD
  reAuthDiscipline: [],
  reAuthCareType: [],
};
export const ReAuthInitialRequest = {
  referralRequest: {
    serviceRequestId: 0,
    referralId: 0,
    receivedDateTime: null,
    urgencyId: null,
    userCreated: null,
    patientEligibilityId: 0,
    receivedMethodId: null,
  },
  requestor: {
    serviceRequestId: 0,
    facilityTypeId: null,
    requesterName: "",
    requesterExtension: "",
    requesterPhoneNo: "",
    requesterEmail: null,
    requesterFax: "",
    requesterFacilityAddress: null,
    facilityTypeName: "",
  },
};

export const Icd10RecordsDefaultValues = {
  id: 0,
  userCreated: null,
  icdCodesInfoDto: [],
  typeId: 0,
};

export interface InfoTable {
  referralIcdCodeId: number | null;
  diagnosisTypeId: number | null;
  diagnosisCode: string;
  diagnosisCodeDescription: string;
  icdDiagnosisCodeId: number | null;
}

export enum MessageType {
  DELETE_DOCUMENT_FILE = "DELETE_DOCUMENT_FILE",
  ERROR_DISCIPLINE_DATA = "ERROR_DISCIPLINE_DATA",
  SAVE_DOCUMENT_DATA = "SAVE_DOCUMENT_DATA",
  SAVE_DOCUMENT_FILE = "SAVE_DOCUMENT_FILE",
  SAVE_FOLLOWING_PHYSICIAN_DETAILS = "SAVE_FOLLOWING_PHYSICIAN_DETAILS",
  SAVE_ICD10_DATA = "SAVE_ICD10_DATA",
  SAVE_ORDERING_PHYSICIAN_DETAILS = "SAVE_ORDERING_PHYSICIAN_DETAILS",
  SAVE_PATIENT_RECORD = "SAVE_PATIENT_RECORD",
  SAVE_PHYSICIAN_DATA = "SAVE_PHYSICIAN_DATA",
  SAVE_REFERRAL_DATA = "SAVE_REFERRAL_DATA",
  SAVE_SERVICE_PROVIDER_DATA = "SAVE_SERVICE_PROVIDER_DATA",
  SAVE_SERVICE_REQUEST = "SAVE_SERVICE_REQUEST",
  SAVE_REFERRAL_INTAKE_DETAILS = "SAVE_REFERRAL_INTAKE_DETAILS",
  UPDATE_DISCIPLINE_DATA = "UPDATE_DISCIPLINE_DATA",
  UPDATE_DOCUMENT_DATA = "UPDATE_DOCUMENT_DATA",
  UPDATE_DOCUMENT_FILE = "UPDATE_DOCUMENT_FILE",
  UPDATE_FOLLOWING_PHYSICIAN_DETAILS = "UPDATE_FOLLOWING_PHYSICIAN_DETAILS",
  UPDATE_ICD10_DATA = "UPDATE_ICD10_DATA",
  UPDATE_INCOMPLETE_REQUEST = "UPDATE_INCOMPLETE_REQUEST",
  UPDATE_ORDERING_PHYSICIAN_DETAILS = "UPDATE_ORDERING_PHYSICIAN_DETAILS",
  UPDATE_PHYSICIAN_DATA = "UPDATE_PHYSICIAN_DATA",
  UPDATE_REFERRAL_DATA = "UPDATE_REFERRAL_DATA",
  UPDATE_SERVICE_PROVIDER_DATA = "UPDATE_SERVICE_PROVIDER_DATA",
  UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST",
  REMOVE_PATIENT_SUCCESS = "REMOVE_PATIENT_SUCCESS",
  REMOVE_PATIENT_ERROR = "REMOVE_PATIENT_ERROR",
  UPLOAD_PHOTO = "UPLOAD_PHOTO",
  UPLOAD_PHOTO_ERROR = "UPLOAD_PHOTO_ERROR",
  SAVE_AUTH_DETAILS = "SAVE_AUTH_DETAILS",
  SAVE_INITIATE_FAX_FAIL = "SAVE_INITIATE_FAX_FAIL",
  REAUTH_DETAILS_SUCCESS = "REAUTH_DETAILS_SUCCESS",
  AUTH_TEMPLATE_GENERATE_SUCCESS = "AUTH_TEMPLATE_GENERATE_SUCCESS",
  FAX_SENT_MESSAGE = "FAX_SENT_MESSAGE",
  SAVE_ATTACH_LETTER = "SAVE_ATTACH_LETTER",
  ATTACH_LETTER_ERROR = "ATTACH_LETTER_ERROR",
}

export const ReferralResponseMessages = (value: string) => {
  switch (value) {
    case MessageType.UPDATE_REFERRAL_DATA:
      return "Referral Details Updated Successfully !";
    case MessageType.SAVE_REFERRAL_DATA:
      return "Referral Details Saved Successfully !";
    case MessageType.SAVE_PATIENT_RECORD:
      return "Patient Record Saved Successfully !";
    case MessageType.UPDATE_DOCUMENT_DATA:
      return "Document Detail Updated Successfully !";
    case MessageType.SAVE_DOCUMENT_DATA:
      return "Document Detail Saved Successfully !";
    case MessageType.UPDATE_DOCUMENT_FILE:
      return "File Updated Successfully !";
    case MessageType.SAVE_DOCUMENT_FILE:
      return "File Saved Successfully !";
    case MessageType.SAVE_ICD10_DATA:
      return "ICD10 Saved Successfully !";
    case MessageType.UPDATE_PHYSICIAN_DATA:
      return "Physician Detail Updated Successfully !";
    case MessageType.UPDATE_SERVICE_PROVIDER_DATA:
      return "Service Provider Updated Successfully !";
    case MessageType.UPDATE_SERVICE_REQUEST:
      return "Service Request Updated Successfully !";
    case MessageType.SAVE_PHYSICIAN_DATA:
      return "Physician Detail Saved Successfully !";
    case MessageType.SAVE_SERVICE_PROVIDER_DATA:
      return "Service Provider Saved Successfully !";
    case MessageType.SAVE_SERVICE_REQUEST:
      return "Service Request Saved Successfully !";
    case MessageType.SAVE_REFERRAL_INTAKE_DETAILS:
      return "Referral Intake details Saved Successfully !";
    case MessageType.DELETE_DOCUMENT_FILE:
      return "Document Removed Successfully !";
    case MessageType.SAVE_FOLLOWING_PHYSICIAN_DETAILS:
      return "Following Physician Details Saved Successfully !";
    case MessageType.UPDATE_FOLLOWING_PHYSICIAN_DETAILS:
      return "Following Physician Details Updated Successfully !";
    case MessageType.UPDATE_DISCIPLINE_DATA:
      return "Discipline Data Updated Successfully !";
    case MessageType.ERROR_DISCIPLINE_DATA:
      return "Error In Discipline Data Update !";
    case MessageType.SAVE_ORDERING_PHYSICIAN_DETAILS:
      return "Ordering Physician Details Saved Successfully !";
    case MessageType.UPDATE_ORDERING_PHYSICIAN_DETAILS:
      return "Ordering Physician Details Updated Successfully !";
    case MessageType.UPDATE_INCOMPLETE_REQUEST:
      return "Assigned Staff To The Referral Successfully !";
    case MessageType.UPDATE_ICD10_DATA:
      return "ICD10 Updated Successfully !";
    case MessageType.REMOVE_PATIENT_SUCCESS:
      return "Patients Removed Successfully !";
    case MessageType.REMOVE_PATIENT_ERROR:
      return "Error In Patient Remove !";
    case MessageType.UPLOAD_PHOTO:
      return "Photo Uploaded Successfully!";
    case MessageType.UPLOAD_PHOTO_ERROR:
      return "Photo Upload Failure!";
    case MessageType.SAVE_AUTH_DETAILS:
      return "Authorization Details Submitted!";
    case MessageType.SAVE_INITIATE_FAX_FAIL:
      return "Error In Fax Sending Process!";
    case MessageType.REAUTH_DETAILS_SUCCESS:
      return "Re-Authorization Details Saved Successfully !";
    case MessageType.AUTH_TEMPLATE_GENERATE_SUCCESS:
      return "Auth Template Generated Successfully !";
    case MessageType.FAX_SENT_MESSAGE:
      return "The fax is currently being sent. Please refresh fax status/page after a few moments to check the status and download the file";
    case MessageType.SAVE_ATTACH_LETTER:
      return "Attach Letter Saved Successfully !";
    case MessageType.ATTACH_LETTER_ERROR:
      return "Error In Saving Attach Letter !";
    default:
      return "";
  }
};

export const RadioButton = [
  {
    label: "YES",
    value: "Yes",
  },
  {
    label: "NO",
    value: "No",
  },
  {
    label: "UNKNOWN",
    value: "Unknown",
  },
];

export const HistoryHeaderTabs = [
  { id: 1, label: "AUTHORIZATION" },
  { id: 2, label: "ELIGIBILITY" },
];

export const HeaderLabelAuthHistory = [
  { label: "Auth ID", flag: false },
  { label: "Health Plan", flag: false },
  { label: "Subscriber ID", flag: false },
  { label: "Status", flag: false },
  { label: "Service Provider", flag: false },
  { label: "Admit Date", flag: false },
  { label: "Discharge Date", flag: false },
  { label: "Total Approved", flag: false },
  { label: "SN", flag: true },
  { label: "PT", flag: true },
  { label: "OT", flag: true },
  { label: "ST", flag: true },
  { label: "MSW", flag: true },
  { label: "HHA", flag: true },
];

export const EligibilityHistoryHeaderLabel = [
  { label: "Eligibility Check Date", flag: false, style: { cell: Headcell200 } },
  { label: "Health Plan", flag: false, style: { cell: Headcell250 } },
  { label: "Subscriber ID", flag: false, style: { cell: Headcell200 } },
  { label: "Product", flag: false, style: { cell: Headcell130 } }, 
  { label: "Benefit Plan", flag: false, style: { cell: Headcell250 } },
  { label: "State", flag: false, style: { cell: Headcell100 } },
  { label: "MBI Number", flag: false, style: { cell: Headcell200 } },
  { label: "AHCCCS Number", flag: false, style: { cell: Headcell200 } },
  { label: "Status", flag: true, style: { cell: Headcell130 } },
  { label: "Policy Dates", flag: false, style: { cell: Headcell250 } },
  { label: "Contract ID-PBP", flag: false, style: { cell: Headcell200 } },
  { label: "Created By", flag: false, style: { cell: Headcell250 } },
];

export const OrderingPhysicianInitialState = {
  primaryPhysician: {
    referralId: 0,
    referralPhysicianDetailId: 0,
    physicianId: 0,
    physicianName: "",
    physicianNpiId: "",
    phoneExt: "",
    phoneNo: "",
    taxId: "",
    streetName: "",
    county: "",
    city: "",
    physicianState: "",
    zipCode: "",
    userCreated: null,
    physicianUid: "",
  },
  orderingPhysician: {
    referralPhysicianDetailId: 0,
    referralId: 0,
    physicianId: 0,
    physicianName: "",
    physicianNpiId: "",
    phoneExt: "",
    phoneNo: "",
    taxId: "",
    streetName: "",
    county: "",
    city: "",
    physicianState: "",
    zipCode: "",
    physicianSameAsPrimary: false,
  },
};

export const documentDefaultValues = {
  id: 0,
  containsSupportedDoc: false,
  documentationDate: null,
  documentId: [],
  userCreated: null,
  type: 0,
  addedDocuments: [],
};

export const DocumentFlowType = {
  Auth: 1,
  ReAuth: 2,
};

export const PatientDetailType = {
  Patient: 1,
  Referral: 2,
  ServiceRequest: 3,
};

export const reAuthDocumentDefaultValues = {
  referralDocument: {
    referralId: 0,
    reAuthId: 0,
    containsSupportedDoc: false,
    documentationDate: null,
    documentId: [],
    userCreated: null,
  },
  blobDocument: [],
};

export enum AddNotesHeaderMessageType {
  ACTION = "ACTION",
  NOTE_DATE_TIME = "NOTE DATE/TIME:",
  NOTE_TYPE = "NOTE TYPE:",
  COMMENT = "COMMENT:",
  CREATED_BY = "CREATED BY:",
}

export const AddNotesHeaderMessage = (value: string) => {
  switch (value) {
    case AddNotesHeaderMessageType.NOTE_DATE_TIME:
      return "NOTE DATE/TIME";
    case AddNotesHeaderMessageType.NOTE_TYPE:
      return "NOTE TYPE";
    case AddNotesHeaderMessageType.COMMENT:
      return "COMMENT";
    case AddNotesHeaderMessageType.CREATED_BY:
      return "CREATED BY";
    case AddNotesHeaderMessageType.ACTION:
      return "ACTION";
    default:
      return "";
  }
};

export const PatientTab = [
  {
    id: 1,
    status: "REQUEST",
  },
  {
    id: 2,
    status: "ICD 10",
  },
  {
    id: 3,
    status: "ORDERING PHYSICIAN",
  },
  {
    id: 4,
    status: "SERVICE PROVIDER",
  },
  {
    id: 5,
    status: "SERVICE REQUESTS",
  },
  {
    id: 6,
    status: "DOCUMENTS",
  },
  {
    id: 7,
    status: "REVIEW",
  },
];

export const ReferralTab = [
  {
    id: 1,
    status: "REQUEST",
  },
  {
    id: 2,
    status: "ICD 10",
  },
  {
    id: 3,
    status: "FOLLOWING PHYSICIAN",
  },
  {
    id: 4,
    status: "SERVICE REQUESTS",
  },
  {
    id: 5,
    status: "DOCUMENTS",
  },
  {
    id: 6,
    status: "REVIEW",
  },
];

export enum SortColumn {
  ASSIGNED_TO = "ASSIGNED TO",
  AUTH = "AUTH ID",
  AUTH_TYPE = "AUTH. TYPE",
  DATE_TIME_REQUESTED = "DATE/TIME REQ. RECEIVED",
  HEALTH_PLAN = "HEALTH PLAN",
  NAME = "NAME",
  SERVICE_PROVIDER = "SERVICE PROVIDER",
  SERVICE_REQUEST_TYPE = "SERVICE REQ. TYPE",
  SKILL = "SKILL",
  STATE = "STATE",
  STATUS = "STATUS",
  STATUS_REASON = "STATUS REASON",
  URGENCY = "URGENCY",
  CREATED_BY = "CREATED BY",
  REFERRAL_SOURCE = "REFERRAL SOURCE",
}

export const getSortColumn = (value: string | undefined) => {
  switch (value) {
    case StaffingListTitle.NAME:
    case SortColumn.NAME:
      return "Name";
    case StaffingListTitle.URGENCY:
    case SortColumn.URGENCY:
      return "UrgencyStatus";
    case StaffingListTitle.DATE_TIME_REQUESTED:
    case SortColumn.DATE_TIME_REQUESTED:
      return "RequestDateTime";
    case StaffingListTitle.HEALTH_PLAN:
    case SortColumn.HEALTH_PLAN:
      return "HealthPlanName";
    case StaffingListTitle.ASSIGNED_TO:
      return "AssignedToStaffName";
    case SortColumn.ASSIGNED_TO:
      return "AssignedTo";
    case StaffingListTitle.AUTH_ID:
    case SortColumn.AUTH:
      return "AuthorizationNumber";
    case StaffingListTitle.STATE:
    case SortColumn.STATE:
      return "State";
    case StaffingListTitle.COUNTY:
      return "County";
    case StaffingListTitle.SERVICE_PROVIDER:
    case SortColumn.SERVICE_PROVIDER:
      return "ProviderNpi";
    case SortColumn.AUTH_TYPE:
    case SortColumn.SERVICE_REQUEST_TYPE:
      return "ServiceRequestType";
    case SortColumn.SKILL:
      return "DisciplineName";
    case SortColumn.STATUS:
      return "ServiceStatus";
    case SortColumn.STATUS_REASON:
      return "ServiceStatusReason";
    case SortColumn.CREATED_BY:
      return "UserCreated";
    case StaffingListTitle.STAFFING_STATUS:
      return "StaffingStatus";
    case StaffingListTitle.STAGE:
      return "Stage";
    case SortColumn.REFERRAL_SOURCE:
      return "FacilityTypeName";
    default:
      return "";
  }
};

export type PatientList = {
  value: string;
  fontWeight: string;
  fieldValue?: string;
};

export const myPatientsData = [
  {
    value: "fullName",
    fontWeight: fontWeight.Weight[6],
  },
  {
    value: "urgencyStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "requestDateTime",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "authorizationNumber",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "serviceRequestTypeName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "disciplineName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceStatusReason",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "healthPlanName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "patientState",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "providerNpi",
    fontWeight: fontWeight.Weight[4],
  },
];

export const allPendingData = [
  {
    value: "fullName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "urgencyStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "requestDateTime",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "authorizationNumber",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "serviceRequestTypeName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "disciplineName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceStatusReason",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "healthPlanName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "patientState",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "providerNpi",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "assignedUserFullName",
    fontWeight: fontWeight.Weight[4],
  },
];

export const incompleteRequestData = [
  {
    value: "fullName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "urgencyStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "requestDateTime",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "authorizationNumber",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "healthPlanName",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "patientState",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "facilityTypeName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "assignedUserFullName",
    fontWeight: fontWeight.Weight[4],
  },
  {
    value: "createdBy",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceRequestTypeName",
    fontWeight: fontWeight.Weight[5],
  },
];

export const EligibilityRecordLabel = [
  { label: "Health Plan:" },
  { label: "Subscriber ID:" },
  { label: "Product:" },
  { label: "Benefit Plan:" },
  { label: "State:" },
  { label: "MBI Number:" },
  { label: "AHCCS Number:" },
  { label: "Status:" },
];

export const EligibilityRecordKeys = [
  "healthPlan",
  "product",
  "benefitPlanName",
  "healthPlanState",
];

export const disciplineList = [
  {
    value: "serviceRequestNumber",
    fontWeight: fontWeight.Weight[6],
  },
  {
    value: "serviceNumber",
    fontWeight: fontWeight.Weight[6],
  },
  {
    value: "requestDateTime",
    fontWeight: fontWeight.Weight[4],
    color: colors.black[15],
  },
  {
    value: "serviceRequestTypeName",
    fontWeight: fontWeight.Weight[6],
    color: colors.black[15],
  },
  {
    value: "urgencyStatus",
    fontWeight: fontWeight.Weight[4],
    color: colors.black[15],
  },
  {
    value: "skill",
    fontWeight: fontWeight.Weight[3],
    color: colors.fonts[4],
  },
  {
    value: "effectiveFromDate",
    fontWeight: fontWeight.Weight[3],
    color: colors.fonts[4],
  },
  {
    value: "effectiveThroughDate",
    fontWeight: fontWeight.Weight[3],
    color: colors.fonts[4],
  },
  {
    value: "requestedVisits",
    fontWeight: fontWeight.Weight[6],
    color: colors.black[15],
  },
  {
    value: "approvedVisits",
    fontWeight: fontWeight.Weight[6],
    color: colors.black[15],
  },
  {
    value: "deniedVisits",
    fontWeight: fontWeight.Weight[6],
    color: colors.black[15],
  },
  {
    value: "serviceStatus",
    fontWeight: fontWeight.Weight[5],
  },
  {
    value: "serviceStatusReason",
    fontWeight: fontWeight.Weight[5],
    color: colors.black[15],
  },
  {
    value: "assignedUserFullName",
    fontWeight: fontWeight.Weight[5],
    color: colors.black[15],
  },
];

export enum BreadcrumbName {
  PATIENT_RECORDS = "Patient Records",
  PATIENT_DETAILS = "Patient Details",
  RE_AUTHORIZATION_DETAILS = "Re-authorization Details",
  AUTHORIZATION_DETAILS = "Authorization Details",
  REFERRAL_INTAKE = "Referral Intake",
}

export const ReferralIntakeLastTab = 6;
export const ReAuthIntakeLastTab = 5;
export enum IntakeType {
  RE_AUTHORIZATION_DETAILS = "reauth",
  REFERRAL_INTAKE = "referralIntake",
}

export const StaffingAssignStaffValues = [
  { headerLabel: "Note Date/Time", width: "17%", key: 1, name: "dateCreated" },
  { headerLabel: "Note Level", width: "9%", key: 2, name: "noteLevel" },
  { headerLabel: "Note Type", width: "13%", key: 3, name: "templateName" },
  { headerLabel: "Auth/SN", width: "5%", key: 4, name: "disciplineName" },
  { headerLabel: "Note", width: "36%", key: 5, name: "note" },
  { headerLabel: "Created By", width: "15%", key: 6, name: "userCreated" },
  { headerLabel: "", width: "5%", key: 7, name: "action" },
];

export type AllPatientRecordsLabelType = {
  label: string;
  xs: number;
};

export const AllPatientRecordsLabel: AllPatientRecordsLabelType[] = [
  { label: "NAME", xs: 2 },
  { label: "DOB", xs: 0.8 },
  { label: "MBI", xs: 1.1 },
  { label: "ELIGIBILITY DATES", xs: 1.6 },
  { label: "ADDRESS", xs: 2.1 },
  { label: "CITY", xs: 1.1 },
  { label: "STATE", xs: 0.9 },
];

export enum TableDropdown {
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  PHYSICIAN = "PHYSICIAN",
}

const gridSize = [4, 2, 4, 2];

const physicianTableHeadKeys = [
  "physicianName",
  "physicianNpi",
  "physicianAddress",
  "action",
];

const physicianTableBodyKeys = [
  "physicianName",
  "physicianNpiId",
  "physicianAddress",
  "CHOOSE",
];

const serviceProviderTableHeadKeys = [
  "providerName",
  "providerNpi",
  "physicianAddress",
  "action",
];

const serviceProviderTableBodyKeys = [
  "providerName",
  "providerNpi",
  "providerAddress",
  "CHOOSE",
];

const tableHead: TableHead[] = [
  {
    label: "Name:",
    key: "",
    style: { textAlign: "left", paddingLeft: "1rem" },
    type: "body1",
    color: colors.black[14],
    fontWeight: fontWeight.Weight[5],
    xs: gridSize[0],
  },
  {
    label: "NPI Number:",
    key: "",
    style: { textAlign: "center" },
    type: "body1",
    color: colors.black[14],
    fontWeight: fontWeight.Weight[5],
    xs: gridSize[1],
  },
  {
    label: "Address:",
    key: "",
    style: { textAlign: "left", paddingLeft: "1rem" },
    type: "body1",
    color: colors.black[14],
    fontWeight: fontWeight.Weight[5],
    xs: gridSize[2],
  },
  {
    label: "Action:",
    key: "",
    style: { textAlign: "center" },
    type: "body1",
    color: colors.black[14],
    fontWeight: fontWeight.Weight[5],
    xs: gridSize[3],
  },
];

const tableBody: TableBody[] = [
  {
    key: "",
    style: PhysicianNameCol,
    type: "subtitle2",
    color: colors.black[4],
    fontWeight: fontWeight.Weight[4],
    xs: gridSize[0],
  },
  {
    key: "",
    style: PhysicianNpiCol,
    type: "subtitle2",
    color: colors.black[4],
    fontWeight: fontWeight.Weight[4],
    xs: gridSize[1],
  },
  {
    key: "",
    style: PhysicianNameCol,
    type: "subtitle2",
    color: colors.black[4],
    fontWeight: fontWeight.Weight[4],
    xs: gridSize[2],
  },
  {
    key: "",
    style: SelectPhysicianBtn,
    type: "subtitle2",
    color: colors.black[4],
    fontWeight: fontWeight.Weight[4],
    xs: gridSize[3],
  },
];

export const getTableHead = (table: TableDropdown) => {
  switch (table) {
    case TableDropdown.SERVICE_PROVIDER:
      return tableHead.map((head, index) => {
        head.key = serviceProviderTableHeadKeys[index];
        return head;
      });
    case TableDropdown.PHYSICIAN:
      return tableHead.map((head, index) => {
        head.key = physicianTableHeadKeys[index];
        return head;
      });
  }
  return [];
};

export const getTableBody = (tab: TableDropdown) => {
  switch (tab) {
    case TableDropdown.SERVICE_PROVIDER:
      return tableBody.map((body, index) => {
        body.key = serviceProviderTableBodyKeys[index];
        return body;
      });
    case TableDropdown.PHYSICIAN:
      return tableBody.map((body, index) => {
        body.key = physicianTableBodyKeys[index];
        return body;
      });
  }
  return [];
};

export const MandatoryFieldAlertMessage = "Please fill in the mandatory fields";

export enum ServiceStatus {
  APPROVED = "Approved",
  CANCELLED = "Cancel",
  DENIED = "Denied",
  EXPEDITED = "Expedited",
  PARTIALLY_APPROVED = "Partially Approved",
  PENDING = "Pending",
  STANDARD = "Standard",
}

export enum UrgencyStatus {
  EXPEDITED = "Expedited",
  STANDARD = "Standard",
}

export const EmergencyContactOption = {
  label: "relationshipName",
  value: "relationId",
};

export enum AuthType {
  PRIOR_AUTH = "Prior Auth",
  RE_AUTH = "ReAuth",
}
